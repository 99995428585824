/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validate as validateUUID } from 'uuid';

import { useServices } from '../services';

export const registrationDetailsContext = createContext({
  aggregatedRegistration: {
    buyer: {},
    registration: {},
    car: {},
    labels: [],
    agent: {},
    account: {},
  },
  setAggregatedRegistration: () => {},
  registrationLoading: false,
  updater: 0,
  forceUpdate: () => {},
  registrationIncidents: [],
});

export function RegistrationDetailsContextProvider({ children }) {
  const [aggregatedRegistration, setAggregatedRegistration] = useState({
    buyer: {},
    registration: {},
    car: {},
    labels: [],
    agent: {},
    account: {},
  });
  const [registrationLoading, setRegistrationLoading] = useState(true);

  const [registrationIncidents, setRegistrationIncidents] = useState([]);

  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { code } = useParams();

  const {
    getRegistrationByCode,
    fetchAllIncidentsByEntityId,
    getRegistrationById,
  } = useServices();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRegistration = async () => {
      if (validateUUID(code)) {
        const registration = await getRegistrationById(code);
        navigate(`/registration/${registration.registrationCode}`);
        return;
      }

      const response = await getRegistrationByCode(code);

      const incidents = await fetchAllIncidentsByEntityId(
        response?.registration?.id,
      );
      setRegistrationIncidents(incidents);

      setAggregatedRegistration(response);
      setRegistrationLoading(false);
    };
    fetchRegistration();
  }, [code, updater]);

  const value = useMemo(
    () => ({
      aggregatedRegistration,
      setAggregatedRegistration,
      updater,
      forceUpdate,
      registrationLoading,
      registrationIncidents,
    }),
    [
      aggregatedRegistration,
      setAggregatedRegistration,
      updater,
      forceUpdate,
      registrationLoading,
      registrationIncidents,
    ],
  );
  return (
    <registrationDetailsContext.Provider value={value}>
      {children}
    </registrationDetailsContext.Provider>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import DeliveryInfoTemplate from '../DeliveryInfoTemplate/DeliveryInfoTemplate';
import { Shipment } from '../../../../types/types';
import { DeliveryInfo, ReceiverInfo } from '../../types/types';
import { getDeliveryAddress } from '../../helpers/getDeliveryAddress';
import { handleChangeAddress } from '../../helpers/handleChangeAddress';
import { handleFormEdit } from '../../helpers/handleFormEdit';
import { handleSubmit } from '../../helpers/handleSubmit';
import { setNewReceiver } from '../../helpers/setNewReceiver';
import Spinner from '../../../../../../components/Spinner';
import { shipmentsContext } from '../../../../../../context/ShipmentsContext/shipmentsContext';
import {
  getUserById,
  getCompanyInfoById,
} from '../../../../../../services/shipment.services';
import { DeliveryAddress, Transaction } from '../../../../../../types/types';

function Form({
  shipment,
  setError,
  showModal,
  setShowModal,
}: {
  shipment: Shipment;
  setError: Dispatch<SetStateAction<string>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) {
  const [loading, setLoader] = useState(false);
  const [receiverRole, setReceiverRole] = useState<string>('');
  const [receiverLoading, setReceiverLoading] = useState<boolean>(false);
  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>(
    [],
  );

  const { entity } = useContext(shipmentsContext);

  const initialDeliveryInfo: DeliveryInfo = {
    deliveryState: {
      value: shipment.delivery?.state || '',
      label: 'Comunidad Autónoma',
      shipmentKey: 'state',
    },
    deliveryCity: {
      value: shipment.delivery?.city || '',
      label: 'Ciudad',
      shipmentKey: 'city',
    },
    deliveryAddress: {
      value: shipment.delivery?.street || '',
      label: 'Dirección',
      shipmentKey: 'street',
    },
    deliveryZipCode: {
      value: shipment.delivery?.zipCode || '',
      label: 'Código Postal',
      shipmentKey: 'zipCode',
    },
  };

  const initialReceiverInfo: ReceiverInfo = {
    receiverName: {
      value: shipment.receiver?.name || '',
      label: 'Nombre',
      shipmentKey: 'name',
    },
    receiverDni: {
      value: shipment.receiver?.doi || '',
      label: 'DNI',
      shipmentKey: 'doi',
    },
    receiverEmail: {
      value: shipment.receiver?.email || '',
      label: 'Email',
      shipmentKey: 'email',
    },
    receiverPhone: {
      value: shipment.receiver?.phone || '',
      label: 'Teléfono',
      shipmentKey: 'phone',
    },
  };

  const [deliveryInfo, setDeliveryInfo] =
    useState<DeliveryInfo>(initialDeliveryInfo);
  const [receiverInfo, setReceiverInfo] =
    useState<ReceiverInfo>(initialReceiverInfo);

  const { forceUpdate } = useContext(shipmentsContext);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!showModal) return;

    const getUserInfo = async () => {
      const id = entity[`${receiverRole}Id` as keyof typeof entity] as string;
      let company;
      let user;
      setReceiverLoading(true);
      try {
        ({ data: user } = await getUserById(
          id,
          await getAccessTokenSilently(),
        ));
      } catch (e) {
        if (!user) {
          ({ data: company } = await getCompanyInfoById(
            id,
            await getAccessTokenSilently(),
          ));
          ({ data: user } = await getUserById(
            company.representativeId,
            await getAccessTokenSilently(),
          ));
        }
      }
      setNewReceiver(user, setDeliveryInfo, setReceiverInfo);
      setReceiverLoading(false);
    };

    if (['buyer', 'seller', 'intermediary'].includes(receiverRole)) {
      getUserInfo();
    }
  }, [receiverRole]);

  useEffect(() => {
    setDeliveryInfo(initialDeliveryInfo);
    setReceiverInfo(initialReceiverInfo);
  }, [shipment, showModal]);

  useEffect(() => {
    if (!showModal) return;
    (async () => {
      getDeliveryAddress({
        accountId: entity?.accountId,
        setDeliveryAddress: setDeliveryAddresses,
        setError,
        token: await getAccessTokenSilently(),
      });
    })();
  }, [showModal]);

  return (
    <form
      onSubmit={handleSubmit({
        deliveryInfo,
        receiverInfo,
        shipment,
        setShowModal,
        setError,
        setLoader,
        forceUpdate,
        getAccessTokenSilently,
      })}
    >
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <p className="block text-sm font-medium text-gray-700 mb-2">
            Selecciona al destinatario
          </p>
          <p className="mb-2 text-sm font-medium text-gray-700 py-2">
            <span>Destinatario:</span>

            <div className="flex gap-x-4 items-center">
              <select
                value={receiverRole}
                onChange={handleChangeAddress({
                  setReceiverRole,
                  setDeliveryInfo,
                  setReceiverInfo,
                  deliveryAddresses,
                })}
                className="rounded-md border-gray-300 text-sm h-fit ml-4"
                data-testid="receiver-role"
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {entity.buyerId && <option value="buyer">Comprador</option>}
                {(entity as Transaction)?.sellerId && (
                  <option value="seller">Vendedor</option>
                )}
                {(entity as Transaction)?.intermediaryId && (
                  <option value="intermediary">Intermediario</option>
                )}
                <option value="custom">Personalizado</option>
                {deliveryAddresses.map((address) => (
                  <option value={address.id} key={address.id}>
                    {address.referenceName}
                  </option>
                ))}
              </select>
              {receiverLoading && (
                <Spinner size={5} color="text-blue-500" marginTop={0} />
              )}
            </div>
          </p>

          <p className="block text-sm text-gray-700 mb-2  font-bold">
            Dirección de Envío
          </p>
          <div className="flex flex-col gap-y-4 mb-4">
            {Object.keys(deliveryInfo).map((i) => (
              <DeliveryInfoTemplate
                key={i}
                objectKey={i}
                data={deliveryInfo[i as keyof typeof deliveryInfo]}
                handleFormEdit={handleFormEdit(setReceiverRole)}
                setState={setDeliveryInfo}
              />
            ))}
          </div>
          <p className="block text-sm text-gray-700 mb-2 font-bold">
            Datos del Receptor
          </p>
          <div className="flex flex-col gap-y-4">
            {Object.keys(receiverInfo).map((i) => (
              <DeliveryInfoTemplate
                key={i}
                objectKey={i}
                data={receiverInfo[i as keyof typeof receiverInfo]}
                handleFormEdit={handleFormEdit(setReceiverRole)}
                setState={setReceiverInfo}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button
          type="button"
          className="ml-3 inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md text-black"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancelar
        </button>
        <button
          type="submit"
          disabled={loading || !deliveryInfo.deliveryState.value}
          className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
            deliveryInfo.deliveryState.value
              ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              : 'bg-gray-400'
          }`}
        >
          {loading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
          {loading ? 'Guardando...' : 'Guardar datos'}
        </button>
      </div>
    </form>
  );
}

export default Form;

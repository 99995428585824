import {
  FolderIcon,
  DocumentTextIcon,
  UsersIcon,
  TruckIcon,
  CurrencyDollarIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import { Location } from 'react-router-dom';

export type MenuItem = {
  name: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  current: boolean;
};

export const getNavigationTramitItems = (location: Location): MenuItem[] => [
  {
    name: 'Compraventas',
    href: '/transfers?page=0',
    icon: FolderIcon,
    current: location.pathname.includes('/transfers'),
  },
  {
    name: 'Matriculaciones',
    href: '/registrations?page=0',
    icon: FolderIcon,
    current: location.pathname.includes('/registrations'),
  },
  {
    name: 'Reservas de Dominio',
    href: '/domain-reservations?page=0',
    icon: FolderIcon,
    current: location.pathname.includes('/domain-reservations'),
  },
  {
    name: 'Duplicados',
    href: '/duplicates?page=0',
    icon: FolderIcon,
    current: location.pathname.includes('/duplicates'),
  },
  {
    name: 'Informes',
    href: '/traffic-reports?page=0',
    icon: FolderIcon,
    current: location.pathname.includes('/traffic-reports'),
  },
];

export const getInsuranceItems = (location: Location): MenuItem[] => [
  {
    name: 'Garantías Mecánicas',
    href: '/mechanical-warranties?page=0',
    icon: ShieldCheckIcon,
    current: location.pathname.includes('/mechanical-warranties'),
  },
];

export const getNavigationCompanyItems = (location: Location): MenuItem[] => [
  {
    name: 'Clientes',
    href: '/customers?page=0',
    icon: UsersIcon,
    current: location.pathname.includes('/customers'),
  },
  {
    name: 'Vehículos',
    href: '/vehicles?page=0',
    icon: TruckIcon,
    current: location.pathname.includes('/vehicles'),
  },
  {
    name: 'Facturas',
    href: '/invoices',
    icon: DocumentTextIcon,
    current: location.pathname.includes('/invoices'),
  },
  {
    name: 'Método de Pago',
    href: '/payment-method',
    icon: CurrencyDollarIcon,
    current: location.pathname.includes('/payment-method'),
  },
];

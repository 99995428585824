import { useAuth0, User } from '@auth0/auth0-react';
import { useState, useReducer, useEffect } from 'react';
import { Comment } from '../../types/types';
import { getAllIncidentsByEntityId } from '../../services/services';
import {
  fetchComments,
  postNewComment,
  removeComment,
} from '../../services/comments';
import { Incident } from '../../components/Incidents/Incidents';

export type CommentPayload = Omit<
  Comment,
  'id' | 'createdAt' | 'fromIncident' | 'incidentName' | 'incidentResolvedAt'
>;
type CommentFromDB = Omit<Comment, 'fromIncident' | 'incidentName'>;
type UseCommentsResponse = {
  user: User;
  comments: Comment[];
  createComment: (payload: CommentPayload) => Promise<void>;
  deleteComment: (commentId: string, userEmail: string) => Promise<void>;
  loadingCreateComment: boolean;
  errorComment: {
    creating: boolean;
    fetching: boolean;
    deleting: boolean;
  };
};

export default function useComments(entityId: string): UseCommentsResponse {
  const [comments, setComments] = useState<Comment[]>([]);
  const [entityIncidents, setEntityIncidents] = useState<Incident[]>([]);

  const [updater, forceUpdateComments] = useReducer((x) => x + 1, 0);

  const [loadingCreateComment, setLoadingCreateComment] = useState(false);
  const [errorComment, setErrorComment] = useState({
    creating: false,
    fetching: false,
    deleting: false,
  });

  const { getAccessTokenSilently, user } = useAuth0();

  const createComment = async (payload: CommentPayload) => {
    setLoadingCreateComment(true);
    try {
      const token = await getAccessTokenSilently();
      await postNewComment(token, payload);
    } catch (e) {
      setErrorComment((prevState) => ({
        ...prevState,
        creating: true,
      }));
    } finally {
      forceUpdateComments();
      setLoadingCreateComment(false);
    }
  };

  const deleteComment = async (commentId: string, userEmail: string) => {
    try {
      const token = await getAccessTokenSilently();
      await removeComment(token, commentId, userEmail);
    } catch (e) {
      setErrorComment((prevState) => ({
        ...prevState,
        deleting: true,
      }));
    } finally {
      forceUpdateComments();
    }
  };

  const getComments = async (id: string) => {
    const token = await getAccessTokenSilently();
    try {
      const { data } = await fetchComments(token, id);
      setComments((prev) => {
        const previousCommentsFromIncidents = prev.filter(
          (comment) => comment.fromIncident,
        );
        const newCommentsWithoutIncidents: Comment[] = data.map(
          (c: CommentFromDB) =>
            ({
              ...c,
              fromIncident: false,
              incidentName: null,
              incidentResolvedAt: null,
            } as Comment),
        );

        return [
          ...previousCommentsFromIncidents,
          ...newCommentsWithoutIncidents,
        ];
      });
    } catch (e) {
      setErrorComment((prevState) => ({
        ...prevState,
        fetching: true,
      }));
    }
  };

  useEffect(() => {
    if (!entityId) return;

    const fetchAllIncidentsByEntityId = async () => {
      const token = await getAccessTokenSilently();
      const { data } = await getAllIncidentsByEntityId(token, entityId);
      setEntityIncidents(data);
    };

    fetchAllIncidentsByEntityId();
  }, [entityId]);

  useEffect(() => {
    const incidentComments = entityIncidents
      ?.filter((incident) => incident?.comment)
      ?.map((incident) => ({
        id: incident.id,
        comment: incident.comment,
        createdAt: incident.createdAt,
        createdBy: incident.createdBy,
        isPublic: true,
        isNotified: true,
        fromIncident: true,
        incidentName: incident.name,
        incidentResolvedAt: incident.resolvedAt,
      }));

    setComments((prevComments) => [
      ...prevComments,
      ...incidentComments.filter(
        (incidentComment) =>
          !prevComments
            .map((prevComment) => prevComment.id)
            .includes(incidentComment.id),
      ),
    ]);
  }, [entityIncidents]);

  useEffect(() => {
    if (!entityId) return;
    getComments(entityId);
  }, [updater, entityId]);

  return {
    user,
    comments,
    createComment,
    deleteComment,
    loadingCreateComment,
    errorComment,
  };
}

import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { CheckIcon } from '@heroicons/react/outline';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import Button from '../../../components/Button';
import { toasterContext } from '../../../context/ToasterContext/ToasterContext';
import { userInfoContext } from '../../../context/UserInfoContext/UserInfoContext';
import { getPaymentMethods } from '../../../services/stripe';
import { bateDetailsContext } from '../../../context/BateDetailsContext/bateDetailsContext';
import { STRIPE_CARD_DECLINE_CODES } from '../../../enums/stripeCardDeclineCodes';

function PresentToAgencyButton() {
  const [loading, setLoading] = useState<boolean>(false);

  const { setToasterData } = useContext(toasterContext);
  const { accountInfo } = useContext(userInfoContext);
  const { bateTramit, forceUpdate } = useContext(bateDetailsContext);

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const handleMarkBateAsReady = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();

      const { cards, sepas } = await getPaymentMethods(
        accountInfo.organizationId,
        token,
      );
      const method = cards[0] || sepas[0];
      if (!method) {
        setToasterData({
          type: 'ERROR',
          title: 'No hay ningún método de pago',
          message:
            'Debes añadir un método de pago para poder presentar a gestoría',
        });
        navigate('/payment-method');
        return;
      }
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/v1/internal/bate/${bateTramit.bate.bateCode}/ready`,
        {
          orgId: accountInfo.organizationId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      forceUpdate();
    } catch (error) {
      const possibleStripeError =
        STRIPE_CARD_DECLINE_CODES[
          error.response?.data?.error as keyof typeof STRIPE_CARD_DECLINE_CODES
        ];
      if (possibleStripeError) {
        setToasterData({
          type: 'ERROR',
          title: possibleStripeError.title,
          message: possibleStripeError.nextSteps,
        });
      } else {
        setToasterData({
          type: 'ERROR',
          title: 'Ha ocurrido un error al presentar a gestoría',
          message: 'Revisa los datos del trámite o contacta con nosotros.',
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Tooltip
        id="present-bate-button"
        style={{
          marginTop: '0',
          fontSize: '15px',
          zIndex: '11',
        }}
        content="Presentar únicamente la baja por entrega a compraventa"
        place="top"
      />
      <Button
        text="Presentar"
        bgColor="bg-blue-700"
        hoverBgColor="bg-blue-500"
        textColor="white"
        border="border-2"
        RightIcon={CheckIcon}
        textSm
        callback={handleMarkBateAsReady}
        loading={loading}
        dataTooltipId="present-bate-button"
        additionalClasses="whitespace-nowrap"
      />
    </>
  );
}

export default PresentToAgencyButton;

import React from 'react';
import StepCircle from './StepCircle';

export default function Timeline({
  currentStep,
  totalSteps,
}: {
  currentStep: number;
  totalSteps: number;
}): JSX.Element {
  return (
    <div className="flex -ml-[140px]" data-testid="timeline">
      <StepCircle currentStep={currentStep} thisStep={1} />
      <div className="w-[80px] h-[1px] border border-blue-600 self-center" />
      <StepCircle currentStep={currentStep} thisStep={2} />
      {totalSteps === 3 && (
        <div className="w-[80px] h-[1px] border border-blue-600 self-center" />
      )}
      {totalSteps === 3 && (
        <StepCircle currentStep={currentStep} thisStep={3} />
      )}
    </div>
  );
}

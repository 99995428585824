import AWS from '../services/aws';
import { hashFileName } from '../helpers/hashFileName';

const bucketName = 'swipoo-transactions-files';

const s3 = new AWS.S3({
  params: {
    Bucket: bucketName,
  },
  region: 'eu-west-1',
});

export default function useUploadFile(): {
  uploadFile: (file: File) => Promise<string>;
  getS3SignedUrl: (objectKey: string) => Promise<string>;
} {
  const uploadFile = async (file: File): Promise<string> => {
    const keyName = hashFileName(file.name);
    const isPdf = file.name?.includes('.pdf') || file.name?.includes('.PDF');
    await s3
      .putObject({
        ACL: 'public-read',
        Body: file,
        Bucket: bucketName,
        Key: keyName,
        Tagging: 'type=pro',
        ContentType: isPdf ? 'application/pdf' : file.type,
      })
      .promise();
    return keyName;
  };

  const getS3SignedUrl = async (objectKey: string): Promise<string> => {
    try {
      return s3.getSignedUrlPromise('getObject', {
        Bucket: bucketName,
        Key: objectKey,
      });
    } catch (e) {
      return '';
    }
  };

  return {
    uploadFile,
    getS3SignedUrl,
  };
}

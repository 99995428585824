import ShipmentItemsInTable from './Shipments/components/ShipmentItemsInTable';
import {
  ShipmentTableHeaders,
  shipmentStatusDictionary,
} from './Shipments/components/ShipmentsTable';

export default function ShipmentTableByOTN({ shipment }) {
  return (
    <div>
      <div className="w-full mt-4 border rounded-lg shadow-sm">
        <ShipmentTableHeaders />
        <div
          className="flex items-center pb-2 pt-2 pr-2 text-gray-500 text-xs hover:bg-gray-100 transition-colors border-t bg-white"
          tabIndex={0}
          role="button"
        >
          <div
            className={`flex items-center justify-center w-[30%] text-center ${
              shipment.status === 'CANCELED' && 'text-red-400'
            }`}
          >
            {shipmentStatusDictionary.get(shipment.status)}
          </div>
          <div className="flex items-center justify-center w-[30%] text-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-500 flex items-center justify-center"
              href={
                shipment.otn
                  ? `https://swipoo.outvio.com/es/id/${shipment.otn}`
                  : '#'
              }
            >
              {shipment.otn}
            </a>
          </div>
          <div className="w-[30%] flex items-start flex-col gap-y-0 pl-8">
            <div className="block">
              <ShipmentItemsInTable items={shipment.items} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { PaperClipIcon } from '@heroicons/react/outline';
import FileLink from '../../../../../components/FileLink';

export default function Document({ title, documentKey, file, setModalType }) {
  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex items-center">
        <PaperClipIcon
          className="shrink-0 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <span className="ml-2 flex-1 w-0 truncate">{title}</span>
      </div>
      <div className="ml-4 shrink-0 flex space-x-4">
        {file ? (
          <>
            <FileLink source={file} />
            <span className="text-gray-300" aria-hidden="true">
              |
            </span>
            <button
              type="button"
              onClick={() => setModalType(documentKey)}
              className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
            >
              Reemplazar
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={() => setModalType(documentKey)}
            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
          >
            Añadir
          </button>
        )}
      </div>
    </li>
  );
}

import React from 'react';

function Select({
  label,
  stateName,
  options,
  value,
  handleChange,
  spaceBottom = false,
}) {
  return (
    <div
      className={`sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ${
        spaceBottom && 'mb-2 pb-2'
      }`}
    >
      <label
        htmlFor={stateName}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <select
          id={stateName}
          name={stateName}
          className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          onChange={handleChange}
          value={value}
        >
          <option disabled value="">
            {' '}
            -- seleccione una opción --{' '}
          </option>
          {options.map((option) => (
            <option key={option.key || option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default Select;

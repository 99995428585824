import { Fragment, useEffect, useState } from 'react';
import ShipmentItemsInTable from './ShipmentItemsInTable';
import {
  ERROR_SHIPMENT_STATUS,
  SHIPMENT_STATUS,
  ccaaDictionary,
} from '../../../helpers/enums';
import Spinner from '../../Spinner';
import ShipmentMenu from './ShipmentMenu';
import classNames from '../../../helpers/classNames';

export const shipmentStatusDictionary = new Map([
  [SHIPMENT_STATUS.CREATED, 'CREADO'],
  [SHIPMENT_STATUS.READY_TO_SHIP, 'OUTVIO - SIN ENVIAR'],
  [SHIPMENT_STATUS.SHIPPED, 'ENVIADO'],
  [SHIPMENT_STATUS.IN_TRANSIT, 'EN TRÁNSITO'],
  [SHIPMENT_STATUS.OUT_FOR_DELIVERY, 'EN REPARTO'],
  [SHIPMENT_STATUS.DELIVERED, 'ENTREGADO'],
  [SHIPMENT_STATUS.CANCELED, 'CANCELADO'],
  [SHIPMENT_STATUS.INCIDENT, 'INCIDENCIA'],
  [SHIPMENT_STATUS.RETURNED, 'DEVUELTO'],
  [SHIPMENT_STATUS.BUNDLED, 'AGRUPADO'],
  [SHIPMENT_STATUS.LOST, 'EXTRAVIADO'],
]);

export function ShipmentTableHeaders() {
  return (
    <div className="flex w-full pb-2 border-b bg-[#F9FBFB] pt-2">
      <div className="w-[20%] text-center text-sm font-semibold text-gray-900">
        Status
      </div>
      <div className="w-[30%] text-center text-sm font-semibold text-gray-900">
        OTN
      </div>
      <div className="w-[35%] text-center text-sm font-semibold text-gray-900">
        Items
      </div>
    </div>
  );
}

export default function ShipmentsTable({
  shipments,
  areRolesComplete,
  setShowDeliveryInfoModal,
  forceUpdate,
  setEditedShipment,
  isDocumentOnOffice,
  entityType,
}) {
  const [orderCreationLoading, setOrderCreationLoading] = useState(false);
  const [shipError, setShipError] = useState('');
  const [expandedShipmentId, setExpandedShipmentId] = useState('');
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const handleExpandShipment = (id) => {
    if ([0, 1].includes(shipments.length)) return;

    if (expandedShipmentId === id) {
      setExpandedShipmentId('');
      return;
    }

    setExpandedShipmentId(id);
  };

  useEffect(() => {
    if (shipments.length === 1) {
      setExpandedShipmentId(shipments[0].id);
    }
  }, [shipments]);

  return (
    <div>
      {shipError && <p className="text-red-500">{shipError}</p>}

      <div className="w-full mt-4 border rounded-lg shadow-sm">
        <ShipmentTableHeaders />
        {shipments.map((s) => (
          <Fragment key={s.id}>
            <div
              className={`flex items-center p-2 text-gray-500 text-xs hover:bg-gray-100 transition-colors ${
                s.id === expandedShipmentId && shipments.length > 1
                  ? 'border-t bg-gray-50'
                  : 'border-b border-b-gray-200'
              }`}
              onClick={() => handleExpandShipment(s.id)}
              onKeyDown={() => handleExpandShipment(s.id)}
              tabIndex={0}
              role="button"
            >
              <div
                className={classNames(
                  'flex items-center justify-center w-[20%] max-w-[20%] text-center break-words',
                  ERROR_SHIPMENT_STATUS.includes(s.status) && 'text-red-400',
                )}
              >
                {shipmentStatusDictionary.get(s.status)}
              </div>
              <div className="flex items-center justify-center w-[30%] text-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-500 flex items-center justify-center"
                  href={
                    s.otn ? `https://swipoo.outvio.com/es/id/${s.otn}` : '#'
                  }
                >
                  {s.otn}
                </a>
              </div>
              <div className="w-[45%] flex items-center flex-col gap-y-0 pl-8">
                <div className="block">
                  <ShipmentItemsInTable
                    items={s.items}
                    entityType={entityType}
                  />
                </div>
              </div>
              <ShipmentMenu
                setIsCancelLoading={setIsCancelLoading}
                setShipError={setShipError}
                forceUpdate={forceUpdate}
                areRolesComplete={areRolesComplete}
                setShowDeliveryInfoModal={setShowDeliveryInfoModal}
                setEditedShipment={setEditedShipment}
                shipment={s}
                orderCreationLoading={orderCreationLoading}
                setOrderCreationLoading={setOrderCreationLoading}
                isDocumentOnOffice={isDocumentOnOffice}
              />
              {(isCancelLoading || orderCreationLoading) &&
                s.status !== SHIPMENT_STATUS.CANCELED && (
                  <Spinner color="text-gray-500" size={4} marginTop={0} />
                )}
            </div>

            {s.id === expandedShipmentId && (
              <div
                className={`flex w-full justify-between px-16 pt-4 text-xs pb-4 ${
                  shipments.length > 1 && 'bg-gray-50 border-b'
                }`}
              >
                <div className="flex flex-col">
                  <div className="flex flex-col gap-y-2">
                    <div>
                      <p className="text-gray-500">Comunidad Autónoma</p>
                      <p>{ccaaDictionary[s.delivery.state] || '-'}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">Ciudad</p>
                      <p>{s.delivery.city || '-'}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">Código Postal</p>
                      <p>{s.delivery.zipCode || '-'}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">Dirección</p>
                      <p>{s.delivery.street || '-'}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col gap-y-2">
                    <div>
                      <p className="text-gray-500">Nombre</p>
                      <p>{s.receiver.name || '-'}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">DNI</p>
                      <p>{s.receiver.doi || '-'}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">Email</p>
                      <p>{s.receiver.email || '-'}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">Teléfono</p>
                      <p>{s.receiver.phone || '-'}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

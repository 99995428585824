import React, { useContext } from 'react';
import TransferType from '../../steps/TransferType/TransferType';
import CounterpartType from '../../steps/CounterpartType/CounterpartType';
import LinkBate from '../../steps/LinkBate/LinkBate';
import { createTransferContext } from '../../../../context/CreateTransferContext/CreateTransferContext';

export default function Stepper(): JSX.Element {
  const { currentStep, formValue } = useContext(createTransferContext);

  const renderStep = (): React.ReactElement => {
    if (formValue.userType === 'SELLER_WITH_TEMP_LEAVE' && currentStep === 3) {
      return <LinkBate />;
    }
    if (currentStep === 1) return <TransferType />;
    if (currentStep === 2) return <CounterpartType />;
    return null;
  };

  return <div className="px-8 pt-8 pb-2 min-h-[400px]">{renderStep()}</div>;
}

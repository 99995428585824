import { useEffect, useState } from 'react';
import { CreditCardIcon } from '@heroicons/react/outline';

import { VisaLogo } from './logoBrands/visa';
import { SepaLogo } from './logoBrands/sepa';
import { AmexLogo } from './logoBrands/amex';
import { MasterCardLogo } from './logoBrands/mastercard';
import { useServices } from '../../../../../services';
import Spinner from '../../../../../components/Spinner';
import NewManualPaymentModal from './NewManualPaymentModal';

const cardInfoLogo = (card) => {
  switch (card.brand) {
    case 'visa':
      return <VisaLogo />;
    case 'amex':
      return <AmexLogo />;
    case 'mastercard':
      return <MasterCardLogo />;
    default:
      return <CreditCardIcon width="36" height="24" />;
  }
};

const sepaInfo = (sepaDebit) => (
  <div className="sm:flex sm:items-start">
    <SepaLogo />
    <div className="mt-3 sm:ml-4 sm:mt-0">
      <div className="text-sm font-medium text-gray-900">
        Acabda en {sepaDebit?.last4}
      </div>
      <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
        <div>
          {sepaDebit.country}
          {sepaDebit.bank_code}
          {sepaDebit.branch_code}
        </div>
      </div>
    </div>
  </div>
);

export default function PaymentMethodModule({ account }) {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewManualPaymentModalOpen, setIsNewManualPaymentModalOpen] =
    useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);

  const { getPaymentInfo } = useServices();

  useEffect(() => {
    if (!account.organizationId) return;
    (async () => {
      try {
        setError(false);
        setIsLoading(true);
        const [cards, sepas] = await Promise.all([
          getPaymentInfo(account.organizationId, 'card'),
          getPaymentInfo(account.organizationId, 'sepa_debit'),
        ]);
        setPaymentMethods([...cards.data, ...sepas.data]);
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [account.organizationId]);

  const handleSelectPaymentMethod = (paymentMethodId) => {
    setSelectedPaymentMethodId(paymentMethodId);
    setIsNewManualPaymentModalOpen(true);
  };

  return (
    <>
      <NewManualPaymentModal
        accountId={account.id}
        isOpen={isNewManualPaymentModalOpen}
        setIsOpen={setIsNewManualPaymentModalOpen}
        paymentMethodId={selectedPaymentMethodId}
      />
      <h3 className="text-base font-semibold leading-6 text-gray-900 mb-3">
        Métodos de pago
      </h3>
      {paymentMethods.map((pm) => (
        <div className="mt-3" key={pm.id}>
          <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
            {pm.type === 'card' && (
              <div className="sm:flex sm:items-start">
                {cardInfoLogo(pm.card)}
                <div className="mt-3 sm:ml-4 sm:mt-0">
                  <div className="text-sm font-medium text-gray-900">
                    Acabda en {pm.card?.last4}
                  </div>
                  <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                    <div>
                      Caduca {pm.card?.exp_month}/{pm.card?.exp_year}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {pm.type === 'sepa_debit' && sepaInfo(pm.sepa_debit)}
            <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
              <button
                type="button"
                onClick={() => handleSelectPaymentMethod(pm.id)}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Pago
              </button>
            </div>
          </div>
        </div>
      ))}

      {isLoading && (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner color="text-blue-700" size={10} />
        </div>
      )}

      {error && (
        <p className="mb-3">
          Hubo un error al cargar el método de pago. Por favor, intenta de
          nuevo.
        </p>
      )}

      {!paymentMethods.length && !isLoading && !error && (
        <p className="mb-3">
          {account?.name} no tiene un método de pago configurado.
        </p>
      )}
    </>
  );
}

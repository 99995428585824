import { CheckIcon } from '@heroicons/react/outline';
import React from 'react';
import {
  statusDictionary,
  STEP_GROUP_KEY,
  SWIPOO_STATUS,
  TRANSFER_STATUS,
} from '../../../../../../../shared/transaction-status';

const statusToMarkGroupAsDone: {
  [key in STEP_GROUP_KEY]: SWIPOO_STATUS[];
} = {
  [STEP_GROUP_KEY.NOT_SUBMITTED]: [SWIPOO_STATUS.PENDING_DOCUMENTATION],
  [STEP_GROUP_KEY.SUBMITTED_TO_AGENCY]: [
    SWIPOO_STATUS.PRESENTED_TO_AGENCY,
    SWIPOO_STATUS.REVIEW_PENDING_DOCUMENTATION,
  ],
  [STEP_GROUP_KEY.SUBMITTED_TO_DGT]: [SWIPOO_STATUS.PRESENTED_TO_DGT],
  [STEP_GROUP_KEY.FINISHED_BY_DGT]: [
    SWIPOO_STATUS.FINISHED_BY_DGT,
    SWIPOO_STATUS.CIRCULATION_PERMIT_AT_OFFICE,
    SWIPOO_STATUS.CIRCULATION_PERMIT_SENT,
    SWIPOO_STATUS.CIRCULATION_PERMIT_DELIVERED,
  ],
};

export default function Step({
  status,
  title,
  stepNumber,
  stepGroup,
}: {
  status: number;
  title: string;
  stepNumber: number;
  stepGroup: STEP_GROUP_KEY;
}) {
  const stepGroupKeys = Object.keys(TRANSFER_STATUS);

  const statusIndex = Object.keys(SWIPOO_STATUS).indexOf(
    statusDictionary[status],
  );
  const currentStatus = Object.values(SWIPOO_STATUS)[
    statusIndex
  ] as SWIPOO_STATUS;

  const thisStepGroupIndex = stepGroupKeys.findIndex(
    (key) => key === stepGroup,
  );

  const currentStatusStepGroupIndex = stepGroupKeys.findIndex((key) =>
    TRANSFER_STATUS[key].includes(status),
  );

  const stepGroupIncludesStatus =
    TRANSFER_STATUS[stepGroup].includes(currentStatus);

  const hasToBeMarkedAsDone =
    statusToMarkGroupAsDone[stepGroup].includes(currentStatus);

  const pastGroup = thisStepGroupIndex < currentStatusStepGroupIndex;

  const futureGroup = thisStepGroupIndex > currentStatusStepGroupIndex;

  if (stepGroupIncludesStatus && !hasToBeMarkedAsDone) {
    return (
      <div className="flex items-center justify-center gap-x-4 w-1/3">
        <div className="border-2 border-emerald-600 w-10 h-10 rounded-full flex items-center justify-center">
          <span className="text-emerald-600">0{stepNumber}</span>
        </div>
        <p className="text-emerald-600 text-sm">{title}</p>
      </div>
    );
  }

  if (
    (!stepGroupIncludesStatus && pastGroup) ||
    (stepGroupIncludesStatus && hasToBeMarkedAsDone)
  ) {
    return (
      <div className="flex items-center justify-center gap-x-4 w-1/3">
        <div className="bg-emerald-600 w-10 h-10 rounded-full flex items-center justify-center">
          <CheckIcon className="text-white w-6 h-6" data-testid="icon" />
        </div>
        <p className="text-sm">{title}</p>
      </div>
    );
  }

  if (!stepGroupIncludesStatus && futureGroup) {
    return (
      <div className="flex items-center justify-center gap-x-4 w-1/3">
        <div className="border-2 border-gray-300 w-10 h-10 rounded-full flex items-center justify-center">
          <span className="text-gray-500">0{stepNumber}</span>
        </div>
        <p className="text-gray-500 text-sm">{title}</p>
      </div>
    );
  }
}

import React from 'react';
import DateRangePicker from '../../../components/TypeformComponents/DateRangePicker';
import ActiveFilters from '../../../components/TypeformComponents/ActiveFilters';
import useDatePicker from '../../../hooks/useDatePicker';

function InvoiceFilters() {
  const { date, setDate } = useDatePicker();

  return (
    <div className="flex gap-4 items-center">
      <DateRangePicker date={date} setDate={setDate} />
      <ActiveFilters date={date} setDate={setDate} />
    </div>
  );
}

export default InvoiceFilters;

import { useCallback, useEffect, useReducer, useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';

import Spinner from '../../../../../components/Spinner';
import { ccaaDictionary } from '../../../../../helpers/enums';
import { useServices } from '../../../../../services';
import CompleteAddressModal from './CompleteAddressModal';
import classNames from '../../../../../helpers/classNames';

export default function DeliveryAddressList({ accountId }) {
  const { getAllDeliveryAddress } = useServices();

  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [loader, setLoader] = useState(true);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [addressId, setAddressId] = useState('');

  const getDeliveryAddress = useCallback(async () => {
    const response = await getAllDeliveryAddress(accountId);
    setDeliveryAddress(response);

    setLoader(false);
  }, [getAllDeliveryAddress]);

  useEffect(() => {
    getDeliveryAddress();
  }, [updater]);

  if (loader) {
    return (
      <div className="w-full h-full min-h-[100px] flex justify-center items-center">
        <Spinner color="text-blue-700" size={10} marginTop={28} />
      </div>
    );
  }

  return (
    <>
      <CompleteAddressModal
        showModal={showModal}
        accountId={accountId}
        setShowModal={setShowModal}
        save={forceUpdate}
        modalType={modalType}
        addressId={addressId}
        setModalType={setModalType}
        deliveryAddress={deliveryAddress}
      />
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="flex text-md text-black font-medium border-b border-gray-100 justify-between items-center py-5 px-5">
          <h3 className="text-md text-black font-medium border-none">
            Direcciones de envío
          </h3>
          <button
            type="button"
            onClick={() => {
              setShowModal(true);
              setModalType('newDeliveryAddress');
            }}
            className="rounded-full bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            <PlusIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <ul className="divide-y divide-gray-100">
          {deliveryAddress.map((address, i) => (
            <li
              key={address.id}
              className={classNames(
                'flex justify-between py-5 px-5',
                i % 2 === 0 ? 'bg-white' : 'bg-gray-50',
              )}
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {address.referenceName}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>
                    {address.deliveryAddress}, {address.deliveryCity}.{' '}
                    {address.deliveryZipcode},{' '}
                    {ccaaDictionary[address.deliveryCCAA]} .
                  </p>
                </div>
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900 mt-4">
                    Receptor
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>
                    {address.deliveryNameSurname}, {address.deliveryDOI}.
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>Tel.: {address.deliveryPhone}.</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>Email: {address.deliveryEmail}.</p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(true);
                    setModalType('editDeliveryAddress');
                    setAddressId(address.id);
                  }}
                  className="self-end rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <PencilIcon className="h-5 text-blue-500" />
                </button>
                <button
                  type="button"
                  disbaled="true"
                  className="self-end rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <TrashIcon className="h-5 text-red-500" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

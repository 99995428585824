import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import { ContextMenuOptionType } from './ContextMenuButton';
import ContextMenuOption from './ContextMenuOption';

export default function ContextMenu({
  contextMenuOptions,
}: {
  contextMenuOptions: ContextMenuOptionType[];
}) {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute w-max left-0 top-10 z-10 mt-2 -mr-1 w-48 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {contextMenuOptions.map((op) => (
          <Menu.Item key={op.id}>
            {({ active }) => <ContextMenuOption option={op} active={active} />}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Transition>
  );
}

import { MailIcon } from '@heroicons/react/outline';
import { Tooltip } from 'react-tooltip';
import { isDeliveryDataComplete } from '../helpers/shipment.helpers';
import { useServices } from '../../../services';
import Spinner from '../../Spinner';
import classNames from '../../../helpers/classNames';

export default function ShipButton({
  shipment,
  orderCreationLoading,
  setOrderCreationLoading,
  setShipError,
  forceUpdate,
  isDocumentOnOffice,
}) {
  const { generateSingleShipment } = useServices();

  const isReadyToShip =
    isDocumentOnOffice &&
    shipment.status === 'CREATED' &&
    isDeliveryDataComplete(shipment);

  const tooltipTitles = (s) => [
    {
      content: isDeliveryDataComplete(shipment) ? '' : 'Falta completar datos',
    },
    {
      content: s.status === 'CREATED' ? '' : 'El envío no está creado',
    },
    {
      content: isDocumentOnOffice
        ? ''
        : 'El permiso todavía no está en oficina',
    },
  ];

  const handleShip = async () => {
    try {
      setOrderCreationLoading(true);
      setShipError('');
      await generateSingleShipment({ shipmentId: shipment.id });
    } catch (e) {
      setShipError('Ha ocurrido un error al generar el envío');
    } finally {
      forceUpdate();
      setOrderCreationLoading(false);
    }
  };

  return (
    <>
      <Tooltip
        id="my-tooltip-send"
        style={{
          marginTop: '0',
          fontSize: '15px',
        }}
        content={tooltipTitles(shipment).map((title) => (
          <p>{title.content}</p>
        ))}
        place="bottom"
      />
      <div
        data-tooltip-id={isReadyToShip ? undefined : 'my-tooltip-send'}
        className="w-full"
      >
        <button
          type="button"
          onClick={handleShip}
          className={classNames(
            'py-1 text-sm text-gray-700 w-full',
            isReadyToShip && 'cursor-pointer',
          )}
          disabled={!isReadyToShip}
        >
          {orderCreationLoading ? (
            <Spinner color="text-gray-500" size={4} marginTop={0} />
          ) : (
            <div className="flex gap-x-4 items-center">
              <span>Enviar</span>
              <MailIcon
                className={classNames(
                  'w-4 h-4',
                  isReadyToShip ? 'text-indigo-600' : 'text-gray-400',
                )}
              />
            </div>
          )}
        </button>
      </div>
    </>
  );
}

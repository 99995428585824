import { XIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import BaseModal from '../../../../components/BaseModal';
import DgtFeeTypeSelector from './DgtFeeTypeSelector';

export default function DgtFeeTypeInformationModal({
  showModal,
  setShowModal,
  dgtFeeTypes,
}) {
  const [selectedFeeType, setSelectedFeeType] = useState('4.1');

  const feeTypeInfo = dgtFeeTypes.find((type) => type.type === selectedFeeType);

  return (
    <BaseModal
      isOpen={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <div className="flex flex-col gap-y-2 p-6">
        <div className="flex justify-between">
          <h2 className="font-bold">Información de tipos de tasa</h2>
          <XIcon
            className="h-6 w-6 cursor-pointer"
            onClick={() => setShowModal(false)}
          />
        </div>
        <DgtFeeTypeSelector
          dgtFeeTypes={dgtFeeTypes}
          setSelectedFeeType={setSelectedFeeType}
          selectedFeeType={selectedFeeType}
          summaryLoader={null}
        />
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-2">
            <span className="font-semibold">Precio:</span>
            <span>{feeTypeInfo?.price}€</span>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">Descripción:</span>
            <span>{feeTypeInfo?.description}</span>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

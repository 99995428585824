import { useContext, useEffect, useState } from 'react';
import { queryParamsContext } from '../../context/QueryParamsContext/QueryParamsContext';

export const usePage = () => {
  const { getSingleParam, setSingleParam } = useContext(queryParamsContext);
  const pageParam = Number(getSingleParam('page'));
  const [page, setPage] = useState(Number.isNaN(pageParam) ? 0 : pageParam);

  useEffect(() => {
    setSingleParam('page', page.toString());
  }, [page]);

  return { page, setPage };
};

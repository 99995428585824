import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useServices } from '../../services';
import { classNames } from '../../helpers';
import Spinner from '../Spinner';

function NewCommentForm({
  forceCommentUpdate,
  entityId,
  tramitCode,
  tramitType,
  emailToNotify,
  showNotificationOption,
  platform,
  isAlwaysPrivate,
}) {
  const [newCommentText, setNewCommentText] = useState('');
  const [newCommentLoading, setNewCommentLoading] = useState(false);
  const [isCommentPrivate, setIsCommentPrivate] = useState(
    false || isAlwaysPrivate,
  );
  const [sendNotification, setSendNotification] = useState(false);

  const { user } = useAuth0();
  const { createComment } = useServices();

  const toggleCommentPrivacy = () => {
    setIsCommentPrivate((prev) => !prev);
  };

  const toggleSendNotification = () => {
    setSendNotification((prev) => !prev);
  };

  const submitComment = async (e) => {
    e.preventDefault();
    setNewCommentLoading(true);
    const payload = {
      createdBy: user.email,
      comment: newCommentText,
      isPublic: !isCommentPrivate,
      sendNotification,
      entityId,
      emailToNotify,
      tramitCode,
      tramitType,
      platform: platform.toUpperCase(),
    };
    await createComment(payload);
    forceCommentUpdate();
    setNewCommentText('');
    setNewCommentLoading(false);
    setIsCommentPrivate(false);
    setSendNotification(false);
  };

  useEffect(() => {
    if (!isCommentPrivate) return;
    setSendNotification(false);
  }, [isCommentPrivate]);

  useEffect(() => {
    if (!sendNotification) return;
    setIsCommentPrivate(false);
  }, [sendNotification]);

  return (
    <div className="bg-gray-50 px-4 py-6 sm:px-6">
      <div className="flex space-x-3">
        <div className="min-w-0 flex-1">
          <form onSubmit={submitComment}>
            <div>
              <label htmlFor="comment" className="sr-only">
                Comentario
              </label>
              <textarea
                id="comment"
                name="comment"
                rows={3}
                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                placeholder="Añadir comentario"
                value={newCommentText}
                onChange={(e) => setNewCommentText(e.target.value)}
              />
            </div>
            <div className="mt-3 flex items-center justify-between">
              {!isAlwaysPrivate && (
                <div className="flex items-center mt-2">
                  <input
                    checked={isCommentPrivate}
                    type="checkbox"
                    name="commentPrivate"
                    id="commentPrivate"
                    onChange={toggleCommentPrivacy}
                    disabled={sendNotification}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-3"
                  />
                  <label
                    className={classNames(
                      'text-sm',
                      sendNotification && 'text-gray-500',
                    )}
                    htmlFor="commentPrivate"
                  >
                    Comentario privado
                  </label>
                </div>
              )}
              {showNotificationOption && (
                <div className="flex items-center mt-2">
                  <input
                    checked={sendNotification}
                    type="checkbox"
                    name="send-notification"
                    id="send-notification"
                    onChange={toggleSendNotification}
                    disabled={isCommentPrivate}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-3"
                  />
                  <label
                    className={classNames(
                      'text-sm',
                      isCommentPrivate && 'text-gray-500',
                    )}
                    htmlFor="send-notification"
                  >
                    Enviar notificación
                  </label>
                </div>
              )}
              <button
                type="submit"
                className={classNames(
                  newCommentText
                    ? 'bg-blue-600 hover:bg-blue-700'
                    : 'bg-blue-300',
                  'inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
                )}
                disabled={!newCommentText || newCommentLoading}
              >
                {newCommentLoading && (
                  <Spinner color="text-white" size={5} marginTop={0} />
                )}
                Añadir
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewCommentForm;

import React, { useState, useEffect } from 'react';
import { z } from 'zod';
import { STEPS } from './enums/enums';
import SelectAddress from './components/steps/SelectAddress';
import SelectShipments from './components/steps/SelectShipments';
import { generateValidationError, getName } from './helpers/helpers';

const deliveryAddressSchema = z.object({
  name: z.string().min(3),
  dni: z.string().length(9),
  postcode: z.string().length(5),
  state: z.string(),
  city: z.string(),
  address: z.string(),
  email: z.string().email(),
  phone: z.string().refine(
    (value) => {
      const phoneRegex = /^(\+\d{1,3})?\d{9}$/;
      return phoneRegex.test(value);
    },
    {
      message:
        'Número de teléfono inválido. Debe de ser un número de 9 digitos con código de país opcional sin espacios (ej: +34694627391 o 694627391).',
    },
  ),
});

function ShipmentBatcher({ account }) {
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [currentStep, setCurrentStep] = useState(STEPS.SELECT_SHIPMENTS);
  const [deliveryAddresses, setDeliveryAddresses] = useState({
    data: [],
    loading: false,
  });
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);

  const resetState = () => {
    setSelectedShipments([]);
    setCurrentStep(STEPS.SELECT_SHIPMENTS);
    setError('');
    setDeliveryAddresses({ data: [], loading: false });
    setSelectedDeliveryAddress(null);
  };

  const getFinalAddress = () => {
    if (selectedDeliveryAddress) {
      const selectedDeliveryAddressData = deliveryAddresses.data.find(
        (address) => address.id === selectedDeliveryAddress,
      );
      return {
        name: selectedDeliveryAddressData.deliveryNameSurname,
        dni: selectedDeliveryAddressData.deliveryDOI,
        postcode: selectedDeliveryAddressData.deliveryZipcode,
        state: selectedDeliveryAddressData.deliveryCCAA,
        city: selectedDeliveryAddressData.deliveryCity,
        address: selectedDeliveryAddressData.deliveryAddress,
        email: selectedDeliveryAddressData.deliveryEmail,
        phone: selectedDeliveryAddressData.deliveryPhone,
      };
    }
    return {
      name: getName(
        account.representative.name,
        account.representative.surname,
      ),
      dni: account.representative.dni,
      postcode: account.fiscalAddressZipCode,
      state: account.fiscalAddressState,
      city: account.fiscalAddressCity,
      address: account.fiscalAddressAddress,
      email: account.representative.email,
      phone: account.representative.phone,
    };
  };

  const validateAddress = () => {
    const deliveryAddress = getFinalAddress();

    deliveryAddressSchema.parse(deliveryAddress);

    return deliveryAddress;
  };

  useEffect(() => {
    setError('');

    if (currentStep !== STEPS.SELECT_ADDRESS && !account.id) return;
    if (!deliveryAddresses.data?.length) return;
    if (deliveryAddresses.data.length && !selectedDeliveryAddress) return;

    try {
      validateAddress();
    } catch (e) {
      if (e.issues) {
        setError(generateValidationError(e.issues));
        return;
      }

      setError('Ha ocurrido un error inesperado');
    }
  }, [currentStep, deliveryAddresses.data, selectedDeliveryAddress]);

  return (
    <div className="flex flex-col gap-6 px-8 py-4 pb-4 mt-5 rounded-lg shadow w-full h-[fit-content]">
      {currentStep === STEPS.SELECT_SHIPMENTS && (
        <SelectShipments
          selectedShipments={selectedShipments}
          setSelectedShipments={setSelectedShipments}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          account={account}
          deliveryAddresses={deliveryAddresses}
          error={error}
          setError={setError}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          setDeliveryAddresses={setDeliveryAddresses}
        />
      )}
      {currentStep === STEPS.SELECT_ADDRESS && (
        <SelectAddress
          selectedShimpents={selectedShipments}
          selectedDeliveryAddress={selectedDeliveryAddress}
          error={error}
          account={account}
          deliveryAddresses={deliveryAddresses}
          setSelectedDeliveryAddress={setSelectedDeliveryAddress}
          setSuccessMessage={setSuccessMessage}
          setError={setError}
          resetState={resetState}
          validateAddress={validateAddress}
        />
      )}
    </div>
  );
}

export default ShipmentBatcher;

/* eslint-disable no-shadow */

export enum REGISTRATION_STATUS {
  PENDING_DOCUMENTATION = 'PENDING_DOCUMENTATION',
  REVIEW_PENDING_DOCUMENTATION = 'REVIEW_PENDING_DOCUMENTATION',
  PRESENTED_TO_AGENCY = 'PRESENTED_TO_AGENCY',
  PRESENTED_TO_DGT = 'PRESENTED_TO_DGT',
  FINISHED_BY_DGT = 'FINISHED_BY_DGT',
  CANCELED = 'CANCELED',
  DOCS_AT_OFFICE = 'DOCS_AT_OFFICE',
  DOCS_SENT = 'DOCS_SENT',
  DOCS_DELIVERED = 'DOCS_DELIVERED',
}

export const REGISTRATION_STATUS_PRO: { [key: string]: REGISTRATION_STATUS[] } =
  {
    NOT_SUBMITTED: [REGISTRATION_STATUS.PENDING_DOCUMENTATION],
    SUBMITTED_TO_AGENCY: [
      REGISTRATION_STATUS.REVIEW_PENDING_DOCUMENTATION,
      REGISTRATION_STATUS.PRESENTED_TO_AGENCY,
    ],
    SUBMITTED_TO_DGT: [REGISTRATION_STATUS.PRESENTED_TO_DGT],
    FINISHED_BY_DGT: [
      REGISTRATION_STATUS.FINISHED_BY_DGT,
      REGISTRATION_STATUS.DOCS_AT_OFFICE,
      REGISTRATION_STATUS.DOCS_SENT,
      REGISTRATION_STATUS.DOCS_DELIVERED,
    ],
  };

export const SHIPMENT_STATUS = {
  SENT: [REGISTRATION_STATUS.DOCS_SENT],
  DELIVERED: [REGISTRATION_STATUS.DOCS_DELIVERED],
};

import React, { useContext } from 'react';
import { BiErrorCircle } from 'react-icons/bi';
import 'mapbox-gl/dist/mapbox-gl.css';

import Tracking from './components/Tracking/Tracking';
import MapComponent from './components/Map/Map';
import DeliveryInfo from './components/DeliveryInfo/DeliveryInfo';
import Spinner from '../../components/Spinner';
import { shipmentsContext } from '../../context/ShipmentsContext/shipmentsContext';

export const statusDictionary = new Map([
  ['CREATED', 'CREADO'],
  ['READY_TO_SHIP', 'OUTVIO - SIN ENVIAR'],
  ['SHIPPED', 'ENVIADO'],
  ['IN_TRANSIT', 'EN TRÁNSITO'],
  ['OUT_FOR_DELIVERY', 'EN REPARTO'],
  ['DELIVERED', 'ENTREGADO'],
  ['CANCELED', 'CANCELADO'],
  ['INCIDENT', 'INCIDENCIA'],
  ['RETURNED', 'DEVUELTO'],
  ['BUNDLED', 'AGRUPADO'],
  ['LOST', 'EXTRAVIADO'],
]);

export type TrackingStep = { date: string; status: string };

export default function Shipments() {
  const { shipments, getShipmentError, isShipmentLoading } =
    useContext(shipmentsContext);

  return (
    <div className="flex flex-col pt-4 gap-y-2">
      {isShipmentLoading && (
        <div className="flex justify-center items-center w-full h-96">
          <Spinner color="text-blue-700" size={16} marginTop={0} />
        </div>
      )}
      {getShipmentError && (
        <div className="flex flex-col items-center justify-center">
          <BiErrorCircle className="w-8 h-8 text-red-500" />
          <p className="text-red-500">Ocurrió un error al cargar el envío</p>
        </div>
      )}
      {!isShipmentLoading && !getShipmentError && (
        <>
          <div className="flex justify-between gap-x-2 h-full">
            <div className="w-1/2 h-[434px] border border-gray-300 rounded-md">
              <MapComponent shipment={shipments?.[0]} />
            </div>
            <div className="w-1/2 h-full">
              <Tracking
                shipment={shipments?.[0]}
                isLoading={isShipmentLoading}
              />
            </div>
          </div>
          <DeliveryInfo shipment={shipments?.[0]} />
        </>
      )}
    </div>
  );
}

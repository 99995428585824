/* eslint-disable no-unsafe-optional-chaining */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Spinner from '../../../../components/Spinner';
import { transferDetailsContext } from '../../../../context/transfer-details.context';
import useClickOutside from '../../../../hooks/useClickOutside';
import { useServices } from '../../../../services';
import { classNames } from '../../../../helpers';

function RefundModal({ isOpen, setIsOpen }) {
  const { getHoldedInvoice, generateHoldedRefund, checkStripeRefund } =
    useServices();
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceItemsAdjusted, setInvoiceItemsAdjusted] = useState([]);
  const [isStripeRefundAvailable, setIsStripeRefundAvailable] = useState(false);
  const [error, setError] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { transfer } = useContext(transferDetailsContext);
  const { transaction } = transfer;

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          const { invoiceData: holdedInvoice } = await getHoldedInvoice(
            transaction?.holdedInvoiceId,
          );
          const mappedItems = holdedInvoice.products.map((product) => ({
            name: product.name,
            subtotal: product.price,
            tax: product.tax,
            account: product.account,
          }));
          setInvoiceItems(JSON.parse(JSON.stringify(mappedItems)));
          setInvoiceItemsAdjusted(
            JSON.parse(
              JSON.stringify(mappedItems.map((e) => ({ ...e, subtotal: 0 }))),
            ),
          );
          const stripeRefundConsultResponse = await checkStripeRefund(
            transaction?.transactionCode,
          );
          setIsStripeRefundAvailable(Boolean(stripeRefundConsultResponse));
        } catch (e) {
          setError(e.message);
        }
      })();
    }
  }, [isOpen]);

  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setIsOpen(false));

  const handleGenerateRefund = async () => {
    try {
      setIsLoading(true);
      await generateHoldedRefund(
        transaction?.transactionCode,
        invoiceItemsAdjusted.filter((e) => e.subtotal > 0),
      );
      setIsLoading(false);
      setIsOpen(false);
    } catch (e) {
      setIsLoading(false);
      setError(e.message || 'No se ha podido generar el reembolso');
    }
  };

  const totalRefund =
    invoiceItemsAdjusted.length > 0
      ? invoiceItemsAdjusted
          .map((item) => item.subtotal.toFixed(2))
          .reduce((a, b) => (Number(a) + Number(b)).toFixed(2))
      : '0.00';

  return isOpen ? (
    <div className="fixed left-0 top-0 w-screen h-screen bg-black z-30 bg-opacity-75 flex justify-center items-center">
      <div
        ref={modalRef}
        className="3xl:w-2/3 xl:w-8/12 w-10/12 bg-white rounded-md shadow-md p-10"
      >
        {!confirm && (
          <>
            <h3 className="text-2xl font-medium">Generar Reembolso</h3>
            <div className="grid grid-cols-10 gap-x-3 gap-y-3 items-center mt-6">
              <h3 className="col-span-3 text-lg font-bold">
                Nombre del producto
              </h3>
              <h3 className="col-span-2 text-lg font-bold">Total facturado</h3>
              <h3 className="col-span-2 text-lg font-bold">
                A devolver (sin IVA)
              </h3>
              <div className="col-span-1" />
              <h3 className="col-span-2 text-lg font-bold">
                A devolver (con IVA)
              </h3>

              {invoiceItems.map((item, index) => (
                <React.Fragment key={item.name}>
                  <h4 className="col-span-3">{item.name}</h4>
                  <p className="col-span-2">
                    {(item.subtotal * (1 + item.tax / 100)).toFixed(2)}€
                  </p>
                  <input
                    className="col-span-2 border-2 rounded-md flex-grow"
                    type="number"
                    min="0"
                    step="0.01"
                    value={invoiceItemsAdjusted[index]?.subtotal.toFixed(2)}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const newInvoiceItemsAdjusted = [...invoiceItemsAdjusted];
                      newInvoiceItemsAdjusted[index].subtotal =
                        Number(newValue);
                      setInvoiceItemsAdjusted(newInvoiceItemsAdjusted);
                    }}
                    max={item.subtotal.toFixed(2)}
                  />
                  <button
                    type="button"
                    className="bg-indigo-500 text-white rounded-md px-2 py-1 hover:bg-indigo-600 w-16"
                    onClick={() => {
                      const newInvoiceItemsAdjusted = [...invoiceItemsAdjusted];
                      newInvoiceItemsAdjusted[index].subtotal = Number(
                        item.subtotal,
                      );
                      setInvoiceItemsAdjusted(newInvoiceItemsAdjusted);
                    }}
                  >
                    Máx.
                  </button>
                  <p className="col-span-2">
                    {(
                      invoiceItemsAdjusted[index]?.subtotal *
                      (1 + item.tax / 100)
                    ).toFixed(2)}
                    €
                  </p>
                </React.Fragment>
              ))}
              <p className="col-start-5 text2xl font-medium mt-4">
                Total a reembolsar:{' '}
              </p>
              <p className="text-xl font-medium mt-4 col-span-2">
                {totalRefund}€ (sin IVA)
              </p>
              <p className="text-xl font-medium mt-4 col-start-9 col-span-2">
                {invoiceItemsAdjusted.length > 0 &&
                  invoiceItemsAdjusted
                    .map((item) =>
                      (item.subtotal * (1 + item.tax / 100)).toFixed(2),
                    )
                    .reduce((a, b) => (Number(a) + Number(b)).toFixed(2))}
                € (con IVA)
              </p>
            </div>

            <div className="flex gap-5 items-center">
              <button
                type="button"
                onClick={() => setConfirm(true)}
                className={classNames(
                  'bg-indigo-500 px-2 py-1 rounded-md text-white hover:bg-indigo-600',
                  !Number(totalRefund) && 'opacity-50 cursor-not-allowed',
                )}
                disabled={!Number(totalRefund)}
              >
                Generar reembolso
              </button>
              {!isStripeRefundAvailable && (
                <p className="text-red-500 bg-red-100 border border-red-500 p-3">
                  No se han encontrado cargos reembolsables en Stripe del
                  intermediario de esta transacción, por lo que solo se generará
                  reembolso en Holded. Recuerda realizar el reembolso de Stripe
                  manualmente desde su plataforma.
                </p>
              )}
            </div>
          </>
        )}
        {confirm && (
          <div className="flex flex-col items-center justify-center gap-y-4">
            <p>¿Está seguro que desea generar el reembolso?</p>
            <div className="flex items-center justify-center gap-x-4">
              <button
                className="bg-red-500 px-2 py-1 rounded-md text-white hover:bg-indigo-600"
                type="button"
                onClick={() => setConfirm(false)}
              >
                Cancelar
              </button>
              <button
                className={`bg-indigo-500 px-2 py-1 rounded-md text-white hover:bg-indigo-600 flex gap-2${
                  isLoading ? ' opacity-50 cursor-not-allowed' : ''
                }`}
                type="button"
                onClick={handleGenerateRefund}
                disabled={isLoading}
              >
                {isLoading ? 'Generando...' : 'Confirmar'}
                {isLoading && <Spinner color="white" size={5} marginTop={0} />}
              </button>
            </div>
            {error && <p className="text-red-500 text-md">{error}</p>}
          </div>
        )}
      </div>
    </div>
  ) : null;
}

export default RefundModal;

import React from 'react';
import { VehicleForTable } from '../..';
import Table, { Column } from '../../../components/TypeformComponents/Table';

export default function VehicleTable({
  vehicles,
  columns,
}: {
  vehicles: VehicleForTable[];
  columns: Column[];
}) {
  return (
    <Table
      columns={columns}
      data={vehicles}
      dateSort="DESC"
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setSingleParam={() => {}}
    />
  );
}

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import React, { Fragment, useContext } from 'react';
import { transferDetailsContext } from '../../../../context/transfer-details.context';
import { statusEnums } from '../../../../helpers/enums';
import { useServices } from '../../../../services';
import classNames from '../../../../helpers/classNames';

export default function TransactionStatusSelector() {
  const { editTransaction } = useServices();

  const { transfer, setTransfer } = useContext(transferDetailsContext);
  const { transaction } = transfer;

  const handleStatus = async (e) => {
    const payload = {
      status: e.id,
    };
    await editTransaction(transaction?.transactionCode, payload);
    setTransfer((prevState) => ({
      ...prevState,
      transaction: {
        ...prevState.transaction,
        status: e.id,
      },
    }));
  };
  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mb-5">
      <Listbox
        onChange={handleStatus}
        value={statusEnums.find((state) => transaction.status === state.id)}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              Cambiar estado:
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <span className="block truncate">
                  {
                    statusEnums.find((state) => transaction.status === state.id)
                      .label
                  }
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {statusEnums.map((step) => (
                    <Listbox.Option
                      key={step.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9',
                        )
                      }
                      value={step}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={`${step.bgColor} shrink-0 inline-block h-2 w-2 rounded-full`}
                              aria-hidden="true"
                            />
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate ml-3',
                              )}
                            >
                              {step.label}
                            </span>
                          </div>
                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useContext, useState } from 'react';
import { toasterContext } from '../../context/ToasterContext/ToasterContext';
import classNames from '../../helpers/classNames';
import Spinner from '../../components/Spinner';

export default function SetupForm({ close }: { close: () => void }) {
  const [readyToSend, setReadyToSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { setToasterData } = useContext(toasterContext);

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setIsLoading(true);

    if (!stripe || !elements) return;

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: 'if_required',
    });

    if (error) {
      setToasterData({
        type: 'ERROR',
        message: 'Ha ocurrido un error al asociar el método de pago',
        title: 'Error',
      });
    } else {
      setToasterData({
        type: 'SUCCESS',
        message: 'Método de pago asociado correctamente',
        title: 'Guardado',
      });
      close();
    }

    setIsLoading(false);
  };

  return (
    <>
      <PaymentElement
        onChange={(e) => {
          if (e.complete) {
            return setReadyToSend(true);
          }
          return setReadyToSend(false);
        }}
      />
      <button
        type="button"
        disabled={!readyToSend}
        onClick={handleSubmit}
        className={classNames(
          'px-2 py-2 bg-indigo-600 hover:bg-indigo-700 rounded-md text-white flex items-center gap-5 justify-center',
          !readyToSend ? 'opacity-20 cursor-not-allowed' : '',
        )}
      >
        Guardar {isLoading && <Spinner color="white" size={4} marginTop={0} />}
      </button>
    </>
  );
}

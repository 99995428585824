import { useState } from 'react';

export default function ExtraFilesDragAndDrop({ handleUploadExtraFiles }) {
  const [extraFilesDraggingOver, setExtraFilesDraggingOver] = useState(false);

  return (
    <div
      className={`flex flex-col items-center justify-between w-full p-3 rounded-sm mt-4 ${
        extraFilesDraggingOver ? ' bg-gray-300' : ' bg-gray-100'
      }`}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setExtraFilesDraggingOver(true);
      }}
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setExtraFilesDraggingOver(false);
        handleUploadExtraFiles(e.dataTransfer.files);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setExtraFilesDraggingOver(false);
      }}
    >
      <p>
        Arrastra aquí o{' '}
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.multiple = true;
            input.accept = 'image/*,application/pdf';
            input.click();
            input.onchange = async (e) => {
              handleUploadExtraFiles(e.target.files);
            };
          }}
          className="text-indigo-500 underline cursor-pointer"
          onClick={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.multiple = true;
            input.accept = 'image/*,application/pdf';
            input.click();
            input.onchange = async (e) => {
              handleUploadExtraFiles(e.target.files);
            };
          }}
        >
          selecciona desde tu equipo
        </span>{' '}
        los archivos que quieras subir
      </p>
    </div>
  );
}

import { XIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { useServices } from '../../../../services';

function DeleteDigitalSignatureModal({ showModal, setShowModal, docId, save }) {
  const [input, setInput] = useState('Firma cancelada');
  const [error, setError] = useState('');

  const { deleteDigitalContract } = useServices();

  const handleDelete = async () => {
    if (input.trim().length === 0) {
      setError('Se debe de especificar un motivo');
      return;
    }
    try {
      setError('');
      await deleteDigitalContract(docId, input);
      setShowModal(false);
    } catch (err) {
      setError('Error al cancelar la firma. Prueba a recargar la página');
    } finally {
      save();
    }
  };

  const resetModal = () => {
    setShowModal(false);
    setInput('Firma cancelada');
    setError('');
  };

  return (
    showModal && (
      <div className="fixed top-0 left-0 w-full h-full bg-black/75  z-20 flex flex-col justify-center items-center">
        <div className="bg-white flex flex-col justify-center items-center gap-3 p-10 rounded-md">
          <XIcon className="self-end h-6 cursor-pointer" onClick={resetModal} />
          <h3 className="text-2xl font-bold">Eliminar el contrato</h3>
          <p className="text-lg">
            A continuación se{' '}
            <span className="font-bold">cancelará la solicitud de firma</span> y
            se{' '}
            <span className="font-bold">
              borrará la información del contrato
            </span>
          </p>
          <label htmlFor="reason" className="sr-only">
            Especifica el motivo de cancelación (esta información será recibida
            por los firmantes):
          </label>
          <textarea
            onChange={(e) => setInput(e.target.value)}
            id="reason"
            rows="5"
            cols="50"
            value={input}
            placeholder="Especifica el motivo de cancelación (esta información será recibida
          por los firmantes)"
          />
          {error !== '' && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="button"
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleDelete}
          >
            Eliminar
          </button>
        </div>
      </div>
    )
  );
}

export default DeleteDigitalSignatureModal;

import React from 'react';

import Summary from '../summary';
import Invoicing from '../invoicing';
import Payments from '../payments';
import Shipments from '../shipments';
import Tramits from '../tramits';
import Users from '../users';
import MechanicalWarranties from '../mechanicalWarranties';
import classNames from '../../../../helpers/classNames';

export const tabs = [
  {
    name: 'Resumen',
    component: <Summary />,
    current: true,
  },
  {
    name: 'Tramites',
    component: <Tramits />,
    current: false,
  },
  {
    name: 'Facturación',
    component: <Invoicing />,
    current: false,
  },
  {
    name: 'Pagos',
    component: <Payments />,
    current: false,
  },
  {
    name: 'Usuarios',
    component: <Users />,
    current: false,
  },
  {
    name: 'Garantías Mecánicas',
    component: <MechanicalWarranties />,
    current: false,
  },
  {
    name: 'Envíos',
    component: <Shipments />,
    current: false,
  },
];

export default function Tabs({ setCurrentTabs, currentTabs, forceUpdate }) {
  const handleTabChange = (e) => {
    e.preventDefault();
    forceUpdate();
    setCurrentTabs((prevState) =>
      prevState.map((tab) => ({
        ...tab,
        current: tab.name === e.target.value,
      })),
    );
  };

  return (
    <div className="my-8">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={handleTabChange}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {currentTabs.map((tab) => (
            <button
              type="button"
              key={tab.name}
              value={tab.name}
              onClick={handleTabChange}
              className={classNames(
                tab.current
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-500 hover:text-gray-600',
                'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
              )}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}

import React, { useContext } from 'react';
import { transferDetailsContext } from '../../../../../../context/transfer-details.context';

function ExtraFiles({ selectedDocs, handleInputCheck }) {
  const { transfer } = useContext(transferDetailsContext);

  return (
    <div>
      <h3 className="text-lg font-medium">Archivos extra</h3>
      <div className="flex flex-col gap-2 mb-4 mt-2">
        {transfer?.transaction?.extraFiles?.map((file, index) => (
          <div key={file} className="flex items-center gap-2">
            <input
              id={`extra-${index}`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={selectedDocs.some(
                (e) => e.family === 'extra' && e.docName === file,
              )}
              onChange={(e) =>
                handleInputCheck(e.target.checked, 'extra', file, file)
              }
            />
            <label className="w-72 truncate" htmlFor={`extra-${index}`}>
              {file.split('-hash-')[0]}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExtraFiles;

import axios from 'axios';

export const getTrafficReportById = async (id: string, token: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SAAB_URL}/traffic-report/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return data;
};

import React, { useContext } from 'react';
import { TbNotes, TbBrowserCheck } from 'react-icons/tb';
import { transferDetailsContext } from '../../../../context/TransferDetailsContext/transferDetailsContext';
import {
  FILE_TYPE,
  User,
  Transaction,
  Company,
  Car,
} from '../../../../types/types';
import TableActionsItp from './TableActionsItp';

type RowData = {
  icon: React.ReactElement;
  label: string;
  docURI: string;
  fileType: FILE_TYPE;
  owner: Company | User | Transaction | Car;
};

function ItpTableBody() {
  const { transfer } = useContext(transferDetailsContext);

  const generateTemplateRows = (): RowData[] => [
    {
      label: 'Modelo 620',
      docURI: transfer.transaction.paidItpFile,
      icon: <TbNotes className="w-5 h-5" />,
      owner: transfer.transaction,
      fileType: FILE_TYPE.PAID_ITP_FILE,
    },
    {
      label: 'Comprobante de pago ITP',
      docURI: transfer?.transaction?.paidItpProof,
      icon: <TbBrowserCheck className="w-5 h-5" />,
      owner: transfer.transaction,
      fileType: FILE_TYPE.PAID_ITP_PROOF,
    },
  ];

  const filePrefix = transfer.car?.plate || '';

  return (
    <>
      {generateTemplateRows().map((row) => (
        <tr key={`${row.fileType}-${row.owner?.id}`}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            <div className="flex justify-start">
              <span className="mr-2">{row.icon}</span> {row.label}
            </div>
          </td>
          <TableActionsItp
            uri={row.docURI}
            fileName={`${filePrefix} - ${row.label}`}
          />
        </tr>
      ))}
    </>
  );
}

export default ItpTableBody;

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import React, { useContext } from 'react';
import { transferDetailsContext } from '../../../../context/transfer-details.context';

export default function TransactionPaidStatus() {
  const { transfer } = useContext(transferDetailsContext);
  const { transaction } = transfer;
  return transaction?.paid ? (
    <div className="rounded-md bg-emerald-50 p-4 mb-5">
      <div className="flex gap-2">
        <CheckCircleIcon
          className="h-5 w-5 text-emerald-400"
          aria-hidden="true"
        />
        <p className="text-sm font-medium text-emerald-800">
          Transferencia pagada
        </p>
      </div>
    </div>
  ) : (
    <div className="rounded-md bg-red-50 p-4 mb-3">
      <div className="flex gap-2">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        <p className="text-sm font-medium text-red-800">
          Transferencia no pagada
        </p>
        {transaction?.holdedInvoiceId && (
          <a
            className="text-sm font-medium text-red-800 underline flex-grow text-center"
            href={`https://app.holded.com/sales/revenue#open:invoice-${transaction?.holdedInvoiceId}`}
            target="_blank"
            rel="noreferrer"
          >
            Ir a Holded
          </a>
        )}
      </div>
    </div>
  );
}

import { useState } from 'react';
import { BsX } from 'react-icons/bs';
import BaseModal from '../BaseModal';
import { useServices } from '../../services';
import { parseDayMonthYearWithSlash } from '../../helpers';

export default function IncidentViewerModal({
  incident,
  entityId,
  closeModal,
  forceUpdate,
}) {
  const [loading, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { closeIncidentOnEntity } = useServices();

  const handleClick = async () => {
    try {
      setLoader(true);
      setErrorMessage('');
      await closeIncidentOnEntity(incident.incidentTypeId, entityId);
      forceUpdate();
      closeModal();
    } catch (e) {
      setErrorMessage('Ha ocurrido un error resolviendo la incidencia');
    } finally {
      setLoader(false);
    }
  };

  return (
    <BaseModal
      isOpen={Boolean(incident.id)}
      onClose={closeModal}
      overflowVisible={false}
    >
      {incident.name && (
        <div className="space-y-6 relative">
          <BsX
            className="cursor-pointer w-8 h-8 absolute top-0 right-4 text-gray-400"
            onClick={closeModal}
          />
          <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                  {incident.name}
                </h3>
                <div className="flex flex-col gap-y-2">
                  <p className="text-red-500">{errorMessage}</p>
                  <div className="max-h-[400px] overflow-y-auto">
                    <div className="flex flex-col gap-y-4">
                      <div>
                        <p className="block text-sm font-medium text-gray-700">
                          Abierta por:
                        </p>
                        <p>{incident.createdBy || '---'}</p>
                      </div>
                    </div>
                  </div>
                  <div className="max-h-[400px] overflow-y-auto">
                    <div className="flex flex-col gap-y-4">
                      <div>
                        <p className="block text-sm font-medium text-gray-700">
                          Fecha:
                        </p>
                        <p>
                          {parseDayMonthYearWithSlash(incident.createdAt) ||
                            '---'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="max-h-[400px] overflow-y-auto">
                    <div className="flex flex-col gap-y-4">
                      <div>
                        <p className="block text-sm font-medium text-gray-700">
                          Comentario:
                        </p>
                        <p>{incident.comment || '---'}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-6">
                    <button
                      type="button"
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-0"
                      onClick={handleClick}
                    >
                      {loading && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      )}
                      {loading ? 'Resolviendo...' : 'Resolver incidencia'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  );
}

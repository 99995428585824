import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from '../../../../helpers/classNames';
import { BATE_STATUS } from '../../../../shared/bate-status';
import { parseDayMonthYear } from '../../../../helpers/parseDate';
import { getBateByTransactionId } from '../../../../services/services';

export type RelatedBateTramit = {
  brand: string;
  model: string;
  plate: string;
  bateCode: string;
  createdAt: string;
  status: BATE_STATUS;
  batecomId: string;
};

function RelatedBateTramit({
  transactionId,
}: Readonly<{
  transactionId: string;
}>): JSX.Element {
  const [relatedTramit, setRelatedTramit] = useState<RelatedBateTramit>(null);
  const [relatedTramitExpanded, setRelatedTramitExpanded] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!transactionId) return;
    const fetchByTransactionId = async () => {
      const token = await getAccessTokenSilently();
      const { data } = await getBateByTransactionId(token, transactionId);
      setRelatedTramit(data);
    };

    fetchByTransactionId();
  }, [transactionId]);

  const relatedTramitStatuses = {
    [BATE_STATUS.PENDING_DOCUMENTATION]: {
      style: 'text-gray-600 bg-gray-50 ring-gray-500/10',
      text: 'No presentado',
    },
    [BATE_STATUS.REVIEW_PENDING_DOCUMENTATION]: {
      style: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      text: 'Presentado a gestoría',
    },
    [BATE_STATUS.PRESENTED_TO_AGENCY]: {
      style: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      text: 'Presentado a gestoría',
    },
    [BATE_STATUS.PRESENTED_TO_DGT]: {
      style: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      text: 'Presentado a DGT',
    },
    [BATE_STATUS.FINISHED_BY_DGT]: {
      style: 'text-green-700 bg-green-50 ring-green-600/20',
      text: 'Finalizado DGT',
    },
  };

  if (!relatedTramit) return null;

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex justify-between w-full">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-1">
            Trámites relacionados
          </h3>
          {relatedTramitExpanded ? (
            <button
              type="button"
              onClick={() => setRelatedTramitExpanded(false)}
            >
              <ChevronUpIcon
                className="h-5 w-5 text-gray-400 cursor-pointer"
                aria-hidden="true"
              />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setRelatedTramitExpanded(true)}
            >
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400 cursor-pointer"
                aria-hidden="true"
              />
            </button>
          )}
        </div>
        {relatedTramitExpanded && (
          <div className="mt-2">
            <ul className="divide-y divide-gray-100">
              <li className="flex items-center justify-between gap-x-6 py-5">
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {relatedTramit.plate} - {relatedTramit.brand}{' '}
                      {relatedTramit.model}
                    </p>
                    <p
                      className={classNames(
                        relatedTramitStatuses[
                          relatedTramit.status as keyof typeof relatedTramitStatuses
                        ].style,
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {
                        relatedTramitStatuses[
                          relatedTramit.status as keyof typeof relatedTramitStatuses
                        ].text
                      }
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="truncate">Compra - Batecom</p>
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="whitespace-nowrap">
                      <time dateTime={relatedTramit.createdAt}>
                        {parseDayMonthYear(new Date(relatedTramit.createdAt))}
                      </time>
                    </p>
                  </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  <Link
                    to={`/bate/${relatedTramit.bateCode}`}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                  >
                    Ver trámite
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default RelatedBateTramit;

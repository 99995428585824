import axios from 'axios';

export type FullAddressResponse = {
  ccaa?: string;
  city?: string;
  country?: string;
  province?: string;
  streetName?: string;
  zipCode?: string;
  coordinates?: {
    lng: number;
    lat: number;
  };
};

export const obtainFullAddress = async (
  address: string,
): Promise<FullAddressResponse> => {
  const { data } = await axios.get<FullAddressResponse>(
    `${process.env.REACT_APP_PIAGGIO_BASE_URL}/get-full-info-from-address?address=${address}`,
  );

  return data;
};

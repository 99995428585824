export const isShipmentAddressComplete = (s) =>
  s.delivery?.country &&
  s.delivery?.city &&
  s.delivery?.state &&
  s.delivery?.street &&
  s.delivery?.zipCode;

export const isDeliveryDataComplete = (s) =>
  isShipmentAddressComplete(s) &&
  s.receiver?.doi &&
  s.receiver?.email &&
  s.receiver?.name &&
  s.receiver?.phone;

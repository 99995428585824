import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import { BATE_STATUS } from '../../../shared/bate-status';

export default function BateStatusStepper({ status }: { status: BATE_STATUS }) {
  const getCurrentStep = (number: number, title: string) => (
    <div
      key={`${title}-${number}`}
      className="flex items-center justify-center gap-x-4 w-1/3"
    >
      <div className="border-2 border-emerald-600 w-10 h-10 rounded-full flex items-center justify-center">
        <span className="text-emerald-600">0{number}</span>
      </div>
      <p className="text-emerald-600 text-sm">{title}</p>
    </div>
  );

  const getPastStep = (title: string) => (
    <div key={title} className="flex items-center justify-center gap-x-4 w-1/3">
      <div className="bg-emerald-600 w-10 h-10 rounded-full flex items-center justify-center">
        <CheckIcon className="text-white w-6 h-6" />
      </div>
      <p className="text-sm">{title}</p>
    </div>
  );

  const getFutureStep = (number: number, title: string) => (
    <div
      key={`${title}-${number}`}
      className="flex items-center justify-center gap-x-4 w-1/3"
    >
      <div className="border-2 border-gray-300 w-10 h-10 rounded-full flex items-center justify-center">
        <span className="text-gray-500">0{number}</span>
      </div>
      <p className="text-gray-500 text-sm">{title}</p>
    </div>
  );

  const renderStep = (bateStatus: BATE_STATUS): JSX.Element => {
    let stepElement: React.ReactElement[];

    switch (bateStatus) {
      case BATE_STATUS.PENDING_DOCUMENTATION:
        stepElement = [
          getCurrentStep(1, 'Trámite presentado'),
          getFutureStep(2, 'Presentado DGT'),
          getFutureStep(3, 'Finalizado DGT'),
        ];
        break;
      case BATE_STATUS.REVIEW_PENDING_DOCUMENTATION:
      case BATE_STATUS.PRESENTED_TO_AGENCY:
        stepElement = [
          getPastStep('Trámite presentado'),
          getCurrentStep(2, 'Presentado DGT'),
          getFutureStep(3, 'Finalizado DGT'),
        ];
        break;
      case BATE_STATUS.PRESENTED_TO_DGT:
        stepElement = [
          getPastStep('Trámite presentado'),
          getPastStep('Presentado DGT'),
          getCurrentStep(3, 'Finalizado DGT'),
        ];
        break;
      case BATE_STATUS.FINISHED_BY_DGT:
        stepElement = [
          getPastStep('Trámite presentado'),
          getPastStep('Presentado DGT'),
          getPastStep('Finalizado DGT'),
        ];
        break;
      default:
        stepElement = [];
    }

    return <>{stepElement.map((el) => el)}</>;
  };

  return (
    <div className="bg-white border border-gray-300 rounded-md px-10 py-4 flex justify-start items-center">
      {renderStep(status)}
    </div>
  );
}

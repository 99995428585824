import axios from 'axios';
import { CommentPayload } from '../Modules/Comments/useComments';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchComments = async (token: string, id: string) =>
  axios.get(`${BASE_URL}/comment/list/${id}?commentType=public`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const postNewComment = async (token: string, payload: CommentPayload) =>
  axios.post(`${BASE_URL}/comment`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const removeComment = async (
  token: string,
  commentId: string,
  userEmail: string,
) =>
  axios.delete(`${BASE_URL}/comment/delete/${commentId}`, {
    headers: {
      'Content-Type': 'application/json',
      'x-user-email': userEmail,
      Authorization: `Bearer ${token}`,
    },
  });

export default function ItpDetails({ itpData }) {
  const keysDictionary = {
    itp: 'ITP',
    itp_coeficient: 'Porcentaje de ITP',
    value: 'Valoración tablas hacienda',
    deprecation_coeficient: 'Porcentaje de depreciación',
    deprecated_value: 'Valor fiscal',
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Desglose del impuesto
          </h1>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Dato
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Valor
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {Object.keys(itpData).map(
                    (key) =>
                      key !== 'itp' &&
                      key !== 'itp_coeficient' && (
                        <tr key={key}>
                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                            {keysDictionary[key]}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                            {itpData[key].toLocaleString('ES-es')}{' '}
                            {keysDictionary[key].toLowerCase().includes('valor')
                              ? '€'
                              : '%'}
                          </td>
                        </tr>
                      ),
                  )}
                  <tr className="bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-bold text-gray-900 sm:pl-6">
                      ITP ({itpData.itp_coeficient}%)
                    </td>
                    <td className="whitespace-nowrap px-2 py-4 text-lg font-bold text-gray-900">
                      {itpData.itp.toLocaleString('ES-es')} €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useRef, useState } from 'react';
import { useServices } from '../services';

export default function useDgtFees() {
  const [dgtFees, setDgtFees] = useState({
    data: [],
    isLoading: false,
    error: false,
  });
  const [selectedDgtFee, setSelectedDgtFee] = useState('');

  const [dgtFeeTypes, setDgtFeeTypes] = useState({
    data: [],
    isLoading: false,
    error: false,
  });
  const [selectedFeeType, setSelectedFeeType] = useState('');

  const dgtFeeTypeSelectRef = useRef(null);

  const { getDgtFeeTypes, getAllAvailableDgtFeesByFeeType } = useServices();

  useEffect(() => {
    (async () => {
      try {
        setDgtFeeTypes((prev) => ({ ...prev, isLoading: true, error: false }));

        const types = await getDgtFeeTypes();

        setDgtFeeTypes((prev) => ({ ...prev, data: types }));
      } catch (e) {
        setDgtFeeTypes((prev) => ({ ...prev, error: true }));
      } finally {
        setDgtFeeTypes((prev) => ({ ...prev, isLoading: false }));
      }
    })();
  }, []);

  useEffect(() => {
    if (!dgtFeeTypes.data.length) return;

    setSelectedFeeType('4.1');
  }, [dgtFeeTypes.data]);

  useEffect(() => {
    const getAllFees = async () => {
      try {
        setDgtFees((prev) => ({ ...prev, isLoading: true, error: false }));

        const dgtFeesResponse = await getAllAvailableDgtFeesByFeeType(
          selectedFeeType,
        );

        setDgtFees((prev) => ({ ...prev, data: dgtFeesResponse }));
      } catch (e) {
        setDgtFees((prev) => ({ ...prev, data: [], error: true }));
      } finally {
        setDgtFees((prev) => ({ ...prev, isLoading: false }));
      }
    };
    getAllFees();
  }, [selectedFeeType]);

  return {
    dgtFeeTypes,
    dgtFees,
    selectedFeeType,
    setSelectedFeeType,
    selectedDgtFee,
    setSelectedDgtFee,
    dgtFeeTypeSelectRef,
  };
}

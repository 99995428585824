import { useState } from 'react';
import IncidentViewerModal from './IncidentViewer';
import OpenIncidentModal from './OpenIncidentModal';

export default function Incidents({
  entityId,
  entityType,
  forceUpdate,
  emailToNotify,
  entityIncidents,
}) {
  const [viewedIncident, setViewedIncident] = useState({});
  const [showIncidentModal, setShowIncidentModal] = useState(false);

  const clearViewedIncidentId = () => setViewedIncident({});

  return (
    <>
      <OpenIncidentModal
        setShowModal={setShowIncidentModal}
        showModal={showIncidentModal}
        entityId={entityId}
        entityType={entityType}
        forceUpdate={forceUpdate}
        emailToNotify={emailToNotify}
      />
      <IncidentViewerModal
        incident={viewedIncident}
        entityId={entityId}
        closeModal={clearViewedIncidentId}
        forceUpdate={forceUpdate}
      />

      <div className="flex flex-wrap">
        {entityIncidents
          ?.filter((incident) => !incident.resolvedAt)
          ?.map((incident) => (
            <button
              key={incident.id}
              type="button"
              className="h-fit w-fit px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 hover:bg-red-800 hover:text-red-100 transition-colors p-1 items-center mr-2"
              onClick={() => setViewedIncident(incident)}
            >
              <span className="pointer-events-none">{incident.name}</span>
            </button>
          ))}

        <button
          type="button"
          onClick={() => setShowIncidentModal(true)}
          className="h-fit w-fit text-red-400 mr-2 text-xs relative border-2 border-red-300 border-dashed rounded-full p-1 text-center hover:border-red-600 focus:outline-none focus:ring-0"
        >
          +&nbsp;Incidencia
        </button>
      </div>
    </>
  );
}

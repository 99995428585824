import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TransferForTable } from '../..';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';
import parseDate from '../../../helpers/parseDate';
import { SHIPMENT_STATUS } from '../../../shared/transaction-status';
import { getTransferStatusLabel } from '../../../helpers/statusLabelParser';

interface TransfersTableProps {
  transfers: TransferForTable[];
}

export default function TransfersTable({ transfers }: TransfersTableProps) {
  const typeDictionary = {
    seller: 'Venta',
    buyer: 'Compra',
    intermediary: 'Intermediación',
    batecom_sale: 'Batecom - venta',
    batecom_purchase: 'Batecom - compra',
  };

  const [dateSort, setDateSort] = useState('DESC');

  const emptyLabel = { label: '', colors: '' };

  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);

  useEffect(() => {
    const dateSortParam = getSingleParam('dateSort') || 'DESC';
    setDateSort(dateSortParam);
  }, [params]);

  const shippedLabel = {
    label: 'Enviado',
    colors: 'bg-yellow-100 text-yellow-800',
  };

  const deliveredLabel = {
    label: 'Entregado',
    colors: 'bg-green-100 text-green-800',
  };

  const getShipmentStatusLabel = (status: number) => {
    if (SHIPMENT_STATUS.DELIVERED.includes(status)) {
      return deliveredLabel;
    }

    if (SHIPMENT_STATUS.SENT.includes(status)) {
      return shippedLabel;
    }

    return emptyLabel;
  };

  return (
    <div className="overflow-x-auto min-h-fit shadow ring-1 ring-black ring-opacity-5 rounded-lg">
      <table className="table-fixed min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
            >
              Matrícula
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Id
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Tipo
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Estado del trámite
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Estado del envío
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase cursor-pointer flex gap-2 items-center"
              onClick={
                dateSort === 'DESC'
                  ? () => setSingleParam('dateSort', 'ASC')
                  : () => setSingleParam('dateSort', 'DESC')
              }
            >
              Fecha de creación{' '}
              {dateSort === 'ASC' ? (
                <ChevronUpIcon className="h-3" />
              ) : (
                <ChevronDownIcon className="h-3" />
              )}
            </th>
            <th
              scope="col"
              className="sticky right-0 bg-gray-50 py-3.5 pl-3 pr-4 shadow-md sm:pr-6"
            >
              <span className="sr-only">Ver</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {transfers.map((transfer, personIdx) => (
            <tr
              key={transfer.tramitCode}
              className={`${
                personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
              } hover:bg-gray-100`}
            >
              <td className="min-w-40 px-3 sm:px-6 align-middle whitespace-nowrap text-sm text-gray-900 flex items-center gap-3 h-14 font-medium">
                {transfer.plate}
                {transfer.hasIncident && (
                  <ExclamationCircleIcon className="h-5 text-red-500" />
                )}
              </td>
              <td className="min-w-36 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                {transfer.tramitCode}
              </td>
              <td className="min-w-36 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                {typeDictionary[transfer.type]}
              </td>
              <td className="min-w-48 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                <span
                  className={`rounded-full px-2.5 py-1 text-xs font-medium ${
                    getTransferStatusLabel(transfer.status).colors
                  }`}
                >
                  {getTransferStatusLabel(transfer.status).label}
                </span>
              </td>
              <td className="min-w-48 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                <span
                  className={`rounded-full px-2.5 py-1 text-xs font-medium ${
                    getShipmentStatusLabel(transfer.status).colors
                  }`}
                >
                  {getShipmentStatusLabel(transfer.status).label}
                </span>
              </td>
              <td className="min-w-48 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                {parseDate(transfer.createdAt)}
              </td>
              <td
                className={`${
                  personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                } sticky right-0 min-w-16 w-16 align-middle whitespace-nowrap text-center text-sm font-medium`}
              >
                <Link
                  to={
                    transfer.type !== 'batecom_purchase'
                      ? `/transfer/${transfer.tramitCode}`
                      : `/bate/${transfer.tramitCode}`
                  }
                  className="text-blue-600 hover:text-blue-900"
                >
                  Ver
                  <span className="sr-only">
                    , trámite con matrícula {transfer.plate}
                  </span>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

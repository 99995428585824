import Select from '../../../../components/select';
import {
  brandCarsEnums,
  brandMotoEnums,
  ccEnums,
  fuelEnums,
  taxCCAAsEnums,
  vehicleTypeEnums,
} from '../../../../helpers/enums';
import DateInput from './dateInput';

function ItpForm({
  itpFormValue,
  handleChange,
  setCc,
  setCcaa,
  itpResetValues,
  isModelLoading,
  modelError,
  modelData,
  isFormComplete,
  selectedModel,
  handleModelSelection,
  ccaa,
  isItpLoading,
  itpError,
  cc,
  setPrefilledUrl,
  setIsCopySucccess,
}) {
  return (
    <>
      <Select
        label="Tipo de Vehículo"
        stateName="vehicleType"
        options={vehicleTypeEnums}
        value={itpFormValue.vehicleType}
        handleChange={handleChange}
      />
      {itpFormValue.vehicleType && (
        <Select
          label="Marca"
          stateName="brand"
          options={
            itpFormValue.vehicleType === 'car' ? brandCarsEnums : brandMotoEnums
          }
          value={itpFormValue.brand}
          handleChange={handleChange}
        />
      )}
      {itpFormValue.brand && (
        <DateInput
          label="Fecha de matriculación"
          stateName="enrollmentDate"
          value={itpFormValue.enrollmentDate}
          handleChange={handleChange}
        />
      )}
      {itpFormValue.vehicleType === 'car' &&
        itpFormValue.brand &&
        itpFormValue.enrollmentDate && (
          <Select
            label="Combustible"
            stateName="fuel"
            options={fuelEnums}
            value={itpFormValue.fuel}
            handleChange={handleChange}
          />
        )}

      {itpFormValue.vehicleType === 'moto' &&
        itpFormValue.brand &&
        itpFormValue.enrollmentDate && (
          <Select
            label="Tamaño de Motor (CC)"
            stateName="cc"
            options={ccEnums}
            value={cc}
            handleChange={(e) => {
              setCc(e.target.value);
              setCcaa('');
              itpResetValues();
              if (setPrefilledUrl) {
                setPrefilledUrl('');
              }
              if (setIsCopySucccess) {
                setIsCopySucccess(false);
              }
            }}
          />
        )}

      {itpFormValue.vehicleType === 'car' && isModelLoading && (
        <p>loading...</p>
      )}
      {itpFormValue.vehicleType === 'car' && modelError && (
        <p className="text-red-600">{modelError.message}</p>
      )}
      {itpFormValue.vehicleType === 'car' &&
        !modelData?.cars.length &&
        !modelError &&
        !isModelLoading &&
        isFormComplete() && <p>No se encontró ningún modelo.</p>}
      {itpFormValue.vehicleType === 'car' &&
        modelData?.cars &&
        !!modelData?.cars.length && (
          <Select
            label="Modelo"
            stateName="selectedModel"
            options={modelData.cars.map((car) => ({
              model: car.model,
              label: car.model,
              value: car.model,
            }))}
            value={selectedModel?.model}
            handleChange={handleModelSelection}
          />
        )}
      {(selectedModel?.model || cc) && (
        <Select
          label="Comunidad Autónoma"
          stateName="ccaa"
          options={taxCCAAsEnums}
          value={ccaa}
          handleChange={(e) => {
            setCcaa(e.target.value);
            if (setPrefilledUrl) {
              setPrefilledUrl('');
            }
            if (setIsCopySucccess) {
              setIsCopySucccess(false);
            }
          }}
        />
      )}

      {isItpLoading && <p>loading...</p>}
      {itpError && <p className="text-red-600">{itpError.message}</p>}
    </>
  );
}

export default ItpForm;

import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import PricePlan from './components/PricePlan';
import Spinner from '../../../../components/Spinner';
import { parseDateHourYear } from '../../../../helpers';
import PaymentMethodModule from './components/PaymentMethodModule';
import { accountDetailsContext } from '../../../../context/account-details.context';

const paymentsStatusBadge = (status) => {
  switch (status) {
    case 'succeeded':
      return (
        <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
          Pagado
        </span>
      );
    case 'processing':
      return (
        <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
          Pendiente
        </span>
      );
    case 'requires_payment_method':
      return (
        <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
          Error
        </span>
      );
    default:
      return null;
  }
};

export default function Payments() {
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nextCursor, setNextCursor] = useState(null);
  const [previousCursor, setPreviousCursor] = useState(null);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [isStartOfList, setIsStartOfList] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  const { account } = useContext(accountDetailsContext);

  useEffect(() => {
    (async () => {
      if (!account.stripeId) return;

      const token = await getAccessTokenSilently();

      let queryString = '';

      if (nextCursor) queryString += `?nextCursor=${nextCursor}`;
      if (previousCursor) queryString += `?previousCursor=${previousCursor}`;

      const { data: fetchedPayments } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/payments/${account.stripeId}${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setPayments(fetchedPayments.data);
      if (nextCursor) {
        setIsEndOfList(!fetchedPayments.has_more);
      }
      if (previousCursor) {
        setIsStartOfList(!fetchedPayments.has_more);
      }
      setIsLoading(false);
    })();
  }, [account.stripeId, previousCursor, nextCursor]);

  const goNext = () => {
    setNextCursor(payments[payments.length - 1]?.id);
    setPreviousCursor(null);
    setIsStartOfList(false);
  };

  const goPrev = () => {
    setPreviousCursor(payments[0]?.id);
    setNextCursor(null);
    setIsEndOfList(false);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Spinner color="text-blue-700" size={10} />
      </div>
    );
  }

  return (
    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 relative">
      <div className="lg:col-start-1 lg:col-span-1">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <PaymentMethodModule account={account} />
            </div>
          </div>
        </div>
        <div className="mt-5 overflow-hidden rounded-lg bg-white shadow">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <PricePlan
                pricePlanId={account.pricePlanId}
                accountId={account.id}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="lg:col-start-2 lg:col-span-2 space-y-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Cantidad
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Estado
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Descripción
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Fecha
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((p, i) => (
                    <tr
                      key={p.id}
                      className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                        {(p.amount / 100).toLocaleString('es-ES', {
                          minimumFractionDigits: 2,
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {paymentsStatusBadge(p.status)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {p.description}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {parseDateHourYear(p.created * 1000)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block" />
                <div className="flex-1 flex justify-between sm:justify-end">
                  {!isStartOfList && (
                    <button
                      onClick={goPrev}
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Anterior
                    </button>
                  )}
                  {!isEndOfList && !payments.length < 10 && (
                    <button
                      onClick={goNext}
                      type="button"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Siguiente
                    </button>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

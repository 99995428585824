export const handleViewTrafficReport = async ({
  trafficReportId,
  getTrafficReportById,
  setErrorMessage,
}) => {
  try {
    setErrorMessage('');

    const { uri } = await getTrafficReportById(trafficReportId);

    const link = document.createElement('a');
    link.href = uri;
    link.setAttribute('rel', 'noopener noreferrer');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    setErrorMessage('No se pudo abrir el informe de tráfico');
    setTimeout(() => {
      setErrorMessage('');
    }, 10000);
  }
};

/* eslint-disable react/function-component-definition */
import { useAuth0 } from '@auth0/auth0-react';
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { Transfer, Company, OperationType } from '../../types/types';
import { getAggregatedTransaction } from '../../services/services';

export interface TransferDetailsContextType {
  transfer: Transfer;
  transferLoading: boolean;
  forceUpdate: () => void;
}

export const transferDetailsContext = createContext<TransferDetailsContextType>(
  {} as TransferDetailsContextType,
);

export const TransferDetailsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [transfer, setTransfer] = useState<Transfer | null>(null);
  const [transferLoading, setTransferLoading] = useState<boolean>(false);

  const { transactionCode } = useParams();
  const [updater, forceUpdateTransaction] = useReducer((x) => x + 1, 0);

  const { getAccessTokenSilently } = useAuth0();

  const computeOperationType = (data: Transfer): OperationType => {
    const buyerIsCompany = (data.buyer as Company)?.representativeId;
    const sellerIsCompany = (data.seller as Company)?.representativeId;

    if (sellerIsCompany && buyerIsCompany) {
      return 'B2B';
    }
    if (buyerIsCompany && !sellerIsCompany) {
      return 'B2P';
    }
    if (!buyerIsCompany && sellerIsCompany) {
      return 'P2B';
    }
    return 'P2P';
  };

  const getTransactionByCode = async () => {
    setTransferLoading(true);
    const token = await getAccessTokenSilently();
    const { data } = await getAggregatedTransaction(token, transactionCode);
    const operationType = computeOperationType(data);
    setTransfer({
      ...data,
      operationType,
    });
    setTransferLoading(false);
  };

  useEffect(() => {
    getTransactionByCode();
  }, [updater, transactionCode]);

  const value = useMemo(
    () => ({
      transfer,
      transferLoading,
      forceUpdate: forceUpdateTransaction,
    }),
    [transfer, transferLoading],
  );

  return (
    <transferDetailsContext.Provider value={value}>
      {children}
    </transferDetailsContext.Provider>
  );
};

import { useContext, useEffect } from 'react';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';

export default function useTypeform() {
  const { companyInfo } = useContext(userInfoContext);

  useEffect(() => {
    if (!companyInfo?.nif) return;

    const script = document.createElement('script');
    script.src = 'https://embed.typeform.com/next/embed.js';
    document.querySelector('head').appendChild(script);
  }, [companyInfo]);
}

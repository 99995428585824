import { useContext, useState } from 'react';

import { registrationDetailsContext } from '../../../context/registration-details.context';
import TopSection from './components/TopSection';
import RegistrationSummary from './components/RegistrationSummary';
import { useServices } from '../../../services';
import RegistrationStatusSelector from './components/RegistrationStatusSelector';
import AgentAssignBox from '../../../components/AgentAssignBox';
import Timeline from '../../../components/Timeline/Timeline';
import CommentsModule from '../../../components/Comments/CommentsModule';
import { ENTITY_TYPES } from '../../../helpers/enums';
import ExtraFiles from '../../../components/ExtraFiles';
import Shipment from '../../../components/Shipments/Shipment';
import useRegistrationShipments from './hooks/useRegistrationShipments';

export default function RegistrationDetails() {
  const [showDeliveryInfoModal, setShowDeliveryInfoModal] = useState(false);

  const {
    forceUpdate,
    registrationLoading,
    registrationIncidents,
    aggregatedRegistration,
    updater,
  } = useContext(registrationDetailsContext);

  const { registration, car, agent, labels, account } =
    aggregatedRegistration || {};

  const { editRegistration } = useServices();

  const {
    editedShipment,
    availableItems,
    setAvailableItems,
    isShipmentLoading,
    getShipmentError,
    shipments,
    items,
    uncreatedItems,
    setUncreatedItems,
    setEditedShipment,
    areRolesComplete,
    accountId,
    possibleReceivers,
    isDocumentOnOffice,
    isPro,
  } = useRegistrationShipments({
    registration,
    car,
    updater,
  });

  if (registrationLoading) return null;

  return (
    <div className="min-h-full">
      <main>
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 relative">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section>
              <div className="bg-white shadow sm:rounded-lg">
                <TopSection
                  account={account}
                  registration={registration}
                  labels={labels}
                  forceUpdate={forceUpdate}
                  incidents={registrationIncidents}
                />
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <RegistrationSummary />
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Documentos
                      </dt>
                      <dd className="mt-2 text-sm text-gray-900">
                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                          <ExtraFiles
                            tramitType={ENTITY_TYPES.REGISTRATION}
                            entity={registration}
                          />
                        </ul>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
            <Shipment
              setShowDeliveryInfoModal={setShowDeliveryInfoModal}
              showDeliveryInfoModal={showDeliveryInfoModal}
              availableItems={availableItems}
              editedShipment={editedShipment}
              forceUpdate={forceUpdate}
              updater={updater}
              setAvailableItems={setAvailableItems}
              isShipmentLoading={isShipmentLoading}
              getShipmentError={getShipmentError}
              shipments={shipments}
              items={items}
              uncreatedItems={uncreatedItems}
              setUncreatedItems={setUncreatedItems}
              setEditedShipment={setEditedShipment}
              areRolesComplete={areRolesComplete}
              accountId={accountId}
              possibleReceivers={possibleReceivers}
              isDocumentOnOffice={isDocumentOnOffice}
              isPro={isPro}
              entityType={ENTITY_TYPES.REGISTRATION}
              plate={car?.plate}
            />
            <CommentsModule
              entityId={registration?.id}
              entityIncidents={registrationIncidents}
              showNotificationOption
              tramitCode={registration?.registrationCode}
              tramitType={ENTITY_TYPES.REGISTRATION}
              emailToNotify={account?.billingEmail}
              platform={account?.platform}
            />
          </div>
          <section
            aria-labelledby="timeline-title"
            className="lg:col-start-3 lg:col-span-1"
          >
            <RegistrationStatusSelector />
            <AgentAssignBox
              agent={agent}
              tramitCode={registration?.registrationCode}
              editTramit={editRegistration}
              forceUpdate={forceUpdate}
            />
            <Timeline tramitId={registration?.id} />
          </section>
        </div>
      </main>
    </div>
  );
}

import { Link } from 'react-router-dom';
import { itemTypeDictionary } from '../helpers/itemTypeDictionary';
import { getItemLink } from '../helpers/getItemLink';

export default function ShipmentItemsInTable({ items }) {
  return (
    <div className="flex flex-col">
      {items?.map((item) => (
        <div className="flex justify-between gap-x-2" key={item.entityId}>
          <span>{itemTypeDictionary.get(item.type)}</span>
          <Link
            to={getItemLink(item.type, item.entityId, item.entityType)}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-500 self-center"
          >
            Ver
          </Link>
        </div>
      ))}
    </div>
  );
}

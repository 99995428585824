import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { BiError } from 'react-icons/bi';
import { User } from '@auth0/auth0-react';
import { TrashIcon } from '@heroicons/react/outline';

import Spinner from '../../components/Spinner';
import { Comment } from '../../types/types';
import { parseDayMonthYearHourMinute } from '../../helpers/parseDate';

type CommentRowProps = {
  user: User;
  comment: Comment;
  deleteComment: (commentId: string, userEmail: string) => Promise<void>;
};

function CommentRow({
  comment,
  deleteComment,
  user,
}: CommentRowProps): JSX.Element {
  const { id, comment: message, createdAt, createdBy } = comment;
  const isCommentFromActualUser = createdBy === user?.email;

  const [loading, setLoading] = useState(false);

  const handleDeleteComment = async (commentId: string) => {
    if (!commentId) return;
    setLoading(true);
    await deleteComment(commentId, user?.email);
    setLoading(false);
  };

  return (
    <li className="flex">
      <div className="flex space-x-3 w-full">
        <div className="flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src={
              isCommentFromActualUser
                ? user?.picture
                : 'https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/swipoo-white-icon.svg'
            }
            alt={`Imagen de ${user?.name}`}
          />
        </div>
        <div>
          <div className="text-sm">
            <span>{isCommentFromActualUser ? user?.name : createdBy}</span>
          </div>
          <div className="mt-1 text-sm text-gray-700">
            <p>{message}</p>
          </div>
          <div className="mt-2 space-x-2 text-sm">
            <div className="flex gap-x-2">
              <span className="font-sm text-gray-500">
                {parseDayMonthYearHourMinute(new Date(createdAt))}{' '}
              </span>{' '}
              {isCommentFromActualUser && !comment.fromIncident && (
                <button
                  type="button"
                  onClick={() => handleDeleteComment(id)}
                  className="font-medium text-xs"
                >
                  {loading ? (
                    <Spinner color="text-blue-700" size={5} marginTop={0} />
                  ) : (
                    <TrashIcon className="h-5 w-5 text-gray-500 hover:text-rose-500" />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="self-center">
        {comment.fromIncident && !comment.incidentResolvedAt && (
          <>
            <Tooltip
              id={`comment-${id}`}
              style={{
                marginTop: '0',
                fontSize: '15px',
              }}
              content={comment.incidentName}
              place="bottom"
            />
            <div data-tooltip-id={`comment-${id}`} data-testid={id}>
              <BiError className="text-red-500 h-5 w-5" />
            </div>
          </>
        )}
      </div>
    </li>
  );
}

export default CommentRow;

export const docFamilyDictionary = {
  buyer: 'Comprador',
  seller: 'Vendedor',
  intermediary: 'Intermediario',
  car: 'Vehículo',
  transfer: 'Trámite',
  proBuyer: 'Comprador (Pro)',
  proSeller: 'Vendedor (Pro)',
  proIntermediary: 'Intermediario (Pro)',
  extra: 'Extra',
  digitalSignature: 'Firma digital',
};

export const docDictionary = {
  dniFront: 'DNI frontal',
  dniBack: 'DNI reverso',
  census: 'Padrón',
  circulationPermit: 'Permiso de circulación',
  technicalSheet: 'Ficha técnica',
  saleContract: 'Contrato de compra-venta (subido)',
  temporaryCirculationDoc: 'Permiso de circulación provisional',
  presentationProof: 'Justificante de presentación',
  proInvoice: 'Factura Pro',
  paidItpFile: 'ITP pagado',
  paidItpProof: 'Justificante de ITP pagado',
  nifFile: 'NIF',
  dniFrontRepresentative: 'DNI frontal (representante)',
  dniBackRepresentative: 'DNI reverso (representante)',
  censusRepresentative: 'Censo (representante)',
};

import React, { useContext } from 'react';

import ChooseDocumentSourceStep from './ChooseDocumentSourceStep';
import EditDocuement from './EditDocument';
import { digitallySignContext } from '../../../context/DigitallySignContext/DigitallySignContext';

// eslint-disable-next-line no-shadow
export enum DigitallySignSteps {
  CHOOSE_DOCUMENT_SOURCE = 1,
  CHOOSE_TEMPLATE = 2,
  EDIT_DOCUMENT = 3,
  SEND_DOC_RECIPIENTS = 4,
}

export default function DigitallySignStepsManager({
  hide,
}: {
  readonly hide: () => void;
}) {
  const { currentStep } = useContext(digitallySignContext);

  if (currentStep === DigitallySignSteps.CHOOSE_DOCUMENT_SOURCE) {
    return <ChooseDocumentSourceStep hide={hide} />;
  }

  if (currentStep === DigitallySignSteps.CHOOSE_TEMPLATE) {
    // SWIP-708
    // SWIP-709
    return 'Elegir plantilla';
  }

  if (currentStep === DigitallySignSteps.EDIT_DOCUMENT) {
    return (
      // Pass docId from CHOOSE_TEMPLATE selection step
      <EditDocuement docId="1rImnJgf78SCV3PoJuVywqYuWTBnvciOeZw6zjbtKIkA" />
    );
  }

  if (currentStep === DigitallySignSteps.SEND_DOC_RECIPIENTS) {
    return 'Enviar contrato'; // SWIP-711
  }

  return null;
}

import React, {
  useMemo,
  Dispatch,
  useState,
  SetStateAction,
  useEffect,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { OfferFormStateType } from '../index';
import Spinner from '../../../../components/Spinner';
import Results from './ResultsForm';
import OfferForm from './OfferForm';
import {
  getOffers,
  WeecoverOfferResponse,
} from '../../../../services/mechanicalWarraties';

type OfferPropsType = {
  setStep: Dispatch<SetStateAction<'OFFER' | 'ISSUANCE'>>;
  selectedOffer: WeecoverOfferResponse;
  setSelectedOffer: Dispatch<SetStateAction<WeecoverOfferResponse>>;
  offerFormState: OfferFormStateType;
  setOfferFormState: Dispatch<SetStateAction<OfferFormStateType>>;
  hasNotMechanicalWarrantiesActivated: boolean;
};

export type FormErrorsType = {
  plate: boolean;
  kilometers: boolean;
  engineCapacity: boolean;
  price: boolean;
  carRegistrationDate: boolean;
};

export default function Offer({
  setStep,
  selectedOffer,
  setSelectedOffer,
  offerFormState,
  setOfferFormState,
  hasNotMechanicalWarrantiesActivated,
}: OfferPropsType) {
  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState<WeecoverOfferResponse[]>([]);
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    plate: false,
    engineCapacity: false,
    kilometers: false,
    price: false,
    carRegistrationDate: false,
  });

  const { getAccessTokenSilently } = useAuth0();

  const fetchOffers = async (payload: OfferFormStateType) => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await getOffers(token, {
        engineCapacity: Number(payload.engineCapacity),
        kilometers: Number(payload.kilometers),
        carRegistration: payload.carRegistration,
        carRegistrationDate: payload.carRegistrationDate?.split('T')[0],
        price: Number(payload.price),
      });
      setOffers(response);
    } finally {
      setIsLoading(false);
    }
  };

  const hasEmptyFields = useMemo(
    () =>
      Object.values(offerFormState).some(
        (field) => field === '' || field === null || field === undefined,
      ),
    [offerFormState],
  );

  const hasErrors = useMemo(
    () => Object.values(formErrors).some((field) => field),
    [formErrors],
  );

  useEffect(() => {
    if (!hasEmptyFields && !hasErrors) {
      fetchOffers(offerFormState);
    } else {
      setOffers([]);
    }
  }, [offerFormState, hasEmptyFields, hasErrors]);

  const renderResults = () => {
    if (isLoading) {
      return (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5 flex justify-center items-center min-h-[300px]">
          <Spinner size={10} color="text-blue-500" marginTop={0} />
        </div>
      );
    }

    if (!offers.length) {
      return (
        <p className="flex justify-center items-center text-md h-[150px]">
          Sin resultados
        </p>
      );
    }

    return (
      <Results
        offers={offers}
        setStep={setStep}
        selectedOffer={selectedOffer}
        setSelectedOffer={setSelectedOffer}
        hasNotMechanicalWarrantiesActivated={
          hasNotMechanicalWarrantiesActivated
        }
      />
    );
  };

  return (
    <>
      <OfferForm
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        offerFormState={offerFormState}
        setOfferFormState={setOfferFormState}
      />
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">
            Resultados
          </span>
        </div>
      </div>
      {renderResults()}
    </>
  );
}

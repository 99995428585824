import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  checkDateRangeValid,
  isValidDate,
} from '../../transfers/helpers/helpers';

import { statusEnums } from '../../../../helpers/enums';
import usePagination from '../../../../hooks/usePagination';

export default function useFilters() {
  const [companies, setCompanies] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, goNext, goPrev, setPage] = usePagination();

  const [minDate, setMinDate] = useState(
    searchParams.get('minDate')
      ? new Date(searchParams.get('minDate')).toISOString().substring(0, 10)
      : '',
  );
  const [maxDate, setMaxDate] = useState(
    searchParams.get('maxDate')
      ? new Date(searchParams.get('maxDate')).toISOString().substring(0, 10)
      : '',
  );
  const [dateSort, setDateSort] = useState(
    searchParams.get('dateSort') ? searchParams.get('dateSort') : 'DESC',
  );

  const statusItems = statusEnums.map((status) => ({
    key: 'status',
    name: status.label,
    value: status.id,
    color: status.bgColor,
  }));

  const incidentTypeItems = [
    {
      key: 'hasIncident',
      name: 'Todas',
      value: !searchParams.get('hasIncident'),
      entityType: '',
    },
    ...incidentTypes.map((incidentType) => ({
      key: 'incidentType',
      name: incidentType.name,
      value: incidentType.id,
      entityType: incidentType.entityType,
    })),
  ];

  const handleFilter = (key, value) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (
      (key === 'status' &&
        Boolean(searchParams.get('status')) &&
        Number(searchParams.get('status')) === value) ||
      (key === 'hasIncident' && Boolean(searchParams.get('hasIncident'))) ||
      (key === 'incidentType' &&
        Boolean(searchParams.get('incidentType')) &&
        searchParams.get('incidentType') === value) ||
      (key === 'companyId' &&
        Boolean(searchParams.get('companyId')) &&
        searchParams.get('companyId') === value) ||
      (key === 'accountId' &&
        Boolean(searchParams.get('accountId')) &&
        searchParams.get('accountId') === value) ||
      (key === 'tramitType' &&
        Boolean(searchParams.get('tramitType')) &&
        searchParams.get('tramitType') === value)
    ) {
      updatedSearchParams.delete(key);
    } else {
      updatedSearchParams.set(key, value);
    }

    if (key === 'hasIncident' && Boolean(searchParams.get('incidentType'))) {
      updatedSearchParams.delete('incidentType');
    }
    if (key === 'incidentType' && Boolean(searchParams.get('hasIncident'))) {
      updatedSearchParams.delete('hasIncident');
    }

    updatedSearchParams.set('page', 0);
    setPage(0);
    setSearchParams(updatedSearchParams);
  };

  const handleLabelsFilter = (labelId) => {
    const labels = searchParams.getAll('labels');
    if (labels.includes(labelId)) {
      searchParams.delete('labels');
      labels.forEach((label) => {
        if (label !== labelId) {
          searchParams.append('labels', label);
        }
      });
    } else {
      searchParams.append('labels', labelId);
    }
    setSearchParams(searchParams);
  };

  const handleDateSort = () => {
    if (dateSort === 'DESC') {
      setDateSort('ASC');
    }
    if (dateSort === 'ASC') {
      setDateSort('DESC');
    }
  };

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    const isDateRangeValid = checkDateRangeValid(minDate, maxDate);

    if (
      minDate &&
      maxDate &&
      isValidDate(new Date(minDate)) &&
      isValidDate(new Date(maxDate)) &&
      isDateRangeValid
    ) {
      updatedSearchParams.set('minDate', minDate);
      updatedSearchParams.set('maxDate', maxDate);
    } else {
      updatedSearchParams.delete('minDate');
      updatedSearchParams.delete('maxDate');
    }
    setSearchParams(updatedSearchParams);
  }, [minDate, maxDate]);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (!['DESC', 'ASC'].includes(dateSort)) {
      setDateSort('DESC');
    } else {
      updatedSearchParams.set('dateSort', dateSort);
      setSearchParams(updatedSearchParams);
    }
  }, [dateSort]);

  return {
    page,
    dateSort,
    minDate,
    maxDate,
    incidentTypeItems,
    statusItems,
    handleFilter,
    searchParams,
    handleLabelsFilter,
    goNext,
    goPrev,
    setIncidentTypes,
    setMinDate,
    setMaxDate,
    handleDateSort,
    companies,
    setCompanies,
    labelOptions,
    setLabelOptions,
    accounts,
    setAccounts,
  };
}

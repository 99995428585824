import { useState, useRef } from 'react';
import { useServices } from '../../../services';
import useClickOutside from '../../../hooks/useClickOutside';

export default function NewBadgeModal({ save, setShowModal, showModal }) {
  const initialFormState = {
    transactionCode: '',
    plate: '',
  };

  const [formState, setFormState] = useState(initialFormState);
  const [loading, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAssociatedTransfer, setIsAssociatedTransfer] = useState('NO');

  const { createEnvironmentalBadge } = useServices();

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const resetModal = () => {
    setShowModal(false);
    setFormState(initialFormState);
    setLoader(false);
    setErrorMessage('');
    setIsAssociatedTransfer('NO');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      await createEnvironmentalBadge(formState);
      setLoader(false);
      resetModal();
      save();
    } catch (error) {
      setLoader(false);
      if (error.response.status === 404) {
        setErrorMessage('No existe ninguna transferencia con ese código');
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  const modalRef = useRef(null);
  useClickOutside(modalRef, () => {
    setShowModal(false);
    resetModal();
  });

  return (
    showModal && (
      <div className="w-full h-full fixed left-0 top-0 bg-black/75 z-30 flex items-center justify-center">
        <div
          ref={modalRef}
          className="p-10 flex flex-col items-start bg-white rounded-md shadow-md"
        >
          <form onSubmit={handleSubmit}>
            {errorMessage && (
              <p className="text-red-500 mb-2">{errorMessage}</p>
            )}

            <h3
              className="text-lg font-medium leading-6 text-gray-900 mb-4"
              id="modal-title"
            >
              ¿Está vinculado a una transferencia?
            </h3>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 mb-6">
                <label
                  htmlFor="associatedTransfer"
                  className="block text-sm font-medium text-gray-700"
                />
                <select
                  id="associatedTransfer"
                  name="associatedTransfer"
                  required
                  onChange={(e) => setIsAssociatedTransfer(e.target.value)}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Selecciona una opción</option>
                  <option value="YES">Sí</option>
                  <option value="NO">No</option>
                </select>
              </div>
            </div>
            {isAssociatedTransfer === 'YES' ? (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5 flex items-center mb-4 w-80">
                <div>
                  <label htmlFor="transactionCode">
                    Código de la transferencia
                  </label>
                </div>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    onChange={handleChange}
                    value={formState.transactionCode}
                    type="text"
                    name="transactionCode"
                    id="transactionCode"
                    required
                    disabled={isAssociatedTransfer ? '' : true}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            ) : (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5 flex items-center mb-4 w-80">
                <div>
                  <label htmlFor="plate">Matrícula</label>
                </div>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    onChange={handleChange}
                    value={formState.plate}
                    type="text"
                    name="plate"
                    id="plate"
                    required
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            )}

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="px-4 py-1 bg-indigo-500 text-white rounded-md hover:bg-indigo-600"
              >
                {loading && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                )}
                {loading ? 'Creando...' : 'Crear'}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

import axios, { AxiosResponse } from 'axios';
import { Company, DeliveryAddress, User } from '../types/types';
import { TrackingResult } from '../Modules/Shipments/components/Tracking/Tracking';

export const getUserById = async (
  id: string,
  token: string,
): Promise<AxiosResponse<User>> =>
  axios.get(`${process.env.REACT_APP_BASE_API_URL}/get-user-info/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getCompanyInfoById = async (
  id: string,
  token: string,
): Promise<AxiosResponse<Company>> =>
  axios.get(`${process.env.REACT_APP_BASE_API_URL}/get-company-info/id/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateShipment = async (
  id: string,
  payload: {
    state?: string;
    city?: string;
    street?: string;
    zipCode?: string;
    doi?: string;
    email?: string;
    name?: string;
    phone?: string;
    items?: string[];
  },
  token: string,
): Promise<AxiosResponse<void>> =>
  axios.put(`${process.env.REACT_APP_ZANELLA_URL}/shipments/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAllDeliveryAddress = async (
  accountId: string,
  token: string,
): Promise<AxiosResponse<DeliveryAddress[]>> =>
  axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/delivery-address/${accountId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const getTrackingByShipmentId = async (
  shipmentId: string,
  token: string,
): Promise<AxiosResponse<TrackingResult>> =>
  axios.get<TrackingResult>(
    `${process.env.REACT_APP_ZANELLA_URL}/tracking/${shipmentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

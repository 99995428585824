import { Menu, Transition } from '@headlessui/react';
import { Tooltip } from 'react-tooltip';
import { DotsVerticalIcon, PencilIcon } from '@heroicons/react/outline';
import ShipButton from './ShipButton';
import { useServices } from '../../../services';
import { SHIPMENT_STATUS, isShipmentEditable } from '../../../helpers/enums';

export default function ShipmentMenu({
  setIsCancelLoading,
  setShipError,
  forceUpdate,
  areRolesComplete,
  setShowDeliveryInfoModal,
  setEditedShipment,
  shipment,
  orderCreationLoading,
  setOrderCreationLoading,
  isDocumentOnOffice,
}) {
  const { updateShipment } = useServices();

  const updateShipmentStatus = async (id, status) => {
    try {
      setIsCancelLoading(true);
      await updateShipment(id, {
        status,
      });
    } catch (e) {
      setShipError('Ha ocurrido un error al cancelar el envío');
    }
    forceUpdate();
    setIsCancelLoading(false);
  };

  const generateTooltipId = () => {
    if (!areRolesComplete) return 'ship-tooltip-edit';
    return '';
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {isShipmentEditable(shipment.status) && (
        <>
          <div>
            <Menu.Button className="flex items-center">
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          />
          <Menu.Items className="absolute z-10 right-8 top-7 -translate-y-full w-40 rounded-md bg-white border border-gray-200">
            <div className="py-1">
              {shipment.status === 'CREATED' && (
                <>
                  <Tooltip
                    id="ship-tooltip-edit"
                    style={{
                      marginTop: '0',
                      fontSize: '15px',
                    }}
                    content="Falta asignar comprador, vendedor o intermediario"
                    place="bottom"
                  />
                  <Menu.Item disabled={!areRolesComplete}>
                    {({ active }) => (
                      <div
                        className="flex items-center"
                        data-tooltip-id={generateTooltipId()}
                      >
                        <button
                          type="button"
                          disabled={!areRolesComplete}
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } flex w-full px-4 py-2 text-sm text-left`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDeliveryInfoModal(true);
                            setEditedShipment(shipment);
                          }}
                        >
                          Editar
                          <PencilIcon
                            className={`w-4 h-4 ${
                              areRolesComplete
                                ? 'text-indigo-600'
                                : 'text-gray-400'
                            } ml-4`}
                          />
                        </button>
                      </div>
                    )}
                  </Menu.Item>
                </>
              )}

              {shipment.status === SHIPMENT_STATUS.CREATED &&
                !shipment.otn?.trim() && (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={`${
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } flex justify-between w-full px-4 py-2 text-sm text-left`}
                      >
                        <ShipButton
                          shipment={shipment}
                          orderCreationLoading={orderCreationLoading}
                          setOrderCreationLoading={setOrderCreationLoading}
                          setShipError={setShipError}
                          forceUpdate={forceUpdate}
                          isDocumentOnOffice={isDocumentOnOffice}
                        />
                      </div>
                    )}
                  </Menu.Item>
                )}

              {shipment.status !== SHIPMENT_STATUS.READY_TO_SHIP &&
                shipment.otn?.trim() && (
                  <>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={`${
                            active ? 'bg-gray-100 text-red-600' : 'text-red-600'
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                          onClick={() =>
                            updateShipmentStatus(
                              shipment.id,
                              SHIPMENT_STATUS.LOST,
                            )
                          }
                        >
                          Marcar como extraviado
                        </button>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={`${
                            active ? 'bg-gray-100 text-red-600' : 'text-red-600'
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                          onClick={() =>
                            updateShipmentStatus(
                              shipment.id,
                              SHIPMENT_STATUS.RETURNED,
                            )
                          }
                        >
                          Marcar como devuelto
                        </button>
                      )}
                    </Menu.Item>
                  </>
                )}

              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={`${
                      active ? 'bg-gray-100 text-red-600' : 'text-red-600'
                    } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                    onClick={() =>
                      updateShipmentStatus(
                        shipment.id,
                        SHIPMENT_STATUS.CANCELED,
                      )
                    }
                  >
                    Cancelar envío
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}

import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon, DownloadIcon } from '@heroicons/react/outline';

import Button from '../../../components/Button';
import classNames from '../../../helpers/classNames';
import PreviewDocument from './PreviewDocuments';
import useDownloadFile from '../../../hooks/useDownloadFile';

type AgencyRowData = {
  icon: React.ReactElement;
  label: string;
  docURI: string;
};

function AgencyDocumentsActions({
  row,
}: Readonly<{
  row: AgencyRowData;
}>): React.ReactElement {
  const [showPreview, setShowPreview] = useState(false);

  const { download } = useDownloadFile();

  const switchPreviewModal = () => setShowPreview((prev) => !prev);

  const handleDownload = () => {
    download(row.docURI, row.label);
  };

  return (
    <>
      {row.docURI && (
        <PreviewDocument uri={row.docURI} toggler={showPreview} forcePdf />
      )}

      <tr key={row.docURI}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <div className="flex justify-start">
            <span className="mr-2">{row.icon}</span> {row.label}
          </div>
        </td>
        <td className="flex justify-end whitespace-nowrap py-4 px-8 text-sm font-medium text-gray-900">
          {row.docURI ? (
            <>
              <Button
                bgColor="bg-white"
                hoverBgColor="bg-gray-100"
                text="Ver"
                textColor="text-gray-900"
                border="border"
                borderColor="border-gray-300"
                callback={switchPreviewModal}
              />
              <Menu
                as="div"
                className="relative inline-block text-left pt-2 ml-3"
              >
                <div>
                  <Menu.Button className="flex items-center">
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-8 top-7 -translate-y-full z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'flex px-4 py-2 text-sm w-full items-center justify-start gap-3',
                            )}
                            onClick={handleDownload}
                          >
                            <DownloadIcon className="w-5 h-5" />
                            Descargar
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </>
          ) : (
            <p>No disponible</p>
          )}
        </td>
      </tr>
    </>
  );
}

function AgencyDocumentsTable({
  agencyDocumentsRows,
}: Readonly<{
  agencyDocumentsRows: AgencyRowData[];
}>): React.ReactElement {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          >
            Documentos de gestoría
          </th>
          <th
            scope="col"
            className="px-8 py-3.5 text-right text-sm font-semibold text-gray-900"
          >
            Acciones
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {agencyDocumentsRows.map((row) => (
          <AgencyDocumentsActions row={row} key={row.label} />
        ))}
      </tbody>
    </table>
  );
}

export default AgencyDocumentsTable;

import LinkifyIt from 'linkify-it';
import { useEffect, useState } from 'react';

export default function Comment({ text }) {
  const [parsed, setParsed] = useState(text);

  useEffect(() => {
    const matches = LinkifyIt()
      .match(text)
      ?.map((match) => match.raw);

    const indexes = [];

    if (!matches) return;

    const parsedText = text.split(' ').map((word, i) => {
      if (matches.includes(word)) {
        indexes.push(i);
        return (
          <a
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            href={word}
            target="_blank"
            rel="noreferrer"
            className="text-blue-700"
          >
            {word}
          </a>
        );
      }
      return `${word} `;
    });

    indexes.forEach((index) => {
      if (!parsedText[index + 1]) return;
      parsedText.splice(index + 1, 0, ' ');
    });

    setParsed(parsedText);
  }, [text]);

  return <p>{parsed}</p>;
}

import React, { useContext, useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { transferDetailsContext } from '../../../../../../context/transfer-details.context';

import BasicDocs from './BasicDocs';

import ExtraFiles from './ExtraFiles';
import DigitalSignatureDocs from './DigitalSignatureDocs';
import SelectedDocs from './SelectedDocs';
import useClickOutside from '../../../../../../hooks/useClickOutside';
import { useServices } from '../../../../../../services';

const GENERATION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

function GenerateDocumentationModal({ isOpen, setIsOpen }) {
  const { transfer } = useContext(transferDetailsContext);

  const [existingMergedDoc, setExistingMergedDoc] = useState(null);
  const [generationStatus, setGenerationStatus] = useState(
    GENERATION_STATUS.NOT_STARTED,
  );

  const { getS3SignedUrl } = useServices();

  const getExistingSignedUrlMergedDoc = async () => {
    const { signedUrl } = await getS3SignedUrl(
      'swipoo-merged-pdfs',
      `${transfer.transaction.transactionCode}.pdf`,
    );
    try {
      await axios.get(signedUrl);
      setExistingMergedDoc(signedUrl);
    } catch {
      setExistingMergedDoc(null);
    }
  };

  useEffect(() => {
    if (!transfer.transaction.transactionCode) return;
    try {
      getExistingSignedUrlMergedDoc();
    } catch (error) {
      //
    }
  }, []);

  const [selectedDocs, setSelectedDocs] = useState([]);

  const handleInputCheck = (checked, docFamily, docName, docSource) => {
    if (checked) {
      setSelectedDocs([
        ...selectedDocs,
        {
          family: docFamily,
          docName,
          source: docSource,
        },
      ]);
      return;
    }
    setSelectedDocs(
      selectedDocs.filter(
        (d) => !(d.family === docFamily && d.docName === docName),
      ),
    );
  };

  const modal = useRef(null);

  useClickOutside(modal, () => {
    setIsOpen(false);
    setSelectedDocs([]);
  });

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    const items = Array.from(selectedDocs);
    const [reorderedItem] = items.splice(e.source.index, 1);
    items.splice(e.destination.index, 0, reorderedItem);
    setSelectedDocs(items);
  };

  const handleGenerateDocs = async () => {
    try {
      setGenerationStatus(GENERATION_STATUS.LOADING);

      const urls = await Promise.all(
        selectedDocs
          .map((e) => e.source)
          .map(async (e) => {
            if (e.includes('suzuki-') || e.includes('storage.googleapis.com')) {
              return e;
            }
            const { signedUrl } = await getS3SignedUrl(
              'swipoo-transactions-files',
              e,
            );
            return signedUrl;
          }),
      );

      await axios.post(`${process.env.REACT_APP_PIAGGIO_BASE_URL}/merge-pdf`, {
        transactionCode: transfer.transaction.transactionCode,
        urls,
      });

      getExistingSignedUrlMergedDoc();
      setGenerationStatus(GENERATION_STATUS.NOT_STARTED);
    } catch {
      setGenerationStatus(GENERATION_STATUS.ERROR);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black/50 flex items-center justify-center z-30">
      <div
        className=" bg-white p-4 rounded-md shadow-md min-w-[900px]"
        ref={modal}
      >
        <div className="flex gap-2">
          <div className="p-4">
            <h2 className="text-lg font-medium mb-4">Documentos disponibles</h2>
            <BasicDocs
              handleInputCheck={handleInputCheck}
              selectedDocs={selectedDocs}
            />
            <ExtraFiles
              handleInputCheck={handleInputCheck}
              selectedDocs={selectedDocs}
            />
            <DigitalSignatureDocs
              handleInputCheck={handleInputCheck}
              selectedDocs={selectedDocs}
            />
          </div>
          <div className="flex-grow">
            <SelectedDocs
              handleDragEnd={handleDragEnd}
              selectedDocs={selectedDocs}
            />
          </div>
        </div>
        <div className="flex items-center">
          <button
            type="button"
            className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-700 disabled:opacity-40"
            disabled={generationStatus === GENERATION_STATUS.LOADING}
            onClick={handleGenerateDocs}
          >
            Generar documentos
          </button>
          {existingMergedDoc && generationStatus !== GENERATION_STATUS.LOADING && (
            <a
              href={existingMergedDoc}
              target="_blank"
              rel="noreferrer"
              className=" text-indigo-500 hover:text-indigo-700 ml-4"
            >
              Ver existente
            </a>
          )}
          {generationStatus === GENERATION_STATUS.ERROR && (
            <p className="text-right flex-1 text-red-500 mr-4">
              Error generando el documento conjunto
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default GenerateDocumentationModal;

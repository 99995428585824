import React, { useEffect, useState } from 'react';
import { BadgeCheckIcon, ExclamationIcon } from '@heroicons/react/solid';

import Card from './ModuleWrapper';
import { useServices } from '../../../../services';
import { parseDayMonthYearWithSlash } from '../../../../helpers';
import Spinner from '../../../../components/Spinner';

const vehicleFields = ({ policyInfo }) => [
  {
    label: 'brand',
    displayValue: 'Marca',
    value: policyInfo.insuredObject.brand,
  },
  {
    label: 'model',
    displayValue: 'Modelo',
    value: policyInfo.insuredObject.model,
  },
  {
    label: 'chassisNumber',
    displayValue: 'Bastidor',
    value: policyInfo.insuredObject.chassisNumber,
  },
  {
    label: 'kilometers',
    displayValue: 'Kilómetros',
    value: `${policyInfo.insuredObject.kilometers} Kms`,
  },
  {
    label: 'engineCapacity',
    displayValue: 'Cilindrada',
    value: `${policyInfo.insuredObject.engineCapacity} cc`,
  },
  {
    label: 'carRegistrationDate',
    displayValue: 'Fecha de matriculación',
    value: parseDayMonthYearWithSlash(
      policyInfo.insuredObject.carRegistrationDate,
    ),
  },
];

const beneficiaryFields = ({ policyInfo }) => {
  const beneficiary = policyInfo?.persons.find(
    (p) => p.personPolicyType === 'BENEFICIARY',
  );
  return [
    {
      label: 'name',
      displayValue: 'Nombre',
      value: beneficiary.name,
    },
    {
      label: 'surname',
      displayValue: 'Apellidos',
      value: beneficiary.surname,
    },
    {
      label: 'document',
      displayValue: 'DNI',
      value: beneficiary.document,
    },
    {
      label: 'address',
      displayValue: 'Dirección',
      value: `${beneficiary.address}, ${beneficiary.postalCode}, ${beneficiary.town}`,
    },
    {
      label: 'email',
      displayValue: 'Correo',
      value: beneficiary.email,
    },
    {
      label: 'phone',
      displayValue: 'Teléfono',
      value: beneficiary.phoneNumber,
    },
  ];
};

const infoFields = ({
  policyInfo,
  annualPremium,
  commissionAmount,
  issueDate,
}) => [
  {
    label: 'company',
    displayValue: 'Empresa',
    value: policyInfo.productInfo?.company,
  },
  {
    label: 'productName',
    displayValue: 'Producto',
    value: `${policyInfo.productInfo?.productName} (${policyInfo.productInfo?.productCode})`,
  },
  {
    label: 'modalityName',
    displayValue: 'Modalidad',
    value: `${policyInfo.productInfo?.modalityName} (${policyInfo.productInfo?.modalityCode})`,
  },
  {
    label: 'duration',
    displayValue: 'Duración',
    value: `${policyInfo.duration} año${policyInfo.duration === 1 ? '' : 's'}`,
  },
  {
    label: 'annualPremium',
    displayValue: 'Precio',
    value: `${annualPremium} €`,
  },
  {
    label: 'commissionAmount',
    displayValue: 'Comisión',
    value: `${commissionAmount} €`,
  },
  {
    label: 'issueDate',
    displayValue: 'Fecha de Emisión',
    value: parseDayMonthYearWithSlash(issueDate),
  },
  {
    label: 'paymentStatus',
    displayValue: 'Pagado',
    value:
      policyInfo.paymentInformation?.paymentStatus === 'PAID'
        ? 'Pagado'
        : 'No pagado',
  },
  {
    label: 'policyStatus',
    displayValue: 'Estado',
    value: policyInfo.status === 'ACTIVE' ? 'Activa' : 'Inactiva',
  },
];

const renderIconsWhenNecessary = (e) => {
  if (e.label !== 'paymentStatus' && e.label !== 'policyStatus') return null;
  if (e.value === 'Activa' || e.value === 'Pagado')
    return <BadgeCheckIcon className="text-teal-500 h-5 ml-1" />;
  return <ExclamationIcon className="text-red-500 h-5 ml-1" />;
};

export default function MechanicalWarranty({ carId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [mechanicalWarranty, setMechanicalWarranty] = useState(null);

  const { getMechanicalWarranty } = useServices();

  useEffect(() => {
    const fetchMechanicalWarrranty = async () => {
      try {
        setIsLoading(true);
        const response = await getMechanicalWarranty(carId);
        setMechanicalWarranty(response);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMechanicalWarrranty();
  }, []);

  if (!mechanicalWarranty) return null;

  return (
    <Card title="Garantía Mecánica Contratada">
      <div className="px-4 py-5 sm:px-6">
        <div className="overflow-hidden bg-white h-full w-full flex flex-col">
          {isLoading && !mechanicalWarranty ? (
            <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5 justify-center items-center w-full flex flex-col gap-5 h-[200px]">
              <Spinner size={10} color="text-blue-500" marginTop={0} />
            </div>
          ) : (
            <>
              <div className="mb-3">
                <div className="px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                    {infoFields(mechanicalWarranty)?.map((e) => (
                      <React.Fragment
                        key={`${e.label}-${mechanicalWarranty.id}`}
                      >
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            {e.displayValue}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 flex">
                            {e.value || '---'} {renderIconsWhenNecessary(e)}
                          </dd>
                        </div>
                      </React.Fragment>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="px-2 bg-white text-sm text-gray-500">
                    Beneficiario
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <div className="px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                    {beneficiaryFields(mechanicalWarranty)?.map((e) => (
                      <React.Fragment
                        key={`${e.label}-${mechanicalWarranty.id}`}
                      >
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            {e.displayValue}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 flex">
                            {e.value || '---'}
                          </dd>
                        </div>
                      </React.Fragment>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">
                    Vehículo
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <div className="px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                    {vehicleFields(mechanicalWarranty)?.map((e) => (
                      <React.Fragment
                        key={`${e.label}-${mechanicalWarranty.id}`}
                      >
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            {e.displayValue}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 flex">
                            {e.value || '---'}
                          </dd>
                        </div>
                      </React.Fragment>
                    ))}
                  </dl>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
}

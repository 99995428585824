import { useContext, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';
import getLocalDate from '../helpers/getLocalDate';

export default function useDatePicker() {
  const { setSingleParam, getSingleParam } = useContext(queryParamsContext);

  const [date, setDate] = useState<DateRange | undefined>({
    from: getSingleParam('minDate')
      ? new Date(getSingleParam('minDate'))
      : undefined,
    to: getSingleParam('maxDate')
      ? new Date(getSingleParam('maxDate'))
      : undefined,
  });

  useEffect(() => {
    setSingleParam('minDate', date?.from ? getLocalDate(date.from) : '');
    setSingleParam('maxDate', date?.to ? getLocalDate(date.to) : '');
  }, [date, setSingleParam]);

  return { date, setDate };
}

import React from 'react';

export default function MechanicalWarrantiesTableSkeletonLoader() {
  return (
    <div className="overflow-x-auto min-h-fit shadow ring-1 ring-black ring-opacity-5 rounded-lg">
      <table className="table-fixed min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
            >
              Núm. póliza
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Matrícula
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Precio
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Fecha de emisión
            </th>
            <th
              scope="col"
              className="sticky right-0 bg-gray-50 py-3.5 pl-3 pr-4 shadow-md sm:pr-6"
            >
              <span className="sr-only">Ver</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {[...Array(10)].map((transfer, personIdx) => (
            <tr
              key={transfer}
              className={personIdx % 2 === 0 ? '' : 'bg-gray-50'}
            >
              <td className="min-w-40 px-3 sm:px-6 align-middle whitespace-nowrap text-sm text-gray-900 flex items-center gap-3 h-14 font-medium">
                <div className="w-full h-4 bg-gray-200 rounded-lg text-transparent animate-pulse" />
              </td>
              <td className="min-w-36 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                <div className="w-full h-4 bg-gray-200 rounded-lg text-transparent animate-pulse" />
              </td>
              <td className="min-w-36 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                <div className="w-full h-4 bg-gray-200 rounded-lg text-transparent animate-pulse" />
              </td>
              <td className="min-w-48 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                <div className="w-full h-4 bg-gray-200 rounded-lg text-transparent animate-pulse" />
              </td>
              <td
                className={`${
                  personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                } sticky right-0 min-w-16 w-16 px-3 align-middle whitespace-nowrap text-center text-sm font-medium`}
              >
                <div className="w-full h-4 bg-gray-200 rounded-lg text-transparent animate-pulse" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

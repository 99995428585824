import React from 'react';
import { CalendarIcon, FolderIcon } from '@heroicons/react/solid';

import { Car } from '../types/types';
import SkeletonPlaceholder from './SkeletonPlaceholder';
import { parseDayMonthYear } from '../helpers/parseDate';

export default function Title({
  isLoading,
  vehicle,
  tramitSubtitleType,
  creationDate,
}: {
  isLoading: boolean;
  vehicle?: Car;
  tramitSubtitleType?: string;
  creationDate?: string;
}) {
  return isLoading ? (
    <div className="flex flex-col gap-y-4 w-1/2">
      <SkeletonPlaceholder width={40} height={5} />
      <div className="flex text-gray-500">
        <div className="flex mr-8 gap-x-2">
          <FolderIcon className="w-5 h-5 text-gray-400" />{' '}
          <SkeletonPlaceholder width={20} height={5} />
        </div>
        <div className="flex gap-x-2">
          <CalendarIcon className="w-5 h-5 text-gray-400" />
          <SkeletonPlaceholder width={20} height={5} />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-y-4" id="plate-type-date-container">
      <h1 className="text-2xl font-semibold">
        {vehicle?.plate} - {vehicle?.brand} {vehicle?.model}
      </h1>
      <div className="flex text-gray-500">
        <div className="flex mr-8 gap-x-2">
          <FolderIcon className="w-5 h-5 text-gray-400" />{' '}
          <p className="text-sm">{tramitSubtitleType}</p>
        </div>
        <div className="flex gap-x-2">
          <CalendarIcon className="w-5 h-5 text-gray-400" />
          <p className="text-sm">{parseDayMonthYear(new Date(creationDate))}</p>
        </div>
      </div>
    </div>
  );
}

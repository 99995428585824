import React, { createContext, useEffect, useMemo, useState } from 'react';
import Toaster from './Toaster/Toaster';

export type ToastType = 'SUCCESS' | 'ERROR' | 'INFO' | 'WARNING';

export type ToasterData = {
  title: string;
  message: string;
  type: ToastType;
};

export interface ToasterContextType {
  data: ToasterData;
  setToasterData: (data: ToasterData) => void;
  toastOpen: boolean;
  closeToast: () => void;
}

export const toasterContext = createContext<ToasterContextType>(null);

export function ToasterContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [toastOpen, setToastOpen] = useState(false);
  const [data, setToasterData] = useState<ToasterData>({
    title: '',
    message: '',
    type: 'INFO',
  });

  const closeToast = () => {
    setToastOpen(false);
  };

  useEffect(() => {
    if (data.title) {
      setToastOpen(true);
    }
  }, [data]);

  return (
    <toasterContext.Provider
      value={useMemo(
        () => ({ toastOpen, data, closeToast, setToasterData }),
        [data, toastOpen],
      )}
    >
      <Toaster />
      {children}
    </toasterContext.Provider>
  );
}

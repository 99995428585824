import { useEffect, useState } from 'react';
import { useServices } from '../../../../../services';
import useShipment from '../useShipment';
import { ERROR_SHIPMENT_STATUS } from '../../../../../helpers/enums';

export default function useTransactionShipments({
  transfer,
  transaction,
  updater,
  environmentalBadge,
}) {
  const [availableItems, setAvailableItems] = useState([]);
  const [uncreatedItems, setUncreatedItems] = useState([]);
  const [editedShipment, setEditedShipment] = useState('');

  const [items, setItems] = useState([]);

  const { getItemByEntityId } = useServices();

  const { shipments, isShipmentLoading, getShipmentError } = useShipment({
    transaction,
    environmentalBadgeId: environmentalBadge?.id,
    updater,
  });

  const areRolesComplete =
    transaction?.buyerId &&
    transaction?.sellerId &&
    transaction?.intermediaryId;

  useEffect(() => {
    (async () => {
      try {
        if (!transaction?.id) return;

        const { data: item } = await getItemByEntityId(transaction.id);

        setItems((prev) => [...prev, { ...item, isChecked: false }]);
      } catch (e) {
        if (e.message.includes(404)) {
          setUncreatedItems((prev) => [
            ...prev,
            {
              type: 'CIRCULATION_PERMIT',
              entityId: transaction.id,
              entityType: 'transaction',
              plate: transfer?.car?.plate,
              isChecked: false,
            },
          ]);
        }
      }
    })();
  }, [transaction]);

  useEffect(() => {
    (async () => {
      try {
        if (!environmentalBadge) return;

        const { data: item } = await getItemByEntityId(environmentalBadge?.id);

        setItems((prev) => [...prev, { ...item, isChecked: false }]);
      } catch (e) {
        if (e.message.includes(404)) {
          setUncreatedItems((prev) => [
            ...prev,
            {
              type: 'ENVIRONMENTAL_BADGE',
              entityId: transaction.id,
              entityType: 'transaction',
              plate: transfer?.car?.plate,
              isChecked: false,
            },
          ]);
        }
      }
    })();
  }, [environmentalBadge]);

  useEffect(() => {
    items.forEach((item) => {
      const isItemAvailable = shipments.every(
        (s) =>
          s.items.every((i) => i.type !== item.type) ||
          (s.items.some((i) => i.type === item.type) &&
            ERROR_SHIPMENT_STATUS.includes(s.status)),
      );

      setAvailableItems((prev) => {
        if (isItemAvailable) {
          return !prev.some((i) => i.type === item.type)
            ? [
                ...prev,
                {
                  id: item.id,
                  type: item.type,
                  entityId: transaction.id,
                  entityType: 'transaction',
                  plate: transfer?.car?.plate,
                  isChecked: false,
                },
              ]
            : prev;
        }
        return prev.some((i) => i.type === item.type)
          ? prev.filter((i) => i.type !== item.type)
          : prev;
      });
    });
  }, [items, shipments]);

  return {
    editedShipment,
    transaction,
    availableItems,
    setAvailableItems,
    isShipmentLoading,
    getShipmentError,
    shipments,
    items,
    uncreatedItems,
    setUncreatedItems,
    setEditedShipment,
    areRolesComplete,
    accountId: transaction?.accountId,
    possibleReceivers: {
      buyer: transaction?.buyerId,
      seller: transaction?.sellerId,
      intermediary: transaction?.intermediaryId,
    },
    isDocumentOnOffice: transaction.status === 8,
    isPro: Boolean(transfer.intermediary?.representative),
  };
}

import React, { useEffect, useRef } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import { User, Company } from '../../types/types';
import CompanyFields from './CompanyFields/CompanyFields';
import UserFields from './UserFields/UserFields';

type EditPartModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  preloadedValue: User | Company;
  type: 'Comprador' | 'Vendedor';
  onSave: () => void;
};

function EditPartModal({
  isOpen,
  setIsOpen,
  onSave,
  preloadedValue,
  type,
}: EditPartModalProps) {
  const modalRef = useRef();

  useClickOutside(modalRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (isOpen) return;
    onSave();
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black/75 flex items-center justify-center z-50">
      <div ref={modalRef} className="p-10 rounded-md shadow-md bg-white w-3/4">
        <h3 className="text-xl mb-3">{type}</h3>
        {(preloadedValue as Company)?.representativeId ? (
          <CompanyFields
            setIsOpen={setIsOpen}
            preloadedValue={preloadedValue as Company}
          />
        ) : (
          <UserFields
            type={type}
            setIsOpen={setIsOpen}
            preloadedValue={preloadedValue as User}
          />
        )}
      </div>
    </div>
  );
}

export default EditPartModal;

/* eslint-disable react/function-component-definition */

import { Company, User } from '../types/types';

type GetCountarpartEntityParams = {
  counterPartRole: 'buyer' | 'seller';
  buyer?: User | Company;
  seller?: User | Company;
};

export const getCounterpartEntityType =
  ({ counterPartRole, buyer, seller }: GetCountarpartEntityParams) =>
  () => {
    if (counterPartRole === 'buyer' && (buyer as User)?.dni !== undefined) {
      return 'user';
    }
    if (counterPartRole === 'buyer' && (buyer as Company)?.nif !== undefined) {
      return 'company';
    }
    if (counterPartRole === 'seller' && (seller as User)?.dni !== undefined) {
      return 'user';
    }
    if (
      counterPartRole === 'seller' &&
      (seller as Company)?.nif !== undefined
    ) {
      return 'company';
    }
    return null;
  };

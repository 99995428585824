import React, { useState, SetStateAction, Dispatch } from 'react';
import { XIcon } from '@heroicons/react/outline';

import { Shipment } from '../../types/types';
import Form from './components/Form/Form';

type DeliveryInfoModalProps = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  shipment: Shipment;
};

function DeliveryInfoModal({
  setShowModal,
  showModal,
  shipment,
}: DeliveryInfoModalProps) {
  const [error, setError] = useState('');

  return (
    showModal && (
      <div className="w-screen h-screen min-h-fit fixed overflow-scroll py-10 top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-black bg-opacity-40 z-50 overscroll-none">
        <div className=" inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
          <div className="space-y-6">
            <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-1 md:gap-6">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="flex items-start justify-between">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                      Completar Información de Envío
                    </h3>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={() => setShowModal(false)}
                      data-testid="close-icon"
                    >
                      <XIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        data-testid="icon"
                      />
                    </button>
                  </div>
                  {error && <p className="text-red-600">{error}</p>}
                  <Form
                    shipment={shipment}
                    setError={setError}
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default DeliveryInfoModal;

import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@headlessui/react';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BaseModal from '../../../../components/BaseModal';
import { classNames } from '../../../../helpers';

async function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result.split(';base64,')[1]);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

const typeDictionary = {
  buyer: 'Comprador',
  seller: 'Vendedor',
  agent: 'Gestor',
};

const STATE = {
  NOT_STARTED: 'NOT_STARTED',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

function CustomSignatureModal({ open, setOpen, transfer, forceUpdate }) {
  const [file, setFile] = useState(null);

  const [submitState, setSubmitState] = useState(STATE.NOT_STARTED);

  const correctedBuyerName =
    transfer?.buyer?.representative?.name || transfer?.buyer?.name;
  const correctedBuyerSurname =
    transfer?.buyer?.representative?.surname || transfer?.buyer?.surname;
  const correctedBuyerPhone =
    transfer?.buyer?.representative?.phone || transfer?.buyer?.phone;
  const correctedSellerName =
    transfer?.seller?.representative?.name || transfer?.seller?.name;
  const correctedSellerSurname =
    transfer?.seller?.representative?.surname || transfer?.seller?.surname;
  const correctedSellerPhone =
    transfer?.seller?.representative?.phone || transfer?.seller?.phone;

  const [signers, setSigners] = useState({
    buyer: {
      send: false,
      payload: {
        id: transfer?.buyer?.id,
        name: correctedBuyerName,
        surname: correctedBuyerSurname,
        role: 'buyer',
        phone: correctedBuyerPhone,
      },
      isDisabled:
        !correctedBuyerName || !correctedBuyerSurname || !correctedBuyerPhone,
    },
    seller: {
      send: false,
      payload: {
        id: transfer?.seller?.id,
        name: correctedSellerName,
        surname: correctedSellerSurname,
        role: 'seller',
        phone: correctedSellerPhone,
      },
      isDisabled:
        !correctedSellerName ||
        !correctedSellerSurname ||
        !correctedSellerPhone,
    },
    agent: {
      send: false,
      payload: {
        id: transfer?.agent?.id,
        name: transfer?.agent?.name,
        surname: transfer?.agent?.surname,
        role: 'agency',
        email: transfer?.agent?.email,
      },
      isDisabled:
        !transfer?.agent?.name ||
        !transfer?.agent?.surname ||
        !transfer?.agent?.email,
    },
  });

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!open) {
      setFile(null);
      setSubmitState(STATE.NOT_STARTED);
    }
  }, [open]);

  const handleSubmit = async () => {
    if (!file || !transfer) return;
    try {
      setSubmitState(STATE.LOADING);
      const base64 = await getBase64(file);

      const payload = [
        {
          id: crypto.randomUUID(),
          type: 'custom',
          title: file.name,
          entityType: 'transaction',
          entityCode: transfer?.transaction.transactionCode,
          pdfInBase64: base64,
          signers: Object.values(signers)
            .filter((signer) => signer.send)
            .map((signer) => ({
              id: signer.payload.id,
              name: `${signer.payload.name} ${signer.payload.surname}`,
              role: signer.payload.role,
              ...(signer.payload.email && { email: signer.payload.email }),
              ...(signer.payload.phone && { phone: signer.payload.phone }),
            })),
        },
      ];

      const token = await getAccessTokenSilently();

      await axios.post(
        `${process.env.REACT_APP_SUZUKI_URL}/documents-pro`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setSubmitState(STATE.NOT_STARTED);
      setFile(null);
      setOpen(false);
      forceUpdate();
    } catch {
      setSubmitState(STATE.ERROR);
    }
  };

  const toggleSigner = (type) =>
    setSigners({
      ...signers,
      [type]: {
        ...signers[type],
        send: !signers[type].send,
      },
    });

  return (
    <BaseModal isOpen={open} onClose={setOpen} overflowVisible={false}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Documento personalizado
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500 mb-5">
              Selecciona el archivo a enviar y los firmantes
            </p>
          </div>
          <div
            className={`mx-6 pt-5 pb-6 border-2 border-gray-300 ${
              file ? 'border-solid' : 'border-dashed'
            } rounded-md`}
          >
            <div className="space-y-1 text-center justify-center">
              {file ? (
                <ClipboardCheckIcon
                  className="h-10 w-10 text-gray-400 m-auto"
                  aria-hidden="true"
                />
              ) : (
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <div className="text-sm text-center text-gray-600">
                {file ? (
                  <span>{file.name}</span>
                ) : (
                  <label
                    htmlFor="file-upload"
                    className="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <span>Subir archivo</span>
                    <input
                      id="file-upload"
                      type="file"
                      className="sr-only"
                      name="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      accept="application/pdf"
                    />
                  </label>
                )}
              </div>
              {!file && (
                <p className="text-xs text-gray-500">PDF hasta 10 MB</p>
              )}
            </div>
          </div>
        </div>
        {file && (
          <div className="flex flex-col gap-2 items-center mt-3">
            {Object.keys(signers).map((e) => (
              <div className="flex items-center gap-2" key={e}>
                <input
                  id={e}
                  type="checkbox"
                  checked={signers[e].send}
                  onChange={() => toggleSigner(e)}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:cursor-not-allowed disabled:opacity-50"
                  disabled={signers[e].isDisabled}
                />
                <label htmlFor={e}>{typeDictionary[e]}</label>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mt-5 sm:mt-6 flex flex-col items-stretch">
        <button
          type="button"
          className={classNames(
            'inline-flex mx-6 mb-6 justify-center rounded-md bg-indigo-600 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed',
            submitState === STATE.ERROR &&
              'bg-red-600 hover:bg-red-500 cursor-not-allowed',
          )}
          onClick={() => handleSubmit()}
          disabled={
            !file ||
            Object.values(signers).every((signer) => !signer.send) ||
            submitState === STATE.LOADING ||
            submitState === STATE.ERROR
          }
        >
          {submitState === STATE.ERROR ? 'Error' : 'Crear'}
        </button>
      </div>
    </BaseModal>
  );
}

export default CustomSignatureModal;

import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@headlessui/react';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import BaseModal from '../../components/BaseModal';
import { toasterContext } from '../../context/ToasterContext/ToasterContext';
import {
  findModuleExistingDocument,
  parseDocumentsToCheckExistence,
} from '../../Modules/SignatureDetails/helpers/helpers';
import { Document } from '../../Modules/SignatureDetails/SignatureDetails';
import CustomModule from './components/CustomModule';
import SignModule from './components/SignModule';
import { BateTramit, Transfer } from '../../types/types';

export type Module = {
  title: string;
  type: 'contract' | 'mandate' | 'misplacement' | 'representation';
  counterPart?: 'seller' | 'buyer';
  description: string;
  existing: 'NO' | 'COMPLETED' | 'PENDING';
  value: unknown;
  tramits: ('transaction' | 'bate')[];
};

type CreateSignatureModalProps = {
  tramit: Transfer | BateTramit;
  entityType: 'transaction' | 'bate';
  entityCode: string;
  documents: Document[];
  open: boolean;
  setOpen: (open: boolean) => void;
  update: React.DispatchWithoutAction;
};

const modulesInitialState: Module[] = [
  {
    title: 'Contrato de compraventa',
    type: 'contract',
    description:
      'Documento que regula la compraventa. Debe de ser firmado por el comprador y el vendedor.',
    existing: 'NO',
    value: null,
    tramits: ['transaction', 'bate'],
  },
  {
    title: 'Mandato comprador',
    type: 'mandate',
    counterPart: 'buyer',
    description:
      'Poderes que permite a la gestoría tramitar en nombre del comprador. Debe de ser firmado por el comprador y el gestor.',
    existing: 'NO',
    value: null,
    tramits: ['transaction'],
  },
  {
    title: 'Mandato vendedor',
    type: 'mandate',
    counterPart: 'seller',
    description:
      'Poderes que permite a la gestoría tramitar en nombre del vendedor. Debe de ser firmado por el vendedor y el gestor.',
    existing: 'NO',
    value: null,
    tramits: ['transaction', 'bate'],
  },
  {
    title: 'Extravío de documentación',
    type: 'misplacement',
    description:
      'Documento que se firma en caso de no entregar el permiso de circulación a la gestoría. Debe de ser firmado por el vendedor',
    existing: 'NO',
    value: null,
    tramits: ['transaction', 'bate'],
  },
  {
    title: 'Documento de representación comprador',
    type: 'representation',
    counterPart: 'buyer',
    description:
      'Documento que acredita que la persona es un representante legítimo de la empresa. Debe de ser firmado por el comprador en caso de ser una empresa.',
    existing: 'NO',
    value: null,
    tramits: ['transaction'],
  },
  {
    title: 'Documento de representación vendedor',
    type: 'representation',
    counterPart: 'seller',
    description:
      'Documento que acredita que la persona es un representante legítimo de la empresa. Debe de ser firmado por el vendedor en caso de ser una empresa.',
    existing: 'NO',
    value: null,
    tramits: ['transaction', 'bate'],
  },
];

export type ExtraFile = {
  id: string;
  title: string;
  type: 'custom';
  entityType: 'transaction' | 'bate';
  entityCode: string;
  pdfInBase64: string;
  signers: (
    | {
        id: string;
        name: string;
        role: string;
        phone: string;
      }
    | { id: string; name: string; role: string; email: string }
  )[];
};

function CreateSignatureModal({
  tramit,
  entityType,
  entityCode,
  documents,
  open,
  setOpen,
  update,
}: CreateSignatureModalProps) {
  const [modules, setModules] = useState<Module[]>(modulesInitialState);
  const [loading, setLoading] = useState(false);

  const [extraFiles, setExtraFiles] = useState<ExtraFile[]>([]);

  const { setToasterData } = useContext(toasterContext);

  const { getAccessTokenSilently } = useAuth0();

  // Check if documents exist already
  useEffect(() => {
    // Parse documents to match module types and parse pro signers to buyer/seller
    const parsedDocuments = parseDocumentsToCheckExistence(documents, tramit);
    setModules(
      modules.map((module) => {
        // Check if document exists for that module
        const foundDocument = findModuleExistingDocument(
          module,
          parsedDocuments,
        );

        // Update existing property
        let result: 'NO' | 'COMPLETED' | 'PENDING' = 'NO';

        if (foundDocument && foundDocument.status === 'CANCELLED') {
          result = 'NO';
        }

        if (foundDocument && foundDocument.status !== 'CANCELLED') {
          result = foundDocument.status;
        }

        return {
          ...module,
          existing: result,
        };
      }),
    );
  }, [tramit, documents]);

  const updateModule = (newModule: Module) => {
    setModules((prev) =>
      prev.map((m) => {
        if (
          m.counterPart &&
          m.type === newModule.type &&
          m.counterPart === newModule.counterPart
        ) {
          return newModule;
        }
        if (!m.counterPart && m.type === newModule.type) {
          return newModule;
        }
        return m;
      }),
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_SUZUKI_URL}/documents-pro`,
        modules
          .map((m) => m.value)
          .concat(extraFiles)
          .filter(Boolean),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (status === 207) {
        setToasterData({
          type: 'WARNING',
          title: 'No se han podido generar todos los documentos',
          message: `Sólo se han podido generar ${data.completed}/${data.total} documentos`,
        });
        return;
      }

      setToasterData({
        type: 'SUCCESS',
        title: 'Firma creada correctamente',
        message: 'Se han generado y enviado los documentos correctamente',
      });
    } catch (err) {
      setToasterData({
        type: 'ERROR',
        title: 'Error al crear la firma',
        message: 'No se han podido generar los documentos',
      });
    } finally {
      setExtraFiles([]);
      setLoading(false);
      setOpen(false);
      update();
    }
  };

  return (
    <BaseModal isOpen={open} onClose={setOpen}>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Documentos para firmar
          </Dialog.Title>
          <div className="mt-2 grid grid-cols-3 grid-rows-2">
            {modules
              .filter((module) => module.tramits.includes(entityType))
              .map((module) => (
                <SignModule
                  key={module.title}
                  module={module}
                  updateModule={updateModule}
                  tramit={tramit}
                  entityType={entityType}
                  entityCode={entityCode}
                />
              ))}
          </div>
          <CustomModule
            extraFiles={extraFiles}
            setExtraFiles={setExtraFiles}
            tramit={tramit}
            entityCode={entityCode}
            entityType={entityType}
          />
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${
            loading && 'opacity-50 cursor-not-allowed'
          }`}
          onClick={handleSubmit}
          disabled={loading}
        >
          Enviar firma digital
        </button>
      </div>
    </BaseModal>
  );
}

export default CreateSignatureModal;

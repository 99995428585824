import React, { useContext } from 'react';
import {
  createTransferContext,
  CreateTransferModalContextType,
} from '../../../../context/CreateTransferContext/CreateTransferContext';

type UserType =
  | 'BUYER'
  | 'SELLER'
  | 'INTERMEDIARY'
  | 'BUYER_WITH_TEMP_LEAVE'
  | 'SELLER_WITH_TEMP_LEAVE';

type UserTypeOptionsType = {
  userType: UserType;
  title: string;
  description: string;
};

export default function TransferType(): JSX.Element {
  const { formValue, setFormValue } =
    useContext<CreateTransferModalContextType>(createTransferContext);

  const handleAction = (userType: UserType) => {
    if (userType !== 'INTERMEDIARY') {
      setFormValue({
        userType,
        counterPartType: '',
      });
    } else {
      setFormValue({
        userType,
        buyerType: '',
        sellerType: '',
      });
    }
  };

  const transactionOptions: UserTypeOptionsType[] = [
    {
      userType: 'BUYER',
      title: 'Comprar vehículo',
      description: 'Cambio de nombre de un vehículo como comprador.',
    },
    {
      userType: 'SELLER',
      title: 'Vender vehículo',
      description: 'Cambio de nombre de un vehículo como vendedor.',
    },
    {
      userType: 'INTERMEDIARY',
      title: 'Intermediar compra-venta',
      description:
        'Cambio de nombre de un vehículo sin ser ni comprador ni vendedor.',
    },
    {
      userType: 'BUYER_WITH_TEMP_LEAVE',
      title: 'Comprar vehículo - Batecom',
      description: 'Inicia la baja temporal por entrega a compraventa.',
    },
    {
      userType: 'SELLER_WITH_TEMP_LEAVE',
      title: 'Vender vehículo - Batecom',
      description: 'Finaliza una Batecom por cambio de nombre.',
    },
  ];

  return (
    <div className="w-full">
      <p className="mb-4 font-semibold">¿Qué quieres hacer?</p>
      <div className="mt-2 grid grid-cols-3 gap-4 w-full">
        {transactionOptions.map((o) => (
          <button
            key={o.userType}
            type="button"
            onClick={() => handleAction(o.userType)}
            onKeyDown={() => handleAction(o.userType)}
            className={`border rounded-lg py-[17px] px-[25px] w-full
            ${formValue.userType === o.userType && 'border-2 border-blue-600'}`}
          >
            <p className="font-semibold text-sm text-left">{o.title}</p>
            <p className="text-gray-400 text-sm text-left">{o.description}</p>
          </button>
        ))}
      </div>
    </div>
  );
}

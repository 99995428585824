import { useEffect, useState } from 'react';
import { useServices } from '../services';

export default function useAccounts(loadCondition) {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState({
    data: [],
    isLoading: false,
    error: '',
  });

  const { getAllAccounts } = useServices();

  useEffect(() => {
    (async () => {
      try {
        if (!loadCondition) return;
        setAccounts((prev) => ({ ...prev, isLoading: true, error: '' }));

        const accountsResponse = await getAllAccounts();

        setAccounts((prev) => ({ ...prev, data: accountsResponse.accounts }));
      } catch (e) {
        setAccounts((prev) => ({
          ...prev,
          error: 'Error al solicitar las cuentas',
        }));
      } finally {
        setAccounts((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    })();
  }, [loadCondition]);

  return { accounts, setAccounts, selectedAccount, setSelectedAccount };
}

import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCallback } from 'react';

export default function useItpPro() {
  const { getAccessTokenSilently } = useAuth0();

  const getItpInfo = useCallback(
    async (model: string, enrollmentDate: string, ccaa: string) => {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_API_URL
        }/itp/calculate/?model=${encodeURIComponent(
          model,
        )}&ccaa=${ccaa}&enrollmentDate=${encodeURI(enrollmentDate)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    },
    [],
  );

  const getMotoItpInfo = useCallback(
    async (cc: string, years: number, ccaa: string) => {
      const token = await getAccessTokenSilently();

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/itp/calculate/moto?ccaa=${ccaa}&years=${years}&cc=${cc}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    },

    [],
  );
  return {
    getItpInfo,
    getMotoItpInfo,
  };
}

import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { TrashIcon, DocumentIcon } from '@heroicons/react/outline';

import TransferDetailSteps from './components/TransferDetailSteps/TransferDetailSteps';
import Tabs from './components/Tabs/Tabs';
import { Tab, TabsType } from '../..';
import { transferDetailsContext } from '../../../context/TransferDetailsContext/transferDetailsContext';
import TemporaryDocButton from './components/TemporaryDocButton/TemporaryDocButton';
import PresentToAgencyButton from './components/PresentToAgencyButton/PresentToAgencyButton';
import {
  SWIPOO_STATUS,
  TRANSFER_STATUS,
} from '../../../shared/transaction-status';
import Button from '../../../components/Button';

import Title from '../../../components/Title';
import ContextMenuButton, {
  ContextMenuOptionType,
} from '../../../components/ContextMenuButton';
import { editTransaction } from '../../../services/services';
import { toasterContext } from '../../../context/ToasterContext/ToasterContext';
import Incidents from '../../../components/Incidents/Incidents';

type TransferDetailsHeaderPropsType = {
  currentTab: Tab;
  tabs: TabsType;
  handleChangeTab: (tabId: string) => () => void;
  transactionType: 'Venta' | 'Compra' | 'Intermediación' | 'Venta - Batecom';
};

export default function TransferDetailsHeader({
  currentTab,
  handleChangeTab,
  tabs,
  transactionType,
}: TransferDetailsHeaderPropsType) {
  const { transfer, transferLoading } = useContext(transferDetailsContext);
  const { setToasterData } = useContext(toasterContext);

  const navigate = useNavigate();

  const statusBeforePresentToAgency = [
    SWIPOO_STATUS.PENDING_DOCUMENTATION,
    SWIPOO_STATUS.DIGITAL_CONTRACT_SENT,
    SWIPOO_STATUS.DIGITAL_CONTRACT_SIGNED,
  ];

  const { getAccessTokenSilently } = useAuth0();

  const { transaction } = transfer || {};

  const contextMenuOptions: ContextMenuOptionType[] = [
    {
      id: 1,
      label: 'Anular',
      RightIcon: TrashIcon,
      isVisible:
        SWIPOO_STATUS.PENDING_DOCUMENTATION === transfer?.transaction?.status,
      onClick: async () => {
        try {
          const token = await getAccessTokenSilently();
          await editTransaction(token, transfer.transaction.transactionCode, {
            status: SWIPOO_STATUS.CANCELED,
          });
          navigate('/transfers');
        } catch (e) {
          setToasterData({
            type: 'ERROR',
            title: 'Error',
            message: 'Ha ocurrido un error al anular la transferencia',
          });
        }
      },
    },
  ];

  return (
    <div className="flex flex-col justify-between gap-y-6">
      <div className="flex justify-between">
        <Title
          vehicle={transfer?.car}
          isLoading={transferLoading}
          tramitSubtitleType={transactionType}
          creationDate={transfer?.transaction?.createdAt}
        />
        <div className="flex items-start gap-x-4">
          <ContextMenuButton contextMenuOptions={contextMenuOptions} />
          <TemporaryDocButton />
          {statusBeforePresentToAgency.includes(
            transfer?.transaction.status,
          ) && <PresentToAgencyButton />}
          {transfer?.transaction.presentationProof &&
            [
              ...TRANSFER_STATUS.SUBMITTED_TO_DGT,
              ...TRANSFER_STATUS.FINISHED_BY_DGT,
            ].includes(transfer?.transaction.status) && (
              <Button
                text="Descargar justificante"
                bgColor="bg-blue-700"
                hoverBgColor="bg-blue-500"
                textColor="white"
                RightIcon={DocumentIcon}
                textSm
                callback={() => {
                  window.open(
                    transfer?.transaction.presentationProof,
                    '_blank',
                  );
                }}
              />
            )}
        </div>
      </div>

      <TransferDetailSteps />
      <Incidents entityId={transaction?.id} />
      <Tabs
        currentTab={currentTab}
        handleChangeTab={handleChangeTab}
        tabs={tabs}
      />
    </div>
  );
}

import { Switch } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { ZodIssue } from 'zod';
import classNames from '../../../helpers/classNames';
import {
  contractZod,
  mandateBuyerZod,
  mandateSellerZod,
  misplacementZod,
  representationBuyerZod,
  representationSellerZod,
} from '../validators';
import { Module } from '../CreateSignatureModal';
import { BateTramit, Company, Transfer } from '../../../types/types';

type SignModuleProps = {
  module: Module;
  updateModule: (module: Module) => void;
  tramit: Transfer | BateTramit;
  entityType: 'transaction' | 'bate';
  entityCode: string;
};

const pathDictionary: Map<string, string> = new Map([
  ['buyer', 'comprador'],
  ['seller', 'vendedor'],
  ['agent', 'gestor'],
  ['car', 'vehículo'],
  ['id', 'ID'],
  ['phone', 'teléfono'],
  ['address', 'dirección'],
  ['city', 'ciudad'],
  ['province', 'provincia'],
  ['name', 'nombre'],
  ['surname', 'apellidos'],
  ['dni', 'DNI'],
  ['nif', 'NIF'],
  ['representative', 'representante'],
  ['brand', 'marca'],
  ['model', 'modelo'],
  ['enrollmentDate', 'fecha de matriculación'],
  ['kms', 'kilómetros'],
  ['plate', 'matrícula'],
  ['frameNumber', 'número de bastidor'],
  ['fiscalAddressZipCode', 'código postal'],
  ['fiscalAddressCity', 'ciudad'],
  ['fiscalAddressAddress', 'dirección'],
  ['fiscalAddressStreetNumber', 'número'],
  ['email', 'email'],
  ['colNumber', 'número de colegiado'],
  ['colLocation', 'provincia de colegio'],
  ['addressNumber', 'número de calle'],
  ['zipCode', 'código postal'],
]);

const decorateUsersWithType = ({
  tramit,
  isMandate,
}: {
  tramit: Transfer | BateTramit;
  isMandate: boolean;
}) => {
  const proType = isMandate ? 'proMandate' : 'pro';
  return {
    ...tramit,
    ...(tramit.buyer && {
      buyer: {
        ...tramit.buyer,
        type: (tramit.buyer as Company).representative ? proType : 'user',
      },
    }),
    seller: {
      ...tramit.seller,
      type: (tramit.seller as Company).representative ? proType : 'user',
    },
  };
};

function SignModule({
  module,
  updateModule,
  tramit,
  entityType,
  entityCode,
}: Readonly<SignModuleProps>) {
  const [checked, setChecked] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [errors, setErrors] = useState<ZodIssue[]>([]);

  const handleContractValidation = () => {
    const validation = contractZod.safeParse(
      decorateUsersWithType({ tramit, isMandate: false }),
    );

    if (validation.success === false) {
      setEnabled(false);
      setErrors(validation.error.issues);
      return;
    }

    const { data } = validation;

    if (!checked) {
      updateModule({
        ...module,
        value: null,
      });
      return;
    }
    updateModule({
      ...module,
      value: {
        id: crypto.randomUUID(),
        type: 'contract',
        signers: [
          {
            ...data.buyer,
            role: (data.buyer as { representantName: string }).representantName
              ? 'pro'
              : 'buyer',
          },
          {
            ...data.seller,
            role: (data.seller as { representantName: string }).representantName
              ? 'pro'
              : 'seller',
          },
        ],
        vehicleData: {
          ...data.car,
        },
        entityType,
        entityCode,
      },
    });
  };

  const handleMandateBuyerValidation = () => {
    const validation = mandateBuyerZod.safeParse(
      decorateUsersWithType({ tramit, isMandate: true }),
    );

    if (validation.success === false) {
      setEnabled(false);
      setErrors(validation.error.issues);
      return;
    }

    const { data } = validation;

    if (!checked) {
      updateModule({
        ...module,
        value: null,
      });
      return;
    }
    updateModule({
      ...module,
      value: {
        id: crypto.randomUUID(),
        type: (data.buyer as { companyCity: string }).companyCity
          ? 'mandate-pro'
          : 'mandate',
        signers: [
          {
            ...data.buyer,
            role: (data.buyer as { companyCity: string }).companyCity
              ? 'pro'
              : 'buyer',
          },
          {
            ...data.agent,
            role: 'agency',
          },
        ],
        entityType,
        entityCode,
      },
    });
  };

  const handleMandateSellerValidation = () => {
    const validation = mandateSellerZod.safeParse(
      decorateUsersWithType({ tramit, isMandate: true }),
    );

    if (validation.success === false) {
      setEnabled(false);
      setErrors(validation.error.issues);
      return;
    }

    const { data } = validation;

    if (!checked) {
      updateModule({
        ...module,
        value: null,
      });
      return;
    }
    updateModule({
      ...module,
      value: {
        id: crypto.randomUUID(),
        type: (data.seller as { companyCity: string }).companyCity
          ? 'mandate-pro'
          : 'mandate',
        signers: [
          {
            ...data.seller,
            role: (data.seller as { companyCity: string }).companyCity
              ? 'pro'
              : 'seller',
          },
          {
            ...data.agent,
            role: 'agency',
          },
        ],
        entityType,
        entityCode,
      },
    });
  };

  const handleMisplacementValidation = () => {
    const validation = misplacementZod.safeParse(
      decorateUsersWithType({ tramit, isMandate: false }),
    );

    if (validation.success === false) {
      setEnabled(false);
      setErrors(validation.error.issues);
      return;
    }

    const { data } = validation;

    if (!checked) {
      updateModule({
        ...module,
        value: null,
      });
      return;
    }
    updateModule({
      ...module,
      value: {
        id: crypto.randomUUID(),
        type: 'misplacement',
        signers: [
          {
            ...data.seller,
            role: (data.seller as { representantName: string }).representantName
              ? 'pro'
              : 'seller',
          },
        ],
        entityType,
        entityCode,
        vehicleData: {
          ...data.car,
        },
      },
    });
  };

  const handleRepresentationSellerValidation = () => {
    const validation = representationSellerZod.safeParse(
      decorateUsersWithType({ tramit, isMandate: false }),
    );

    if (validation.success === false) {
      setEnabled(false);
      setErrors(validation.error.issues);
      return;
    }

    const { data } = validation;

    if (!checked) {
      updateModule({
        ...module,
        value: null,
      });
      return;
    }
    updateModule({
      ...module,
      value: {
        id: crypto.randomUUID(),
        type: 'representation',
        signers: [
          {
            ...data.seller,
            role: (data.seller as { representantName: string }).representantName
              ? 'pro'
              : 'seller',
          },
        ],
        entityType,
        entityCode,
        vehicleData: {
          ...data.car,
        },
      },
    });
  };

  const handleRepresentationBuyerValidation = () => {
    const validation = representationBuyerZod.safeParse(
      decorateUsersWithType({ tramit, isMandate: false }),
    );

    if (validation.success === false) {
      setEnabled(false);
      setErrors(validation.error.issues);
      return;
    }

    const { data } = validation;

    if (!checked) {
      updateModule({
        ...module,
        value: null,
      });
      return;
    }
    updateModule({
      ...module,
      value: {
        id: crypto.randomUUID(),
        type: 'representation',
        signers: [
          {
            ...data.buyer,
            role: (data.buyer as { representantName: string }).representantName
              ? 'pro'
              : 'buyer',
          },
        ],
        entityType,
        entityCode,
        vehicleData: {
          ...data.car,
        },
      },
    });
  };

  const handleChangePriceInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateModule({
      ...module,
      value: {
        ...(module.value as Record<string, unknown>),
        vehicleData: {
          ...(
            module.value as {
              vehicleData: Record<string, unknown>;
            }
          ).vehicleData,
          carPrice: e.target.value,
        },
      },
    });
  };

  useEffect(() => {
    setErrors([]);
    switch (module.type) {
      case 'contract':
        handleContractValidation();
        break;
      case 'mandate':
        if (module.counterPart === 'seller') {
          handleMandateSellerValidation();
        } else {
          handleMandateBuyerValidation();
        }
        break;
      case 'misplacement':
        handleMisplacementValidation();
        break;
      case 'representation':
        if (module.counterPart === 'seller') {
          handleRepresentationSellerValidation();
        } else {
          handleRepresentationBuyerValidation();
        }
        break;
      default:
    }
  }, [module.type, checked]);

  const getErrorMessage = () =>
    errors
      .map((e) => {
        const { path } = e;
        if (path.length === 1) {
          return `${pathDictionary.get(path[0] as string)} no existe.`;
        }
        const newPath = JSON.parse(JSON.stringify(path)).reverse();
        return newPath.map((p: string) => pathDictionary.get(p)).join(' de ');
      })
      .join(', ');

  return (
    <Switch.Group as="div" className="px-4 py-5 sm:p-6">
      <Switch.Label
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900"
        passive
      >
        {module.title}
      </Switch.Label>
      <div className="mt-2 sm:flex sm:items-start sm:justify-between">
        <div className="max-w-xl text-sm text-gray-500">
          <Switch.Description>{module.description}</Switch.Description>
        </div>
      </div>
      <div className="mt-5">
        {module.existing === 'PENDING' && (
          <span className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-sm font-medium text-yellow-800">
            Pendiente de firma
          </span>
        )}
        {module.existing === 'COMPLETED' && (
          <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
            Completado
          </span>
        )}
        {module.existing === 'NO' && (
          <div className="group">
            <Switch
              data-testid="switch"
              checked={checked}
              onChange={enabled ? setChecked : null}
              className={classNames(
                checked ? 'bg-blue-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                !enabled && 'opacity-30 cursor-not-allowed',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  checked ? 'translate-x-5' : 'translate-x-0',
                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            {module.type === 'contract' && checked && (
              <input
                type="number"
                name="price"
                id="price"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mt-2"
                placeholder="Precio del vehículo"
                onChange={handleChangePriceInput}
              />
            )}
            {errors.length > 0 && (
              <p className="text-xs text-red-500 mt-2 opacity-0 group-hover:opacity-100 transition-all duration-200">
                Error en los campos: {getErrorMessage()}
              </p>
            )}
          </div>
        )}
      </div>
    </Switch.Group>
  );
}

export default SignModule;

import { useEffect, useState } from 'react';

import { useServices } from '../../../../../services';
import { brandMotoEnums } from '../../../../../helpers/enums';
import BaseModal from '../../../../../components/BaseModal';
import Spinner from '../../../../../components/Spinner';
import Combobox from '../../../../../components/Combobox';

export default function BrandModelEditModal({
  car,
  isModalOpen,
  setIsModalOpen,
  save,
}) {
  const [brands, setBrands] = useState({
    data: [],
    isLoading: false,
    error: false,
  });
  const [carModelList, setCarModelList] = useState({
    data: [],
    isLoading: false,
    error: false,
  });
  const [vehicleModel, setVehicleModel] = useState(car?.model || '');
  const [vehicleBrand, setVehicleBrand] = useState({
    value: car?.brand || '',
    hasChanged: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const { getModelsByBrand, getCarBrands, updateCarModel, editCar } =
    useServices();

  const resetModal = () => {
    setIsModalOpen(false);
    setBrands({
      data: [],
      isLoading: false,
      error: false,
    });
    setCarModelList({
      data: [],
      isLoading: false,
      error: false,
    });
    setVehicleModel('');
    setVehicleBrand({
      value: '',
      hasChanged: false,
    });
  };

  const editCarModelAndBrand = async () => {
    try {
      setErrorText('');
      setIsLoading(true);
      await editCar(car.id, { brand: vehicleBrand.value });
      await updateCarModel(car.id, { model: vehicleModel });
      save();
      resetModal();
    } catch (e) {
      setErrorText('Error al editar el modelo del vehículo');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isModalOpen) return;
    (async () => {
      setBrands((prev) => ({ ...prev, isLoading: true, error: false }));
      try {
        if (!car?.isMoto) {
          const brandsVehicle = await getCarBrands();
          setBrands((prev) => ({ ...prev, data: brandsVehicle.brands }));
        } else {
          setBrands((prev) => ({
            ...prev,
            data: brandMotoEnums.map((brand) => brand.value),
          }));
        }
      } catch (e) {
        setBrands((prev) => ({ ...prev, error: true }));
      } finally {
        setBrands((prev) => ({ ...prev, isLoading: false }));
      }
    })();
  }, [isModalOpen]);

  useEffect(() => {
    if (!brands.data.length) return;

    setVehicleBrand({
      value:
        brands.data.find((brand) => brand === car?.brand) || brands.data[0],
      hasChanged: true,
    });
  }, [brands.data]);

  useEffect(() => {
    (async () => {
      if (vehicleBrand.hasChanged && !car?.isMoto) {
        try {
          setCarModelList((prev) => ({
            ...prev,
            isLoading: true,
            error: false,
          }));
          const { cars: models } = await getModelsByBrand(vehicleBrand.value);
          setCarModelList((prev) => ({
            ...prev,
            data: models
              .map((vehicle) => vehicle.model)
              .sort((a, b) => a.localeCompare(b)),
          }));
        } catch (e) {
          setCarModelList((prev) => ({ ...prev, error: true }));
        } finally {
          setCarModelList((prev) => ({ ...prev, isLoading: false }));
        }
      }
    })();
  }, [vehicleBrand]);

  useEffect(() => {
    if (!carModelList.data.length) return;

    setVehicleModel(car.model || carModelList.data[0]);
  }, [carModelList.data]);

  useEffect(() => {
    if (!car.isMoto || !isModalOpen) return;

    setVehicleModel(car.model);
  }, [isModalOpen]);

  return (
    <BaseModal isOpen={isModalOpen} onClose={resetModal} overflowVisible>
      <div className="flex flex-col gap-y-4 w-full p-8">
        <h2>Marca y Modelo del Vehículo</h2>
        <label htmlFor="brands" className="sr-only">
          Marca
        </label>
        <div className="relative">
          <select
            className=" shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            name="brands"
            id="brands"
            value={vehicleBrand.value}
            disabled={brands.isLoading}
            onChange={(e) =>
              setVehicleBrand({ value: e.target.value, hasChanged: true })
            }
          >
            {brands.data.map((brand) => (
              <option value={brand}>{brand}</option>
            ))}
          </select>
          {brands.isLoading && (
            <div className="absolute right-6 top-2">
              <Spinner color="text-indigo-700" size={5} marginTop={28} />
            </div>
          )}
        </div>

        <label htmlFor="name" className="sr-only">
          Modelo
        </label>
        {car?.isMoto ? (
          <input
            type="text"
            name="name"
            id="name"
            value={vehicleModel}
            disabled={brands.isLoading}
            placeholder="Modelo"
            onChange={(e) => setVehicleModel(e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        ) : (
          <div className="relative">
            <Combobox
              data={carModelList.data}
              selectedItem={vehicleModel}
              setSelectedItem={setVehicleModel}
            />
            {carModelList.isLoading && (
              <div className="absolute right-6 top-4">
                <Spinner color="text-indigo-700" size={5} marginTop={28} />
              </div>
            )}
          </div>
        )}
        {errorText && <p className="text-red-500">{errorText}</p>}
        {brands.error && (
          <p className="text-red-500">Ocurrió un error cargando las marcas</p>
        )}
        {carModelList.error && (
          <p className="text-red-500">Ocurrió un error cargando los modelos</p>
        )}
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={editCarModelAndBrand}
          disabled={isLoading}
        >
          {isLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
          {isLoading ? 'Guardando...' : 'Guardar'}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={resetModal}
        >
          Cancelar
        </button>
      </div>
    </BaseModal>
  );
}

import { useRef, useState, useReducer } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

import { useServices } from '../../../../../services';
import Spinner from '../../../../../components/Spinner';
import useClickOutside from '../../../../../hooks/useClickOutside';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NAME':
      return { ...state, name: action.payload };
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    default:
      return state;
  }
};

export default function NewMemberModal({
  accountId,
  setNewMemberModal,
  refresh,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [resetPasswordLink, setResetPasswordLink] = useState(null);
  const [formState, dispatch] = useReducer(reducer, {
    name: '',
    email: '',
  });

  const { createNewAccountMember } = useServices();

  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setNewMemberModal(false));

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setHasError(false);
      setIsLoading(true);

      const { ticket } = await createNewAccountMember(accountId, formState);
      setResetPasswordLink(ticket);
      refresh();
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full fixed left-0 top-0 bg-black/75 z-30 flex items-center justify-center">
      <div
        ref={modalRef}
        className="p-10 flex flex-col items-start bg-white rounded-md shadow-md"
      >
        <h2 className="text-xl font-medium mb-5">Crear nuevo usuario</h2>
        {!resetPasswordLink ? (
          <form onSubmit={handleSubmit}>
            <div className="w-80">
              <div className="flex">
                <input
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-2"
                  type="text"
                  placeholder="Nombre"
                  value={formState.name}
                  onChange={(e) =>
                    dispatch({ type: 'SET_NAME', payload: e.target.value })
                  }
                />
              </div>
              <div className="flex">
                <input
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-2"
                  type="email"
                  placeholder="Correo electrónico"
                  value={formState.email}
                  onChange={(e) =>
                    dispatch({ type: 'SET_EMAIL', payload: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="flex gap-5 items-center mt-5">
              <button
                type="submit"
                className="px-4 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Añadir
              </button>
              {isLoading && (
                <Spinner color="text-blue-500" size={6} marginTop={0} />
              )}
            </div>
          </form>
        ) : (
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">
                  Usuario creado correctamente
                </h3>
                <div className="mt-2 text-sm text-green-700">
                  <p className="underline">{resetPasswordLink}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {hasError && (
          <p className="text-sm text-red-500 mt-2">
            Ha ocurrido un error al añadir el usuario.
          </p>
        )}
      </div>
    </div>
  );
}

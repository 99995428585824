import React, { useEffect, useRef, useState } from 'react';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import { Shipment } from '../../types/types';
import Spinner from '../../../../components/Spinner';
import { obtainFullAddress } from '../../../../services/obtainFullAddress';
import { AsyncState } from '../../../../types/types';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

type Coordinate = [number, number];

const SWIPOO_COORDINATES: Coordinate = [2.13673, 41.36602];

const getMidPoint = (lngLat1: Coordinate, lngLat2: Coordinate): Coordinate => {
  const midLng = (lngLat1[0] + lngLat2[0]) / 2;
  const midLat = (lngLat1[1] + lngLat2[1]) / 2;

  return [midLng, midLat];
};

export default function MapComponent({ shipment }: { shipment: Shipment }) {
  const [destinationCoords, setDestinationCoords] = useState<
    AsyncState<Coordinate>
  >({ data: SWIPOO_COORDINATES, isLoading: false, error: null });

  const mapRef = useRef(null);

  const midPoint = getMidPoint(SWIPOO_COORDINATES, destinationCoords.data);

  const padding = 0.3;

  const bounds: LngLatBoundsLike = [
    [
      Math.min(SWIPOO_COORDINATES[0], destinationCoords.data[0]) - padding,
      Math.min(SWIPOO_COORDINATES[1], destinationCoords.data[1]) - padding,
    ],
    [
      Math.max(SWIPOO_COORDINATES[0], destinationCoords.data[0]) + padding,
      Math.max(SWIPOO_COORDINATES[1], destinationCoords.data[1]) + padding,
    ],
  ];

  const fetchDestinationCoords = async () => {
    try {
      setDestinationCoords((prev) => ({ ...prev, isLoading: true }));

      const fullAddress = `${shipment.delivery.street}, ${shipment.delivery.city}, ${shipment.delivery.state}, ${shipment.delivery.zipCode}, España`;

      const { coordinates } = await obtainFullAddress(fullAddress);

      setDestinationCoords((prev) => ({
        ...prev,
        data: [coordinates.lng, coordinates.lat],
      }));
    } catch (e) {
      setDestinationCoords((prev) => ({
        ...prev,
        error: 'Ocurrió un error cargando las coordenadas de destino',
      }));
    } finally {
      setDestinationCoords((prev) => ({ ...prev, isLoading: false }));
    }
  };

  useEffect(() => {
    if (!mapRef.current) return;
    if (
      destinationCoords.data[0] === SWIPOO_COORDINATES[0] &&
      destinationCoords.data[1] === SWIPOO_COORDINATES[1]
    ) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapRef.current, // container ID
      center: midPoint, // starting position [lng, lat]
      zoom: 13, // starting zoom
      style: 'mapbox://styles/mapbox/streets-v11', // style URL or style object
      accessToken: process.env.REACT_APP_MAPBOX_KEY,
    });

    map.fitBounds(bounds);

    new mapboxgl.Marker({
      color: '#FF0000',
    })
      .setLngLat(SWIPOO_COORDINATES)
      .addTo(map);

    new mapboxgl.Marker({
      color: '#FF0000',
    })
      .setLngLat(destinationCoords.data)
      .addTo(map);

    map.on('load', () => {
      map.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [SWIPOO_COORDINATES, destinationCoords.data],
          },
        },
      });
      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#1D4ED8',
          'line-width': 6,
        },
      });
    });
  }, [destinationCoords]);

  useEffect(() => {
    if (!shipment) return;
    if (
      !shipment.delivery.street ||
      !shipment.delivery.city ||
      !shipment.delivery.state ||
      !shipment.delivery.zipCode
    ) {
      return;
    }
    fetchDestinationCoords();
  }, [shipment]);

  return destinationCoords.isLoading ? (
    <div className="w-full h-full flex items-center justify-center">
      <Spinner size={16} color="text-blue-500" marginTop={0} />
    </div>
  ) : (
    <div id="map" data-testid="map" ref={mapRef} className="h-full" />
  );
}

import { useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';

function uniqueValues<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

const nifRegex = /^[a-z]{1}[0-9]{8}$/i;
const dniRegex = /^[a-z0-9]{1}[0-9]{7}[a-z]$/i;
const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+º/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

function useFieldsValidator(): {
  errors: string[];
  validateFields: (name: string, value: string) => void;
} {
  const [errors, setErrors] = useState<string[]>([]);

  const validateFields = (name: string, value: string): void => {
    try {
      if (
        name === 'phone' &&
        value !== '' &&
        !parsePhoneNumber(value, 'ES').isValid()
      ) {
        setErrors(uniqueValues([...errors, 'phone']));
      }
      if (
        name === 'phone' &&
        (value === '' || parsePhoneNumber(value, 'ES').isValid())
      ) {
        setErrors(errors.filter((error) => error !== 'phone'));
      }
    } catch (e) {
      setErrors(uniqueValues([...errors, 'phone']));
    }

    if (name === 'dni' && value !== '' && !dniRegex.test(value)) {
      setErrors(uniqueValues([...errors, 'dni']));
    }
    if (name === 'dni' && (value === '' || dniRegex.test(value))) {
      setErrors(errors.filter((error) => error !== 'dni'));
    }
    if (name === 'nif' && value !== '' && !nifRegex.test(value)) {
      setErrors(uniqueValues([...errors, 'nif']));
    }
    if (name === 'nif' && (value === '' || nifRegex.test(value))) {
      setErrors(errors.filter((error) => error !== 'nif'));
    }
    if (name === 'email' && value !== '' && !emailRegex.test(value)) {
      setErrors(uniqueValues([...errors, 'email']));
    }
    if (name === 'email' && (value === '' || emailRegex.test(value))) {
      setErrors(errors.filter((error) => error !== 'email'));
    }
  };

  return { errors, validateFields };
}

export default useFieldsValidator;

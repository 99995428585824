export default function ModuleWrapper({ title, button, children }) {
  return (
    <section aria-labelledby="notes-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
            <h2 id="notes-title" className="text-lg font-medium text-gray-900">
              {title}
            </h2>
            {button}
          </div>
          {children}
        </div>
      </div>
    </section>
  );
}

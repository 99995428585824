import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DocumentTextIcon,
  IdentificationIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/outline';
import { AiOutlineCar } from 'react-icons/ai';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import {
  Transfer,
  BateTramit,
  FILE_TYPE,
  Company,
  User,
  Transaction,
  Car,
  Bate,
  RegistrationTramit,
} from '../../types/types';
import TableBody from './DocumentsTable/TableBody';
import AgencyDocumentsTable from './DocumentsTable/Agency';
import DragAndDropFile from './DragAndDropFile/DragAndDropFile';
import { loadInvoice } from '../../services/services';
import { userInfoContext } from '../../context/UserInfoContext/UserInfoContext';

type DocumentsProps = {
  tramit: Transfer | BateTramit | RegistrationTramit;
  entityType: 'transaction' | 'bate' | 'registration';
  forceUpdate: () => void;
};

export type RowData = {
  icon: React.ReactElement;
  label: string;
  docURI: string;
  fileType: FILE_TYPE;
  owner: Company | User | Transaction | Car | Bate;
  canUseDigitalSignature?: boolean;
};

function Documents({
  tramit,
  entityType,
  forceUpdate,
}: Readonly<DocumentsProps>) {
  const [invoiceUri, setInvoiceUri] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const digitalSignatureEnabled = useFeatureFlagEnabled(
    'template-digital-signature',
  );

  const { companyInfo } = useContext(userInfoContext);

  useEffect(() => {
    const fetchInvoice = async () => {
      const token = await getAccessTokenSilently();
      const holdedInvoiceUri = await loadInvoice(
        token,
        (tramit as Transfer).transaction?.holdedInvoiceId,
      );
      setInvoiceUri(holdedInvoiceUri);
    };

    if (
      entityType === 'transaction' &&
      (tramit as Transfer).transaction?.holdedInvoiceId
    ) {
      fetchInvoice();
    }
  }, []);

  const agencyDocumentsRows = useMemo(() => {
    switch (entityType) {
      case 'bate':
        return [
          {
            label: 'Solictud de baja por entrega a compraventa',
            docURI: (tramit as BateTramit).bate?.temporaryLeaveRequest,
            icon: <DocumentTextIcon className="w-5 h-5" />,
          },
        ];
      case 'transaction':
        return [
          {
            label: 'Permiso provisional de gestoría',
            docURI: (tramit as Transfer).transaction?.temporaryCirculationDoc,
            icon: <DocumentTextIcon className="w-5 h-5" />,
          },
          {
            label: 'Permiso provisional de DGT',
            docURI: (tramit as Transfer).transaction?.presentationProof,
            icon: <DocumentTextIcon className="w-5 h-5" />,
          },
          {
            label: 'Factura de gestoría',
            docURI: invoiceUri,
            icon: <DocumentTextIcon className="w-5 h-5" />,
          },
        ];
      case 'registration':
        // Ficha técnica provisional
        // Permiso de circulación provisional
        return [];
      default:
        return [];
    }
  }, [entityType, tramit, invoiceUri]);

  const generateTemplateRows = (): RowData[] => {
    if (entityType === 'bate') {
      const { seller, car } = tramit as BateTramit;
      return (seller as Company)?.representative
        ? [
            {
              label: 'Tarjeta Identificación Fiscal - Vendedor',
              docURI: (seller as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.TIF,
            },
            {
              label: 'DNI Frontal - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            digitalSignatureEnabled && {
              label: 'Mandato - Representante Vendedor',
              docURI: (seller as Company)?.representative?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.MANDATE,
              canUseDigitalSignature: true,
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
            {
              label: 'Factura',
              docURI: (tramit as BateTramit).bate?.proInvoice,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: (tramit as BateTramit).bate,
              fileType: FILE_TYPE.PRO_INVOICE,
            },
          ].filter(Boolean)
        : [
            {
              label: 'DNI Frontal - Vendedor',
              docURI: (seller as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_FRONT,
              owner: seller,
            },
            {
              label: 'DNI Reverso - Vendedor',
              docURI: (seller as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_BACK,
              owner: seller,
            },
            digitalSignatureEnabled && {
              label: 'Mandato - Vendedor',
              docURI: (seller as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.MANDATE,
              canUseDigitalSignature: true,
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
              owner: car,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
              owner: car,
            },
          ].filter(Boolean);
    }
    if (entityType === 'transaction') {
      const { operationType, buyer, seller, car } = tramit as Transfer;
      const sellerIsAccount = (seller as Company)?.nif === companyInfo.nif;
      const buyerIsAccount = (buyer as Company)?.nif === companyInfo.nif;
      switch (operationType) {
        case 'B2P':
          return [
            !buyerIsAccount && {
              label: 'Tarjeta Identificación Fiscal - Comprador',
              docURI: (buyer as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.TIF,
              owner: buyer,
            },
            !buyerIsAccount && {
              label: 'DNI Frontal - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_FRONT,
              owner: (buyer as Company)?.representative,
            },
            !buyerIsAccount && {
              label: 'DNI Reverso - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_BACK,
              owner: (buyer as Company)?.representative,
            },
            !buyerIsAccount &&
              digitalSignatureEnabled && {
                label: 'Mandato - Representante Comprador',
                docURI: (buyer as Company)?.representative?.mandate,
                icon: <IdentificationIcon className="w-5 h-5" />,
                owner: (buyer as Company)?.representative,
                fileType: FILE_TYPE.MANDATE,
                canUseDigitalSignature: true,
              },
            {
              label: 'DNI Frontal - Vendedor',
              docURI: (seller as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_FRONT,
              owner: seller,
            },
            {
              label: 'DNI Reverso - Vendedor',
              docURI: (seller as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_BACK,
              owner: seller,
            },
            digitalSignatureEnabled && {
              label: 'Mandato - Vendedor',
              docURI: (seller as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.MANDATE,
              canUseDigitalSignature: true,
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
              owner: car,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
              owner: car,
            },
          ].filter(Boolean);
        case 'B2B':
          return [
            !buyerIsAccount && {
              label: 'Tarjeta Identificación Fiscal - Comprador',
              docURI: (buyer as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.TIF,
            },
            !buyerIsAccount && {
              label: 'DNI Frontal - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            !buyerIsAccount && {
              label: 'DNI Reverso - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            !buyerIsAccount &&
              digitalSignatureEnabled && {
                label: 'Mandato - Representante Comprador',
                docURI: (buyer as Company)?.representative?.mandate,
                icon: <IdentificationIcon className="w-5 h-5" />,
                owner: (buyer as Company)?.representative,
                fileType: FILE_TYPE.MANDATE,
                canUseDigitalSignature: true,
              },
            !sellerIsAccount && {
              label: 'Tarjeta Identificación Fiscal - Vendedor',
              docURI: (seller as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.TIF,
            },
            !sellerIsAccount && {
              label: 'DNI Frontal - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            !sellerIsAccount && {
              label: 'DNI Reverso - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            !sellerIsAccount &&
              digitalSignatureEnabled && {
                label: 'Mandato - Representante Vendedor',
                docURI: (seller as Company)?.representative?.mandate,
                icon: <IdentificationIcon className="w-5 h-5" />,
                owner: (seller as Company)?.representative,
                fileType: FILE_TYPE.MANDATE,
                canUseDigitalSignature: true,
              },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
            {
              label: 'Factura',
              docURI: (tramit as Transfer).transaction.proInvoice,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: (tramit as Transfer).transaction,
              fileType: FILE_TYPE.PRO_INVOICE,
            },
          ].filter(Boolean);
        case 'P2B':
          return [
            {
              label: 'DNI Frontal - Comprador',
              docURI: (buyer as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Comprador',
              docURI: (buyer as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.DOI_BACK,
            },
            digitalSignatureEnabled && {
              label: 'Mandato - Comprador',
              docURI: (buyer as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.MANDATE,
              canUseDigitalSignature: true,
            },
            !sellerIsAccount && {
              label: 'Tarjeta Identificación Fiscal - Vendedor',
              docURI: (seller as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.TIF,
            },
            !sellerIsAccount && {
              label: 'DNI Frontal - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            !sellerIsAccount && {
              label: 'DNI Reverso - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            !sellerIsAccount &&
              digitalSignatureEnabled && {
                label: 'Mandato - Representante Vendedor',
                docURI: (seller as Company)?.representative?.mandate,
                icon: <IdentificationIcon className="w-5 h-5" />,
                owner: (seller as Company)?.representative,
                fileType: FILE_TYPE.MANDATE,
                canUseDigitalSignature: true,
              },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
            {
              label: 'Factura',
              docURI: (tramit as Transfer).transaction.proInvoice,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.PRO_INVOICE,
              owner: (tramit as Transfer).transaction,
            },
          ].filter(Boolean);
        case 'P2P':
          return [
            {
              label: 'DNI Frontal - Comprador',
              docURI: (buyer as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Comprador',
              docURI: (buyer as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.DOI_BACK,
            },
            digitalSignatureEnabled && {
              label: 'Mandato - Comprador',
              docURI: (buyer as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.MANDATE,
              canUseDigitalSignature: true,
            },
            {
              label: 'DNI Frontal - Vendedor',
              docURI: (seller as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Vendedor',
              docURI: (seller as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.DOI_BACK,
            },
            digitalSignatureEnabled && {
              label: 'Mandato - Vendedor',
              docURI: (seller as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.MANDATE,
              canUseDigitalSignature: true,
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
          ].filter(Boolean);
        default:
          return [];
      }
    }
    if (entityType === 'registration') {
      const { car, buyer } = tramit as RegistrationTramit;
      return (buyer as Company)?.representative
        ? [
            {
              label: 'Tarjeta Identificación Fiscal - Comprador',
              docURI: (buyer as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.TIF,
            },
            {
              label: 'DNI Frontal - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            digitalSignatureEnabled && {
              label: 'Mandato - Representante Comprador',
              docURI: (buyer as Company)?.representative?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.MANDATE,
              canUseDigitalSignature: true,
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
          ].filter(Boolean)
        : [
            {
              label: 'DNI Frontal - Comprador',
              docURI: (buyer as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_FRONT,
              owner: buyer,
            },
            {
              label: 'DNI Reverso - Comprador',
              docURI: (buyer as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_BACK,
              owner: buyer,
            },
            digitalSignatureEnabled && {
              label: 'Mandato - Comprador',
              docURI: (buyer as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.MANDATE,
              owner: buyer,
              canUseDigitalSignature: true,
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
              owner: car,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
              owner: car,
            },
          ].filter(Boolean);
    }
    return [];
  };

  const getTramitId = (): string => {
    switch (entityType) {
      case 'transaction':
        return (tramit as Transfer).transaction.id;
      case 'bate':
        return (tramit as BateTramit).bate.id;
      case 'registration':
        return (tramit as RegistrationTramit).registration.id;
      default:
        return null;
    }
  };

  return (
    <div className="mt-6 flex flex-col w-full">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          {Boolean(agencyDocumentsRows.length) && (
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-4">
              <AgencyDocumentsTable agencyDocumentsRows={agencyDocumentsRows} />
            </div>
          )}
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-4">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Documentos para el trámite
                  </th>
                  <th
                    scope="col"
                    className="px-8 py-3.5 text-right text-sm font-semibold text-gray-900"
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                <TableBody
                  tramit={tramit}
                  tramitType={entityType}
                  forceUpdate={forceUpdate}
                  templateRows={generateTemplateRows()}
                />
              </tbody>
            </table>
          </div>
          <DragAndDropFile
            id={getTramitId()}
            entityType={entityType}
            forceUpdate={forceUpdate}
          />
        </div>
      </div>
    </div>
  );
}

export default Documents;

// eslint-disable-next-line no-shadow
export enum TransferStatusCategories {
  ALL = 'all',
  ONGOING = 'ongoing',
  WITH_INCIDENTS = 'hasIncident',
  FINISHED = 'completed',
  CANCELED = 'canceled',
}

export const isValidTransferStatusCategory = (
  statusCategory: string,
): boolean =>
  Object.values(TransferStatusCategories).includes(
    statusCategory as TransferStatusCategories,
  );

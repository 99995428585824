import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useContext, useMemo } from 'react';
import { CheckIcon, ChevronLeftIcon } from '@heroicons/react/outline';

import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import { Car, User } from '../../../../types/types';
import { OfferFormStateType } from '../index';
import {
  WeecoverOfferResponse,
  postIssuance,
  WeecoverFuelTypes,
  WeecoverIssuncePayload,
} from '../../../../services/mechanicalWarraties';
import { userInfoContext } from '../../../../context/UserInfoContext/UserInfoContext';

type OfferProps = {
  buyer: User;
  car: Car;
  stepBack: () => void;
  selectedOffer: WeecoverOfferResponse;
  offerFormState: OfferFormStateType;
  forceUpdate: () => void;
};

type SwipooWeecoverFuelDictionaryType = {
  [key: Car['fuel']]: WeecoverFuelTypes;
};

export type VehicleFormType = {
  brand: string;
  model: string;
  chassisNumber: string;
  puchaseDate: string;
  fuelType: WeecoverFuelTypes;
};

export type BeneficiaryFormType = {
  name: string;
  surname: string;
  document: string;
  email: string;
  phoneNumber: string;
  postalCode: string;
  town: string;
  address: string;
};

const swipooWeecoverFuelDictionary: SwipooWeecoverFuelDictionaryType = {
  D: 'D',
  Elc: 'E',
  S: 'G',
  G: 'P',
  DyE: 'H',
  GyE: 'H',
  M: 'ET',
  H: 'HD',
};

const fuelLabelDictionary: { [key: string]: string } = {
  P: 'Gasolina',
  E: 'Eléctrico',
  D: 'Diesel',
  G: 'Gasolina GLP',
  H: 'Híbrido',
  ET: 'Etanol + Gasolina/Bio',
  HD: 'Hidrógeno',
};

export default function Issuance({
  stepBack,
  car,
  buyer,
  selectedOffer,
  offerFormState,
  forceUpdate,
}: OfferProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleForm, setVehicleForm] = useState<VehicleFormType>({
    brand: car.brand,
    model: car.model,
    puchaseDate: null,
    fuelType: swipooWeecoverFuelDictionary[car.fuel],
    chassisNumber: car.frameNumber,
  });

  const [beneficiaryForm, setBeneficiaryForm] = useState<BeneficiaryFormType>({
    name: buyer.name,
    surname: buyer.surname,
    document: buyer.dni,
    email: buyer.email,
    phoneNumber: buyer.phone,
    postalCode: buyer.zipCode,
    town: buyer.city,
    address: buyer.address,
  });

  const { accountInfo, companyInfo } = useContext(userInfoContext);
  const { getAccessTokenSilently } = useAuth0();

  const handleVehicleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleForm((prevState: VehicleFormType) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleBeneficaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBeneficiaryForm((prevState: BeneficiaryFormType) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const beneficiaryHasEmptyFields = useMemo(
    () =>
      Object.values(beneficiaryForm).some(
        (f) => f === null || f === undefined || f === '',
      ),
    [beneficiaryForm],
  );

  const vehicleHasEmptyFields = useMemo(
    () =>
      Object.values(vehicleForm).some(
        (f) => f === null || f === undefined || f === '',
      ),
    [vehicleForm],
  );

  const handleContractIssuance = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const payload: WeecoverIssuncePayload = {
        plate: offerFormState.carRegistration,
        productCode: selectedOffer.productCode,
        modalityCode: selectedOffer.modalityCode,
        duration: selectedOffer.duration,
        holder: {
          name: companyInfo.legalName,
          email: accountInfo.billingEmail,
          document: companyInfo.nif,
          phoneNumber: companyInfo.representative.phone?.slice(-9),
          addressInfo: {
            postalCode: companyInfo.fiscalAddressZipCode,
            address: companyInfo.fiscalAddressAddress,
            town: companyInfo.fiscalAddressCity,
          },
        },
        beneficiary: {
          name: beneficiaryForm.name,
          surname: beneficiaryForm.surname,
          document: beneficiaryForm.document,
          email: beneficiaryForm.email,
          phoneNumber: beneficiaryForm.phoneNumber?.slice(-9),
          addressInfo: {
            postalCode: beneficiaryForm.postalCode,
            town: beneficiaryForm.town,
            address: beneficiaryForm.address,
          },
        },
        subject: {
          ...vehicleForm,
          ...offerFormState,
        },
      };
      await postIssuance(token, car.id, payload);
      forceUpdate();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="px-4 pt-4 pb-3 sm:px-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Contratar garantía mecánica
          </h3>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">Vehículo</span>
        </div>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3">
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="plate"
                className="block text-sm font-medium text-gray-700"
              >
                Marca
              </label>
              <div className="mt-1">
                <input
                  value={vehicleForm.brand}
                  onChange={handleVehicleChange}
                  type="text"
                  name="brand"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!vehicleForm.brand && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="model"
                className="block text-sm font-medium text-gray-700"
              >
                Modelo
              </label>
              <div className="mt-1">
                <input
                  value={vehicleForm.model}
                  onChange={handleVehicleChange}
                  type="text"
                  name="model"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!vehicleForm.model && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="chassisNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Bastidor
              </label>
              <div className="mt-1">
                <input
                  value={vehicleForm.chassisNumber}
                  onChange={handleVehicleChange}
                  type="text"
                  name="chassisNumber"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!vehicleForm.chassisNumber && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
        </dl>
        <dl className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 mt-6">
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="fuelType"
                className="block text-sm font-medium text-gray-700"
              >
                Combustible
              </label>
              <Select<WeecoverFuelTypes>
                selected={{
                  label: fuelLabelDictionary[vehicleForm.fuelType],
                  value: fuelLabelDictionary[
                    vehicleForm.fuelType
                  ] as WeecoverFuelTypes,
                }}
                setSelected={(el: {
                  label: string;
                  value: WeecoverFuelTypes;
                }) =>
                  setVehicleForm((prevState) => ({
                    ...prevState,
                    fuelType: el.value,
                  }))
                }
                values={Object.keys(fuelLabelDictionary).map((el) => ({
                  label: fuelLabelDictionary[el as WeecoverFuelTypes],
                  value: el as WeecoverFuelTypes,
                }))}
              />
              {!vehicleForm.fuelType && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="puchaseDate"
                className="block text-sm font-medium text-gray-700"
              >
                Fecha de compra
              </label>
              <div className="mt-1">
                <input
                  value={vehicleForm.puchaseDate}
                  onChange={handleVehicleChange}
                  type="date"
                  name="puchaseDate"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!vehicleForm.puchaseDate && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
        </dl>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">
            Beneficiario
          </span>
        </div>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3">
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Nombre
              </label>
              <div className="mt-1">
                <input
                  value={beneficiaryForm.name}
                  onChange={handleBeneficaryChange}
                  type="text"
                  name="name"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!beneficiaryForm.name && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="surname"
                className="block text-sm font-medium text-gray-700"
              >
                Apellidos
              </label>
              <div className="mt-1">
                <input
                  value={beneficiaryForm.surname}
                  onChange={handleBeneficaryChange}
                  type="text"
                  name="surname"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!beneficiaryForm.surname && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="document"
                className="block text-sm font-medium text-gray-700"
              >
                DNI
              </label>
              <div className="mt-1">
                <input
                  value={beneficiaryForm.document}
                  onChange={handleBeneficaryChange}
                  type="text"
                  name="document"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!beneficiaryForm.document && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
        </dl>
        <dl className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 mt-6">
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700"
              >
                Dirección
              </label>
              <div className="mt-1">
                <input
                  value={beneficiaryForm.address}
                  onChange={handleBeneficaryChange}
                  type="text"
                  name="address"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!beneficiaryForm.address && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="town"
                className="block text-sm font-medium text-gray-700"
              >
                Ciudad
              </label>
              <div className="mt-1">
                <input
                  value={beneficiaryForm.town}
                  onChange={handleBeneficaryChange}
                  type="text"
                  name="town"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!beneficiaryForm.town && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="postalCode"
                className="block text-sm font-medium text-gray-700"
              >
                Código Postal
              </label>
              <div className="mt-1">
                <input
                  value={beneficiaryForm.postalCode}
                  onChange={handleBeneficaryChange}
                  type="text"
                  name="postalCode"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!beneficiaryForm.postalCode && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
        </dl>
        <dl className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 mt-6">
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Correo
              </label>
              <div className="mt-1">
                <input
                  value={beneficiaryForm.email}
                  onChange={handleBeneficaryChange}
                  type="email"
                  name="email"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!beneficiaryForm.email && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Teléfono
              </label>
              <div className="mt-1">
                <input
                  value={beneficiaryForm.phoneNumber}
                  onChange={handleBeneficaryChange}
                  type="tel"
                  name="phoneNumber"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!beneficiaryForm.phoneNumber && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
        </dl>
      </div>
      <div className="flex w-full justify-between mt-3 px-4 py-5 sm:px-6">
        <Button
          bgColor="bg-blue-50"
          hoverBgColor="bg-blue-50"
          textColor="blue-600"
          text="Atrás"
          LeftIcon={ChevronLeftIcon}
          callback={stepBack}
        />
        <Button
          bgColor="bg-blue-700"
          hoverBgColor="bg-blue-900"
          textColor="white"
          text="Contratar"
          RightIcon={CheckIcon}
          callback={handleContractIssuance}
          loading={isLoading}
          disabled={vehicleHasEmptyFields || beneficiaryHasEmptyFields}
        />
      </div>
    </>
  );
}

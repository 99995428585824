import axios from 'axios';
import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useAxios = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const runRequest = useCallback(
    async (config = {}) => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const { data: responseData } = await axios(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            ...config?.headers,
          },
          ...config,
        });
        setData(responseData);
      } catch (e) {
        setData(null);
        setError(e);
      }
      setIsLoading(false);
    },
    [getAccessTokenSilently, url],
  );

  const resetValues = () => {
    setData(null);
    setIsLoading(false);
    setError(null);
  };

  return { data, isLoading, error, runRequest, resetValues };
};

export const asyncState = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  NOT_FOUND: 'NOT_FOUND',
  SUCCESS: 'SUCCESS',
};

export const ccaaDictionary = {
  CT: 'Cataluña',
  MD: 'Madrid',
  EX: 'Extremadura',
  PV: 'País Vasco',
  NC: 'Navarra',
  RI: 'La Rioja',
  CL: 'Castilla y León',
  CM: 'Castilla-La Mancha',
  CB: 'Cantabria',
  GA: 'Galicia',
  AS: 'Asturias',
  CN: 'Canarias',
  AN: 'Andalucía',
  AR: 'Aragón',
  IB: 'Islas baleares',
  MC: 'Murcia',
  VC: 'Comunidad Valenciana',
};

export const fuelDictionary = {
  G: 'Gasolina',
  D: 'Diesel',
  Elc: 'Eléctrico',
  DyE: 'Híbrido Diesel y Eléctrico',
  GyE: 'Híbrido Gasolina y Eléctrico',
  S: 'Gasolina GLP',
  M: 'Etanol + Gasolina/Bio',
  H: 'Hidrógeno',
};

export const serviceTypeDict = {
  A00: 'PUBLICO SIN EPECIFICAR',
  A01: 'PUBLICO ALQUILER SIN CONDUCTOR',
  A02: 'PUBLICO ALQUILER CON CONDUCTOR',
  A03: 'PUBLICO APRENDIZAJE CONDUCCION',
  A04: 'PUBLICO TAXI',
  A05: 'PUBLICO AUXILIO CARRETERA',
  A07: 'PUBLICO AMBULANCIA',
  A08: 'PUBLICO FUNERARIO',
  A10: 'PUBLICO MERCANCIAS PELIGROSAS',
  A11: 'PUBLICO BASURERO',
  A12: 'PUBLICO TRANSPORTE ESCOLAR',
  A13: 'PUBLICO POLICIA',
  A14: 'PUBLICO BOMBEROS',
  A15: 'PUBLICO PROTECCION CIVIL Y SALVAMENTO',
  A16: 'PUBLICO MINISTERIO DE DEFENSA',
  A18: 'PUBLICO ACTIVIDAD ECONOMICA',
  A20: 'PUBLICO MERCANCIAS PERECEDERAS',
  B00: 'PARTICULAR SIN ESPECIFICAR',
  B06: 'PARTICULAR AGRICOLA',
  B09: 'PARTICULAR OBRAS',
  B17: 'PARTICULAR VIVIENDA',
  B18: 'PARTICULAR ACTIVIDAD ECONOMICA',
  B19: 'PARTICULAR RECREATIVO',
  B21: 'PARTICULAR VEHICULO DE FERIAS',
};

export const ENTITY_TYPES = {
  TRANSACTION: 'TRANSACTION',
  BATE: 'BATE',
  REGISTRATION: 'REGISTRATION',
  ACCOUNT: 'ACCOUNT',
  TRAFFIC_REPORT: 'TRAFFIC_REPORT',
};

export const TRAMIT_SUB_TYPES = {
  transfer: 'Transferencia',
  registration: 'Matriculación',
  batecom_purchase: 'BATE',
  batecom_sale: 'COM',
};

export const TRAMIT_PATHS = {
  transfer: 'transfers',
  registration: 'registration',
  batecom_purchase: 'bates',
  batecom_sale: 'transfers',
};

export const statesEnum = [
  'Andalucía',
  'Aragón',
  'Canarias',
  'Cantabria',
  'Castilla y León',
  'Castilla-La Mancha',
  'Cataluña',
  'Ceuta',
  'Comunidad Valenciana',
  'Comunidad de Madrid',
  'Extremadura',
  'Galicia',
  'Islas Baleares',
  'La Rioja',
  'Melilla',
  'Navarra',
  'País Vasco',
  'Principado de Asturias',
  'Región de Murcia',
];

export const statusEnums = [
  {
    id: 0,
    label: 'Documentación pendiente',
    bgColor: 'bg-yellow-300',
    textColor: 'text-yellow-700',
  },
  {
    id: 1,
    label: 'Revisar documentación',
    bgColor: 'bg-pink-300',
    textColor: 'text-pink-700',
  },
  {
    id: 3,
    label: 'Permiso circulación enviado',
    bgColor: 'bg-blue-200',
    textColor: 'text-blue-700',
  },
  {
    id: 4,
    label: 'Presentado a gestoría',
    bgColor: 'bg-blue-800',
    textColor: 'text-blue-100',
  },
  {
    id: 5,
    label: 'Contrato digital enviado',
    bgColor: 'bg-yellow-700',
    textColor: 'text-yellow-400',
  },
  {
    id: 6,
    label: 'Contrato digital firmado',
    bgColor: 'bg-pink-900',
    textColor: 'text-pink-300',
  },
  {
    id: 7,
    label: 'Anulado',
    bgColor: 'bg-black',
    textColor: 'text-white',
  },
  {
    id: 8,
    label: 'Permiso circulación en oficina',
    bgColor: 'bg-green-400',
    textColor: 'text-black',
  },
  {
    id: 9,
    label: 'Permiso circulación entregado',
    bgColor: 'bg-teal-600',
    textColor: 'text-white',
  },
  {
    id: 10,
    label: 'Presentado a DGT',
    bgColor: 'bg-orange-500',
    textColor: 'text-white',
  },
  {
    id: 11,
    label: 'Finalizado por DGT',
    bgColor: 'bg-fuchsia-600',
    textColor: 'text-white',
  },
];

export const statusToEditShipmentEnums = {
  pendingDocumentation: 0,
  reviewPendingDocumentation: 1,
  circulationPermitSent: 3,
  digitalContractSent: 5,
  digitalContractSigned: 6,
  presentedToAgency: 4,
  circulationPermitAtOffice: 8,
  presentedToDGT: 10,
  finishedByDGT: 11,
};

export const proStatusEnums = [
  {
    id: 0,
    label: 'Documentación pendiente',
    bgColor: 'bg-yellow-300',
    textColor: 'text-yellow-700',
  },
  {
    id: 1,
    label: 'Preparado para tramitar',
    bgColor: 'bg-pink-300',
    textColor: 'text-pink-700',
  },
];

export const BATE_STATUS_ENUM = {
  PENDING_DOCUMENTATION: 'PENDING_DOCUMENTATION',
  REVIEW_PENDING_DOCUMENTATION: 'REVIEW_PENDING_DOCUMENTATION',
  PRESENTED_TO_AGENCY: 'PRESENTED_TO_AGENCY',
  PRESENTED_TO_DGT: 'PRESENTED_TO_DGT',
  FINISHED_BY_DGT: 'FINISHED_BY_DGT',
  CANCELED: 'CANCELED',
};

export const REGISTRATION_STATUS = {
  PENDING_DOCUMENTATION: 'PENDING_DOCUMENTATION',
  REVIEW_PENDING_DOCUMENTATION: 'REVIEW_PENDING_DOCUMENTATION',
  PRESENTED_TO_AGENCY: 'PRESENTED_TO_AGENCY',
  PRESENTED_TO_DGT: 'PRESENTED_TO_DGT',
  FINISHED_BY_DGT: 'FINISHED_BY_DGT',
  CANCELED: 'CANCELED',
  DOCS_AT_OFFICE: 'DOCS_AT_OFFICE',
  DOCS_SENT: 'DOCS_SENT',
  DOCS_DELIVERED: 'DOCS_DELIVERED',
};

export const bateStatus = [
  {
    id: BATE_STATUS_ENUM.PENDING_DOCUMENTATION,
    label: 'Documentación pendiente',
    bgColor: 'bg-yellow-300',
    textColor: 'text-yellow-700',
  },
  {
    id: BATE_STATUS_ENUM.REVIEW_PENDING_DOCUMENTATION,
    label: 'Revisar documentación',
    bgColor: 'bg-pink-300',
    textColor: 'text-pink-700',
  },
  {
    id: BATE_STATUS_ENUM.PRESENTED_TO_AGENCY,
    label: 'Presentado a gestoría',
    bgColor: 'bg-blue-800',
    textColor: 'text-blue-100',
  },
  {
    id: BATE_STATUS_ENUM.PRESENTED_TO_DGT,
    label: 'Presentado a DGT',
    bgColor: 'bg-orange-500',
    textColor: 'text-white',
  },
  {
    id: BATE_STATUS_ENUM.FINISHED_BY_DGT,
    label: 'Finalizado por DGT',
    bgColor: 'bg-fuchsia-600',
    textColor: 'text-white',
  },
  {
    id: BATE_STATUS_ENUM.CANCELED,
    label: 'Anulado',
    bgColor: 'bg-black',
    textColor: 'text-white',
  },
];

export const registrationEnums = [
  {
    id: REGISTRATION_STATUS.PENDING_DOCUMENTATION,
    label: 'Documentación pendiente',
    bgColor: 'bg-yellow-300',
    textColor: 'text-yellow-700',
  },
  {
    id: REGISTRATION_STATUS.REVIEW_PENDING_DOCUMENTATION,
    label: 'Revisar documentación',
    bgColor: 'bg-pink-300',
    textColor: 'text-pink-700',
  },
  {
    id: REGISTRATION_STATUS.PRESENTED_TO_AGENCY,
    label: 'Presentado a gestoría',
    bgColor: 'bg-blue-800',
    textColor: 'text-blue-100',
  },
  {
    id: REGISTRATION_STATUS.PRESENTED_TO_DGT,
    label: 'Presentado a DGT',
    bgColor: 'bg-orange-500',
    textColor: 'text-white',
  },
  {
    id: REGISTRATION_STATUS.FINISHED_BY_DGT,
    label: 'Finalizado por DGT',
    bgColor: 'bg-fuchsia-600',
    textColor: 'text-white',
  },
  {
    id: REGISTRATION_STATUS.CANCELED,
    label: 'Anulado',
    bgColor: 'bg-black',
    textColor: 'text-white',
  },
  {
    id: REGISTRATION_STATUS.DOCS_AT_OFFICE,
    label: 'Documentación en oficina',
    bgColor: 'bg-green-400',
    textColor: 'text-black',
  },
  {
    id: REGISTRATION_STATUS.DOCS_SENT,
    label: 'Documentación enviada',
    bgColor: 'bg-blue-200',
    textColor: 'text-blue-700',
  },
  {
    id: REGISTRATION_STATUS.DOCS_DELIVERED,
    label: 'Documentación entregada',
    bgColor: 'bg-teal-600',
    textColor: 'text-white',
  },
];

export const brandCarsEnums = [
  {
    label: 'ABARTH',
    value: 'ABARTH',
  },
  {
    label: 'AIWAYS',
    value: 'AIWAYS',
  },
  {
    label: 'ALFA ROMEO',
    value: 'ALFA ROMEO',
  },
  {
    label: 'ALPINE',
    value: 'ALPINE',
  },
  {
    label: 'ARO',
    value: 'ARO',
  },
  {
    label: 'ASTON MARTIN',
    value: 'ASTON MARTIN',
  },
  {
    label: 'AUDI',
    value: 'AUDI',
  },
  {
    label: 'AUSTIN',
    value: 'AUSTIN',
  },
  {
    label: 'BAIC',
    value: 'BAIC',
  },
  {
    label: 'BENTLEY',
    value: 'BENTLEY',
  },
  {
    label: 'BMW',
    value: 'BMW',
  },
  {
    label: 'BMWI',
    value: 'BMWI',
  },
  {
    label: 'BYD',
    value: 'BYD',
  },
  {
    label: 'CADILLAC',
    value: 'CADILLAC',
  },
  {
    label: 'CATERHAM',
    value: 'CATERHAM',
  },
  {
    label: 'CENNTRO',
    value: 'CENNTRO',
  },
  {
    label: 'CHEVROLET',
    value: 'CHEVROLET',
  },
  {
    label: 'CHRYSLER',
    value: 'CHRYSLER',
  },
  {
    label: 'CITROEN',
    value: 'CITROEN',
  },
  {
    label: 'CORVETTE',
    value: 'CORVETTE',
  },
  {
    label: 'CUPRA',
    value: 'CUPRA',
  },
  {
    label: 'DACIA',
    value: 'DACIA',
  },
  {
    label: 'DAEWOO',
    value: 'DAEWOO',
  },
  {
    label: 'DAIHATSU',
    value: 'DAIHATSU',
  },
  {
    label: 'DAIMLER',
    value: 'DAIMLER',
  },
  {
    label: 'DFSK MOTORS',
    value: 'DFSK MOTORS',
  },
  {
    label: 'DODGE',
    value: 'DODGE',
  },
  {
    label: 'DR MOTOR',
    value: 'DR MOTOR',
  },
  {
    label: 'DS',
    value: 'DS',
  },
  {
    label: 'FERRARI',
    value: 'FERRARI',
  },
  {
    label: 'FIAT',
    value: 'FIAT',
  },
  {
    label: 'FISKER',
    value: 'FISKER',
  },
  {
    label: 'FORD',
    value: 'FORD',
  },
  {
    label: 'FSM',
    value: 'FSM',
  },
  {
    label: 'GALLOPER',
    value: 'GALLOPER',
  },
  {
    label: 'GREEN TOUR',
    value: 'GREEN TOUR',
  },
  {
    label: 'HONDA',
    value: 'HONDA',
  },
  {
    label: 'HUMMER',
    value: 'HUMMER',
  },
  {
    label: 'HURTAN',
    value: 'HURTAN',
  },
  {
    label: 'HYUNDAI',
    value: 'HYUNDAI',
  },
  {
    label: 'INEOS',
    value: 'INEOS',
  },
  {
    label: 'INFINITI',
    value: 'INFINITI',
  },
  {
    label: 'INNOCENTI',
    value: 'INNOCENTI',
  },
  {
    label: 'ISUZU',
    value: 'ISUZU',
  },
  {
    label: 'IVECO',
    value: 'IVECO',
  },
  {
    label: 'JAC',
    value: 'JAC',
  },
  {
    label: 'JAGUAR',
    value: 'JAGUAR',
  },
  {
    label: 'JEEP',
    value: 'JEEP',
  },
  {
    label: 'KIA',
    value: 'KIA',
  },
  {
    label: 'KTM',
    value: 'KTM',
  },
  {
    label: 'LADA',
    value: 'LADA',
  },
  {
    label: 'LAMBORGHINI',
    value: 'LAMBORGHINI',
  },
  {
    label: 'LANCIA',
    value: 'LANCIA',
  },
  {
    label: 'LAND ROVER',
    value: 'LAND ROVER',
  },
  {
    label: 'LEVC',
    value: 'LEVC',
  },
  {
    label: 'LEXUS',
    value: 'LEXUS',
  },
  {
    label: 'LINK & CO',
    value: 'LINK & CO',
  },
  {
    label: 'LOTUS',
    value: 'LOTUS',
  },
  {
    label: 'MAHINDRA',
    value: 'MAHINDRA',
  },
  {
    label: 'MASERATI',
    value: 'MASERATI',
  },
  {
    label: 'MAXUS',
    value: 'MAXUS',
  },
  {
    label: 'MAYBACH',
    value: 'MAYBACH',
  },
  {
    label: 'MAZDA',
    value: 'MAZDA',
  },
  {
    label: 'MCLAREN',
    value: 'MCLAREN',
  },
  {
    label: 'MERCEDES',
    value: 'MERCEDES',
  },
  {
    label: 'MG',
    value: 'MG',
  },
  {
    label: 'MINI',
    value: 'MINI',
  },
  {
    label: 'MITSUBISHI',
    value: 'MITSUBISHI',
  },
  {
    label: 'MK SPORTSCAR',
    value: 'MK SPORTSCAR',
  },
  {
    label: 'MOBILIZE',
    value: 'MOBILIZE',
  },
  {
    label: 'MORGAN',
    value: 'MORGAN',
  },
  {
    label: 'NISSAN',
    value: 'NISSAN',
  },
  {
    label: 'OPEL',
    value: 'OPEL',
  },
  {
    label: 'PEUGEOT',
    value: 'PEUGEOT',
  },
  {
    label: 'PGO',
    value: 'PGO',
  },
  {
    label: 'PIAGGIO',
    value: 'PIAGGIO',
  },
  {
    label: 'POLESTAR',
    value: 'POLESTAR',
  },
  {
    label: 'PONTIAC',
    value: 'PONTIAC',
  },
  {
    label: 'PORSCHE',
    value: 'PORSCHE',
  },
  {
    label: 'QOROS',
    value: 'QOROS',
  },
  {
    label: 'RENAULT',
    value: 'RENAULT',
  },
  {
    label: 'ROLLS ROYCE',
    value: 'ROLLS ROYCE',
  },
  {
    label: 'ROVER',
    value: 'ROVER',
  },
  {
    label: 'SAAB',
    value: 'SAAB',
  },
  {
    label: 'SANTANA',
    value: 'SANTANA',
  },
  {
    label: 'SEAT',
    value: 'SEAT',
  },
  {
    label: 'SKODA',
    value: 'SKODA',
  },
  {
    label: 'SMART',
    value: 'SMART',
  },
  {
    label: 'SSANGYONG',
    value: 'SSANGYONG',
  },
  {
    label: 'SUBARU',
    value: 'SUBARU',
  },
  {
    label: 'SUZUKI',
    value: 'SUZUKI',
  },
  {
    label: 'SWM',
    value: 'SWM',
  },
  {
    label: 'TATA',
    value: 'TATA',
  },
  {
    label: 'TESLA',
    value: 'TESLA',
  },
  {
    label: 'THINK',
    value: 'THINK',
  },
  {
    label: 'TOYOTA',
    value: 'TOYOTA',
  },
  {
    label: 'VOLKSWAGEN',
    value: 'VOLKSWAGEN',
  },
  {
    label: 'VOLVO',
    value: 'VOLVO',
  },
  {
    label: 'ZIDHOU',
    value: 'ZIDHOU',
  },
];

export const fuelEnums = [
  {
    value: 'D',
    label: 'Diesel',
  },
  {
    value: 'Elc',
    label: 'Eléctrico',
  },
  {
    value: 'G',
    label: 'Gasolina',
  },
  {
    value: 'S',
    label: 'Gasolina/Gasolina GLP',
  },
  {
    value: 'M',
    label: 'Etanol + Gasolina/Bio',
  },
  {
    value: 'DyE',
    label: 'Hibrido Diesel Eléctrico',
  },
  {
    value: 'GyE',
    label: 'Hibrido Gasolina Eléctrico',
  },
  {
    value: 'H',
    label: 'Hidrógeno',
  },
];

export const taxCCAAsEnums = [
  { label: 'Andalucía', value: 'AN' },
  { label: 'Aragón', value: 'AR' },
  { label: 'Asturias', value: 'AS' },
  { label: 'Cantabria', value: 'CB' },
  { label: 'Castilla y León', value: 'CL' },
  { label: 'Castilla-La Mancha', value: 'CM' },
  { label: 'Cataluña', value: 'CT' },
  { label: 'Comunidad Valenciana', value: 'VC' },
  { label: 'Extremadura', value: 'EX' },
  { label: 'Galicia', value: 'GA' },
  { label: 'Islas baleares', value: 'IB' },
  { label: 'La Rioja', value: 'RI' },
  { label: 'Madrid', value: 'MD' },
  { label: 'Murcia', value: 'MC' },
  { label: 'Navarra', value: 'NC' },
  { label: 'País Vasco', value: 'PV' },
];

export const vehicleTypeEnums = [
  { value: 'car', label: 'Coche' },
  { value: 'moto', label: 'Moto' },
];

export const brandMotoEnums = [
  { value: 'Adivia', label: 'Adivia' },
  { value: 'Aixam', label: 'Aixam' },
  { value: 'AIE', label: 'AIE' },
  { value: 'Aiyumo', label: 'Aiyumo' },
  { value: 'AJP', label: 'AJP' },
  { value: 'Alfer', label: 'Alfer' },
  { value: 'Alpina Renania', label: 'Alpina Renania' },
  { value: 'American Ironhorse', label: 'American Ironhorse' },
  { value: 'Apache', label: 'Apache' },
  { value: 'Apollo Orion', label: 'Apollo Orion' },
  { value: 'Aprilia', label: 'Aprilia' },
  { value: 'Arctic Cat', label: 'Arctic Cat' },
  { value: 'Asiawing', label: 'Asiawing' },
  { value: 'Askoll', label: 'Askoll' },
  { value: 'Astor', label: 'Astor' },
  { value: 'Atala', label: 'Atala' },
  { value: 'Ax Road', label: 'Ax Road' },
  { value: 'Azel', label: 'Azel' },
  { value: 'Bajaj', label: 'Bajaj' },
  { value: 'Baotian', label: 'Baotian' },
  { value: 'Barossa', label: 'Barossa' },
  { value: 'Batery', label: 'Batery' },
  { value: 'Benelli', label: 'Benelli' },
  { value: 'Bereco', label: 'Bereco' },
  { value: 'Beta', label: 'Beta' },
  { value: 'Big Dog', label: 'Big Dog' },
  { value: 'Bimota', label: 'Bimota' },
  { value: 'BMW', label: 'BMW' },
  { value: 'Boss Hoss', label: 'Boss Hoss' },
  { value: 'Brammo', label: 'Brammo' },
  { value: 'Buell', label: 'Buell' },
  { value: 'Brixton', label: 'Brixton' },
  { value: 'BRP', label: 'BRP' },
  { value: 'BTM', label: 'BTM' },
  { value: 'BSG Electrics', label: 'BSG Electrics' },
  { value: 'Buccimoto', label: 'Buccimoto' },
  { value: 'Bultaco', label: 'Bultaco' },
  { value: 'Bunker Trike', label: 'Bunker Trike' },
  { value: 'Cagiva', label: 'Cagiva' },
  { value: 'Cannondale', label: 'Cannondale' },
  { value: 'Canyon', label: 'Canyon' },
  { value: 'CBK', label: 'CBK' },
  { value: 'CFMOTO', label: 'CFMOTO' },
  { value: 'Chopper Nation', label: 'Chopper Nation' },
  { value: 'Claraxavi', label: 'Claraxavi' },
  { value: 'Clipic', label: 'Clipic' },
  { value: 'Colibir', label: 'Colibir' },
  { value: 'CPI', label: 'CPI' },
  { value: 'CSR', label: 'CSR' },
  { value: 'Daelim', label: 'Daelim' },
  { value: 'DAK', label: 'DAK' },
  { value: 'Dayun', label: 'Dayun' },
  { value: 'Derbi', label: 'Derbi' },
  { value: 'DH HAOTIAN', label: 'DH HAOTIAN' },
  { value: 'Dino', label: 'Dino' },
  { value: 'Dobo', label: 'Dobo' },
  { value: 'Dorton', label: 'Dorton' },
  { value: 'Dragon TT', label: 'Dragon TT' },
  { value: 'Ducati', label: 'Ducati' },
  { label: 'E-max', value: 'E-max' },
  { label: 'E-ton', value: 'E-ton' },
  { label: 'Easy Trike', value: 'Easy Trike' },
  { label: 'Ebroh', value: 'Ebroh' },
  { label: 'Ecooter', value: 'Ecooter' },
  { label: 'Efun', value: 'Efun' },
  { label: 'Electric City Motor 00', value: 'Electric City Motor 00' },
  { label: 'Elmoto', value: 'Elmoto' },
  { label: 'EMF', value: 'EMF' },
  { label: 'Emocycles', value: 'Emocycles' },
  { label: 'Energica', value: 'Energica' },
  { label: 'Explorer', value: 'Explorer' },
  { label: 'Factory Bike', value: 'Factory Bike' },
  { label: 'Fantic', value: 'Fantic' },
  { label: 'Fauro', value: 'Fauro' },
  { label: 'Faztek', value: 'Faztek' },
  { label: 'FB Mondial', value: 'FB Mondial' },
  { label: 'FKM', value: 'FKM' },
  { label: 'Garelli', value: 'Garelli' },
  { label: 'Gas Gas', value: 'Gas Gas' },
  { label: 'Generic', value: 'Generic' },
  { label: 'Gilera', value: 'Gilera' },
  { label: 'Goelix', value: 'Goelix' },
  { label: 'Goes', value: 'Goes' },
  { label: 'Gowinn', value: 'Gowinn' },
  { label: 'Hammel', value: 'Hammel' },
  { label: 'Hanway', value: 'Hanway' },
  { label: 'Harley Davidson', value: 'Harley Davidson' },
  { label: 'Helectra', value: 'Helectra' },
  { label: 'Herald', value: 'Herald' },
  { label: 'HM', value: 'HM' },
  { label: 'Honda', value: 'Honda' },
  { label: 'Horwin', value: 'Horwin' },
  { label: 'HRD', value: 'HRD' },
  { label: 'Hsun', value: 'Hsun' },
  { label: 'Huatian', value: 'Huatian' },
  { label: 'Hudson Boss', value: 'Hudson Boss' },
  { label: 'Husaberg', value: 'Husaberg' },
  { label: 'Husqvarna', value: 'Husqvarna' },
  { label: 'Hyosung', value: 'Hyosung' },
  { label: 'I-moto', value: 'I-moto' },
  { label: 'Imax', value: 'Imax' },
  { label: 'IMR', value: 'IMR' },
  { label: 'Indian', value: 'Indian' },
  { label: 'Infmoto', value: 'Infmoto' },
  { label: 'Innocenti', value: 'Innocenti' },
  { label: 'Invicta', value: 'Invicta' },
  { label: 'Iotostark', value: 'Iotostark' },
  { label: 'Italjet', value: 'Italjet' },
  { label: 'Jcadi', value: 'Jcadi' },
  { label: 'Jianshe', value: 'Jianshe' },
  { label: 'Jin Lun', value: 'Jin Lun' },
  { label: 'Jincheng', value: 'Jincheng' },
  { label: 'Jnen', value: 'Jnen' },
  { label: 'Jonway', value: 'Jonway' },
  { label: 'JTG', value: 'JTG' },
  { label: 'JTS', value: 'JTS' },
  { label: 'Kangxin', value: 'Kangxin' },
  { label: 'Kasea', value: 'Kasea' },
  { label: 'Kawasaki', value: 'Kawasaki' },
  { label: 'Kazuma', value: 'Kazuma' },
  { label: 'Keeway', value: 'Keeway' },
  { label: 'Kenrod', value: 'Kenrod' },
  { label: 'Kinroad', value: 'Kinroad' },
  { label: 'Ksr Moto', value: 'Ksr Moto' },
  { label: 'KTM', value: 'KTM' },
  { label: 'Kuberg', value: 'Kuberg' },
  { label: 'Kymco', value: 'Kymco' },
  { label: 'Lambretta', value: 'Lambretta' },
  { label: 'Lanvertti', value: 'Lanvertti' },
  { label: 'Lem', value: 'Lem' },
  { label: 'Lemev', value: 'Lemev' },
  { label: 'Leonart', value: 'Leonart' },
  { label: 'Lexmoto', value: 'Lexmoto' },
  { label: 'Lifan', value: 'Lifan' },
  { label: 'Ling Ben', value: 'Ling Ben' },
  { label: 'Linhai', value: 'Linhai' },
  { label: 'Lml', value: 'Lml' },
  { label: 'Lube', value: 'Lube' },
  { label: 'Lvneng', value: 'Lvneng' },
  { label: 'Macbor', value: 'Macbor' },
  { label: 'Malaguti', value: 'Malaguti' },
  { label: 'Malcor', value: 'Malcor' },
  { label: 'Masai', value: 'Masai' },
  { label: 'Mash', value: 'Mash' },
  { label: 'MBK', value: 'MBK' },
  { label: 'Mecatecno', value: 'Mecatecno' },
  { label: 'Megelli', value: 'Megelli' },
  { label: 'Meko', value: 'Meko' },
  { label: 'Merlin', value: 'Merlin' },
  { label: 'Metrakit', value: 'Metrakit' },
  { label: 'Mh Motorcycles', value: 'Mh Motorcycles' },
  { label: 'Minelli', value: 'Minelli' },
  { label: 'Mitt', value: 'Mitt' },
  { label: 'Mobilette', value: 'Mobilette' },
  { label: 'Moden', value: 'Moden' },
  { label: 'Monkey Bike', value: 'Monkey Bike' },
  { label: 'Monster Pro', value: 'Monster Pro' },
  { label: 'Monterosso', value: 'Monterosso' },
  { label: 'Montesa', value: 'Montesa' },
  { label: 'Moriwaki', value: 'Moriwaki' },
  { label: 'Motivas', value: 'Motivas' },
  { label: 'Moto Guzzi', value: 'Moto Guzzi' },
  { label: 'Moto Morini', value: 'Moto Morini' },
  { label: 'Motogac', value: 'Motogac' },
  { label: 'Motor Hispania', value: 'Motor Hispania' },
  { label: 'Motor Mania', value: 'Motor Mania' },
  { label: 'MTM', value: 'MTM' },
  { label: 'MTR', value: 'MTR' },
  { label: 'MUZ', value: 'MUZ' },
  { label: 'MV Agusta', value: 'MV Agusta' },
  { label: 'Mxonda', value: 'Mxonda' },
  { label: 'Neco', value: 'Neco' },
  { label: 'Next', value: 'Next' },
  { label: 'Nimoto', value: 'Nimoto' },
  { label: 'NIU', value: 'NIU' },
  { label: 'Norton', value: 'Norton' },
  { label: 'Orcal', value: 'Orcal' },
  { label: 'Orion', value: 'Orion' },
  { label: 'Oset', value: 'Oset' },
  { label: 'Ossa', value: 'Ossa' },
  { label: 'Peda', value: 'Peda' },
  { label: 'Peugeot', value: 'Peugeot' },
  { label: 'PGO', value: 'PGO' },
  { label: 'Piaggio', value: 'Piaggio' },
  { label: 'Polini', value: 'Polini' },
  { label: 'Puch', value: 'Puch' },
  { label: 'Qingqi', value: 'Qingqi' },
  { label: 'Qooder', value: 'Qooder' },
  { label: 'Quadro', value: 'Quadro' },
  { label: 'Quantya', value: 'Quantya' },
  { label: 'Quazzar', value: 'Quazzar' },
  { label: 'RAV', value: 'RAV' },
  { label: 'Raydan', value: 'Raydan' },
  { label: 'Rebel', value: 'Rebel' },
  { label: 'Renault', value: 'Renault' },
  { label: 'Retto', value: 'Retto' },
  { label: 'Rewaco', value: 'Rewaco' },
  { label: 'Ridley Motorcycles', value: 'Ridley Motorcycles' },
  { label: 'Rieju', value: 'Rieju' },
  { label: 'Rieju Nuuk', value: 'Rieju Nuuk' },
  { label: 'Riya', value: 'Riya' },
  { label: 'Roan', value: 'Roan' },
  { label: 'Roketa Moto', value: 'Roketa Moto' },
  { label: 'Royal Alloy', value: 'Royal Alloy' },
  { label: 'Royal Enfield', value: 'Royal Enfield' },
  { label: 'RSX', value: 'RSX' },
  { label: 'Sachs Bikes', value: 'Sachs Bikes' },
  { label: 'Samada', value: 'Samada' },
  { label: 'Sanben', value: 'Sanben' },
  { label: 'Sanyou', value: 'Sanyou' },
  { label: 'Scomadi', value: 'Scomadi' },
  { label: 'Scoolectrix', value: 'Scoolectrix' },
  { label: 'Scorpa', value: 'Scorpa' },
  { label: 'Scutum', value: 'Scutum' },
  { label: 'Senke', value: 'Senke' },
  { label: 'Setter', value: 'Setter' },
  { label: 'Sherco', value: 'Sherco' },
  { label: 'Shineray', value: 'Shineray' },
  { label: 'Siam', value: 'Siam' },
  { label: 'Silence', value: 'Silence' },
  { label: 'Sincro', value: 'Sincro' },
  { label: 'Singaz', value: 'Singaz' },
  { label: 'Skyteam', value: 'Skyteam' },
  { label: 'Soriano Motori', value: 'Soriano Motori' },
  { label: 'Sumco', value: 'Sumco' },
  { label: 'Sumo', value: 'Sumo' },
  { label: 'Sunra', value: 'Sunra' },
  { label: 'Super Soco', value: 'Super Soco' },
  { label: 'Suzuki', value: 'Suzuki' },
  { label: 'Swm', value: 'Swm' },
  { label: 'Sym', value: 'Sym' },
  { label: 'Tbq', value: 'Tbq' },
  { label: 'Tgb', value: 'Tgb' },
  { label: 'Thunder Mountain', value: 'Thunder Mountain' },
  { label: 'Tm', value: 'Tm' },
  { label: 'Torrot', value: 'Torrot' },
  { label: 'Trakker', value: 'Trakker' },
  { label: 'Triumph', value: 'Triumph' },
  { label: 'Trs Motorcycles', value: 'Trs Motorcycles' },
  { label: 'Turbho', value: 'Turbho' },
  { label: 'UM', value: 'UM' },
  { label: 'Universal Motor', value: 'Universal Motor' },
  { label: 'Unoracing', value: 'Unoracing' },
  { label: 'Ural', value: 'Ural' },
  { label: 'Urban Electric Motors', value: 'Urban Electric Motors' },
  { label: 'Vectrix', value: 'Vectrix' },
  { label: 'Velca', value: 'Velca' },
  { label: 'Velimotor', value: 'Velimotor' },
  { label: 'Vertigo', value: 'Vertigo' },
  { label: 'Vespa', value: 'Vespa' },
  { label: 'Vespino', value: 'Vespino' },
  { label: 'Via Scooter', value: 'Via Scooter' },
  { label: 'Victory', value: 'Victory' },
  { label: 'Vigar', value: 'Vigar' },
  { label: 'Vikers', value: 'Vikers' },
  { label: 'Vmoto', value: 'Vmoto' },
  { label: 'Voge', value: 'Voge' },
  { label: 'Volta Motorbikes', value: 'Volta Motorbikes' },
  { label: 'Vor', value: 'Vor' },
  { label: 'Voxan', value: 'Voxan' },
  { label: 'Wildlander', value: 'Wildlander' },
  { label: 'WK', value: 'WK' },
  { label: 'Wottan', value: 'Wottan' },
  { label: 'Xero', value: 'Xero' },
  { label: 'Xingyue', value: 'Xingyue' },
  { label: 'Xispa', value: 'Xispa' },
  { label: 'Xmotos', value: 'Xmotos' },
  { label: 'XPA', value: 'XPA' },
  { label: 'Yamaha', value: 'Yamaha' },
  { label: 'YCF', value: 'YCF' },
  { label: 'Yiying', value: 'Yiying' },
  { label: 'YMR', value: 'YMR' },
  { label: 'Young Rider', value: 'Young Rider' },
  { label: 'Zeltech', value: 'Zeltech' },
  { label: 'Zero', value: 'Zero' },
  { label: 'Zero Motorcycles', value: 'Zero Motorcycles' },
  { label: 'Znen', value: 'Znen' },
  { label: 'Zongshen', value: 'Zongshen' },
  { label: 'Zontes', value: 'Zontes' },
  { label: 'Zuap', value: 'Zuap' },
];

export const ccEnums = [
  { label: 'Hasta 50 c.c.', value: '1' },
  { label: 'Más de 50 c.c. y menor o igual que 75 c.c.', value: '51' },
  { label: 'Más de 75 c.c. y menor o igual que 125 c.c.', value: '76' },
  { label: 'Más de 125 c.c. y menor o igual que 150 c.c.', value: '126' },
  { label: 'Más de 150 c.c. y menor o igual que 200 c.c.', value: '151' },
  { label: 'Más de 200 c.c. y menor o igual que 250 c.c.', value: '201' },
  { label: 'Más de 250 c.c. y menor o igual que 350 c.c.', value: '251' },
  { label: 'Más de 350 c.c. y menor o igual que 450 c.c.', value: '351' },
  { label: 'Más de 450 c.c. y menor o igual que 550 c.c.', value: '451' },
  { label: 'Más de 550 c.c. y menor o igual que 750 c.c.', value: '551' },
  { label: 'Más de 750 c.c. y menor o igual que 1000 c.c.', value: '751' },
  { label: 'Más de 1000 c.c. y menor o igual que 1200 c.c.', value: '1001' },
  { label: 'Más de 1200 c.c.', value: '1201' },
];

export const userTypeEnums = [
  { label: 'Comprador', value: 'buyer' },
  { label: 'Vendedor', value: 'seller' },
];

export const formStepEnums = [
  { label: '1 - Vehículo', value: 0 },
  { label: '2 - Precio', value: 1 },
  { label: '3 - Pago', value: 2 },
];

export const yesNoEnums = [
  { label: 'Sí', value: true },
  { label: 'No', value: false },
];

export const months = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Oct',
  'Nov',
  'Dic',
];

export const environmentalBadgeTypes = [
  { label: 'Etiqueta Ambiental B Amarilla (16TB)', value: '16TB' },
  { label: 'Etiqueta Ambiental B Amarilla (16MB)', value: '16MB' },
  { label: 'Etiqueta Ambiental C Verde (16TC)', value: '16TC' },
  { label: 'Etiqueta Ambiental C Verde (16MC)', value: '16MC' },
  { label: 'Etiqueta Ambiental 0 Azul (16T0)', value: '16T0' },
  { label: 'Etiqueta Ambiental 0 Azul (16M0)', value: '16M0' },
  { label: 'Etiqueta Ambiental ECO (16TE)', value: '16TE' },
  { label: 'Sin distintivo (TIV0)', value: 'TIV0' },
  { label: 'Sin distintivo', value: 'SIN DISTINTIVO' },
];

export const getBadgeLabelByBadgeType = (badgeType) =>
  environmentalBadgeTypes.find((badge) => badge.value === badgeType).label;

export const ASYNC_STATE = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const STRIPE_CARD_DECLINE_CODES = {
  generic_error: {
    title: 'Error en la tarjeta',
    description: 'Ha ocurrido un error con la tarjeta.',
    nextSteps: 'Por favor comunicarse con su entidad bancaria.',
  },
  insufficient_funds: {
    title: 'Fondos insuficientes',
    description:
      'La tarjeta tiene fondos insuficientes para completar la compra.',
    nextSteps:
      'Debe de utilizar un método de pago alternativo o añadir fondos.',
  },
  expired_card: {
    title: 'Tarjeta expirada',
    description: 'La tarjeta ha expirado.',
    nextSteps: 'El cliente debe de utilizar otra tarjeta.',
  },
  card_velocity_exceeded: {
    title: 'Crédito excedido',
    description: 'Se ha sobrepasado el límite de crédito de la tarjeta.',
    nextSteps:
      'El cliente debe de utilizar otra tarjeta o coordinar con su banco.',
  },
};

export const GENERIC_STATUS = {
  DOCS_AT_OFFICE: 'DOCS_AT_OFFICE',
};

export const SHIPMENT_STATUS = {
  CREATED: 'CREATED',
  READY_TO_SHIP: 'READY_TO_SHIP',
  SHIPPED: 'SHIPPED',
  IN_TRANSIT: 'IN_TRANSIT',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED',
  CANCELED: 'CANCELED',
  INCIDENT: 'INCIDENT',
  RETURNED: 'RETURNED',
  BUNDLED: 'BUNDLED',
  LOST: 'LOST',
};

export const FINAL_SHIPMENT_STATUS = [
  SHIPMENT_STATUS.CANCELED,
  SHIPMENT_STATUS.INCIDENT,
  SHIPMENT_STATUS.RETURNED,
  SHIPMENT_STATUS.BUNDLED,
  SHIPMENT_STATUS.LOST,
];

export const ERROR_SHIPMENT_STATUS = [
  SHIPMENT_STATUS.CANCELED,
  SHIPMENT_STATUS.INCIDENT,
  SHIPMENT_STATUS.RETURNED,
  SHIPMENT_STATUS.LOST,
];

export const isShipmentEditable = (shipmentStatus) =>
  !FINAL_SHIPMENT_STATUS.includes(shipmentStatus);

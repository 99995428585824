import { useState } from 'react';
import { BsX } from 'react-icons/bs';
import BaseModal from '../BaseModal';
import { classNames } from '../../helpers';
import { useServices } from '../../services';

const roleDictionary = {
  seller: {
    label: 'vendedor',
  },
  buyer: {
    label: 'comprador',
  },
  intermediary: {
    label: 'intermediario',
  },
};

const entityTypeDictionary = {
  user: 'Particular',
  company: 'Empresa',
};

export default function ChangeEntityTypeModal({
  changeEntityModalData,
  setChangeEntityModalData,
  forceUpdate,
  tramitType,
  tramitCode,
}) {
  const { showModal, role, sourceEntityType, sourceEntityId } =
    changeEntityModalData;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirm, setConfirm] = useState(false);

  const { switchEntityType } = useServices();

  const reset = () => {
    setIsLoading(false);
    setErrorMessage('');
    setConfirm(false);
    setChangeEntityModalData({
      showModal: false,
      role: null,
      sourceEntityType: null,
      sourceEntityId: null,
    });
  };

  const close = () => {
    reset();
  };

  const switchEntityTypeHandler = async () => {
    try {
      setIsLoading(true);
      await switchEntityType({
        sourceEntityId,
        sourceEntityType,
        tramitCode,
        tramitType,
        role,
      });
      forceUpdate();
      close();
    } catch (e) {
      setErrorMessage('Ha ocurrido un error cambiando el tipo de entidad');
    } finally {
      setIsLoading(false);
    }
  };

  const initialType = entityTypeDictionary[sourceEntityType];
  const finalType =
    entityTypeDictionary[sourceEntityType === 'user' ? 'company' : 'user'];

  return (
    <BaseModal isOpen={showModal} onClose={close} overflowVisible={false}>
      <div className="space-y-6 relative">
        <BsX
          className="cursor-pointer w-8 h-8 absolute top-0 right-4 text-gray-400"
          onClick={close}
        />
        <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                Cambiar{' '}
                <span className="font-bold text-blue-600">
                  {roleDictionary[role]?.label}
                </span>{' '}
                de{' '}
                <span className="font-bold text-blue-600">{initialType}</span> a{' '}
                <span className="font-bold text-blue-600">{finalType}</span>{' '}
              </h3>
              <form>
                {errorMessage && (
                  <p className="text-red-500 mb-2">{errorMessage}</p>
                )}
                <div className="max-h-[400px] overflow-y-auto">
                  <p>
                    Acción irreversible. Se cambiará el{' '}
                    {roleDictionary[role]?.label} de {initialType} a {finalType}{' '}
                    y se perderán los datos. ¿Estás seguro?
                  </p>
                  <div className="flex gap-x-2 items-center mt-1">
                    <input
                      type="checkbox"
                      onChange={() => setConfirm((prev) => !prev)}
                      value={confirm}
                      id="confirm"
                      className="outline-none focus:ring-0"
                    />
                    <label htmlFor="confirm" className="select-none">
                      Sí, estoy seguro
                    </label>
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="button"
                    onClick={switchEntityTypeHandler}
                    disabled={!confirm}
                    className={classNames(
                      'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white',
                      confirm
                        ? 'bg-red-600 hover:bg-red-700 focus:ring-0'
                        : 'bg-gray-400',
                    )}
                  >
                    {isLoading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {isLoading ? 'Cambiando...' : 'Cambiar'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

/* eslint-disable no-shadow */

export enum BATE_STATUS {
  PENDING_DOCUMENTATION = 'PENDING_DOCUMENTATION',
  REVIEW_PENDING_DOCUMENTATION = 'REVIEW_PENDING_DOCUMENTATION',
  PRESENTED_TO_AGENCY = 'PRESENTED_TO_AGENCY',
  PRESENTED_TO_DGT = 'PRESENTED_TO_DGT',
  FINISHED_BY_DGT = 'FINISHED_BY_DGT',
  CANCELED = 'CANCELED',
}

export const BATE_STATUS_LABEL_MAP: { [key: string]: string[] } = {
  NOT_SUBMITTED: [BATE_STATUS.PENDING_DOCUMENTATION],
  SUBMITTED_TO_AGENCY: [
    BATE_STATUS.REVIEW_PENDING_DOCUMENTATION,
    BATE_STATUS.PRESENTED_TO_AGENCY,
  ],
  SUBMITTED_TO_DGT: [BATE_STATUS.PRESENTED_TO_DGT],
  FINISHED_BY_DGT: [BATE_STATUS.FINISHED_BY_DGT],
};

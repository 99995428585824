import { BATE_STATUS_LABEL_MAP } from '../shared/bate-status';
import {
  REGISTRATION_STATUS,
  REGISTRATION_STATUS_PRO,
} from '../shared/registration-status';
import { TRANSFER_STATUS } from '../shared/transaction-status';

const notSubmittedLabel = {
  label: 'No presentado',
  colors: 'bg-gray-100 text-gray-800',
};
const submittedToAgencyLabel = {
  label: 'Presentado a gestoría',
  colors: 'bg-yellow-100 text-yellow-800',
};
const submittedToDGTLabel = {
  label: 'Presentado a DGT',
  colors: 'bg-blue-100 text-blue-800',
};
const finishedByDGTLabel = {
  label: 'Finalizado por DGT',
  colors: 'bg-green-100 text-green-800',
};
const emptyLabel = { label: '', colors: '' };

export const getTransferStatusLabel = (status: number) => {
  if (TRANSFER_STATUS.FINISHED_BY_DGT.includes(status)) {
    return finishedByDGTLabel;
  }
  if (TRANSFER_STATUS.SUBMITTED_TO_DGT.includes(status)) {
    return submittedToDGTLabel;
  }
  if (TRANSFER_STATUS.SUBMITTED_TO_AGENCY.includes(status)) {
    return submittedToAgencyLabel;
  }
  if (TRANSFER_STATUS.NOT_SUBMITTED.includes(status)) {
    return notSubmittedLabel;
  }

  return emptyLabel;
};

export const getBateStatusLabel = (status: string) => {
  if (BATE_STATUS_LABEL_MAP.FINISHED_BY_DGT.includes(status)) {
    return finishedByDGTLabel;
  }
  if (BATE_STATUS_LABEL_MAP.SUBMITTED_TO_DGT.includes(status)) {
    return submittedToDGTLabel;
  }
  if (BATE_STATUS_LABEL_MAP.SUBMITTED_TO_AGENCY.includes(status)) {
    return submittedToAgencyLabel;
  }
  if (BATE_STATUS_LABEL_MAP.NOT_SUBMITTED.includes(status)) {
    return notSubmittedLabel;
  }

  return emptyLabel;
};

export const getRegistrationStatusLabel = (status: string) => {
  if (
    REGISTRATION_STATUS_PRO.FINISHED_BY_DGT.includes(
      status as REGISTRATION_STATUS,
    )
  ) {
    return finishedByDGTLabel;
  }
  if (
    REGISTRATION_STATUS_PRO.SUBMITTED_TO_DGT.includes(
      status as REGISTRATION_STATUS,
    )
  ) {
    return submittedToDGTLabel;
  }
  if (
    REGISTRATION_STATUS_PRO.SUBMITTED_TO_AGENCY.includes(
      status as REGISTRATION_STATUS,
    )
  ) {
    return submittedToAgencyLabel;
  }
  if (
    REGISTRATION_STATUS_PRO.NOT_SUBMITTED.includes(
      status as REGISTRATION_STATUS,
    )
  ) {
    return notSubmittedLabel;
  }

  return emptyLabel;
};

import React, { useMemo, useContext } from 'react';
import {
  CounterPartType,
  createTransferContext,
  FormValueType,
  FormValueTypeIfNotIntermediary,
  FormValueTypeIfIntermediary,
} from '../../../../context/CreateTransferContext/CreateTransferContext';

type CounterPartOptionsType = {
  counterPartType: CounterPartType | '';
  title: string;
  description: string;
};

export default function CounterpartType(): JSX.Element {
  const { formValue, setFormValue } = useContext(createTransferContext);

  const handleAction = (counterPartType: CounterPartType | '') => {
    setFormValue(
      (prev: FormValueType) =>
        ({
          ...prev,
          counterPartType,
        } as FormValueTypeIfNotIntermediary),
    );
  };

  const handleIntermediaryAction = (
    counterPart: 'SELLER' | 'BUYER',
    counterPartType: CounterPartType | '',
  ) => {
    setFormValue((prev: FormValueType) => ({
      ...prev,
      [counterPart === 'BUYER' ? 'buyerType' : 'sellerType']: counterPartType,
    }));
  };

  const optionsIfNotIntermediary: CounterPartOptionsType[] = [
    {
      counterPartType: CounterPartType.PERSON,
      title: 'Particular',
      description: 'La contraparte es una persona física.',
    },
    {
      counterPartType: CounterPartType.COMPANY,
      title: 'Empresa',
      description: 'La contraparte es una empresa.',
    },
  ];

  const optionsIfIntermediary: {
    [key in 'BUYER' | 'SELLER']: CounterPartOptionsType[];
  } = {
    BUYER: [
      {
        counterPartType: CounterPartType.PERSON,
        title: 'Particular',
        description: 'El comprador es una persona física.',
      },
      {
        counterPartType: CounterPartType.COMPANY,
        title: 'Empresa',
        description: 'El comprador es una empresa.',
      },
    ],
    SELLER: [
      {
        counterPartType: CounterPartType.PERSON,
        title: 'Particular',
        description: 'El vendedor es una persona física.',
      },
      {
        counterPartType: CounterPartType.COMPANY,
        title: 'Empresa',
        description: 'El vendedor es una empresa.',
      },
    ],
  };

  const counterpartyTypeText = useMemo(
    () =>
      formValue.userType === 'BUYER' ||
      formValue.userType === 'BUYER_WITH_TEMP_LEAVE'
        ? 'compras'
        : 'vendes',
    [formValue.userType],
  );

  return (
    <div className="w-full">
      <p className="mb-4 font-semibold">
        {formValue.userType !== 'INTERMEDIARY'
          ? `¿A quién le ${counterpartyTypeText} el vehículo?`
          : '¿Quienes son las contrapartes del contrato?'}
      </p>
      <div className="flex gap-x-4 w-full" id="counterpart-type-container">
        {formValue.userType !== 'INTERMEDIARY' ? (
          optionsIfNotIntermediary.map((o) => (
            <button
              key={o.counterPartType}
              type="button"
              onClick={() => handleAction(o.counterPartType)}
              onKeyDown={() => handleAction(o.counterPartType)}
              className={`mt-2 border rounded-lg py-[17px] px-[25px] w-full
            ${
              formValue.counterPartType === o.counterPartType &&
              'border-2 border-blue-600'
            }`}
            >
              <p className="font-semibold text-sm text-left">{o.title}</p>
              <p className="text-gray-400 text-sm text-left">{o.description}</p>
            </button>
          ))
        ) : (
          <div className="flex gap-2 mt-6">
            {(Object.keys(optionsIfIntermediary) as ('SELLER' | 'BUYER')[]).map(
              (counterP) => (
                <div key={counterP}>
                  <p className="font-medium ml-2">
                    {counterP === 'SELLER' ? 'Vendedor' : 'Comprador'}
                  </p>
                  {optionsIfIntermediary[counterP].map((opt) => (
                    <button
                      key={counterP + opt.counterPartType}
                      type="button"
                      onClick={() =>
                        handleIntermediaryAction(counterP, opt.counterPartType)
                      }
                      onKeyDown={() =>
                        handleIntermediaryAction(counterP, opt.counterPartType)
                      }
                      className={`mt-2 border rounded-lg py-[17px] px-[25px] w-full
            ${
              (formValue as FormValueTypeIfIntermediary)[
                counterP === 'SELLER' ? 'sellerType' : 'buyerType'
              ] === opt.counterPartType && 'border-2 border-blue-600'
            }`}
                    >
                      <p className="font-semibold text-sm text-left">
                        {opt.title}
                      </p>
                      <p className="text-gray-400 text-sm text-left">
                        {opt.description}
                      </p>
                    </button>
                  ))}
                </div>
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );
}

import BaseModal from '../../../../components/BaseModal';
import DateFilter from './DateFilter';
import Select from '../../../../components/select';
import { statusEnums } from '../../../../helpers/enums';

const options = [
  { value: '', label: '-' },
  ...statusEnums.map(({ id, label }) => ({
    value: id,
    label,
  })),
];

export default function StatusChangeFilterModal({
  setShowModal,
  showModal,
  minDateStatusChange,
  setMinDateStatusChange,
  maxDateStatusChange,
  setMaxDateStatusChange,
  initialState,
  setInitialState,
  finalState,
  setFinalState,
}) {
  const closeModal = () => {
    setShowModal(false);
  };
  const isDisabled =
    !finalState || !maxDateStatusChange || !minDateStatusChange;
  return (
    <BaseModal isOpen={showModal} onClose={closeModal} overflowVisible={false}>
      <div className="space-y-6">
        <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-1 md:gap-6">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                Filtrar por cambios de estado
              </h3>
              {new Date(minDateStatusChange) >
                new Date(maxDateStatusChange) && (
                <p className="text-red-500 mb-2">
                  La fecha Desde no puede ser después que la fecha Hasta
                </p>
              )}
              <div className="flex flex-col">
                <DateFilter
                  minDate={minDateStatusChange}
                  setMinDate={setMinDateStatusChange}
                  maxDate={maxDateStatusChange}
                  setMaxDate={setMaxDateStatusChange}
                  spaceBottom
                />
                <Select
                  label="Estado inicial (opcional)"
                  stateName="initialState"
                  options={options}
                  value={initialState}
                  handleChange={(e) => setInitialState(e.target.value)}
                  spaceBottom
                />
                <Select
                  label="Estado final"
                  stateName="finalState"
                  options={options}
                  value={finalState}
                  handleChange={(e) => setFinalState(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <button
              type="button"
              className="w-full inline-flex justify-center cursor-pointer rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 sm:w-auto sm:text-sm"
              onClick={closeModal}
              disabled={isDisabled}
            >
              Aplicar filtro
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

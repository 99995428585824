import axios from 'axios';
import { Combobox } from '@headlessui/react';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';

import { BateOption } from './LinkBate';

export default function SearchBox({
  selected,
  accountId,
  setSelected,
  handleSelectedBate,
}: Readonly<{
  selected: BateOption;
  accountId: string;
  setSelected: (bate: BateOption) => void;
  handleSelectedBate: (bateId: string) => void;
}>): JSX.Element {
  const [search, setSearch] = useState('');
  const [bateOptions, setBateOptions] = useState<BateOption[] | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  const fetchBateOptions = async (query: string): Promise<void> => {
    const token = await getAccessTokenSilently();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/v1/search-initiated-batecom/${accountId}?text=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setBateOptions(data.batecoms);
    } catch (e) {
      // do nothing
    }
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleSelectBate = (bateOption: BateOption) => {
    setSelected(bateOption);
    handleSelectedBate(bateOption.id);
    setBateOptions(null);
    setSearch('');
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search.length > 0) {
        fetchBateOptions(search);
      }
    }, 300);
    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <div className="flex justify-center w-full">
      <Combobox
        as="div"
        value={selected}
        onChange={setSelected}
        className="w-1/2 relative mt-8 max-h-60"
      >
        <div className="w-full relative mt-2">
          <div className="w-full relative mt-4 rounded-md shadow-sm">
            <Combobox.Input
              autoComplete="off"
              className="w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              placeholder="Busca por matrícula, marca, modelo o codigo de ref."
              type="text"
              name="query"
              id="query"
              value={search}
              onChange={handleSearch}
            />
          </div>
          {bateOptions?.length > 0 && (
            <Combobox.Options className="absolute mt-1 max-h-60 z-50 w-full overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <div>
                <ul className="divide-y divide-gray-100">
                  {bateOptions
                    .filter((_, i) => i < 10)
                    .map(({ id, brand, model, bateCode, plate }) => (
                      <button
                        type="button"
                        key={id}
                        className="p-2 hover:bg-gray-100 cursor-pointer text-sm text-gray-800 flex gap-x-4 py-3 w-full justify-center"
                        onClick={() =>
                          handleSelectBate({
                            id,
                            brand,
                            model,
                            bateCode,
                            plate,
                          })
                        }
                      >
                        <div className="min-w-0">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            {`${bateCode} - ${brand ?? '---'} ${
                              model ?? '---'
                            } (${plate ?? '---'})`}
                          </p>
                        </div>
                      </button>
                    ))}
                </ul>
              </div>
            </Combobox.Options>
          )}
        </div>
        {bateOptions?.length === 0 && (
          <p className="text-red-500 text-xs mt-1 -mb-5">
            No se han encontrado resultados
          </p>
        )}
        {selected && (
          <div className="mt-5 sm:mt-6">
            <p className="text-sm text-gray-500">Seleccionado:</p>
            <p className="text-sm text-gray-800">
              {`${selected.bateCode} - ${selected.brand ?? '---'} ${
                selected.model ?? '---'
              } (${selected.plate ?? '---'})`}
            </p>
          </div>
        )}
      </Combobox>
    </div>
  );
}

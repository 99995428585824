import { parseDayMonthYearWithSlash } from '../../../helpers';
import { roleDictionary } from './dictionaries';

export const getTooltipMessage = (document, contractSignatureDate) => {
  if (document.status === 'PENDING') {
    let message = 'Pendiente de firmar por:';
    document.signers.forEach((signer) => {
      if (signer.signed) return;
      message += ` ${roleDictionary.get(signer.role)},`;
    });
    if (message === 'Pendiente de firmar por:') return '';
    message = message.slice(0, -1);
    return message;
  }

  if (document.status === 'COMPLETED' && contractSignatureDate) {
    const documentDate = parseDayMonthYearWithSlash(
      new Date(contractSignatureDate),
    );

    return `Proceso de firma finalizado el ${documentDate}`;
  }

  return '';
};

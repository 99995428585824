import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosResponse } from 'axios';
import { DispatchWithoutAction, useEffect, useReducer, useState } from 'react';
import { Shipment } from '../types/types';
import { Registration, Transaction } from '../../../types/types';

export type ShipmentsData = {
  shipments: Shipment[];
  getShipmentError: string;
  isShipmentLoading: boolean;
  forceUpdate: DispatchWithoutAction;
  entity: Transaction | Registration;
};

export default function useShipment(
  entityId: string,
  entity: Transaction | Registration,
): ShipmentsData {
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isShipmentLoading, setIsShipmentLoading] = useState<boolean>(true);
  const [getShipmentError, setGetShipmentError] = useState<string>('');

  const { getAccessTokenSilently } = useAuth0();

  const getAllShipmentsByEntityId = async (
    id: string,
  ): Promise<AxiosResponse<Shipment[]>> =>
    axios.get(
      `${process.env.REACT_APP_ZANELLA_URL}/shipments/all/entity/${id}`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );

  useEffect(() => {
    const fetchShipments = async () => {
      try {
        setGetShipmentError('');
        setIsShipmentLoading(true);

        const { data: response } = await getAllShipmentsByEntityId(entityId);

        setShipments(
          response.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          ),
        );
      } catch (e) {
        setGetShipmentError(
          'Ha ocurrido un error al cargar la información del envio.',
        );
      } finally {
        setIsShipmentLoading(false);
      }
    };
    if (entityId) {
      fetchShipments();
    }
  }, [entityId, updater]);

  return {
    shipments,
    getShipmentError,
    isShipmentLoading,
    forceUpdate,
    entity,
  };
}

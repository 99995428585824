import { useEffect, useState } from 'react';
import useShipment from './useShipment';
import {
  ERROR_SHIPMENT_STATUS,
  REGISTRATION_STATUS,
} from '../../../../helpers/enums';

const possibleRegistrationItems = [
  'CIRCULATION_PERMIT',
  'TECHNICAL_SHEET',
  'ENVIRONMENTAL_BADGE',
];

export default function useRegistrationShipments({
  registration,
  car,
  updater,
}) {
  const [availableItems, setAvailableItems] = useState([]);
  const [uncreatedItems, setUncreatedItems] = useState([]);
  const [editedShipment, setEditedShipment] = useState('');

  const [items, setItems] = useState([]);

  const { shipments, isShipmentLoading, getShipmentError } = useShipment({
    registration,
    environmentalBadgeId: null,
    updater,
  });

  const areRolesComplete = true;

  useEffect(() => {
    if (!shipments?.length) return;

    possibleRegistrationItems.forEach((possibleItem) => {
      shipments
        .filter((shipment) => ERROR_SHIPMENT_STATUS.includes(shipment.status))
        .forEach((shipment) => {
          const item = shipment.items.find(
            (i) =>
              i.type === possibleItem &&
              !items.some((it) => it.type === possibleItem),
          );
          if (item) {
            setItems((prev) => [...prev, { ...item, isChecked: false }]);
          }
        });
    });
  }, [shipments]);

  useEffect(() => {
    items.forEach((item) => {
      const isItemAvailable = shipments.every(
        (s) =>
          s.items.every((i) => i.type !== item.type) ||
          (s.items.some((i) => i.type === item.type) &&
            ERROR_SHIPMENT_STATUS.includes(s.status)),
      );

      setAvailableItems((previousAvailableItems) => {
        if (isItemAvailable) {
          return !previousAvailableItems.some((i) => i.type === item.type)
            ? [
                ...previousAvailableItems,
                {
                  id: item.id,
                  type: item.type,
                  entityId: registration.id,
                  entityType: 'registration',
                  plate: car?.plate,
                  isChecked: false,
                },
              ]
            : previousAvailableItems;
        }
        return previousAvailableItems.some((i) => i.type === item.type)
          ? previousAvailableItems.filter((i) => i.type !== item.type)
          : previousAvailableItems;
      });
    });
  }, [items, shipments]);

  return {
    editedShipment,
    registration,
    availableItems,
    setAvailableItems,
    isShipmentLoading,
    getShipmentError,
    shipments,
    items,
    uncreatedItems,
    setUncreatedItems,
    setEditedShipment,
    areRolesComplete,
    accountId: registration?.accountId,
    possibleReceivers: {},
    isDocumentOnOffice:
      registration.status === REGISTRATION_STATUS.DOCS_AT_OFFICE,
    isPro: true,
  };
}

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useServices } from '../services';
import { ENTITY_TYPES } from '../helpers/enums';

const tramitPathDictionary = {
  [ENTITY_TYPES.BATE]: 'bate',
  [ENTITY_TYPES.TRANSACTION]: 'transaction',
  [ENTITY_TYPES.REGISTRATION]: 'registration',
  [ENTITY_TYPES.ACCOUNT]: 'account',
};

export default function useExtraFiles({ tramitType, entity }) {
  const [extraFiles, setExtraFiles] = useState([]);
  const [extraFilesLoading, setExtraFilesLoading] = useState(false);
  const [modalError, setModalError] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { getS3SignedUrl } = useServices();

  const handleUploadExtraFiles = async (files) => {
    const filesToUpload = Array.from(files);
    const namesOfFilesLong = filesToUpload.filter(
      (file) => file.name.length > 100,
    );

    if (namesOfFilesLong.length > 0) {
      setModalError(true);
    }

    setExtraFilesLoading(true);
    const formData = new FormData();

    const filteredFiles = Array.from(files).filter(
      (e) =>
        /\.(pdf|jpg|png|jpeg|tif|tiff|webp|heic)/gi.test(e.name) &&
        e.name.length < 100 &&
        e.size < 15000000,
    );

    if (filteredFiles.length !== files.length) {
      setModalError(true);
      if (filteredFiles.length === 0) {
        setExtraFilesLoading(false);
        return;
      }
    }

    filteredFiles.forEach((file) => {
      formData.append('files', file);
    });

    const addExtraFile = async () => {
      const token = await getAccessTokenSilently();
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/add-extra-files/${tramitPathDictionary[tramitType]}/${entity.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setExtraFiles(data.newExtraFiles);
      } finally {
        setExtraFilesLoading(false);
      }
    };

    addExtraFile();
  };

  const handleDeleteExtraFile = async (objectKey) => {
    setExtraFilesLoading(true);
    const token = await getAccessTokenSilently();
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_API_URL}/remove-extra-file/${tramitPathDictionary[tramitType]}/${entity.id}/${objectKey}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    setExtraFiles(data.newExtraFiles);
    setExtraFilesLoading(false);
  };

  const handleViewExtraFile = async (objectKey) => {
    const { signedUrl } = await getS3SignedUrl(
      'swipoo-transactions-files',
      objectKey,
    );
    window.open(signedUrl, '_blank');
  };

  const resetModalError = () => {
    setModalError(false);
  };

  useEffect(() => {
    setExtraFiles(entity?.extraFiles);
  }, [entity]);

  return {
    extraFilesLoading,
    extraFiles,
    handleViewExtraFile,
    handleDeleteExtraFile,
    handleUploadExtraFiles,
    modalError,
    resetModalError,
  };
}

import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, SyntheticEvent, useState } from 'react';

import Spinner from '../../../components/Spinner';
import { toasterContext } from '../../../context/ToasterContext/ToasterContext';

type DragAndDropFileProps = {
  id: string;
  entityType: 'transaction' | 'bate' | 'registration';
  forceUpdate: () => void;
};

function DragAndDropFile({
  id,
  entityType,
  forceUpdate,
}: Readonly<DragAndDropFileProps>): JSX.Element {
  const [dragActive, setDragActive] = useState(false);
  const [areExtraFilesLoading, setAreExtraFilesLoading] = useState(false);

  const { setToasterData } = useContext(toasterContext);
  const { getAccessTokenSilently } = useAuth0();

  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleUploadExtraFiles = async (files: FileList) => {
    setAreExtraFilesLoading(true);
    const formData = new FormData();

    const filteredFiles = Array.from(files).filter(
      (e) =>
        /\.(pdf|jpg|png|jpeg|tiff|tif|heic|webp)/gi.test(e.name) &&
        e.name.length < 100 &&
        e.size < 15000000,
    );

    if (filteredFiles.length !== files.length) {
      setToasterData({
        type: 'ERROR',
        title: 'Error al subir algún documento',
        message:
          'Revisa tus docuemntos, asegurate que tengan un tamaño máximo de 15MB y que el nombre no supere los 100 caracteres.',
      });
      if (filteredFiles.length === 0) {
        setAreExtraFilesLoading(false);
        return;
      }
    }

    filteredFiles.forEach((file) => {
      formData.append('files', file);
    });

    const addExtraFile = async () => {
      try {
        const token = await getAccessTokenSilently();
        await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/add-extra-files/${entityType}/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          },
        );
      } catch {
        setToasterData({
          type: 'ERROR',
          title: 'Error al subir documento.',
          message:
            'Ha ocurrido algún problema procesando la documentación. Intentalo más tarde.',
        });
      } finally {
        forceUpdate();
        setAreExtraFilesLoading(false);
      }
    };

    addExtraFile();
  };

  // triggers when file is dropped
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      // at least one file has been dropped so do something
      handleUploadExtraFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    if (target.files) {
      // at least one file has been dropped so do something
      handleUploadExtraFiles(target.files);
    }
  };

  return (
    <div
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
      className="w-full text-center relative h-full bg-white mt-4 mb-6"
      data-testid="drag-container"
    >
      <input
        className="hidden"
        type="file"
        id="input-file-upload"
        multiple
        data-testid="file-input"
        onChange={handleChange}
        accept="image/*, application/pdf"
      />
      <label
        id="label-file-upload"
        className="h-full flex items-center justify-center border-2 border-r-2 border-dashed rounded-sm p-10"
      >
        {areExtraFilesLoading ? (
          <Spinner color="text-blue-700" size={8} marginTop={0} />
        ) : (
          <>
            {dragActive && (
              <div className="">
                <p className="text-sm">Suelta tu archivo para subirlo</p>
              </div>
            )}
            {!dragActive && (
              <div>
                <div className="flex flex-col items-center justify-center">
                  <img src="./upload.svg" alt="" />
                  <span className="text-sm">
                    <label
                      htmlFor="input-file-upload"
                      className="text-blue-600 cursor-pointer"
                    >
                      Adjunta un archivo
                    </label>{' '}
                    o suéltalo aquí
                  </span>
                </div>
                <p className="text-gray-500 text-sm">
                  PDF, JPG, PNG, TIF, HEIC hasta 15MB
                </p>
              </div>
            )}
          </>
        )}
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          data-testid="drag-file-element"
          className="absolute w-full h-full top-0 right-0 bottom-0 left-0"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  );
}
export default DragAndDropFile;

import React, { useState } from 'react';
import { MdError } from 'react-icons/md';
import Spinner from '../../../../../../../components/Spinner';
import { useServices } from '../../../../../../../services';
import { generateValidationError } from '../../helpers/helpers';
import { ccaaDictionary } from '../../../../../../../helpers/enums';
import { classNames } from '../../../../../../../helpers';

export default function SelectAddress({
  selectedShimpents,
  selectedDeliveryAddress,
  error,
  account,
  deliveryAddresses,
  setSelectedDeliveryAddress,
  setSuccessMessage,
  setError,
  resetState,
  validateAddress,
}) {
  const [loadingBatchRequest, setLoadingBatchRequest] = useState(false);

  const { generateBatchShipment } = useServices();

  const handleSelectAddress = (e) => setSelectedDeliveryAddress(e.target.value);

  const handleBatchShipments = async () => {
    try {
      setLoadingBatchRequest(true);

      const deliveryAddress = validateAddress();

      await generateBatchShipment({
        shipmentIds: selectedShimpents,
        accountId: account.id,
        deliveryAddress,
      });

      setSuccessMessage(
        `Se agruparon ${selectedShimpents.length} órdenes de envío de ${account.name} y se enviaron de manera exitosa`,
      );
      resetState();
    } catch (e) {
      const issues = e?.response?.data?.issues;

      if (!issues) {
        setError('Ha ocurrido un error al intentar generar el pedido agrupado');
      }
      if (issues) {
        setError(generateValidationError(issues));
      }
    } finally {
      setLoadingBatchRequest(false);
    }
  };

  const getDeliveryAddressParts = (deliveryAddressId) => {
    const deliveryAddress = deliveryAddresses.data.find(
      (address) => address.id === deliveryAddressId,
    );

    if (deliveryAddress) {
      return [
        {
          label: 'Dirección',
          value: `${deliveryAddress.deliveryAddress}, ${
            deliveryAddress.deliveryCity
          }, ${ccaaDictionary[deliveryAddress.deliveryCCAA]}, ${
            deliveryAddress.deliveryZipcode
          }`,
        },
        {
          label: 'Recibidor',
          value: `${deliveryAddress.deliveryNameSurname}, ${deliveryAddress.deliveryDOI}`,
        },
        {
          label: 'Email',
          value: deliveryAddress.deliveryEmail,
        },
        {
          label: 'Teléfono',
          value: deliveryAddress.deliveryPhone,
        },
      ];
    }

    return [
      {
        label: 'Dirección',
        value: `${account.billingAddress}, ${account.billingCity}, ${
          ccaaDictionary[account.billingCcaa]
        }, ${account.billingZipCode}`,
      },
      {
        label: 'Recibidor',
        value: `${account.representative?.name} ${account.representative?.surname}, ${account.representative?.dni}`,
      },
      {
        label: 'Email',
        value: account.representative?.email,
      },
      {
        label: 'Teléfono',
        value: account.representative?.phone,
      },
    ];
  };

  return (
    <div className="flex flex-col gap-6 border-2 px-8 py-4 pb-4 rounded-lg w-full h-[fit-content]">
      <div className="flex justify-between items-center">
        <h2 className="text-xl">Agrupador de Envíos</h2>

        <button
          type="button"
          className={classNames(
            'px-2 py-1 rounded-md',
            selectedDeliveryAddress || !error
              ? 'bg-indigo-600 hover:bg-indigo-700 text-white'
              : 'bg-gray-300 text-gray-500',
          )}
          disabled={!selectedDeliveryAddress && error}
          onClick={handleBatchShipments}
        >
          Confirmar
        </button>
      </div>

      <div className="flex flex-col gap-2">
        {account.billingNif && (
          <div className="flex items-center justify-between">
            <div className="flex gap-x-2 items-center">
              <span className="font-bold">{account.name}</span>
              <span className="text-sm font-light">{account.billingNif}</span>
            </div>
            <span>{selectedShimpents.length} seleccionados</span>
          </div>
        )}
        {Boolean(deliveryAddresses.data.length) && (
          <div className="flex flex-col gap-y-2">
            <label htmlFor="custom-address">Selecciona una dirección</label>
            <select
              onChange={handleSelectAddress}
              value={selectedDeliveryAddress}
              id="custom-address"
              name="custom-address"
              defaultValue=""
              className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
            >
              <option value="" disabled>
                Selecciona una dirección de entrega
              </option>
              {deliveryAddresses.data.map((address) => (
                <option key={address.id} value={address.id}>
                  {address.referenceName}
                </option>
              ))}
            </select>
            {selectedDeliveryAddress && (
              <div className="flex flex-col mt-2">
                {getDeliveryAddressParts(selectedDeliveryAddress).map(
                  (part) => (
                    <div key={part.label} className="flex gap-x-2 items-center">
                      <span className="font-bold text-xs">{part.label}:</span>
                      <span>{part.value}</span>
                    </div>
                  ),
                )}
              </div>
            )}
          </div>
        )}
        {!deliveryAddresses.loading && !deliveryAddresses.data.length && (
          <div className="flex flex-col gap-y-2">
            <span className="text-sm">
              No se encontraron direcciones personalizadas
            </span>
            <span className="text-sm">
              Se utilizará la dirección de la empresa.
            </span>
            <div className="flex flex-col text-sm">
              {getDeliveryAddressParts(selectedDeliveryAddress).map((part) => (
                <div key={part.label} className="flex gap-x-2 items-center">
                  <span className="font-bold text-xs">{part.label}:</span>
                  <span>{part.value}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        {(deliveryAddresses.loading || loadingBatchRequest) && (
          <div className="flex justify-center items-center w-full h-full">
            <Spinner color="text-indigo-600" size="10" marginTop="0" />
          </div>
        )}

        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <MdError className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <div className="mt-2 text-sm text-red-700">
                  <p className="whitespace-pre-line">{error}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useContext, useState } from 'react';
import TransfersFilters from './components/TransfersFilters/TransfersFilters';
import Pagination from '../components/Pagination';
import TransfersTable from './components/TransfersTable/TransfersTable';
import TransferTableSkeletonLoader from './components/TransferTableSkeletonLoader/TransferTableSkeletonLoader';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';
import NewTransferModal from '../modals/NewTransferModal/NewTransferModal';
import OngoingTransfersEmptyState from './components/EmptyStates/OngoingTransfersEmptyState';
import Button from '../components/Button';
import TransfersWithIncidentsEmptyState from './components/EmptyStates/TransfersWithIncidentsEmptyState';
import { TransferStatusCategories } from './enums/TransferStatusCategories';
import FinishedTransfersEmptyState from './components/EmptyStates/FinishedTransfersEmptyState';
import CanceledTransfersEmptyState from './components/EmptyStates/CanceledTransfersEmptyState';
import TransfersEmptyState from './components/EmptyStates/TransfersEmptyState';
import { LIMIT, useTransfers } from './hooks/useTransfers';

export interface TransferForTable {
  plate?: string;
  tramitCode: string;
  type:
    | 'seller'
    | 'buyer'
    | 'intermediary'
    | 'batecom_sale'
    | 'batecom_purchase';
  status: number;
  createdAt: string;
  hasIncident: boolean;
}

export default function TransfersList() {
  const {
    hasLoadedTransfers,
    transfers,
    page,
    setPage,
    totalCount,
    totalPages,
    hasFoundAnyTransfer,
  } = useTransfers();
  const { getSingleParam } = useContext(queryParamsContext);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);

  const displayTransfersPerTab = () => {
    if (transfers.length > 0) {
      return (
        <>
          <TransfersTable transfers={transfers} />
          <Pagination
            limit={LIMIT}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            totalCount={totalCount}
          />
        </>
      );
    }

    if (!hasFoundAnyTransfer) {
      return <TransfersEmptyState showNewTransferModal={showModal} />;
    }

    switch (getSingleParam('status_category')) {
      case TransferStatusCategories.WITH_INCIDENTS:
        return <TransfersWithIncidentsEmptyState />;
      case TransferStatusCategories.ONGOING:
        return <OngoingTransfersEmptyState showNewTransferModal={showModal} />;
      case TransferStatusCategories.FINISHED:
        return <FinishedTransfersEmptyState showNewTransferModal={showModal} />;
      case TransferStatusCategories.CANCELED:
        return <CanceledTransfersEmptyState showNewTransferModal={showModal} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-5 p-8 w-full h-full min-h-fit bg-gray-100">
      <header className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold">Compraventas</h1>
        {hasFoundAnyTransfer && (
          <Button
            text="Nueva compraventa"
            bgColor="bg-blue-600"
            hoverBgColor="bg-blue-700"
            textColor="white"
            callback={showModal}
            disabled={isModalOpen}
          />
        )}
        <NewTransferModal isShown={isModalOpen} hide={hideModal} />
      </header>
      {hasFoundAnyTransfer && <TransfersFilters />}
      {hasLoadedTransfers ? (
        displayTransfersPerTab()
      ) : (
        <TransferTableSkeletonLoader />
      )}
    </div>
  );
}

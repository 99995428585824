import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { CheckIcon } from '@heroicons/react/outline';

import Button from '../../../components/Button';
import useCompanyFieldsForm from './useCompanyFieldsForm';
import { Company } from '../../../types/types';
import { ccaaDictionary } from '../../../enums/ccaaDictionary';

const companyLabelDictionary: { [key: string]: string } = {
  legalName: 'Razón social',
  nif: 'CIF',
  fiscalAddressZipCode: 'Código postal',
  fiscalAddressState: 'Comunidad autónoma',
  fiscalAddressProvince: 'Provincia',
  fiscalAddressCity: 'Ciudad',
  fiscalAddressAddress: 'Dirección',
};

const representativeLabelDictionary: { [key: string]: string } = {
  name: 'Nombre',
  surname: 'Apellidos',
  dni: 'DNI',
  address: 'Dirección',
  phone: 'Teléfono',
  email: 'Email',
  city: 'Ciudad',
  zipCode: 'Código postal',
  province: 'Provincia',
};

const errorDictionary: {
  [key: string]: string;
} = {
  email: 'El email no es válido',
  phone: 'El teléfono no es válido',
  dni: 'El DNI no es válido',
  nif: 'El CIF no es válido',
};

function CompanyFields({
  setIsOpen,
  preloadedValue,
}: {
  preloadedValue: Company;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const {
    errors,
    isLoading,
    handleSubmit,
    editableCompanyInfo,
    handleEditCompanyInfo,
    handleEditRepresentativeInfo,
  } = useCompanyFieldsForm(preloadedValue);

  const onCompanyFormSubmit = async () => {
    await handleSubmit(setIsOpen);
  };

  return (
    <>
      <div className="relative mt-4">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">
            Datos de la empresa
          </span>
        </div>
      </div>
      <div className="py-5">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
          {Object.keys(editableCompanyInfo)
            .filter((e) => e !== 'representative')
            .map((e: keyof Company) => (
              <div className="sm:col-span-1" key={e}>
                <div>
                  <label
                    htmlFor={e}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {companyLabelDictionary[e]}
                  </label>
                  <div className="mt-1">
                    {e === 'fiscalAddressState' && (
                      <select
                        value={editableCompanyInfo.fiscalAddressState}
                        onChange={handleEditCompanyInfo}
                        defaultValue=""
                        name="fiscalAddressState"
                        id="fiscalAddressState"
                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      >
                        <option hidden>Seleccionar:</option>
                        {Object.keys(ccaaDictionary).map((ca) => (
                          <option key={ca} value={ca}>
                            {ccaaDictionary[ca]}
                          </option>
                        ))}
                      </select>
                    )}

                    {e !== 'fiscalAddressState' && (
                      <input
                        value={editableCompanyInfo[e] as string}
                        onChange={handleEditCompanyInfo}
                        type="text"
                        name={e}
                        id={e}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    )}
                  </div>
                  {errors.includes(e) && (
                    <p className="mt-2 -mb-6 text-xs ml-1 text-red-600">
                      {errorDictionary[e]}
                    </p>
                  )}
                </div>
              </div>
            ))}
        </dl>
      </div>
      <div className="relative mt-4">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">
            Datos del representante
          </span>
        </div>
      </div>
      <div className="py-5">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
          {Object.keys(editableCompanyInfo.representative).map(
            (e: keyof Company['representative']) => (
              <div className="sm:col-span-1" key={e}>
                <div>
                  <label
                    htmlFor={e}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {representativeLabelDictionary[e]}
                  </label>
                  <div className="mt-1">
                    <input
                      value={editableCompanyInfo.representative[e]}
                      onChange={handleEditRepresentativeInfo}
                      type="text"
                      name={e}
                      id={e}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                  {errors.includes(e) && (
                    <p className="mt-2 -mb-6 text-xs ml-1 text-red-600">
                      {errorDictionary[e]}
                    </p>
                  )}
                </div>
              </div>
            ),
          )}
        </dl>
      </div>
      <div className="flex w-full justify-end mt-3">
        {isLoading ? (
          <Button
            bgColor="bg-gray-200"
            hoverBgColor="bg-gray-200"
            textColor="white"
            text="Cargando..."
            RightIcon={FaSpinner}
            disabled
          />
        ) : (
          <Button
            bgColor="bg-blue-700"
            hoverBgColor="bg-blue-900"
            textColor="white"
            text="Guardar"
            RightIcon={CheckIcon}
            callback={onCompanyFormSubmit}
            disabled={errors.length > 0}
          />
        )}
      </div>
    </>
  );
}

export default CompanyFields;

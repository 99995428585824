import { ENTITY_TYPES } from '../../../helpers/enums';

export const getItemLink = (itemType, entityId, entityType) => {
  if (itemType === 'ENVIRONMENTAL_BADGE') {
    return `/environmental-badges/${entityId}`;
  }

  if (['CIRCULATION_PERMIT', 'TECHNICAL_SHEET'].includes(itemType)) {
    if (entityType?.toUpperCase() === ENTITY_TYPES.TRANSACTION) {
      return `/transfers/${entityId}`;
    }
    if (entityType?.toUpperCase() === ENTITY_TYPES.REGISTRATION) {
      return `/registration/${entityId}`;
    }
  }
  return '';
};

/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { ASYNC_STATE } from '../types/types';
import usePagination from '../hooks/usePagination';
import { Column } from '../components/TypeformComponents/Table';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import Pagination from '../components/Pagination';
import VehicleTable from './components/VehicleTable/VehicleTable';
import VehicleTableSkeletonLoader from './components/VehicleTableSkeletonLoader/VehicleTableSkeletonLoader';

export interface VehicleForTable {
  id: string;
  name: string;
  plate: string;
  type: 'Coche' | 'Moto';
}

export interface VehicleResponse {
  cars: {
    id: string;
    name: string;
    plate: string;
    isMoto: boolean;
  }[];
  count: number;
}

export const LIMIT = 10;

export default function VehiclesList() {
  const [loadState, setLoadState] = useState<ASYNC_STATE>(
    ASYNC_STATE.NOT_STARTED,
  );
  const [vehicles, setVehicles] = useState<VehicleForTable[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { getAccessTokenSilently } = useAuth0();

  const { page, setPage } = usePagination();

  const columns: Column[] = [
    {
      label: 'Vehículo',
      key: 'name',
    },
    {
      label: 'Matrícula',
      key: 'plate',
    },
    {
      label: 'Tipo',
      key: 'type',
    },
  ];

  const { accountInfo } = useContext(userInfoContext);

  useEffect(() => {
    (async () => {
      if (!accountInfo.id) return;
      setLoadState(ASYNC_STATE.LOADING);

      const token = await getAccessTokenSilently();

      const { data: fetchedVehicles } = await axios.get<VehicleResponse>(
        `${process.env.REACT_APP_BASE_API_URL}/cars/account/${accountInfo.id}?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const mappedVehicles: VehicleForTable[] = fetchedVehicles.cars.map(
        (vehicle) => ({
          id: vehicle.id,
          name: vehicle.name,
          plate: vehicle.plate,
          type: vehicle.isMoto ? 'Moto' : 'Coche',
        }),
      );
      setVehicles(mappedVehicles);
      setTotalCount(fetchedVehicles.count);
      setTotalPages(Math.ceil(fetchedVehicles.count / LIMIT));
      setLoadState(ASYNC_STATE.LOADED);
    })();
  }, [accountInfo?.id, page]);

  return (
    <div className="flex flex-col gap-5 p-8 w-full h-full min-h-fit bg-gray-100">
      <h1 className="text-3xl font-semibold">Vehículos</h1>
      {loadState === ASYNC_STATE.LOADING && <VehicleTableSkeletonLoader />}
      {loadState === ASYNC_STATE.LOADED && (
        <VehicleTable vehicles={vehicles} columns={columns} />
      )}
      <Pagination
        limit={LIMIT}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </div>
  );
}

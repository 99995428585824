import { useState, useEffect } from 'react';

import { useServices } from '../../../../../services';

export default function TramitStats({ accountId }) {
  const [openIncidents, setOpenIncidents] = useState(0);

  const { getAccountTramitStats } = useServices();

  useEffect(() => {
    const fetchOpenIncidents = async () => {
      const response = await getAccountTramitStats(accountId);
      setOpenIncidents(response.openIncidents);
    };
    fetchOpenIncidents();
  }, [accountId]);

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="mx-auto max-w-7xl">
        <h3 className="px-4 py-4 text-base font-semibold leading-7 text-gray-900">
          Pendiente
        </h3>
        <div className="grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-4 border-t border-gray-100">
          <div className="px-4 py-6 sm:px-6 lg:px-8">
            <p className="text-sm flex justify-center font-medium leading-6 text-gray-900">
              Incidencias
            </p>
            <p className="mt-2 flex items-baseline gap-x-2 justify-center">
              <span className="text-4xl font-semibold tracking-tight text-black">
                {openIncidents}
              </span>
            </p>
          </div>
          <div className="px-4 py-6 sm:px-6 lg:px-8">
            <p className="text-sm flex justify-center font-medium leading-6 text-gray-900">
              Transferencias
            </p>
            <p className="mt-2 flex items-baseline gap-x-2 justify-center">
              <span className="text-4xl font-semibold tracking-tight text-black">
                --
              </span>
            </p>
          </div>
          <div className="px-4 py-6 sm:px-6 lg:px-8">
            <p className="text-sm flex justify-center font-medium leading-6 text-gray-900">
              Batecoms
            </p>
            <p className="mt-2 flex items-baseline gap-x-2 justify-center">
              <span className="text-4xl font-semibold tracking-tight text-black">
                --
              </span>
            </p>
          </div>
          <div className="px-4 py-6 sm:px-6 lg:px-8">
            <p className="text-sm flex justify-center font-medium leading-6 text-gray-900">
              Matriculaciones
            </p>
            <p className="mt-2 flex items-baseline gap-x-2 justify-center">
              <span className="text-4xl font-semibold tracking-tight text-black">
                --
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

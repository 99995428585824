import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useEffect, useReducer, useState } from 'react';
import LabelModal from '../../../../components/LabelModal';
import { useServices } from '../../../../services';

const entityTypeDictionary = {
  TRANSACTION: 'Transferencia',
  ACCOUNT: 'Cuenta',
  BATE: 'Bate',
  REGISTRATION: 'Matriculación',
};

function LabelEditor() {
  const [labels, setLabels] = useState([]);

  const [preloadedLabel, setPreloadedLabel] = useState(null);

  const { fetchAllLabels, deleteLabel } = useServices();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const [error, setError] = useState(null);

  const entityTypes = Array.from(
    new Set(labels.map((label) => label.entityType)),
  );

  useEffect(() => {
    (async () => {
      const fetchedLabels = await fetchAllLabels();
      setLabels(fetchedLabels);
    })();
  }, [updater]);

  const handleDelete = async (label) => {
    try {
      await deleteLabel(label.id);
      setError(null);
      forceUpdate();
    } catch (e) {
      setError(
        'No se pudo eliminar la etiqueta. Comprueba que no esté en uso.',
      );
    }
  };

  return (
    <>
      <LabelModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        save={() => {
          setPreloadedLabel(null);
          forceUpdate();
        }}
        preloadedLabel={preloadedLabel}
      />
      <div className="flex flex-col gap-6 border-2 px-8 py-4 pb-4 rounded-lg w-full h-[fit-content]">
        <div className="flex justify-between items-center">
          <h2 className="text-xl">Editor de etiquetas</h2>
          <button
            type="button"
            className="bg-indigo-600 hover:bg-indigo-700 px-2 py-1 rounded-md text-white"
            onClick={() => {
              setPreloadedLabel(null);
              setIsModalOpen(true);
            }}
          >
            Crear
          </button>
        </div>

        <div className="flex flex-col gap-2 max-h-96 p-2 overflow-y-auto">
          {entityTypes.map((entityType) => (
            <div key={entityType}>
              <h3 className="text-lg font-medium">
                {entityTypeDictionary[entityType]}
              </h3>
              <div className="flex flex-col gap-2">
                {labels
                  .filter((l) => l.entityType === entityType)
                  .map((label) => (
                    <div
                      key={label.id}
                      className="flex items-center justify-between border-2 rounded-lg px-4 py-2"
                    >
                      <div className="flex items-center gap-2">
                        <div
                          className="w-4 h-4 rounded-full"
                          style={{ backgroundColor: label.color }}
                        />
                        <p>{label.name}</p>
                      </div>
                      {!label.immutable && (
                        <div className="flex items-center gap-2">
                          <button
                            type="button"
                            className="text-gray-500 hover:text-gray-700"
                            onClick={() => {
                              setPreloadedLabel(label);
                              setIsModalOpen(true);
                            }}
                          >
                            <PencilIcon className="h-5 text-blue-500" />
                          </button>
                          <button
                            type="button"
                            className="text-gray-500 hover:text-gray-700"
                            onClick={() => handleDelete(label)}
                          >
                            <TrashIcon className="h-5 text-red-500" />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
          {labels.length === 0 && (
            <p className="text-gray-500">No hay etiquetas creadas</p>
          )}
          {error && <p className="text-red-500">{error}</p>}
        </div>
      </div>
    </>
  );
}

export default LabelEditor;

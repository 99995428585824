import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { DeliveryInfo, ReceiverInfo } from '../types/types';
import { Shipment } from '../../../types/types';
import { updateShipment } from '../../../../../services/shipment.services';

export const handleSubmit =
  ({
    deliveryInfo,
    receiverInfo,
    shipment,
    setShowModal,
    setError,
    setLoader,
    forceUpdate,
    getAccessTokenSilently,
  }: {
    deliveryInfo: DeliveryInfo;
    receiverInfo: ReceiverInfo;
    shipment: Shipment;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setError: Dispatch<SetStateAction<string>>;
    setLoader: Dispatch<SetStateAction<boolean>>;
    forceUpdate: () => void;
    getAccessTokenSilently: () => Promise<string>;
  }) =>
  async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      setError('');
      setLoader(true);

      await updateShipment(
        shipment.id,
        {
          state: deliveryInfo.deliveryState.value,
          city: deliveryInfo.deliveryCity.value,
          street: deliveryInfo.deliveryAddress.value,
          zipCode: deliveryInfo.deliveryZipCode.value,
          doi: receiverInfo.receiverDni.value,
          email: receiverInfo.receiverEmail.value,
          name: receiverInfo.receiverName.value,
          phone: receiverInfo.receiverPhone.value,
        },
        await getAccessTokenSilently(),
      );

      forceUpdate();
      setShowModal(false);
    } catch (err) {
      setError('Ha ocurrido un error al actualizar los datos de envío');
    } finally {
      setLoader(false);
    }
  };

import { useEffect, useState } from 'react';
import BaseModal from '../../../../../components/BaseModal';
import CompleteAddressTemplate from './CompleteAddressTemplate';
import { useServices } from '../../../../../services';

export default function CompleteAddressModal({
  setShowModal,
  showModal,
  save,
  modalType,
  setModalType,
  addressId,
  deliveryAddress,
  accountId,
}) {
  const address = deliveryAddress.find((item) => item.id === addressId);

  const initialStateAddressInfo = {
    referenceName: {
      value:
        address && modalType === 'editDeliveryAddress'
          ? address?.referenceName
          : '',
      label: 'Nombre',
      deliveryKey: 'referenceName',
    },
    deliveryState: {
      value: '',
      label: 'Comunidad Autónoma',
      deliveryKey: 'state',
    },
    deliveryCity: {
      value: '',
      label: 'Ciudad',
      deliveryKey: 'city',
    },
    deliveryStreet: {
      value: '',
      label: 'Dirección',
      deliveryKey: 'street',
    },
    deliveryZipCode: {
      value: '',
      label: 'Código Postal',
      deliveryKey: 'zipCode',
    },
  };

  const initialStateReceiverInfo = {
    deliveryNameSurname: {
      value: '',
      label: 'Nombre y apellido/s',
      deliveryKey: 'nameSurname',
    },
    deliveryDOI: {
      value: '',
      label: 'DNI',
      deliveryKey: 'dni',
    },
    deliveryPhone: {
      value: '',
      label: 'Teléfono',
      deliveryKey: 'phone',
    },
    deliveryEmail: {
      value: '',
      label: 'Email',
      deliveryKey: 'email',
    },
  };

  const [loading, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [addressInfo, setAddressInfo] = useState(initialStateAddressInfo);
  const [receiverInfo, setReceiverInfo] = useState(initialStateReceiverInfo);

  const { createDeliveryAddress, editDeliveryAddressById } = useServices();

  const resetModal = () => {
    setShowModal(false);
    setError(false);
    setLoader(false);
    setAddressInfo(initialStateAddressInfo);
    setReceiverInfo(initialStateReceiverInfo);
    setModalType('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      if (modalType === 'newDeliveryAddress') {
        await createDeliveryAddress({
          accountId,
          referenceName: addressInfo.referenceName.value,
          deliveryCCAA: addressInfo.deliveryState.value,
          deliveryZipcode: addressInfo.deliveryZipCode.value,
          deliveryCity: addressInfo.deliveryCity.value,
          deliveryAddress: addressInfo.deliveryStreet.value,
          deliveryNameSurname: receiverInfo.deliveryNameSurname.value,
          deliveryDOI: receiverInfo.deliveryDOI.value,
          deliveryPhone: receiverInfo.deliveryPhone.value,
          deliveryEmail: receiverInfo.deliveryEmail.value,
        });
        save();
      }

      if (modalType === 'editDeliveryAddress') {
        const payload = {
          referenceName: addressInfo.referenceName.value,
          deliveryCCAA: addressInfo.deliveryState.value,
          deliveryZipcode: addressInfo.deliveryZipCode.value,
          deliveryCity: addressInfo.deliveryCity.value,
          deliveryAddress: addressInfo.deliveryStreet.value,
          deliveryNameSurname: receiverInfo.deliveryNameSurname.value,
          deliveryDOI: receiverInfo.deliveryDOI.value,
          deliveryPhone: receiverInfo.deliveryPhone.value,
          deliveryEmail: receiverInfo.deliveryEmail.value,
        };
        await editDeliveryAddressById(addressId, payload);
        save();
      }
      resetModal();
    } catch (err) {
      setError(true);
      setLoader(false);
    }
  };

  const handleFormEdit = (e, setState) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: { ...prev[e.target.name], value: e.target.value },
    }));
  };

  const areCompletedFieldsOfAddress = Object?.values?.(addressInfo).every(
    (field) => field.value,
  );

  const areCompletedFieldsOfReceiver = Object?.values?.(receiverInfo).every(
    (field) => field.value,
  );

  useEffect(() => {
    if (modalType === 'editDeliveryAddress' && address) {
      setAddressInfo((prevAddressInfo) => ({
        ...prevAddressInfo,
        referenceName: {
          ...prevAddressInfo.referenceName,
          value: address.referenceName || '',
        },
        deliveryState: {
          ...prevAddressInfo.deliveryState,
          value: address.deliveryCCAA || '',
        },
        deliveryCity: {
          ...prevAddressInfo.deliveryCity,
          value: address.deliveryCity || '',
        },
        deliveryStreet: {
          ...prevAddressInfo.deliveryStreet,
          value: address.deliveryAddress || '',
        },
        deliveryZipCode: {
          ...prevAddressInfo.deliveryZipCode,
          value: address.deliveryZipcode || '',
        },
      }));

      setReceiverInfo((prevReceiverInfo) => ({
        ...prevReceiverInfo,
        deliveryNameSurname: {
          ...prevReceiverInfo.deliveryNameSurname,
          value: address.deliveryNameSurname || '',
        },
        deliveryDOI: {
          ...prevReceiverInfo.deliveryDOI,
          value: address.deliveryDOI || '',
        },
        deliveryPhone: {
          ...prevReceiverInfo.deliveryPhone,
          value: address.deliveryPhone || '',
        },
        deliveryEmail: {
          ...prevReceiverInfo.deliveryEmail,
          value: address.deliveryEmail || '',
        },
      }));
    } else if (modalType === 'newDeliveryAddress') {
      setAddressInfo(initialStateAddressInfo);
      setReceiverInfo(initialStateReceiverInfo);
    }
  }, [address, modalType]);

  return (
    <BaseModal
      isOpen={showModal}
      onClose={() => {
        resetModal();
      }}
    >
      <div className="space-y-6">
        <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-1 md:gap-6">
            <div className="mt-5 md:mt-0 md:col-span-2">
              {error && (
                <p className="text-red-600">
                  No se ha podido{' '}
                  {modalType === 'newDeliveryAddress'
                    ? 'añadir la nueva dirección'
                    : 'editar la dirección'}
                  . Inténtelo nuevamente
                </p>
              )}
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <p className="block text-sm text-gray-700 mb-6 font-bold">
                      {modalType === 'newDeliveryAddress'
                        ? 'Nueva dirección'
                        : 'Editar dirección'}
                    </p>
                    <div className="flex flex-col gap-y-4 mb-4">
                      {Object.keys(addressInfo).map((i) => (
                        <CompleteAddressTemplate
                          key={i}
                          objectKey={i}
                          data={addressInfo[i]}
                          handleFormEdit={handleFormEdit}
                          setState={setAddressInfo}
                        />
                      ))}
                      <p className="block text-sm text-gray-700 font-bold mt-4">
                        Datos del receptor
                      </p>
                      {Object.keys(receiverInfo).map((i) => (
                        <CompleteAddressTemplate
                          key={i}
                          objectKey={i}
                          data={receiverInfo[i]}
                          handleFormEdit={handleFormEdit}
                          setState={setReceiverInfo}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    disabled={
                      loading ||
                      (!areCompletedFieldsOfAddress &&
                        !areCompletedFieldsOfReceiver)
                    }
                    className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                      areCompletedFieldsOfAddress &&
                      areCompletedFieldsOfReceiver
                        ? 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        : 'bg-gray-400 hover:bg-indigo-500'
                    }`}
                  >
                    {loading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {modalType === 'newDeliveryAddress'
                      ? 'Crear nueva dirección'
                      : 'Editar dirección'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

import { useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';

import { useServices } from '../../../services';
import AssignBox from './components/AssignBox';
import Timeline from '../../../components/Timeline/Timeline';
import './components/spinner.css';

import AgentAssignBox from '../../../components/AgentAssignBox';
import EditorModal from '../../../components/EditorModal';
import TaxDetails from './components/TaxDetails/TaxDetails';
import { transferDetailsContext } from '../../../context/transfer-details.context';
import TransactionStatusSelector from './components/TransactionStatusSelector';
import GenerateInvoiceButton from './components/GenerateInvoiceButton';
import TransactionPaidStatus from './components/TransactionPaidStatus';
import TopSection from './components/TopSection';
import DocumentsSection from './components/DocumentsSection/DocumentsSection';
import TransactionSummary from './components/TransactionSummary';
import { ENTITY_TYPES } from '../../../helpers/enums';
import SignatureDetails from '../../../components/SignatureDetails/SignatureDetails';
import RelatedTramit from './components/RelatedTramit';
import CommentsModule from '../../../components/Comments/CommentsModule';
import Shipment from '../../../components/Shipments/Shipment';
import useTransactionShipments from './hooks/shipments/useTransactionShipments';
import MechanicalWarranty from './components/MechanicalWarranty';

export default function TransferDetails() {
  const [modalType, setModalType] = useState(null);
  const [environmentalBadge, setEnvironmentalBadge] = useState(null);

  const [contractSignatureDate, setContractSignatureDate] = useState('');
  const [currentVehicleValue, setCurrentVehicleValue] = useState('0');

  const [showDeliveryInfoModal, setShowDeliveryInfoModal] = useState(false);

  const { getBadgeByTransactionId, editTransaction } = useServices();

  const { code } = useParams();

  const {
    transfer,
    forceUpdate,
    updater,
    transferLoading,
    transactionIncidents,
  } = useContext(transferDetailsContext);

  const { transaction, car, agent, intermediary } = transfer;

  const {
    editedShipment,
    availableItems,
    setAvailableItems,
    isShipmentLoading,
    getShipmentError,
    shipments,
    items,
    uncreatedItems,
    setUncreatedItems,
    setEditedShipment,
    areRolesComplete,
    accountId,
    possibleReceivers,
    isDocumentOnOffice,
    isPro,
  } = useTransactionShipments({
    transfer,
    transaction,
    updater,
    environmentalBadge,
  });

  useEffect(() => {
    const fetchBadge = async () => {
      const response = await getBadgeByTransactionId(transaction.id);
      setEnvironmentalBadge(response);
    };

    if (transaction?.environmentBadge) {
      fetchBadge();
    }
  }, [transaction, getBadgeByTransactionId]);

  if (transferLoading) return null;

  return (
    <div className="min-h-full">
      <EditorModal
        type={modalType}
        setType={setModalType}
        id={code}
        save={forceUpdate}
        plate={car?.plate}
        preloadedState={transfer}
        tramit={transaction}
        editTramit={editTransaction}
      />

      <main>
        <div id="data-collection-swipoo-copy-helper" className="hidden">
          {JSON.stringify({
            ...transfer,
            car: {
              ...transfer.car,
              currentVehicleValue,
            },
            contractSignatureDate,
          })}
        </div>
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 relative">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section>
              <div className="bg-white shadow sm:rounded-lg">
                <TopSection />
                {transaction.isBatecom && (
                  <RelatedTramit transactionId={transaction.id} />
                )}
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <TransactionSummary
                      setModalType={setModalType}
                      environmentalBadge={environmentalBadge}
                    />
                    <DocumentsSection setModalType={setModalType} />
                  </dl>
                </div>
              </div>
            </section>
            <SignatureDetails
              tramitType={ENTITY_TYPES.TRANSACTION}
              tramitCode={transaction?.transactionCode}
              tramit={transfer}
              setContractSignatureDate={setContractSignatureDate}
            />
            <TaxDetails
              setModalType={setModalType}
              setCurrentVehicleValue={setCurrentVehicleValue}
            />
            <MechanicalWarranty carId={transaction.carId} />
            <Shipment
              setShowDeliveryInfoModal={setShowDeliveryInfoModal}
              showDeliveryInfoModal={showDeliveryInfoModal}
              availableItems={availableItems}
              editedShipment={editedShipment}
              forceUpdate={forceUpdate}
              updater={updater}
              setAvailableItems={setAvailableItems}
              isShipmentLoading={isShipmentLoading}
              getShipmentError={getShipmentError}
              shipments={shipments}
              items={items}
              uncreatedItems={uncreatedItems}
              setUncreatedItems={setUncreatedItems}
              setEditedShipment={setEditedShipment}
              areRolesComplete={areRolesComplete}
              accountId={accountId}
              possibleReceivers={possibleReceivers}
              isDocumentOnOffice={isDocumentOnOffice}
              isPro={isPro}
              entityType={ENTITY_TYPES.TRANSACTION}
              plate={car?.plate}
            />
            <CommentsModule
              entityId={transaction?.id}
              showNotificationOption={transfer.transaction.isB2B}
              entityIncidents={transactionIncidents}
              tramitCode={transaction?.transactionCode}
              tramitType="TRANSACTION"
              emailToNotify={
                intermediary?.email || intermediary?.representative?.email
              }
              platform={transaction?.host}
            />
          </div>
          <section
            aria-labelledby="timeline-title"
            className="lg:col-start-3 lg:col-span-1"
          >
            <TransactionStatusSelector />
            <AssignBox />
            <AgentAssignBox
              agent={agent}
              tramitCode={transaction?.transactionCode}
              editTramit={editTransaction}
              forceUpdate={forceUpdate}
            />
            <TransactionPaidStatus />
            <Timeline tramitId={transaction?.id} />
            <GenerateInvoiceButton />
          </section>
        </div>
      </main>
    </div>
  );
}

import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import React, { useContext, useState } from 'react';
import Spinner from '../../../../components/Spinner';
import { transferDetailsContext } from '../../../../context/transfer-details.context';
import { useServices } from '../../../../services';
import RefundModal from './RefundModal';

const NOT_STARTED = 'NOT_STARTED';
const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';

export default function GenerateInvoiceButton() {
  const [refundModalOpen, setRefundModalOpen] = useState(false);

  const [
    createHoldedInvoiceLoadingStatus,
    setCreateHoldedInvoiceLoadingStatus,
  ] = useState(NOT_STARTED);

  const { transfer } = useContext(transferDetailsContext);
  const { transaction } = transfer;

  const { createHoldedInvoice } = useServices();

  const handleCopyToHolded = async () => {
    setCreateHoldedInvoiceLoadingStatus(LOADING);
    try {
      await createHoldedInvoice({
        transactionCode: transaction.transactionCode,
      });
      setCreateHoldedInvoiceLoadingStatus(SUCCESS);
    } catch (e) {
      setCreateHoldedInvoiceLoadingStatus(ERROR);
    }
  };

  return (
    <>
      <RefundModal isOpen={refundModalOpen} setIsOpen={setRefundModalOpen} />

      {transaction?.holdedInvoiceId ? (
        <button
          type="button"
          className="mt-5 px-2 py-1 bg-yellow-600 text-white rounded-md hover:bg-yellow-700"
          onClick={() => setRefundModalOpen(true)}
        >
          Generar reembolso
        </button>
      ) : (
        <button
          type="button"
          className="mt-5 px-2 py-1 bg-amber-600 text-white rounded-md hover:bg-yellow-700 flex items-center gap-3"
          onClick={handleCopyToHolded}
        >
          <span
            className={`ml-3 ${
              createHoldedInvoiceLoadingStatus === NOT_STARTED && ' mr-3'
            }`}
          >
            Generar factura
          </span>
          {createHoldedInvoiceLoadingStatus === LOADING && (
            <Spinner color="white" size={4} marginTop={0} />
          )}
          {createHoldedInvoiceLoadingStatus === SUCCESS && (
            <CheckIcon className="h-5 w-5 mr-3" aria-hidden="true" />
          )}
          {createHoldedInvoiceLoadingStatus === ERROR && (
            <ExclamationCircleIcon
              className="h-5 w-5 mr-3"
              aria-hidden="true"
            />
          )}
        </button>
      )}
    </>
  );
}

import { useAuth0 } from '@auth0/auth0-react';

import SwipooPro from '../screens/SwipooPro/index.tsx';
import Backoffice from './Backoffice';

export default function Main() {
  const {
    isLoading,
    user: { org_id: orgId },
  } = useAuth0();

  const renderBackofficeVersion = () =>
    orgId === process.env.REACT_APP_SWIPOO_ORG_ID ? (
      <Backoffice />
    ) : (
      <SwipooPro />
    );

  if (isLoading) return <>cargando...</>;
  return <>{renderBackofficeVersion()}</>;
}

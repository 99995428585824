import { MdAdd, MdOutlineFlag } from 'react-icons/md';
import React, { useContext } from 'react';
import EmptyState from '../../../components/EmptyState';
import FileClockOutlineIcon from './icons/FileClockOutlineIcon';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';
import { TransferStatusCategories } from '../../enums/TransferStatusCategories';

interface FinishedTransfersEmptyStateProps {
  showNewTransferModal?: () => void;
}

export default function FinishedTransfersEmptyState({
  showNewTransferModal,
}: FinishedTransfersEmptyStateProps) {
  const { setSingleParam } = useContext(queryParamsContext);

  const seeOngoingTransfers = () => {
    setSingleParam('status_category', TransferStatusCategories.ONGOING);
  };

  return (
    <EmptyState
      icon={MdOutlineFlag}
      title="No tienes compraventas finalizadas"
      message="Una vez completados tus trámites, podrás verlos aquí."
      button={
        <>
          <button
            onClick={seeOngoingTransfers}
            className="flex items-center gap-2 py-2 px-3 font-semibold hover:bg-gray-200 rounded-lg transition ease-in-out"
            type="button"
          >
            <FileClockOutlineIcon className="size-5" />
            Ver trámites en curso
          </button>
          <button
            onClick={showNewTransferModal}
            className="flex items-center gap-2 py-2 px-3 font-semibold text-blue-700 bg-blue-50 hover:bg-blue-100 rounded-lg transition ease-in-out"
            type="button"
          >
            <MdAdd className="size-5" />
            Iniciar nuevo trámite
          </button>
        </>
      }
    />
  );
}

import { CheckIcon } from '@heroicons/react/outline';
import React from 'react';

export default function StepCircle({
  currentStep,
  thisStep,
}: {
  currentStep: number;
  thisStep: number;
}): JSX.Element {
  return (
    <div className="flex items-center justify-center rounded-full border-2 border-blue-600 w-[32px] h-[32px]">
      {currentStep === thisStep && (
        <div
          className="rounded-full bg-blue-600 w-[10px] h-[10px]"
          data-testid="currentStep"
        />
      )}
      {thisStep < currentStep && (
        <div
          data-testid="notCurrentStep"
          className="flex items-center justify-center bg-blue-600 w-[32px] h-[32px] rounded-full"
        >
          <CheckIcon className="text-white text-sm h-[20px] w-[20px]" />
        </div>
      )}
    </div>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import {
  DownloadIcon,
  ClockIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import axios from 'axios';
import React, { useContext } from 'react';
import Button from '../../../../../components/Button';
import { toasterContext } from '../../../../../context/ToasterContext/ToasterContext';
import { transferDetailsContext } from '../../../../../context/TransferDetailsContext/transferDetailsContext';

function TemporaryDocButton() {
  const { transfer, forceUpdate } = useContext(transferDetailsContext);
  const { setToasterData } = useContext(toasterContext);

  const { getAccessTokenSilently } = useAuth0();

  const requestTemporaryDoc = async () => {
    if (!transfer) return;

    const token = await getAccessTokenSilently();

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/v1/internal/request-temporary-circulation-doc/${transfer.transaction.transactionCode}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (e) {
      setToasterData({
        type: 'ERROR',
        title: 'Error solicitando el documento',
        message:
          'No se ha podido solicitar el documento. Por favor, contacta con nosotros.',
      });
      return;
    }
    setToasterData({
      type: 'SUCCESS',
      title: 'Documento solicitado',
      message:
        'El permiso de circulación temporal se ha solicitado correctamente',
    });

    forceUpdate();
  };

  const loadTemporaryDocButton = () => {
    if (transfer?.transaction?.temporaryCirculationDoc) {
      return (
        <Button
          text="Descargar provisional"
          bgColor="bg-white"
          hoverBgColor="bg-gray-100"
          textColor="gray-700"
          RightIcon={DownloadIcon}
          callback={() => {
            // this is supposing that we store the temporary doc in GCS, so the url is public
            window.open(
              transfer?.transaction?.temporaryCirculationDoc,
              '_blank',
            );
          }}
          iconColor="text-gray-400"
          border="border-2"
          borderColor="border-gray-200"
          textSm
          additionalClasses="whitespace-nowrap"
        />
      );
    }

    if (
      transfer?.labels?.some((label) => label.value === 'provisionalSolicitado')
    ) {
      return (
        <Button
          text="Tramitando provisional"
          bgColor="bg-white"
          hoverBgColor="bg-white"
          textColor="gray-700"
          RightIcon={ClockIcon}
          iconColor="text-gray-400"
          border="border-2"
          borderColor="border-gray-200"
          textSm
          additionalClasses="whitespace-nowrap"
        />
      );
    }

    return (
      <Button
        text="Solicitar provisional"
        bgColor="bg-white"
        hoverBgColor="bg-gray-200"
        textColor="gray-700"
        RightIcon={DocumentTextIcon}
        iconColor="text-gray-400"
        callback={requestTemporaryDoc}
        border="border-2"
        borderColor="border-gray-200"
        textSm
        additionalClasses="whitespace-nowrap"
      />
    );
  };
  return loadTemporaryDocButton();
}

export default TemporaryDocButton;

import { MdOutlineFlag } from 'react-icons/md';
import React, { useContext } from 'react';
import EmptyState from '../../../components/EmptyState';
import FileClockOutlineIcon from './icons/FileClockOutlineIcon';
import FileCheckOutlineIcon from './icons/FileCheckOutlineIcon';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';
import { TransferStatusCategories } from '../../enums/TransferStatusCategories';

export default function TransfersWithIncidentsEmptyState() {
  const { setSingleParam } = useContext(queryParamsContext);

  const seeFinishedTransfers = () => {
    setSingleParam('status_category', TransferStatusCategories.FINISHED);
  };

  const seeOngoingTransfers = () => {
    setSingleParam('status_category', TransferStatusCategories.ONGOING);
  };

  return (
    <EmptyState
      icon={FileCheckOutlineIcon}
      title="No tienes compraventas con incidencias"
      message="¡Genial! No hay incidencias a resolver en tus trámites actualmente."
      button={
        <>
          <button
            onClick={seeFinishedTransfers}
            className="flex items-center gap-2 py-2 px-3 font-semibold hover:bg-gray-200 rounded-lg transition ease-in-out"
            type="button"
          >
            <MdOutlineFlag className="size-5" />
            Ver trámites finalizados
          </button>{' '}
          <button
            onClick={seeOngoingTransfers}
            className="flex items-center gap-2 py-2 px-3 font-semibold text-blue-700 bg-blue-50 hover:bg-blue-100 rounded-lg transition ease-in-out"
            type="button"
          >
            <FileClockOutlineIcon className="size-5" />
            Ver trámites en curso
          </button>
        </>
      }
    />
  );
}

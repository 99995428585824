import { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XCircleIcon, XIcon } from '@heroicons/react/solid';

import { useServices } from '../../../../services';
import BaseModal from '../../../../components/BaseModal';

const getBuyerPayload = (transfer) => ({
  id: transfer?.transaction.buyerId,
  role: 'buyer',
  phone: transfer?.buyer?.phone,
  email: transfer?.buyer?.email,
  address: transfer?.buyer?.address,
  city: transfer?.buyer?.city,
  province: transfer?.buyer?.province,
  name:
    transfer?.buyer?.name && transfer?.buyer?.surname
      ? `${transfer?.buyer?.name} ${transfer?.buyer?.surname}`
      : null,
  nif: transfer?.buyer?.dni,
});

const getProBuyerPayload = (transfer) => ({
  id: transfer?.transaction.buyerId,
  role: 'pro',
  phone: transfer?.buyer?.representative?.phone,
  name: `${transfer?.buyer?.representative?.name} ${transfer?.buyer?.representative?.surname}`,
  nif: transfer?.buyer?.representative?.dni,
  companyCity: transfer?.buyer?.fiscalAddressCity,
  companyName: transfer?.buyer?.legalName,
  companyNif: transfer?.buyer?.nif,
  companyStreet: transfer?.buyer?.fiscalAddressAddress,
  companyStreetNumber: ' ',
  companyZipCode: transfer?.buyer?.fiscalAddressZipCode,
  companyProvince: transfer?.buyer?.fiscalAddressProvince,
});

const getSellerPayload = (transfer) => ({
  id: transfer?.transaction.sellerId,
  role: 'seller',
  phone: transfer?.seller?.phone,
  email: transfer?.seller?.email,
  address: transfer?.seller?.address,
  city: transfer?.seller?.city,
  province: transfer?.seller?.province,
  name:
    transfer?.seller?.name && transfer?.seller?.surname
      ? `${transfer?.seller?.name} ${transfer?.seller?.surname}`
      : null,
  nif: transfer?.seller?.dni,
});

const getProSellerPayload = (transfer) => ({
  id: transfer?.transaction.sellerId,
  role: 'pro',
  phone: transfer?.seller?.representative?.phone,
  name: `${transfer?.seller?.representative?.name} ${transfer?.seller?.representative?.surname}`,
  nif: transfer?.seller?.representative?.dni,
  companyCity: transfer?.seller?.fiscalAddressCity,
  companyName: transfer?.seller?.legalName,
  companyNif: transfer?.seller?.nif,
  companyStreet: transfer?.seller?.fiscalAddressAddress,
  companyStreetNumber: ' ',
  companyZipCode: transfer?.seller?.fiscalAddressZipCode,
  companyProvince: transfer?.seller?.fiscalAddressProvince,
});

const getAgentPayload = (transfer) => ({
  id: transfer?.transaction.agentId,
  role: 'agency',
  email: transfer?.agent?.email,
  city: transfer?.agent?.city,
  name:
    transfer?.agent?.name && transfer?.agent?.surname
      ? `${transfer?.agent?.name} ${transfer?.agent?.surname}`
      : null,
  number: transfer?.agent?.colNumber,
  colLocation: transfer?.agent?.colLocation,
  street: transfer?.agent?.address,
  streetNumber: transfer?.agent?.addressNumber,
  zipCode: transfer?.agent?.zipCode,
});

const getVehiclePayload = (transfer) => ({
  carBrandAndModel:
    transfer?.car?.brand || transfer?.car?.model
      ? `${transfer?.car?.brand} ${transfer?.car?.model}`
      : null,
  carEnrollmentDate: transfer?.car?.enrollmentDate,
  carKms: transfer?.car?.kms ? String(transfer?.car?.kms) : null,
  carPlate: transfer?.car?.plate,
  carFrameNumber: transfer?.car?.frameNumber,
});

const getDocumentPayload = (transfer) => {
  if (transfer?.buyer?.representativeId && transfer?.seller?.representativeId) {
    // B2B
    return {
      id: crypto.randomUUID(),
      signers: [
        getProBuyerPayload(transfer),
        getProSellerPayload(transfer),
        getAgentPayload(transfer),
      ],
      entityType: 'transaction',
      entityCode: transfer?.transaction.transactionCode,
      vehicleData: getVehiclePayload(transfer),
    };
  }

  if (transfer?.buyer?.representativeId || transfer?.seller?.representativeId) {
    // B2C
    return {
      id: crypto.randomUUID(),
      signers: [
        transfer?.buyer?.representativeId
          ? getProBuyerPayload(transfer)
          : getBuyerPayload(transfer),
        transfer?.seller?.representativeId
          ? getProSellerPayload(transfer)
          : getSellerPayload(transfer),
        getAgentPayload(transfer),
      ],
      entityType: 'transaction',
      entityCode: transfer?.transaction.transactionCode,
      vehicleData: getVehiclePayload(transfer),
    };
  }

  return {
    // C2C
    id: crypto.randomUUID(),
    signers: [
      getBuyerPayload(transfer),
      getSellerPayload(transfer),
      getAgentPayload(transfer),
    ],
    entityType: 'transaction',
    entityCode: transfer?.transaction.transactionCode,
    vehicleData: getVehiclePayload(transfer),
  };
};

const validateData = (transfer, documentPayload, setError) => {
  let newError = 'No es posible crear la firma digital:\n';
  if (!transfer?.buyer) {
    newError += '- No existe comprador\n';
  }

  if (!transfer?.seller) {
    newError += '- No existe vendedor\n';
  }

  if (!transfer?.agent) {
    newError += '- No hay un gestor asignado\n';
  }

  if (!transfer?.car) {
    newError += '- No existe vehículo\n';
  }

  const payloadToAnalyze = {
    buyer:
      documentPayload.signers.find((s) => s.role === 'buyer') ||
      documentPayload.signers.find((s) => s.role === 'pro'),
    seller:
      documentPayload.signers.find((s) => s.role === 'seller') ||
      documentPayload.signers.find((s) => s.role === 'pro'),
    agent: documentPayload.signers.find((s) => s.role === 'agency'),
    car: {
      carBrandAndModel: documentPayload.vehicleData.carBrandAndModel,
      carEnrollmentDate: documentPayload.vehicleData.carEnrollmentDate,
      carPlate: documentPayload.vehicleData.carPlate,
      carFrameNumber: documentPayload.vehicleData.carFrameNumber,
    },
  };

  const externalKeyDictionary = {
    buyer: 'comprador',
    seller: 'vendedor',
    agent: 'gestor',
    car: 'vehículo',
  };

  const internalKeyDictionary = {
    id: 'id',
    phone: 'teléfono',
    address: 'dirección',
    city: 'ciudad',
    name: 'nombre y/o apellidos',
    nif: 'dni/nif',
    representantName: 'nombre del representante',
    representantNif: 'dni del representante',
    email: 'email',
    number: 'número de colegiado',
    province: 'provincia',
    street: 'calle',
    streetNumber: 'número de calle',
    zipCode: 'código postal',
    carBrandAndModel: 'marca y modelo',
    carEnrollmentDate: 'fecha de matriculación',
    carPlate: 'matrícula',
    carFrameNumber: 'número de bastidor',
    companyCity: 'ciudad de la empresa',
    companyName: 'nombre de la empresa',
    companyNif: 'nif de la empresa',
    companyStreet: 'calle de la empresa',
    companyZipCode: 'código postal de la empresa',
    companyProvince: 'provincia de la empresa',
  };

  Object.keys(payloadToAnalyze).forEach((externalKey) => {
    Object.keys(payloadToAnalyze[externalKey]).forEach((internalKey) => {
      if (!payloadToAnalyze[externalKey][internalKey])
        newError += `- Falta el campo ${internalKeyDictionary[internalKey]} en ${externalKeyDictionary[externalKey]}\n`;
    });
  });

  if (newError !== 'No es posible crear la firma digital:\n') {
    setError({
      status: true,
      message: newError,
    });
  } else {
    setError({
      status: false,
      message: '',
    });
  }
};

export default function DigitalSignatureModal({
  setShowModal,
  showModal,
  transfer,
  save,
  isPro,
}) {
  const initialErrorState = {
    status: false,
    message: '',
  };

  const setCarPriceInitState = {
    include: true,
    value: String(transfer?.transaction?.priceContractValue || '0'),
  };

  const [error, setError] = useState(initialErrorState);
  const [skipAgentSignature, setSkipAgentSignature] = useState(false);
  const [loading, setLoader] = useState(false);

  const [carPrice, setCarPrice] = useState(setCarPriceInitState);

  const documentPayload = getDocumentPayload(transfer);

  const { createDigitalSignature } = useServices();

  const isThereAnyPro = documentPayload.signers.some(
    (signer) => signer.role === 'pro',
  );

  const isB2b =
    documentPayload.signers.filter((signer) => signer.role === 'pro').length ===
    2;

  useEffect(() => {
    if (!showModal) return;

    validateData(transfer, documentPayload, setError);
  }, [showModal]);

  const resetModal = () => {
    setShowModal(false);
    setSkipAgentSignature(false);
    setError(initialErrorState);
    setLoader(false);
    setCarPrice(setCarPriceInitState);
  };

  const handleCreateSignature = async () => {
    setLoader(true);

    const getType = () => {
      if (isB2b) return 'b2b';
      if (isThereAnyPro) return 'b2c';
      return 'c2c';
    };

    try {
      await createDigitalSignature(
        {
          ...documentPayload,
          vehicleData: {
            ...documentPayload.vehicleData,
            ...(carPrice.include && { carPrice: carPrice.value }),
          },
        },
        getType(),
        skipAgentSignature,
      );
      setError({
        status: false,
        message: '',
      });
      resetModal();
    } catch (err) {
      setError({
        status: true,
        message:
          err.response.data.message === 'Invalid phone number'
            ? 'Error al crear firma digital: hay un número de teléfono inválido.'
            : 'Error al crear firma digital, ocurrió un error interno del servidor, por favor intente más tarde o contacte con nosotros.',
      });
    } finally {
      setLoader(false);
    }
    save();
  };

  const handleChangeSkipAgentSignature = () => {
    setSkipAgentSignature(!skipAgentSignature);
  };

  return (
    <BaseModal isOpen={showModal} onClose={resetModal} overflowVisible={false}>
      {error.status ? (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800 whitespace-pre">
                {error.message}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={() => {
                    resetModal();
                  }}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-md leading-6 font-medium text-gray-900"
                >
                  Crear firma digital
                </Dialog.Title>
                <div className="mt-2">
                  <div>
                    {!isPro && (
                      <div className="flex gap-2 mt-3 items-center">
                        <input
                          type="checkbox"
                          name="skipAgentSignature"
                          id="skipAgentSignature"
                          checked={!skipAgentSignature}
                          onChange={handleChangeSkipAgentSignature}
                        />
                        <label htmlFor="skipAgentSignature">
                          Añadir gestor a la firma
                        </label>
                      </div>
                    )}
                    <div className="flex gap-2 mt-3 items-center">
                      <input
                        type="checkbox"
                        checked={carPrice.include}
                        name="includeCarPrice"
                        id="includeCarPrice"
                        onChange={() => {
                          setCarPrice({
                            ...carPrice,
                            include: !carPrice.include,
                          });
                        }}
                      />
                      <label htmlFor="includeCarPrice">
                        Añadir precio de contrato
                      </label>
                    </div>
                    {carPrice.include && (
                      <div className="flex gap-2 mt-3 items-center">
                        <input
                          type="number"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mt-2"
                          placeholder="Precio del coche"
                          value={carPrice.value}
                          onChange={(e) => {
                            setCarPrice({
                              ...carPrice,
                              value: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleCreateSignature}
              disabled={loading}
            >
              {loading && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              )}
              {loading ? 'Enviando...' : 'Enviar'}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={resetModal}
            >
              Cancelar
            </button>
          </div>
        </>
      )}
    </BaseModal>
  );
}

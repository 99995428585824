import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { DeliveryInfo, ReceiverInfo, ShipmentField } from '../types/types';

export const handleFormEdit =
  (setReceiverRole: Dispatch<SetStateAction<string>>) =>
  (
    e: SyntheticEvent,
    setState: Dispatch<SetStateAction<DeliveryInfo | ReceiverInfo>>,
  ) => {
    const target = e.target as HTMLInputElement;
    setState((prev: DeliveryInfo | ReceiverInfo) => {
      const previousFieldValue = prev[
        target.name as keyof typeof prev
      ] as ShipmentField;
      return {
        ...prev,
        [target.name]: { ...previousFieldValue, value: target.value },
      };
    });
    setReceiverRole('custom');
  };

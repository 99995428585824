import {
  ChevronDownIcon,
  ChevronUpIcon,
  DownloadIcon,
} from '@heroicons/react/outline';
import React from 'react';
import parseDate from '../../helpers/parseDate';
import { downloadDocument, getFileName } from '../../helpers/typeformFiles';

export type Column = { label: string; key: string; file?: boolean };

type TableProps = {
  columns: Column[];
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }[];
  dateSort: string;
  setSingleParam: (key: string, value: string) => void;
  view?: boolean;
};

export default function Table({
  columns,
  data,
  dateSort,
  setSingleParam,
  view = false,
}: TableProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCellContents = (col: Column, item: { [key: string]: any }) => {
    if (col.key === 'createdAt') {
      return parseDate(item[col.key]);
    }
    if (col?.file) {
      return (
        item[col.key] && (
          <div className="flex gap-x-2">
            <span>{getFileName(item[col.key])}</span>
            <button
              type="button"
              onClick={() =>
                downloadDocument(item[col.key], getFileName(item[col.key]))
              }
            >
              <DownloadIcon className="w-4 hover:text-indigo-500 cursor-pointer" />
            </button>
          </div>
        )
      );
    }
    return item[col.key];
  };
  return (
    <div className="overflow-x-auto min-h-fit shadow ring-1 ring-black ring-opacity-5 rounded-lg">
      <table className="table-fixed min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            {columns.map((col, i) =>
              col.key === 'createdAt' ? (
                <th
                  key={col.key}
                  scope="col"
                  className="px-3 py-3.5 text-sm font-semibold text-gray-900 uppercase cursor-pointer flex gap-2 items-center justify-center text-center"
                  onClick={
                    dateSort === 'DESC'
                      ? () => setSingleParam('dateSort', 'ASC')
                      : () => setSingleParam('dateSort', 'DESC')
                  }
                >
                  {col.label}
                  {dateSort === 'ASC' ? (
                    <ChevronUpIcon className="h-3" />
                  ) : (
                    <ChevronDownIcon className="h-3" />
                  )}
                </th>
              ) : (
                <th
                  key={col.key}
                  scope="col"
                  className={`py-3.5 text-left px-3 sm:px-6 text-sm font-semibold text-gray-900 uppercase ${
                    i > 0 && 'text-center'
                  }`}
                >
                  {col.label}
                </th>
              ),
            )}
            {view && (
              <th
                scope="col"
                className="sticky right-0 bg-gray-50 py-3.5 pl-3 pr-4 shadow-md sm:pr-6"
              >
                <span className="sr-only">Ver</span>
              </th>
            )}
          </tr>
        </thead>
        <tbody className="bg-white">
          {data.map((item, index) => (
            <tr
              key={item.id}
              className={`${
                index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
              } hover:bg-gray-100 h-14`}
            >
              {columns.map((col, i) => (
                <td
                  key={`${item.id}${col.key}`}
                  className={`whitespace-nowrap px-3 sm:px-6 py-4 text-sm text-gray-500 ${
                    i > 0 || (i === 0 && col.key === 'createdAt')
                      ? 'text-center'
                      : ''
                  }`}
                >
                  {getCellContents(col, item)}
                </td>
              ))}
              {view && item.href && (
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer hover:text-blue-700"
                  >
                    Ver
                  </a>
                </td>
              )}
              {view && item.viewCallback && (
                <td
                  className={`${
                    index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                  } sticky right-0 min-w-16 w-16 align-middle whitespace-nowrap text-center text-sm font-medium`}
                >
                  <button
                    type="button"
                    className="text-blue-600 hover:text-blue-900"
                    onClick={() => item.viewCallback()}
                  >
                    Ver
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

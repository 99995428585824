import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import React, { Fragment, useContext, useEffect } from 'react';
import { toasterContext, ToastType } from '../ToasterContext';

function Toaster() {
  const { data, toastOpen, closeToast } = useContext(toasterContext);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (toastOpen) {
      timer = setTimeout(() => {
        closeToast();
      }, 5000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [toastOpen]);

  const getIcon = (type: ToastType) => {
    if (type === 'SUCCESS')
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      );
    if (type === 'ERROR')
      return (
        <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
      );
    if (type === 'WARNING')
      return (
        <ExclamationIcon
          className="h-6 w-6 text-yellow-400"
          aria-hidden="true"
        />
      );
    return (
      <InformationCircleIcon
        className="h-6 w-6 text-blue-400"
        aria-hidden="true"
      />
    );
  };
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={toastOpen}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">{getIcon(data.type)}</div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    {data.title}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">{data.message}</p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={closeToast}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon
                      data-testid="close-icon"
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default Toaster;

import React from 'react';

export default function FileCancelOutlineIcon({
  className = '',
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="m14 2l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm4 18V9h-5V4H6v16zm-6.5-10c2.5 0 4.5 2 4.5 4.5S14 19 11.5 19S7 17 7 14.5S9 10 11.5 10m0 1.5c-.56 0-1.08.15-1.5.42L14.08 16c.27-.42.42-.94.42-1.5a3 3 0 0 0-3-3m-3 3a3 3 0 0 0 3 3c.56 0 1.08-.15 1.5-.42L8.92 13c-.27.42-.42.94-.42 1.5"
      />
    </svg>
  );
}

import React from 'react';

import { parseDate } from '../../../../helpers';
import Incidents from '../../../../components/Incidents/Incidents';
import { ENTITY_TYPES } from '../../../../helpers/enums';
import Labels from '../../../../components/Labels/Labels';

export default function TopSection({
  account,
  registration,
  labels,
  forceUpdate,
  incidents,
}) {
  return (
    <div className="px-4 py-5 sm:px-6">
      <div className="flex justify-between gap-x-4">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900 inline">
            {registration.registrationCode}
          </h2>

          <p className="my-1 max-w-2xl text-sm text-gray-500">
            <time dateTime={parseDate(registration.createdAt)}>
              Creada el {parseDate(registration.createdAt)}
            </time>
          </p>
        </div>
        <Incidents
          entityId={registration?.id}
          entityType={ENTITY_TYPES.REGISTRATION}
          forceUpdate={forceUpdate}
          emailToNotify={account?.billingEmail}
          entityIncidents={incidents}
        />
      </div>
      <Labels
        forceUpdate={forceUpdate}
        labels={labels}
        entityId={registration?.id}
        entityType={ENTITY_TYPES.REGISTRATION}
      />
    </div>
  );
}

import { useState, useEffect, useCallback } from 'react';
import { useServices } from '../../../services';
import DeliveryInfoTemplate from './DeliveryInfoTemplate';
import BaseModal from '../../BaseModal';
import { itemTypeDictionary } from '../helpers/itemTypeDictionary';

export default function DeliveryInfoModal({
  setShowModal,
  showModal,
  save,
  shipment,
  availableItems,
  setAvailableItems,
  accountId,
  possibleReceivers,
  isPro,
  updater,
}) {
  const [loading, setLoader] = useState(false);
  const [error, setError] = useState('');
  const [items, setItems] = useState([]);
  const [receiverRole, setReceiverRole] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState([]);

  const { getAllDeliveryAddress } = useServices();

  const possibleReceiversRoles = {
    BUYER: 'BUYER',
    SELLER: 'SELLER',
    INTERMEDIARY: 'INTERMEDIARY',
    CUSTOM: 'CUSTOM',
  };

  const getDeliveryAddress = useCallback(async () => {
    if (!accountId) return;
    try {
      setError('');
      const response = await getAllDeliveryAddress(accountId);
      setDeliveryAddress(response);
    } catch (err) {
      setError('Ha ocurrido un error al cargar las direcciones de envío.');
    }
  }, [getAllDeliveryAddress, accountId]);

  useEffect(() => {
    if (!showModal) return;
    getDeliveryAddress();
  }, [updater, showModal]);

  const [deliveryInfo, setDeliveryInfo] = useState({
    deliveryState: {
      value: shipment.delivery?.state || '',
      label: 'Comunidad Autónoma',
      shipmentKey: 'state',
    },
    deliveryCity: {
      value: shipment.delivery?.city || '',
      label: 'Ciudad',
      shipmentKey: 'city',
    },
    deliveryAddress: {
      value: shipment.delivery?.street || '',
      label: 'Dirección',
      shipmentKey: 'street',
    },
    deliveryZipCode: {
      value: shipment.delivery?.zipCode || '',
      label: 'Código Postal',
      shipmentKey: 'zipCode',
    },
  });
  const [receiverInfo, setReceiverInfo] = useState({
    receiverName: {
      value: shipment.receiver?.name || '',
      label: 'Nombre',
      shipmentKey: 'name',
    },
    receiverDni: {
      value: shipment.receiver?.doi || '',
      label: 'DNI',
      shipmentKey: 'doi',
    },
    receiverEmail: {
      value: shipment.receiver?.email || '',
      label: 'Email',
      shipmentKey: 'email',
    },
    receiverPhone: {
      value: shipment.receiver?.phone || '',
      label: 'Teléfono',
      shipmentKey: 'phone',
    },
  });

  const { getUserById, getCompanyInfoById, updateShipment } = useServices();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError('');
      setLoader(true);
      await updateShipment(shipment.id, {
        state: deliveryInfo.deliveryState.value,
        city: deliveryInfo.deliveryCity.value,
        street: deliveryInfo.deliveryAddress.value,
        zipCode: deliveryInfo.deliveryZipCode.value,
        doi: receiverInfo.receiverDni.value,
        email: receiverInfo.receiverEmail.value,
        name: receiverInfo.receiverName.value,
        phone: receiverInfo.receiverPhone.value,
        items: items.filter((i) => i.isOnShipment).map((i) => i.id),
      });
      setAvailableItems([]);
      save();
      setShowModal(false);
    } catch (err) {
      setError('Ha ocurrido un error al actualizar los datos de envío');
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (e) => setReceiverRole(e.target.value);
  const handleChangeAddress = (e) => {
    if (
      Object.values(possibleReceiversRoles).some(
        (value) => value.toLowerCase() === e.target.value,
      )
    ) {
      setReceiverRole(e.target.value);
      setDeliveryInfo({
        deliveryState: {
          value: '',
          label: 'Comunidad Autónoma',
          shipmentKey: 'state',
        },
        deliveryCity: {
          value: '',
          label: 'Ciudad',
          shipmentKey: 'city',
        },
        deliveryAddress: {
          value: '',
          label: 'Dirección',
          shipmentKey: 'street',
        },
        deliveryZipCode: {
          value: '',
          label: 'Código Postal',
          shipmentKey: 'zipCode',
        },
      });
    } else {
      setReceiverRole('');

      const selectedAddress = deliveryAddress.find(
        (address) => address.id === e.target.value,
      );
      setDeliveryInfo({
        deliveryState: {
          value: selectedAddress.deliveryCCAA,
          label: 'Comunidad Autónoma',
          shipmentKey: 'state',
        },
        deliveryCity: {
          value: selectedAddress.deliveryCity,
          label: 'Ciudad',
          shipmentKey: 'city',
        },
        deliveryAddress: {
          value: selectedAddress.deliveryAddress,
          label: 'Dirección',
          shipmentKey: 'street',
        },
        deliveryZipCode: {
          value: selectedAddress.deliveryZipcode,
          label: 'Código Postal',
          shipmentKey: 'zipCode',
        },
      });

      setReceiverInfo({
        receiverName: {
          value: selectedAddress?.deliveryNameSurname || '',
          label: 'Nombre',
          shipmentKey: 'name',
        },
        receiverDni: {
          value: selectedAddress?.deliveryDOI || '',
          label: 'DNI',
          shipmentKey: 'doi',
        },
        receiverEmail: {
          value: selectedAddress?.deliveryEmail || '',
          label: 'Email',
          shipmentKey: 'email',
        },
        receiverPhone: {
          value: selectedAddress?.deliveryPhone || '',
          label: 'Teléfono',
          shipmentKey: 'phone',
        },
      });
    }
  };

  const handleFormEdit = (e, setState) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: { ...prev[e.target.name], value: e.target.value },
    }));
    setReceiverRole('custom');
  };

  const setNewReceiver = (user) => {
    setReceiverInfo((prev) => ({
      receiverName: {
        ...prev.receiverName,
        value: `${user.name} ${user.surname}` || '',
      },
      receiverDni: {
        ...prev.receiverDni,
        value: user.dni || '',
      },
      receiverEmail: {
        ...prev.receiverEmail,
        value: user.email || '',
      },
      receiverPhone: {
        ...prev.receiverPhone,
        value: user.phone || '',
      },
    }));
  };

  const handleToggleItem = (i) => {
    setItems((prev) =>
      prev.map((item) =>
        item.type === i.type
          ? { ...item, isOnShipment: !i.isOnShipment }
          : item,
      ),
    );
  };

  useEffect(() => {
    if (showModal) {
      const getUserInfo = async () => {
        const id = possibleReceivers[receiverRole];

        let company;
        let user;

        try {
          user = await getUserById(id);
        } catch (e) {
          if (!user) {
            company = await getCompanyInfoById(id);
            user = await getUserById(company.representativeId);
          }
        }

        setNewReceiver(user);
      };

      if (receiverRole !== 'custom') {
        getUserInfo();
      }
    }
  }, [receiverRole]);

  useEffect(() => {
    setDeliveryInfo({
      deliveryState: {
        value: shipment.delivery?.state || '',
        label: 'Comunidad Autónoma',
        shipmentKey: 'state',
      },
      deliveryCity: {
        value: shipment.delivery?.city || '',
        label: 'Ciudad',
        shipmentKey: 'city',
      },
      deliveryAddress: {
        value: shipment.delivery?.street || '',
        label: 'Dirección',
        shipmentKey: 'street',
      },
      deliveryZipCode: {
        value: shipment.delivery?.zipCode || '',
        label: 'Código Postal',
        shipmentKey: 'zipCode',
      },
    });
    setReceiverInfo({
      receiverName: {
        value: shipment.receiver?.name || '',
        label: 'Nombre',
        shipmentKey: 'name',
      },
      receiverDni: {
        value: shipment.receiver?.doi || '',
        label: 'DNI',
        shipmentKey: 'doi',
      },
      receiverEmail: {
        value: shipment.receiver?.email || '',
        label: 'Email',
        shipmentKey: 'email',
      },
      receiverPhone: {
        value: shipment.receiver?.phone || '',
        label: 'Teléfono',
        shipmentKey: 'phone',
      },
    });
  }, [shipment, showModal]);

  useEffect(() => {
    if (!shipment) return;

    setItems([
      ...shipment.items.map((i) => ({
        id: i.id,
        type: i.type,
        isOnShipment: true,
      })),
      ...availableItems.map((i) => ({
        id: i.id,
        type: i.type,
        isOnShipment: false,
      })),
    ]);
  }, [shipment]);

  return (
    <BaseModal
      isOpen={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      overflowVisible={false}
    >
      <div className="space-y-6">
        <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-1 md:gap-6">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                Completar Información de Envío
              </h3>
              {error && <p className="text-red-600">{error}</p>}
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <p className="block text-sm font-medium text-gray-700 mb-2">
                      Faltan algunos datos de envío. Por favor seleccionar quién
                      será el destinatario del envío y se autorellenarán los
                      datos.
                    </p>
                    <p className="mb-2 text-sm font-medium text-gray-700 py-2">
                      <span>Destinatario:</span>{' '}
                      {isPro ? (
                        <select
                          onChange={handleChangeAddress}
                          className="rounded-md border-gray-300 text-sm h-fit ml-4"
                        >
                          <option value="" disabled>
                            Seleccionar
                          </option>
                          <option value="buyer">Comprador</option>
                          <option value="seller">Vendedor</option>
                          <option value="intermediary">Intermediario</option>
                          <option value="custom">Personalizado</option>
                          {deliveryAddress.map((address) => (
                            <option value={address.id}>
                              {address.referenceName}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <select
                          value={receiverRole}
                          onChange={handleChange}
                          className="rounded-md border-gray-300 text-sm h-fit ml-4"
                        >
                          <option value="" disabled>
                            Seleccionar
                          </option>
                          <option value="buyer">Comprador</option>
                          <option value="seller">Vendedor</option>
                          <option value="intermediary">Intermediario</option>
                          <option value="custom">Personalizado</option>
                        </select>
                      )}
                    </p>

                    <p className="block text-sm text-gray-700 mb-2  font-bold">
                      Items en el envío
                    </p>
                    <div className="flex flex-col mb-4">
                      {items.map((i) => (
                        <span
                          key={i.id}
                          className="text-sm flex gap-x-2 items-center"
                        >
                          {itemTypeDictionary.get(i.type)}
                          {!['CIRCULATION_PERMIT', 'TECHNICAL_SHEET'].includes(
                            i.type,
                          ) &&
                            items.length > 1 && (
                              <input
                                type="checkbox"
                                name=""
                                id=""
                                onChange={() => handleToggleItem(i)}
                                checked={i.isOnShipment}
                              />
                            )}
                        </span>
                      ))}
                    </div>

                    <p className="block text-sm text-gray-700 mb-2  font-bold">
                      Dirección de Envío
                    </p>
                    <div className="flex flex-col gap-y-4 mb-4">
                      <div className="flex justify-between mr-6 mb-2 text-sm font-medium text-gray-700 ml-4">
                        <span>País:</span>{' '}
                        <span className="font-bold ml-2">
                          {shipment.delivery?.country === 'ES'
                            ? 'España'
                            : shipment.delivery?.country}
                        </span>
                      </div>
                      {Object.keys(deliveryInfo).map((i) => (
                        <DeliveryInfoTemplate
                          key={i}
                          objectKey={i}
                          data={deliveryInfo[i]}
                          handleFormEdit={handleFormEdit}
                          setState={setDeliveryInfo}
                        />
                      ))}
                    </div>

                    <p className="block text-sm text-gray-700 mb-2 font-bold">
                      Datos del Receptor
                    </p>
                    <div className="flex flex-col gap-y-4">
                      {Object.keys(receiverInfo).map((i) => (
                        <DeliveryInfoTemplate
                          key={i}
                          objectKey={i}
                          data={receiverInfo[i]}
                          handleFormEdit={handleFormEdit}
                          setState={setReceiverInfo}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    disabled={loading || !deliveryInfo.deliveryState.value}
                    className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                      deliveryInfo.deliveryState.value
                        ? 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        : 'bg-gray-400 hover:bg-indigo-500'
                    }`}
                  >
                    {loading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {loading ? 'Guardando...' : 'Guardar datos'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

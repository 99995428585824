import { XIcon } from '@heroicons/react/solid';
import React, { useContext } from 'react';
import { createTransferContext } from '../../../../context/CreateTransferContext/CreateTransferContext';
import Timeline from './Timeline';

export default function Header({
  toggleModalOpen,
}: {
  toggleModalOpen: () => void;
}): JSX.Element {
  const { currentStep, totalSteps } = useContext(createTransferContext);
  return (
    <div className="h-[65px] w-full bg-[#F9FAFB] flex items-center justify-between px-8 py-[20px] rounded-t-md">
      <p className="select-none">Nueva compraventa</p>
      <Timeline currentStep={currentStep} totalSteps={totalSteps} />
      <XIcon
        data-testid="close-btn"
        onClick={toggleModalOpen}
        className="text-gray-400 w-6 h-6 cursor-pointer"
      />
    </div>
  );
}

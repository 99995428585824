export default function parseDate(date: string) {
  const parsedDate = new Date(date);
  return `${parsedDate.getDate().toString().padStart(2, '0')}/${(
    parsedDate.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}/${parsedDate.getFullYear()}`;
}

const padNum = (num: number) => num.toString().padStart(2, '0');

const processDate = (date: Date) => {
  const d = new Date(date);
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getUTCFullYear();

  return { hours, minutes, seconds, day, month, year };
};

export const parseDayMonthYear = (date: Date) => {
  const { day, month, year } = processDate(date);
  return `${padNum(day)}/${padNum(month)}/${year}`;
};

export const parseDayMonthYearHourMinute = (date: Date) => {
  const { day, month, year, hours, minutes } = processDate(date);
  return `${padNum(day)}/${padNum(month)}/${year} ${padNum(hours)}:${padNum(
    minutes,
  )}`;
};

const monthNamesShort = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

export const parseDayMonth3LetterYear = (date: Date) => {
  const { day, month } = processDate(date);
  return `${padNum(day)} ${monthNamesShort[month - 1]} ${
    processDate(date).year
  }`;
};

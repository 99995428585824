import React from 'react';

function CustomersTableSkeletonLoader() {
  return (
    <div className="overflow-x-auto min-h-fit shadow ring-1 ring-black ring-opacity-5 rounded-lg">
      <table className="table-fixed min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
            >
              Nombre
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              DNI
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Teléfono
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {[...Array(10)].map((transfer, personIdx) => (
            <tr
              key={transfer}
              className={personIdx % 2 === 0 ? '' : 'bg-gray-50'}
            >
              <td className="w-1/4 px-3 h-14 align-middle whitespace-nowrap text-sm text-gray-500">
                <div className="w-full h-4 bg-gray-200 rounded-lg text-transparent animate-pulse" />
              </td>
              <td className="w-1/4 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                <div className="w-full h-4 bg-gray-200 rounded-lg text-transparent animate-pulse" />
              </td>
              <td className="w-1/4 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                <div className="w-full h-4 bg-gray-200 rounded-lg text-transparent animate-pulse" />
              </td>
              <td className="w-1/4 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                <div className="w-full h-4 bg-gray-200 rounded-lg text-transparent animate-pulse" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CustomersTableSkeletonLoader;

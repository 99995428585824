import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useContext } from 'react';
import { CheckIcon } from '@heroicons/react/outline';

import { REGISTRATION_STATUS } from '../../shared/registration-status';
import Tabs, { Tab, TabsType } from '../../components/Tabs';
import RegistrationStepper from './RegistrationStepper';
import Button from '../../components/Button';
import Title from '../../components/Title';
import { RegistrationTramit } from '../../types/types';
import { toasterContext } from '../../context/ToasterContext/ToasterContext';
import Incidents from '../../components/Incidents/Incidents';

type RegistrationDetailsHeaderPropsType = {
  currentTab: Tab;
  tabs: TabsType;
  forceUpdate: () => void;
  isLoading: boolean;
  tramit: RegistrationTramit;
  handleChangeTab: (tabId: string) => () => void;
};

export default function RegistrationDetailsHeader({
  tramit,
  isLoading,
  forceUpdate,
  currentTab,
  handleChangeTab,
  tabs,
}: RegistrationDetailsHeaderPropsType) {
  const [loading, setLoading] = useState<boolean>(false);

  const { setToasterData } = useContext(toasterContext);
  const { getAccessTokenSilently } = useAuth0();

  const { registration } = tramit || {};

  const presentRegistrationHandler = async () => {
    const token = await getAccessTokenSilently();
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/v1/registration/${tramit.registration.registrationCode}/ready`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );
      forceUpdate();
    } catch (error) {
      setToasterData({
        type: 'ERROR',
        title: 'Ha ocurrido un error al presentar a gestoría',
        message: 'Revisa los datos del trámite o contacta con nosotros.',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col justify-between gap-y-6">
      <div className="flex justify-between">
        <Title
          vehicle={tramit?.car}
          isLoading={isLoading}
          tramitSubtitleType="Matriculación"
          creationDate={tramit?.registration?.createdAt}
        />
        <div className="flex items-start gap-x-4">
          {REGISTRATION_STATUS.PENDING_DOCUMENTATION ===
            tramit?.registration.status && (
            <Button
              text="Presentar"
              bgColor="bg-blue-700"
              hoverBgColor="bg-blue-500"
              textColor="white"
              border="border-2"
              RightIcon={CheckIcon}
              textSm
              callback={presentRegistrationHandler}
              loading={loading}
              additionalClasses="whitespace-nowrap"
            />
          )}
        </div>
      </div>
      <RegistrationStepper status={tramit?.registration.status} />
      <Incidents entityId={registration?.id} />
      <Tabs
        currentTab={currentTab}
        handleChangeTab={handleChangeTab}
        tabs={tabs}
      />
    </div>
  );
}

import React, { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export interface QueryParamsContextProviderType {
  params: URLSearchParams;
  setSingleParam: (key: string, value: string) => void;
  setArrayParam: (key: string, value: string[]) => void;
  getSingleParam: (key: string) => string | null;
  getArrayParam: (key: string) => string[] | null;
  clearParams: () => void;
}

export const queryParamsContext =
  React.createContext<QueryParamsContextProviderType>(null);

export default function QueryParamsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [params, setParams] = useSearchParams();
  const { pathname } = useLocation();

  const getSingleParam = (paramName: string) => params.get(paramName);
  const getArrayParam = (paramName: string) => params.getAll(paramName);
  const clearParams = () => setParams(new URLSearchParams());

  const setSingleParam = (paramName: string, value: string) => {
    if (value === '') {
      params.delete(paramName);
    } else {
      params.set(paramName, value);
    }
    setParams(params);
  };

  const setArrayParam = (paramName: string, value: string[]) => {
    if (value.length === 0) {
      params.delete(paramName);
    } else {
      params.delete(paramName);
      value.forEach((item) => params.append(paramName, item));
    }
    setParams(params);
  };

  const value = useMemo(
    () => ({
      params,
      setSingleParam,
      setArrayParam,
      getSingleParam,
      getArrayParam,
      clearParams,
    }),
    [params, pathname],
  );

  return (
    <queryParamsContext.Provider value={value}>
      {children}
    </queryParamsContext.Provider>
  );
}

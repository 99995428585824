import React, { useEffect, useState } from 'react';

import { useServices } from '../../../services';
import usePagination from '../../../hooks/usePagination';
import Spinner from '../../../components/Spinner';
import AccountCreationModal from './components/AccountCreationModal';
import AccountsTableList from '../../../components/AccountsTableList';

export default function Accounts() {
  const [accounts, setAccounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, goNext, goPrev] = usePagination();
  const [loader, setLoader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { getAllAccounts } = useServices();

  useEffect(() => {
    const fetchAllAccounts = async () => {
      const response = await getAllAccounts(page);
      setAccounts(response.accounts);
      setTotal(response.total);
      setLoader(false);
    };
    fetchAllAccounts();
  }, [page]);

  return (
    <>
      {isModalOpen && <AccountCreationModal setIsModalOpen={setIsModalOpen} />}
      {loader && (
        <div className="w-full h-full min-h-[500px] flex justify-center items-center">
          <Spinner color="text-blue-700" size={10} marginTop={28} />
        </div>
      )}
      {!loader && (
        <div className="flex flex-col gap-y-2">
          <button
            onClick={() => setIsModalOpen(true)}
            type="button"
            className="inline-flex items-center self-end px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Nueva cuenta
          </button>
          <AccountsTableList
            data={accounts}
            page={page}
            goNext={goNext}
            goPrev={goPrev}
            total={total}
          />
        </div>
      )}
    </>
  );
}

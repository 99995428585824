export const holdedServices = [
  {
    id: '64636470b59d23b8160a1b00',
    name: 'Matriculación Vehículo Nuevo - ANCOVE',
    label: 'Matriculación',
  },
  {
    id: '646363978d61f1764f085b80',
    name: 'Duplicado - ANCOVE',
    label: 'Duplicado',
  },
  {
    id: '64636387fb8e1ea0740da436',
    name: 'Matriculación Vehículo Importado - ANCOVE',
    label: 'Matriculación',
  },
  {
    id: '64636370dc09146359031325',
    name: 'Batecom - ANCOVE',
    label: 'Batecom',
  },
  {
    id: '6463635f43b97df6f708e350',
    name: 'Reserva de dominio - ANCOVE',
    label: 'Reserva de dominio',
  },
  {
    id: '65526afad0dc68b07303c398',
    name: 'BATECOM - Venta - Ancove',
    label: 'BATECOM - Venta',
  },
  {
    id: '65495d7ed37633d79b0cf24c',
    name: 'BATECOM - Compra - Ancove',
    label: 'BATECOM - Compra',
  },
  {
    id: '6463631b6ec7eb62c70a24c4',
    name: 'Duplicado - Swipoo Pro',
    label: 'Duplicado',
  },
  {
    id: '646362fcabfac16a1f0de950',
    name: 'Matriculación - Swipoo Pro',
    label: 'Matriculación',
  },
  {
    id: '646362ba0fae7aef11046d1a',
    name: 'Batecom - Swipoo Pro',
    label: 'Batecom',
  },
  {
    id: '64636291c3a6876d3809d6f1',
    name: 'Reserva de dominio - Swipoo Pro',
    label: 'Reserva de dominio',
  },
  {
    id: '6463624afd8bf743f00d9b8a',
    name: 'Cambio de nombre - Swipoo Pro',
    label: 'Cambio de nombre',
  },
  {
    id: '6538efa93d58f0e342045b0e',
    name: 'BATECOM - Venta - Swipoo Pro',
    label: 'BATECOM - Venta',
  },
  {
    id: '6538ef93ef8defb7c306d039',
    name: 'BATECOM - Compra - Swipoo Pro',
    label: 'BATECOM - Compra',
  },
  {
    id: '643e4dc86e0ea3197006413a',
    name: 'Ancove - Honorarios transferencia',
    label: 'Cambio de nombre',
  },
  {
    id: '62c6ed33004c5f5b0a0459e2',
    name: 'Matriculación Honorarios',
    label: 'Matriculación',
  },
  {
    id: '618aaccd309f2e521c04a3e5',
    name: 'Cambio de nombre ciclomotor',
    label: 'Cambio de nombre - Ciclomotor',
  },
  {
    id: '61485afa6bf30452df2b2733',
    name: 'Cambio de nombre',
    label: 'Cambio de nombre',
  },
  {
    id: '64941466978653afa70948ca',
    name: 'Envío',
    label: 'Envío',
  },
  {
    id: '660c157fdff00b6a2b03243a',
    name: 'Informe de vehículo - Swipoo Pro',
    label: 'Informe de Tráfico',
  },
];

import axios from 'axios';
import React, { useContext, useRef, useState } from 'react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '../../components/Button';
import { toasterContext } from '../../context/ToasterContext/ToasterContext';
import useClickOutside from '../../hooks/useClickOutside';

// eslint-disable-next-line no-shadow
export enum RegistrationsBuyerType {
  INDIVIDUAL = 'PERSON',
  COMPANY = 'COMPANY',
}

interface NewTransferModalProps {
  readonly isShown: boolean;
  readonly hide: () => void;
}

export default function NewTransferModal({
  isShown,
  hide,
}: NewTransferModalProps): JSX.Element {
  const [buyerType, setBuyerType] = useState<RegistrationsBuyerType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ref = useRef(null);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { setToasterData } = useContext(toasterContext);

  useClickOutside(ref, hide);

  const handleCreateRegistration = async () => {
    const token = await getAccessTokenSilently();
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/v1/registration`,
        {
          buyerType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );
      setIsLoading(false);
      setToasterData({
        title: 'Matriculación creada!',
        message: `Se creó la matriculación con código ${data.registrationCode}`,
        type: 'SUCCESS',
      });
      navigate(`/registration/${data.registrationCode}`);
    } catch (e) {
      setToasterData({
        title: 'Error',
        message:
          'Error al crear la matriculación, por favor intente nuevamente.',
        type: 'ERROR',
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isShown) {
    return (
      <dialog
        open={isShown}
        aria-label="Nueva matriculación"
        aria-modal={isShown}
        className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.4)] z-50 w-screen h-screen flex items-center justify-center"
      >
        <div
          className="w-[900px] bg-white rounded-md overflow-hidden"
          ref={ref}
        >
          <header className="w-full bg-gray-50 flex items-center justify-between px-8 py-5">
            <p className="select-none">Nueva matriculación</p>
            <XIcon
              data-testid="close-btn"
              onClick={hide}
              className="text-gray-400 w-6 h-6 cursor-pointer"
            />
          </header>
          <section className="flex flex-col gap-6 w-full px-8 pt-8 pb-2 min-h-96">
            <p className="font-semibold">¿Quién compra el vehículo?</p>
            <article className="flex gap-4 w-full">
              <button
                type="button"
                onClick={() => setBuyerType(RegistrationsBuyerType.INDIVIDUAL)}
                className={`text-left text-sm border rounded-lg py-4 px-6 w-full outline-1 outline-gray-400 outline-offset-2 ${
                  buyerType === RegistrationsBuyerType.INDIVIDUAL &&
                  'border-2 border-blue-600'
                }`}
              >
                <p className="font-semibold">Particular</p>
                <p className="text-gray-400">
                  El comprador es una persona física.
                </p>
              </button>
              <button
                type="button"
                onClick={() => setBuyerType(RegistrationsBuyerType.COMPANY)}
                className={`text-left text-sm border rounded-lg py-4 px-6 w-full outline-1 outline-gray-400 outline-offset-2 ${
                  buyerType === RegistrationsBuyerType.COMPANY &&
                  'border-2 border-blue-600'
                }`}
              >
                <p className="font-semibold">Empresa</p>
                <p className="text-gray-400">El comprador es una empresa.</p>
              </button>
            </article>
          </section>
          <footer className="flex justify-end items-center w-full border-t border-t-gray-200 py-3 px-8">
            <Button
              text="Crear trámite"
              bgColor="bg-blue-600"
              hoverBgColor="bg-blue-700"
              textColor="white"
              callback={handleCreateRegistration}
              disabled={!buyerType || isLoading}
              RightIcon={CheckIcon}
              loading={isLoading}
            />
          </footer>
        </div>
      </dialog>
    );
  }

  return null;
}

import { useState, useEffect, useCallback } from 'react';
import { formChangeHandler } from '../screens/Backoffice/tools/functions/itpFormChangeHandler';
import useItp from './useItp';

export default function useBase64Calculator() {
  const {
    itpFormValue,
    setItpFormValue,
    setCc,
    setCcaa,
    itpResetValues,
    isModelLoading,
    modelError,
    modelData,
    modelRunRequest,
    modelResetValues,
    selectedModel,
    setSelectedModel,
    ccaa,
    itpData,
    isItpLoading,
    itpError,
    cc,
  } = useItp();

  const [formValue, setFormValue] = useState({
    userType: '',
    email: '',
    defaultStep: 0,
    environmentChecked: false,
    trafficReportChecked: false,
  });
  const [prefilledUrl, setPrefilledUrl] = useState('');
  const [isCopySuccess, setIsCopySucccess] = useState(false);

  const handleInitialDataChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    setPrefilledUrl('');
    setIsCopySucccess(false);
  };

  const handleChange = (e) => {
    formChangeHandler(
      setItpFormValue,
      itpFormValue,
      e.target.name,
      e.target.value,
      setCc,
    );
    setSelectedModel({});
    setCcaa('');
    itpResetValues();
    modelResetValues();
    setPrefilledUrl('');
    setIsCopySucccess(false);
  };

  const handleModelSelection = (e) => {
    const selected = modelData.cars.find(
      (item) => item.model === e.target.value,
    );
    setSelectedModel(selected);
    itpResetValues();
    setCcaa('');
    setPrefilledUrl('');
    setIsCopySucccess(false);
  };

  const isInitialDataFormComplete = () => formValue.userType && formValue.email;

  const isItpFormComplete = useCallback(
    () =>
      itpFormValue.brand && itpFormValue.enrollmentDate && itpFormValue.fuel,
    [itpFormValue],
  );

  useEffect(() => {
    if (isItpFormComplete()) {
      modelRunRequest();
    }
  }, [isItpFormComplete, modelRunRequest]);

  useEffect(() => {
    if (modelData?.cars.length) {
      setSelectedModel({});
    }
  }, [modelData, setSelectedModel]);

  return {
    itpFormValue,
    setCc,
    setCcaa,
    itpResetValues,
    isModelLoading,
    modelError,
    modelData,
    selectedModel,
    ccaa,
    itpData,
    isItpLoading,
    itpError,
    cc,
    formValue,
    prefilledUrl,
    setPrefilledUrl,
    isCopySuccess,
    setIsCopySucccess,
    handleInitialDataChange,
    handleChange,
    handleModelSelection,
    isInitialDataFormComplete,
    isItpFormComplete,
  };
}

import { InformationCircleIcon } from '@heroicons/react/solid';
import React from 'react';
import { Link } from 'react-router-dom';

export default function AddPaymentMethodAlert() {
  return (
    <div className="flex items-center gap-2 bg-red-50 p-3.5 text-red-800 rounded-lg border border-red-800 text-sm">
      <InformationCircleIcon className="w-5 h-5" />
      <p id="payment-instruction">
        <span role="alert">Debes añadir un método de pago</span> para poder
        iniciar un trámite.{' '}
        <Link
          to="/payment-method"
          className="text-blue-600 underline hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Añadir un método de pago
        </Link>
      </p>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useServices } from '../services';

export default function useAgentAssign({
  agent,
  tramitCode,
  editTramit,
  forceUpdate,
}) {
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [tramitAssignLoading, setTramitAssignLoading] = useState(false);
  const [tramitAssignError, setTramitAssignError] = useState(null);
  const [inputField, setInputField] = useState('');

  const { searchAgentsByName } = useServices();

  const handleAssignTramitToAgent = async () => {
    if (selectedAgent) {
      try {
        setTramitAssignLoading(true);
        await editTramit(tramitCode, {
          agentId: selectedAgent.id,
        });
        setInputField('');
      } catch (error) {
        setTramitAssignError(error);
      } finally {
        forceUpdate();
      }
    }
  };

  const handleRemoveAgent = async () => {
    try {
      setTramitAssignLoading(true);
      await editTramit(tramitCode, { agentId: null });
      setInputField('');
    } catch (error) {
      setTramitAssignError(error);
    } finally {
      forceUpdate();
    }
  };

  useEffect(() => {
    setTramitAssignLoading(false);
  }, [agent?.name]);

  useEffect(() => {
    const loadAgents = async () => {
      const fetchedAgent = await searchAgentsByName(inputField);
      setAgents(fetchedAgent);
    };

    if (!inputField) return;
    loadAgents();
  }, [inputField]);

  return {
    agents,
    selectedAgent,
    setSelectedAgent,
    tramitAssignLoading,
    tramitAssignError,
    inputField,
    setInputField,
    handleAssignTramitToAgent,
    handleRemoveAgent,
  };
}

import React, { useContext } from 'react';
import { BadgeCheckIcon, ExclamationIcon } from '@heroicons/react/solid';

import Thumbnail from '../../../../components/Thumbnail';
import CardButton from '../../../../components/CardButton';
import { BATE_STATUS } from '../../../../shared/bate-status';
import { GeneratedFile } from '../../../../helpers/generateCardSections';
import {
  isValidDNI,
  isValidNIE,
  isValidCIF,
} from '../../../../helpers/doiValidators';
import PlaceholderToFill from '../../../../components/PlaceholderToFill';
import SkeletonPlaceholder from '../../../../components/SkeletonPlaceholder';
import { bateDetailsContext } from '../../../../context/BateDetailsContext/bateDetailsContext';

function Card({
  title,
  subtitle,
  subtitleIcon,
  sections,
  files,
  onEdit,
  onReplace,
  hideEditButton = false,
}: Readonly<{
  title: string;
  subtitle: string;
  subtitleIcon: JSX.Element;
  sections: {
    id: string;
    title: string;
    data: {
      label: string;
      displayValue: string;
      value: string;
    }[];
  }[];
  files: GeneratedFile[];
  onEdit: () => void;
  onReplace: () => void;
  hideEditButton?: boolean;
}>) {
  const { bateLoading, bateTramit, forceUpdate } =
    useContext(bateDetailsContext);

  const showEditButton =
    !hideEditButton &&
    ![BATE_STATUS.PRESENTED_TO_DGT, BATE_STATUS.FINISHED_BY_DGT].includes(
      bateTramit?.bate.status,
    );

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-1">
              {title}
            </h3>
            {!bateLoading ? (
              <p className="mt-1 max-w-2xl text-sm text-gray-500 flex items-center gap-1">
                {subtitleIcon} {subtitle}
              </p>
            ) : (
              <SkeletonPlaceholder width={20} height={5} />
            )}
          </div>
          {showEditButton && (
            <CardButton onEdit={onEdit} onReplace={onReplace} title={title} />
          )}
        </div>
      </div>
      {sections.map((section) => (
        <div key={section.title} className="mb-3">
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-sm text-gray-500">
                {section.title}
              </span>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
              {section.data?.map((e) => (
                <React.Fragment key={e.label}>
                  {bateLoading && (
                    <div className="sm:col-span-1" key={e.label}>
                      <dt className="text-sm font-medium text-gray-500 mb-1">
                        {e.displayValue}
                      </dt>
                      <SkeletonPlaceholder width={20} height={5} />
                    </div>
                  )}
                  {!bateLoading && (
                    <div className="sm:col-span-1" key={e.label}>
                      <dt className="text-sm font-medium text-gray-500">
                        {e.displayValue}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 flex">
                        {e.value || '---'}
                        {e.label === 'dni' &&
                          e.value &&
                          (isValidDNI(e.value) || isValidNIE(e.value) ? (
                            <BadgeCheckIcon className="text-teal-500 h-5 ml-1" />
                          ) : (
                            <ExclamationIcon className="text-red-500 h-5 ml-1" />
                          ))}
                        {e.label === 'nif' &&
                          e.value &&
                          (isValidCIF(e.value) ? (
                            <BadgeCheckIcon className="text-teal-500 h-5 ml-1" />
                          ) : (
                            <ExclamationIcon className="text-red-500 h-5 ml-1" />
                          ))}
                      </dd>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </dl>
          </div>
        </div>
      ))}
      {Boolean(files?.length) && (
        <div className="mb-3">
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-sm text-gray-500">
                Documentos
              </span>
            </div>
          </div>
          <div className="flex">
            {files
              .filter(Boolean)
              .map((f, i) =>
                f.img ? (
                  <Thumbnail
                    uri={f.img}
                    key={f.img}
                    alt={`document-${i}`}
                    type={f.type}
                  />
                ) : (
                  <PlaceholderToFill
                    key={f.type}
                    owner={f.owner}
                    fileType={f.type}
                    forceUpdate={forceUpdate}
                  />
                ),
              )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Card;

import React, { ReactNode, createContext } from 'react';
import useShipment, {
  ShipmentsData,
} from '../../Modules/Shipments/hooks/useShipment';
import { Registration, Transaction } from '../../types/types';

export const shipmentsContext = createContext<ShipmentsData>(
  {} as ShipmentsData,
);

export function ShipmentsContextProvider({
  entityId,
  entity,
  children,
}: {
  entityId: string;
  entity: Transaction | Registration;
  children: ReactNode;
}) {
  const shipmentHookData = useShipment(entityId, entity);

  return (
    <shipmentsContext.Provider value={shipmentHookData}>
      {children}
    </shipmentsContext.Provider>
  );
}

import React, { useEffect, useState } from 'react';

import { useServices } from '../../../services';
import usePagination from '../../../hooks/usePagination';
import RegistrationTableList from './components/RegistrationTableList';
import Spinner from '../../../components/Spinner';

export default function Registrations() {
  const [registrations, setRegistrations] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, goNext, goPrev] = usePagination();
  const [loader, setLoader] = useState(true);
  const { getAllRegistrations } = useServices();

  useEffect(() => {
    const fetchRegistrations = async () => {
      const response = await getAllRegistrations(page);
      setRegistrations(response.registrations);
      setTotal(response.count);
      setLoader(false);
    };
    fetchRegistrations();
  }, [page]);

  if (loader) {
    return (
      <div className="w-full h-full min-h-[500px] flex justify-center items-center">
        <Spinner color="text-blue-700" size={10} marginTop={28} />
      </div>
    );
  }

  return (
    <>
      <span className="flex justify-start items-center gap-x-3 z-0 mb-4 mt-8 rounded-md bg-gray-50">
        <span className="uppercase text-gray-500 px-6 py-4 font-semibold text-xs">
          Filtros
        </span>
      </span>
      <div className="flex flex-col gap-5">
        <RegistrationTableList
          data={registrations}
          page={page}
          goNext={goNext}
          goPrev={goPrev}
          total={total}
        />
      </div>
    </>
  );
}

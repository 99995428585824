import { CalendarIcon } from '@heroicons/react/outline';
import React, { useRef, useState } from 'react';

import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import es from 'date-fns/locale/es';
import useClickOutside from '../../hooks/useClickOutside';

type DateRangePickerProps = {
  date: DateRange;
  setDate: (date: DateRange) => void;
};

function DateRangePicker({ date, setDate }: DateRangePickerProps) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const ref = useRef();

  useClickOutside(ref, () => {
    setDatePickerOpen(false);
  });

  return (
    <div className="relative">
      <button
        type="button"
        className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium black focus:outline-none focus-visible:ring-2 bg-white border border-gray-300 focus-visible:ring-white focus-visible:ring-opacity-75 items-center gap-2"
        onClick={() => setDatePickerOpen(!datePickerOpen)}
      >
        Fecha <CalendarIcon className="h-4 text-gray-600" />
      </button>
      {datePickerOpen && (
        <div
          className="absolute left-0 top-15 bg-white rounded-md shadow-md z-10"
          ref={ref}
        >
          <DayPicker
            mode="range"
            fromDate={new Date('2021-01-01')}
            toDate={
              new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            }
            captionLayout="dropdown"
            defaultMonth={new Date()}
            selected={date}
            onSelect={setDate}
            locale={es}
            modifiersStyles={{
              selected: {
                backgroundColor: 'rgb(79, 70, 229)',
                color: 'white',
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

export default DateRangePicker;

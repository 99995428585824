import React, { useContext, useState, useMemo } from 'react';
import {
  InformationCircleIcon,
  DocumentDuplicateIcon,
  ChatIcon,
  ReceiptTaxIcon,
  PencilIcon,
  TruckIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';

import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import Documents from '../Modules/Documents';
import GeneralInfo from './tabs/GeneralInfo/GeneralInfo';
import TransferDetailsHeader from './components/TransferDetailsHeader/TransferDetailsHeader';
import {
  transferDetailsContext,
  TransferDetailsContextProvider,
} from '../context/TransferDetailsContext/transferDetailsContext';
import ItpTable from './tabs/ITP/ItpTable';
import Comments from '../Modules/Comments/Comments';
import SignatureDetails from '../Modules/SignatureDetails/SignatureDetails';
import { ShipmentsContextProvider } from '../context/ShipmentsContext/shipmentsContext';
import { Company } from '../types/types';
import Shipments from '../Modules/Shipments/Shipments';
import MechanicalWarranty from './tabs/MechanicalWarranty';

export type Tab = {
  id: keyof TabsType;
  title: string;
  icon: JSX.Element;
};

interface IObjectKeys {
  [key: string]: Tab;
}

export interface TabsType extends IObjectKeys {
  information?: Tab;
  documents?: Tab;
  digitalSignature?: Tab;
  taxes?: Tab;
  shipment?: Tab;
  comments?: Tab;
}

function TransferDetails() {
  const tabs: TabsType = {
    information: {
      id: 'information',
      title: 'Información',
      icon: (
        <InformationCircleIcon
          className="h-5 w-5 mb-2.5"
          data-testid="svg-information"
        />
      ),
    },
    digitalSignature: {
      id: 'digitalSignature',
      title: 'Firmas',
      icon: (
        <PencilIcon
          className="h-5 w-5 mb-2.5"
          data-testid="svg-documentation"
        />
      ),
    },
    documents: {
      id: 'documents',
      title: 'Documentación',
      icon: (
        <DocumentDuplicateIcon
          className="h-5 w-5 mb-2.5"
          data-testid="svg-documentation"
        />
      ),
    },
    taxes: {
      id: 'taxes',
      title: 'Impuestos',
      icon: (
        <ReceiptTaxIcon className="h-5 w-5 mb-2.5" data-testid="svg-taxes" />
      ),
    },
    shipment: {
      id: 'shipment',
      title: 'Envíos',
      icon: <TruckIcon className="h-5 w-5 mb-2.5" data-testid="svg-taxes" />,
    },
    comments: {
      id: 'comments',
      title: 'Comentarios',
      icon: <ChatIcon className="h-5 w-5 mb-2.5" data-testid="svg-comments" />,
    },
    mechanicalWarranty: {
      id: 'mechanicalWarranty',
      title: 'Garantía',
      icon: <ShieldCheckIcon className="h-5 w-5 mb-2.5" />,
    },
  };

  const [currentTab, setCurrentTab] = useState<Tab>(tabs.information);

  const { companyInfo } = useContext(userInfoContext);
  const { transfer, forceUpdate } = useContext(transferDetailsContext);

  const getComponent = (tabId: keyof TabsType): JSX.Element => {
    if (tabId === 'documents') {
      return (
        <Documents
          tramit={transfer}
          entityType="transaction"
          forceUpdate={forceUpdate}
        />
      );
    }
    if (tabId === 'digitalSignature') {
      return (
        <SignatureDetails
          tramit={transfer}
          entityType="transaction"
          entityCode={transfer?.transaction?.transactionCode}
        />
      );
    }
    if (tabId === 'taxes') return <ItpTable />;
    if (tabId === 'shipment') return <Shipments />;
    if (tabId === 'comments') {
      return (
        <Comments
          entityId={transfer?.transaction?.id}
          tramitCode={transfer?.transaction?.transactionCode}
          tramitType="TRANSACTION"
        />
      );
    }
    if (tabId === 'mechanicalWarranty') {
      return <MechanicalWarranty buyer={transfer.buyer} car={transfer.car} />;
    }
    return <GeneralInfo />;
  };

  const handleChangeTab = (tabId: string) => () => {
    setCurrentTab(Object.values(tabs).find((tab) => tab.id === tabId));
  };

  const transactionType = useMemo(() => {
    if ((transfer?.buyer as Company)?.nif === companyInfo?.nif) return 'Compra';
    if ((transfer?.seller as Company)?.nif === companyInfo?.nif) {
      if (transfer?.transaction.isBatecom) return 'Venta - Batecom';
      return 'Venta';
    }
    return 'Intermediación';
  }, [transfer, companyInfo]);

  return (
    <ShipmentsContextProvider
      entityId={transfer?.transaction?.id}
      entity={transfer?.transaction}
    >
      <div className="bg-gray-100 py-10 flex justify-center min-h-screen">
        <div className="w-2/3">
          <TransferDetailsHeader
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
            tabs={tabs}
            transactionType={transactionType}
          />
          {getComponent(currentTab.id)}
        </div>
      </div>
    </ShipmentsContextProvider>
  );
}

export default function TransferDetailsWrapper() {
  return (
    <TransferDetailsContextProvider>
      <TransferDetails />
    </TransferDetailsContextProvider>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import { CheckIcon } from '@heroicons/react/outline';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../../components/Button';
import { toasterContext } from '../../../../../context/ToasterContext/ToasterContext';
import { transferDetailsContext } from '../../../../../context/TransferDetailsContext/transferDetailsContext';
import { userInfoContext } from '../../../../../context/UserInfoContext/UserInfoContext';
import { getPaymentMethods } from '../../../../../services/stripe';
import { BATE_STATUS } from '../../../../../shared/bate-status';
import { STRIPE_CARD_DECLINE_CODES } from '../../../../../enums/stripeCardDeclineCodes';

function PresentToAgencyButton() {
  const [loading, setLoading] = useState<boolean>(false);

  const { setToasterData } = useContext(toasterContext);
  const { transfer, forceUpdate } = useContext(transferDetailsContext);
  const { accountInfo } = useContext(userInfoContext);

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const checkPaymentMethod = async (token: string) => {
    const { cards, sepas } = await getPaymentMethods(
      accountInfo.organizationId,
      token,
    );
    const method = cards[0] || sepas[0];
    if (!method) {
      setToasterData({
        type: 'ERROR',
        title: 'No hay ningún método de pago',
        message:
          'Debes añadir un método de pago para poder presentar a gestoría',
      });
      navigate('/payment-method');
    }
  };

  const handleMarkTransactionAsReady = async (token: string) => {
    try {
      setLoading(true);
      await checkPaymentMethod(token);
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/v1/internal/transactions/${transfer.transaction.transactionCode}/ready`,
        {
          orgId: accountInfo.organizationId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      forceUpdate();
    } catch (error) {
      const possibleStripeError =
        STRIPE_CARD_DECLINE_CODES[
          error.response?.data?.error as keyof typeof STRIPE_CARD_DECLINE_CODES
        ];

      if (possibleStripeError) {
        setToasterData({
          type: 'ERROR',
          title: possibleStripeError.title,
          message: possibleStripeError.nextSteps,
        });
      } else {
        setToasterData({
          type: 'ERROR',
          title: 'Ha ocurrido un error al presentar a gestoría',
          message: 'Revisa los datos del trámite o contacta con nosotros.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMarkBatecomAsReady = async (token: string, batecomId: string) => {
    try {
      setLoading(true);
      await checkPaymentMethod(token);
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/v1/internal/batecom/${batecomId}/ready`,
        {
          orgId: accountInfo.organizationId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      forceUpdate();
    } catch (error) {
      const possibleStripeError =
        STRIPE_CARD_DECLINE_CODES[
          error.response?.data?.error as keyof typeof STRIPE_CARD_DECLINE_CODES
        ];

      if (possibleStripeError) {
        setToasterData({
          type: 'ERROR',
          title: possibleStripeError.title,
          message: possibleStripeError.nextSteps,
        });
      } else {
        setToasterData({
          type: 'ERROR',
          title: 'Ha ocurrido un error al presentar a gestoría',
          message: 'Revisa los datos del trámite o contacta con nosotros.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const choosePresenterHandler = async (): Promise<void> => {
    const token = await getAccessTokenSilently();
    if (!transfer.transaction.isBatecom)
      return handleMarkTransactionAsReady(token);
    const { data: relatedBate } = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/v1/transaction/${transfer?.transaction.id}/bate`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const isBatePresented =
      relatedBate.status !== BATE_STATUS.PENDING_DOCUMENTATION;
    if (isBatePresented) return handleMarkTransactionAsReady(token);
    return handleMarkBatecomAsReady(token, relatedBate.batecomId);
  };

  return (
    <Button
      text="Presentar"
      bgColor="bg-blue-700"
      hoverBgColor="bg-blue-500"
      textColor="white"
      border="border-2"
      RightIcon={CheckIcon}
      textSm
      callback={choosePresenterHandler}
      loading={loading}
      additionalClasses="whitespace-nowrap"
    />
  );
}

export default PresentToAgencyButton;

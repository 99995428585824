import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { FormErrorsType } from './index';
import { OfferFormStateType } from '../index';

type OfferProps = {
  formErrors: FormErrorsType;
  setFormErrors: Dispatch<SetStateAction<FormErrorsType>>;
  offerFormState: OfferFormStateType;
  setOfferFormState: Dispatch<SetStateAction<OfferFormStateType>>;
};

export default function OfferForm({
  formErrors,
  setFormErrors,
  offerFormState,
  setOfferFormState,
}: OfferProps) {
  const kmsValidation = (kms: string | number) =>
    Number(kms) < 0 || Number(kms) > 200000;
  const engineCapacityValidation = (cc: string | number) =>
    Number(cc) < 0 || Number(cc) > 4000;
  const nineYearsAgoValidation = (date: string | Date) => {
    const dateToCheck = new Date(date);
    const now = new Date();
    const nineYearsAgo = new Date();
    nineYearsAgo.setFullYear(now.getFullYear() - 9);
    return dateToCheck > now || dateToCheck < nineYearsAgo;
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'kilometers') {
      if (kmsValidation(value)) {
        setFormErrors((prevState) => ({
          ...prevState,
          kilometers: true,
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          kilometers: false,
        }));
      }
    }

    if (name === 'engineCapacity') {
      if (engineCapacityValidation(value)) {
        setFormErrors((prevState) => ({
          ...prevState,
          engineCapacity: true,
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          engineCapacity: false,
        }));
      }
    }

    if (name === 'price') {
      if (Number(value) < 0) {
        setFormErrors((prevState) => ({
          ...prevState,
          price: true,
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          price: false,
        }));
      }
    }

    if (name === 'carRegistrationDate') {
      if (nineYearsAgoValidation(value)) {
        setFormErrors((prevState) => ({
          ...prevState,
          carRegistrationDate: true,
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          carRegistrationDate: false,
        }));
      }
    }

    setOfferFormState((prevState: OfferFormStateType) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (nineYearsAgoValidation(offerFormState.carRegistrationDate)) {
      setFormErrors((prevState) => ({
        ...prevState,
        carRegistrationDate: true,
      }));
    }
    if (engineCapacityValidation(offerFormState.engineCapacity)) {
      setFormErrors((prevState) => ({
        ...prevState,
        engineCapacity: true,
      }));
    }
    if (kmsValidation(offerFormState.engineCapacity)) {
      setFormErrors((prevState) => ({
        ...prevState,
        kilometers: true,
      }));
    }
  }, []);

  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="flex justify-between items-center">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            1 - Calcular garantía mecánica
          </h3>
        </div>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3">
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="carRegistration"
                className="block text-sm font-medium text-gray-700"
              >
                Matrícula
              </label>
              <div className="mt-1">
                <input
                  value={offerFormState.carRegistration}
                  onChange={handleFormChange}
                  type="text"
                  name="carRegistration"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {!offerFormState.carRegistration && (
                <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                  Campo obligatorio
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="carRegistrationDate"
                className="block text-sm font-medium text-gray-700"
              >
                Fecha de matriculación
              </label>
              <div className="mt-1">
                <input
                  value={offerFormState.carRegistrationDate}
                  onChange={handleFormChange}
                  type="date"
                  max={new Date().toISOString().split('T')[0]}
                  name="carRegistrationDate"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
                {formErrors.carRegistrationDate && (
                  <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                    Máximo 9 años de antigüedad.
                  </p>
                )}
                {!offerFormState.carRegistrationDate && (
                  <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                    Campo obligatorio
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="price"
                className="block text-sm font-medium text-gray-700"
              >
                Precio de venta
              </label>
              <div className="mt-1">
                <input
                  value={offerFormState.price}
                  onChange={handleFormChange}
                  type="number"
                  name="price"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
                {formErrors.price && (
                  <p className="mt-2 -mb-6 text-xs ml-1 text-red-600">
                    El precio no puede ser negativo
                  </p>
                )}
                {!offerFormState.price && (
                  <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                    Campo obligatorio
                  </p>
                )}
              </div>
            </div>
          </div>
        </dl>
        <dl className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 mt-6 mb-3">
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="engineCapacity"
                className="block text-sm font-medium text-gray-700"
              >
                CC
              </label>
              <div className="mt-1">
                <input
                  value={offerFormState.engineCapacity}
                  onChange={handleFormChange}
                  type="number"
                  name="engineCapacity"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
                {formErrors.engineCapacity && (
                  <p className="mt-2 -mb-6 text-xs ml-1 text-red-600">
                    Máximo admitido hasta 4000 cc
                  </p>
                )}
                {!offerFormState.engineCapacity && (
                  <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                    Campo obligatorio
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-1">
            <div>
              <label
                htmlFor="kilometers"
                className="block text-sm font-medium text-gray-700"
              >
                Kms
              </label>
              <div className="mt-1">
                <input
                  value={offerFormState.kilometers}
                  onChange={handleFormChange}
                  type="number"
                  name="kilometers"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
                {formErrors.kilometers && (
                  <p className="mt-2 -mb-6 text-xs ml-1 text-red-600">
                    Máximo admitido hasta 200.000 Kms
                  </p>
                )}
                {!offerFormState.kilometers && (
                  <p className="mt-1 -mb-6 text-xs ml-1 text-red-600">
                    Campo obligatorio
                  </p>
                )}
              </div>
            </div>
          </div>
        </dl>
      </div>
    </>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@headlessui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BaseModal from '../../../../components/BaseModal';
import { classNames } from '../../../../helpers';

const SUBMIT_STATE = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

function DelegateSignatureModal({
  open,
  setOpen,
  buyer,
  seller,
  dataForDelegation,
}) {
  const [selectedUser, setSelectedUser] = useState('buyer');
  const [emailAndName, setEmailAndName] = useState({
    email: buyer.email,
    name: buyer.name,
  });
  const [submitState, setSubmitState] = useState(SUBMIT_STATE.NOT_SUBMITTED);
  const { getAccessTokenSilently } = useAuth0();

  const handleSelectUser = (e) => {
    setSelectedUser(e.target.value);
    if (e.target.value === 'buyer') {
      setEmailAndName({
        email: buyer.email || '',
        name: buyer.name || '',
      });
    } else {
      setEmailAndName({
        email: seller.email || '',
        name: seller.name || '',
      });
    }
  };

  useEffect(() => {
    if (!open) {
      setSubmitState(SUBMIT_STATE.NOT_SUBMITTED);
    }
  }, [open]);

  const handleSubmit = async () => {
    setSubmitState(SUBMIT_STATE.LOADING);
    try {
      let role = '';

      if (selectedUser === 'buyer' && buyer.isPro) {
        role = 'pro';
      } else if (selectedUser === 'buyer' && !buyer.isPro) {
        role = 'buyer';
      } else if (selectedUser === 'seller' && seller.isPro) {
        role = 'pro';
      } else if (selectedUser === 'seller' && !seller.isPro) {
        role = 'seller';
      }

      const payload = {
        email: emailAndName.email,
        name: emailAndName.name,
        serviceId: dataForDelegation.serviceId,
        role,
      };

      const token = await getAccessTokenSilently();

      await axios.put(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/delegate/${dataForDelegation.documentId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setOpen(false);
    } catch (err) {
      setSubmitState(SUBMIT_STATE.ERROR);
    }
  };

  return (
    <BaseModal isOpen={open} onClose={setOpen} overflowVisible={false}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Delegar firma digital
          </Dialog.Title>
          <div className="mx-6">
            <div className="mt-4">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Selecciona al próximo usuario que tenga que firmar y cuya firma
                quieras delegar
              </label>
              <select
                id="location"
                name="location"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={selectedUser}
                onChange={handleSelectUser}
              >
                <option value="buyer">Comprador</option>
                <option value="seller">Vendedor</option>
              </select>
            </div>
            <p className="mt-4 text-sm">
              Revisa los datos y modifica el email si es necesario
            </p>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sr-only"
              >
                Nombre
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 "
                  placeholder="Nombre"
                  value={emailAndName.name}
                  onChange={(e) =>
                    setEmailAndName({
                      ...emailAndName,
                      name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 sr-only"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  "
                  placeholder="Email: you@example.com"
                  value={emailAndName.email}
                  onChange={(e) =>
                    setEmailAndName({
                      ...emailAndName,
                      email: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 flex flex-col items-stretch">
        <button
          type="button"
          className={classNames(
            'inline-flex mx-6 mb-6 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50',
            submitState === SUBMIT_STATE.LOADING && 'cursor-not-allowed',
            submitState === SUBMIT_STATE.ERROR &&
              'cursor-not-allowed bg-red-600 hover:bg-red-600',
          )}
          onClick={handleSubmit}
          disabled={
            !emailAndName.email ||
            !emailAndName.name ||
            submitState === SUBMIT_STATE.LOADING ||
            submitState === SUBMIT_STATE.ERROR
          }
        >
          {submitState === SUBMIT_STATE.ERROR ? 'Error' : 'Delegar firma'}
        </button>
      </div>
    </BaseModal>
  );
}

export default DelegateSignatureModal;

/* eslint-disable react/jsx-props-no-spreading */
import { DuplicateIcon } from '@heroicons/react/solid';
import { Buffer } from 'buffer';
import Select from '../../../../components/select';
import {
  formStepEnums,
  userTypeEnums,
  yesNoEnums,
} from '../../../../helpers/enums';
import EmailInput from '../../../../components/emailInput';
import ItpForm from './itpForm';
import useBase64Calculator from '../../../../hooks/useBase64Calculator';

export default function Base64Calculator() {
  const {
    itpFormValue,
    setCc,
    setCcaa,
    itpResetValues,
    isModelLoading,
    modelError,
    modelData,
    selectedModel,
    ccaa,
    itpData,
    isItpLoading,
    itpError,
    cc,
    formValue,
    prefilledUrl,
    setPrefilledUrl,
    isCopySuccess,
    setIsCopySucccess,
    handleInitialDataChange,
    handleChange,
    handleModelSelection,
    isInitialDataFormComplete,
    isItpFormComplete,
  } = useBase64Calculator();

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...formValue,
      ...itpFormValue,
      itpPrice: itpData.itp,
      model: selectedModel.model,
      ccaa,
      cc,
      enrollment: {
        day: itpFormValue.enrollmentDate.split('-')[2],
        month: itpFormValue.enrollmentDate.split('-')[1],
        year: itpFormValue.enrollmentDate.split('-')[0],
      },
    };
    const base64StringEncode = Buffer.from(JSON.stringify(payload)).toString(
      'base64',
    );
    setPrefilledUrl(
      `https://swipoo.com/cambio-de-nombre/?form_data=${base64StringEncode}`,
    );
  };

  const copyToClipboard = (text) => navigator.clipboard.writeText(text);

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-8 divide-y divide-gray-200 border-2 px-8 pb-4 rounded-lg w-full h-[fit-content]  min-h-[230px]"
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:pt-4 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Generador de enlace con widget precargado
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Selecciona los campos para generar un enlace del widget
              precargado.
            </p>
          </div>
          <Select
            label="Tipo de Usuario"
            stateName="userType"
            options={userTypeEnums}
            value={formValue.userType}
            handleChange={handleInitialDataChange}
          />
          {formValue.userType && (
            <EmailInput
              label="Email"
              stateName="email"
              value={formValue.email}
              required
              handleChange={handleInitialDataChange}
            />
          )}
          {formValue.email && (
            <>
              <Select
                label="Paso predeterminado del formulario"
                stateName="defaultStep"
                options={formStepEnums}
                value={formValue.defaultStep}
                handleChange={handleInitialDataChange}
              />
              <Select
                label="Distintivo Medioambiental"
                stateName="environmentChecked"
                options={yesNoEnums}
                value={formValue.environmentChecked}
                handleChange={handleInitialDataChange}
              />
              <Select
                label="Informe de Tráfico"
                stateName="trafficReportChecked"
                options={yesNoEnums}
                value={formValue.trafficReportChecked}
                handleChange={handleInitialDataChange}
              />
            </>
          )}
          {isInitialDataFormComplete() && (
            <ItpForm
              itpFormValue={itpFormValue}
              handleChange={handleChange}
              setCc={setCc}
              setCcaa={setCcaa}
              itpResetValues={itpResetValues}
              isModelLoading={isModelLoading}
              modelError={modelError}
              modelData={modelData}
              isFormComplete={isItpFormComplete}
              selectedModel={selectedModel}
              handleModelSelection={handleModelSelection}
              ccaa={ccaa}
              isItpLoading={isItpLoading}
              itpError={itpError}
              cc={cc}
              setPrefilledUrl={setPrefilledUrl}
              setIsCopySucccess={setIsCopySucccess}
            />
          )}
          <div className="flex flex-col gap-y-4 items-center">
            {isItpFormComplete() && isInitialDataFormComplete && ccaa && (
              <button
                type="submit"
                className="inline-flex w-full justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Generar URL
              </button>
            )}
            {isCopySuccess && <p className="text-indigo-600">URL Copiada!</p>}
            {prefilledUrl && (
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  copyToClipboard(prefilledUrl);
                  setIsCopySucccess(true);
                }}
                onKeyDown={() => {
                  copyToClipboard(prefilledUrl);
                  setIsCopySucccess(true);
                }}
                className="w-full border-2 p-4 rounded-lg flex justify-center gap-x-4 text-sm cursor-pointer"
              >
                <p>Copiar URL</p>
                <DuplicateIcon
                  className="mr-1 inline h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

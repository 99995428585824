/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import Tool from './tool';

export default function Column({ column, tools }) {
  return (
    <div className="flex flex-col w-[50%] flex-grow gap-4">
      <Droppable droppableId={column.id}>
        {(provided, droppableSnapshot) => (
          <div
            className={`${
              droppableSnapshot.isDraggingOver && 'bg-blue-400'
            } transition-all`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tools.map((tool, index) => (
              <Draggable key={tool.id} draggableId={tool.id} index={index}>
                {(draggableProvided, snapshot) => (
                  <Tool
                    toolComponent={tool.component}
                    innerRef={draggableProvided.innerRef}
                    provided={draggableProvided}
                    snapshot={snapshot}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

import { ChevronLeftIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect } from 'react';
import Button from '../../../../components/Button';
import {
  createTransferContext,
  Status,
  FormValueTypeIfIntermediary,
} from '../../../../context/CreateTransferContext/CreateTransferContext';
import { userInfoContext } from '../../../../context/UserInfoContext/UserInfoContext';

export default function Footer({
  toggleModalOpen,
}: {
  toggleModalOpen: () => void;
}): JSX.Element {
  const {
    formValue,
    currentStep,
    totalSteps,
    stepForward,
    stepBackward,
    submitForm,
    status,
  } = useContext(createTransferContext);
  const { companyInfo } = useContext(userInfoContext);

  const inLastStepBateIsNotSelected =
    formValue.userType === 'SELLER_WITH_TEMP_LEAVE' && !formValue.batecomId;

  const isNotIntermediaryAndCounterpartIsSelected =
    formValue.userType !== 'INTERMEDIARY' && formValue.counterPartType;

  const isIntermediaryAndBuyerAndSellerAreSelected =
    formValue.userType === 'INTERMEDIARY' &&
    (formValue as FormValueTypeIfIntermediary).buyerType &&
    (formValue as FormValueTypeIfIntermediary).sellerType;

  const stepTwoButtonIsDisabled = !(
    isNotIntermediaryAndCounterpartIsSelected ||
    isIntermediaryAndBuyerAndSellerAreSelected
  );

  const handleSubmitForm = async () => {
    await submitForm(companyInfo.id);
  };

  useEffect(() => {
    if (status === Status.COMPLETED) {
      toggleModalOpen();
    }
  }, [status]);

  return (
    <div
      id="modal-footer-container"
      className="flex justify-between items-center h-[65px] w-full border-t border-t-gray-200 px-[30px]"
    >
      <div className="w-[50%]">
        {currentStep !== 1 && (
          <Button
            text="Atrás"
            bgColor="bg-blue-50"
            hoverBgColor="bg-blue-50"
            textColor="blue-600"
            callback={stepBackward}
            LeftIcon={ChevronLeftIcon}
          />
        )}
      </div>
      <div className="w-[50%] flex justify-end">
        {currentStep === 1 && (
          <Button
            text="Siguiente"
            bgColor="bg-blue-600"
            hoverBgColor="bg-blue-700"
            textColor="white"
            callback={stepForward}
            disabled={!formValue.userType}
          />
        )}
        {currentStep === 2 &&
          formValue.userType === 'SELLER_WITH_TEMP_LEAVE' && (
            <Button
              text="Siguiente"
              bgColor="bg-blue-600"
              hoverBgColor="bg-blue-700"
              textColor="white"
              callback={stepForward}
              disabled={stepTwoButtonIsDisabled}
            />
          )}
        {currentStep === totalSteps && (
          <Button
            text="Crear trámite"
            bgColor="bg-blue-600"
            hoverBgColor="bg-blue-700"
            textColor="white"
            callback={handleSubmitForm}
            disabled={stepTwoButtonIsDisabled || inLastStepBateIsNotSelected}
            RightIcon={CheckIcon}
            loading={status === Status.LOADING}
          />
        )}
      </div>
    </div>
  );
}

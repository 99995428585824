import React, { useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TrashIcon } from '@heroicons/react/outline';

import { useNavigate } from 'react-router-dom';

import BateStatusStepper from './components/BateStatusStepper';
import Tabs from '../../components/Tabs';
import Title from '../../components/Title';
import { Tab, TabsType } from '../index';
import { BATE_STATUS } from '../../shared/bate-status';
import { BateTramit } from '../../types/types';
import PresentToAgencyButton from './components/PresentToAgencyButton';
import ContinueWithBatecomModal from './components/ContinueWithBatecomModal';
import ContinueWithBatecomButton from './components/ContinueWithBatecomButton';
import ContextMenuButton, {
  ContextMenuOptionType,
} from '../../components/ContextMenuButton';
import { editBate } from '../../services/services';
import { toasterContext } from '../../context/ToasterContext/ToasterContext';
import Incidents from '../../components/Incidents/Incidents';

type BateDetailsHeaderPropsType = {
  currentTab: Tab;
  tabs: TabsType;
  isLoading: boolean;
  handleChangeTab: (tabId: string) => () => void;
  bateTramit: BateTramit;
  showContinueWithBatecom: boolean;
};

export default function BateDetailsHeader({
  currentTab,
  handleChangeTab,
  tabs,
  isLoading,
  bateTramit,
  showContinueWithBatecom,
}: Readonly<BateDetailsHeaderPropsType>) {
  const [showModal, setShowModal] = useState(false);

  const { setToasterData } = useContext(toasterContext);

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const openBuyerTypeModal = () => {
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const contextMenuOptions: ContextMenuOptionType[] = [
    {
      id: 1,
      label: 'Anular',
      RightIcon: TrashIcon,
      isVisible:
        [
          BATE_STATUS.PENDING_DOCUMENTATION,
          BATE_STATUS.REVIEW_PENDING_DOCUMENTATION,
        ].includes(bateTramit?.bate?.status) && showContinueWithBatecom,
      onClick: async () => {
        try {
          const token = await getAccessTokenSilently();
          await editBate(token, bateTramit?.bate?.bateCode, {
            status: BATE_STATUS.CANCELED,
          });
          navigate('/transfers');
        } catch (e) {
          setToasterData({
            type: 'ERROR',
            title: 'Error',
            message: 'Ha ocurrido un error al anular la bate',
          });
        }
      },
    },
  ];

  return (
    <>
      {showContinueWithBatecom && (
        <ContinueWithBatecomModal
          bateTramit={bateTramit}
          showModal={showModal}
          closeModal={closeModal}
        />
      )}
      <div className="flex flex-col justify-between gap-y-6">
        <div className="flex justify-between">
          <Title
            isLoading={isLoading}
            tramitSubtitleType="Compra - Batecom"
            creationDate={bateTramit?.bate?.createdAt}
            vehicle={bateTramit?.car}
          />
          <div className="flex items-start gap-x-4">
            <ContextMenuButton contextMenuOptions={contextMenuOptions} />
            <ContinueWithBatecomButton
              openBuyerTypeModal={openBuyerTypeModal}
              showContinueWithBatecom={showContinueWithBatecom}
            />
            {BATE_STATUS.PENDING_DOCUMENTATION === bateTramit?.bate.status && (
              <PresentToAgencyButton />
            )}
          </div>
        </div>

        <BateStatusStepper status={bateTramit?.bate?.status} />
        <Incidents entityId={bateTramit?.bate?.id} />
        <Tabs
          currentTab={currentTab}
          handleChangeTab={handleChangeTab}
          tabs={tabs}
        />
      </div>
    </>
  );
}

import { useState } from 'react';

export default function ItpRangeFilter({ handleFilter, searchParams }) {
  const [minItp, setMinItp] = useState(searchParams.get('minItp') || '');
  const [maxItp, setMaxItp] = useState(searchParams.get('maxItp') || '');
  const handleMinItp = (e) => {
    if (e.target.value >= 0) {
      setMinItp(e.target.value);
      handleFilter('minItp', e.target.value);
    }
  };
  const handleMaxItp = (e) => {
    setMaxItp(e.target.value);
    handleFilter('maxItp', e.target.value);
  };

  return (
    <div className="ml-4 flex items-center justify-center gap-x-4 bg-gray-50 rounded-md py-2 px-3">
      <span className="text-sm font-medium">ITP:</span>
      <div className="flex items-center justify-center gap-x-2">
        <span className="text-sm">Desde</span>
        <input
          className="rounded-md border w-20 border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          type="number"
          min="0"
          value={minItp}
          onChange={handleMinItp}
        />
      </div>
      <div className="flex items-center justify-center gap-x-2">
        <span className="text-sm">Hasta</span>
        <input
          className="rounded-md border w-20 border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          type="number"
          value={maxItp}
          onChange={handleMaxItp}
        />
      </div>
    </div>
  );
}

/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useServices } from '../../../services';
import usePagination from '../../../hooks/usePagination';
import AgentsTableList from './components/AgentsTableList';
import Spinner from '../../../components/Spinner';

export default function Agents() {
  const [page, goNext, goPrev] = usePagination();
  const [loader, setLoader] = useState(true);
  const [agents, setAgents] = useState([]);
  const [searchParams] = useSearchParams();

  const { getAllAgents } = useServices();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchTransfers = async () => {
      const response = await getAllAgents({
        page,
        signal,
      });
      setAgents(response);
      setLoader(false);
    };

    fetchTransfers();

    return () => {
      controller.abort();
    };
  }, [page, getAllAgents, searchParams]);

  return loader ? (
    <div className="w-full h-full min-h-[500px] flex justify-center items-center">
      <Spinner color="text-blue-700" size={10} marginTop={28} />
    </div>
  ) : (
    <AgentsTableList
      data={agents}
      page={page}
      goNext={goNext}
      goPrev={goPrev}
    />
  );
}

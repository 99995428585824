import { CheckIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { statusEnums } from '../../../../helpers/enums';
import { parseDateHourYear } from '../../../../helpers';
import PaginationFooter from '../../../../components/PaginationFooter';

function BatecomsTableList({
  data,
  page,
  goNext,
  goPrev,
  handleDateSort,
  dateSort,
  total,
}) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Código de referencia
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Matrícula
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Cuenta
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tipo
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Estado
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    PROV / SOL BAJA
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={handleDateSort}
                  >
                    Fecha {dateSort === 'DESC' ? '▼' : '▲'}
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Ver</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((trans, i) => {
                  const transStatus = statusEnums.find(
                    (step) => step.id === trans.status,
                  );
                  return (
                    <tr
                      key={trans.tramitCode}
                      className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                        {trans.tramitCode}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {trans.plate ?? '---'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {trans.accountName ?? '---'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {trans.type === 'batecom_purchase' ? 'BATE' : 'COM'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="shrink-0 flex">
                          <p
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${transStatus.bgColor} ${transStatus.textColor} mr-1`}
                          >
                            {transStatus.label}
                          </p>
                          {trans.isInactive && (
                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-400 text-black mr-1">
                              Inactiva
                            </p>
                          )}
                          {trans.hasIncident && (
                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 mr-1">
                              Incidencia
                            </p>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {trans.tempCircDocOrLeaveRequest && (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={trans.tempCircDocOrLeaveRequest}
                            className="flex justify-center"
                          >
                            <CheckIcon className="h-6 text-indigo-700" />
                          </a>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {parseDateHourYear(trans.createdAt)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer hover:text-blue-600">
                        <Link
                          to={`/${
                            trans.type === 'batecom_purchase'
                              ? 'bates'
                              : 'transfers'
                          }/${trans.tramitCode}`}
                        >
                          Ver
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {goNext && goPrev && (
              <PaginationFooter
                data={data}
                page={page}
                goNext={goNext}
                goPrev={goPrev}
                total={total}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BatecomsTableList;

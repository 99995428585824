import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Layout from '../layout';
import Accounts from '../screens/Backoffice/accounts';
import CarDetails from '../screens/Backoffice/cars/details';
import CustomerDetails from '../screens/Backoffice/customers/details';
import Transfers from '../screens/Backoffice/transfers';
import TransferDetails from '../screens/Backoffice/transfers/details';
import Reports from '../screens/Backoffice/reports';
import Search from '../screens/Backoffice/search';
import Dashboard from '../screens/Backoffice/dashboard';
import CompanyDetails from '../screens/Backoffice/companies/details';
import Tools from '../screens/Backoffice/tools';
import Badges from '../screens/Backoffice/badges';
import Agents from '../screens/Backoffice/agents';
import AccountDetails from '../screens/Backoffice/accounts/details';
import BadgeDetails from '../screens/Backoffice/badges/details';
import { TransferDetailsContextProvider } from '../context/transfer-details.context';
import PricePlans from '../screens/Backoffice/price-plans';
import Batecoms from '../screens/Backoffice/batecoms';
import BateDetails from '../screens/Backoffice/bates/details';
import { BateDetailsContextProvider } from '../context/bate-details.context';
import Registrations from '../screens/Backoffice/registrations';
import RegistrationDetails from '../screens/Backoffice/registrations/details';
import { RegistrationDetailsContextProvider } from '../context/registration-details.context';
import DgtFees from '../screens/Backoffice/dgt-fees';
import { AccountDetailsContextProvider } from '../context/account-details.context';

function BackofficeRouter() {
  return (
    <Layout>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/search/:type/:query" element={<Search />} />
        <Route exact path="/search/:type/" element={<Search />} />
        <Route path="/customers/:userId" element={<CustomerDetails />} />
        <Route path="/vehicles/:carId" element={<CarDetails />} />
        <Route path="/transfers" element={<Transfers />} />
        <Route
          path="/transfers/:code"
          element={
            <TransferDetailsContextProvider>
              <TransferDetails />
            </TransferDetailsContextProvider>
          }
        />
        <Route path="/registrations" element={<Registrations />} />
        <Route
          path="/registration/:code"
          element={
            <RegistrationDetailsContextProvider>
              <RegistrationDetails />
            </RegistrationDetailsContextProvider>
          }
        />
        <Route path="/batecoms" element={<Batecoms />} />
        <Route
          path="/bates/:code"
          element={
            <BateDetailsContextProvider>
              <BateDetails />
            </BateDetailsContextProvider>
          }
        />
        <Route
          path="/account/:accountId"
          element={
            <AccountDetailsContextProvider>
              <AccountDetails />
            </AccountDetailsContextProvider>
          }
        />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/reports-pro" element={<Reports isPro />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/pro-transfers" element={<Transfers isPro />} />
        <Route path="/my-transfers" element={<Transfers isMyTransfers />} />
        <Route path="/companies/:companyId" element={<CompanyDetails />} />
        <Route path="/agents" element={<Agents />} />
        <Route path="/dgt-fees" element={<DgtFees />} />
        <Route path="/tools" element={<Tools />} />
        <Route path="/environmental-badges" element={<Badges />} />
        <Route path="/environmental-badges/:id" element={<BadgeDetails />} />
        <Route path="/price-plans" element={<PricePlans />} />
      </Routes>
    </Layout>
  );
}

export default function Backoffice() {
  return (
    <BrowserRouter>
      <BackofficeRouter />
    </BrowserRouter>
  );
}

import React from 'react';
import BaseModal from '../../components/BaseModal';
import { DigitallySignContextProvider } from '../../context/DigitallySignContext/DigitallySignContext';
import DigitallySignStepsManager from './steps/DigitallySignStepsManager';

type DigitallySignModalProps = {
  isShown: boolean;
  hide: () => void;
};

export default function DigitallySignModal({
  isShown,
  hide,
}: Readonly<DigitallySignModalProps>) {
  return (
    <BaseModal
      isOpen={isShown}
      onClose={hide}
      width="w-contain"
      padding={false}
    >
      <section className="h-full w-full p-8">
        <DigitallySignContextProvider>
          <DigitallySignStepsManager hide={hide} />
        </DigitallySignContextProvider>
      </section>
    </BaseModal>
  );
}

import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useContext } from 'react';
import { CheckIcon, XIcon } from '@heroicons/react/outline';

import useClickOutside from '../../../hooks/useClickOutside';

import { BateTramit } from '../../../types/types';
import Button from '../../../components/Button';
import { toasterContext } from '../../../context/ToasterContext/ToasterContext';
import { CounterPartType } from '../../../context/CreateTransferContext/CreateTransferContext';

type BateDetailsHeaderPropsType = {
  showModal: boolean;
  bateTramit: BateTramit;
  closeModal: () => void;
};

export default function ContinueWithBatecomModal({
  showModal,
  bateTramit,
  closeModal,
}: Readonly<BateDetailsHeaderPropsType>) {
  const [counterPartType, setCounterPartType] =
    useState<CounterPartType | null>(null);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { setToasterData } = useContext(toasterContext);

  const linkBatecom = async (transactionId: string, batecomId: string) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${process.env.REACT_APP_BASE_API_URL}/v1/link-batecom/${batecomId}`,
        {
          transactionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );
      return true;
    } catch (e) {
      setToasterData({
        title: 'Error en la Batecom.',
        message: 'No se pudo enlazar la Bate con la nueva transferencia.',
        type: 'ERROR',
      });
      return false;
    }
  };

  const getBatecomByBate = async (bateId: string) => {
    const token = await getAccessTokenSilently();
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/v1/batecom/bate/${bateId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-origin': 'swipoo-pro',
        },
      },
    );
    return data;
  };

  const createTransfer = async (
    batecomId: string,
  ): Promise<{ id: string; transactionCode: string } | null> => {
    try {
      const token = await getAccessTokenSilently();
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/create-pro-transaction`,
        {
          batecomId,
          intermediaryId: bateTramit?.buyer.id,
          userType: 'SELLER',
          counterPartType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );
      return data;
    } catch (e) {
      setToasterData({
        title: 'Error en la Transferencia.',
        message: 'No se pudo crear la Venta - Batecom.',
        type: 'ERROR',
      });
      return null;
    }
  };

  const createBatecom = async () => {
    try {
      const { id: batecomId } = await getBatecomByBate(bateTramit.bate.id);
      const data = await createTransfer(batecomId);
      const result = await linkBatecom(data.id, batecomId);
      if (!result) return;
      setToasterData({
        title: 'Transferencia creada!',
        message: `Se creó la transferencia con código ${data.transactionCode}`,
        type: 'SUCCESS',
      });
      navigate(`/transfer/${data.transactionCode}`);
    } catch (e) {
      setToasterData({
        title: 'Error en la Batecom.',
        message: 'No se pudo enlazar la Bate con la nueva Transferencia.',
        type: 'ERROR',
      });
    }
  };

  const ref = useRef(null);

  useClickOutside(ref, closeModal);

  return (
    showModal && (
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.4)] z-50 w-screen h-screen flex items-center justify-center">
        <div className="w-[900px] h-fit bg-white rounded-md" ref={ref}>
          <div className="px-5 pt-8 pb-2 min-h-[400px]">
            <div className="w-full">
              <div className="flex items-center justify-between">
                <p className="mb-4 font-semibold">
                  ¿A quién le venderás el vehículo?
                </p>
                <XIcon
                  onClick={closeModal}
                  className="text-gray-400 w-6 h-6 cursor-pointer mb-4"
                />
              </div>
              <div className="flex gap-x-4 w-full">
                <button
                  type="button"
                  onClick={() => setCounterPartType(CounterPartType.PERSON)}
                  onKeyDown={() => setCounterPartType(CounterPartType.PERSON)}
                  className={`mt-2 border rounded-lg py-[17px] px-[25px] w-full
            ${
              counterPartType === CounterPartType.PERSON &&
              'border-2 border-blue-600'
            }`}
                >
                  <p className="font-semibold text-sm text-left">Particular</p>
                  <p className="text-gray-400 text-sm text-left">
                    El vendedor es una persona física.
                  </p>
                </button>
                <button
                  type="button"
                  onClick={() => setCounterPartType(CounterPartType.COMPANY)}
                  onKeyDown={() => setCounterPartType(CounterPartType.COMPANY)}
                  className={`mt-2 border rounded-lg py-[17px] px-[25px] w-full
            ${
              counterPartType === CounterPartType.COMPANY &&
              'border-2 border-blue-600'
            }`}
                >
                  <p className="font-semibold text-sm text-left">Empresa</p>
                  <p className="text-gray-400 text-sm text-left">
                    El vendedor es una empresa.
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center h-[65px] w-full border-t border-t-gray-200 px-6">
            <Button
              text="Crear Venta - Batecom"
              bgColor="bg-blue-600"
              hoverBgColor="bg-blue-700"
              textColor="white"
              callback={createBatecom}
              disabled={!counterPartType}
              RightIcon={CheckIcon}
            />
          </div>
        </div>
      </div>
    )
  );
}

import { useEffect, useReducer, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  PaperClipIcon,
  BadgeCheckIcon,
  ExclamationIcon,
} from '@heroicons/react/solid';
import { DownloadIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { validate as validateUUID } from 'uuid';

import { useServices } from '../../../services';
import {
  isValidCIF,
  isValidDNI,
  isValidNIE,
} from '../../../helpers/doiValidators';
import useIsValidZipCode from '../../../hooks/useIsValidZipCode';
import AssociatedTramits from '../../../components/AssociatedTramits';
import EditorModal from '../../../components/EditorModal';
import { ccaaDictionary } from '../../../helpers/enums';
import FileLink from '../../../components/FileLink';
import RequestMandateButton from './components/RequestMandateButton';
import Spinner from '../../../components/Spinner';
import UploadFileModal from '../../../components/UploadFileModal';
import CompanyAdministrator from './components/CompanyAdministrator';

export default function CompanyDetails() {
  const [company, setCompany] = useState(null);
  const { companyId } = useParams();
  const [modalType, setModalType] = useState(null);
  const [uploadModalType, setUploadModalType] = useState('');
  const [editionId, setEditionId] = useState(null);
  const [preloadedState, setPreloadedState] = useState(company);
  const [mandate, setMandate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [representative, setRepresentative] = useState(null);

  const {
    getCompanyInfoById,
    getUserById,
    getEntityTramitsCompany,
    editCompany,
  } = useServices();
  const { getAccessTokenSilently } = useAuth0();
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const { isValidZipCode } = useIsValidZipCode(company?.fiscalAddressZipCode);

  const fetchCompany = async () => {
    const companyFound = await getCompanyInfoById(companyId);
    setCompany(companyFound);
  };

  const fetchRepresentative = async (representativeId) => {
    const representativeFound = await getUserById(representativeId);
    setRepresentative(representativeFound);
  };

  useEffect(() => {
    fetchCompany();
    if (!company || !company.representativeId) return;
    fetchRepresentative(company.representativeId);
  }, [
    companyId,
    company?.representativeId,
    getCompanyInfoById,
    getUserById,
    updater,
  ]);

  useEffect(() => {
    setPreloadedState(company);
  }, [company]);

  const fetchMandate = async () => {
    const token = await getAccessTokenSilently();
    const { data: fetchedMandate } = await axios.get(
      `${process.env.REACT_APP_SUZUKI_URL}/documents/signer/${company.id}?type=mandate-pro`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    setMandate(fetchedMandate[0]);
  };

  const downloadMandate = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();

    const response = await axios.get(
      `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${mandate.serviceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      },
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/pdf' }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Mandato-${company.name}.pdf`);
    document.body.appendChild(link);
    link.click();

    setLoading(false);
  };

  useEffect(() => {
    if (!company) return;
    fetchMandate();
  }, [company]);

  const isAutonomo = useMemo(
    () => isValidDNI(company?.nif) || isValidNIE(company?.nif),
    [company?.nif],
  );

  return (
    company && (
      <>
        <EditorModal
          type={modalType}
          setType={setModalType}
          id={editionId}
          save={forceUpdate}
          preloadedState={preloadedState}
        />
        <UploadFileModal
          entity={company}
          modalType={uploadModalType}
          setModalType={setUploadModalType}
          entityKey={company?.id}
          editEntity={editCompany}
          forceUpdate={forceUpdate}
        />
        <div className="mt-8 max-w-3xl mx-auto sm:px-6 lg:max-w-7xl relative">
          <div className="space-y-6">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-3xl leading-6 font-medium text-gray-900 inline">
                  {company.name}
                </h3>
                <span className="ml-4">
                  <button
                    type="button"
                    onClick={() => {
                      setModalType('companyName');
                      setEditionId(company?.id);
                    }}
                    className="rounded-md text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </div>
              <div>
                {company?.holdedId && (
                  <div>
                    <span className="ml-4 shrink-0">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://app.holded.com/contacts/${company?.holdedId}`}
                        className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                      >
                        Ir a Holded
                      </a>
                    </span>
                  </div>
                )}
                {company.stripeId && (
                  <span className="ml-4 shrink-0">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://dashboard.stripe.com/customers/${company.stripeId}`}
                      className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                    >
                      Ir a Stripe
                    </a>
                  </span>
                )}
              </div>
            </div>
            <div className="mt-3">
              <h3 className="text-2xl leading-6 text-gray-900 inline">
                Datos de la empresa
              </h3>
              <h3 className="text-md text-black font-medium pt-4 border-none mt-2">
                Datos fiscales
              </h3>
              <dl className="divide-y divide-gray-200 border-t">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    Razón social
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">{company.legalName}</span>
                    <span className="ml-4 shrink-0">
                      <button
                        type="button"
                        onClick={() => {
                          setModalType('legalName');
                          setEditionId(company.id);
                        }}
                        className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Editar
                      </button>
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">NIF</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">{company?.nif}</span>
                    {company?.nif && (
                      <span className="flex-grow">
                        {isValidCIF(company?.nif) || isAutonomo ? (
                          <BadgeCheckIcon className="text-teal-500 h-6" />
                        ) : (
                          <ExclamationIcon className="text-red-500 h-6" />
                        )}
                      </span>
                    )}
                    <span className="ml-4 shrink-0">
                      <button
                        type="button"
                        onClick={() => {
                          setModalType('companyNif');
                          setEditionId(company.id);
                        }}
                        className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Editar
                      </button>
                    </span>
                  </dd>
                </div>
                <h3 className="text-md text-black font-medium pt-4 border-none mt-2">
                  Dirección fiscal
                </h3>
                <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    Calle y número
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      {company?.fiscalAddressAddress}
                    </span>
                    <button
                      type="button"
                      className="text-indigo-600"
                      onClick={() => {
                        setModalType('fiscalAddressAddress');
                        setEditionId(company.id);
                      }}
                    >
                      Editar
                    </button>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Ciudad</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      {company?.fiscalAddressCity}
                    </span>
                    <button
                      type="button"
                      className="text-indigo-600"
                      onClick={() => {
                        setModalType('fiscalAddressCity');
                        setEditionId(company.id);
                      }}
                    >
                      Editar
                    </button>
                  </dd>
                </div>{' '}
                <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    Provincia
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      {company?.fiscalAddressProvince}
                    </span>
                    <button
                      type="button"
                      className="text-indigo-600"
                      onClick={() => {
                        setModalType('fiscalAddressProvince');
                        setEditionId(company.id);
                      }}
                    >
                      Editar
                    </button>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    Comunidad autónoma
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      {ccaaDictionary[company?.fiscalAddressState]}
                    </span>
                    <button
                      type="button"
                      className="text-indigo-600"
                      onClick={() => {
                        setModalType('fiscalAddressState');
                        setEditionId(company.id);
                      }}
                    >
                      Editar
                    </button>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    Código postal
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      {company?.fiscalAddressZipCode}
                    </span>
                    {company.fiscalAddressZipCode && (
                      <span className="flex-grow">
                        {isValidZipCode ? (
                          <BadgeCheckIcon className="text-teal-500 h-6" />
                        ) : (
                          <ExclamationIcon className="text-red-500 h-6" />
                        )}
                      </span>
                    )}
                    <button
                      type="button"
                      className="text-indigo-600"
                      onClick={() => {
                        setModalType('fiscalAddressZipCode');
                        setEditionId(company.id);
                      }}
                    >
                      Editar
                    </button>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                  <dt className="text-md text-black font-medium">Documentos</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <PaperClipIcon
                            className="shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">NIF</span>
                        </div>
                        <div className="ml-4 shrink-0 flex space-x-4">
                          {company?.nifFile ? (
                            <>
                              <FileLink source={company?.nifFile} />
                              <span
                                className="text-gray-300"
                                aria-hidden="true"
                              >
                                |
                              </span>
                              <button
                                type="button"
                                onClick={() => {
                                  setUploadModalType('nifFile');
                                }}
                                className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Reemplazar
                              </button>
                            </>
                          ) : (
                            <button
                              type="button"
                              className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                              onClick={() => {
                                setUploadModalType('nifFile');
                              }}
                            >
                              Añadir
                            </button>
                          )}
                        </div>
                      </li>
                      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <PaperClipIcon
                            className="shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">
                            Mandato
                          </span>
                        </div>
                        <div className="ml-4 shrink-0 flex space-x-4">
                          {mandate && mandate.status !== 'CANCELLED' && (
                            <>
                              <button
                                type="button"
                                className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                              >
                                <a
                                  href={
                                    validateUUID(mandate.serviceId)
                                      ? `https://${
                                          process.env.REACT_APP_ENV !== 'PROD'
                                            ? 'apps-d'
                                            : 'apps'
                                        }.docusign.com/send/documents/details/${
                                          mandate.serviceId
                                        }`
                                      : `https://app.docuten.com/ViewDocument.html?id=${mandate.serviceId}&pageAttr=1`
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Ver
                                </a>
                              </button>
                              {mandate.status === 'COMPLETED' && (
                                <>
                                  <span
                                    className="text-gray-300"
                                    aria-hidden="true"
                                  >
                                    |
                                  </span>
                                  <button type="button">
                                    {loading ? (
                                      <Spinner
                                        color="text-blue-700"
                                        size={4}
                                        marginTop={28}
                                      />
                                    ) : (
                                      <DownloadIcon
                                        className="w-5 h-5 cursor-pointer text-indigo-600 hover:text-indigo-500 self-end mr-4"
                                        onClick={downloadMandate}
                                      />
                                    )}
                                  </button>
                                </>
                              )}
                            </>
                          )}
                          <RequestMandateButton
                            company={company}
                            representative={representative}
                            mandate={mandate}
                            fetchMandate={fetchMandate}
                          />
                        </div>
                      </li>
                    </ul>
                  </dd>
                </div>
              </dl>
              <CompanyAdministrator
                forceUpdate={forceUpdate}
                companyId={company.id}
                representative={representative}
              />
              <div className="mt-6 mb-2 divide-y divide-gray-200 border-t">
                <AssociatedTramits
                  entityId={company?.id}
                  entityIdentifier={company?.nif}
                  fetchFunction={getEntityTramitsCompany}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

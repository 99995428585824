import React, { useContext } from 'react';
import { parseDate } from '../../../../helpers';
import Incidents from '../../../../components/Incidents/Incidents';
import { bateDetailsContext } from '../../../../context/bate-details.context';
import { ENTITY_TYPES } from '../../../../helpers/enums';
import Labels from '../../../../components/Labels/Labels';

export default function TopSection() {
  const { aggregatedBate, forceUpdate, bateIncidents } =
    useContext(bateDetailsContext);

  const { bate, buyer, labels } = aggregatedBate;

  return (
    <div className="px-4 py-5 sm:px-6">
      <div className="flex justify-between gap-x-4">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900 inline">
            {bate.bateCode} - (BATE)
          </h2>

          <p className="my-1 max-w-2xl text-sm text-gray-500">
            <time dateTime={parseDate(bate.createdAt)}>
              Creada el {parseDate(bate.createdAt)}
            </time>
          </p>
        </div>
        <Incidents
          entityId={bate?.id}
          entityType={ENTITY_TYPES.BATE}
          forceUpdate={forceUpdate}
          emailToNotify={buyer?.representative?.email}
          entityIncidents={bateIncidents}
        />
      </div>
      <Labels
        forceUpdate={forceUpdate}
        labels={labels}
        entityId={bate?.id}
        entityType={ENTITY_TYPES.BATE}
      />
    </div>
  );
}

import { useRef } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

import useClickOutside from '../../../../../hooks/useClickOutside';

export default function ResetPasswordModal({
  resetPasswordLink,
  setResetPasswordLink,
}) {
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setResetPasswordLink(false));
  return (
    <div className="w-full h-full fixed left-0 top-0 bg-black/75 z-30 flex items-center justify-center">
      <div
        ref={modalRef}
        className="p-4 flex flex-col items-start bg-green-50 rounded-md shadow-md"
      >
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Link para restablecer nueva contraseña
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p className="underline">{resetPasswordLink}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

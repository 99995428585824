import { Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { CalendarIcon } from '@heroicons/react/solid';
import { FaBuilding } from 'react-icons/fa';

import { parseDate } from '../../../../helpers';
import { useServices } from '../../../../services';

function AssociatedCompanies({ userId }) {
  const [companiesRepresented, setCompaniesRepresented] = useState([]);
  const { getCompaniesRepresentedByUser } = useServices();

  const fetchCompaniesRepresentedByUser = useCallback(async () => {
    const response = await getCompaniesRepresentedByUser(userId);
    setCompaniesRepresented(response);
  }, [getCompaniesRepresentedByUser, userId]);

  useEffect(() => {
    fetchCompaniesRepresentedByUser();
  }, [fetchCompaniesRepresentedByUser]);

  return (
    Boolean(companiesRepresented.length) && (
      <>
        <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
          Empresas asociadas:
        </h3>
        <div className="bg-white shadow overflow-hidden sm:rounded-md mt-5">
          <ul className="divide-y divide-gray-200">
            {companiesRepresented.map((company) => (
              <li key={company.id}>
                <Link to={`/companies/${company.id}`}>
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-start">
                      <p className="text-sm font-medium text-indigo-600 truncate">
                        {company.name || <p className="mr-2">-</p>}
                      </p>

                      {company.name && company.nif && <p className="mx-2">-</p>}
                      <div className="shrink-0 flex">
                        <p className="text-sm font-medium text-indigo-600 truncate">
                          {company.nif || ' - '}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          <FaBuilding
                            className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Representante
                        </p>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon
                          className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          <time dateTime={parseDate(company.createdAt)}>
                            {parseDate(company.createdAt)}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </>
    )
  );
}

export default AssociatedCompanies;

import { useAuth0 } from '@auth0/auth0-react';
import { EyeIcon, EyeOffIcon, TrashIcon } from '@heroicons/react/solid';
import { BiError } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useServices } from '../../services';
import Spinner from '../Spinner';
import Comment from './Comment';
import { parseDateHourYear } from '../../helpers';
import ModuleWrapper from '../../screens/Backoffice/transfers/components/ModuleWrapper';
import NewCommentForm from './NewCommentForm';

const CommentStatusEnum = {
  NOT_STARTED: 'Not started',
  LOADING: 'loading',
  ERROR: 'error',
};

export default function CommentsModule({
  entityId,
  showNotificationOption,
  entityIncidents,
  tramitCode,
  tramitType,
  emailToNotify,
  platform,
  isAlwaysPrivate,
}) {
  const [deleteCommentStatus, setDeleteCommentStatus] = useState(
    CommentStatusEnum.NOT_STARTED,
  );

  const [comments, setComments] = useState([]);

  const { getComments, toggleCommentVisibility, deleteCommentById } =
    useServices();

  const fetchComments = async () => {
    const response = await getComments(entityId);

    setComments((prev) => {
      const previousCommentsFromIncidents = prev.filter(
        (comment) => comment.fromIncident,
      );
      const newCommentsWithoutIncidents = response.map((c) => ({
        ...c,
        fromIncident: false,
        incidentName: null,
      }));

      return [...previousCommentsFromIncidents, ...newCommentsWithoutIncidents];
    });
  };

  const handleCommentVisibilityToggle = async (id) => {
    await toggleCommentVisibility(id);
    fetchComments();
  };
  const { user } = useAuth0();

  const removeComment = async (id) => {
    if (!id) return;

    try {
      setDeleteCommentStatus({ loading: true });
      await deleteCommentById(id, user.email);
      fetchComments();
    } catch (error) {
      setDeleteCommentStatus(CommentStatusEnum.ERROR);
    }
    setDeleteCommentStatus(CommentStatusEnum.NOT_STARTED);
  };

  useEffect(() => {
    fetchComments();
  }, [entityId]);

  useEffect(() => {
    const incidentComments = entityIncidents
      ?.filter((incident) => incident?.comment)
      ?.map((incident) => ({
        id: incident.id,
        comment: incident.comment,
        createdAt: incident.createdAt,
        createdBy: incident.createdBy,
        isPublic: true,
        isNotified: true,
        fromIncident: true,
        incidentName: incident.name,
      }));

    setComments((prevComments) => [
      ...prevComments,
      ...incidentComments.filter(
        (incidentComment) =>
          !prevComments
            .map((prevComment) => prevComment.id)
            .includes(incidentComment.id),
      ),
    ]);
  }, [entityIncidents]);

  return (
    <ModuleWrapper title="Comentarios">
      {deleteCommentStatus.loading && (
        <div className="flex justify-center">
          <Spinner size={6} color="indigo-700" marginBottom={4} marginTop={4} />
        </div>
      )}
      {deleteCommentStatus.error && (
        <p className="text-red-500 px-4 py-5 sm:px-6 flex justify-between items-center">
          Hubo un error intentando eliminar el comentario. Inténtalo nuevamente.{' '}
        </p>
      )}
      {Boolean(comments.length) && (
        <div className="px-4 py-6 sm:px-6">
          <ul className="space-y-8">
            {comments
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime(),
              )
              .map(
                ({
                  id,
                  comment,
                  createdAt,
                  createdBy,
                  isPublic,
                  isNotified,
                  fromIncident,
                  incidentName,
                }) => (
                  <li key={id}>
                    <div className="flex">
                      <div className="w-full">
                        <div className="flex mt-1 text-sm text-gray-700 justify-between">
                          <Comment text={comment} />
                          <div className="flex gap-x-4">
                            {fromIncident && (
                              <>
                                <Tooltip
                                  id={`comment-${id}`}
                                  style={{
                                    marginTop: '0',
                                    fontSize: '15px',
                                  }}
                                  content={<p>{incidentName}</p>}
                                  place="bottom"
                                />
                                <div data-tooltip-id={`comment-${id}`}>
                                  <BiError className="text-red-500 h-5 w-5" />
                                </div>
                              </>
                            )}
                            {createdBy === user.email && !fromIncident && (
                              <button
                                type="button"
                                onClick={() => removeComment(id)}
                                className="font-medium text-xs hover:text-rose-500"
                              >
                                <TrashIcon className="h-5 w-5" />
                              </button>
                            )}
                            {!fromIncident && (
                              <button
                                type="button"
                                onClick={() =>
                                  handleCommentVisibilityToggle(id)
                                }
                              >
                                {isPublic ? (
                                  <EyeIcon className="h-5 w-5" />
                                ) : (
                                  <EyeOffIcon className="h-5 w-5" />
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="mt-2 flex justify-between text-xs">
                          <div className="text-gray-500 font-medium">
                            {parseDateHourYear(createdAt)}
                          </div>
                          <div className="flex flex-col">
                            {isNotified && (
                              <div className="text-gray-500 font-medium">
                                Se notificó por correo
                              </div>
                            )}
                            {createdBy && (
                              <div className="text-gray-500 font-medium">
                                Autor: {createdBy}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ),
              )}
          </ul>
        </div>
      )}
      <NewCommentForm
        entityId={entityId}
        forceCommentUpdate={fetchComments}
        showNotificationOption={showNotificationOption}
        tramitCode={tramitCode}
        tramitType={tramitType}
        emailToNotify={emailToNotify}
        platform={platform}
        isAlwaysPrivate={isAlwaysPrivate}
      />
    </ModuleWrapper>
  );
}

import AvailableItems from './components/AvailableItems';
import ModuleWrapper from '../../screens/Backoffice/transfers/components/ModuleWrapper';
import DeliveryInfoModal from './components/DeliveryInfoModal';
import Spinner from '../Spinner';
import ShipmentsTable from './components/ShipmentsTable';

export default function Shipment({
  setShowDeliveryInfoModal,
  showDeliveryInfoModal,
  availableItems,
  editedShipment,
  forceUpdate,
  updater,
  setAvailableItems,
  isShipmentLoading,
  getShipmentError,
  shipments,
  items,
  uncreatedItems,
  setUncreatedItems,
  setEditedShipment,
  areRolesComplete,
  accountId,
  possibleReceivers,
  isDocumentOnOffice,
  isPro,
  entityType,
  plate,
}) {
  return (
    <ModuleWrapper title="Envíos">
      <div className="p-6">
        <DeliveryInfoModal
          setShowModal={setShowDeliveryInfoModal}
          showModal={showDeliveryInfoModal}
          save={forceUpdate}
          shipment={editedShipment}
          availableItems={availableItems}
          setAvailableItems={setAvailableItems}
          accountId={accountId}
          possibleReceivers={possibleReceivers}
          isPro={isPro}
          updater={updater}
        />
        {isShipmentLoading && (
          <Spinner color="text-indigo-600" size="4" marginTop="0" />
        )}
        {getShipmentError && <p className="text-red-500">{getShipmentError}</p>}

        <AvailableItems
          availableItems={availableItems}
          setAvailableItems={setAvailableItems}
          shipments={shipments}
          forceUpdate={forceUpdate}
          items={items}
          uncreatedItems={uncreatedItems}
          setUncreatedItems={setUncreatedItems}
          accountId={accountId}
          plate={plate}
        />

        <ShipmentsTable
          shipments={shipments}
          areRolesComplete={areRolesComplete}
          setShowDeliveryInfoModal={setShowDeliveryInfoModal}
          forceUpdate={forceUpdate}
          setEditedShipment={setEditedShipment}
          isDocumentOnOffice={isDocumentOnOffice}
          entityType={entityType}
        />
      </div>
    </ModuleWrapper>
  );
}

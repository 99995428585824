import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import Spinner from '../../../../components/Spinner';

const representativeFields = {
  name: 'nombre',
  surname: 'apellidos',
  dni: 'dni',
  phone: 'telefono',
};

const companyFields = {
  fiscalAddressCity: 'ciudad',
  legalName: 'razón social',
  nif: 'nif',
  fiscalAddressAddress: 'dirección',
  fiscalAddressZipCode: 'código postal',
};

const agentFields = {
  email: 'email',
  city: 'ciudad',
  name: 'nombre',
  surname: 'apellidos',
  colNumber: 'número de colegiado',
  colLocation: 'localidad de colegiado',
  address: 'dirección',
  addressNumber: 'número de calle',
  zipCode: 'código postal',
};

const LOADING_STATE = {
  NOT_STARTED: 'NOT_STARTED',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

function RequestMandateButton({
  company,
  representative,
  mandate,
  fetchMandate,
}) {
  const [agent, setAgent] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [requestMandateLoadingState, setRequestMandateLoadingState] = useState(
    LOADING_STATE.NOT_STARTED,
  );
  const [cancelMandateLoadingState, setCancelMandateLoadingState] = useState(
    LOADING_STATE.NOT_STARTED,
  );
  const { getAccessTokenSilently } = useAuth0();

  const fetchAgent = async (agentId) => {
    const token = await getAccessTokenSilently();
    const { data: fetchedAgent } = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/agents/${agentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    setAgent(fetchedAgent);
  };

  const validateFields = () => {
    setErrorMsg('');
    Object.keys(representativeFields).forEach((key) => {
      if (!representative?.[key]) {
        setErrorMsg(
          `El representante legal no tiene el campo ${representativeFields[key]}`,
        );
      }
    });
    Object.keys(companyFields).forEach((key) => {
      if (!company?.[key]) {
        setErrorMsg(`La empresa no tiene el campo ${companyFields[key]}`);
      }
    });

    Object.keys(agentFields).forEach((key) => {
      if (!agent?.[key]) {
        setErrorMsg(`El gestor no tiene el campo ${agentFields[key]}`);
      }
    });
    if (!agent) {
      setErrorMsg('No hay un gestor asociado');
    }
  };

  useEffect(() => {
    if (!company.agentId) return;
    fetchAgent(company.agentId);
  }, [company]);

  useEffect(() => {
    validateFields();
  }, [company, agent]);

  const requestMandate = async () => {
    setRequestMandateLoadingState(LOADING_STATE.LOADING);
    try {
      const companyPayload = {
        id: company.id,
        role: 'pro',
        phone: representative.phone,
        name: `${representative.name} ${representative.surname}`,
        nif: representative.dni,
        companyCity: company.fiscalAddressCity,
        companyName: company.legalName,
        companyNif: company.nif,
        companyStreet: company.fiscalAddressAddress,
        companyStreetNumber: ' ',
        companyZipCode: company.fiscalAddressZipCode,
      };
      const agentPayload = {
        id: agent.id,
        role: 'agency',
        email: agent.email,
        city: agent.city,
        name: `${agent.name} ${agent.surname}`,
        number: agent.colNumber,
        colLocation: agent.colLocation,
        street: agent.address,
        streetNumber: agent.addressNumber,
        zipCode: agent.zipCode,
      };
      const payload = {
        id: crypto.randomUUID(),
        signers: [companyPayload, agentPayload],
      };

      const token = await getAccessTokenSilently();

      await axios.post(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/proMandate`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      await fetchMandate();
      setRequestMandateLoadingState(LOADING_STATE.NOT_STARTED);
    } catch {
      setRequestMandateLoadingState(LOADING_STATE.ERROR);
    }
  };

  const cancelCurrentMandate = async () => {
    setCancelMandateLoadingState(LOADING_STATE.LOADING);
    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/cancel/${mandate.id}`,
        {
          reason: 'El usuario ha solicitado cambios en el mandato',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      await fetchMandate();
      setCancelMandateLoadingState(LOADING_STATE.NOT_STARTED);
    } catch {
      setCancelMandateLoadingState(LOADING_STATE.ERROR);
    }
  };

  const getButtonText = () => {
    if (!mandate || mandate?.status === 'CANCELLED') return 'Solicitar';
    if (mandate?.status === 'PENDING') return 'Pendiente de firma';
    return 'Firmado';
  };

  const handleButtonClick = async () => {
    if (!mandate || mandate?.status === 'CANCELLED') {
      await requestMandate();
      return;
    }
    window.open(
      `https://${
        process.env.REACT_APP_ENV !== 'PROD' ? 'sandbox' : 'app'
      }.docuten.com/ViewDocumentByAdmin.html?id=${mandate.serviceId}`,
      '_blank',
    );
  };

  return (
    <>
      <Tooltip id="request-mandate-button" content={errorMsg} place="left" />
      <div data-tooltip-id="request-mandate-button">
        <button
          data-tooltip-id="request-mandate-button"
          type="button"
          onClick={handleButtonClick}
          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 flex gap-4 items-center"
          disabled={
            errorMsg !== '' ||
            requestMandateLoadingState !== LOADING_STATE.NOT_STARTED
          }
        >
          {getButtonText()}
          {requestMandateLoadingState === LOADING_STATE.LOADING && (
            <Spinner size={4} />
          )}
        </button>
      </div>
      {['PENDING', 'COMPLETED'].includes(mandate?.status) && (
        <button
          type="button"
          onClick={cancelCurrentMandate}
          className="rounded-md font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 flex gap-4 items-center"
          disabled={cancelMandateLoadingState !== LOADING_STATE.NOT_STARTED}
        >
          Cancelar firma
          {cancelMandateLoadingState === LOADING_STATE.LOADING && (
            <Spinner size={4} />
          )}
        </button>
      )}
      {cancelMandateLoadingState === LOADING_STATE.ERROR ||
        (requestMandateLoadingState === LOADING_STATE.ERROR && (
          <p className="text-sm text-red-500">Error</p>
        ))}
    </>
  );
}

export default RequestMandateButton;

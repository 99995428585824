import { BateTramit, Transfer } from '../../../types/types';
import { Module } from '../../../modals/CreateSignatureModal/CreateSignatureModal';
import { Document } from '../SignatureDetails';
import { roleDictionary } from './dictionaries';

export const getTooltipMessage = (d: Document) => {
  let message = 'Pendiente de firmar por:';

  d.signers.forEach((s) => {
    if (s.signed) return;
    message += ` ${roleDictionary.get(s.role)},`;
  });
  if (message === 'Pendiente de firmar por:') return '';
  message = message.slice(0, -1);
  return message;
};

export const parseDocumentsToCheckExistence = (
  documents: Document[],
  tramit: Transfer | BateTramit,
) =>
  documents.map((document) => ({
    ...document,
    parts: document.parts.map((part) =>
      part === 'mandate-pro' ? 'mandate' : part,
    ),
    signers: document.signers.map((signer) => {
      if (signer.role === 'pro') {
        const newRole =
          tramit?.buyer?.id === signer.entityId
            ? 'buyer'
            : ('seller' as 'buyer' | 'seller' | 'pro' | 'agency');
        return { ...signer, role: newRole };
      }
      return signer;
    }),
  }));

type ParsedDocument = {
  id: Document['id'];
  serviceId: Document['serviceId'];
  parts: Omit<Document['parts'], 'mandate-pro'>;
  signers: Document['signers'];
  metadata: Document['metadata'];
  status: Document['status'];
};

export const findModuleExistingDocument = (
  module: Module,
  parsedDocuments: ParsedDocument[],
) => {
  if (module.counterPart) {
    return parsedDocuments.find(
      (document) =>
        document.parts.includes(module.type) &&
        document.signers.some((signer) => signer.role === module.counterPart),
    );
  }
  return parsedDocuments.find((document) =>
    document.parts.includes(module.type),
  );
};

export const STRIPE_CARD_DECLINE_CODES = {
  generic_error: {
    title: 'Error en la tarjeta',
    description: 'Ha ocurrido un error con la tarjeta.',
    nextSteps: 'Por favor comunicarse con su entidad bancaria.',
  },
  insufficient_funds: {
    title: 'Fondos insuficientes',
    description:
      'La tarjeta tiene fondos insuficientes para completar la compra.',
    nextSteps:
      'Debe de utilizar un método de pago alternativo o añadir fondos.',
  },
  expired_card: {
    title: 'Tarjeta expirada',
    description: 'La tarjeta ha expirado.',
    nextSteps: 'El cliente debe de utilizar otra tarjeta.',
  },
  card_velocity_exceeded: {
    title: 'Crédito excedido',
    description: 'Se ha sobrepasado el límite de crédito de la tarjeta.',
    nextSteps:
      'El cliente debe de utilizar otra tarjeta o coordinar con su banco.',
  },
};

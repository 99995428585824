import { getTrafficReportById } from '../services/trafficReports';

export const handleViewTrafficReport = async (
  trafficReportId: string,
  token: string,
) => {
  const { uri } = await getTrafficReportById(trafficReportId, token);

  const anchor = document.createElement('a');
  anchor.href = uri;
  anchor.target = '_blank';
  anchor.click();
};

import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import React, {
  createContext,
  useEffect,
  ReactNode,
  useMemo,
  useState,
  useReducer,
} from 'react';

import { RegistrationTramit } from '../../types/types';

export interface RegistrationDetailsContextType {
  registrationTramit: RegistrationTramit;
  registrationLoading: boolean;
  forceUpdate: () => void;
}

export const registrationDetailsContext =
  createContext<RegistrationDetailsContextType>(
    {} as RegistrationDetailsContextType,
  );

export function RegistrationDetailsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [registrationTramit, setRegistrationTramit] =
    useState<RegistrationTramit | null>(null);
  const [registrationLoading, setRegistrationLoading] =
    useState<boolean>(false);

  const [updater, forceUpdateRegistration] = useReducer((x) => x + 1, 0);

  const { registrationCode } = useParams();

  const { getAccessTokenSilently } = useAuth0();

  const BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const getRegistrationByCode = async () => {
    setRegistrationLoading(true);
    const token = await getAccessTokenSilently();
    const { data } = await axios.get(
      `${BASE_URL}/v1/registration/${registrationCode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    setRegistrationTramit(data);
    setRegistrationLoading(false);
  };

  useEffect(() => {
    getRegistrationByCode();
  }, [updater]);

  const value = useMemo(
    () => ({
      registrationTramit,
      registrationLoading,
      forceUpdate: forceUpdateRegistration,
    }),
    [registrationTramit, registrationLoading],
  );

  return (
    <registrationDetailsContext.Provider value={value}>
      {children}
    </registrationDetailsContext.Provider>
  );
}

import { useContext, useEffect, useState } from 'react';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';

export default function usePagination() {
  const { getSingleParam, setSingleParam } = useContext(queryParamsContext);

  const [page, setPage] = useState(
    getSingleParam('page') ? Number(getSingleParam('page')) : 0,
  );

  useEffect(() => {
    setSingleParam('page', page.toString());
  }, [page, setSingleParam]);

  return { page, setPage };
}

export function SepaLogo() {
  return (
    <svg
      width="36"
      height="24"
      viewBox="-.28266667 -10.16017133 891.07166667 253.68217133"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m197.703 77.694h-60.973q0-15.005-3.52-20.137-5.44-7.583-30.029-7.587-23.875 0-31.615 4.369-7.722 4.39-7.726 19.079 0 13.294 6.854 17.5 4.918 2.977 13 3.5l12.294.867q39.534 2.616 49.373 3.682 31.263 3.151 45.324 16.621 11.061 10.511 13.176 27.469a191.207 191.207 0 0 1 1.229 22.929q0 29.412-5.612 43.056-10.177 25.043-45.765 31.671-14.915 2.8-45.607 2.806-51.221 0-71.374-6.117-24.73-7.506-32.621-30.4-4.4-12.752-4.381-42.473h60.967v5.069q0 15.861 9.108 20.4a35.743 35.743 0 0 0 15.239 3.316h22.418q17.155 0 21.885-1.762 8.41-3.3 11.042-10.657a40.749 40.749 0 0 0 1.4-11.724q0-16.076-11.724-19.58-4.4-1.384-40.784-3.845-29.231-2.087-40.6-4.03-29.941-5.6-39.9-23.072-8.761-14.857-8.751-44.943 0-22.894 4.73-36.712t15.246-20.986q15.429-11.019 39.439-12.939 19.993-1.742 44.354-1.757 38.379 0 54.7 4.392 39.8 10.692 39.8 59.9 0 4.035-.594 12.1m268.101 165.823v-243.5h122.1q25.12 0 38.307 4.1 30.122 9.433 40.454 38.641 5.343 15.332 5.347 46.154 0 37.05-5.89 53.254-11.748 32.051-48.294 36.87-4.292.723-36.545 1.252l-10.875.362h-39.069v62.867zm65.539-119.35h40.858q19.43-.7 23.666-3.019c3.872-2.129 6.474-6.4 7.768-12.791q1.245-6.379 1.243-18.991 0-15.45-2.477-22.919-3.534-10.483-16.941-12.786-2.657-.348-12.712-.357h-41.4zm280.56 77.268h-87.623l-11.775 42.082h-67.8l73.166-243.5h98.857l74.059 243.5h-66.38zm-13.013-47.434-30.71-105.553-29.8 105.553z"
        fill="#10298e"
      />
      <path
        d="m375.353 34.281a86.153 86.153 0 0 1 67.01 31.954l14.126-30.289a123.816 123.816 0 0 0 -85.328-33.767c-51.64 0-95.814 31.193-113.938 75.344h-27.548l-16.269 34.87h35.436q-.422 4.875-.441 9.772a117.841 117.841 0 0 0 .6 11.849h-17.805l-16.264 34.88h43.13c18.643 43.033 62.265 73.243 113.094 73.243a124.152 124.152 0 0 0 69.076-20.791v-42.745a86.218 86.218 0 0 1 -137.131-9.707h90.309l16.262-34.88h-119.856a87.87 87.87 0 0 1 -.51-21.625h130.453l16.26-34.866h-135.443a86.367 86.367 0 0 1 74.778-43.246"
        fill="#ffbe00"
      />
      <path
        d="m375.353 35.325a85.022 85.022 0 0 1 66.2 31.573l1.053 1.3.714-1.517 14.124-30.29.315-.691-.543-.519a125.936 125.936 0 0 0 -156.172-12.744 121.519 121.519 0 0 0 -44.786 54.692l.965-.649h-28.221l-.278.6-16.264 34.877-.7 1.489h37.084l-1.043-1.136q-.422 4.919-.441 9.86c0 3.914.2 7.944.6 11.956l1.043-1.15h-18.466l-.278.6-16.274 34.877-.691 1.489h44.772l-.96-.631c19.441 44.874 64.213 73.873 114.054 73.873a125.107 125.107 0 0 0 69.648-20.958l.459-.306v-46.093l-1.827 2.087a85.181 85.181 0 0 1 -135.471-9.591l-.872 1.623h90.982l.278-.6 16.264-34.885.7-1.484h-121.51l1.039.9a86.817 86.817 0 0 1 -.506-21.374l-1.043.941h131.126l.278-.6 16.26-34.875.7-1.489h-137.09l.9 1.572a85.721 85.721 0 0 1 73.878-42.727m-74.778 43.246h135.439l-.951-1.489-16.255 34.875.946-.6h-131.4l-.1.946a90.248 90.248 0 0 0 .519 21.876l.139.9h120.76l-.946-1.493-16.26 34.875.941-.6h-92.252l1.067 1.614a87.289 87.289 0 0 0 138.8 9.832l-1.837-.691v42.745l.464-.863a123.084 123.084 0 0 1 -68.484 20.605c-49.011 0-93.022-28.508-112.13-72.617l-.278-.626h-43.821l.955 1.5 16.253-34.901-.951.612h18.971l-.12-1.159c-.394-3.942-.6-7.9-.6-11.743 0-2.954.148-6.126.441-9.683l.093-1.132h-36.568l.941 1.489 16.265-34.875-.941.6h28.243l.264-.649a119.518 119.518 0 0 1 44.03-53.751 123.856 123.856 0 0 1 153.558 12.535l-.232-1.206-14.117 30.297 1.762-.223a87.318 87.318 0 0 0 -143.517 11.432l-.909 1.572z"
        fill="#10298e"
      />
    </svg>
  );
}

import React from 'react';
import classNames from '../../../helpers/classNames';
import { TransferStatusCategories } from '../../enums/TransferStatusCategories';

function StatusCategoryFilter({
  selected,
  setSelected,
}: {
  selected: string;
  setSelected: (value: TransferStatusCategories) => void;
}) {
  const specialFilterOptions: {
    label: string;
    value: TransferStatusCategories;
  }[] = [
    {
      label: 'Todas',
      value: TransferStatusCategories.ALL,
    },
    {
      label: 'En curso',
      value: TransferStatusCategories.ONGOING,
    },
    {
      label: 'Con incidencias',
      value: TransferStatusCategories.WITH_INCIDENTS,
    },
    {
      label: 'Finalizadas',
      value: TransferStatusCategories.FINISHED,
    },
    {
      label: 'Anuladas',
      value: TransferStatusCategories.CANCELED,
    },
  ];

  return (
    <div className="w-full">
      <nav className="flex justify-between sm:justify-start gap-8 w-full">
        {specialFilterOptions.map((tab) => (
          <button
            key={tab.label}
            type="button"
            onClick={() => setSelected(tab.value)}
            className={classNames(
              tab.value === selected
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap p-2 border-b-2 font-medium text-sm cursor-pointer',
            )}
            aria-current={tab.value === selected ? 'page' : undefined}
          >
            {tab.label}
          </button>
        ))}
      </nav>
    </div>
  );
}

export default StatusCategoryFilter;

import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import axios from 'axios';
import React, { useState } from 'react';
import BaseModal from '../../components/BaseModal';
import { ToasterData } from '../../context/ToasterContext/ToasterContext';

type DeleteModalProps = {
  documentId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  setToasterData: (data: ToasterData) => void;
  update: React.DispatchWithoutAction;
};

function DeleteSignatureModal({
  documentId,
  open,
  setOpen,
  setToasterData,
  update,
}: DeleteModalProps) {
  const [reason, setReason] = useState('Firma digital cancelada');
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const deleteDocument = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/cancel/${documentId}`,
        {
          reason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setToasterData({
        type: 'SUCCESS',
        title: 'Firma digital cancelada con éxito',
        message:
          'La firma digital ha sido cancelada con éxito y se ha notificado al cliente.',
      });
      update();
    } catch {
      setToasterData({
        type: 'ERROR',
        title: 'Error al cancelar la firma digital',
        message:
          'Ha ocurrido un error al cancelar la firma digital. Inténtalo de nuevo o contacta a soporte.',
      });
    } finally {
      setLoading(false);
      setOpen(false);
      setReason('Firma digital cancelada');
    }
  };

  return (
    <BaseModal isOpen={open} onClose={setOpen} width="w-[512px]">
      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={() => setOpen(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center grow sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Cancelar firma digital
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Especifica el motivo de la cancelación:
            </p>
            <textarea
              className="w-full my-2 border border-gray-300 rounded-md"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            <p className="text-sm text-gray-500">
              Los firmantes serán notificados de la cancelación.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-25"
          onClick={deleteDocument}
          disabled={loading || reason.trim() === ''}
        >
          Cancelar
        </button>
      </div>
    </BaseModal>
  );
}

export default DeleteSignatureModal;

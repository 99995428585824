import { PencilAltIcon, SearchIcon } from '@heroicons/react/outline';
import React from 'react';

function CardButton({
  onEdit,
  onReplace,
  id,
  title,
}: {
  onEdit: () => void;
  onReplace: () => void;
  id?: string;
  title: string;
}) {
  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 gap-2"
        onClick={onReplace}
      >
        <SearchIcon className="h-5 w-5 text-gray-900" />{' '}
        {`Buscar ${title === 'Vehículo' ? 'vehículo' : 'contacto'}`}
      </button>
      <button
        type="button"
        className="ml-2 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 gap-2"
        onClick={onEdit}
        id={`${id}-edit-button`}
      >
        <PencilAltIcon className="h-5 w-5 text-gray-900" /> Editar
      </button>
    </div>
  );
}

export default CardButton;

import React from 'react';

export default function FileClockOutlineIcon({
  className = '',
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <rect width="24" height="24" fill="none" />
      <path
        fill="currentColor"
        d="M4 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8.41A7 7 0 0 0 16 23a7 7 0 0 0 7-7a7 7 0 0 0-5-6.7V8l-6-6zm0 2h7v5h5a7 7 0 0 0-7 7a7 7 0 0 0 1.26 4H4zm12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m-1 1v5l3.61 2.16l.75-1.22l-2.86-1.69V12z"
      />
    </svg>
  );
}

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/outline';
import PaginationFooter from '../../../../components/PaginationFooter';
import { useServices } from '../../../../services';
import { handleViewTrafficReport } from '../../../../helpers/handleViewTrafficReport';
import classNames from '../../../../helpers/classNames';
import EditFeeStatusModal from './EditFeeStatusModal';
import { ENTITY_TYPES } from '../../../../helpers/enums';
import {
  DGT_FEE_STATUS,
  dgtFeeStatusDictionary,
  tramitPaths,
} from '../enums/enums';

export default function DgtFeeTable({
  dgtFees,
  setErrorMessage,
  page,
  goNext,
  goPrev,
  count,
  forceUpdate,
}) {
  const [selectedDgtFee, setSelectedDgtFee] = useState(null);

  const { getTrafficReportById } = useServices();

  const resetModal = () => {
    setSelectedDgtFee(null);
  };

  const isTrafficReportFee = (dgtFee) =>
    dgtFee?.tramitId && dgtFee.tramitType === ENTITY_TYPES.TRAFFIC_REPORT;

  const acceptedStateChanges = (dgtFee) => {
    switch (dgtFee?.status) {
      case DGT_FEE_STATUS.READY_TO_USE:
        return [];
      case DGT_FEE_STATUS.IN_QUARANTINE:
        return [DGT_FEE_STATUS.IN_DISPUTE, DGT_FEE_STATUS.READY_TO_USE];
      case DGT_FEE_STATUS.IN_DISPUTE:
        return [DGT_FEE_STATUS.READY_TO_USE];
      case DGT_FEE_STATUS.USED: {
        if (isTrafficReportFee(dgtFee)) {
          return [];
        }
        return [DGT_FEE_STATUS.IN_QUARANTINE];
      }
      default:
        return [];
    }
  };

  return (
    <>
      <EditFeeStatusModal
        isOpen={Boolean(selectedDgtFee)}
        onClose={resetModal}
        selectedDgtFee={selectedDgtFee}
        acceptedStateChanges={acceptedStateChanges(selectedDgtFee)}
        forceUpdate={forceUpdate}
      />
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Número
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Estado
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Tipo
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ACCIONES
              </th>
            </tr>
          </thead>
          <tbody>
            {dgtFees?.map((dgtFee, i) => (
              <tr
                key={dgtFee.id}
                className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {dgtFee.feeNumber}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex gap-x-2">
                  {dgtFeeStatusDictionary[dgtFee.status]}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {dgtFee.type}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex gap-x-2">
                  <div className="w-5 h-5">
                    {dgtFee?.tramitId &&
                      Object.keys(tramitPaths).includes(dgtFee.tramitType) && (
                        <Link
                          className="cursor-pointer hover:text-blue-600"
                          to={`/${tramitPaths[dgtFee.tramitType]}/${
                            dgtFee.tramitId
                          }`}
                        >
                          Ver
                        </Link>
                      )}
                    {isTrafficReportFee(dgtFee) && (
                      <button
                        className="cursor-pointer hover:text-blue-600"
                        type="button"
                        onClick={() =>
                          handleViewTrafficReport({
                            trafficReportId: dgtFee.tramitId,
                            getTrafficReportById,
                            setErrorMessage,
                          })
                        }
                      >
                        Ver
                      </button>
                    )}
                  </div>
                  <div className="w-5 h-5">
                    {Boolean(acceptedStateChanges(dgtFee).length) && (
                      <PencilIcon
                        className={classNames(
                          'w-5 h-5 cursor-pointer hover:text-blue-600',
                        )}
                        onClick={() => setSelectedDgtFee(dgtFee)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <PaginationFooter
          data={dgtFees}
          page={page}
          goNext={goNext}
          goPrev={goPrev}
          total={count}
        />
      </div>
    </>
  );
}

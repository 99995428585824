export const duplicateFields =
  process.env.REACT_APP_ENV === 'PROD'
    ? {
        plate: 'LRl4NsEtusld',
        ownerDoi: 'BZujTTtIKHI4',
        technicalSheet: '3J6B5fFRo6w7',
      }
    : {
        plate: 'WUBNKQ3RSSKc',
        ownerDoi: 'G7QXel4FTvo7',
        technicalSheet: 'AtVEbRifiyU7',
      };

export const domainReservationFields =
  process.env.REACT_APP_ENV === 'PROD'
    ? {
        plate: 'chiw5EH1NuCE',
        ownerDniFront: 'nEIwwAr27bfU',
        ownerDniBack: 'NYvhL6VsS8Tj',
        circulationPermit: 'b35yFiELTRzU',
        technicalSheet: 'jtBWbe7z0l2N',
        financeCard: 'qqJ24A5fHMLO',
      }
    : {
        plate: 'NSS2dY8FlXyM',
        ownerDniFront: 'YpLTzw8NOKtL',
        ownerDniBack: 'psk4Wue774Js',
        circulationPermit: 'K2fvdhON51El',
        technicalSheet: 'DWWX63IKoE4w',
        financeCard: 'VAausP4FCYXE',
      };

import { PaperClipIcon } from '@heroicons/react/outline';
import { useState } from 'react';

import useExtraFiles from '../hooks/useExtraFiles';
import Spinner from './Spinner';
import NotifierModal from './NotifierModal';
import ExtraFilesDragAndDrop from './ExtraFilesDragAndDrop';
import DeleteExtraFileModal from './DeleteExtraFilesModal';

export default function ExtraFiles({ tramitType, entity }) {
  const {
    extraFilesLoading,
    extraFiles,
    handleViewExtraFile,
    handleDeleteExtraFile,
    handleUploadExtraFiles,
    modalError,
    resetModalError,
  } = useExtraFiles({
    tramitType,
    entity,
  });

  const [fileToDelete, setFileToDelete] = useState(null);

  const handleSetFileToDelete = (file) => {
    setFileToDelete(file);
  };
  const clearFileToDelete = () => {
    setFileToDelete(null);
  };

  return (
    <>
      <DeleteExtraFileModal
        isOpen={Boolean(fileToDelete)}
        close={clearFileToDelete}
        file={fileToDelete}
        handleDeleteExtraFile={handleDeleteExtraFile}
      />
      <li className="group pl-3 pr-4 py-3 flex flex-col items-center jus-between text-sm">
        <div className="flex items-center justify-between w-full">
          <div className="w-0 flex-1 flex items-center">
            <PaperClipIcon
              className="shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span className="ml-2 flex-1 w-0 truncate">Archivos extra</span>
            {extraFilesLoading && <Spinner color="text-blue-700" size="4" />}
          </div>
        </div>
        {extraFiles?.length > 0 && (
          <div className="flex items-center justify-between w-full">
            <ul className="w-full pl-5 mt-3">
              {extraFiles.map((file) => (
                <li
                  key={file}
                  className="flex items-center justify-between w-full p-2 "
                >
                  <div className="w-0 flex-1 flex items-center">
                    <span className="ml-2 flex-1 truncate">
                      {file?.split('-hash-')[0]}
                    </span>
                  </div>
                  <div className="ml-4 shrink-0">
                    <button
                      type="button"
                      onClick={() => handleViewExtraFile(file)}
                      className="cursor-pointer font-medium text-blue-600 hover:text-blue-500"
                    >
                      Ver
                    </button>
                    <button
                      type="button"
                      onClick={() => handleSetFileToDelete(file)}
                      className="ml-3 cursor-pointer font-medium text-red-600 hover:text-red-500"
                    >
                      Eliminar
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {modalError && (
          <NotifierModal
            text="EL archivo no puede pesar más de 15MB, tener un nombre más largo de 100 carácteres o no es un formato aceptado (pdf, jpg, png, jpeg, tif, tiff, webp, heic)."
            buttonText="Ok"
            onClick={resetModalError}
          />
        )}
        <ExtraFilesDragAndDrop
          handleUploadExtraFiles={handleUploadExtraFiles}
        />
      </li>
    </>
  );
}

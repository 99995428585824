import React, { useEffect, useState } from 'react';
import { parseEventDateWithYear } from '../../helpers';
import { useAxios } from '../../hooks/useAxios';
import EventIcon from './EventIcon';

export default function Timeline({ tramitId }) {
  const [history, setHistory] = useState([]);

  const {
    data,
    isLoading,
    error,
    runRequest: getEvents,
  } = useAxios(
    `${process.env.REACT_APP_VOLKSWAGEN_URL}/get-transfer-history/${tramitId}`,
  );

  const MAX_WORD_LENGTH = 15;

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    setHistory(data?.states?.reverse() || []);
  }, [data]);

  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <h2
        id="timeline-title"
        className="text-lg font-medium text-gray-900 mb-4"
      >
        Timeline
      </h2>
      <div className="h-60 overflow-y-auto">
        <ul className="mb-6">
          {isLoading && <p>loading...</p>}
          {!isLoading &&
            Boolean(history.length) &&
            history.map((item, index) => (
              <li key={JSON.stringify(item)}>
                <div
                  className={`relative ${
                    index === history.length - 1 ? 'pb-0' : 'pb-8'
                  }`}
                >
                  {index !== history.length - 1 && (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  )}
                  <div className="relative flex space-x-3">
                    <div>
                      <EventIcon msg={item.msg} />
                    </div>
                    <div className="min-w-0 flex-1 flex-col w-full flex-wrap gap-y-2">
                      <div>
                        {item.msg
                          .split(' ')
                          .some((e) => e.length > MAX_WORD_LENGTH) ? (
                          <p className="text-sm text-gray-500 w-30 break-all ">
                            {item.msg}
                          </p>
                        ) : (
                          <p className="text-sm text-gray-500 w-30 break-words ">
                            {item.msg}
                          </p>
                        )}
                      </div>
                      <div className="text-xs text-gray-500">
                        <time dateTime={item.timestamp}>
                          {parseEventDateWithYear(new Date(item.timestamp))}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}

          {error && <p>{error}</p>}
        </ul>
      </div>
    </div>
  );
}

import { deliveryDataValidationMap } from '../enums/enums';

export const generateValidationError = (
  issues,
) => `La información de envío no está completa o contiene errores en los siguientes campos:
${issues
  .map((i) => `- ${deliveryDataValidationMap.get(i.path[0])}`)
  .join('\n')}`;

export const getName = (name, surname) => {
  if (!name && !surname) return null;
  if (name && !surname) return name;
  if (!name && surname) return surname;
  return `${name} ${surname}`;
};

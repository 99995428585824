import React from 'react';
import { BanIcon, BellIcon, DownloadIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { ToasterData } from '../../../context/ToasterContext/ToasterContext';
import { Status } from '../SignatureDetails';

const viewExtraAction = {
  value: 'Original',
  action: async (params: { serviceId: string }) => {
    const a = document.createElement('a');
    a.href = `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${params.serviceId}?original=true`;
    a.target = '_blank';
    a.click();
  },
  icon: <DownloadIcon className="h-4" />,
};

const cancelExtraAction = {
  value: 'Cancelar',
  action: async (params: {
    documentId: string;
    setDocumentIdForDelete: (documentId: string) => void;
    setOpenDeleteModal: (open: boolean) => void;
  }) => {
    params.setDocumentIdForDelete(params.documentId);
    params.setOpenDeleteModal(true);
  },
  icon: <BanIcon className="h-4" />,
};

export const extraActionsDictionary = new Map<
  Status,
  {
    value: string;
    action: (params: {
      serviceId?: string;
      documentId?: string;
      getToken?: () => Promise<string>;
      setToasterData?: (data: ToasterData) => void;
      setDocumentIdForDelete?: (documentId: string) => void;
      setOpenDeleteModal?: (open: boolean) => void;
    }) => void;
    icon: JSX.Element;
  }[]
>([
  ['PENDING', [viewExtraAction, cancelExtraAction]],
  ['COMPLETED', [viewExtraAction, cancelExtraAction]],
  ['CANCELLED', [viewExtraAction]],
]);

export const getActions = (
  serviceId: string,
  status: Status,
  documentId: string,
  getToken: () => Promise<string>,
  setToasterData: (data: ToasterData) => void,
) => {
  switch (status) {
    case 'COMPLETED':
      return (
        <a
          href={`${process.env.REACT_APP_SUZUKI_URL}/documents/download/${serviceId}`}
          className="inline-flex gap-2 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Descargar firmado
          <DownloadIcon className="h-4" />
        </a>
      );
    case 'PENDING':
      return (
        <button
          type="button"
          onClick={async () => {
            try {
              const token = await getToken();
              await axios.post(
                `${process.env.REACT_APP_SUZUKI_URL}/documents/notify/${documentId}`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              );
              setToasterData({
                title: 'Notificación enviada',
                message:
                  'La notificación ha sido enviada con éxito a las partes que faltan por firmar.',
                type: 'SUCCESS',
              });
            } catch {
              setToasterData({
                title: 'Error notificando',
                message:
                  'Ha ocurrido un error al intentar enviar la notificación. Por favor, inténtelo de nuevo más tarde.',
                type: 'ERROR',
              });
            }
          }}
          className="inline-flex gap-2 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Reenviar notificación
          <BellIcon className="h-4" />
        </button>
      );
    default:
      return null;
  }
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export default function Tool({ toolComponent, innerRef, provided, snapshot }) {
  return (
    <div
      {...provided.dragHandleProps}
      {...provided.draggableProps}
      ref={innerRef}
      className={`mt-4 bg-white ${snapshot.isDragging && 'bg-yellow-100'}`}
    >
      {toolComponent()}
    </div>
  );
}

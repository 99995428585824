import React, { useEffect, useState } from 'react';

type TypeformButtonProps = {
  formId: string;
  formTitle: string;
  buttonLabel: string;
  nif: string;
};

export default function TypeformButton({
  formId,
  formTitle,
  buttonLabel,
  nif,
}: TypeformButtonProps) {
  const [hiddenData, setHiddenData] = useState<string>('');

  useEffect(() => {
    setHiddenData(`nif=${nif},origin=swipoo-pro`);
  }, [nif]);
  return (
    <button
      type="button"
      data-tf-popup={formId}
      data-tf-size="70"
      data-tf-iframe-props={formTitle}
      data-tf-hidden={hiddenData}
      className="flex justify-center items-center gap-2 px-5 py-2 cursor-pointer rounded-md text-white bg-blue-600 hover:bg-blue-700"
    >
      {buttonLabel}
    </button>
  );
}

import axios from 'axios';

type StripePaymentMethodsResponse = {
  sepas: unknown[];
  cards: unknown[];
};
export const getPaymentMethods = async (
  orgId: string,
  token: string,
): Promise<StripePaymentMethodsResponse> => {
  const [
    {
      data: { data: sepas },
    },
    {
      data: { data: cards },
    },
  ] = await Promise.all([
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/v1/get-payment-info/${orgId}?type=sepa_debit`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/v1/get-payment-info/${orgId}?type=card`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  ]);

  return {
    sepas,
    cards,
  };
};

export const deletePaymentMethod = async (
  paymentMethodId: string,
  token: string,
): Promise<void> => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_API_URL}/payment-method/${paymentMethodId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createSetupIntent = async (
  organizationId: string,
  token: string,
) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/v1/create-setup-intent`,
      {
        orgId: organizationId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

import { PaperClipIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useContext, useMemo, useState } from 'react';
import { BateTramit, Company, Transfer, User } from '../../../types/types';
import fileToBase64 from '../../../helpers/fileToBase64';
import { ExtraFile } from '../CreateSignatureModal';
import { toasterContext } from '../../../context/ToasterContext/ToasterContext';

type CustomModuleProps = {
  extraFiles: ExtraFile[];
  setExtraFiles: (extraFiles: ExtraFile[]) => void;
  tramit: Transfer | BateTramit;
  entityCode: string;
  entityType: 'transaction' | 'bate';
};

function CustomModule({
  extraFiles,
  setExtraFiles,
  tramit,
  entityCode,
  entityType,
}: Readonly<CustomModuleProps>) {
  const [draggingOver, setDraggingOver] = useState(false);

  const { setToasterData } = useContext(toasterContext);

  const { buyer, seller } = tramit;

  const buyerSigner = useMemo(
    () =>
      buyer && {
        id: buyer.id,
        name: (buyer as Company)?.representative?.id
          ? (buyer as Company)?.legalName
          : (buyer as User)?.name,
        role: 'buyer',
        phone:
          (buyer as Company)?.representative?.phone || (buyer as User)?.phone,
      },
    [buyer],
  );

  const sellerSigner = useMemo(
    () => ({
      id: seller.id,
      name: (seller as Company)?.representative?.id
        ? (seller as Company)?.legalName
        : (seller as User)?.name,
      role: 'seller',
      phone:
        (seller as Company)?.representative?.phone || (seller as User)?.phone,
    }),
    [seller],
  );

  const handleSubmitFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    const base64 = await fileToBase64(file);

    setExtraFiles([
      ...extraFiles,
      {
        pdfInBase64: base64,
        id: crypto.randomUUID(),
        signers: [buyerSigner],
        title: file.name,
        entityType,
        entityCode,
        type: 'custom',
      },
    ]);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file.type !== 'application/pdf') {
      setToasterData({
        title: 'Error',
        message: 'Solo se pueden subir archivos PDF',
        type: 'ERROR',
      });
      return;
    }
    const base64 = await fileToBase64(file);
    setExtraFiles([
      ...extraFiles,
      {
        pdfInBase64: base64,
        id: crypto.randomUUID(),
        signers: [buyerSigner],
        title: file.name,
        entityType,
        entityCode,
        type: 'custom',
      },
    ]);
    setDraggingOver(false);
  };
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingOver(true);
  };

  const handleDeleteFile = (id: string) => {
    setExtraFiles(extraFiles.filter((file) => file.id !== id));
  };

  const handleSetSigners = (
    id: string,
    selection: 'buyer' | 'seller' | 'both',
  ) => {
    switch (selection) {
      case 'buyer':
        setExtraFiles(
          extraFiles.map((file) =>
            file.id === id
              ? {
                  ...file,
                  signers: [buyerSigner],
                }
              : file,
          ),
        );
        break;
      case 'seller':
        setExtraFiles(
          extraFiles.map((file) =>
            file.id === id
              ? {
                  ...file,
                  signers: [sellerSigner],
                }
              : file,
          ),
        );
        break;
      case 'both':
        setExtraFiles(
          extraFiles.map((file) =>
            file.id === id
              ? {
                  ...file,
                  signers: [buyerSigner, sellerSigner],
                }
              : file,
          ),
        );
        break;
      default:
    }
  };

  const handleChangeName = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) =>
    setExtraFiles(
      extraFiles.map((file) =>
        file.id === id
          ? {
              ...file,
              title: e.target.value,
            }
          : file,
      ),
    );

  return (
    <>
      <div className="mt-2 w-full flex justify-center">
        <div
          className={`w-10/12 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 ${
            draggingOver && 'border-blue-500 bg-gray-100/50'
          }`}
          data-testid="drop-zone"
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
        >
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500"
              >
                <span>Sube un archivo</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  accept="application/pdf"
                  onChange={handleSubmitFile}
                />
              </label>
              <p className="pl-1">o arrástralo y suéltalo aquí</p>
            </div>
            <p className="text-xs text-gray-500">PDF hasta 10MB</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-center mt-6">
        {extraFiles.map((file) => (
          <div
            className="flex gap-4 items-center justify-center w-full"
            key={file.id}
          >
            <PaperClipIcon className="text-gray-500 h-5 -mr-2" />
            <input
              type="text"
              className="block rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 w-64"
              placeholder="Documento para firmar"
              value={file.title}
              onChange={(e) => handleChangeName(e, file.id)}
            />
            <select
              className="mt-1 block w-64 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 "
              defaultValue="Comprador"
              onChange={(e) =>
                handleSetSigners(
                  file.id,
                  e.target.value as 'buyer' | 'seller' | 'both',
                )
              }
            >
              <option value="buyer">Comprador</option>
              <option value="seller">Vendedor</option>
              <option value="both">Ambos</option>
            </select>
            <button type="button" onClick={() => handleDeleteFile(file.id)}>
              <TrashIcon className="text-red-500 h-6 ml-3" />
            </button>
          </div>
        ))}
      </div>
    </>
  );
}

export default CustomModule;

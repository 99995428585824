import { useAuth0 } from '@auth0/auth0-react';
import { CreditCardIcon, TrashIcon } from '@heroicons/react/outline';
import { AiFillBank } from 'react-icons/ai';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { deletePaymentMethod, getPaymentMethods } from '../services/stripe';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import Button from '../components/Button';
import { toasterContext } from '../context/ToasterContext/ToasterContext';
import NewPaymentMethodModal from '../modals/NewPaymentMethodModal/NewPaymentMethodModal';

export default function PaymentMethod() {
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const { accountInfo } = useContext(userInfoContext);
  const { setToasterData } = useContext(toasterContext);

  const { getAccessTokenSilently } = useAuth0();

  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const generateSEPANumber = () =>
    // eslint-disable-next-line dot-notation
    `${currentPaymentMethod.sepa_debit?.country}XX XXXX XXXX XXXX XXXX ${currentPaymentMethod['sepa_debit']?.last4}`;

  const generateCardNumber = () =>
    `${currentPaymentMethod.card?.brand?.toUpperCase()} XXXX XXXX XXXX XXXX ${
      currentPaymentMethod.card?.last4
    }`;

  const isSEPA = Boolean(currentPaymentMethod?.sepa_debit);

  const handleDeletePaymentMethod = async () => {
    try {
      setIsDeleteLoading(true);
      const token = await getAccessTokenSilently();
      await deletePaymentMethod(currentPaymentMethod.id, token);
      setToasterData({
        type: 'SUCCESS',
        title: 'Método de pago eliminado',
        message: 'El método de pago ha sido eliminado correctamente',
      });
      forceUpdate();
    } catch (e) {
      setToasterData({
        type: 'ERROR',
        title: 'Error',
        message: 'Error eliminando el método de pago',
      });
    } finally {
      setIsDeleteLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const { cards, sepas } = await getPaymentMethods(
        accountInfo.organizationId,
        token,
      );
      setCurrentPaymentMethod(cards[0] || sepas[0]);
    })();
  }, [accountInfo?.organizationId, updater]);

  return (
    <>
      <NewPaymentMethodModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        save={forceUpdate}
      />
      <div className="bg-gray-100 p-6 w-full min-h-[calc(100vh_-_64px)]">
        <div className="w-1/4">
          <div className="flex mb-4">
            <h1 className="text-3xl font-semibold">Método de Pago</h1>
          </div>
          {currentPaymentMethod ? (
            <>
              <div className="p-5 border rounded-md mb-3 bg-white">
                <p>{isSEPA ? generateSEPANumber() : generateCardNumber()}</p>
                <span className="flex items-center gap-2 mt-2">
                  {isSEPA ? (
                    <AiFillBank className="w-5 h-5 text-gray-500" />
                  ) : (
                    <CreditCardIcon className="w-5 h-5 text-gray-500" />
                  )}
                  <h3 className="text-sm text-gray-500">
                    {isSEPA ? 'Adeudo SEPA' : 'Tarjeta de crédito'}
                  </h3>
                </span>
              </div>
              <Button
                bgColor="bg-red-400"
                textColor="white"
                hoverBgColor="bg-red-500"
                RightIcon={TrashIcon}
                text="Eliminar"
                textSm
                callback={handleDeletePaymentMethod}
                loading={isDeleteLoading}
              />
            </>
          ) : (
            <div>
              <Button
                bgColor="bg-blue-600"
                hoverBgColor="bg-blue-700"
                text="Añadir"
                textColor="white"
                callback={() => {
                  setIsModalOpen(true);
                }}
                textSm
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

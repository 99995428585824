import React, { useState } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { Incident } from '../Incidents';

function IncidentWarning({
  incident,
  expandable,
}: Readonly<{
  incident: Incident;
  expandable: boolean;
}>): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className="rounded-md bg-red-50 border border-red-500 p-4">
      <div className="flex w-full">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3 w-full">
          <div className="flex justify-between w-full">
            <h3 className="text-sm font-bold text-red-800">{incident.name}</h3>
            {expandable &&
              (expanded ? (
                <button type="button" onClick={() => setExpanded(false)}>
                  <ChevronUpIcon className="cursor-pointer h-5 w-5 text-red-400" />
                </button>
              ) : (
                <button type="button" onClick={() => setExpanded(true)}>
                  <ChevronDownIcon className="cursor-pointer h-5 w-5 text-red-400" />
                </button>
              ))}
          </div>
          {(expanded || !expandable) && (
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc space-y-1 pl-5">
                {incident.comment ? (
                  <li>
                    <span className="font-bold">Comentario:</span>{' '}
                    {incident.comment}
                  </li>
                ) : (
                  <>
                    <li>
                      <span className="font-bold">Problema:</span>{' '}
                      {incident.description}
                    </li>
                    <li>
                      <span className="font-bold">Solución:</span>{' '}
                      {incident.solution}
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default IncidentWarning;

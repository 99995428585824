import React from 'react';

export default function SkeletonPlaceholder({
  width,
  height,
  marginBottom,
}: {
  width: number | string;
  height: number | string;
  marginBottom?: number;
}) {
  return (
    <div
      className={`bg-gray-300 w-${width} h-${height} mb-${
        marginBottom || 0
      } animate-pulse rounded-full`}
    />
  );
}

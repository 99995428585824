export const brandCarEnums = [
  'ABARTH',
  'AIWAYS',
  'ALFA ROMEO',
  'ALPINE',
  'ARO',
  'ASTON MARTIN',
  'AUDI',
  'AUSTIN',
  'BAIC',
  'BENTLEY',
  'BMW',
  'BMWI',
  'BYD',
  'CADILLAC',
  'CATERHAM',
  'CENNTRO',
  'CHEVROLET',
  'CHRYSLER',
  'CITROEN',
  'CORVETTE',
  'CUPRA',
  'DACIA',
  'DAEWOO',
  'DAIHATSU',
  'DAIMLER',
  'DFSK MOTORS',
  'DODGE',
  'DR MOTOR',
  'DS',
  'FERRARI',
  'FIAT',
  'FISKER',
  'FORD',
  'FSM',
  'GALLOPER',
  'GREEN TOUR',
  'HONDA',
  'HUMMER',
  'HURTAN',
  'HYUNDAI',
  'INEOS',
  'INFINITI',
  'INNOCENTI',
  'ISUZU',
  'IVECO',
  'JAC',
  'JAGUAR',
  'JEEP',
  'KIA',
  'KTM',
  'LADA',
  'LAMBORGHINI',
  'LANCIA',
  'LAND ROVER',
  'LEVC',
  'LEXUS',
  'LINK & CO',
  'LOTUS',
  'MAHINDRA',
  'MASERATI',
  'MAXUS',
  'MAYBACH',
  'MAZDA',
  'MCLAREN',
  'MERCEDES',
  'MG',
  'MINI',
  'MITSUBISHI',
  'MK SPORTSCAR',
  'MOBILIZE',
  'MORGAN',
  'NISSAN',
  'OPEL',
  'PEUGEOT',
  'PGO',
  'PIAGGIO',
  'POLESTAR',
  'PONTIAC',
  'PORSCHE',
  'QOROS',
  'RENAULT',
  'ROLLS ROYCE',
  'ROVER',
  'SAAB',
  'SANTANA',
  'SEAT',
  'SKODA',
  'SMART',
  'SSANGYONG',
  'SUBARU',
  'SUZUKI',
  'SWM',
  'TATA',
  'TESLA',
  'THINK',
  'TOYOTA',
  'VOLKSWAGEN',
  'VOLVO',
  'ZIDHOU',
];

export const brandMotoEnums = [
  'AIXAM',
  'BMW',
  'HONDA',
  'KAWASAKI',
  'KTM',
  'KYMCO',
  'PIAGGIO',
  'SUZUKI',
  'SYM',
  'TRIUMPH',
  'YAMAHA',
  'ADIVIA',
  'AIE',
  'AIYUMO',
  'AJP',
  'ALFER',
  'ALPINA RENANIA',
  'AMERICAN IRONHORSE',
  'APACHE',
  'APOLLO ORION',
  'APRILIA',
  'ARCTIC CAT',
  'ASIAWING',
  'ASKOLL',
  'ASTOR',
  'ATALA',
  'AX ROAD',
  'AZEL',
  'BAJAJ',
  'BAOTIAN',
  'BAROSSA',
  'BATERY',
  'BENELLI',
  'BERECO',
  'BETA',
  'BIG DOG',
  'BIMOTA',
  'BOSS HOSS',
  'BRAMMO',
  'BUELL',
  'BRIXTON',
  'BRP',
  'BTM',
  'BSG ELECTRICS',
  'BUCCIMOTO',
  'BULTACO',
  'BUNKER TRIKE',
  'CAGIVA',
  'CANNONDALE',
  'CANYON',
  'CBK',
  'CFMOTO',
  'CHOPPER NATION',
  'CLARAXAVI',
  'CLIPIC',
  'COLIBIR',
  'CPI',
  'CSR',
  'DAELIM',
  'DAK',
  'DAYUN',
  'DERBI',
  'DH HAOTIAN',
  'DINO',
  'DOBO',
  'DORTON',
  'DRAGON TT',
  'DUCATI',
  'E-MAX',
  'E-TON',
  'EASY TRIKE',
  'EBROH',
  'ECOOTER',
  'EFUN',
  'ELECTRIC CITY MOTOR 00',
  'ELMOTO',
  'EMF',
  'EMOCYCLES',
  'ENERGICA',
  'EXPLORER',
  'FACTORY BIKE',
  'FANTIC',
  'FAURO',
  'FAZTEK',
  'FB MONDIAL',
  'FKM',
  'GARELLI',
  'GAS GAS',
  'GENERIC',
  'GILERA',
  'GOELIX',
  'GOES',
  'GOWINN',
  'HAMMEL',
  'HANWAY',
  'HARLEY DAVIDSON',
  'HELECTRA',
  'HERALD',
  'HM',
  'HONDA',
  'HORWIN',
  'HRD',
  'HSUN',
  'HUATIAN',
  'HUDSON BOSS',
  'HUSABERG',
  'HUSQVARNA',
  'HYOSUNG',
  'I-MOTO',
  'IMAX',
  'IMR',
  'INDIAN',
  'INFMOTO',
  'INNOCENTI',
  'INVICTA',
  'IOTOSTARK',
  'ITALJET',
  'JCADI',
  'JIANSHE',
  'JIN LUN',
  'JINCHENG',
  'JNEN',
  'JONWAY',
  'JTG',
  'JTS',
  'KANGXIN',
  'KASEA',
  'KAWASAKI',
  'KAZUMA',
  'KEEWAY',
  'KENROD',
  'KINROAD',
  'KSR MOTO',
  'KTM',
  'KUBERG',
  'KYMCO',
  'LAMBRETTA',
  'LANVERTTI',
  'LEM',
  'LEMEV',
  'LEONART',
  'LEXMOTO',
  'LIFAN',
  'LING BEN',
  'LINHAI',
  'LML',
  'LUBE',
  'LVNENG',
  'MACBOR',
  'MALAGUTI',
  'MALCOR',
  'MASAI',
  'MASH',
  'MBK',
  'MECATECNO',
  'MEGELLI',
  'MEKO',
  'MERLIN',
  'METRAKIT',
  'MH MOTORCYCLES',
  'MINELLI',
  'MITT',
  'MOBILETTE',
  'MODEN',
  'MONKEY BIKE',
  'MONSTER PRO',
  'MONTEROSSO',
  'MONTESA',
  'MORIWAKI',
  'MOTIVAS',
  'MOTO GUZZI',
  'MOTO MORINI',
  'MOTOGAC',
  'MOTOR HISPANIA',
  'MOTOR MANIA',
  'MTM',
  'MTR',
  'MUZ',
  'MV AGUSTA',
  'MXONDA',
  'NECO',
  'NEXT',
  'NIMOTO',
  'NIU',
  'NORTON',
  'ORCAL',
  'ORION',
  'OSET',
  'OSSA',
  'PEDA',
  'PEUGEOT',
  'PGO',
  'PIAGGIO',
  'POLINI',
  'PUCH',
  'QINGQI',
  'QOODER',
  'QUADRO',
  'QUANTYA',
  'QUAZZAR',
  'RAV',
  'RAYDAN',
  'REBEL',
  'RENAULT',
  'RETTO',
  'REWACO',
  'RIDLEY MOTORCYCLES',
  'RIEJU',
  'RIEJU NUUK',
  'RIYA',
  'ROAN',
  'ROKETA MOTO',
  'ROYAL ALLOY',
  'ROYAL ENFIELD',
  'RSX',
  'SACHS BIKES',
  'SAMADA',
  'SANBEN',
  'SANYOU',
  'SCOMADI',
  'SCOOLECTRIX',
  'SCORPA',
  'SCUTUM',
  'SENKE',
  'SETTER',
  'SHERCO',
  'SHINERAY',
  'SIAM',
  'SILENCE',
  'SINCRO',
  'SINGAZ',
  'SKYTEAM',
  'SORIANO MOTORI',
  'SUMCO',
  'SUMO',
  'SUNRA',
  'SUPER SOCO',
  'SUZUKI',
  'SWM',
  'SYM',
  'TBQ',
  'TGB',
  'THUNDER MOUNTAIN',
  'TM',
  'TORROT',
  'TRAKKER',
  'TRIUMPH',
  'TRS MOTORCYCLES',
  'TURBHO',
  'UM',
  'UNIVERSAL MOTOR',
  'UNORACING',
  'URAL',
  'URBAN ELECTRIC MOTORS',
  'VECTRIX',
  'VELCA',
  'VELIMOTOR',
  'VERTIGO',
  'VESPA',
  'VESPINO',
  'VIA SCOOTER',
  'VICTORY',
  'VIGAR',
  'VIKERS',
  'VMOTO',
  'VOGE',
  'VOLTA MOTORBIKES',
  'VOR',
  'VOXAN',
  'WILDLANDER',
  'WK',
  'WOTTAN',
  'XERO',
  'XINGYUE',
  'XISPA',
  'XMOTOS',
  'XPA',
  'YAMAHA',
  'YCF',
  'YIYING',
  'YMR',
  'YOUNG RIDER',
  'ZELTECH',
  'ZERO',
  'ZERO MOTORCYCLES',
  'ZNEN',
  'ZONGSHEN',
  'ZONTES',
  'ZUAP',
];

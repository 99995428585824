/* eslint-disable react/jsx-props-no-spreading */
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import React from 'react';
import { docDictionary, docFamilyDictionary } from './dictionaries';

function SelectedDocs({ handleDragEnd, selectedDocs }) {
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="p-4"
          >
            <h3 className="text-lg font-medium mb-4">
              Documentos seleccionados
            </h3>
            <div className="flex flex-col mb-4">
              {selectedDocs.map((doc, index) => (
                <Draggable
                  key={`${doc.family}-${doc.docName}`}
                  draggableId={`${doc.family}-${doc.docName}`}
                  index={index}
                >
                  {(dragProvided) => (
                    <div
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      className="px-4 py-2 rounded-md shadow-md bg-white my-1 flex justify-center w-[484px] hover:bg-gray-50"
                    >
                      <p className="truncate">
                        <span className="font-medium">
                          {docFamilyDictionary[doc.family]}
                        </span>
                        :{' '}
                        {docDictionary[doc.docName] ||
                          doc.docName.split('-hash-')[0]}
                      </p>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default SelectedDocs;

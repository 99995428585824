import { useState } from 'react';

import { useServices } from '../../../../../services';
import BaseModal from '../../../../../components/BaseModal';

export default function EditPlateModal({
  car,
  save,
  setIsModalOpen,
  isModalOpen,
}) {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newPlate, setCarNewPlate] = useState(car.plate);

  const { editCar } = useServices();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      await editCar(car.id, {
        plate: newPlate,
      });
      save();
      closeModal();
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseModal
      isOpen={isModalOpen}
      onClose={closeModal}
      overflowVisible={false}
    >
      <div className="p-8">
        {error && (
          <p className="text-red-500">
            Ocurrió un error al actualizar el vehículo
          </p>
        )}
        <span className="py-8">Editar Matrícula</span>
        <div>
          <label htmlFor="plate" className="sr-only">
            Matrícula
          </label>
          <input
            type="text"
            name="plate"
            id="plate"
            placeholder="Matrícula"
            value={newPlate}
            onChange={(e) => setCarNewPlate(e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
          {isLoading ? 'Guardando...' : 'Guardar'}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={closeModal}
        >
          Cancelar
        </button>
      </div>
    </BaseModal>
  );
}

import React, { useState } from 'react';
import BaseModal from '../../../../components/BaseModal';
import { useServices } from '../../../../services';

const TYPE = {
  EXISTING: 'existing',
  NEW: 'new',
};

const REPLACE_STATE = {
  NOT_STARTED: 'NOT_STARTED',
  LOADING: 'LOADING',
};

function ReplaceRepresentativeModal({
  isOpen,
  setIsOpen,
  companyId,
  updateCompanyDetails,
}) {
  const [replaceType, setReplaceType] = useState(TYPE.EXISTING);
  const [replaceState, setReplaceState] = useState(REPLACE_STATE.NOT_STARTED);
  const [existingUserDni, setExistingUserDni] = useState('');

  const [errorMsg, setErrorMsg] = useState('');

  const { replaceCompanyRepresentative } = useServices();

  const handleRadioChange = (event) => {
    if (event.target.value !== TYPE.EXISTING) setExistingUserDni('');
    setReplaceType(event.target.value);
  };

  const handleReplace = async () => {
    setReplaceState(REPLACE_STATE.LOADING);
    setErrorMsg('');
    try {
      await replaceCompanyRepresentative(companyId, existingUserDni);
      setIsOpen(false);
      setExistingUserDni('');
      updateCompanyDetails();
    } catch (err) {
      if (err.response.status === 400) {
        setErrorMsg('El usuario no existe');
        return;
      }
      setErrorMsg('Ocurrió un error al reemplazar el representante');
    } finally {
      setReplaceState(REPLACE_STATE.NOT_STARTED);
    }
  };

  return (
    <BaseModal isOpen={isOpen} onClose={setIsOpen} overflowVisible={false}>
      <div className="p-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Remplazar representante de la empresa
        </h1>
        <p className="mt-2 text-sm text-gray-500">
          A continuación se remplazará al representante de la empresa
        </p>
        <div className="mt-6 flex flex-col gap-2">
          <div className="flex items-center h-9">
            <input
              id="existing"
              name="type"
              type="radio"
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={replaceType === TYPE.EXISTING}
              onChange={handleRadioChange}
              value={TYPE.EXISTING}
            />
            <label
              htmlFor="existing"
              className="ml-3 block text-sm font-medium leading-6 text-gray-900"
            >
              Usuario existente
            </label>
            {replaceType === TYPE.EXISTING && (
              <div>
                <label htmlFor="dni" className="sr-only">
                  Dni
                </label>
                <input
                  type="text"
                  name="dni"
                  id="dni"
                  className="ml-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="DNI del usuario"
                  value={existingUserDni}
                  onChange={(e) => setExistingUserDni(e.target.value)}
                />
              </div>
            )}
          </div>

          <div className="flex items-center">
            <input
              id="new"
              type="radio"
              name="type"
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={replaceType === TYPE.NEW}
              onChange={handleRadioChange}
              value={TYPE.NEW}
            />
            <label
              htmlFor="new"
              className="ml-3 block text-sm font-medium leading-6 text-gray-900"
            >
              Nuevo usuario
            </label>
          </div>
        </div>
        <div className="flex justify-between items-center mt-4">
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 disabled:opacity-50"
            onClick={handleReplace}
            disabled={
              replaceState === REPLACE_STATE.LOADING ||
              (replaceType === TYPE.EXISTING && !existingUserDni)
            }
          >
            Reemplazar
          </button>
          <p className="text-sm text-red-500">{errorMsg}</p>
        </div>
      </div>
    </BaseModal>
  );
}

export default ReplaceRepresentativeModal;

import React, { useContext } from 'react';
import { MdAdd, MdOutlineFlag } from 'react-icons/md';
import FileClockOutlineIcon from './icons/FileClockOutlineIcon';
import EmptyState from '../../../components/EmptyState';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';
import { TransferStatusCategories } from '../../enums/TransferStatusCategories';

export default function OngoingTransferListEmptyState({
  showNewTransferModal,
}: {
  showNewTransferModal: () => void;
}) {
  const { setSingleParam } = useContext(queryParamsContext);

  const seeFinishedTransfers = () => {
    setSingleParam('status_category', TransferStatusCategories.FINISHED);
  };

  return (
    <EmptyState
      icon={FileClockOutlineIcon}
      title="No tienes compraventas en curso "
      message="Actualmente, no hay trámites en proceso. Cuando inicies un trámite, podrás ver su progreso aquí."
      button={
        <>
          <button
            onClick={seeFinishedTransfers}
            className="flex items-center gap-2 py-2 px-3 font-semibold hover:bg-gray-200 rounded-lg transition ease-in-out"
            type="button"
          >
            <MdOutlineFlag className="size-5" />
            Ver trámites finalizados
          </button>{' '}
          <button
            onClick={showNewTransferModal}
            className="flex items-center gap-2 py-2 px-3 font-semibold text-blue-700 bg-blue-50 hover:bg-blue-100 rounded-lg transition ease-in-out"
            type="button"
          >
            <MdAdd className="size-5" />
            Iniciar nuevo trámite
          </button>
        </>
      }
    />
  );
}

import { useState } from 'react';
import { useServices } from '../../../../services';
import ItpForm from '../../tools/components/itpForm';
import useItp from '../../../../hooks/useItp';
import BaseModal from '../../../../components/BaseModal';

export default function NewTransferModal({ setShowModal, showModal, save }) {
  const [userType, setUserType] = useState('BUYER');
  const [dni, setDni] = useState('');
  const [model, setModel] = useState('');
  const [loading, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasEnvironmentalBadge, setHasEnvironmentalBadge] = useState(false);

  const { createUserDni, createCar, createTransaction } = useServices();

  const {
    itpFormValue,
    handleChange,
    setCc,
    setCcaa,
    itpResetValues,
    isModelLoading,
    modelError,
    modelData,
    isFormComplete,
    selectedModel,
    handleModelSelection,
    ccaa,
    itpData,
    isItpLoading,
    itpError,
    cc,
    resetItpForm,
  } = useItp();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!itpFormValue.enrollmentDate || itpFormValue.enrollmentDate === '') {
      return setErrorMessage(
        'Es necesario rellenar el campo de fecha de matriculación',
      );
    }

    setLoader(true);
    try {
      if (!/^[a-zA-Z]?\d{6,8}[a-zA-Z0-9]$/.test(dni)) {
        setLoader(false);
        return setErrorMessage(
          'El formato debe de ser 12345678X (DNI) o X1234567X(NIE)',
        );
      }

      const [{ userId }, { id: carId }] = await Promise.all([
        createUserDni({ dni }),
        createCar({
          brand: itpFormValue.brand,
          model: selectedModel.model || model,
          enrollmentDate: itpFormValue.enrollmentDate,
          fuel: itpFormValue.fuel || null,
          cc: selectedModel.cc || cc,
          cylinders: selectedModel.cylinders,
          powerKw: selectedModel.kw,
          fiscalCv: selectedModel.cvf,
          value: selectedModel.value,
          isMoto: itpFormValue.vehicleType === 'moto',
        }),
      ]);
      await createTransaction({
        carId,
        itpPrice: itpData?.itp,
        isManuallyCreated: true,
        host: 'backoffice',
        [userType === 'SELLER' ? 'sellerId' : 'buyerId']: userId,
        environmentBadge: hasEnvironmentalBadge,
      });

      setLoader(false);
      setErrorMessage('');
      setShowModal(false);
      return save();
    } catch (error) {
      setLoader(false);
      return setErrorMessage(error.message);
    }
  };

  const resetModal = () => {
    setShowModal(false);
    setUserType('BUYER');
    setDni('');
    setModel('');
    setLoader('');
    setErrorMessage('');
    setHasEnvironmentalBadge(false);
    resetItpForm();
  };

  return (
    <BaseModal isOpen={showModal} onClose={resetModal} overflowVisible={false}>
      <div className="space-y-6">
        <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Crear transferencia
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Para la creación de una transferencia se requiere la información
                mínima del&nbsp;
                <span className="font-bold">cliente</span> y el&nbsp;
                <span className="font-bold">vehículo</span>.
              </p>
              <p className="mt-2 text-sm text-gray-500">
                Para saber el ITP usa el widget de la web.
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleSubmit}>
                {errorMessage && (
                  <p className="text-sm text-red-500">{errorMessage}</p>
                )}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label
                      htmlFor="userType"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tipo de usuario:
                    </label>
                    <select
                      id="userType"
                      name="userType"
                      required
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => setUserType(e.target.value)}
                    >
                      <option value="BUYER">Comprador</option>
                      <option value="SELLER">Vendedor</option>
                    </select>
                  </div>
                  <div className="col-span-6">
                    <label
                      htmlFor="dni"
                      className="block text-sm font-medium text-gray-700"
                    >
                      DNI:
                    </label>
                    <input
                      type="text"
                      name="dni"
                      id="dni"
                      required
                      onChange={(e) => setDni(e.target.value)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <ItpForm
                  itpFormValue={itpFormValue}
                  handleChange={handleChange}
                  setCc={setCc}
                  setCcaa={setCcaa}
                  itpResetValues={itpResetValues}
                  isModelLoading={isModelLoading}
                  modelError={modelError}
                  modelData={modelData}
                  isFormComplete={isFormComplete}
                  selectedModel={selectedModel}
                  handleModelSelection={handleModelSelection}
                  ccaa={ccaa}
                  isItpLoading={isItpLoading}
                  itpError={itpError}
                  cc={cc}
                />
                {itpFormValue.vehicleType === 'moto' && ccaa && (
                  <div className="col-span-6 mt-4">
                    <label
                      htmlFor="model"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Modelo:
                    </label>
                    <input
                      type="text"
                      name="model"
                      id="model"
                      required
                      onChange={(e) => setModel(e.target.value)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                )}
                <div className="flex sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
                  <input
                    type="checkbox"
                    onChange={() => setHasEnvironmentalBadge((prev) => !prev)}
                    name="environment-badge"
                    id="environment-badge"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="environmental-badge"
                    className="text-sm font-medium text-gray-700"
                  >
                    Crear distintivo medioambiental
                  </label>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {loading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {loading ? 'Creando...' : 'Crear'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

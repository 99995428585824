import React, { useEffect, useState, useReducer } from 'react';
import usePagination from '../../../hooks/usePagination';
import { useServices } from '../../../services';
import Spinner from '../../../components/Spinner';
import UploadFeesModal from './components/UploadFeesModal';
import DgtFeeTable from './components/DgtFeeTable';
import DgtFeeSummary from './components/DgtFeeSummary';

export default function DgtFees() {
  const [dgtFees, setDgtFees] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedFeeType, setSelectedFeeType] = useState('4.1');
  const [page, goNext, goPrev] = usePagination();
  const [tableLoader, setTableLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { getAllDgtFees } = useServices();

  useEffect(() => {
    const getAllFees = async () => {
      try {
        setTableLoader(true);
        const { data, count: total } = await getAllDgtFees(
          page,
          selectedFeeType,
        );
        setDgtFees(data.map((fee) => ({ ...fee, showEditButton: false })));
        setCount(total);
      } catch (e) {
        setErrorMessage('No se pudieron obtener las tasas');
      } finally {
        setTableLoader(false);
      }
    };
    getAllFees();
  }, [page, selectedFeeType, updater]);

  return (
    <>
      <UploadFeesModal
        showModal={showModal}
        setShowModal={setShowModal}
        save={forceUpdate}
      />
      {errorMessage && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle min-w-full sm:px-6 lg:px-8 flex flex-col">
            <span className="flex justify-between z-0 mb-4 rounded-md py-4">
              <DgtFeeSummary
                selectedFeeType={selectedFeeType}
                setSelectedFeeType={setSelectedFeeType}
                forceUpdate={forceUpdate}
                updater={updater}
              />
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="self-end inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Subir archivo de tasas
              </button>
            </span>
            {tableLoader ? (
              <div className="w-full h-full min-h-[500px] flex justify-center items-center">
                <Spinner color="text-blue-700" size={10} marginTop={28} />
              </div>
            ) : (
              <DgtFeeTable
                dgtFees={dgtFees}
                setDgtFees={setDgtFees}
                setErrorMessage={setErrorMessage}
                page={page}
                goNext={goNext}
                goPrev={goPrev}
                count={count}
                forceUpdate={forceUpdate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

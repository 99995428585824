import React, { useContext, useEffect, useState } from 'react';
import { transferDetailsContext } from '../../../context/TransferDetailsContext/transferDetailsContext';
import useItpPro from '../../../hooks/useItpPro';
import { Company, ItpData, User } from '../../../types/types';
import ItpTableSkeletonLoader from './ItpSkeletonLoader';
import DocumentsItpTable from './Documents';

export default function ItpTable() {
  const { transfer } = useContext(transferDetailsContext);
  const vehicle = transfer?.car;
  const buyer = transfer?.buyer;
  const isCar = !vehicle?.isMoto;

  const itpInfoInitialState: ItpData = {
    value: 0,
    deprecationCoeficient: 0,
    deprecatedValue: 0,
    itpCoeficient: 0,
    itp: 0,
  };

  const [itpInfo, setItpInfo] = useState(itpInfoInitialState);
  const [errorFetchingData, setErrorFetchingData] = useState(false);
  const [isItpInfoLoading, setIsItpInfoLoading] = useState(false);

  const { getItpInfo, getMotoItpInfo } = useItpPro();

  useEffect(() => {
    (async () => {
      const ccaa =
        (buyer as User)?.ccaa || (buyer as Company).fiscalAddressState;

      if (!ccaa) return;

      if ((!vehicle?.model || !vehicle?.enrollmentDate) && isCar) return;

      if ((!vehicle?.cc || !vehicle.enrollmentDate) && !isCar) return;

      try {
        let itpData;
        setIsItpInfoLoading(true);
        if (isCar) {
          itpData = await getItpInfo(
            vehicle?.model,
            vehicle?.enrollmentDate,
            ccaa,
          );
        } else {
          const yearsDifference =
            +new Date() - +new Date(vehicle?.enrollmentDate);

          const yearsOld = Math.floor(
            yearsDifference / (365.24 * 24 * 60 * 60 * 1000),
          );

          itpData = await getMotoItpInfo(vehicle?.cc, yearsOld, ccaa);
        }

        setItpInfo({
          deprecatedValue: itpData?.deprecated_value,
          deprecationCoeficient: itpData?.deprecation_coeficient,
          itp: itpData?.itp,
          itpCoeficient: itpData?.itp_coeficient,
          value: itpData?.value,
        });
        setIsItpInfoLoading(false);
      } catch (e) {
        setErrorFetchingData(true);
        setIsItpInfoLoading(false);
      }
    })();
  }, [
    (buyer as User)?.ccaa,
    vehicle?.enrollmentDate,
    vehicle?.isMoto,
    vehicle?.model,
    getItpInfo,
  ]);

  return (
    <div className="flex flex-col mt-2">
      {errorFetchingData && (
        <p className="text-red-500 mb-2">
          No se ha podido calcular los impuestos. Inténtelo de nuevo
        </p>
      )}
      {isCar &&
        !vehicle?.model &&
        !vehicle?.enrollmentDate &&
        !(buyer as User)?.ccaa && (
          <p className="my-2 text-red-500">
            Para poder calcular los impuestos necesitamos que rellenes los
            campos de fecha de matriculación, Comunidad Autónoma del comprador y
            el modelo del vehículo (con las opciones ofrecidas en el formulario
            de edición)
          </p>
        )}

      {!isCar && !vehicle?.cc && !(buyer as User)?.ccaa && (
        <p className="my-2 text-red-500">
          Para poder calcular los impuestos necesitamos que rellenes los campos
          de cilindrada del vehículo, fecha de matriculación y Comunidad
          Autónoma del comprador.
        </p>
      )}
      {isItpInfoLoading ? (
        <ItpTableSkeletonLoader />
      ) : (
        <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Dato
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Valores calculados
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  <tr key="Valoración tablas Hacienda">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      Valoración tablas Hacienda
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      {itpInfo.value !== null ? `${itpInfo.value}€` : '---'}
                    </td>
                  </tr>
                  <tr key="Porcentaje de depreciación">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      Porcentaje de depreciación
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      {itpInfo.deprecationCoeficient !== null
                        ? `${itpInfo.deprecationCoeficient}%`
                        : '---'}
                    </td>
                  </tr>
                  <tr key="Valor fiscal">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      Valor fiscal
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      {itpInfo.deprecatedValue !== null
                        ? `${itpInfo.deprecatedValue}€`
                        : '---'}
                    </td>
                  </tr>
                  <tr key="Porcentaje aplicado">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      Porcentaje aplicado
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      {itpInfo.itpCoeficient !== null
                        ? `${itpInfo.itpCoeficient}%`
                        : '---'}
                    </td>
                  </tr>
                  <tr key="ITP a liquidar">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      ITP a liquidar
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      {itpInfo.itp !== null ? `${itpInfo.itp}€` : '---'}
                    </td>
                  </tr>
                  <tr key="CET">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      CET
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      {transfer?.transaction.cetCode !== null
                        ? `${transfer?.transaction.cetCode}`
                        : '---'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <DocumentsItpTable />
    </div>
  );
}

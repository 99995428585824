import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon, DownloadIcon } from '@heroicons/react/outline';
import PreviewDocument from '../../../../Modules/Documents/DocumentsTable/PreviewDocuments';
import Button from '../../../../components/Button';
import classNames from '../../../../helpers/classNames';
import useDownloadFile from '../../../../hooks/useDownloadFile';

type Actions = {
  label: string;
  icon: React.ReactElement;
  callback: () => void;
};

function TableActionsItp({
  uri,
  fileName,
}: {
  uri: string;
  fileName: string;
}): React.ReactElement {
  const [showPreview, setShowPreview] = useState(false);

  const { download } = useDownloadFile();

  const switchPreviewModal = () => setShowPreview((prev) => !prev);

  const actions: Actions[] = [
    {
      label: 'Descargar',
      icon: <DownloadIcon className="w-5 h-5" />,
      callback: () => {
        download(uri, fileName);
      },
    },
  ];

  return (
    <>
      {uri && <PreviewDocument uri={uri} toggler={showPreview} />}
      <td className="flex justify-end whitespace-nowrap py-4 px-8 text-sm font-medium text-gray-900">
        {uri ? (
          <>
            <Button
              bgColor="bg-white"
              hoverBgColor="bg-gray-100"
              text="Ver"
              textColor="text-gray-900"
              border="border"
              borderColor="border-gray-300"
              callback={switchPreviewModal}
            />
            <Menu
              as="div"
              className="relative inline-block text-left pt-2 ml-3"
            >
              <div>
                <Menu.Button className="flex items-center">
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-8 top-7 -translate-y-full z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {actions.map((action) => (
                      <Menu.Item key={action.label}>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'flex px-4 py-2 text-sm w-full items-center justify-start gap-3',
                            )}
                            onClick={() => action.callback()}
                          >
                            {action.icon}
                            {action.label}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </>
        ) : (
          <p>No hay documento para mostrar</p>
        )}
      </td>
    </>
  );
}

export default TableActionsItp;

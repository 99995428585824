import { useCallback, useContext, useEffect, useState } from 'react';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';

export default function useDateParams() {
  const { params } = useContext(queryParamsContext);

  const [dateSortParam, setDateSort] = useState<string>(params.get('dateSort'));

  const [minDateParam, setMinDateParam] = useState<string>(
    params.get('minDate'),
  );
  const [maxDateParam, setMaxDateParam] = useState<string>(
    params.get('maxDate'),
  );

  const getDateParams = useCallback(() => {
    const minDateRangeParam = minDateParam
      ? `&since=${new Date(minDateParam).getTime() / 1000}`
      : '';
    const maxDateRangeParam = maxDateParam
      ? `&until=${new Date(maxDateParam).getTime() / 1000}`
      : '';

    if (
      minDateRangeParam &&
      maxDateRangeParam &&
      minDateParam === maxDateParam
    ) {
      return `&since=${new Date(minDateParam).getTime() / 1000}&until=${
        new Date(maxDateParam).getTime() / 1000 + 24 * 60 * 60
      }`;
    }
    if (
      minDateRangeParam &&
      maxDateRangeParam &&
      minDateParam !== maxDateParam
    ) {
      return `${minDateRangeParam}${maxDateRangeParam}`;
    }
    if (minDateRangeParam && !maxDateRangeParam) return minDateRangeParam;
    if (!minDateRangeParam && maxDateRangeParam) return maxDateRangeParam;
    if (!minDateRangeParam && !maxDateRangeParam) return '';
    return '';
  }, [maxDateParam, minDateParam]);

  useEffect(() => {
    const currentMinDate = params.get('minDate');
    const currentMaxDate = params.get('maxDate');
    const currentDateSort = params.get('dateSort');

    if (currentMinDate !== minDateParam) {
      setMinDateParam(currentMinDate);
    }
    if (currentMaxDate !== maxDateParam) {
      setMaxDateParam(currentMaxDate);
    }
    if (currentDateSort !== dateSortParam) {
      setDateSort(currentDateSort);
    }
  }, [params, minDateParam, maxDateParam, dateSortParam]);

  return { getDateParams, dateSortParam, minDateParam, maxDateParam };
}

import { useAuth0 } from '@auth0/auth0-react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import axios from 'axios';

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { toasterContext } from '../context/ToasterContext/ToasterContext';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import useCopyEntityData from '../hooks/useCopyEntityData';

function ReplaceVehicleModal({
  open,
  setOpen,
  forceUpdate,
  sourceCarId,
}: {
  open: boolean;
  setOpen: (o: boolean) => void;
  forceUpdate: () => void;
  sourceCarId: string;
}) {
  const [search, setSearch] = useState('');

  const [notFound, setNotFound] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const { accountInfo } = useContext(userInfoContext);
  const { setToasterData } = useContext(toasterContext);

  type Vehicle = {
    id: string;
    plate: string;
    name: string;
  };

  const [foundVehicles, setFoundVehicles] = useState<Vehicle[]>([]);

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

  const { copyEntityData } = useCopyEntityData();

  const fetchVehicles = async () => {
    try {
      const token = await getAccessTokenSilently();
      const url = `${process.env.REACT_APP_BASE_API_URL}/get-cars-related-to-account/${accountInfo.id}?query=${search}`;

      const { data: vehicles } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (vehicles.length === 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
      setFoundVehicles(vehicles);
    } catch {
      setToasterData({
        message:
          'No se han podido obtener los datos. Por favor, inténtelo de nuevo más tarde o contacte con nosotros.',
        title: 'Error en la búsqueda',
        type: 'ERROR',
      });
    }
  };

  useEffect(() => {
    if (search.length < 3) {
      setFoundVehicles([]);
      setNotFound(false);
    }
  }, [search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search.length > 3) {
        fetchVehicles();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!open) {
      setFoundVehicles([]);
      setSearch('');
      setSelectedVehicle(null);
    }
  }, [open]);

  const handleReplace = async () => {
    try {
      await copyEntityData(sourceCarId, selectedVehicle.id, 'car');
      forceUpdate();
      setToasterData({
        message: 'El vehículo ha sido actualizado correctamente.',
        title: 'Vehículo reemplazado',
        type: 'SUCCESS',
      });
      setOpen(false);
    } catch {
      setToasterData({
        message:
          'No se ha podido actualizar el vehículo. Por favor, inténtelo de nuevo más tarde o contacte con nosotros.',
        title: 'Error en el reemplazo',
        type: 'ERROR',
      });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all w-[512px] p-4">
                <Combobox
                  as="div"
                  value={selectedVehicle}
                  onChange={setSelectedVehicle}
                  className="relative mt-1 max-h-60"
                >
                  <div className="flex">
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                      Busca y selecciona el nuevo vehículo del trámite.
                    </Combobox.Label>
                    <div className="absolute right-0 top-0 hidden sm:block items-end">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      >
                        <XIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                          onClick={() => setOpen(false)}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="relative mt-2">
                    <div className="relative mt-4 rounded-md shadow-sm">
                      <Combobox.Input
                        autoComplete="off"
                        className="w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Introduce la matrícula o marca y modelo"
                        type="text"
                        name="query"
                        id="query"
                      />
                    </div>
                    {foundVehicles.length > 0 && (
                      <Combobox.Options className="absolute mt-2 max-h-60 z-50 w-full overflow-y-auto overflow-x-hidden rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <div>
                          <ul className="divide-y divide-gray-100">
                            {foundVehicles
                              .filter((_, i) => i < 10)
                              .map(({ id, name, plate }) => (
                                <button
                                  type="button"
                                  key={id}
                                  className="p-1 hover:bg-gray-100 cursor-pointer text-sm text-gray-800 flex gap-x-4 py-3 w-full"
                                  onClick={() => {
                                    setSelectedVehicle({
                                      id,
                                      name,
                                      plate,
                                    });
                                    setSearch('');
                                  }}
                                >
                                  <div className="min-w-0 flex justify-between w-full">
                                    <p className="text-sm font-semibold leading-6 text-gray-900 ml-2">
                                      {name} ({plate})
                                    </p>
                                  </div>
                                </button>
                              ))}
                          </ul>
                        </div>
                      </Combobox.Options>
                    )}
                  </div>
                  {notFound && (
                    <p className="text-red-500 text-xs mt-1 -mb-5">
                      No se han encontrado resultados
                    </p>
                  )}
                  {selectedVehicle && (
                    <div className="mt-5 sm:mt-6">
                      <p className="text-sm text-gray-500">Seleccionado:</p>
                      <p className="text-sm text-gray-800">
                        {selectedVehicle.name} ({selectedVehicle.plate})
                      </p>
                    </div>
                  )}
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      onClick={handleReplace}
                    >
                      Usar
                    </button>
                  </div>
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ReplaceVehicleModal;

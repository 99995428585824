import React, { useState, useContext } from 'react';
import {
  ChatIcon,
  InformationCircleIcon,
  DocumentDuplicateIcon,
  TruckIcon,
} from '@heroicons/react/outline';

import Documents from '../Modules/Documents';
import Comments from '../Modules/Comments/Comments';
import { Tab, TabsType } from '../components/Tabs';
import RegistrationDetailsHeader from './components/RegistrationDetailsHeader';
import {
  registrationDetailsContext,
  RegistrationDetailsContextProvider,
} from '../context/RegistrationDetailsContext/registrationDetailsContext';
import GeneralInfo from './tabs/GeneralInfo/GeneralInfo';
import Shipments from '../Modules/Shipments/Shipments';
import { ShipmentsContextProvider } from '../context/ShipmentsContext/shipmentsContext';

function RegistrationDetails() {
  const tabs: TabsType = {
    information: {
      id: 'information',
      title: 'Información',
      icon: <InformationCircleIcon className="h-5 w-5 mb-2.5" />,
    },
    documents: {
      id: 'documents',
      title: 'Documentación',
      icon: <DocumentDuplicateIcon className="h-5 w-5 mb-2.5" />,
    },
    shipment: {
      id: 'shipment',
      title: 'Envíos',
      icon: <TruckIcon className="h-5 w-5 mb-2.5" />,
    },
    comments: {
      id: 'comments',
      title: 'Comentarios',
      icon: <ChatIcon className="h-5 w-5 mb-2.5" />,
    },
  };

  const [currentTab, setCurrentTab] = useState<Tab>(tabs.information);

  const { registrationTramit, registrationLoading, forceUpdate } = useContext(
    registrationDetailsContext,
  );

  const getComponent = (tabId: keyof TabsType): JSX.Element => {
    if (tabId === 'documents') {
      return (
        <Documents
          tramit={registrationTramit}
          entityType="registration"
          forceUpdate={forceUpdate}
        />
      );
    }
    if (tabId === 'shipment') return <Shipments />;
    if (tabId === 'comments') {
      return (
        <Comments
          entityId={registrationTramit?.registration?.id}
          tramitCode={registrationTramit?.registration?.registrationCode}
          tramitType="REGISTRATION"
        />
      );
    }
    return (
      <GeneralInfo tramit={registrationTramit} forceUpdate={forceUpdate} />
    );
  };

  const handleChangeTab = (tabId: string) => () => {
    setCurrentTab(Object.values(tabs).find((tab) => tab.id === tabId));
  };

  return (
    <ShipmentsContextProvider
      entityId={registrationTramit?.registration?.id}
      entity={registrationTramit?.registration}
    >
      <div className="bg-gray-100 py-10 flex justify-center min-h-screen">
        <div className="w-2/3">
          <RegistrationDetailsHeader
            tabs={tabs}
            forceUpdate={forceUpdate}
            tramit={registrationTramit}
            isLoading={registrationLoading}
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
          />
          {getComponent(currentTab.id)}
        </div>
      </div>
    </ShipmentsContextProvider>
  );
}

export default function RegistrationDetailsWrapper() {
  return (
    <RegistrationDetailsContextProvider>
      <RegistrationDetails />
    </RegistrationDetailsContextProvider>
  );
}

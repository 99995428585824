import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect, useContext } from 'react';

import SearchBox from './SearchBox';
import {
  userInfoContext,
  UserInfoContextType,
} from '../../../../context/UserInfoContext/UserInfoContext';
import {
  createTransferContext,
  CreateTransferModalContextType,
} from '../../../../context/CreateTransferContext/CreateTransferContext';
import classNames from '../../../../helpers/classNames';
import { parseDayMonthYear } from '../../../../helpers/parseDate';

export type BateOption = {
  id: string;
  bateCode: string;
  brand: string;
  model: string;
  plate: string;
  createdAt?: Date;
};

export default function LinkBate(): JSX.Element {
  const [selected, setSelected] = useState<BateOption | null>(null);
  const [bateOptions, setBateOptions] = useState<BateOption[]>([]);
  const [total, setTotal] = useState<number>(0);

  const { getAccessTokenSilently } = useAuth0();
  const { accountInfo } = useContext<UserInfoContextType>(userInfoContext);
  const { setFormValue } = useContext<CreateTransferModalContextType>(
    createTransferContext,
  );

  const fetchShortListOfBateOptions = async (): Promise<void> => {
    const token = await getAccessTokenSilently();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/v1/list-initiated-batecom/${accountInfo.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setTotal(data.total);
      setBateOptions(data.batecoms);
    } catch (e) {
      // do nothing
    }
  };

  const handleSelectedBate = (bateId: string): void => {
    setFormValue((prevState) => ({
      ...prevState,
      batecomId: bateId,
    }));
  };

  const handleSelectBate = (bateOption: BateOption) => {
    setSelected(bateOption);
    handleSelectedBate(bateOption.id);
  };

  useEffect(() => {
    fetchShortListOfBateOptions();
  }, []);

  return (
    <div className="w-full">
      <p className="mb-4 font-semibold">
        Selecciona el trámite Batecom que quieres finalizar.
      </p>
      {total <= 6 ? (
        <div className="mt-2 grid grid-cols-3 gap-4 w-full">
          {bateOptions.map((bate) => (
            <button
              key={bate.id}
              type="button"
              onClick={() => handleSelectBate(bate)}
              onKeyDown={() => handleSelectBate(bate)}
              className={classNames(
                'border rounded-lg py-[17px] px-[25px] w-full',
                selected?.id === bate.id && 'border-2 border-blue-600',
              )}
            >
              <p className="font-semibold text-sm text-left">{`${
                bate.brand ?? '---'
              } ${bate.model ?? '---'} (${bate.plate ?? '---'})`}</p>
              <p className="text-gray-400 text-sm text-left">
                {bate.bateCode}
                <span className="hidden sm:mx-1 sm:inline">&middot;</span>{' '}
                <span className="block sm:inline">
                  {parseDayMonthYear(new Date(bate.createdAt))}
                </span>
              </p>
            </button>
          ))}
        </div>
      ) : (
        <SearchBox
          selected={selected}
          setSelected={setSelected}
          accountId={accountInfo.id}
          handleSelectedBate={handleSelectedBate}
        />
      )}
    </div>
  );
}

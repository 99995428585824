import axios from 'axios';

export const obtainZipCodeByAddress = async (address) => {
  const {
    data: { zipCode },
  } = await axios.get(
    `${process.env.REACT_APP_PIAGGIO_BASE_URL}/get-zipcode-from-address?address=${address}`,
  );

  return zipCode;
};

import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { PaperClipIcon } from '@heroicons/react/solid';
import { useServices } from '../../../services';
import FileLink from '../../../components/FileLink';

export default function BadgeDetails() {
  const [badge, setBadge] = useState(null);
  const { id } = useParams();
  const [transaction, setTransaction] = useState(null);

  const { getBadgeById, getTransactionById } = useServices();

  useEffect(() => {
    const fetchBadge = async () => {
      const badgeFound = await getBadgeById(id);
      setBadge(badgeFound);

      if (badgeFound.transactionId) {
        const transactionFound = await getTransactionById(
          badgeFound.transactionId,
        );

        if (transactionFound) {
          setTransaction(transactionFound);
        }
      }
    };
    fetchBadge();
  }, [id]);

  return (
    badge && (
      <>
        <div className="flex justify-between">
          <div>
            <h3 className="text-xl leading-6 font-medium text-gray-900 inline">
              Distintivo Medioambiental
            </h3>
          </div>
        </div>
        <div className="mt-3 border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Transferencia asociada
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {badge.transactionId === null ? (
                  <span className="flex-grow">
                    {' '}
                    No está asociado a ninguna transferencia
                  </span>
                ) : (
                  <span className="flex-grow">
                    <Link
                      className="text-sm text-gray-900 cursor-pointer hover:text-blue-600"
                      to={`/transfers/${transaction?.transactionCode}`}
                      key={transaction?.transactionCode}
                    >
                      {transaction?.transactionCode}
                    </Link>
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Tipo</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{badge.badgeType}</span>
                {!badge?.transactionId && (
                  <span className="ml-4 shrink-0">
                    <button
                      type="button"
                      className="hidden rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Editar
                    </button>
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Matrícula</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{badge?.plate}</span>
                {!badge?.transactionId && (
                  <span className="ml-4 shrink-0">
                    <button
                      type="button"
                      className="hidden rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Editar
                    </button>
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Nombre</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{badge?.name}</span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    className="hidden rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{badge?.email}</span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    className="hidden rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">DNI</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{badge?.dni}</span>
                <button type="button" className="hidden text-indigo-600">
                  Editar
                </button>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Documentos</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        DNI - cara
                      </span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {badge.photoDni && badge.photoDni[0] ? (
                        <>
                          <FileLink source={badge.photoDni[0]} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick={() => {}}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onClick={() => {}}
                          className="hidden rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        DNI - reverso
                      </span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {badge.photoDni && badge.photoDni[1] ? (
                        <>
                          <FileLink source={badge.photoDni[1]} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick={() => {}}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onClick={() => {}}
                          className="hidden rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        Permiso de circulación provisional
                      </span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {badge.photoProvisional ? (
                        <>
                          <FileLink source={badge.photoProvisional} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick={() => {}}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onClick={() => {}}
                          className="hidden rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </>
    )
  );
}

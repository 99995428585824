import React from 'react';
import ItpTableBody from './ItpTableBody';

function DocumentsItpTable(): React.ReactElement {
  return (
    <div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300 rounded-md">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Documento
            </th>
            <th
              scope="col"
              className="px-8 py-3.5 text-right text-sm font-semibold text-gray-900"
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          <ItpTableBody />
        </tbody>
      </table>
    </div>
  );
}

export default DocumentsItpTable;

import axios from 'axios';
import React, { useEffect, useState, useReducer, useContext } from 'react';

import Spinner from '../../../../components/Spinner';
import { useServices } from '../../../../services';
import { transferDetailsContext } from '../../../../context/transfer-details.context';

export default function AssignBox() {
  const [tramitAssignedUser, setTramitAssignedUser] = useState(null);
  const [tramitAssignLoading, setTramitAssignLoading] = useState(false);
  const [tramitAssignError, setTramitAssignError] = useState(null);
  const [companyMembers, setCompanyMembers] = useState([]);
  const [searchField, setSearchField] = useState('');
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { getSwipooMembers } = useServices();

  const { transfer } = useContext(transferDetailsContext);
  const { transaction } = transfer;

  useEffect(() => {
    const fetchAssignations = async () => {
      setTramitAssignLoading(true);
      try {
        const data = await axios
          .get(
            `${process.env.REACT_APP_PIAGGIO_BASE_URL}/transactions/${transaction.id}`,
          )
          .then((res) => res.data);
        setTramitAssignedUser(data);
      } catch (e) {
        setTramitAssignedUser(null);
      } finally {
        setTramitAssignLoading(false);
      }
    };
    fetchAssignations();
  }, [transaction.id, updater]);

  const loadMembers = async () => {
    if (companyMembers.length === 0) {
      const fetchedCompanyMembers = await getSwipooMembers();
      setCompanyMembers(fetchedCompanyMembers);
    }
  };

  const handleAssignTramit = async () => {
    if (
      searchField !== '' &&
      companyMembers.some(
        (e) => e.name.toLowerCase() === searchField.toLowerCase(),
      )
    ) {
      try {
        setTramitAssignLoading(true);
        const companyMember = companyMembers.find(
          (e) => e.name.toLowerCase() === searchField.toLowerCase(),
        );
        await axios.post(
          `${process.env.REACT_APP_PIAGGIO_BASE_URL}/transactions/${companyMember.email}/${transaction.id}`,
        );
      } catch (e) {
        setTramitAssignError(e);
      } finally {
        setTramitAssignLoading(false);
        forceUpdate();
      }
    }
  };

  const handleRemoveTramit = async () => {
    try {
      setTramitAssignLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_PIAGGIO_BASE_URL}/transactions/${tramitAssignedUser.email}/${transaction.id}`,
      );
    } catch (e) {
      setTramitAssignError(e);
    } finally {
      setTramitAssignLoading(false);
      forceUpdate();
    }
  };

  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mb-5">
      <h2 className="text-lg font-medium text-gray-900 mb-1">Responsable</h2>
      {tramitAssignLoading && (
        <div className="flex justify-center">
          <Spinner size={6} color="indigo-700" />
        </div>
      )}
      {!tramitAssignLoading && tramitAssignedUser && (
        <p className="mb-4">{tramitAssignedUser.email}</p>
      )}
      {!tramitAssignLoading && !tramitAssignedUser && (
        <div className="relative flex flex-col gap-3">
          <label htmlFor="user-email" className="sr-only">
            Email:
          </label>
          <input
            className="border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
            id="user-email"
            placeholder="Correo electrónico"
            type="text"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            onFocus={loadMembers}
            autoComplete="off"
          />
          {searchField !== '' && (
            <ul className="absolute top-10 mt-2 flex flex-col gap-3 bg-white shadow-md z-10 w-full">
              {companyMembers
                .filter(
                  (e) =>
                    e.name.toLowerCase().includes(searchField.toLowerCase()) &&
                    e.name.toLowerCase() !== searchField.toLowerCase(),
                )
                .map((e) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                  <li
                    className="cursor-pointer hover:bg-gray-100 p-3"
                    onClick={() => setSearchField(e.name)}
                    key={e.name}
                  >
                    {e.name}
                  </li>
                ))}
            </ul>
          )}
          <button
            onClick={handleAssignTramit}
            type="button"
            className="bg-indigo-500 text-white hover:bg-indigo-700 rounded-md px-2 py-1"
          >
            Asignar
          </button>
        </div>
      )}
      {!tramitAssignLoading && tramitAssignedUser && (
        <button
          onClick={handleRemoveTramit}
          type="button"
          className="bg-red-500 text-white hover:bg-red-700 rounded-md px-2 py-1"
        >
          Desasignar transferencia
        </button>
      )}
      {tramitAssignError && (
        <p className="text-red-500 text-sm">{tramitAssignError}</p>
      )}
    </div>
  );
}

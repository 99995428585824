import React, { useContext, useEffect, useState } from 'react';
import { DuplicateForTable } from '../..';
import Table, { Column } from '../../../components/TypeformComponents/Table';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';

export default function DuplicatesTable({
  duplicates,
  columns,
}: {
  duplicates: DuplicateForTable[];
  columns: Column[];
}) {
  const [dateSort, setDateSort] = useState('DESC');

  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);

  useEffect(() => {
    const dateSortParam = getSingleParam('dateSort') || 'DESC';
    setDateSort(dateSortParam);
  }, [params, getSingleParam]);

  return (
    <Table
      columns={columns}
      data={duplicates}
      dateSort={dateSort}
      setSingleParam={setSingleParam}
    />
  );
}

import React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Menu } from '@headlessui/react';

import ContextMenu from './ContextMenu';

export type ContextMenuOptionType = {
  id: number;
  RightIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element | null;
  label: string;
  onClick: () => void;
  isVisible: boolean;
};

export default function ContextMenuButton({
  contextMenuOptions,
}: {
  contextMenuOptions: ContextMenuOptionType[];
}) {
  const optionsToShow = contextMenuOptions.filter(
    (contextMenuOption) => contextMenuOption.isVisible,
  );
  return (
    Boolean(optionsToShow.length) && (
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button className="flex justify-center items-center gap-2 px-1 py-2 bg-white cursor-pointer rounded-md text-gray-700 border-2 border-gray-200 hover:bg-gray-100 text-sm">
          <BsThreeDotsVertical
            className="w-5 h-5 text-gray-400"
            data-testid="right-icon"
          />
          <ContextMenu contextMenuOptions={optionsToShow} />
        </Menu.Button>
      </Menu>
    )
  );
}

import React, { useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdError } from 'react-icons/md';
import ShipmentBatcherTableRows from '../ShipmentBatcherTableRows';
import Spinner from '../../../../../../../components/Spinner';
import { STEPS } from '../../enums/enums';
import { useServices } from '../../../../../../../services';
import { GENERIC_STATUS } from '../../../../../../../helpers/enums';

export default function SelectShipments({
  selectedShipments,
  setSelectedShipments,
  setCurrentStep,
  account,
  deliveryAddresses,
  error,
  setError,
  successMessage,
  setDeliveryAddresses,
}) {
  const [shipments, setShipments] = useState({ data: [], loading: false });

  const {
    getShipmentsByAccountId,
    getAccountTramitIdsByStatus,
    getAllDeliveryAddress,
  } = useServices();

  const resetState = () => {
    setShipments({ data: [], loading: false });
  };

  const handleSelectAllShipments = () =>
    setSelectedShipments(shipments.data.map((s) => s.id));

  const handleDeselectAllShipments = () => setSelectedShipments([]);

  const fetchShipments = async () => {
    try {
      setError('');
      setSelectedShipments([]);
      setShipments((prev) => ({ ...prev, loading: true }));
      const response = await getShipmentsByAccountId(account.id);

      const tramitIdsWithDocumentationOnOffice =
        await getAccountTramitIdsByStatus(
          account.id,
          GENERIC_STATUS.DOCS_AT_OFFICE,
        );

      const populatedShipmentsWithDocumentationOnOffice = response
        .map((s) => ({
          ...s,
          entityId: s.items[0].entityId,
          entityType: s.items[0].entityType,
          plate: s.items[0].plate,
        }))
        .filter((s) => tramitIdsWithDocumentationOnOffice.includes(s.entityId));
      setShipments((prev) => ({
        ...prev,
        data: populatedShipmentsWithDocumentationOnOffice,
      }));
    } catch (e) {
      setError('Ha ocurrido un error al buscar las órdenes de envío');
    } finally {
      setShipments((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (!account.billingNif || successMessage) return;

    fetchShipments();
  }, [account]);

  useEffect(() => {
    if (!account.id) return;
    (async () => {
      try {
        setDeliveryAddresses((prev) => ({ ...prev, loading: true }));
        const addresses = await getAllDeliveryAddress(account.id);
        setDeliveryAddresses((prev) => ({ ...prev, data: addresses }));
      } catch (e) {
        setError('Ha ocurrido un error al buscar las direcciones de envío');
      } finally {
        setDeliveryAddresses((prev) => ({ ...prev, loading: false }));
      }
    })();
  }, [account]);

  useEffect(() => {
    if (!successMessage) return;
    resetState();
  }, [successMessage]);

  return (
    <>
      <div className="flex justify-between items-center">
        <h3 className="text-md text-black font-medium border-none">
          Agrupador de Envíos
        </h3>

        <button
          type="button"
          className={`px-2 py-1 rounded-md ${
            selectedShipments.length
              ? 'bg-indigo-600 hover:bg-indigo-700 text-white'
              : 'bg-gray-300 hover:bg-gray-400 text-gray-500'
          }`}
          disabled={!selectedShipments.length}
          onClick={() => setCurrentStep(STEPS.SELECT_ADDRESS)}
        >
          Agrupar
        </button>
      </div>

      {successMessage && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AiFillCheckCircle
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Envío en grupo generado
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>{successMessage}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-2">
        {(shipments.loading || deliveryAddresses.loading) && (
          <div className="flex justify-center items-center w-full h-full">
            <Spinner color="text-indigo-600" size="10" marginTop="0" />
          </div>
        )}
        {!shipments.loading && Boolean(shipments.data.length) && (
          <div className="flex flex-col items-center">
            <div className="inline-block w-full align-middle">
              <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50 w-full">
                    <tr className="flex items-center">
                      <th
                        scope="col"
                        className="relative px-6 sm:w-16 sm:px-8 w-[10%]"
                      >
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                          checked={
                            selectedShipments.length === shipments.data.length
                          }
                          onChange={
                            selectedShipments.length === shipments.data.length
                              ? handleDeselectAllShipments
                              : handleSelectAllShipments
                          }
                        />
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 w-[30%]"
                      >
                        Matrícula
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 w-[50%]"
                      >
                        Items
                      </th>
                      <th scope="col" className="relative w-[10%]">
                        <span>
                          {selectedShipments.length} / {shipments.data.length}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white h-52 overflow-y-scroll block">
                    <ShipmentBatcherTableRows
                      shipments={shipments}
                      deliveryAddresses={deliveryAddresses}
                      selectedShimpents={selectedShipments}
                      setSelectedShipments={setSelectedShipments}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {account.billingNif &&
          shipments.data.length === 0 &&
          !shipments.loading &&
          !successMessage && (
            <p className="text-gray-500">
              No se encontraron envíos para esta empresa
            </p>
          )}
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <MdError className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <div className="mt-2 text-sm text-red-700">
                  <p className="whitespace-pre-line">{error}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export const STEPS = {
  SELECT_SHIPMENTS: 'SELECT_SHIPMENTS',
  SELECT_ADDRESS: 'SELECT_ADDRESS',
};

export const deliveryDataValidationMap = new Map([
  ['name', 'Nombre del representante'],
  ['dni', 'DNI del representante'],
  ['email', 'Correo electrónico del representante'],
  ['phone', 'Teléfono del representante'],
  ['postcode', 'Código Postal de la empresa'],
  ['state', 'Comunidad Autónoma de la empresa'],
  ['city', 'Ciudad de la empresa'],
  ['address', 'Dirección de la empresa'],
]);

import React from 'react';
import axios from 'axios';
import { MdDownload } from 'react-icons/md';
import { BadgeCheckIcon, ExclamationIcon } from '@heroicons/react/solid';
import { PDFDocument } from '@cantoo/pdf-lib';

import { parseDayMonthYear } from '../../../../helpers/parseDate';
import {
  MechanicalWarrantyInfoType,
  modalityDict,
} from '../../../../services/mechanicalWarraties';

type InformationTypeProps = {
  mechanicalWarranty: MechanicalWarrantyInfoType;
};

type FieldType = {
  label: string;
  displayValue: string;
  value: string | number;
};

export function Information({ mechanicalWarranty }: InformationTypeProps) {
  const {
    numPolicy,
    effectiveDate,
    issueDate,
    annualPremium,
    policyInfo,
    plate,
  } = mechanicalWarranty;

  const beneficiary = policyInfo.persons.find(
    (p) => p.personPolicyType === 'BENEFICIARY',
  );

  const holder = policyInfo.persons.find(
    (p) => p.personPolicyType === 'HOLDER',
  );

  const infoFields: FieldType[] = [
    {
      label: 'effectiveDate',
      displayValue: 'Fecha efectiva',
      value: parseDayMonthYear(effectiveDate),
    },
    {
      label: 'issueDate',
      displayValue: 'Fecha de emisión',
      value: parseDayMonthYear(issueDate),
    },
    {
      label: 'duration',
      displayValue: 'Duración',
      value: `${policyInfo.duration} año${
        policyInfo.duration === 1 ? '' : 's'
      }`,
    },
    {
      label: 'annualPremium',
      displayValue: 'Precio anual',
      value: `${annualPremium} €`,
    },
    {
      label: 'paymentStatus',
      displayValue: 'Pagado',
      value:
        policyInfo.paymentInformation?.paymentStatus === 'PAID'
          ? 'Pagado'
          : 'No pagado',
    },
    {
      label: 'policyStatus',
      displayValue: 'Estado',
      value: policyInfo.status === 'ACTIVE' ? 'Activa' : 'Inactiva',
    },
  ];

  const beneficiaryFields: FieldType[] = [
    {
      label: 'name',
      displayValue: 'Nombre',
      value: beneficiary.name,
    },
    {
      label: 'surname',
      displayValue: 'Apellidos',
      value: beneficiary.surname,
    },
    {
      label: 'document',
      displayValue: 'DNI',
      value: beneficiary.document,
    },
    {
      label: 'address',
      displayValue: 'Dirección',
      value: `${beneficiary.address}, ${beneficiary.postalCode}, ${beneficiary.town}`,
    },
    {
      label: 'email',
      displayValue: 'Correo',
      value: beneficiary.email,
    },
    {
      label: 'phone',
      displayValue: 'Teléfono',
      value: beneficiary.phoneNumber,
    },
  ];

  const vehicleFields: FieldType[] = [
    {
      label: 'brand',
      displayValue: 'Marca',
      value: policyInfo.insuredObject.brand,
    },
    {
      label: 'model',
      displayValue: 'Modelo',
      value: policyInfo.insuredObject.model,
    },
    {
      label: 'chassisNumber',
      displayValue: 'Bastidor',
      value: policyInfo.insuredObject.chassisNumber,
    },
    {
      label: 'kilometers',
      displayValue: 'Kilómetros',
      value: `${policyInfo.insuredObject.kilometers} Kms`,
    },
    {
      label: 'engineCapacity',
      displayValue: 'Cilindrada',
      value: `${policyInfo.insuredObject.engineCapacity} cc`,
    },
    {
      label: 'carRegistrationDate',
      displayValue: 'Fecha de matriculación',
      value: parseDayMonthYear(policyInfo.insuredObject.carRegistrationDate),
    },
  ];

  const generateCertifcate = async () => {
    const modalityCode = policyInfo?.productInfo?.modalityCode;
    const fileName = `Cert ${modalityDict[modalityCode]} ${plate}.pdf`;

    const response = await axios.get(
      'https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/Cert+Ip-138+race.pdf',
      { responseType: 'arraybuffer' },
    );

    const pdfDoc = await PDFDocument.load(response.data);

    const form = pdfDoc.getForm();

    form
      .getTextField('start_date_guarantee')
      .setText(parseDayMonthYear(new Date(effectiveDate)));
    form
      .getTextField('end_date_guarantee')
      .setText(parseDayMonthYear(new Date(policyInfo.expirationDate)));
    form.getTextField('dealership_name').setText(holder.name);
    form.getTextField('email').setText(holder.email);
    form.getTextField('phone').setText(holder.phoneNumber);
    form.getTextField('vin').setText(policyInfo.insuredObject.chassisNumber);
    form
      .getTextField('kms')
      .setText(String(policyInfo.insuredObject.kilometers));
    form
      .getTextField('enrollment_date')
      .setText(
        parseDayMonthYear(
          new Date(policyInfo.insuredObject.carRegistrationDate),
        ),
      );
    form.getTextField('plate').setText(plate);
    form.getTextField('brand').setText(policyInfo.insuredObject.brand);
    form.getTextField('model').setText(policyInfo.insuredObject.model);

    form.flatten();

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const pdfURL = window.URL.createObjectURL(blob);
    const downloadElement = document.createElement('a');
    downloadElement.target = '_blank';
    downloadElement.rel = 'noopner noreferrer';
    downloadElement.href = pdfURL;
    downloadElement.download = fileName;
    downloadElement.click();
  };

  const renderIconsWhenNecessary = (e: FieldType): React.ReactElement => {
    if (e.label !== 'paymentStatus' && e.label !== 'policyStatus') return null;
    if (e.value === 'Activa' || e.value === 'Pagado')
      return <BadgeCheckIcon className="text-teal-500 h-5 ml-1" />;
    return <ExclamationIcon className="text-red-500 h-5 ml-1" />;
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 pt-5 pb-4 sm:px-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-1">
            Póliza núm. {numPolicy}
          </h3>
          <button
            onClick={generateCertifcate}
            className="flex items-center text-sm gap-2 py-2 px-3 font-semibold text-blue-700 bg-blue-50 hover:bg-blue-100 rounded-lg transition ease-in-out"
            type="button"
          >
            <MdDownload className="size-4" />
            Descargar Certificado
          </button>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">
            Información
          </span>
        </div>
      </div>
      <div className="mb-3">
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            {infoFields?.map((e: FieldType) => (
              <React.Fragment key={e.label}>
                <div className="sm:col-span-1" key={e.label}>
                  <dt className="text-sm font-medium text-gray-500">
                    {e.displayValue}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 flex">
                    {e.value || '---'} {renderIconsWhenNecessary(e)}
                  </dd>
                </div>
              </React.Fragment>
            ))}
          </dl>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">
            Beneficiario
          </span>
        </div>
      </div>
      <div className="mb-3">
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            {beneficiaryFields?.map((e: FieldType) => (
              <React.Fragment key={e.label}>
                <div className="sm:col-span-1" key={e.label}>
                  <dt className="text-sm font-medium text-gray-500">
                    {e.displayValue}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 flex">
                    {e.value || '---'}
                  </dd>
                </div>
              </React.Fragment>
            ))}
          </dl>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">Vehículo</span>
        </div>
      </div>
      <div className="mb-3">
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            {vehicleFields?.map((e: FieldType) => (
              <React.Fragment key={e.label}>
                <div className="sm:col-span-1" key={e.label}>
                  <dt className="text-sm font-medium text-gray-500">
                    {e.displayValue}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 flex">
                    {e.value || '---'}
                  </dd>
                </div>
              </React.Fragment>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

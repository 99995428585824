import { useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/outline';

import { useServices } from '../../../../../../services';

export default function TrafficReportSection({
  uri,
  transactionCode,
  forceUpdate,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const { requestTrafficReportFromTransaction } = useServices();

  const handleRequestTrafficReport = async () => {
    try {
      setIsLoading(true);
      await requestTrafficReportFromTransaction(transactionCode);
      setIsLoading(false);
      forceUpdate();
    } catch (e) {
      // Show error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex items-center">
        <PaperClipIcon
          className="shrink-0 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <span className="ml-2 flex-1 w-0 truncate">Informe de tráfico</span>
      </div>
      <div className="ml-4 shrink-0 flex space-x-4">
        {uri ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={uri}
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Ver
          </a>
        ) : (
          <button
            type="button"
            onClick={handleRequestTrafficReport}
            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
          >
            {isLoading ? 'Cargando...' : 'Pedir y enviar'}
          </button>
        )}
      </div>
    </li>
  );
}

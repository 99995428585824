import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from '../../../../helpers/classNames';
import { SWIPOO_STATUS } from '../../../../shared/transaction-status';
import { parseDayMonthYear } from '../../../../helpers/parseDate';
import { RelatedTransaction as RelatedTransactionType } from '../../../../types/types';

function RelatedTransaction({
  relatedTramit,
}: Readonly<{
  relatedTramit: RelatedTransactionType;
}>): JSX.Element {
  const [relatedTramitExpanded, setRelatedTramitExpanded] = useState(false);

  const relatedTramitStatuses: {
    [key: number]: {
      style: string;
      text: string;
    };
  } = {
    [SWIPOO_STATUS.PENDING_DOCUMENTATION]: {
      style: 'text-gray-600 bg-gray-50 ring-gray-500/10',
      text: 'No presentado',
    },
    [SWIPOO_STATUS.REVIEW_PENDING_DOCUMENTATION]: {
      style: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      text: 'Presentado a gestoría',
    },
    [SWIPOO_STATUS.DIGITAL_CONTRACT_SENT]: {
      style: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      text: 'Presentado a gestoría',
    },
    [SWIPOO_STATUS.DIGITAL_CONTRACT_SIGNED]: {
      style: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      text: 'Presentado a gestoría',
    },
    [SWIPOO_STATUS.PRESENTED_TO_AGENCY]: {
      style: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      text: 'Presentado a gestoría',
    },
    [SWIPOO_STATUS.PRESENTED_TO_DGT]: {
      style: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      text: 'Presentado a DGT',
    },
    [SWIPOO_STATUS.FINISHED_BY_DGT]: {
      style: 'text-green-700 bg-green-50 ring-green-600/20',
      text: 'Finalizado DGT',
    },
    [SWIPOO_STATUS.CIRCULATION_PERMIT_AT_OFFICE]: {
      style: 'text-green-700 bg-green-50 ring-green-600/20',
      text: 'Finalizado DGT',
    },
    [SWIPOO_STATUS.CIRCULATION_PERMIT_SENT]: {
      style: 'text-green-700 bg-green-50 ring-green-600/20',
      text: 'Finalizado DGT',
    },
    [SWIPOO_STATUS.CIRCULATION_PERMIT_DELIVERED]: {
      style: 'text-green-700 bg-green-50 ring-green-600/20',
      text: 'Finalizado DGT',
    },
  };

  if (!relatedTramit) return null;

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex justify-between w-full">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-1">
            Trámites relacionados
          </h3>
          {relatedTramitExpanded ? (
            <button
              type="button"
              onClick={() => setRelatedTramitExpanded(false)}
            >
              <ChevronUpIcon
                className="h-5 w-5 text-gray-400 cursor-pointer"
                aria-hidden="true"
              />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setRelatedTramitExpanded(true)}
            >
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400 cursor-pointer"
                aria-hidden="true"
              />
            </button>
          )}
        </div>
        {relatedTramitExpanded && (
          <div className="mt-2">
            <ul className="divide-y divide-gray-100">
              <li className="flex items-center justify-between gap-x-6 py-5">
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {relatedTramit.plate} - {relatedTramit.brand}{' '}
                      {relatedTramit.model}
                    </p>
                    <p
                      className={classNames(
                        relatedTramitStatuses[relatedTramit.status].style,
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {relatedTramitStatuses[relatedTramit.status].text}
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="truncate">Venta - Batecom</p>
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="whitespace-nowrap">
                      <time dateTime={relatedTramit.createdAt}>
                        {parseDayMonthYear(new Date(relatedTramit.createdAt))}
                      </time>
                    </p>
                  </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  <Link
                    to={`/transfer/${relatedTramit.transactionCode}`}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                  >
                    Ver trámite
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default RelatedTransaction;

import React, { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { BiErrorCircle } from 'react-icons/bi';
import { Shipment } from '../../types/types';
import Spinner from '../../../../components/Spinner';
import classNames from '../../../../helpers/classNames';
import { AsyncState } from '../../../../types/types';
import { getTrackingByShipmentId } from '../../../../services/shipment.services';

export type TrackingResult = {
  id: string;
  status: string;
  shipmentId: string;
  createdAt: Date;
}[];

function CurrentStepIcon() {
  return (
    <div className="w-6 h-6 flex items-center justify-center bg-blue-200 rounded-full z-10">
      <div className="w-3 h-3 bg-blue-500 rounded-full" />
    </div>
  );
}
function PendingStepIcon() {
  return (
    <div className="w-6 h-6 flex items-center justify-center">
      <div className="w-3 h-3 bg-blue-100 rounded-full" />
    </div>
  );
}
function CompletedStepIcon() {
  return (
    <div className="w-6 h-6 flex items-center justify-center">
      <div className="w-3 h-3 bg-blue-500 rounded-full" />
    </div>
  );
}
function StepConnector({
  stateStatus,
  shipmentStatus,
}: {
  stateStatus: number;
  shipmentStatus: number;
}) {
  return (
    <div
      className={classNames(
        stateStatus >= shipmentStatus ? 'bg-blue-100' : 'bg-blue-500',
        'w-1 h-16 -mt-2',
      )}
    />
  );
}

export const trackingStates = [
  {
    label: 'Pedido recibido',
    mappedState: 'SHIPPED',
    status: 1,
  },
  {
    label: 'Envío preparado',
    mappedState: 'SHIPPED',
    status: 2,
  },
  {
    label: 'En tránsito',
    mappedState: 'IN_TRANSIT',
    status: 3,
  },
  {
    label: 'En proceso de entrega',
    mappedState: 'OUT_FOR_DELIVERY',
    status: 4,
  },
  {
    label: 'Entregado',
    mappedState: 'DELIVERED',
    status: 5,
  },
];

function Tracking({
  shipment,
  isLoading,
}: {
  shipment: Shipment;
  isLoading: boolean;
}) {
  const [shipmentStatus, setShipmentStatus] = useState<number>(1);
  const [tracking, setTracking] = useState<AsyncState<TrackingResult | null>>({
    data: null,
    error: null,
    isLoading: false,
  });

  const { getAccessTokenSilently } = useAuth0();

  const getTracking = async (shipmentId: string) => {
    try {
      if (!shipment) return;

      const token = await getAccessTokenSilently();

      const { data } = await getTrackingByShipmentId(shipmentId, token);

      setTracking((prev) => ({ ...prev, data }));
    } catch (e) {
      setTracking((prev) => ({
        ...prev,
        error: 'Hubo un error al cargar el tracking',
      }));
    } finally {
      setTracking((prev) => ({ ...prev, isLoading: false }));
    }
  };

  useEffect(() => {
    getTracking(shipment.id);
  }, [shipment]);

  useEffect(() => {
    if (!shipment) return;

    const newShipmentStatus = () => {
      switch (shipment.status) {
        case 'CREATED':
          return 1;
        case 'SHIPPED':
          return 2;
        case 'IN_TRANSIT':
          return 3;
        case 'OUT_FOR_DELIVERY':
          return 4;
        case 'DELIVERED':
          return 5;
        default:
          return 1;
      }
    };
    setShipmentStatus(newShipmentStatus());
  }, [shipment]);

  const trackingStatesWithTimes = trackingStates.map((state) => ({
    ...state,
    dateOccurred: tracking.data?.find((t) => t.status === state.mappedState)
      ?.createdAt,
  }));

  return (
    <div
      className={classNames(
        'bg-white border border-gray-300 rounded-md min-h-[434px] h-full pl-10 pr-6 pt-7 pb-10',
        isLoading && 'flex justify-center items-center',
      )}
    >
      <h4 className="text-lg font-semibold mb-8">Estado del pedido</h4>
      {isLoading && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner color="text-blue-700" size={16} marginTop={0} />
        </div>
      )}
      {tracking.error && (
        <div className="flex flex-col items-center justify-center">
          <BiErrorCircle className="w-8 h-8 text-red-500" />
          <p className="text-red-500">{tracking.error}</p>
        </div>
      )}
      <div className="flex flex-col text-gray-500 text-sm">
        {trackingStatesWithTimes.map((state) => (
          <div key={state.status} className="flex items-center gap-x-3 -mt-2">
            <div className="flex flex-col items-center">
              {state.status === shipmentStatus && <CurrentStepIcon />}
              {state.status < shipmentStatus && <CompletedStepIcon />}
              {state.status > shipmentStatus && <PendingStepIcon />}
              {state.status !== 5 && (
                <StepConnector
                  stateStatus={state.status}
                  shipmentStatus={shipmentStatus}
                />
              )}
            </div>
            <div className="flex flex-col justify-between w-full self-start">
              <p>{state.label}</p>
              <span>
                {state.dateOccurred
                  ? new Date(state.dateOccurred).toLocaleString([], {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  : '-'}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tracking;

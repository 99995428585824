import React, { useContext } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';

import { digitallySignContext } from '../../../context/DigitallySignContext/DigitallySignContext';
import { DigitallySignSteps } from './DigitallySignStepsManager';

type EditDocumentProps = {
  readonly docId: string;
};

export default function EditDocument({ docId }: Readonly<EditDocumentProps>) {
  const { setCurrentDigitallySignStep } = useContext(digitallySignContext);

  const handlePreviousStep = () => {
    setCurrentDigitallySignStep(DigitallySignSteps.CHOOSE_TEMPLATE);
  };

  const handleNextStep = () => {
    setCurrentDigitallySignStep(DigitallySignSteps.SEND_DOC_RECIPIENTS);
  };

  return (
    <>
      <h1 className="text-4xl text-zinc-700 font-bold">
        Firmar nuevo documento
      </h1>
      <h3 className="text-xl text-zinc-600 font-semibold mt-4">
        Editar documento
      </h3>
      <iframe
        title="Editar documento"
        height="800"
        width="1000"
        className="my-10"
        src={`https://docs.google.com/document/d/${docId}`}
      />
      <footer className="w-full flex justify-between gap-8">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200"
        >
          <MdArrowBack className="size-5" />
          Atrás
        </button>
        <button
          type="button"
          onClick={handleNextStep}
          className="flex items-center gap-1.5 px-3 py-2 bg-blue-800 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-900 active:bg-blue-950"
        >
          Siguiente
          <MdArrowForward className="size-5" />
        </button>
      </footer>
    </>
  );
}

import React, { useContext, useEffect } from 'react';
import { transferDetailsContext } from '../../../../../../context/transfer-details.context';
import { useAxios } from '../../../../../../hooks/useAxios';
import { partsDictionary } from '../../../../../../components/SignatureDetails/helpers/dictionaries';

function DigitalSignatureDocs({ selectedDocs, handleInputCheck }) {
  const { transfer } = useContext(transferDetailsContext);

  const { data, runRequest } = useAxios(
    `${process.env.REACT_APP_SUZUKI_URL}/documents/transaction/${transfer?.transaction?.transactionCode}`,
  );

  useEffect(() => {
    if (!transfer?.transaction?.transactionCode) return;
    runRequest();
  }, [transfer?.transaction?.transactionCode]);

  return (
    <div>
      <h3 className="text-lg font-medium">Firma digital</h3>
      <div className="flex flex-col gap-2 mb-4 mt-2">
        {data
          ?.filter((doc) => doc.status === 'COMPLETED')
          .map((doc) => (
            <div key={doc.id} className="flex items-center gap-2">
              <input
                id={doc.id}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                checked={selectedDocs.some(
                  (e) =>
                    e.family === 'digitalSignature' &&
                    e.docName ===
                      doc.parts
                        .map((part) => partsDictionary.get(part))
                        .join(' - '),
                )}
                onChange={(e) =>
                  handleInputCheck(
                    e.target.checked,
                    'digitalSignature',
                    doc.parts
                      .map((part) => partsDictionary.get(part))
                      .join(' - '),
                    `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${doc.serviceId}`,
                  )
                }
              />
              <label className="w-72 truncate" htmlFor={doc.id}>
                {doc.parts.map((part) => partsDictionary.get(part)).join(' - ')}
              </label>
            </div>
          ))}
      </div>
    </div>
  );
}

export default DigitalSignatureDocs;

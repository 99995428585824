import { useState, useEffect } from 'react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

import { useServices } from '../../../../../services';
import ChangePricePlanModal from './ChangePricePlanModal';

export default function Priceplan({ pricePlanId, accountId }) {
  const [pricePlan, setPricePlan] = useState(null);
  const [changePricePlanModalOpen, setChangePricePlanModalOpen] =
    useState(false);

  const { getPricePlanById } = useServices();

  useEffect(() => {
    if (!pricePlanId) return;
    const fetchPricePlan = async () => {
      const response = await getPricePlanById(pricePlanId);
      setPricePlan(response);
    };
    fetchPricePlan();
  }, [pricePlanId]);

  return (
    <>
      <ChangePricePlanModal
        accountId={accountId}
        isOpen={changePricePlanModalOpen}
        setIsOpen={setChangePricePlanModalOpen}
      />
      <div className="px-4 sm:px-0">
        <div className="flex flex-none items-center gap-x-4 justify-between">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Plan de pago
          </h3>
          <button
            type="button"
            onClick={() => {
              setChangePricePlanModalOpen(true);
            }}
            className="text-sm text-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Editar
          </button>
        </div>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          {pricePlan?.name ? pricePlan.name : 'Sin plan de pago'}
        </p>
      </div>
      {pricePlanId && pricePlan && (
        <div className="mt-3 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Transferencias
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.transactionPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Batecom - compra
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.batecomBuyPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Batecom - venta
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.batecomSellPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Duplicado
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.duplicatePrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Matriculación
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.enrollmentPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Reserva de dominio
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.domainReservationPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Distintivo
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.environmentBadgePrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Envíos
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.shipmentPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Informe DGT
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.trafficReportPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Omitir Factura
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.omitInvoice ? (
                    <CheckIcon className="text-teal-500 h-6 m-auto" />
                  ) : (
                    <XIcon className="text-red-500 h-6 m-auto" />
                  )}
                </span>
              </dd>
            </div>
            <div className="px-4 pt-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                Omitir pago del servicio
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:mt-0">
                <span>
                  {pricePlan.omitServicePayment ? (
                    <CheckIcon className="text-teal-500 h-6 m-auto" />
                  ) : (
                    <XIcon className="text-red-500 h-6 m-auto" />
                  )}
                </span>
              </dd>
            </div>
          </dl>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import calculateTextColor from '../helpers/calculateTextColor';
import useClickOutside from '../hooks/useClickOutside';
import { useServices } from '../services';
import { classNames } from '../helpers';

const entityTypes = [
  { label: 'Transferencia', value: 'TRANSACTION' },
  { label: 'Cuenta', value: 'ACCOUNT' },
  { label: 'Bate', value: 'BATE' },
  { label: 'Matriculación', value: 'REGISTRATION' },
];

function LabelModal({ isOpen, setIsOpen, save, preloadedLabel }) {
  const [newLabel, setNewLabel] = useState({
    name: 'Ejemplo',
    color: '#000000',
    entityType: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (preloadedLabel) {
      setNewLabel({
        name: preloadedLabel.name,
        color: preloadedLabel.color,
        entityType: preloadedLabel.entityType,
      });
    }
  }, [preloadedLabel]);

  const { createLabel, editLabel } = useServices();

  const handleChange = (e) => {
    setNewLabel({ ...newLabel, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (preloadedLabel) {
        await editLabel(preloadedLabel.id, newLabel);
      } else {
        await createLabel(newLabel);
      }
      setNewLabel({ name: 'Ejemplo', color: '#000000', entityType: '' });
      setIsOpen(false);
      save();
    } catch (err) {
      if (err.message.includes('409')) {
        setError(
          `Ya existe una etiqueta con tipo ${
            entityTypes.find((type) => type.value === newLabel.entityType)
              ?.label
          } y texto ${newLabel.name}`,
        );
      } else {
        setError('Hubo un error al guardar la etiqueta');
      }
    }
  };

  const ref = useRef(null);
  useClickOutside(ref, () => {
    setIsOpen(false);
  });
  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-black/75 flex justify-center items-center">
      <div
        ref={ref}
        className="bg-white p-5 rounded-md shadow-md flex flex-col items-center w-[320px]"
      >
        <h2 className="text-xl font-medium">
          {preloadedLabel ? 'Editar etiqueta' : 'Nueva etiqueta'}
        </h2>
        {error && <p className="text-red-500 my-2">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mt-2">
            <label htmlFor="entityType">Tipo</label>
            <select
              className="border border-gray-300 rounded-md px-2 py-1"
              name="entityType"
              id="entityType"
              onChange={handleChange}
              defaultValue={preloadedLabel?.entityType || ''}
            >
              <option value="" disabled>
                --- Elegir ---
              </option>
              {entityTypes.map((entityType) => (
                <option key={entityType.value} value={entityType.value}>
                  {entityType.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex gap-4">
            <div className="flex flex-col gap-2 mt-5">
              <label htmlFor="name">Texto</label>
              <input
                type="text"
                name="name"
                id="name"
                value={newLabel.name}
                onChange={handleChange}
                className="border border-gray-300 rounded-md px-2 py-1"
              />
            </div>
            <div className="flex flex-col gap-2 mt-5">
              <label htmlFor="color">Color</label>
              <input
                type="color"
                name="color"
                id="color"
                value={newLabel.color}
                onChange={handleChange}
                className="border border-gray-300 rounded-md px-2 py-1"
              />
            </div>
          </div>

          <div className="mt-5 flex flex-col items-start">
            <div
              className={`h-7 rounded-full font-medium self-center ${
                calculateTextColor(newLabel.color) === 'white'
                  ? 'text-white'
                  : 'text-gray-700'
              } flex items-center justify-center px-4`}
              style={{ backgroundColor: newLabel.color }}
            >
              {newLabel.name}
            </div>
          </div>

          <button
            type="submit"
            className={classNames(
              'rounded-md px-2 py-1 mt-5 w-full',
              newLabel.name && newLabel.color && newLabel.entityType
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300 text-gray-500',
            )}
            disabled={!newLabel.name || !newLabel.color || !newLabel.entityType}
          >
            {preloadedLabel ? 'Editar' : 'Crear'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default LabelModal;

import { useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';

import { ENTITY_TYPES } from '../../../helpers/enums';
import Labels from '../../../components/Labels/Labels';
import Tabs, { tabs } from './components/tabs';
import { accountDetailsContext } from '../../../context/account-details.context';

function PlatformBadge({ platform }) {
  return platform === 'ANCOVE' ? (
    <span className="h-fit rounded-md self-end ml-4 bg-pink-50 px-2 py-1 text-xs leading-none font-medium text-pink-900 ring-1 ring-inset ring-pink-900/10">
      ANCOVE
    </span>
  ) : (
    <span className="h-fit rounded-md self-end ml-4 bg-blue-50 px-2 py-1 text-xs leading-none font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
      SWIPOO PRO
    </span>
  );
}

export default function AccountDetails() {
  const [currentTabs, setCurrentTabs] = useState(tabs);

  const { accountId } = useParams();

  const { account, accountLoading, forceUpdate } = useContext(
    accountDetailsContext,
  );

  if (accountLoading) return null;

  return (
    <>
      <div className="flex">
        {account.companyLogo && (
          <img
            className="h-12 w-18 rounded-md mr-4"
            src={account.companyLogo}
            alt="logo de empresa"
          />
        )}
        <h3 className="text-3xl font-medium text-gray-900 leading-none self-end">
          {account.name}
        </h3>
        <PlatformBadge platform={account.platform} />
        <div className="self-end ml-2">
          <Labels
            forceUpdate={forceUpdate}
            labels={account.labels}
            entityId={accountId}
            entityType={ENTITY_TYPES.ACCOUNT}
          />
        </div>
      </div>
      <Tabs
        setCurrentTabs={setCurrentTabs}
        currentTabs={currentTabs}
        forceUpdate={forceUpdate}
      />
      {currentTabs.filter((tab) => tab.current)[0].component}
    </>
  );
}

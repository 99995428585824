import axios from 'axios';
import { useEffect, useState } from 'react';

function useIsValidZipCode(zipCode) {
  const [isValidZipCode, setIsValidZipCode] = useState(false);

  useEffect(() => {
    const checkIfZipCodeIsValid = async () => {
      const response = await axios
        .get(`https://husqvarna.swipoo-bc3.workers.dev/zipCode/${zipCode}`)
        .then((res) => res.data);
      const zipCodeExists = response.some(
        // eslint-disable-next-line camelcase
        ({ zip_code }) => zip_code === zipCode,
      );
      setIsValidZipCode(zipCodeExists);
    };
    if (zipCode) checkIfZipCodeIsValid();
  }, [zipCode]);

  return {
    isValidZipCode,
  };
}

export default useIsValidZipCode;

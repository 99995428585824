export const formChangeHandler = (
  setFormValue,
  formValue,
  targetName,
  targetValue,
  setCc,
) => {
  let newState;
  if (targetName === 'vehicleType') {
    newState = {
      ...formValue,
      [targetName]: targetValue,
      brand: '',
      enrollmentDate: '',
      fuel: '',
    };
  } else if (targetName === 'brand') {
    newState = {
      ...formValue,
      [targetName]: targetValue,
      enrollmentDate: '',
      fuel: '',
    };
  } else if (targetName === 'enrollmentDate') {
    if (formValue.vehicleType === 'car') {
      newState = {
        ...formValue,
        [targetName]: targetValue,
        fuel: '',
      };
    }
    if (formValue.vehicleType === 'moto') {
      newState = {
        ...formValue,
        [targetName]: targetValue,
        fuel: '',
      };
    }
  } else {
    newState = {
      ...formValue,
      [targetName]: targetValue,
    };
  }
  setCc('');
  setFormValue(newState);
};

import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { BadgeCheckIcon, ExclamationIcon } from '@heroicons/react/solid';

import {
  isValidCIF,
  isValidDNI,
  isValidNIE,
} from '../../../../../helpers/doiValidators';
import { ENTITY_TYPES } from '../../../../../helpers/enums';
import ExtraFiles from '../../../../../components/ExtraFiles';

export default function CompanyData({ account }) {
  const isAutonomo = useMemo(
    () => isValidDNI(account.company?.nif) || isValidNIE(account.company?.nif),
    [account.company?.nif],
  );
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5">
        <div className="px-4 flex justify-between">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Datos de la empresa
          </h3>
          <Link
            to={`/companies/${account.company.id}`}
            className="align-baseline rounded-md bg-white font-sm text-blue-600 hover:text-blue-500"
          >
            Ver
          </Link>
        </div>
        <div className="mt-4 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-4 grid">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Razon social
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700">
                <span className="flex-grow">{account.company.legalName}</span>
              </dd>
            </div>
            <div className="px-4 py-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                NIF
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700">
                <span className="mr-2">{account.company.nif}</span>
                {account.company?.nif && (
                  <span className="flex-grow">
                    {isValidCIF(account.company?.nif) || isAutonomo ? (
                      <BadgeCheckIcon className="text-teal-500 h-6" />
                    ) : (
                      <ExclamationIcon className="text-red-500 h-6" />
                    )}
                  </span>
                )}
              </dd>
            </div>
            <div className="px-4 py-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Direccion fiscal
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700">
                <span className="flex-grow">
                  {account.company?.fiscalAddressAddress},{' '}
                  {account.company?.fiscalAddressCity},{' '}
                  {account.company?.fiscalAddressProvince},{' '}
                  {account.company?.fiscalAddressZipCode}
                </span>
              </dd>
            </div>
            <div className="py-4">
              <ExtraFiles tramitType={ENTITY_TYPES.ACCOUNT} entity={account} />
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

import { Combobox, Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import React, { Fragment, useEffect, useState } from 'react';
import { useServices } from '../../services';

const entityTypesDictionary = {
  user: {
    label: ' el nuevo usuario',
    searchPlaceholder: 'Introduce el nombre, email, teléfono o dni del usuario',
    searchDisplay: ({ name, lastName, dni }) => (
      <span>
        {name} {lastName} - {dni}
      </span>
    ),
  },
  company: {
    label: ' la nueva compañía',
    searchPlaceholder: 'Introduce el nombre, razón social o cif de la compañía',
    searchDisplay: ({ name, nif }) => (
      <span>
        {name} - {nif}
      </span>
    ),
  },
  car: {
    label: ' el nuevo vehículo',
    searchPlaceholder:
      'Introduce la matrícula, bastidor o marca y/o modelo del vehículo',
    searchDisplay: ({ brand, model, plate }) => (
      <span>
        {model} {brand} - {plate}
      </span>
    ),
  },
};

function ReplaceEntityeModal({
  open,
  closeModal,
  forceUpdate,
  sourceEntityId,
  entityType,
}) {
  const [search, setSearch] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [foundEntities, setFoundEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [error, setError] = useState('');

  const { copyEntityData, searchEntities } = useServices();

  const fetchEntities = async () => {
    try {
      setError('');

      const entitiesFound = await searchEntities(search, entityType);

      if (entitiesFound.length === 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
      setFoundEntities(entitiesFound);
    } catch (e) {
      setError('Error buscando entidades');
    }
  };

  useEffect(() => {
    if (search.length < 3) {
      setFoundEntities([]);
      setNotFound(false);
    }
  }, [search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search.length > 0) {
        fetchEntities();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!open) {
      setFoundEntities([]);
      setSearch('');
      setSelectedEntity(null);
    }
  }, [open]);

  const handleReplace = async () => {
    try {
      setError('');

      await copyEntityData(sourceEntityId, selectedEntity.id, entityType);
      forceUpdate();

      closeModal();
    } catch (e) {
      setError('Error copiando los datos');
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all w-[512px] p-4">
                <Combobox
                  as="div"
                  value={selectedEntity}
                  onChange={setSelectedEntity}
                  className="relative mt-1 max-h-60"
                >
                  <div className="flex">
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                      Busca y selecciona
                      {entityTypesDictionary[entityType]?.label} del trámite.
                    </Combobox.Label>
                    {Boolean(error) && (
                      <span className="text-red-500">{error}</span>
                    )}
                    <div className="absolute right-0 top-0 hidden sm:block items-end">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      >
                        <XIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                          onClick={() => closeModal()}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="relative mt-2">
                    <div className="relative mt-4 rounded-md shadow-sm">
                      <Combobox.Input
                        autoComplete="off"
                        className="w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={
                          entityTypesDictionary[entityType]?.searchPlaceholder
                        }
                        type="text"
                        name="query"
                        id="query"
                      />
                    </div>
                    {foundEntities.length > 0 && (
                      <Combobox.Options className="absolute mt-1 max-h-60 z-50 w-full overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <div>
                          <ul className="divide-y divide-gray-100">
                            {foundEntities
                              .filter((_, i) => i < 10)
                              .map((entity) => (
                                <button
                                  type="button"
                                  key={entity?.id}
                                  className="p-2 hover:bg-gray-100 cursor-pointer text-sm text-gray-800 flex gap-x-4 py-3 w-full"
                                  onClick={() => {
                                    setSelectedEntity(entity);
                                    setSearch('');
                                  }}
                                >
                                  <div className="min-w-0">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">
                                      {entityTypesDictionary[
                                        entityType
                                      ]?.searchDisplay(entity)}
                                    </p>
                                  </div>
                                </button>
                              ))}
                          </ul>
                        </div>
                      </Combobox.Options>
                    )}
                  </div>
                  {notFound && (
                    <p className="text-red-500 text-xs mt-1 -mb-5">
                      No se han encontrado resultados
                    </p>
                  )}
                  {selectedEntity && (
                    <div className="mt-5 sm:mt-6">
                      <p className="text-sm text-gray-500">Seleccionado:</p>
                      <p className="text-sm text-gray-800">
                        {entityTypesDictionary[entityType]?.searchDisplay(
                          selectedEntity,
                        )}
                      </p>
                    </div>
                  )}
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      onClick={handleReplace}
                    >
                      Usar
                    </button>
                  </div>
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ReplaceEntityeModal;

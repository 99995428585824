import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';

interface PaginationProps {
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
  limit: number;
  totalCount: number;
}

function Pagination({
  page,
  totalPages,
  setPage,
  limit,
  totalCount,
}: PaginationProps) {
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);

  useEffect(() => {
    const displayPageNumbers = Array.from(Array(totalPages).keys())
      .map((p) => {
        if ([page, page + 1, page - 1, 0, totalPages - 1].includes(p)) {
          return p;
        }
        if (p === page + 2 || p === page - 2) {
          return -p;
        }
        return null;
      })
      .filter((e) => e !== null);

    setPageNumbers(displayPageNumbers);
  }, [totalPages, page]);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 pt-3">
      <div className="flex flex-1 justify-between sm:hidden">
        <p className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Anterior
        </p>
        <p className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Siguiente
        </p>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-medium">{page * limit + 1}</span> -{' '}
            <span className="font-medium">
              {page * limit + limit > totalCount
                ? totalCount
                : page * limit + limit}
            </span>{' '}
            de <span className="font-medium">{totalCount}</span> resultados
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              type="button"
              onClick={() => (page > 0 ? setPage(page - 1) : null)}
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageNumbers.map((pageNumber) => (
              <button
                key={pageNumber}
                className={`relative inline-flex items-center border ${
                  pageNumber === page
                    ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                } px-4 py-2 text-sm font-medium focus:z-20`}
                type="button"
                onClick={() =>
                  pageNumber + 1 > 0 ? setPage(pageNumber) : null
                }
              >
                {pageNumber + 1 > 0 ? pageNumber + 1 : '...'}
              </button>
            ))}

            <button
              type="button"
              onClick={() => (page < totalPages - 1 ? setPage(page + 1) : null)}
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Pagination;

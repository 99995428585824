import React, { ElementType, ReactNode } from 'react';
import { MdFolderOpen } from 'react-icons/md';

interface EmptyStateProps {
  icon?: ElementType;
  title: string;
  message: ReactNode;
  button?: ReactNode;
}

export default function EmptyState({
  icon: Icon = MdFolderOpen,
  title,
  message,
  button,
}: EmptyStateProps) {
  return (
    <main className="flex flex-col gap-10 items-center justify-center text-gray-700 w-full h-4/6">
      <Icon className="size-36 text-gray-300" aria-hidden="true" />
      <article className="flex flex-col items-center gap-2.5 max-w-[490px]">
        <h2 className="text-xl font-medium text-center">{title}</h2>
        <p className="font-light text-center">{message}</p>
      </article>
      <footer className="flex justify-center items-center gap-5">
        {button}
      </footer>
    </main>
  );
}

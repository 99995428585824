import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Company, User } from '../../../types/types';
import useFieldsValidator from '../useFieldsValidator';
import { editUser, editCompany } from '../../../services/services';

function useCompanyFieldsForm(preloadedValue: Company): {
  isLoading: boolean;
  editableCompanyInfo: Partial<Company>;
  handleEditCompanyInfo: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleEditRepresentativeInfo: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleSubmit: (setIsOpen: (isOpen: boolean) => void) => Promise<void>;
  errors: string[];
} {
  const [isLoading, setIsLoading] = useState(false);
  const [editableCompanyInfo, setEditableCompanyInfo] = useState<
    Partial<Company>
  >({
    representative: {} as User,
  });

  const { getAccessTokenSilently } = useAuth0();
  const { errors, validateFields } = useFieldsValidator();

  useEffect(() => {
    if (preloadedValue) {
      setEditableCompanyInfo({
        legalName: preloadedValue.legalName,
        nif: preloadedValue.nif,
        fiscalAddressAddress: preloadedValue.fiscalAddressAddress,
        fiscalAddressCity: preloadedValue.fiscalAddressCity,
        fiscalAddressProvince: preloadedValue.fiscalAddressProvince,
        fiscalAddressState: preloadedValue.fiscalAddressState,
        fiscalAddressZipCode: preloadedValue.fiscalAddressZipCode,
        representative: {
          name: preloadedValue.representative?.name,
          surname: preloadedValue.representative?.surname,
          dni: preloadedValue.representative?.dni,
          address: preloadedValue.representative?.address,
          phone: preloadedValue.representative?.phone,
          email: preloadedValue.representative?.email,
          city: preloadedValue.representative?.city,
          zipCode: preloadedValue.representative?.zipCode,
          province: preloadedValue.representative?.province,
        } as User,
      });
    }
  }, [preloadedValue]);

  const handleEditCompanyInfo = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    validateFields(e.target.name, e.target.value);
    setEditableCompanyInfo({
      ...editableCompanyInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditRepresentativeInfo = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    validateFields(e.target.name, e.target.value);
    setEditableCompanyInfo({
      ...editableCompanyInfo,
      representative: {
        ...editableCompanyInfo?.representative,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async (setIsOpen: (isOpen: boolean) => void) => {
    setIsLoading(true);

    const token = await getAccessTokenSilently();

    try {
      await editUser(
        token,
        preloadedValue.representative.id,
        editableCompanyInfo.representative,
      );
      await editCompany(token, preloadedValue.id, editableCompanyInfo);
      setIsLoading(false);
      setIsOpen(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return {
    errors,
    isLoading,
    handleSubmit,
    editableCompanyInfo,
    handleEditCompanyInfo,
    handleEditRepresentativeInfo,
  };
}

export default useCompanyFieldsForm;

import React, { Dispatch, SetStateAction } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ChevronRightIcon } from '@heroicons/react/outline';

import { WeecoverOfferResponse } from '../../../../services/mechanicalWarraties';
import classNames from '../../../../helpers/classNames';
import Button from '../../../../components/Button';

export default function ResultsForm({
  offers,
  setStep,
  selectedOffer,
  setSelectedOffer,
  hasNotMechanicalWarrantiesActivated,
}: {
  offers: WeecoverOfferResponse[];
  setStep: Dispatch<SetStateAction<'OFFER' | 'ISSUANCE'>>;
  selectedOffer: WeecoverOfferResponse;
  setSelectedOffer: Dispatch<SetStateAction<WeecoverOfferResponse>>;
  hasNotMechanicalWarrantiesActivated: boolean;
}) {
  const stepforward = () => {
    setStep('ISSUANCE');
  };
  return (
    <>
      <div className="px-6 pt-2 pb-1">
        <div className="flex justify-between items-center">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            2 - Seleccionar:
          </h3>
        </div>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <RadioGroup
          value={selectedOffer}
          onChange={setSelectedOffer}
          className="mb-5 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"
        >
          {offers.map((offer: WeecoverOfferResponse) => (
            <div>
              <RadioGroup.Option
                key={offer.modalityCode}
                value={offer}
                className={({ active }) =>
                  classNames(
                    active ? 'border-blue-600 ring-2 ring-blue-600' : '',
                    !active ? 'border-gray-300' : '',
                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <span className="flex flex-1">
                      <span className="flex flex-col">
                        <img
                          src="/race-logo.jpeg"
                          alt="race seguros logo"
                          className="w-12 rounded"
                        />
                        <span className="mt-2 block text-sm font-medium text-gray-900">
                          {offer.title}
                        </span>
                        <span className="mt-2 flex items-center text-sm text-gray-500">
                          {offer.description}
                        </span>
                        <span className="mt-4 text-sm font-medium text-gray-900">
                          {offer.annualPremium} €
                        </span>
                      </span>
                    </span>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? 'invisible' : '',
                        'h-5 w-5 text-blue-600',
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        checked ? 'border-blue-600' : 'border-transparent',
                        active ? 'border' : 'border-2',
                        'pointer-events-none absolute -inset-px rounded-lg',
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
              <a
                href={offer.uri}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-500 flex justify-end mt-1 text-sm"
              >
                Más información
              </a>
            </div>
          ))}
        </RadioGroup>
        <div className="flex w-full justify-end mt-3">
          <Button
            bgColor="bg-blue-700"
            hoverBgColor="bg-blue-900"
            textColor="white"
            text="Continuar"
            RightIcon={ChevronRightIcon}
            callback={stepforward}
            disabled={!selectedOffer || hasNotMechanicalWarrantiesActivated}
          />
        </div>
      </div>
    </>
  );
}

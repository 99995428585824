import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { User } from '../../../types/types';
import useFieldsValidator from '../useFieldsValidator';
import { editUser } from '../../../services/services';

function useUserFieldsForm(preloadedValue: User): {
  isLoading: boolean;
  editableUserInfo: Partial<User>;
  handleEditUserInfo: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleSubmit: (setIsOpen: (isOpen: boolean) => void) => Promise<void>;
  errors: string[];
} {
  const [isLoading, setIsLoading] = useState(false);
  const [editableUserInfo, setEditableUserInfo] = useState<Partial<User>>({});

  const { getAccessTokenSilently } = useAuth0();
  const { errors, validateFields } = useFieldsValidator();

  useEffect(() => {
    if (preloadedValue) {
      setEditableUserInfo({
        name: preloadedValue.name,
        surname: preloadedValue.surname,
        dni: preloadedValue.dni,
        address: preloadedValue.address,
        phone: preloadedValue.phone,
        email: preloadedValue.email,
        city: preloadedValue.city,
        ccaa: preloadedValue.ccaa,
        zipCode: preloadedValue.zipCode,
        province: preloadedValue.province,
      });
    }
  }, [preloadedValue]);

  const handleEditUserInfo = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    validateFields(e.target.name, e.target.value);
    setEditableUserInfo({
      ...editableUserInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (setIsOpen: (isOpen: boolean) => void) => {
    setIsLoading(true);

    const token = await getAccessTokenSilently();

    try {
      await editUser(token, preloadedValue.id, editableUserInfo);
      setIsOpen(false);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return {
    errors,
    isLoading,
    handleSubmit,
    editableUserInfo,
    handleEditUserInfo,
  };
}

export default useUserFieldsForm;

import React from 'react';
import { Tooltip } from 'react-tooltip';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';

import Button from '../../../components/Button';

function ContinueWithBatecomButton({
  showContinueWithBatecom,
  openBuyerTypeModal,
}: Readonly<{
  showContinueWithBatecom: boolean;
  openBuyerTypeModal: () => void;
}>): React.ReactElement {
  if (!showContinueWithBatecom) return null;
  return (
    <>
      <Tooltip
        id="continue-with-batecom-button"
        style={{
          marginTop: '0',
          fontSize: '15px',
          zIndex: '11',
        }}
        content="Crear el trámite de venta a partir de este"
        place="top"
      />
      <Button
        text="Continuar Venta"
        bgColor="bg-white"
        hoverBgColor="bg-gray-200"
        textColor="gray-700"
        RightIcon={ArrowNarrowRightIcon}
        iconColor="text-gray-400"
        callback={openBuyerTypeModal}
        border="border-2"
        borderColor="border-gray-200"
        textSm
        dataTooltipId="continue-with-batecom-button"
        additionalClasses="whitespace-nowrap"
      />
    </>
  );
}

export default ContinueWithBatecomButton;

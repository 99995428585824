import { useState, useEffect, useCallback } from 'react';
import { useAxios } from './useAxios';
import { formChangeHandler } from '../screens/Backoffice/tools/functions/itpFormChangeHandler';
import { yearsFromNowToDate } from '../screens/Backoffice/tools/functions/yearsFromNowToDate';

export default function useItp() {
  const [itpFormValue, setItpFormValue] = useState({
    vehicleType: '',
    brand: '',
    enrollmentDate: '',
    fuel: '',
    motoModel: '',
  });
  const [selectedModel, setSelectedModel] = useState({});
  const [ccaa, setCcaa] = useState('');
  const [cc, setCc] = useState('');

  const {
    data: modelData,
    error: modelError,
    isLoading: isModelLoading,
    runRequest: modelRunRequest,
    resetValues: modelResetValues,
  } = useAxios(
    `${process.env.REACT_APP_BASE_API_URL}/itp/cars/?brand=${itpFormValue.brand}&enrollmentDate=${itpFormValue.enrollmentDate}&fuel=${itpFormValue.fuel}`,
  );

  const {
    data: itpData,
    error: itpError,
    isLoading: isItpLoading,
    runRequest: itpRunRequest,
    resetValues: itpResetValues,
  } = useAxios(
    itpFormValue.vehicleType === 'car'
      ? `${
          process.env.REACT_APP_BASE_API_URL
        }/itp/calculate/?model=${encodeURIComponent(
          selectedModel.model,
        )}&ccaa=${ccaa}&enrollmentDate=${encodeURI(
          itpFormValue.enrollmentDate,
        )}`
      : `${
          process.env.REACT_APP_BASE_API_URL
        }/itp/calculate/moto/?ccaa=${ccaa}&years=${yearsFromNowToDate(
          new Date(itpFormValue.enrollmentDate),
        )}&cc=${cc}`,
  );

  const handleChange = (e) => {
    formChangeHandler(
      setItpFormValue,
      itpFormValue,
      e.target.name,
      e.target.value,
      setCc,
    );
    setSelectedModel({});
    setCcaa('');
    modelResetValues();
    itpResetValues();
  };

  const handleModelSelection = (e) => {
    const selected = modelData.cars.find(
      (item) => item.model === e.target.value,
    );
    setSelectedModel(selected);
    itpResetValues();
    setCcaa('');
  };

  const isFormComplete = useCallback(() => {
    if (itpFormValue.vehicleType === 'moto') {
      return Boolean(itpFormValue.brand && itpFormValue.enrollmentDate && cc);
    }

    return Boolean(
      itpFormValue.brand && itpFormValue.enrollmentDate && itpFormValue.fuel,
    );
  }, [itpFormValue, cc]);

  const resetItpForm = () => {
    setItpFormValue({
      vehicleType: '',
      brand: '',
      enrollmentDate: '',
      fuel: '',
    });
    setSelectedModel({});
    setCcaa('');
    setCc('');
    modelResetValues();
    itpResetValues();
  };

  useEffect(() => {
    if (isFormComplete()) {
      modelRunRequest();
    }
  }, [isFormComplete, modelRunRequest]);

  useEffect(() => {
    if (modelData?.cars.length) {
      setSelectedModel({});
    }
  }, [modelData]);

  useEffect(() => {
    if (isFormComplete() && ccaa) {
      itpRunRequest();
    }
  }, [ccaa, isFormComplete, itpRunRequest]);

  return {
    itpFormValue,
    setItpFormValue,
    handleChange,
    setCc,
    setCcaa,
    itpResetValues,
    itpRunRequest,
    isModelLoading,
    modelError,
    modelData,
    modelRunRequest,
    modelResetValues,
    isFormComplete,
    selectedModel,
    setSelectedModel,
    handleModelSelection,
    ccaa,
    itpData,
    isItpLoading,
    itpError,
    cc,
    resetItpForm,
  };
}

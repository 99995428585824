import { useEffect, useState } from 'react';
import BaseModal from '../../../../components/BaseModal';
import Select from '../../../../components/select';
import classNames from '../../../../helpers/classNames';
import { useServices } from '../../../../services';
import { DGT_FEE_STATUS, dgtFeeStatusDictionary } from '../enums/enums';

export default function EditFeeStatusModal({
  isOpen,
  onClose,
  selectedDgtFee,
  acceptedStateChanges,
  forceUpdate,
}) {
  const [value, setValue] = useState(acceptedStateChanges?.[0]);
  const [errorMessage, setErrorMessage] = useState('');

  const { markFeeAsInDispute, markFeeAsReadyToUse, markFeeAsInQuarantine } =
    useServices();

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const dgtFeeStatusList = [
    {
      key: DGT_FEE_STATUS.READY_TO_USE,
      label: dgtFeeStatusDictionary[DGT_FEE_STATUS.READY_TO_USE],
      value: DGT_FEE_STATUS.READY_TO_USE,
      function: markFeeAsReadyToUse,
    },
    {
      key: DGT_FEE_STATUS.IN_QUARANTINE,
      label: dgtFeeStatusDictionary[DGT_FEE_STATUS.IN_QUARANTINE],
      value: DGT_FEE_STATUS.IN_QUARANTINE,
      function: markFeeAsInQuarantine,
    },
    {
      key: DGT_FEE_STATUS.IN_DISPUTE,
      label: dgtFeeStatusDictionary[DGT_FEE_STATUS.IN_DISPUTE],
      value: DGT_FEE_STATUS.IN_DISPUTE,
      function: markFeeAsInDispute,
    },
  ];

  const handleSubmit = async () => {
    try {
      const selectedStatus = dgtFeeStatusList.find(
        (status) => status.value === value,
      );

      await selectedStatus.function(selectedDgtFee.id);

      forceUpdate();
      onClose();
    } catch (e) {
      setErrorMessage('Hubo un error al intentar cambiar el estado de la tasa');
    } finally {
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  useEffect(() => {
    setValue(acceptedStateChanges?.[0]);
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <div className="p-6 flex flex-col gap-y-2">
        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
          Nuevo estado
        </h3>

        {errorMessage && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}

        <Select
          label="Estados posibles"
          stateName="status"
          options={dgtFeeStatusList.filter((option) =>
            acceptedStateChanges.includes(option.value),
          )}
          value={value}
          handleChange={handleChange}
        />

        <button
          type="button"
          onClick={handleSubmit}
          className={classNames(
            'rounded-md px-2 py-1 mt-5 w-full bg-blue-500 text-white',
          )}
        >
          Editar
        </button>
      </div>
    </BaseModal>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation } from 'react-router-dom';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react';
import { XIcon, MenuAlt2Icon } from '@heroicons/react/outline';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { usePostHog } from 'posthog-js/react';

import classNames from '../helpers/classNames';
import {
  MenuItem,
  getInsuranceItems,
  getNavigationTramitItems,
  getNavigationCompanyItems,
} from './navigation-items';
import Searchbox from './components/Searchbox/Searchbox';
import NavigationItem from './NavigationItem';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';

function Layout({ children }: { children: React.ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const location = useLocation();

  const { logout, user } = useAuth0();
  const posthogClient = usePostHog();
  const { accountInfo } = useContext(userInfoContext);

  const tramitItems = getNavigationTramitItems(location);
  const companyItems = getNavigationCompanyItems(location);
  const insuranceItems = getInsuranceItems(location);

  const isAncove = accountInfo?.platform === 'ANCOVE';

  const toggleSleekHelpcenter = () => {
    if (window && window.$sleek) {
      window.$sleek.toggle();
    }
  };

  const handleLogout = () => {
    if (window && window.$sleek) {
      window.$sleek.shutdown();
    }
    if (posthogClient) {
      posthogClient.reset();
    }
    logout({
      returnTo: window.location.origin,
    });
  };

  useEffect(() => {
    if (window && window.$sleek) {
      window.$sleek.setUser({
        mail: user.email,
        id: user.sub,
        name: user.name,
        img: user.picture,
      });
    }
    if (posthogClient && accountInfo) {
      posthogClient.identify(user.sub, {
        name: user.name,
        email: user.email,
      });
      posthogClient.group('company', accountInfo.id, {
        name: accountInfo.name,
      });
    }
  }, [accountInfo]);

  return (
    <div className="relative h-full">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600/75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Cerrar sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <Link to="/">
                <div className="shrink-0 flex items-center px-4">
                  <img
                    src="https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/swipoo-white-icon.svg"
                    alt="swipoo logo"
                    width="35"
                    height="35"
                  />
                  <span className="ml-4">
                    <img
                      src="https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/swipoo-white-name.svg"
                      alt="swipoo name"
                      width="75"
                      height="70"
                    />
                  </span>
                </div>
              </Link>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  <div className="ml-2 text-xs font-semibold leading-6 text-gray-400">
                    TRAMITES
                  </div>
                  {tramitItems.map((item: MenuItem) => (
                    <NavigationItem
                      item={item}
                      textColorClass="text-gray-200"
                      key={item.name}
                    />
                  ))}
                  {!isAncove && (
                    <>
                      <div className="ml-2 text-xs font-semibold leading-6 text-gray-400">
                        SEGUROS
                      </div>
                      {insuranceItems.map((item: MenuItem) => (
                        <NavigationItem
                          item={item}
                          textColorClass="text-gray-200"
                          key={item.name}
                        />
                      ))}
                    </>
                  )}
                  <div className="ml-2 text-xs font-semibold leading-6 text-gray-400">
                    TU EMPRESA
                  </div>
                  {companyItems.map((item: MenuItem) => (
                    <NavigationItem
                      item={item}
                      textColorClass="text-gray-200"
                      key={item.name}
                    />
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
        <div className="flex-1 flex flex-col min-h-0 bg-gray-100">
          <Link to="/">
            <div className="flex items-center h-16 shrink-0 px-4 bg-topbar">
              <img
                src="https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/swipoo-white-icon.svg"
                alt="swipoo logo"
                width="35"
                height="35"
              />
              <span className="ml-4">
                <img
                  src="https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/swipoo-white-name.svg"
                  alt="swipoo logo"
                  width="75"
                  height="65"
                />
              </span>
            </div>
          </Link>
          <div className="flex-1 flex flex-col overflow-y-auto border-r border-r-gray-200">
            <nav className="flex-1 px-2 py-4 space-y-1">
              <div className="ml-2 text-xs font-semibold leading-6 text-gray-400">
                TRAMITES
              </div>
              {tramitItems.map((item: MenuItem) => (
                <NavigationItem item={item} key={item.name} />
              ))}
              {!isAncove && (
                <>
                  <div className="ml-2 text-xs font-semibold leading-6 text-gray-400">
                    SEGUROS
                  </div>
                  {insuranceItems.map((item: MenuItem) => (
                    <NavigationItem item={item} key={item.name} />
                  ))}
                </>
              )}
              <div className="ml-2 text-xs font-semibold leading-6 text-gray-400">
                TU EMPRESA
              </div>
              {companyItems.map((item: MenuItem) => (
                <NavigationItem item={item} key={item.name} />
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="lg:pl-64 flex flex-col h-full w-full">
        <div className="sticky z-10 flex shrink-0 gap-2 items-center justify-between top-0 h-16 bg-topbar">
          <button
            type="button"
            className="p-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Abrir sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="w-full flex justify-center">
            <Searchbox />
          </div>
          <div className="flex items-center gap-4 p-6">
            <button
              type="button"
              className="max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white lg:rounded-md"
              onClick={toggleSleekHelpcenter}
            >
              <IoHelpCircleOutline className="h-10 w-10 text-gray-200 hover:text-white" />
            </button>
            <Menu as="div">
              <div>
                <Menu.Button className="rounded-full flex items-center w-8 text-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white lg:rounded-md">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={user.picture}
                    alt={user.name}
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'flex flex-col px-4 py-2 text-sm text-gray-700',
                        )}
                      >
                        <button
                          type="button"
                          className="w-full"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <main className="h-full bg-gray-100">{children}</main>
      </div>
    </div>
  );
}

export default Layout;

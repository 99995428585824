import React, { useState } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { PencilAltIcon } from '@heroicons/react/outline';
import { MdLocationPin } from 'react-icons/md';
import { Shipment } from '../../types/types';
import DeliveryInfoModal from '../DeliveryInfoModal/DeliveryInfoModal';
import { ccaaDictionary } from '../../../../enums/ccaaDictionary';

export default function DeliveryInfo({ shipment }: { shipment: Shipment }) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showLogoProvider = () => {
    switch (shipment.provider) {
      case 'CORREOS':
      case 'CORREOS_EXPRESS':
        return (
          <img
            src="/correos-express.png"
            alt="correos express logo"
            className="w-full"
          />
        );
      case 'MRW':
        return <img src="/mrw-logo.png" alt="mrw logo" className="w-full" />;
      default:
        return null;
    }
  };

  return (
    <>
      {shipment && (
        <DeliveryInfoModal
          showModal={isModalOpen}
          setShowModal={setIsModalOpen}
          shipment={shipment}
        />
      )}
      <div className="flex flex-col bg-white pt-6 pb-10 px-10  border border-gray-300 rounded-md gap-y-4">
        <div className="flex justify-between items-center">
          <h2 className="font-semibold">Información de entrega</h2>
          {shipment?.status === 'CREATED' && (
            <button
              type="button"
              className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 gap-2"
              id="edit-button"
              onClick={() => setIsModalOpen(true)}
              disabled={shipment?.status !== 'CREATED'}
            >
              <PencilAltIcon className="h-5 w-5 text-gray-900" /> Editar
            </button>
          )}
        </div>
        <div className="flex text-gray-500">
          <div className="flex w-1/3 gap-x-4">
            <BsFillPersonFill className="w-6 h-6" />
            <div className="flex flex-col">
              <span>{shipment?.receiver?.name || '-'}</span>
              <span>{shipment?.receiver?.doi || '-'}</span>
              <span>{shipment?.receiver?.email || '-'}</span>
              <span>{shipment?.receiver?.phone || '-'}</span>
            </div>
          </div>
          <div className="flex w-1/3 gap-x-4">
            <MdLocationPin className="w-6 h-6" />
            <div className="flex flex-col">
              <span>{shipment?.delivery?.street || '-'}</span>
              <span>{shipment?.delivery?.city || '-'}</span>
              <span>{ccaaDictionary[shipment?.delivery?.state] || '-'}</span>
              <span>
                {(shipment?.delivery?.zipCode &&
                  `${shipment?.delivery?.zipCode} España`) ||
                  '-'}
              </span>
            </div>
          </div>
          <div className="w-16">{showLogoProvider()}</div>
        </div>
      </div>
    </>
  );
}

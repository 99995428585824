import React from 'react';
import { CustomersTableProps } from '../..';

export default function CustomersTable({ customers }: CustomersTableProps) {
  return (
    <div className="overflow-x-auto min-h-fit shadow ring-1 ring-black ring-opacity-5 rounded-lg">
      <table className="table-fixed min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
            >
              Nombre
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Dni
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Teléfono
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {customers?.map((customer, personIdx) => (
            <tr
              key={customer.id}
              className={`${
                personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
              } hover:bg-gray-100`}
            >
              <td className="w-1/4 px-3 sm:px-6 h-14 align-middle whitespace-nowrap text-sm text-gray-900 font-medium">
                {customer.name} {customer.surname}
              </td>
              <td className="w-1/4 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                {customer.dni}
              </td>
              <td className="w-1/4 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                {customer.email}
              </td>
              <td className="w-1/4 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                {customer.phone}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

import React from 'react';
import { MdAdd, MdFolderOpen } from 'react-icons/md';
import EmptyState from '../../../components/EmptyState';
import Button from '../../../components/Button';

interface RegistrationsEmptyStateProps {
  readonly showNewRegistrationModal?: () => void;
}

export function RegistrationsEmptyState({
  showNewRegistrationModal,
}: RegistrationsEmptyStateProps) {
  return (
    <EmptyState
      icon={MdFolderOpen}
      title="Aún no tienes trámites de matriculaciones"
      message="Gestiona y haz seguimiento de tus trámites de matriculación. Comienza ahora y simplifica tus trámites."
      button={
        <Button
          LeftIcon={MdAdd}
          text="Iniciar nuevo trámite"
          bgColor="bg-blue-600"
          hoverBgColor="bg-blue-700"
          textColor="white"
          callback={showNewRegistrationModal}
        />
      }
    />
  );
}

export default function DgtFeeTypeSelector({
  setSelectedFeeType,
  selectedFeeType,
  dgtFeeTypes,
  summaryLoader,
}) {
  return (
    <select
      id="fee-type"
      name="fee-type"
      className="block w-fit rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      defaultValue="4.1"
      onChange={(e) => setSelectedFeeType(e.target.value)}
      value={selectedFeeType}
      disabled={summaryLoader}
    >
      {dgtFeeTypes.map(({ id, type }) => (
        <option key={id} value={type}>
          {type}
        </option>
      ))}
    </select>
  );
}

import CustomLabels from './CustomLabels';

export default function Labels({ forceUpdate, labels, entityId, entityType }) {
  return (
    <div className="mt-2 inline-flex">
      <CustomLabels
        entityType={entityType}
        save={forceUpdate}
        entityLabels={labels}
        entityId={entityId}
      />
    </div>
  );
}

import { Combobox, Dialog, Transition } from '@headlessui/react';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { XIcon } from '@heroicons/react/outline';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import { toasterContext } from '../context/ToasterContext/ToasterContext';
import useCopyEntityData from '../hooks/useCopyEntityData';

export type PossibleCounterpart = 'buyer' | 'seller';

const counterpartDictionary: Map<PossibleCounterpart, string> = new Map([
  ['buyer', 'comprador'],
  ['seller', 'vendedor'],
]);

function ReplaceCounterpartModal({
  open,
  setOpen,
  counterpart,
  forceUpdate,
  sourceEntityId,
  type,
}: {
  open: boolean;
  setOpen: (o: boolean) => void;
  counterpart: PossibleCounterpart;
  forceUpdate: () => void;
  sourceEntityId: string;
  type: 'user' | 'company';
}) {
  const [search, setSearch] = useState('');

  const [notFound, setNotFound] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const { copyEntityData } = useCopyEntityData();

  const { accountInfo } = useContext(userInfoContext);
  const { setToasterData } = useContext(toasterContext);
  type Entity = {
    id: string;
    nif: string;
    name: string;
  };

  const [foundEntities, setFoundEntities] = useState<Entity[]>([]);

  const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);

  const fetchEntities = async () => {
    try {
      const token = await getAccessTokenSilently();
      const url =
        type === 'user'
          ? `${process.env.REACT_APP_BASE_API_URL}/get-users-related-to-account/${accountInfo.id}?query=${search}`
          : `${process.env.REACT_APP_BASE_API_URL}/get-companies-related-to-account/${accountInfo.id}?query=${search}`;

      const { data: entities } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (entities.length === 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
      setFoundEntities(entities);
    } catch {
      setToasterData({
        message:
          'No se han podido obtener los datos. Por favor, inténtelo de nuevo más tarde o contacte con el nosotros.',
        title: 'Error en la búsqueda',
        type: 'ERROR',
      });
    }
  };

  const handleReplace = async () => {
    try {
      await copyEntityData(sourceEntityId, selectedEntity.id, type);
      forceUpdate();
      setToasterData({
        message:
          'Hemos cargado los datos del usuario en el nuevo trámite correctamente',
        title: 'Usuario cargado',
        type: 'SUCCESS',
      });
      setOpen(false);
    } catch {
      setToasterData({
        message:
          'No se han podido cargar los datos del usuario en el nuevo trámite. Por favor, inténtelo de nuevo más tarde o contacte con el nosotros.',
        title: 'Error en el reemplazo',
        type: 'ERROR',
      });
    }
  };

  useEffect(() => {
    if (search.length < 3) {
      setFoundEntities([]);
      setNotFound(false);
    }
  }, [search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search.length > 2) {
        fetchEntities();
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [search, type]);

  useEffect(() => {
    if (!open) {
      setFoundEntities([]);
      setSearch('');
      setSelectedEntity(null);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all w-[512px] p-4">
                <Combobox
                  as="div"
                  value={selectedEntity}
                  onChange={setSelectedEntity}
                  className="relative mt-1 max-h-60"
                >
                  <div className="flex">
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                      Busca y selecciona el nuevo{' '}
                      {counterpartDictionary.get(counterpart)} del trámite.
                    </Combobox.Label>
                    <div className="absolute right-0 top-0 hidden sm:block items-end">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      >
                        <XIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                          onClick={() => setOpen(false)}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="relative mt-2">
                    <div className="relative mt-4 rounded-md shadow-sm">
                      <Combobox.Input
                        autoComplete="off"
                        className="w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={
                          type === 'user'
                            ? 'Introduce el nombre o dni'
                            : 'Introduce el nombre o nif'
                        }
                        type="text"
                        name="query"
                        id="query"
                      />
                    </div>
                    {foundEntities.length > 0 && (
                      <Combobox.Options className="absolute mt-2 max-h-60 z-50 w-full overflow-y-auto overflow-x-hidden rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <div>
                          <ul className="divide-y divide-gray-100">
                            {foundEntities
                              .filter((_, i) => i < 10)
                              .map(({ id, name, nif }) => (
                                <button
                                  type="button"
                                  key={id}
                                  className="p-1 hover:bg-gray-100 cursor-pointer text-sm text-gray-800 flex gap-x-4 py-3 w-full"
                                  onClick={() => {
                                    setSelectedEntity({ id, name, nif });
                                    setSearch('');
                                  }}
                                >
                                  <div className="min-w-0 ml-2">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">
                                      {name} ({nif})
                                    </p>
                                  </div>
                                </button>
                              ))}
                          </ul>
                        </div>
                      </Combobox.Options>
                    )}
                  </div>
                  {notFound && (
                    <p className="text-red-500 text-xs mt-1 -mb-5">
                      No se han encontrado resultados
                    </p>
                  )}
                  {selectedEntity && (
                    <div className="mt-5 sm:mt-6">
                      <p className="text-sm text-gray-500">Seleccionado:</p>
                      <p className="text-sm text-gray-800">
                        {selectedEntity.name} ({selectedEntity.nif})
                      </p>
                    </div>
                  )}
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      onClick={handleReplace}
                    >
                      Usar
                    </button>
                  </div>
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ReplaceCounterpartModal;

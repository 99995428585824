/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/require-default-props */
import React from 'react';
import { CgSpinner } from 'react-icons/cg';
import classNames from '../helpers/classNames';

type ButtonProps = {
  text: string;
  bgColor: string;
  id?: string;
  hoverBgColor: string;
  textColor: string;
  callback?: () => void;
  disabled?: boolean;
  LeftIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element | null;
  RightIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element | null;
  iconColor?: string;
  border?: string;
  borderColor?: string;
  textSm?: boolean;
  testId?: string;
  loading?: boolean;
  dataTooltipId?: string;
  additionalClasses?: string;
  ariaDescribedBy?: string;
};

export default function Button({
  text,
  id,
  bgColor,
  hoverBgColor,
  textColor,
  callback = () => {},
  disabled,
  LeftIcon = null,
  RightIcon = null,
  iconColor = '',
  border = '',
  borderColor = '',
  textSm = false,
  testId = '',
  loading = false,
  dataTooltipId,
  additionalClasses,
  ariaDescribedBy,
}: ButtonProps): JSX.Element {
  return (
    <button
      id={id}
      data-testid={testId}
      data-tooltip-id={dataTooltipId}
      type="button"
      onClick={callback}
      className={classNames(
        `flex justify-center items-center gap-2 px-5 py-2 rounded-md text-${textColor}`,
        disabled || loading
          ? 'cursor-not-allowed bg-slate-400 hover:bg-slate-400 text-white'
          : `${bgColor} hover:${hoverBgColor} cursor-pointer`,
        border && `${border} ${borderColor}`,
        textSm && 'text-sm',
        additionalClasses,
      )}
      disabled={disabled || loading}
      aria-disabled={disabled || loading}
      aria-describedby={ariaDescribedBy}
    >
      {loading && <CgSpinner className="animate-spin w-5 h-5" />}
      {LeftIcon && (
        <LeftIcon
          className={`w-5 h-5 ${iconColor && `${iconColor}`}`}
          data-testid="left-icon"
        />
      )}
      {text}
      {RightIcon && (
        <RightIcon
          className={`w-5 h-5 ${iconColor && `${iconColor}`}`}
          data-testid="right-icon"
        />
      )}
    </button>
  );
}

import React, { useEffect, useReducer, useState } from 'react';
import usePagination from '../../../hooks/usePagination';
import { useServices } from '../../../services';
import Spinner from '../../../components/Spinner';

import TrafficReportsTable from './components/TrafficReportsTable';
import UploadTrafficReportModal from './components/UploadTrafficReportModal';

export default function Reports({ isPro }) {
  const [reports, setReports] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, goNext, goPrev] = usePagination();
  const [loader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { getAllReports: fetchAllReports, getAccountById } = useServices();

  useEffect(() => {
    const getAllReports = async () => {
      setLoader(true);
      setErrorMessage('');
      try {
        const { data, count } = await fetchAllReports(page, isPro);

        setTotal(count);

        if (!isPro) {
          setReports(data);
          return;
        }

        const reportsWithAccount = await Promise.all(
          data.map(async (report) => {
            const account = await getAccountById(report.accountId);
            return { ...report, accountName: account.name };
          }),
        );

        setReports(reportsWithAccount);
      } catch (e) {
        setErrorMessage('Error al cargar los informes');
      } finally {
        setLoader(false);
      }
    };
    getAllReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isPro, updater]);

  return (
    <>
      {isModalOpen && (
        <UploadTrafficReportModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          isPro={isPro}
          forceUpdate={forceUpdate}
        />
      )}
      {loader && (
        <div className="w-full h-full min-h-[500px] flex justify-center items-center">
          <Spinner color="text-blue-700" size={10} marginTop={28} />
        </div>
      )}
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      {!loader && (
        <div className="flex flex-col">
          <button
            onClick={() => setIsModalOpen(true)}
            type="button"
            className="inline-flex items-center self-end px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Subir informe
          </button>
          <TrafficReportsTable
            reports={reports}
            total={total}
            page={page}
            goNext={goNext}
            goPrev={goPrev}
            isPro={isPro}
            setErrorMessage={setErrorMessage}
          />
        </div>
      )}
    </>
  );
}

import axios from 'axios';

import { CarModel } from './EditVehicleModal';

export const getModelsByBrand = async (brand: string) => {
  const response = await axios.get<{ cars: CarModel[] }>(
    `${process.env.REACT_APP_BASE_API_URL}/models-by-brand/${encodeURIComponent(
      brand,
    )}`,
  );
  return response.data;
};

import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
  useContext,
} from 'react';

import { toasterContext } from '../ToasterContext/ToasterContext';

/* eslint-disable no-shadow */

export enum CounterPartType {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
}

export const enum Status {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  COMPLETED = 'COMPLETED',
}

type UserType =
  | 'BUYER'
  | 'SELLER'
  | 'INTERMEDIARY'
  | 'BUYER_WITH_TEMP_LEAVE'
  | 'SELLER_WITH_TEMP_LEAVE';

export type FormValueTypeIfNotIntermediary = {
  userType: UserType;
  counterPartType: CounterPartType | '';
  batecomId?: string | null;
};

export type FormValueTypeIfIntermediary = {
  userType: 'INTERMEDIARY';
  buyerType: CounterPartType | '';
  sellerType: CounterPartType | '';
};

export type FormValueType =
  | FormValueTypeIfIntermediary
  | FormValueTypeIfNotIntermediary;

export type CreateTransferModalContextType = {
  formValue: FormValueType;
  setFormValue: Dispatch<SetStateAction<FormValueType>>;
  totalSteps: number;
  currentStep: number;
  stepForward: () => void;
  stepBackward: () => void;
  createTransfer: (companyId: string) => Promise<void>;
  createBate: (companyId: string) => Promise<void>;
  status: Status;
  submitForm: (companyId: string) => Promise<void>;
};

export const createTransferContext =
  createContext<CreateTransferModalContextType>(
    {} as CreateTransferModalContextType,
  );

type ProTransactionContract = {
  intermediaryId: string;
  userType: UserType;
  batecomId?: string;
  buyerType?: CounterPartType | '';
  sellerType?: CounterPartType | '';
  counterPartType?: CounterPartType | '';
};

export function CreateTransferContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [formValue, setFormValue] = useState<FormValueType>({
    userType: 'BUYER',
    counterPartType: '',
  });
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [status, setStatus] = useState<Status>(Status.INITIAL);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { setToasterData } = useContext(toasterContext);

  const totalSteps: number = useMemo(
    () => (formValue.userType === 'SELLER_WITH_TEMP_LEAVE' ? 3 : 2),
    [formValue.userType],
  );

  const stepForward = (): void => {
    if (currentStep === totalSteps) return;
    setCurrentStep((prev) => prev + 1);
  };

  const stepBackward = (): void => {
    if (currentStep === 1) return;
    setCurrentStep((prev) => prev - 1);
  };

  const linkBatecom = async (transactionId: string) => {
    const token = await getAccessTokenSilently();
    try {
      const batecomId = (formValue as FormValueTypeIfNotIntermediary)
        ?.batecomId;
      await axios.put(
        `${process.env.REACT_APP_BASE_API_URL}/v1/link-batecom/${batecomId}`,
        {
          transactionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return true;
    } catch (e) {
      setToasterData({
        title: 'Error en la Batecom.',
        message: `No se pudo enlazar la Bate con la nueva transferencia.`,
        type: 'ERROR',
      });
      return false;
    }
  };

  const createTransfer = async (companyId: string) => {
    const token = await getAccessTokenSilently();

    const buildTransferPayload = (): ProTransactionContract => {
      switch (formValue.userType) {
        case 'INTERMEDIARY':
          return {
            intermediaryId: companyId,
            userType: 'INTERMEDIARY',
            buyerType: (formValue as FormValueTypeIfIntermediary).buyerType,
            sellerType: (formValue as FormValueTypeIfIntermediary).sellerType,
          };
        case 'BUYER':
          return {
            intermediaryId: companyId,
            userType: 'BUYER',
            counterPartType: formValue.counterPartType,
          };
        case 'BUYER_WITH_TEMP_LEAVE':
          return {
            batecomId: formValue.batecomId,
            intermediaryId: companyId,
            userType: 'BUYER',
            counterPartType: formValue.counterPartType,
          };
        case 'SELLER':
          return {
            intermediaryId: companyId,
            userType: 'SELLER',
            counterPartType: formValue.counterPartType,
          };
        case 'SELLER_WITH_TEMP_LEAVE':
          return {
            batecomId: formValue.batecomId,
            intermediaryId: companyId,
            userType: 'SELLER',
            counterPartType: formValue.counterPartType,
          };
        default:
          return null;
      }
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/create-pro-transaction`,
        buildTransferPayload(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );
      if (formValue.userType === 'SELLER_WITH_TEMP_LEAVE') {
        const result = await linkBatecom(data.id);
        if (!result) return;
      }
      setStatus(Status.COMPLETED);
      setToasterData({
        title: 'Transferencia creada!',
        message: `Se creó la transferencia con código ${data.transactionCode}`,
        type: 'SUCCESS',
      });
      navigate(`/transfer/${data.transactionCode}`);
    } catch (e) {
      setStatus(Status.ERROR);
    }
  };

  const createBate = async () => {
    const token = await getAccessTokenSilently();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/v1/bate`,
        {
          counterparty: (formValue as FormValueTypeIfNotIntermediary)
            .counterPartType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );
      setStatus(Status.COMPLETED);
      setToasterData({
        title: 'BATE creada!',
        message: `Se creó la BATE con código ${data.bateCode}`,
        type: 'SUCCESS',
      });
      navigate(`/bate/${data.bateCode}`);
    } catch (e) {
      setStatus(Status.ERROR);
    }
  };

  const submitForm = async (companyId: string) => {
    if (formValue.userType === 'BUYER_WITH_TEMP_LEAVE') {
      await createBate();
    } else await createTransfer(companyId);
  };

  const value = useMemo(
    () => ({
      formValue,
      setFormValue,
      totalSteps,
      currentStep,
      stepForward,
      stepBackward,
      createTransfer,
      createBate,
      status,
      submitForm,
    }),
    [formValue, currentStep, status],
  );

  return (
    <createTransferContext.Provider value={value}>
      {children}
    </createTransferContext.Provider>
  );
}

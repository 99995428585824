import { useState } from 'react';
import { itemTypeDictionary } from '../helpers/itemTypeDictionary';
import { useServices } from '../../../services';

export default function AvailableItems({
  availableItems,
  setAvailableItems,
  shipments,
  forceUpdate,
  items,
  uncreatedItems,
  setUncreatedItems,
  accountId,
  plate,
}) {
  const [errorCreatingItems, setErrorCreatingItems] = useState(false);
  const [errorCreatingShipment, setErrorCreatingShipment] = useState(false);

  const { createShipment, createItem } = useServices();

  const handleCheckAvailableItem = (e) =>
    setAvailableItems((prev) =>
      prev.map((i) =>
        i.type === e.target.name ? { ...i, isChecked: !i.isChecked } : i,
      ),
    );

  const handleCheckUncreatedItem = (e) =>
    setUncreatedItems((prev) =>
      prev.map((i) =>
        i.type === e.target.name ? { ...i, isChecked: !i.isChecked } : i,
      ),
    );

  const handleCreateItems = async () => {
    try {
      setErrorCreatingItems(false);
      await Promise.all(
        uncreatedItems
          .filter((item) => item.isChecked)
          .map(async (item) =>
            createItem({
              type: item.type,
              entityId: item.entityId,
              entityType: item.entityType,
              plate,
            }),
          ),
      );
      forceUpdate();
      setUncreatedItems((prev) => prev.filter((i) => !i.isChecked));
    } catch (e) {
      setErrorCreatingItems(true);
    }
  };

  const handleCreateNewShipment = async () => {
    try {
      setErrorCreatingShipment(false);
      await createShipment({
        delivery: {
          country: shipments[0]?.delivery.country || 'ES',
          state: shipments[0]?.delivery.state || '',
          city: shipments[0]?.delivery.city || '',
          street: shipments[0]?.delivery.street || '',
          zipCode: shipments[0]?.delivery.zipCode || '',
        },
        receiver: {
          name: shipments[0]?.receiver.name || '',
          email: shipments[0]?.receiver.email || '',
          doi: shipments[0]?.receiver.doi || '',
          phone: shipments[0]?.receiver.phone || '',
        },
        accountId: accountId || '',
        newItems: [],
        oldItemIds: availableItems
          .filter((i) => i.isChecked)
          .map(
            (availableItem) =>
              items.find((i) => i.type === availableItem.type).id,
          ),
      });
      forceUpdate();
      setAvailableItems((prev) => prev.filter((i) => !i.isChecked));
    } catch (e) {
      setErrorCreatingShipment(true);
    }
  };

  return (
    <>
      {Boolean(uncreatedItems.length) && (
        <div className="text-sm">
          <p>Crear Items de Envío</p>
          {errorCreatingItems && (
            <p className="text-red-500">Ha ocurrido un error creando el item</p>
          )}
          {uncreatedItems.map((i) => (
            <div className="flex gap-x-2 items-center" key={i.type}>
              <input
                type="checkbox"
                value={i.isChecked}
                onChange={handleCheckUncreatedItem}
                name={i.type}
                id={i.type}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label htmlFor="">{itemTypeDictionary.get(i.type)}</label>
            </div>
          ))}
          <button
            onClick={handleCreateItems}
            className={`flex justify-center items-center text-xs gap-2 px-2 py-1 mt-3 rounded-md cursor-pointer text-white  ${
              uncreatedItems.some((i) => i.isChecked)
                ? 'bg-indigo-500 hover:bg-indigo-600'
                : 'bg-gray-300 hover:bg-gray-400'
            }`}
            type="button"
          >
            Crear items
          </button>
        </div>
      )}
      {Boolean(availableItems.length) && (
        <div className="text-sm">
          <p className="text-gray-500 mb-2">Items disponibles para envío</p>
          {errorCreatingShipment && (
            <p className="text-red-500">
              Ha ocurrido un error creando el envío
            </p>
          )}
          {availableItems.map((i) => (
            <div className="flex gap-x-2 items-center" key={i.type}>
              <input
                type="checkbox"
                value={i.isChecked}
                onChange={handleCheckAvailableItem}
                name={i.type}
                id={i.type}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label htmlFor="">{itemTypeDictionary.get(i.type)}</label>
            </div>
          ))}
          <button
            onClick={handleCreateNewShipment}
            className={`flex justify-center items-center text-xs gap-2 px-2 py-1 mt-3 rounded-md cursor-pointer text-white  ${
              availableItems.some((i) => i.isChecked)
                ? 'bg-indigo-500 hover:bg-indigo-600'
                : 'bg-gray-300 hover:bg-gray-400'
            }`}
            type="button"
            disabled={availableItems.every((i) => !i.isChecked)}
          >
            Generar nuevo pedido
          </button>
        </div>
      )}
    </>
  );
}

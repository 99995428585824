import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

import {
  statusEnums,
  TRAMIT_SUB_TYPES,
  TRAMIT_PATHS,
} from '../../../../helpers/enums';
import usePagination from '../../../../hooks/usePagination';
import { parseDayMonthYearWithSlash } from '../../../../helpers';
import PaginationFooter from '../../../../components/PaginationFooter';
import Spinner from '../../../../components/Spinner';
import { accountDetailsContext } from '../../../../context/account-details.context';

export default function Tramits() {
  const [tramits, setTramits] = useState([]);
  const [totalTramits, setTotalTramits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const [page, goNext, goPrev] = usePagination();
  const { account } = useContext(accountDetailsContext);

  useEffect(() => {
    (async () => {
      if (!account.id) return;
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/tramits/${account.id}?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setTramits(response.data.tramits);
      setTotalTramits(response.data.count);
      setIsLoading(false);
    })();
  }, [account.id, page]);

  if (isLoading)
    return (
      <div className="flex justify-center">
        <Spinner color="text-blue-700" size={10} />
      </div>
    );

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Código de referencia
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Matrícula
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tipo
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Estado
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Fecha
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Ver</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tramits.map((t, i) => {
                  const transStatus = statusEnums.find(
                    (step) => step.id === t.status,
                  );
                  return (
                    <tr
                      key={t.id}
                      className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                        {t.tramitCode}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {t.plate}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {TRAMIT_SUB_TYPES[t.tramitType]}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="shrink-0 flex">
                          <p
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${transStatus.bgColor} ${transStatus.textColor} mr-1`}
                          >
                            {transStatus.label}
                          </p>
                          {t.hasIncident && (
                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 mr-1">
                              Incidencia
                            </p>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {parseDayMonthYearWithSlash(t.createdAt)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <Link
                          to={`/${TRAMIT_PATHS[t.tramitType]}/${t.tramitCode}`}
                        >
                          Ver
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <PaginationFooter
              data={tramits}
              page={page}
              goNext={goNext}
              goPrev={goPrev}
              total={totalTramits}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

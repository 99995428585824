import { Link } from 'react-router-dom';
import { itemTypeDictionary } from '../components/Shipments/helpers/itemTypeDictionary';
import { getItemLink } from '../components/Shipments/helpers/getItemLink';

export const generateShipmentItemsString = (items) =>
  items.map((item) => (
    <span className="flex items-center justify-between">
      {itemTypeDictionary.get(item.type)}{' '}
      <Link
        to={getItemLink(item.type, item.entityId, item.entityType)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="font-medium text-blue-600 hover:text-blue-500">
          Ver
        </span>
      </Link>
    </span>
  ));

import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { Company, Account } from '../../types/types';

export interface UserInfoContextType {
  companyInfo: Company;
  accountInfo: Account;
}

export const userInfoContext = createContext<UserInfoContextType>(null);

export default function UserInfoContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [value, setValue] = useState<UserInfoContextType>({
    companyInfo: null,
    accountInfo: null,
  });

  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const { data: account } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/account/organizationId/${user.org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setValue({
        companyInfo: account.company,
        accountInfo: account,
      });
    })();
  }, [user]);

  return (
    <userInfoContext.Provider value={value}>
      {children}
    </userInfoContext.Provider>
  );
}

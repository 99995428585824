export const isValidDNI = (dni) => {
  try {
    if (dni.length !== 9) return false;
    const letterTable = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const dniNumbersArray = dni.split('');
    const dniNumbers = dniNumbersArray.slice(0, -1).join('');
    const dniLetter = dniNumbersArray[dniNumbersArray.length - 1];
    const DNIComputedLetter = letterTable.charAt(Number(dniNumbers) % 23);
    return DNIComputedLetter === dniLetter;
  } catch {
    return false;
  }
};

export const isValidNIE = (nie) => {
  try {
    if (nie.length !== 9) return false;
    const letterTable = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const nieNumbersArray = nie
      .slice(0, -1)
      .replace('X', '0')
      .replace('Y', '1')
      .replace('Z', '2')
      .split('');
    const nieNumbers = nieNumbersArray.join('');
    const nieLetter = nie.slice(-1);
    const NIEComputedLetter = letterTable.charAt(Number(nieNumbers) % 23);
    return NIEComputedLetter === nieLetter;
  } catch {
    return false;
  }
};

export const isValidCIF = (cif) => {
  try {
    if (cif.length !== 9) return false;
    const cif7CentralNumbers = cif.slice(1, -1);
    const lastDigitOf9 = cif.slice(-1);
    const evenSum =
      Number(cif7CentralNumbers[1]) +
      Number(cif7CentralNumbers[3]) +
      Number(cif7CentralNumbers[5]);

    const oddOne = String(Number(cif7CentralNumbers[0]) * 2)
      .split('')
      .map(Number)
      .reduce((a, b) => a + b, 0);
    const oddTwo = String(Number(cif7CentralNumbers[2]) * 2)
      .split('')
      .map(Number)
      .reduce((a, b) => a + b, 0);
    const oddThree = String(Number(cif7CentralNumbers[4]) * 2)
      .split('')
      .map(Number)
      .reduce((a, b) => a + b, 0);
    const oddFour = String(Number(cif7CentralNumbers[6]) * 2)
      .split('')
      .map(Number)
      .reduce((a, b) => a + b, 0);

    const oddSum = oddOne + oddTwo + oddThree + oddFour;

    const totalSum = evenSum + oddSum;

    const lastDigit = Number(String(totalSum)[1]);

    let controlDigit = 0;

    if (lastDigit !== 0) {
      controlDigit = 10 - lastDigit;
    }

    if (Number.isNaN(Number(lastDigitOf9))) {
      const dictRelation = {
        0: 'J',
        1: 'A',
        2: 'B',
        3: 'C',
        4: 'D',
        5: 'E',
        6: 'F',
        7: 'G',
        8: 'H',
        9: 'I',
      };
      return String(dictRelation[controlDigit]) === String(lastDigitOf9);
    }
    return Number(controlDigit) === Number(lastDigitOf9);
  } catch {
    return false;
  }
};

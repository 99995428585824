import React, { useRef, Dispatch, SetStateAction } from 'react';
import { IoIosSearch } from 'react-icons/io';
import useClickOutside from '../../../hooks/useClickOutside';
import { SearchResult } from './Searchbox';
import Hit from './Hit';

type SearchResultProps = {
  searchResults: SearchResult;
  setShowResults: Dispatch<SetStateAction<boolean>>;
  resetSearch: () => void;
};

export default function SearchResults({
  searchResults,
  setShowResults,
  resetSearch,
}: SearchResultProps) {
  const ref = useRef(null);

  useClickOutside(ref, () => {
    setShowResults(false);
  });

  return (
    <div
      className="absolute top-14 bg-white flex flex-col w-[600px] shadow-md mt-1 rounded-md border border-gray-200 max-h-[500px] overflow-y-auto"
      ref={ref}
    >
      {Boolean(searchResults?.hits?.length) && (
        <>
          <div className="py-1 px-4">
            <span className="text-gray-500">Trámites</span>
          </div>
          {searchResults.hits.map((hit) => (
            <Hit hit={hit} resetSearch={resetSearch} />
          ))}
        </>
      )}
      {!searchResults?.hits?.length && (
        <div className="flex flex-col justify-center items-center p-8">
          <IoIosSearch className="w-10 h-10 text-gray-500" />
          <span className="text-gray-500">No se encontraron resultados</span>
          <span className="text-gray-400">
            Por favor vuelva a intentarlo con un término diferente
          </span>
        </div>
      )}
    </div>
  );
}

import { Part, Status } from '../SignatureDetails';

export const partsDictionary = new Map<Part, string>([
  ['mandate', 'Mandato'],
  ['custom', 'Personalizado'],
  ['mandate-pro', 'Mandato Profesional'],
  ['mandate-double', 'Mandato Ambas Partes'],
  ['contract', 'Contrato'],
  ['misplacement', 'Extravío'],
  ['representation', 'Representación'],
]);

export const roleDictionary = new Map<
  'buyer' | 'seller' | 'pro' | 'agency',
  string
>([
  ['buyer', 'comprador'],
  ['seller', 'vendedor'],
  ['pro', 'profesional'],
  ['agency', 'gestor'],
]);

export const statusBadgeDictionary = new Map<
  Status,
  { value: string; color: string }
>([
  [
    'PENDING',
    { value: 'Esperando firmas', color: 'bg-yellow-100 text-yellow-800' },
  ],
  ['COMPLETED', { value: 'Completado', color: 'bg-green-100 text-green-800' }],
  ['CANCELLED', { value: 'Cancelado', color: 'bg-red-100 text-red-800' }],
]);

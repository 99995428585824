import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');

  const location = useLocation();

  const [page, setPage] = useState(!pageParam ? 0 : Number(pageParam));

  useEffect(() => {
    const fetchedParam = searchParams.get('page');
    if (fetchedParam) {
      setPage(Number(fetchedParam));
    }
  }, [location]);

  const goNext = () =>
    setPage((prevPage) => {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      const newPage = prevPage + 1;
      updatedSearchParams.set('page', newPage);
      setSearchParams(updatedSearchParams.toString());
      return newPage;
    });
  const goPrev = () =>
    setPage((prevPage) => {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      const newPage = prevPage - 1;
      updatedSearchParams.set('page', newPage);
      setSearchParams(updatedSearchParams.toString());
      return newPage;
    });

  return [page, goNext, goPrev, setPage];
};

export default usePagination;

import React, { useState } from 'react';
import { ContextMenuOptionType } from './ContextMenuButton';

import classNames from '../helpers/classNames';

export default function ContextMenuOption({
  option,
  active,
}: {
  option: ContextMenuOptionType;
  active: boolean;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { label, onClick, RightIcon } = option;

  const handlerWrapper = () => {
    try {
      setIsLoading(true);
      onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      type="button"
      disabled={isLoading}
      onClick={handlerWrapper}
      className={classNames(
        active ? 'bg-red-100 text-gray-900' : 'text-gray-700',
        'flex px-4 py-2 text-sm items-center gap-2 hover:bg-gray-100',
      )}
    >
      {label} {RightIcon && <RightIcon className="w-4 h-4" />}
    </button>
  );
}

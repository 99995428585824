import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { CheckIcon } from '@heroicons/react/outline';

import { ccaaDictionary } from '../../../enums/ccaaDictionary';
import { User } from '../../../types/types';
import Button from '../../../components/Button';
import useUserFieldsForm from './useUserFieldsForm';

const userLabelDictionary: { [key: string]: string } = {
  name: 'Nombre',
  surname: 'Apellidos',
  dni: 'DNI',
  address: 'Dirección',
  phone: 'Teléfono',
  email: 'Email',
  city: 'Ciudad',
  province: 'Provincia',
  ccaa: 'Comunidad Autónoma',
  zipCode: 'Código postal',
};

const errorDictionary: {
  [key: string]: string;
} = {
  email: 'El email no es válido',
  phone: 'El teléfono no es válido',
  dni: 'El DNI no es válido',
};

function UserFields({
  type,
  setIsOpen,
  preloadedValue,
}: {
  preloadedValue: User;
  type: 'Comprador' | 'Vendedor';
  setIsOpen: (isOpen: boolean) => void;
}) {
  const {
    errors,
    isLoading,
    handleSubmit,
    editableUserInfo,
    handleEditUserInfo,
  } = useUserFieldsForm(preloadedValue);

  const onUserFormSubmit = async () => {
    await handleSubmit(setIsOpen);
  };

  return (
    <>
      <div className="relative mt-4">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative z-5 flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">
            Datos Personales
          </span>
        </div>
      </div>
      <div className="py-5" id={`${type}-edit-data-section`}>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
          {Object.keys(editableUserInfo).map((e: keyof User) => (
            <div className="sm:col-span-1" key={e}>
              <div>
                <label
                  htmlFor={e}
                  className="block text-sm font-medium text-gray-700"
                >
                  {userLabelDictionary[e]}
                </label>
                <div className="mt-1">
                  {e === 'ccaa' ? (
                    <select
                      value={editableUserInfo.ccaa}
                      onChange={handleEditUserInfo}
                      defaultValue={editableUserInfo.ccaa || ''}
                      name="ccaa"
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      <option hidden>Seleccionar</option>
                      {Object.keys(ccaaDictionary).map((ca) => (
                        <option key={ca} value={ca}>
                          {ccaaDictionary[ca]}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      value={editableUserInfo[e]}
                      onChange={handleEditUserInfo}
                      type="text"
                      name={e}
                      id={e}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  )}
                </div>
                {errors.includes(e) && (
                  <p className="mt-2 -mb-6 text-xs ml-1 text-red-600">
                    {errorDictionary[e]}
                  </p>
                )}
              </div>
            </div>
          ))}
        </dl>
      </div>
      <div className="flex w-full justify-end mt-3">
        {isLoading ? (
          <Button
            bgColor="bg-gray-200"
            hoverBgColor="bg-gray-200"
            textColor="white"
            text="Cargando..."
            RightIcon={FaSpinner}
            disabled
          />
        ) : (
          <Button
            bgColor="bg-blue-700"
            hoverBgColor="bg-blue-900"
            textColor="white"
            text="Guardar"
            RightIcon={CheckIcon}
            callback={onUserFormSubmit}
            disabled={errors.length > 0}
            id={`${
              type === 'Comprador' ? 'buyer' : 'seller'
            }-save-edition-button}`}
          />
        )}
      </div>
    </>
  );
}

export default UserFields;

import React, { useRef, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/solid';
import { useServices } from '../../services';
import useClickOutside from '../../hooks/useClickOutside';
import LabelModal from '../LabelModal';
import calculateTextColor from '../../helpers/calculateTextColor';

function CustomLabels({ entityType, save, entityLabels, entityId }) {
  const [availableLabels, setAvailableLabels] = useState([]);

  const [labelsVisible, setLabelsVisible] = useState(false);

  const [newLabelModalOpen, setNewLabelModalOpen] = useState(false);

  const {
    fetchAllLabelsByEntityType,
    assignLabelToEntity,
    removeLabelFromEntity,
  } = useServices();

  const loadLabels = async () => {
    const labels = await fetchAllLabelsByEntityType(entityType);
    setAvailableLabels(labels);
    setLabelsVisible(true);
  };

  const list = useRef(null);

  useClickOutside(list, () => {
    setLabelsVisible(false);
  });

  const toggleLabel = async (labelId) => {
    if (entityLabels.some((l) => l.labelId === labelId)) {
      await removeLabelFromEntity(entityId, labelId);
    } else {
      await assignLabelToEntity(entityId, labelId);
    }
    save();
  };

  return (
    <>
      <LabelModal
        isOpen={newLabelModalOpen}
        setIsOpen={setNewLabelModalOpen}
        save={save}
      />
      <div className="relative flex max-h-7 items-center gap-2">
        {entityLabels.length > 0 && (
          <div className="flex gap-2 items-center h-full">
            {entityLabels.map((label) => (
              <div
                key={label.id}
                className={`${
                  calculateTextColor(label.color) === 'black'
                    ? 'text-gray-700'
                    : 'text-white'
                } text-xs font-bold rounded-full px-2 py-1 h-full flex items-center justify-center whitespace-nowrap`}
                style={{ backgroundColor: label.color }}
              >
                {label.name}
                <XCircleIcon
                  className={`${
                    calculateTextColor(label.color) === 'black'
                      ? 'text-gray-700'
                      : 'text-white'
                  } w-4 h-4 ml-1 cursor-pointer`}
                  onClick={() => toggleLabel(label.labelId)}
                />
              </div>
            ))}
          </div>
        )}
        <button onClick={loadLabels} type="button">
          +
        </button>
        {labelsVisible && (
          <ul
            ref={list}
            className="absolute top-10 right-0 flex flex-col w-[200px] items-stretch bg-white rounded-md shadow-md z-10"
          >
            {availableLabels.map((label) => (
              <button
                type="button"
                className={`text-center truncate text-sm flex items-center gap-2 hover:bg-gray-200 cursor-pointer py-1 px-2 ${
                  entityLabels.some((l) => l.labelId === label.id)
                    ? 'bg-gray-100'
                    : ''
                }`}
                key={label.id}
                onClick={() => toggleLabel(label.id)}
              >
                <div
                  className="rounded-full h-3 w-3"
                  style={{ backgroundColor: label.color }}
                >
                  {' '}
                </div>
                {label.name}
              </button>
            ))}
            <li>
              <button
                type="button"
                className="text-sm text-gray-500 px-2 text-center my-1 w-full"
                onClick={() => setNewLabelModalOpen(true)}
              >
                Nueva etiqueta +
              </button>
            </li>
          </ul>
        )}
      </div>
    </>
  );
}

export default CustomLabels;

import { Dispatch, SetStateAction, ChangeEvent } from 'react';
import { DeliveryInfo, ReceiverInfo } from '../types/types';
import { DeliveryAddress } from '../../../../../types/types';

const possibleReceiversRoles = {
  BUYER: 'BUYER',
  SELLER: 'SELLER',
  INTERMEDIARY: 'INTERMEDIARY',
  CUSTOM: 'CUSTOM',
};

export const handleChangeAddress =
  ({
    setReceiverRole,
    setDeliveryInfo,
    setReceiverInfo,
    deliveryAddresses,
  }: {
    setReceiverRole: Dispatch<SetStateAction<string>>;
    setDeliveryInfo: Dispatch<SetStateAction<DeliveryInfo>>;
    setReceiverInfo: Dispatch<SetStateAction<ReceiverInfo>>;
    deliveryAddresses: DeliveryAddress[];
  }) =>
  (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    setReceiverRole(target.value);
    if (
      !Object.values(possibleReceiversRoles).some(
        (value) => value.toLowerCase() === target.value,
      )
    ) {
      const selectedAddress = deliveryAddresses.find(
        (address) => address.id === target.value,
      );
      setDeliveryInfo({
        deliveryState: {
          value: selectedAddress.deliveryCCAA,
          label: 'Comunidad Autónoma',
          shipmentKey: 'state',
        },
        deliveryCity: {
          value: selectedAddress.deliveryCity,
          label: 'Ciudad',
          shipmentKey: 'city',
        },
        deliveryAddress: {
          value: selectedAddress.deliveryAddress,
          label: 'Dirección',
          shipmentKey: 'street',
        },
        deliveryZipCode: {
          value: selectedAddress.deliveryZipcode,
          label: 'Código Postal',
          shipmentKey: 'zipCode',
        },
      });

      setReceiverInfo({
        receiverName: {
          value: selectedAddress?.deliveryNameSurname || '',
          label: 'Nombre',
          shipmentKey: 'name',
        },
        receiverDni: {
          value: selectedAddress?.deliveryDOI || '',
          label: 'DNI',
          shipmentKey: 'doi',
        },
        receiverEmail: {
          value: selectedAddress?.deliveryEmail || '',
          label: 'Email',
          shipmentKey: 'email',
        },
        receiverPhone: {
          value: selectedAddress?.deliveryPhone || '',
          label: 'Teléfono',
          shipmentKey: 'phone',
        },
      });
    }
  };

import React from 'react';
import { MdAdd, MdFolderOpen } from 'react-icons/md';
import EmptyState from '../../../components/EmptyState';
import Button from '../../../components/Button';

interface TransfersEmptyStateProps {
  showNewTransferModal?: () => void;
}

export default function TransfersEmptyState({
  showNewTransferModal,
}: TransfersEmptyStateProps) {
  return (
    <EmptyState
      icon={MdFolderOpen}
      title="Aún no tienes trámites de compraventa"
      message="Gestiona y haz seguimiento de tus compraventas de vehículos. Comienza ahora y simplifica tus trámites."
      button={
        <Button
          LeftIcon={MdAdd}
          text="Iniciar nuevo trámite"
          bgColor="bg-blue-600"
          hoverBgColor="bg-blue-700"
          textColor="white"
          callback={showNewTransferModal}
        />
      }
    />
  );
}

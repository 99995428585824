import React, { useContext } from 'react';
import { transferDetailsContext } from '../../../../../context/TransferDetailsContext/transferDetailsContext';
import { STEP_GROUP_KEY } from '../../../../../shared/transaction-status';

import TransferDetailStep from './components/step/TransferDetailStep';

export default function TransferDetailSteps() {
  const { transfer } = useContext(transferDetailsContext);

  const steps = [
    {
      title: 'Trámite presentado',
      stepNumber: 1,
      stepGroup: STEP_GROUP_KEY.SUBMITTED_TO_AGENCY,
    },
    {
      title: 'Presentado DGT',
      stepNumber: 2,
      stepGroup: STEP_GROUP_KEY.SUBMITTED_TO_DGT,
    },
    {
      title: 'Finalizado DGT',
      stepNumber: 3,
      stepGroup: STEP_GROUP_KEY.FINISHED_BY_DGT,
    },
  ];
  return (
    <div className="bg-white border border-gray-300 rounded-md px-10 py-4 flex justify-start items-center">
      {steps.map(({ title, stepNumber, stepGroup }) => (
        <TransferDetailStep
          key={stepGroup}
          status={transfer?.transaction.status}
          title={title}
          stepNumber={stepNumber}
          stepGroup={stepGroup}
        />
      ))}
    </div>
  );
}

import React, { Fragment, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Transition } from '@headlessui/react';
import {
  DotsVerticalIcon,
  DownloadIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { FaSignature } from 'react-icons/fa';

import UploadFileModal from './UploadModal';
import Button from '../../../components/Button';
import classNames from '../../../helpers/classNames';
import useDownloadFile from '../../../hooks/useDownloadFile';
import {
  Car,
  Company,
  FILE_TYPE,
  Transaction,
  User,
} from '../../../types/types';
import PreviewDocument from './PreviewDocuments';
import { toasterContext } from '../../../context/ToasterContext/ToasterContext';
import {
  editCompany,
  editTransaction,
  editUser,
  editVehicle,
  removeExtraFile,
} from '../../../services/services';
import { fileTypeDictionary } from '../../../enums/fileTypeDictionary';
import DigitallySignModal from '../../../modals/DigitallySignModal/DigitallySignModal';

type Actions = {
  label: string;
  icon: React.ReactElement;
  callback: () => Promise<void>;
};

function TableActions({
  uri,
  fileName,
  fileType,
  tramitType,
  forceUpdate,
  actionAppliedTo,
  haveDigitalSignature,
}: Readonly<{
  uri: string;
  fileName: string;
  fileType: FILE_TYPE;
  tramitType: 'transaction' | 'bate' | 'registration';
  forceUpdate: () => void;
  actionAppliedTo: Car | User | Company | Transaction;
  haveDigitalSignature: boolean;
}>): React.ReactElement {
  const [showModal, setShowModal] = useState(false);
  const [isSignNewDocumentModalShown, setIsSignNewDocumentModalShown] =
    useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const { download } = useDownloadFile();

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const switchPreviewModal = () => setShowPreview((prev) => !prev);

  const { setToasterData } = useContext(toasterContext);

  const { getAccessTokenSilently } = useAuth0();

  const deleteDocument = async () => {
    const token = await getAccessTokenSilently();
    try {
      switch (fileType) {
        case FILE_TYPE.DOI_BACK: {
          await editUser(token, actionAppliedTo.id, {
            photoDni: [(actionAppliedTo as User)?.photoDni?.[0], null],
          });
          break;
        }
        case FILE_TYPE.DOI_FRONT: {
          await editUser(token, actionAppliedTo.id, {
            photoDni: [null, (actionAppliedTo as User)?.photoDni?.[1]],
          });
          break;
        }
        case FILE_TYPE.CIRCULATION_PERMIT: {
          await editVehicle(token, {
            id: actionAppliedTo.id,
            circulationPermit: null,
          });
          break;
        }
        case FILE_TYPE.TECHNICAL_SHEET:
          await editVehicle(token, {
            id: actionAppliedTo.id,
            technicalSheet: null,
          });
          break;
        case FILE_TYPE.PRO_INVOICE:
          await editTransaction(
            token,
            (actionAppliedTo as Transaction).transactionCode,
            {
              proInvoice: null,
            },
          );
          break;
        case FILE_TYPE.TIF: {
          await editCompany(token, (actionAppliedTo as Company).id, {
            nifFile: null,
          });
          break;
        }
        case FILE_TYPE.MANDATE: {
          await editUser(token, actionAppliedTo.id, {
            mandate: null,
          });
          break;
        }
        case FILE_TYPE.EXTRA_FILES: {
          await removeExtraFile(token, {
            objectKey: uri,
            tramitType,
            tramitId: actionAppliedTo.id,
          });
          break;
        }
        default:
          break;
      }
      setToasterData({
        type: 'SUCCESS',
        title: `Documento ${fileTypeDictionary(
          fileType,
        )} eliminado correctamente`,
        message: 'Se eliminó el documento.',
      });
      forceUpdate();
    } catch (err) {
      setToasterData({
        type: 'ERROR',
        title: 'Error eliminando documento',
        message:
          'Ha ocurrido algún problema eliminando el documento. Intentalo más tarde.',
      });
    }
  };

  const actions: Actions[] = [
    {
      label: 'Descargar',
      icon: <DownloadIcon className="w-5 h-5" />,
      callback: async () => download(uri, fileName),
    },
    {
      label: 'Eliminar',
      icon: <TrashIcon className="w-5 h-5" />,
      callback: deleteDocument,
    },
  ];

  return (
    <>
      <UploadFileModal
        closeModal={closeModal}
        showModal={showModal}
        fileType={fileType}
        actionAppliedTo={actionAppliedTo}
        forceUpdate={forceUpdate}
        tramitType={tramitType}
      />
      <DigitallySignModal
        isShown={isSignNewDocumentModalShown}
        hide={() => setIsSignNewDocumentModalShown(false)}
      />
      {uri && <PreviewDocument uri={uri} toggler={showPreview} />}
      <td className="flex justify-end whitespace-nowrap py-4 px-8 text-sm font-medium text-gray-900">
        {uri ? (
          <>
            <Button
              bgColor="bg-white"
              hoverBgColor="bg-gray-100"
              text="Ver"
              textColor="text-gray-900"
              border="border"
              borderColor="border-gray-300"
              callback={switchPreviewModal}
            />
            <Menu
              as="div"
              className="relative inline-block text-left pt-2 ml-3"
            >
              <div>
                <Menu.Button className="flex items-center">
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-8 top-7 -translate-y-full z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {actions.map((action) => (
                      <Menu.Item key={action.label}>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'flex px-4 py-2 text-sm w-full items-center justify-start gap-3',
                            )}
                            onClick={action.callback}
                          >
                            {action.icon}
                            {action.label}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </>
        ) : (
          <>
            {haveDigitalSignature && (
              <Button
                bgColor="bg-white"
                hoverBgColor="bg-gray-100"
                text="Firmar digitalmente"
                textColor="text-gray-900"
                border="border"
                borderColor="border-gray-300"
                additionalClasses="mr-2"
                LeftIcon={FaSignature}
                callback={() => setIsSignNewDocumentModalShown(true)}
              />
            )}
            <Button
              bgColor="bg-white"
              hoverBgColor="bg-gray-100"
              text="Adjuntar"
              textColor="text-gray-900"
              border="border"
              borderColor="border-gray-300"
              callback={openModal}
            />
          </>
        )}
      </td>
    </>
  );
}

export default TableActions;

import React from 'react';
import { validate as validateUUID } from 'uuid';
import {
  ArrowRightIcon,
  BanIcon,
  BellIcon,
  DownloadIcon,
  GlobeAltIcon,
} from '@heroicons/react/outline';
import axios from 'axios';

const viewExtraAction = {
  value: 'Original',
  action: async (params) => {
    const a = document.createElement('a');
    a.href = `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${params.serviceId}?original=true`;
    a.target = '_blank';
    a.click();
  },
  icon: <DownloadIcon className="h-4" />,
};

const cancelExtraAction = {
  value: 'Cancelar',
  action: async (params) => {
    params.setDocumentIdForDelete(params.documentId);
    params.setOpenDeleteModal(true);
  },
  icon: <BanIcon className="h-4" />,
};

const goToDocutenExtraAction = {
  value: 'Ver en Plataforma',
  action: async (params) => {
    const a = document.createElement('a');

    if (validateUUID(params.serviceId)) {
      a.href = `https://${
        process.env.REACT_APP_ENV !== 'PROD' ? 'apps-d' : 'apps'
      }.docusign.com/send/documents/details/${params.serviceId}`;
    } else {
      a.href = `https://${
        process.env.REACT_APP_ENV !== 'PROD' ? 'sandbox' : 'app'
      }.docuten.com/ViewDocumentByAdmin.html?id=${params.serviceId}`;
    }

    a.target = '_blank';
    a.click();
  },
  icon: <GlobeAltIcon className="h-4" />,
};

const notifyExtraAction = {
  value: 'Notificar',
  action: async (params) => {
    const token = await params.getToken();
    await axios.post(
      `${process.env.REACT_APP_SUZUKI_URL}/documents/notify/${params.documentId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  icon: <BellIcon className="h-4" />,
};

const downloadSignedExtraAction = {
  value: 'Descargar firmado',
  action: async (params) => {
    const a = document.createElement('a');
    a.href = `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${params.serviceId}`;
    a.target = '_blank';
    a.click();
  },
  icon: <DownloadIcon className="h-4" />,
};

const delegateExtraAction = {
  value: 'Delegar próximo firmante',
  action: async (params) => {
    params.setDataForDelegation({
      documentId: params.documentId,
      serviceId: params.serviceId,
    });
    params.setOpenDelegateModal(true);
  },
  icon: <ArrowRightIcon className="h-4" />,
};

export const extraActionsDictionary = new Map([
  [
    'PENDING',
    [
      notifyExtraAction,
      viewExtraAction,
      cancelExtraAction,
      goToDocutenExtraAction,
      delegateExtraAction,
    ],
  ],
  [
    'COMPLETED',
    [
      downloadSignedExtraAction,
      viewExtraAction,
      cancelExtraAction,
      goToDocutenExtraAction,
    ],
  ],
  ['CANCELLED', [viewExtraAction, goToDocutenExtraAction]],
]);

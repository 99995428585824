import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import IncidentWarning from './IncidentWarning/IncidentWarning';
import { getAllIncidentsByEntityId } from '../../services/services';

export type Incident = {
  id: string;
  name: string;
  description: string;
  solution: string;
  comment: string;
  resolvedAt: string;
  createdAt: string;
  createdBy: string;
};

function Incidents({ entityId }: { entityId: string }): JSX.Element {
  const [incidents, setIncidents] = useState<Incident[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!entityId) return;

    const fetchAllIncidentsByEntityId = async () => {
      const token = await getAccessTokenSilently();
      const { data } = await getAllIncidentsByEntityId(token, entityId, true);
      setIncidents(data);
    };

    fetchAllIncidentsByEntityId();
  }, [entityId]);

  if (!incidents.length) return null;

  return (
    <div className="flex flex-col gap-y-2">
      {incidents.map((incident) => (
        <IncidentWarning
          key={incident.id}
          incident={incident}
          expandable={incidents.length > 1}
        />
      ))}
    </div>
  );
}

export default Incidents;

import { useEffect, useState } from 'react';
import { useServices } from '../services';

export default function FileLink({ source }) {
  const [parsedSource, setParsedSource] = useState(null);
  const { getS3SignedUrl } = useServices();

  useEffect(() => {
    (async () => {
      if (/https?:\/\//.test(source)) {
        return setParsedSource(source);
      }
      const { signedUrl } = await getS3SignedUrl(
        'swipoo-transactions-files',
        source,
      );
      return setParsedSource(signedUrl);
    })();
  }, [source]);

  return (
    <a
      href={parsedSource}
      target="_blank"
      rel="noopener noreferrer"
      className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      Ver
    </a>
  );
}

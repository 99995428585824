import React, { useContext, useState } from 'react';
import Spinner from '../../components/Spinner';

import CommentRow from './CommentRow';
import classNames from '../../helpers/classNames';
import useComments from './useComments';
import { userInfoContext } from '../../context/UserInfoContext/UserInfoContext';

function Comments({
  entityId,
  tramitCode,
  tramitType,
}: Readonly<{
  entityId: string;
  tramitCode: string;
  tramitType: 'BATE' | 'TRANSACTION' | 'REGISTRATION';
}>): JSX.Element {
  const {
    user,
    comments,
    createComment,
    deleteComment,
    loadingCreateComment,
    errorComment,
  } = useComments(entityId);

  const [newCommentContent, setNewCommentContent] = useState<string>('');

  const { companyInfo, accountInfo } = useContext(userInfoContext);

  const submitComment = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
      createdBy: user.email,
      comment: newCommentContent,
      isPublic: true,
      entityId,
      emailToNotify: companyInfo?.representative?.email,
      tramitCode,
      tramitType,
      platform: accountInfo?.platform,
    };
    await createComment(payload);
    setNewCommentContent('');
  };

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewCommentContent(e.target.value);
  };

  return (
    <section aria-labelledby="comments-title" className="mt-10">
      <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
        <div className="divide-y divide-gray-200">
          {errorComment.fetching && (
            <div className="px-4 py-6">
              <span className="text-m text-red-500">
                Ha habido un error al cargar los comentarios. Vuelva a
                intentarlo.
              </span>
            </div>
          )}
          <div className="px-4 py-6 sm:px-6">
            {comments?.length ? (
              <ul className="space-y-8">
                {comments.map((comment) => (
                  <CommentRow
                    user={user}
                    key={comment.id}
                    comment={comment}
                    deleteComment={deleteComment}
                  />
                ))}
              </ul>
            ) : (
              <p>No hay comentarios disponibles</p>
            )}
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-6 sm:px-6">
          <div className="flex space-x-3">
            <div className="min-w-0 flex-1">
              {errorComment.creating && (
                <div className="mb-2">
                  <span className="text-m text-red-500">
                    No se ha creado el comentario. Vuelva a intentarlo.
                  </span>
                </div>
              )}
              {errorComment.deleting && (
                <div className="mb-2">
                  <span className="text-m text-red-500">
                    No se ha podido borrar el comentario. Vuelva a intentarlo.
                  </span>
                </div>
              )}
              <form onSubmit={submitComment}>
                <div>
                  <label htmlFor="comment" className="sr-only">
                    Comentarios
                  </label>
                  <textarea
                    id="comment"
                    name="comment"
                    rows={3}
                    className="block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:py-1.5 sm:text-sm sm:leading-6"
                    placeholder="Añadir comentario"
                    value={newCommentContent}
                    onChange={onChangeComment}
                  />
                </div>
                <div className="mt-3 flex items-center justify-end">
                  <button
                    type="submit"
                    disabled={!newCommentContent || loadingCreateComment}
                    className={classNames(
                      newCommentContent
                        ? 'border cursor-pointer'
                        : 'cursor-not-allowed opacity-50',
                      'inline-flex rounded-md bg-blue-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                    )}
                  >
                    {loadingCreateComment && (
                      <Spinner color="text-white" size={5} marginTop={0} />
                    )}
                    Añadir
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Comments;

import { useContext } from 'react';

import CompanyData from './components/CompanyData';
import CommentsModule from '../../../../components/Comments/CommentsModule';
import TramitStats from './components/TramitStats';
import { accountDetailsContext } from '../../../../context/account-details.context';

export default function Summary() {
  const { account } = useContext(accountDetailsContext);
  return (
    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 relative">
      <div className="lg:col-start-1 lg:col-span-1">
        <CompanyData account={account} />
      </div>
      <div className="lg:col-start-2 lg:col-span-2 space-y-6">
        <TramitStats accountId={account.id} />
        <CommentsModule
          entityId={account.id}
          entityIncidents={[]}
          emailToNotify={account?.representative?.email}
          platform={account?.platform}
          isAlwaysPrivate
        />
      </div>
    </div>
  );
}

import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { InvoiceForTable, LIMIT } from '../..';
import Table, { Column } from '../../../components/TypeformComponents/Table';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';
import useDateParams from '../../../hooks/useDateParams';

const columns: Column[] = [
  {
    label: 'Fecha de Creación',
    key: 'createdAt',
  },
  {
    label: 'Número de Factura',
    key: 'docNumber',
  },
  {
    label: 'Matrícula',
    key: 'plate',
  },
  {
    label: 'Código de Transferencia',
    key: 'transactionCode',
  },
  {
    label: 'Tipo de Servicio',
    key: 'serviceType',
  },
];

export default function InvoiceTable({
  invoices,
  page,
  totalCount,
  setTotalCount,
  setTotalPages,
}: {
  invoices: InvoiceForTable[];
  page: number;
  totalCount: number;
  setTotalCount: Dispatch<SetStateAction<number>>;
  setTotalPages: Dispatch<SetStateAction<number>>;
}) {
  const [invoicesInPage, setInvoicesInPage] = useState<InvoiceForTable[]>([]);

  const { setSingleParam, params } = useContext(queryParamsContext);

  const { dateSortParam, minDateParam, maxDateParam } = useDateParams();

  useEffect(() => {
    invoices.sort((a, b) =>
      dateSortParam === 'DESC' || !dateSortParam
        ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
  }, [dateSortParam]);

  useEffect(() => {
    if (!invoices.length) return;
    const filteredInvoices = invoices
      .filter((i) =>
        minDateParam
          ? new Date(i.createdAt).getTime() >= new Date(minDateParam).getTime()
          : true,
      )
      .filter((i) =>
        maxDateParam
          ? new Date(i.createdAt).getTime() <= new Date(maxDateParam).getTime()
          : true,
      )
      .filter((_, index) => {
        const pageFilter =
          index >= LIMIT * page && index <= LIMIT * page + LIMIT - 1;
        return pageFilter;
      });
    setInvoicesInPage(filteredInvoices);
  }, [
    invoices,
    page,
    totalCount,
    params,
    setTotalCount,
    setTotalPages,
    dateSortParam,
    minDateParam,
    maxDateParam,
  ]);

  return (
    <Table
      columns={columns}
      data={invoicesInPage}
      dateSort={dateSortParam}
      setSingleParam={setSingleParam}
      view
    />
  );
}

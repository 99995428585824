import { z } from 'zod';

export const userZod = z.object({
  type: z.literal('user'),
  id: z.string().min(1),
  phone: z.string().min(1),
  address: z.string().min(1),
  city: z.string().min(1),
  province: z.string().min(1),
  name: z.string().min(1),
  surname: z.string().min(1),
  dni: z.string().min(1),
});

export const proZod = z.object({
  type: z.literal('pro'),
  id: z.string().min(1),
  legalName: z.string().min(1),
  nif: z.string().min(1),
  fiscalAddressAddress: z.string().min(1),
  fiscalAddressCity: z.string().min(1),
  fiscalAddressProvince: z.string().min(1),
  representative: z.object({
    name: z.string().min(1),
    surname: z.string().min(1),
    dni: z.string().min(1),
    phone: z.string().min(1),
  }),
});

const proMandateZod = z.object({
  type: z.literal('proMandate'),
  id: z.string().uuid(),
  legalName: z.string().min(1),
  nif: z.string().min(1),
  fiscalAddressZipCode: z.string().min(1),
  fiscalAddressCity: z.string().min(1),
  fiscalAddressAddress: z.string().min(1),
  fiscalAddressStreetNumber: z.string().optional(),
  representative: z.object({
    name: z.string().min(1),
    surname: z.string().min(1),
    dni: z.string().min(1),
    phone: z.string().min(1),
    address: z.string().min(1),
  }),
});

export const carZod = z
  .object({
    brand: z.string().min(1),
    model: z.string().min(1),
    enrollmentDate: z.string().min(1),
    kms: z.number().nullable(),
    plate: z.string().min(1),
    frameNumber: z.string().min(1),
  })
  .transform((car) => ({
    carBrandAndModel: `${car.brand} ${car.model}`,
    carEnrollmentDate: car.enrollmentDate,
    carKms: car.kms ? String(car.kms) : null,
    carPlate: car.plate,
    carFrameNumber: car.frameNumber,
  }));

const agencyZod = z
  .object({
    id: z.string().uuid(),
    email: z.string().min(1),
    city: z.string().min(1),
    name: z.string().min(1),
    surname: z.string().min(1),
    colNumber: z.string().min(1),
    colLocation: z.string().min(1),
    address: z.string().min(1),
    addressNumber: z.string().min(1),
    zipCode: z.string().min(1),
  })
  .transform((agency) => ({
    id: agency.id,
    email: agency.email,
    city: agency.city,
    name: `${agency.name} ${agency.surname}`,
    number: agency.colNumber,
    colLocation: agency.colLocation,
    street: agency.address,
    streetNumber: agency.addressNumber,
    zipCode: agency.zipCode,
  }));

const userParser = (user: z.infer<typeof userZod>) => ({
  id: user.id,
  phone: user.phone,
  address: user.address,
  city: user.city,
  province: user.province,
  name: `${user.name} ${user.surname}`,
  nif: user.dni,
});

const proParser = (pro: z.infer<typeof proZod>) => ({
  id: pro.id,
  phone: pro.representative.phone,
  address: pro.fiscalAddressAddress,
  city: pro.fiscalAddressCity,
  province: pro.fiscalAddressProvince,
  name: pro.legalName,
  nif: pro.nif,
  representantName: `${pro.representative.name} ${pro.representative.surname}`,
  representantNif: pro.representative.dni,
});

const proMandateParser = (pro: z.infer<typeof proMandateZod>) => ({
  id: pro.id,
  phone: pro.representative.phone,
  address: pro.representative.address,
  name: `${pro.representative.name} ${pro.representative.surname}`,
  nif: pro.representative.dni,
  companyCity: pro.fiscalAddressCity,
  companyName: pro.legalName,
  companyNif: pro.nif,
  companyStreet: pro.fiscalAddressAddress,
  companyStreetNumber: pro.fiscalAddressStreetNumber || '',
  companyZipCode: pro.fiscalAddressZipCode,
});

const userOrProParser = (
  userOrPro:
    | z.infer<typeof userZod>
    | z.infer<typeof proZod>
    | z.infer<typeof proMandateZod>,
):
  | {
      id: string;
      phone: string;
      address: string;
      city: string;
      name: string;
      nif: string;
    }
  | {
      id: string;
      phone: string;
      address: string;
      city: string;
      name: string;
      nif: string;
      representantName: string;
      representantNif: string;
    }
  | {
      id: string;
      phone: string;
      address: string;
      name: string;
      nif: string;
      companyCity: string;
      companyName: string;
      companyNif: string;
      companyStreet: string;
      companyStreetNumber: string;
      companyZipCode: string;
    } => {
  switch (userOrPro.type) {
    case 'user':
      return userParser(userOrPro);
    case 'pro':
      return proParser(userOrPro);
    case 'proMandate':
      return proMandateParser(userOrPro);
    default:
      throw new Error('Invalid user or pro');
  }
};

export const contractZod = z.object({
  buyer: z
    .discriminatedUnion('type', [userZod, proZod])
    .transform(userOrProParser),
  seller: z
    .discriminatedUnion('type', [userZod, proZod])
    .transform(userOrProParser),
  car: carZod,
});

export const mandateBuyerZod = z.object({
  buyer: z
    .discriminatedUnion('type', [userZod, proMandateZod])
    .transform(userOrProParser),
  agent: agencyZod,
});

export const mandateSellerZod = z.object({
  seller: z
    .discriminatedUnion('type', [userZod, proMandateZod])
    .transform(userOrProParser),
  agent: agencyZod,
});

export const misplacementZod = z.object({
  car: carZod,
  seller: z
    .discriminatedUnion('type', [userZod, proZod])
    .transform(userOrProParser),
});

export const representationSellerZod = z.object({
  car: carZod,
  seller: proZod.transform(userOrProParser),
});
export const representationBuyerZod = z.object({
  car: carZod,
  buyer: proZod.transform(userOrProParser),
});

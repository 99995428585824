import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Switch } from '@headlessui/react';

import { TableRow } from './components/TableRow';
import Spinner from '../../../../components/Spinner';
import PaginationFooter from '../../../../components/PaginationFooter';
import { useServices } from '../../../../services';
import { accountDetailsContext } from '../../../../context/account-details.context';

const availableProviders = ['RACE_QD_LG_LCR'];
const modalityDict = {
  RACE_QD_LG_LCR: 'RACE G. NOMINADA (IP-138)',
};

export default function MechanicalWarranties() {
  const [mechanicalWarranties, setMechanicalWarranties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const { getAccessTokenSilently } = useAuth0();

  const { editAccount } = useServices();
  const { account, forceUpdate } = useContext(accountDetailsContext);

  useEffect(() => {
    (async () => {
      if (!account.id) return;

      const token = await getAccessTokenSilently();

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/account/${account.id}/mechanical-warranties`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setMechanicalWarranties(data.mechanicalWarranties);
      setTotalCount(data.total);
      setIsLoading(false);
    })();
  }, [page, account.id]);

  const goNext = () => {
    setPage((prevState) => prevState + 1);
  };

  const goPrev = () => {
    setPage((prevState) => prevState - 1);
  };

  const handleChangeProvider = async (isProviderActivated, providerEnum) => {
    try {
      const addProvider = Array.isArray(account.mechanicalWarrantyProviders)
        ? [providerEnum, ...account.mechanicalWarrantyProviders]
        : [providerEnum];
      const removeProvider = account.mechanicalWarrantyProviders?.filter(
        (mw) => mw !== providerEnum,
      );
      await editAccount(account.id, {
        mechanicalWarrantyProviders: isProviderActivated
          ? removeProvider
          : addProvider,
      });
    } catch (e) {
      setErrorMsg('Ocurrió un error al activar/desactivar este proveedor.');
    } finally {
      forceUpdate();
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Spinner color="text-blue-700" size={10} />
      </div>
    );
  }

  return (
    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 relative">
      <div className="lg:col-start-1 lg:col-span-1">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="px-4 sm:px-0">
                <div className="flex flex-none items-center gap-x-4">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    Productos disponibles
                  </h3>
                </div>
                <div className="mt-3 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    {availableProviders.map((ap) => {
                      const providerActivated =
                        account.mechanicalWarrantyProviders?.some(
                          (mw) => mw === ap,
                        );
                      return (
                        <>
                          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">
                              {modalityDict[ap]}
                            </dt>
                            <dd className="flex text-sm leading-6 text-gray-700 sm:mt-0 justify-end items-center">
                              <Switch
                                checked={providerActivated}
                                onChange={() =>
                                  handleChangeProvider(providerActivated, ap)
                                }
                                className={`${
                                  providerActivated
                                    ? 'bg-blue-600'
                                    : 'bg-blue-300'
                                }
          relative inline-flex h-5 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                              >
                                <span
                                  aria-hidden="true"
                                  className={`${
                                    providerActivated
                                      ? 'translate-x-6'
                                      : 'translate-x-0'
                                  }
            pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                              </Switch>
                            </dd>
                          </div>
                          <p className="text-sm text-red-500">{errorMsg}</p>
                        </>
                      );
                    })}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:col-start-2 lg:col-span-2 space-y-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Núm. póliza
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Matrícula
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Comisión
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Fecha de emisión
                    </th>
                    <th
                      scope="col"
                      aria-label="dropdown"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    />
                  </tr>
                </thead>
                <tbody>
                  {mechanicalWarranties.map((mw, i) => (
                    <TableRow mw={mw} i={i} key={mw.id} />
                  ))}
                </tbody>
              </table>
              <PaginationFooter
                data={mechanicalWarranties}
                page={page}
                goNext={goNext}
                goPrev={goPrev}
                total={totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

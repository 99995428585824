import { DuplicateIcon } from '@heroicons/react/outline';
import React, { useContext } from 'react';
import { transferDetailsContext } from '../../../../context/transfer-details.context';
import { parseDate } from '../../../../helpers';

import Incidents from '../../../../components/Incidents/Incidents';
import { ENTITY_TYPES } from '../../../../helpers/enums';
import Labels from '../../../../components/Labels/Labels';

export default function TopSection() {
  const { transfer, forceUpdate, transactionIncidents } = useContext(
    transferDetailsContext,
  );

  const { transaction, intermediary, labels } = transfer;

  const copyToClipboard = (text) => navigator.clipboard.writeText(text);

  const ferrariFormUrl = () =>
    `https://swipoo.com/documentos/${transaction?.transactionCode}`;

  return (
    <div className="px-4 py-5 sm:px-6">
      <div className="flex justify-between gap-x-4">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900 inline">
            {transaction.transactionCode} {transaction.isBatecom && '- (COM)'}
          </h2>

          <p className="my-1 max-w-2xl text-sm text-gray-500">
            <time dateTime={parseDate(transaction.createdAt)}>
              Creada el {parseDate(transaction.createdAt)}
            </time>
          </p>
          {(transaction.status === 0 || transaction.status === 1) &&
            !transaction.isB2B && (
              <div className="flex mb-2">
                <DuplicateIcon
                  onClick={() => copyToClipboard(ferrariFormUrl())}
                  className="mr-1 inline h-5 w-5 text-gray-400 cursor-pointer"
                  aria-hidden="true"
                />
                <a
                  href={ferrariFormUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 text-xs"
                >
                  {ferrariFormUrl()}
                </a>
              </div>
            )}
        </div>
        <Incidents
          entityId={transaction?.id}
          entityType={ENTITY_TYPES.TRANSACTION}
          forceUpdate={forceUpdate}
          emailToNotify={
            intermediary?.email || intermediary?.representative?.email
          }
          entityIncidents={transactionIncidents}
        />
      </div>
      <Labels
        forceUpdate={forceUpdate}
        labels={labels}
        entityId={transaction?.id}
        entityType={ENTITY_TYPES.TRANSACTION}
      />
    </div>
  );
}

import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useReducer, useEffect, useState, useContext } from 'react';

import Offer from './Offer/index';
import Issuance from './Issuance';
import { Information } from './Information';
import { Car, User } from '../../../types/types';
import {
  WeecoverOfferResponse,
  getMechanicalWarranty,
  MechanicalWarrantyInfoType,
} from '../../../services/mechanicalWarraties';
import Spinner from '../../../components/Spinner';
import { userInfoContext } from '../../../context/UserInfoContext/UserInfoContext';

type StepsForm = 'OFFER' | 'ISSUANCE';

type MechanicalWarrantyType = {
  car: Car;
  buyer: User;
};

export type OfferFormStateType = {
  carRegistration: string;
  carRegistrationDate: string;
  price: number;
  engineCapacity: number;
  kilometers: number;
};

export default function MechanicalWarranty({
  car,
  buyer,
}: MechanicalWarrantyType) {
  const [activationLoading, setActivationLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mechanicalWarranty, setMechanicalWarranty] =
    useState<MechanicalWarrantyInfoType>(null);
  const [step, setStep] = useState<StepsForm>('OFFER');
  const [selectedOffer, setSelectedOffer] =
    useState<WeecoverOfferResponse>(null);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const [bannerActivated, setBannerActivated] = useState(true);

  const [offerFormState, setOfferFormState] = useState<OfferFormStateType>({
    carRegistration: car.plate || null,
    carRegistrationDate: car.enrollmentDate?.split('T')[0] || null,
    price: null,
    engineCapacity: Number(car.cc) || null,
    kilometers: Number(car.kms) || null,
  });

  const { getAccessTokenSilently } = useAuth0();
  const { accountInfo, companyInfo } = useContext(userInfoContext);

  const stepBack = () => {
    setSelectedOffer(null);
    setStep('OFFER');
  };

  const handleCreateLead = async () => {
    try {
      setActivationLoading(true);
      await axios.post(
        `https://api.pipedrive.com/v1/activities?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
        {
          subject: `Garantía Mecánica ${accountInfo?.name}`,
          attendees: [{ email_address: companyInfo?.representative?.email }],
          done: 0,
        },
      );
    } finally {
      setActivationLoading(false);
      setBannerActivated(false);
    }
  };

  useEffect(() => {
    const fetchMechanicalWarrranty = async () => {
      try {
        setIsLoading(true);
        const token = await getAccessTokenSilently();
        const response = await getMechanicalWarranty(token, car.id);
        setMechanicalWarranty(response);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMechanicalWarrranty();
  }, [car.id, updater]);

  const hasNotMechanicalWarrantiesActivated =
    !accountInfo.mechanicalWarrantyProviders?.length;

  if (isLoading) {
    return (
      <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5 flex justify-center items-center min-h-[300px]">
        <Spinner size={10} color="text-blue-500" marginTop={0} />
      </div>
    );
  }

  return (
    <>
      {hasNotMechanicalWarrantiesActivated && (
        <div className="bg-white shadow sm:rounded-lg mt-5">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {bannerActivated
                ? 'Activar garantías mecánicas'
                : 'Activación del servicio solicitada'}
            </h3>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <div className="max-w-xl text-sm text-gray-500">
                <p>
                  {bannerActivated
                    ? 'Contrata garantías mecánicas desde el expediente. Hazlo sencillo y sin perder tiempo.'
                    : 'Te contactaremos para explicarte todos los detalles sobre la funcionalidad de garantías mecánicas.'}
                </p>
              </div>
              {bannerActivated && (
                <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                  <button
                    type="button"
                    onClick={handleCreateLead}
                    disabled={activationLoading}
                    className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                  >
                    {activationLoading ? 'Solicitando...' : 'Activar'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
        {mechanicalWarranty ? (
          <Information mechanicalWarranty={mechanicalWarranty} />
        ) : (
          <>
            {step === 'OFFER' && (
              <Offer
                setStep={setStep}
                selectedOffer={selectedOffer}
                setOfferFormState={setOfferFormState}
                offerFormState={offerFormState}
                setSelectedOffer={setSelectedOffer}
                hasNotMechanicalWarrantiesActivated={
                  hasNotMechanicalWarrantiesActivated
                }
              />
            )}
            {step === 'ISSUANCE' && (
              <Issuance
                car={car}
                buyer={buyer}
                stepBack={stepBack}
                selectedOffer={selectedOffer}
                offerFormState={offerFormState}
                forceUpdate={forceUpdate}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useServices } from '../../../../services';

export default function useShipment({ registration, updater }) {
  const [shipments, setShipments] = useState([]);
  const [isShipmentLoading, setIsShipmentLoading] = useState(false);
  const [getShipmentError, setGetShipmentError] = useState('');

  const { getAllShipmentsByEntityIdId } = useServices();

  useEffect(() => {
    const fetchShimpents = async () => {
      try {
        setGetShipmentError('');
        setIsShipmentLoading(true);

        const response = await getAllShipmentsByEntityIdId(registration.id);
        setShipments(
          response.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          ),
        );
      } catch (e) {
        setGetShipmentError(
          'Ha ocurrido un error al cargar la información del envio.',
        );
      } finally {
        setIsShipmentLoading(false);
      }
    };

    if (registration?.id) {
      fetchShimpents();
    }
  }, [registration, updater]);

  return {
    shipments,
    getShipmentError,
    isShipmentLoading,
  };
}

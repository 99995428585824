export default function DateInput({ label, stateName, value, handleChange }) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={stateName}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <input
          value={value}
          type="date"
          name={stateName}
          id={stateName}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

import React from 'react';

import { TableRow } from './TableRow';
import { MechanicalWarranty } from '../../index';

export default function MechanicalWarrantiesTable({
  data,
}: {
  data: MechanicalWarranty[];
}) {
  return (
    <div className="overflow-x-auto min-h-fit shadow ring-1 ring-black ring-opacity-5 rounded-lg">
      <table className="table-fixed min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
            >
              Núm. póliza
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Matrícula
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Precio
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Fecha de emisión
            </th>
            <th
              scope="col"
              className="sticky right-0 bg-gray-50 py-3.5 pl-3 pr-4 shadow-md sm:pr-6"
            >
              <span className="sr-only">Desplegar</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {data.map((mw: MechanicalWarranty, i: number) => (
            <TableRow mw={mw} i={i} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

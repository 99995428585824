import React from 'react';

export default function ItpTableSkeletonLoader() {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Dato
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Valores calculados
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {[1, 2, 3, 4, 5].map((transfer, personIdx) => (
                  <tr
                    key={transfer}
                    className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                        ---
                      </span>
                    </td>

                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                        ---
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from 'react';

import StatusCategoryFilter from '../SpecialFilter/StatusCategoryFilter';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';
import {
  isValidTransferStatusCategory,
  TransferStatusCategories,
} from '../../enums/TransferStatusCategories';

export interface FilterLabel {
  id: number;
  name: string;
  value: string;
}

function TransfersFilters() {
  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);
  const [selectedStatusCategoryFilter, setSelectedStatusCategoryFilter] =
    useState<TransferStatusCategories>(TransferStatusCategories.ONGOING);

  const updateStatusCategoryFilter = (
    clickedStatusCategory: TransferStatusCategories,
  ) => {
    setSelectedStatusCategoryFilter(clickedStatusCategory);
    setSingleParam('status_category', clickedStatusCategory);
  };

  useEffect(() => {
    const statusCategory = getSingleParam('status_category');

    if (!isValidTransferStatusCategory(statusCategory)) {
      setSingleParam('status_category', TransferStatusCategories.ONGOING);
      setSelectedStatusCategoryFilter(TransferStatusCategories.ONGOING);
      return;
    }
    setSelectedStatusCategoryFilter(statusCategory as TransferStatusCategories);
  }, [params]);

  return (
    <div className="flex overflow-x-auto">
      <div className="relative border-b border-gray-200 py-0 z-9 w-full">
        <StatusCategoryFilter
          selected={selectedStatusCategoryFilter}
          setSelected={updateStatusCategoryFilter}
        />
      </div>
    </div>
  );
}

export default TransfersFilters;

import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { classNames } from '../../../../../helpers';
import GenerateInvoiceModal from './GenerateInvoiceModal';

const ccaaItpDictionary = {
  CT: 'https://seu.atc.gencat.cat/ca/OficinaVirtual/Pagines/TransmissioVehicles620.aspx',
  MD: 'https://gestiona.comunidad.madrid/opti_webapp_contrib/#/login-view?returnUrl=%2Fmi-espacio',
  EX: 'https://sede.gobex.es/SEDE/estructura/accesoClave.jsf?opcion=32',
  PV: 'https://egoitza.gipuzkoa.eus/WAS/CORP/WATTramiteakWEB/menu.do?mostrarServicios',
  NC: null,
  RI: null,
  CL: 'https://www.tramitacastillayleon.jcyl.es/web/jcyl/AdministracionElectronica/es/Plantilla100Detalle/1251181050732/Servicio/1284208585190/Tramite',
  CM: 'https://modelos-tributos.jccm.es/webgreco/modelos/jsp/cumplimentacion/GreJspModelo620_2024.jsp',
  CB: 'https://ovhacienda.cantabria.es/oficinavirtual/modelo621Comprador.do',
  GA: 'https://viap.cixtec.es/via/ovrun/appdirect/sec/a?idapp=211&tipo=A&param=M_WGV10&Idioma=C',
  AS: 'https://sede.tributasenasturias.es/sites/sede/default/es_ES/Modelos-tributarios?cc1_modelo=620&pt_modelo=620&pt_nuevaCarga=s&esSiga=N&cc1_accion=C&pt_accion=C',
  CN: null,
  AN: 'https://www.juntadeandalucia.es/economiayhacienda/apl/surweb/modelos/modelo621/621.jsp',
  AR: 'https://aplicaciones.aragon.es/yafarcentral/',
  IB: 'https://www.atib.es/TA/Modelos/Modelo.aspx?m=621',
  MC: 'https://sede.carm.es/web/pagina?IDCONTENIDO=3284&IDTIPO=240&RASTRO=c$m40288#solicitud-electronica',
  VC: 'https://atv.gva.es/es/620-individual',
};

const pvItp = {
  Alava:
    'https://egoitza.araba.eus/es/-/modelo-620-tv.-transmision-de-vehiculos-usados-autoliquidacion',
  Bizkaia: 'https://ataria.ebizkaia.eus/es/catalogo-de-tramites-y-servicios',
  Gipuzkoa:
    'https://egoitza.gipuzkoa.eus/WAS/CORP/WATTramiteakWEB/menu.do?mostrarServicios',
};

function ItpLiquidationLink({ ccaa, transaction, plate, forceUpdate }) {
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  return (
    <>
      <GenerateInvoiceModal
        transaction={transaction}
        ccaa={ccaa}
        plate={plate}
        showModal={isInvoiceModalOpen}
        setShowModal={setIsInvoiceModalOpen}
        forceUpdate={forceUpdate}
      />
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {ccaa && ccaaItpDictionary[ccaa] && (
              <div className="py-1">
                {ccaa !== 'PV' ? (
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={ccaaItpDictionary[ccaa]}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        )}
                      >
                        Ir a liquidar ITP
                      </a>
                    )}
                  </Menu.Item>
                ) : (
                  Object.entries(pvItp).map(([city, link]) => (
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={link}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                          )}
                        >
                          Ir a liquidar ITP - {city}
                        </a>
                      )}
                    </Menu.Item>
                  ))
                )}
              </div>
            )}
            <Menu.Item>
              {transaction?.holdedItpLiquidationPurchaseId
                ? ({ active }) => (
                    <a
                      href={`https://app.holded.com/expenses/list#open:purchase-${transaction?.holdedItpLiquidationPurchaseId}`}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm w-full text-left',
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver Factura
                    </a>
                  )
                : ({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm w-full text-left',
                      )}
                      onClick={() => setIsInvoiceModalOpen(true)}
                    >
                      Generar Factura
                    </button>
                  )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}

export default ItpLiquidationLink;

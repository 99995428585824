/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useServices } from '../services';

export const accountDetailsContext = createContext({
  account: {},
  setAggregatedAccount: () => {},
  accountLoading: false,
  forceUpdate: () => {},
});

export function AccountDetailsContextProvider({ children }) {
  const [account, setAccount] = useState({});
  const [accountLoading, setAccountLoading] = useState(true);

  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { accountId } = useParams();

  const { getAccountById } = useServices();

  useEffect(() => {
    const fetchAccount = async () => {
      const response = await getAccountById(accountId);
      setAccount(response);
      setAccountLoading(false);
    };
    fetchAccount();
  }, [accountId, updater]);

  const value = useMemo(
    () => ({
      account,
      setAccount,
      forceUpdate,
      accountLoading,
    }),
    [account, setAccount, forceUpdate, accountLoading],
  );
  return (
    <accountDetailsContext.Provider value={value}>
      {children}
    </accountDetailsContext.Provider>
  );
}

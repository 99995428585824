import { useEffect, useState, useContext } from 'react';

import DeliveryAddressList from './components/DeliveryAddressList';
import { useServices } from '../../../../services';
import ShipmentTable from './components/ShipmentTable';
import ShipmentBatcher from './shipmentBatcher/shipmentBatcher';
import usePagination from '../../../../hooks/usePagination';
import { accountDetailsContext } from '../../../../context/account-details.context';

export default function Shipments() {
  const [sentShipments, setSentShipments] = useState({
    data: [],
    loading: false,
    error: '',
    count: 0,
  });

  const [page, goNext, goPrev] = usePagination();
  const { account } = useContext(accountDetailsContext);
  const { getSentShipmentsByAccountId } = useServices();

  const fetchSentShipments = async () => {
    try {
      setSentShipments((prev) => ({ ...prev, loading: true }));
      const response = await getSentShipmentsByAccountId(account?.id, page);
      setSentShipments((prev) => ({
        ...prev,
        data: response.shipments,
        count: response.count,
      }));
    } catch (e) {
      setSentShipments((prev) => ({
        ...prev,
        error: 'Error al cargar los envíos',
      }));
    } finally {
      setSentShipments((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (!account?.id) return;
    fetchSentShipments();
  }, [account?.id, page]);

  return (
    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 relative">
      <div className="lg:col-start-1 lg:col-span-1">
        <DeliveryAddressList accountId={account?.id} />
      </div>
      <div className="lg:col-start-2 lg:col-span-2 flex flex-col gap-y-2">
        <ShipmentTable
          shipments={sentShipments.data}
          count={sentShipments.count}
          title="Enviados"
          page={page}
          goNext={goNext}
          goPrev={goPrev}
        />
        <ShipmentBatcher account={account} />
      </div>
    </div>
  );
}

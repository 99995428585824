import React, { useRef } from 'react';
import { CreateTransferContextProvider } from '../../context/CreateTransferContext/CreateTransferContext';
import useClickOutside from '../../hooks/useClickOutside';
import Footer from './layout/Footer/Footer';
import Header from './layout/Header/Header';
import Stepper from './layout/Stepper/Stepper';

interface NewTransferModalProps {
  readonly isShown: boolean;
  readonly hide: () => void;
}

export default function NewTransferModal({
  isShown,
  hide,
}: NewTransferModalProps): JSX.Element {
  const ref = useRef(null);
  useClickOutside(ref, hide);

  if (isShown) {
    return (
      <dialog
        open={isShown}
        aria-label="Nueva compraventa"
        aria-modal={isShown}
        className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.4)] z-50 w-screen h-screen flex items-center justify-center"
      >
        <CreateTransferContextProvider>
          <div className="w-[900px] h-fit bg-white rounded-md" ref={ref}>
            <Header toggleModalOpen={hide} />
            <Stepper />
            <Footer toggleModalOpen={hide} />
          </div>
        </CreateTransferContextProvider>
      </dialog>
    );
  }

  return null;
}

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Buffer } from 'buffer';
import FsLightbox from 'fslightbox-react';
import { ASYNC_STATE } from '../types/types';
import usePagination from '../hooks/usePagination';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import Pagination from '../components/Pagination';
import InvoiceTable from './components/InvoiceTable/InvoiceTable';
import InvoiceFilters from './components/InvoiceFilters/InvoiceFilters';
import InvoiceTableSkeletonLoader from './components/InvoiceTableSkeletonLoader/InvoiceTableSkeletonLoader';
import { holdedServices } from './components/InvoiceTable/holdedServices';

export interface InvoiceForTable {
  docNumber: string;
  plate: string;
  transactionCode: string;
  serviceType: string;
  viewCallback: () => void;
  createdAt: Date;
}

export interface MultipledueDate {
  date: number;
  amount: number;
}

export interface Product {
  name: string;
  desc: string;
  price: number;
  units: number;
  tax: number;
  taxes: string[];
  tags: string[];
  discount: number;
  retention: number;
  weight: number;
  costPrice: number;
  sku: number;
  account: string;
  serviceId?: string;
}

type HoldedInvoiceListResponse = {
  id: string;
  contact: string;
  contactName: string;
  desc: string;
  date: number;
  dueDate: number;
  multipledueDate: MultipledueDate;
  notes: string;
  tags: string[];
  products: Product[];
  tax: number;
  subtotal: number;
  discount: number;
  total: number;
  language: string;
  status: number;
  customFields: { field: string; value: string }[];
  docNumber: string;
  currency: string;
  currencyChange: number;
  paymentsTotal: number;
  paymentsPending: number;
  paymentsRefunds: number;
  shipping: string;
};

export const LIMIT = 10;

export default function InvoicesList() {
  const [loadState, setLoadState] = useState<ASYNC_STATE>(
    ASYNC_STATE.NOT_STARTED,
  );
  const [invoices, setInvoices] = useState<InvoiceForTable[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [pdfSource, setPdfSource] = useState<string | null>(null);

  const lightboxToggle = () => setIsLightboxOpen((prev) => !prev);

  const { getAccessTokenSilently } = useAuth0();

  const { page, setPage } = usePagination();

  const { companyInfo } = useContext(userInfoContext);

  useEffect(() => {
    (async () => {
      if (!companyInfo) return;
      setLoadState(ASYNC_STATE.LOADING);

      const token = await getAccessTokenSilently();

      const { data: fetchedInvoices } = await axios.get<
        HoldedInvoiceListResponse[]
      >(
        `${process.env.REACT_APP_BASE_API_URL}/list-holded-invoices?holdedId=${
          companyInfo.holdedId
        }&sort=created-desc&startDate=1672527600&endDate=${Math.floor(
          Date.now() / 1000,
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const mappedInvoices: InvoiceForTable[] = fetchedInvoices.map(
        (invoice) => ({
          docNumber: invoice.docNumber,
          plate:
            invoice.customFields.find((f) => f.field === 'Matrícula')?.value ||
            '',
          transactionCode:
            invoice.customFields.find((f) =>
              [
                'Código de referencia',
                'Código de referencia transferencia',
                'Ref. transferencia',
                'Ref. com',
                'Ref. bate',
                'Código de referencia baja',
              ].includes(f.field),
            )?.value || '',
          serviceType:
            holdedServices.find((s) =>
              invoice.products.find((p) => s.id === p?.serviceId),
            )?.label || '',
          viewCallback: async () => {
            const { data: base64Blob } = await axios({
              url: `${process.env.REACT_APP_BASE_API_URL}/holded-document-pdf?documentType=invoice&documentId=${invoice.id}`,
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/pdf',
              },
            });

            const buffer = Buffer.from(base64Blob, 'base64');

            const pdf = new Blob([buffer], {
              type: 'application/pdf',
            });

            const url = URL.createObjectURL(pdf);

            setPdfSource(url);
          },
          createdAt: new Date(invoice.date * 1000 + 2 * 60 * 60 * 1000),
        }),
      );
      setInvoices(mappedInvoices);
      setTotalCount(fetchedInvoices.length);
      setTotalPages(Math.ceil(fetchedInvoices.length / LIMIT));
      setLoadState(ASYNC_STATE.LOADED);
    })();
  }, [companyInfo]);

  useEffect(() => {
    if (pdfSource) {
      lightboxToggle();
    }
  }, [pdfSource]);

  return (
    <>
      <FsLightbox
        toggler={isLightboxOpen}
        sources={
          [
            <iframe
              title="pdf"
              src={pdfSource}
              width="1920px"
              height="1080px"
              allow="autoplay; fullscreen"
              allowFullScreen
            />,
          ] as unknown as string[]
        }
      />
      <div className="flex flex-col gap-5 p-8 w-full h-full min-h-fit bg-gray-100">
        <h1 className="text-3xl font-semibold">Facturas</h1>
        <InvoiceFilters />
        {loadState === ASYNC_STATE.LOADING && <InvoiceTableSkeletonLoader />}
        {loadState === ASYNC_STATE.LOADED && (
          <InvoiceTable
            invoices={invoices}
            page={page}
            totalCount={totalCount}
            setTotalPages={setTotalPages}
            setTotalCount={setTotalCount}
          />
        )}
        <Pagination
          limit={LIMIT}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totalCount={totalCount}
        />
      </div>
    </>
  );
}

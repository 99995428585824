import { User } from '@auth0/auth0-react';
import { Dispatch, SetStateAction } from 'react';
import { DeliveryInfo, ReceiverInfo } from '../types/types';

export const setNewReceiver = (
  user: User,
  setDeliveryInfo: Dispatch<SetStateAction<DeliveryInfo>>,
  setReceiverInfo: Dispatch<SetStateAction<ReceiverInfo>>,
) => {
  setDeliveryInfo((prev) => ({
    deliveryState: {
      ...prev.deliveryState,
      value: user.ccaa || '',
    },
    deliveryCity: {
      ...prev.deliveryCity,
      value: user.city || '',
    },
    deliveryAddress: {
      ...prev.deliveryAddress,
      value: user.address || '',
    },
    deliveryZipCode: {
      ...prev.deliveryZipCode,
      value: user.zipCode || '',
    },
  }));

  const receiverName =
    `${user.name || ''}${
      user.name ? ` ${user.surname}` : user.surname || ''
    }` || '';
  setReceiverInfo((prev) => ({
    receiverName: {
      ...prev.receiverName,
      value: receiverName,
    },
    receiverDni: {
      ...prev.receiverDni,
      value: user.dni || '',
    },
    receiverEmail: {
      ...prev.receiverEmail,
      value: user.email || '',
    },
    receiverPhone: {
      ...prev.receiverPhone,
      value: user.phone || '',
    },
  }));
};

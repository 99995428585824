export type ServiceTypeI =
  | 'A00'
  | 'A01'
  | 'A02'
  | 'A03'
  | 'A04'
  | 'A05'
  | 'A07'
  | 'A08'
  | 'A10'
  | 'A11'
  | 'A12'
  | 'A13'
  | 'A14'
  | 'A15'
  | 'A16'
  | 'A18'
  | 'A20'
  | 'B00'
  | 'B06'
  | 'B09'
  | 'B17'
  | 'B18'
  | 'B19'
  | 'B21';

export const serviceTypeDict: { [key in ServiceTypeI]: string } = {
  A00: 'PUBLICO SIN EPECIFICAR',
  A01: 'PUBLICO ALQUILER SIN CONDUCTOR',
  A02: 'PUBLICO ALQUILER CON CONDUCTOR',
  A03: 'PUBLICO APRENDIZAJE CONDUCCION',
  A04: 'PUBLICO TAXI',
  A05: 'PUBLICO AUXILIO CARRETERA',
  A07: 'PUBLICO AMBULANCIA',
  A08: 'PUBLICO FUNERARIO',
  A10: 'PUBLICO MERCANCIAS PELIGROSAS',
  A11: 'PUBLICO BASURERO',
  A12: 'PUBLICO TRANSPORTE ESCOLAR',
  A13: 'PUBLICO POLICIA',
  A14: 'PUBLICO BOMBEROS',
  A15: 'PUBLICO PROTECCION CIVIL Y SALVAMENTO',
  A16: 'PUBLICO MINISTERIO DE DEFENSA',
  A18: 'PUBLICO ACTIVIDAD ECONOMICA',
  A20: 'PUBLICO MERCANCIAS PERECEDERAS',
  B00: 'PARTICULAR SIN ESPECIFICAR',
  B06: 'PARTICULAR AGRICOLA',
  B09: 'PARTICULAR OBRAS',
  B17: 'PARTICULAR VIVIENDA',
  B18: 'PARTICULAR ACTIVIDAD ECONOMICA',
  B19: 'PARTICULAR RECREATIVO',
  B21: 'PARTICULAR VEHICULO DE FERIAS',
};

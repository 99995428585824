/* eslint-disable no-empty */
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { useServices } from '../../../services';
import usePagination from '../../../hooks/usePagination';
import CarsTableList from '../../../components/CarsTableList';
import UsersTableList from '../../../components/UsersTableList';
import TransactionsTableList from '../../../components/TransactionsTableList';
import CompaniesTableList from '../../../components/CompaniesTableList';
import ShipmentTableByOTN from '../../../components/ShipmentTableByOTN';
import Spinner from '../../../components/Spinner';
import AccountsTableList from '../../../components/AccountsTableList';

export default function Search() {
  const [searchResults, setSearchResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [page, goNext, goPrev] = usePagination();
  const { type, query: searchQuery } = useParams();
  const { getAllBySearchQuery } = useServices();
  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      const cleanSearchQuery = searchQuery?.trimStart().trimEnd();
      const cleanQuery = `${type}:${cleanSearchQuery}`;
      try {
        const response = await getAllBySearchQuery(cleanQuery, page);
        setSearchResults(response);
      } catch (e) {
        setErrorMessage('Error al obtener los resultados de la búsqueda');
      } finally {
        setLoading(false);
      }
    };
    fetchResults();
  }, [searchQuery, type, page, getAllBySearchQuery]);

  if (!loading) {
    if (!searchQuery) {
      return <p>No ha introducido ningún parámetro de búsqueda</p>;
    }
    if (searchResults.key === 'tramits') {
      return searchResults.tramits.length ? (
        <TransactionsTableList
          data={searchResults.tramits}
          page={page}
          goNext={goNext}
          goPrev={goPrev}
        />
      ) : (
        <p>No se encontraron transacciones con esos términos búsqueda</p>
      );
    }
    if (searchResults.key === 'users') {
      return searchResults.users.length ? (
        <UsersTableList
          data={searchResults.users}
          page={page}
          goNext={goNext}
          goPrev={goPrev}
        />
      ) : (
        <p>No se encontraron usuarios con esos términos de búsqueda</p>
      );
    }
    if (searchResults.key === 'cars') {
      return searchResults.cars.length ? (
        <CarsTableList
          data={searchResults.cars}
          page={page}
          goNext={goNext}
          goPrev={goPrev}
        />
      ) : (
        <p>No se encontraron vehículos con esos términos de búsqueda</p>
      );
    }

    if (searchResults.key === 'companies') {
      return searchResults.companies.length > 0 ? (
        <CompaniesTableList
          data={searchResults.companies}
          page={page}
          goNext={goNext}
          goPrev={goPrev}
        />
      ) : (
        <p>No se encontraron empresas con esos términos de búsqueda</p>
      );
    }

    if (searchResults.key === 'otn') {
      return searchResults.shipment ? (
        <ShipmentTableByOTN shipment={searchResults.shipment} />
      ) : (
        <p>No se encontró ningún OTN con esos términos de búsqueda</p>
      );
    }

    if (searchResults.key === 'accounts') {
      return searchResults.accounts ? (
        <AccountsTableList
          data={searchResults.accounts}
          page={page}
          goNext={goNext}
          goPrev={goPrev}
        />
      ) : (
        <p>No se encontró ninguna cuenta con esos términos de búsqueda</p>
      );
    }
  }

  if (loading) {
    return (
      <div className="w-full h-60 flex items-center justify-center">
        <Spinner color="text-blue-700" size={10} marginTop={28} />
      </div>
    );
  }

  if (errorMessage) {
    return <p>{errorMessage}</p>;
  }

  return null;
}

import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

type ModalityCode = 'QD_LG_LCR';
type ProductCode = 'QD_LG';

export type WeecoverOfferResponse = {
  annualPremium: number;
  modalityCode: ModalityCode;
  productCode: ProductCode;
  duration: number;
  title: string;
  description: string;
  uri: string;
};

export const availableModality: ModalityCode[] = ['QD_LG_LCR'];
export const modalityDict = {
  QD_LG_LCR: 'RACE G. NOMINADA (IP-138)',
};

export type WeecoverFuelTypes = 'D' | 'E' | 'G' | 'P' | 'H' | 'ET' | 'HD';

type WeecoverOfferPayload = {
  engineCapacity: number;
  kilometers: number;
  carRegistration: string;
  carRegistrationDate: string;
  price: number;
};

export type WeecoverIssuncePayload = {
  plate: string;
  productCode: string;
  modalityCode: ModalityCode;
  duration: number;
  holder: {
    name: string;
    email: string;
    document: string;
    phoneNumber: string;
    addressInfo: {
      postalCode: string;
      address: string;
      town: string;
    };
  };
  beneficiary: {
    name: string;
    surname: string;
    document: string;
    email: string;
    phoneNumber: string;
    addressInfo: {
      postalCode: string;
      town: string;
      address: string;
    };
  };
  subject: {
    brand: string;
    model: string;
    puchaseDate: string;
    fuelType: WeecoverFuelTypes;
    chassisNumber: string;
    carRegistration: string;
    carRegistrationDate: string;
    price: number;
    engineCapacity: number;
    kilometers: number;
  };
};

export type MechanicalWarrantyInfoType = {
  id: string;
  numPolicy: string;
  effectiveDate: Date;
  issueDate: Date;
  annualPremium: number;
  plate: string;
  policyInfo: {
    companyPolicyNumber: string;
    productInfo: {
      modalityCode: ModalityCode;
      modalityName: string;
      productCode: ProductCode;
      productName: string;
    };
    renewable: false;
    duration: 1;
    status: string;
    companyStatus: string;
    premium: {
      annualGrossAmount: number;
      annualNetAmount: number;
      taxes: {
        IPS: number;
        RLEA: number;
      };
    };
    persons: {
      personPolicyType: 'HOLDER' | 'BENEFICIARY';
      document: string;
      name: string;
      surname: string;
      email: string;
      phoneNumber: string;
      address: string;
      postalCode: string;
      town: string;
    }[];
    paymentInformation: {
      paymentCollector: string;
      paymentStatus: string;
    };
    expirationDate: Date;
    emissionDate: Date;
    effectiveDate: Date;
    insuredObject: {
      kilometers: number;
      carRegistrationDate: Date;
      engineCapacity: number;
      fuelType: WeecoverFuelTypes;
      carRegistration: string;
      chassisNumber: string;
      price: number;
      brand: string;
      model: string;
    };
  };
};

export const getOffers = async (
  token: string,
  payload: WeecoverOfferPayload,
): Promise<WeecoverOfferResponse[]> =>
  axios
    .post(`${BASE_URL}/mechanical-warranty/offer`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((r) => r.data);

export const postIssuance = async (
  token: string,
  carId: string,
  payload: WeecoverIssuncePayload,
): Promise<void> =>
  axios
    .post(`${BASE_URL}/car/${carId}/mechanical-warranty/issuances`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((r) => r.data);

export const getMechanicalWarranty = async (
  token: string,
  carId: string,
): Promise<MechanicalWarrantyInfoType> =>
  axios
    .get(`${BASE_URL}/car/${carId}/mechanical-warranty`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((r) => r.data);

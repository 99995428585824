import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { DotsVerticalIcon } from '@heroicons/react/solid';

import { Menu, Transition } from '@headlessui/react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { PlusIcon } from '@heroicons/react/outline';
import classNames from '../../helpers/classNames';
import { partsDictionary, statusBadgeDictionary } from './helpers/dictionaries';
import { toasterContext } from '../../context/ToasterContext/ToasterContext';
import { getTooltipMessage } from './helpers/helpers';
import { extraActionsDictionary, getActions } from './helpers/actions';
import DeleteSignatureModal from '../../modals/DeleteSignatureModal/DeleteSignatureModal';
import CreateSignatureModal from '../../modals/CreateSignatureModal/CreateSignatureModal';
import { Transfer, BateTramit } from '../../types/types';

export type Part =
  | 'mandate'
  | 'custom'
  | 'mandate-pro'
  | 'contract'
  | 'misplacement'
  | 'representation'
  | 'mandate-double';

export type Status = 'PENDING' | 'COMPLETED' | 'CANCELLED';

export type Document = {
  id: string;
  serviceId: string;
  parts: Part[];
  signers: {
    id: string;
    entityId: string;
    role: 'buyer' | 'seller' | 'pro' | 'agency';
    signed: boolean;
  }[];
  metadata: {
    transactionCode: string;
  };
  status: Status;
};

function SignatureDetails({
  tramit,
  entityType,
  entityCode,
}: Readonly<{
  tramit: Transfer | BateTramit;
  entityType: 'transaction' | 'bate';
  entityCode: string;
}>) {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentIdForDelete, setDocumentIdForDelete] = useState('');

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { getAccessTokenSilently } = useAuth0();

  const { setToasterData } = useContext(toasterContext);

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const { data: fetchedDocuments } = await axios.get<Document[]>(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/entity/${entityType}/${entityCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setDocuments(fetchedDocuments);
    })();
  }, [updater]);

  return (
    <>
      <DeleteSignatureModal
        documentId={documentIdForDelete}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        setToasterData={setToasterData}
        update={forceUpdate}
      />
      <CreateSignatureModal
        tramit={tramit}
        entityType={entityType}
        entityCode={entityCode}
        documents={documents}
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        update={forceUpdate}
      />
      <div>
        <div className="mt-8 flex flex-col w-full">
          <div className="mb-3 flex justify-end items-center">
            <button
              type="button"
              className="inline-flex gap-2 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              Nuevo documento <PlusIcon className="h-4" />
            </button>
          </div>
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Documento
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Estado
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  {documents.length === 0 ? (
                    <tbody>
                      <tr>
                        <td
                          className="text-center bg-white h-32 text-xl"
                          colSpan={3}
                        >
                          Aún no has generado ningúna firma digital para este
                          trámite.
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {documents.map((d) => (
                        <tr key={d.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {d.parts
                              .map((e) => partsDictionary.get(e))
                              .join(' - ')}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span
                              className={`inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium relative group cursor-default ${
                                statusBadgeDictionary.get(d.status)?.color
                              }`}
                            >
                              {statusBadgeDictionary.get(d.status)?.value}
                              {d.status === 'PENDING' && (
                                <span className="z-10 bg-gray-100 rounded-md py-2 px-2 absolute -left-64 -top-12 translate-x-full text-black opacity-0 group-hover:opacity-100 transition-all duration-200 delay-200 ease-in-out shadow-sm">
                                  {getTooltipMessage(d)}
                                </span>
                              )}
                            </span>
                          </td>

                          <td className="text-end whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <span className="flex items-center justify-end gap-4">
                              {getActions(
                                d.serviceId,
                                d.status,
                                d.id,
                                getAccessTokenSilently,
                                setToasterData,
                              )}
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <div>
                                  <Menu.Button className="flex items-center">
                                    <span className="sr-only">
                                      Open options
                                    </span>
                                    <DotsVerticalIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-8 top-7 -translate-y-full z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      {extraActionsDictionary
                                        .get(d.status)
                                        ?.map((action) => (
                                          <Menu.Item key={action.value}>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                className={classNames(
                                                  active
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700',
                                                  'flex px-4 py-2 text-sm w-full items-center justify-start gap-3',
                                                )}
                                                onClick={() =>
                                                  action.action({
                                                    serviceId: d.serviceId,
                                                    documentId: d.id,
                                                    getToken:
                                                      getAccessTokenSilently,
                                                    setToasterData,
                                                    setDocumentIdForDelete,
                                                    setOpenDeleteModal,
                                                  })
                                                }
                                              >
                                                {action.icon}
                                                {action.value}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ))}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignatureDetails;

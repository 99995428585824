import { BATE_STATUS } from '../shared/bate-status';
import { REGISTRATION_STATUS } from '../shared/registration-status';
import { ServiceTypeI } from '../enums/serviceTypeDict';

// eslint-disable-next-line no-shadow
export enum FILE_TYPE {
  EXTRA_FILES = 'extra_files',
  DOI_FRONT = 'doi_front',
  DOI_BACK = 'doi_back',
  CIRCULATION_PERMIT = 'circulation_permit',
  TECHNICAL_SHEET = 'technical_sheet',
  PRO_INVOICE = 'pro_invoice',
  TIF = 'tif',
  PAID_ITP_FILE = 'paid_itp_file',
  PAID_ITP_PROOF = 'paid_itp_proof',
  PRESENTATION_PROOF = 'presentation_proof',
  TEMPORARY_CIRCULATION_DOC = 'temporary_circulation_doc',
  TEMPORARY_LEAVE_REQUEST = 'temporary_leave_request',
  MANDATE = 'mandate',
}

export type OperationType = 'P2P' | 'P2B' | 'B2B' | 'B2P';

export type Platform = 'SWIPOOPRO' | 'ANCOVE';

export type OrgType = 'DEALER' | 'AGENCY';

export interface BateTramit {
  car: Car;
  bate: Bate;
  buyer: User | Company;
  seller: User | Company;
  intermediary: User | Company;
  labels: Label[];
}

export interface RegistrationTramit {
  buyer: User | Company;
  car: Car;
  registration: Registration;
  labels: Label[];
}

export type RelatedTransaction = {
  brand: string;
  model: string;
  plate: string;
  transactionCode: string;
  createdAt: string;
  status: number;
};

export interface Transfer {
  car: Car;
  transaction: Transaction;
  buyer: User | Company;
  seller: User | Company;
  intermediary: User | Company;
  labels: Label[];
  operationType?: OperationType;
}

export interface Bate {
  id: string;
  sellerId?: string;
  buyerId?: string;
  intermediaryId?: string;
  carId?: string;
  saleContract?: string;
  temporaryLeaveRequest?: string;
  bateCode: string;
  status: BATE_STATUS;
  proInvoice?: string;
  extraFiles?: string[];
  createdAt: string;
  updatedAt: string;
  agentId: string;
}

export interface Registration {
  id: string;
  buyerId: string;
  carId: string;
  registrationCode: string;
  status: REGISTRATION_STATUS;
  extraFiles?: string[];
  accountId: string;
  createdAt: string;
  updatedAt: string;
  agentId: string;
}

export interface Car {
  id: string;
  brand?: string;
  model?: string;
  kms?: string;
  enrollmentDate: string;
  fuel?: string;
  plate?: string;
  nextRevision?: string;
  frameNumber?: string;
  technicalSheet?: string;
  circulationPermit?: string;
  cc?: string;
  cylinders?: string;
  powerKw?: string;
  fiscalCV?: string;
  value?: string;
  createdAt: string;
  updatedAt: string;
  isMoto: boolean;
  serviceType: ServiceTypeI;
}
export interface Transaction {
  id: string;
  sellerId?: string;
  buyerId?: string;
  intermediaryId?: string;
  carId?: string;
  transactionCode: string;
  paid?: boolean;
  isRefunded?: boolean;
  isManuallyCreated?: boolean;
  environmentBadge?: boolean;
  trafficReport?: boolean;
  itpPrice?: number;
  agencyId?: string;
  holdedInvoiceId?: string;
  saleContract?: string;
  temporaryCirculationDoc?: string;
  status?: number;
  host?: string;
  isB2B?: boolean;
  presentationProof?: string;
  proInvoice?: string;
  extraFiles?: string[];
  createdAt: string;
  updatedAt: string;
  cetCode?: string;
  paidItpProof?: string;
  paidItpFile?: string;
  isBatecom: boolean;
  accountId: string;
}
export interface User {
  id: string;
  holdedId?: string;
  name?: string;
  surname?: string;
  city?: string;
  address?: string;
  province?: string;
  ccaa?: string;
  zipCode?: string;
  phone?: string;
  email?: string;
  dni?: string;
  photoDni?: string[];
  stripeId?: string;
  birthDate?: string;
  sex?: string;
  census?: string;
  mandate?: string;
  createdAt: string;
  updatedAt: string;
  dniExpiryDate?: string;
}
export interface Company {
  id: string;
  holdedId?: string;
  nif: string;
  legalName?: string;
  fiscalAddressZipCode?: string;
  fiscalAddressState?: string;
  fiscalAddressProvince?: string;
  fiscalAddressCity?: string;
  fiscalAddressAddress?: string;
  nifFile?: string;
  representativeId: string;
  stripeId?: string;
  createdAt: string;
  updatedAt: string;
  representative: User;
  agentId: string;
}

export interface TransactionDTO {
  id?: string;
  sellerId?: string;
  buyerId?: string;
  carId?: string;
  intermediaryId?: string;
  saleContract?: string;
  temporaryCirculationDoc?: string;
  presentationProof?: string;
  extraFiles?: string[];
}

export interface UserDTO {
  id?: string;
  name?: string;
  surname?: string;
  city?: string;
  address?: string;
  dni?: string;
  photoDni?: string[];
  birthDate?: string;
  sex?: string;
  census?: string;
}
export interface CompanyDTO {
  id?: string;
  name?: string;
  nif: string;
  nifFile?: string;
  representative: UserDTO;
  representativeId?: string;
}

export interface CarDTO {
  id?: string;
  brand?: string;
  model?: string;
  kms?: string;
  plate?: string;
  frameNumber?: string;
  technicalSheet?: string;
  circulationPermit?: string;
}

export interface TransferDTO {
  buyer?: UserDTO | CompanyDTO;
  seller?: UserDTO | CompanyDTO;
  companyRepresentative?: UserDTO;
  transaction: TransactionDTO;
  car: CarDTO;
}
export interface Label {
  id: string;
  value: string;
  name: string;
  color: string;
  createdAt: string;
  updatedAt: string;
  immutable: boolean;
  transactionId: string;
  labelId: string;
}

export interface ItpData {
  deprecatedValue: number;
  deprecationCoeficient: number;
  itp: number;
  itpCoeficient: number;
  value: number;
}

// eslint-disable-next-line no-shadow
export enum TYPEFORM_LOADING_STATE {
  NOT_STARTED,
  LOADING,
  LOADED,
  ERROR,
}

// eslint-disable-next-line no-shadow
export enum ASYNC_STATE {
  NOT_STARTED,
  LOADING,
  LOADED,
  ERROR,
}

export interface Comment {
  id: string;
  comment: string;
  createdAt: string;
  createdBy: string;
  isPublic: boolean;
  fromIncident: boolean;
  incidentName: string | null;
  incidentResolvedAt: string | null;
}

export interface DeliveryAddress {
  id?: string;
  referenceName?: string;
  companyId?: string;
  createdAt?: string;
  deliveryAddress?: string;
  deliveryCCAA?: string;
  deliveryCity?: string;
  deliveryDOI?: string;
  deliveryEmail?: string;
  deliveryNameSurname?: string;
  deliveryPhone?: string;
  deliveryZipcode?: string;
  updatedAt?: string;
}

export type AsyncState<T> = {
  isLoading: boolean;
  error: string;
  data: T;
};

export type Account = {
  id: string;
  organizationId: string;
  pricePlanId: string;
  orgType: OrgType;
  holdedId: string;
  stripeId: string;
  name: string;
  billingName: string;
  billingNif: string;
  billingEmail: string;
  billingAddress: string;
  platform: Platform;
  agentId: string;
  billingZipCode: string;
  billingCcaa: string;
  billingProvince: string;
  billingCity: string;
  companyId: string;
  mechanicalWarrantyProviders: string[];
};

import axios from 'axios';
import { Buffer } from 'buffer';
import { useEffect, useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import usePagination from '../../../../hooks/usePagination';
import { parseDayMonthYearWithSlash } from '../../../../helpers';
import PaginationFooter from '../../../../components/PaginationFooter';
import Spinner from '../../../../components/Spinner';
import { accountDetailsContext } from '../../../../context/account-details.context';

const LIMIT = 10;

export default function Invoicing() {
  const [invoices, setInvoices] = useState([]);
  const [totalInvoices, setTotalInvoice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const [page, goNext, goPrev] = usePagination();
  const { account } = useContext(accountDetailsContext);

  useEffect(() => {
    (async () => {
      if (!account.holdedId) return;

      const token = await getAccessTokenSilently();

      const { data: fetchedInvoices } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/list-holded-invoices?holdedId=${
          account.holdedId
        }&sort=created-desc&startDate=1672527600&endDate=${Math.floor(
          Date.now() / 1000,
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const mappedInvoices = fetchedInvoices
        .map((invoice) => ({
          docNumber: invoice.docNumber,
          plate:
            invoice.customFields.find((f) => f.field === 'Matrícula')?.value ||
            '',
          referenceCode:
            invoice.customFields.find((f) =>
              [
                'Código de referencia',
                'Código de referencia transferencia',
                'Ref. transferencia',
                'Ref. com',
                'Ref. bate',
                'Código de referencia baja',
              ].includes(f.field),
            )?.value || '---',
          viewCallback: async () => {
            const { data: base64Blob } = await axios({
              url: `${process.env.REACT_APP_BASE_API_URL}/holded-document-pdf?documentType=invoice&documentId=${invoice.id}`,
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/pdf',
              },
            });

            const buffer = Buffer.from(base64Blob, 'base64');

            const pdf = new Blob([buffer], {
              type: 'application/pdf',
            });

            const url = URL.createObjectURL(pdf);

            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.click();
          },
          createdAt: new Date(invoice.date * 1000 + 2 * 60 * 60 * 1000),
        }))
        .filter(
          (_, index) =>
            index >= LIMIT * page && index <= LIMIT * page + LIMIT - 1,
        );
      setInvoices(mappedInvoices);
      setTotalInvoice(fetchedInvoices.length);
      setIsLoading(false);
    })();
  }, [account.holdedId, page]);

  if (isLoading)
    return (
      <div className="flex justify-center">
        <Spinner color="text-blue-700" size={10} />
      </div>
    );

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Matrícula
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Código de referencia
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Nº de factura
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Fecha
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Ver</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice, i) => (
                  <tr
                    key={invoice.docNumber}
                    className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900d">
                      {invoice.plate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {invoice.referenceCode}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {invoice.docNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {parseDayMonthYearWithSlash(invoice.createdAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        type="button"
                        onClick={invoice.viewCallback}
                        className="cursor-pointer hover:text-blue-600"
                      >
                        Ver
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationFooter
              data={invoices}
              page={page}
              goNext={goNext}
              goPrev={goPrev}
              total={totalInvoices}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment, useState } from 'react';
import { classNames } from '../../../../helpers';
import usePagination from '../../../../hooks/usePagination';
import { useServices } from '../../../../services';
import TextFilterSearch from './TextFilterSearch';
import StatusChangeFilterModal from './StatusChangeFilterModal';

const getBateComLabel = (entityType) =>
  entityType === 'TRANSACTION' ? 'COM' : 'BATE';

export default function FilterOptions({
  isPro,
  isMyTransfers,
  isBatecoms,
  statusItems,
  incidentTypeItems,
  ccaaItems,
  specialFilters,
  handleFilter,
  searchParams,
  companyMembers,
  setCompanyMembers,
  setCompanies,
  companies,
  handleLabelsFilter,
  loadLabels,
  labelOptions,
  agents,
  setAgents,
  minDateStatusChange,
  setMinDateStatusChange,
  maxDateStatusChange,
  setMaxDateStatusChange,
  initialState,
  setInitialState,
  finalState,
  setFinalState,
  hosts,
  setHosts,
  batecomFilterItems,
  accounts,
  setAccounts,
}) {
  const [page] = usePagination();
  const [searchValue, setSearchValue] = useState({
    assignedTo: '',
    company: '',
    agentAssigned: '',
    host: '',
    account: '',
  });
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const {
    getSwipooMembers,
    getAllCompanies,
    getAllAgents,
    getAllHosts,
    getAllAccounts,
  } = useServices();

  const loadMembers = async () => {
    if (companyMembers.length === 0) {
      setIsDataLoading(true);
      const result = await getSwipooMembers();
      setCompanyMembers(result);
      setIsDataLoading(false);
    }
  };

  const loadAccounts = async () => {
    if (accounts.length === 0) {
      setIsDataLoading(true);
      const result = await getAllAccounts();
      setAccounts(result.accounts);
      setIsDataLoading(false);
    }
  };

  const loadCompanies = async () => {
    if (companies.length === 0) {
      setIsDataLoading(true);
      const result = await getAllCompanies();
      setCompanies(result);
      setIsDataLoading(false);
    }
  };

  const loadHosts = async () => {
    if (hosts.length === 0) {
      setIsDataLoading(true);
      const result = await getAllHosts();
      setHosts(result);
      setIsDataLoading(false);
    }
  };

  const loadAgents = async () => {
    if (agents.length === 0) {
      setIsDataLoading(true);
      const controller = new AbortController();
      const { signal } = controller;
      const response = await getAllAgents({
        page,
        signal,
      });
      setAgents(response);
      setIsDataLoading(false);
    }
  };

  const handleChange = (e) => {
    setSearchValue({ ...searchValue, [e.target.name]: e.target.value });
  };

  const toggleStatusChangeFilterModal = () =>
    setIsStatusChangeModalOpen((prev) => !prev);
  return (
    <>
      <StatusChangeFilterModal
        showModal={isStatusChangeModalOpen}
        setShowModal={setIsStatusChangeModalOpen}
        minDateStatusChange={minDateStatusChange}
        setMinDateStatusChange={setMinDateStatusChange}
        maxDateStatusChange={maxDateStatusChange}
        setMaxDateStatusChange={setMaxDateStatusChange}
        initialState={initialState}
        setInitialState={setInitialState}
        finalState={finalState}
        setFinalState={setFinalState}
      />
      <span className="flex justify-end z-0 mb-4 rounded-md">
        <div className="flex flex-col gap-y-2">
          <div className="flex">
            <Menu as="span" className="-ml-px relative block">
              <Menu.Button
                type="button"
                className="flex items-center justify-center mr-4 pr-3 text-gray-600 gap-x-2 border-r-[1px]"
              >
                Estados{' '}
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {statusItems.map(({ value, key, color, name }) => (
                    <Menu.Item key={name}>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => handleFilter(key, value)}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left',
                            searchParams.get('status') &&
                              value === Number(searchParams.get('status')) &&
                              'bg-blue-100',
                          )}
                        >
                          <span
                            className={`${color} mr-2 shrink-0 inline-block h-2 w-2 rounded-full`}
                            aria-hidden="true"
                          />
                          {name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>

            <Menu as="span" className="-ml-px relative block">
              <Menu.Button
                type="button"
                className="flex items-center justify-center mr-4 pr-3 text-gray-600 gap-x-2 border-r-[1px]"
              >
                Incidencias{' '}
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {incidentTypeItems.map(
                    ({ value, key, color, name, entityType }, index) => (
                      <Menu.Item key={name}>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => handleFilter(key, value)}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              index === 0 &&
                                searchParams.get('hasIncident') &&
                                'bg-blue-100',
                              index !== 0 &&
                                searchParams.get('incidentType') &&
                                value === searchParams.get('incidentType') &&
                                'bg-blue-100',
                              'block px-4 py-2 text-sm w-full text-left',
                            )}
                          >
                            {color && (
                              <span
                                className={`${color} mr-2 shrink-0 inline-block h-2 w-2 rounded-full`}
                                aria-hidden="true"
                              />
                            )}
                            {name}
                            {isBatecoms && entityType
                              ? ` (${getBateComLabel(entityType)})`
                              : ''}
                          </button>
                        )}
                      </Menu.Item>
                    ),
                  )}
                </Menu.Items>
              </Transition>
            </Menu>

            {!isBatecoms && (
              <Menu as="span" className="-ml-px relative block">
                <Menu.Button
                  type="button"
                  className="flex items-center justify-center mr-4 pr-3 text-gray-600 gap-x-2 border-r-[1px]"
                >
                  Especiales{' '}
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {specialFilters.map(({ value, key, name }) => (
                      <Menu.Item key={name}>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => handleFilter(key, value)}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm w-full text-left',
                              searchParams.get(key) && 'bg-blue-100',
                            )}
                          >
                            <span
                              className="shrink-0 inline-block h-2 w-2 rounded-full"
                              aria-hidden="true"
                            />
                            {name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
            <Menu
              as="span"
              className="-ml-px relative block"
              onFocus={loadLabels}
            >
              <Menu.Button
                type="button"
                className="flex items-center justify-center mr-4 pr-3 text-gray-600 gap-x-2 border-r-[1px]"
              >
                Etiquetas{' '}
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {labelOptions.map(({ id, name, color, entityType }) => (
                    <Menu.Item key={id}>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => handleLabelsFilter(id)}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left',
                            searchParams.getAll('labels').includes(id) &&
                              'bg-blue-100',
                          )}
                        >
                          <span
                            className="shrink-0 inline-block h-2 w-2 rounded-full mr-2"
                            aria-hidden="true"
                            style={{ backgroundColor: color }}
                          />
                          {name}
                          {isBatecoms && entityType
                            ? ` (${getBateComLabel(entityType)})`
                            : ''}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="flex">
            {!isBatecoms && (
              <Menu as="span" className="-ml-px relative block">
                <Menu.Button
                  type="button"
                  className="flex items-center justify-center mr-4 pr-3 text-gray-600 gap-x-2 border-r-[1px]"
                >
                  CCAA{' '}
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {ccaaItems.map(({ value, key, name }) => (
                      <Menu.Item key={name}>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => handleFilter(key, value)}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'px-4 py-2 text-sm w-full text-left flex items-center',
                              searchParams.get(key) === value && 'bg-blue-100',
                            )}
                          >
                            <span
                              className="mr-2 shrink-0 inline-block h-2 w-2 rounded-full"
                              aria-hidden="true"
                            />
                            {name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
            {isBatecoms && (
              <Menu as="span" className="-ml-px relative block">
                <Menu.Button
                  type="button"
                  className="flex items-center justify-center mr-4 pr-3 text-gray-600 gap-x-2 border-r-[1px]"
                >
                  Tipo{' '}
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {batecomFilterItems.map(({ value, key, name }) => (
                      <Menu.Item key={name}>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => handleFilter(key, value)}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'px-4 py-2 text-sm w-full text-left flex items-center',
                              searchParams.get(key) === value && 'bg-blue-100',
                            )}
                          >
                            <span
                              className="mr-2 shrink-0 inline-block h-2 w-2 rounded-full"
                              aria-hidden="true"
                            />
                            {name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
            {!isMyTransfers && !isBatecoms && (
              <TextFilterSearch
                data={companyMembers
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .filter(
                    ({ email, name }) =>
                      email.includes(searchValue.assignedTo) ||
                      name.includes(searchValue.assignedTo),
                  )
                  .map(({ email, name }) => ({ key: email, name }))}
                title="Asignado a"
                filterName="assignedTo"
                stateName="assignedTo"
                searchValue={searchValue.assignedTo}
                handleChange={handleChange}
                searchParams={searchParams}
                handleFilter={handleFilter}
                callback={loadMembers}
                loadData={loadMembers}
                isLoading={isDataLoading}
              />
            )}
            {!isMyTransfers && !isBatecoms && (
              <TextFilterSearch
                data={agents
                  .filter(({ id }) => id.includes(searchValue.agentAssigned))
                  .map(({ id, name, surname }) => ({
                    key: id,
                    name: `${name} ${surname}`,
                  }))}
                title="Gestor"
                filterName="agentId"
                stateName="agentAssigned"
                searchValue={searchValue.agentAssigned}
                handleChange={handleChange}
                searchParams={searchParams}
                handleFilter={handleFilter}
                callback={loadAgents}
                loadData={loadAgents}
                isLoading={isDataLoading}
              />
            )}
            <TextFilterSearch
              data={companies
                .filter((e) => e.name)
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter(({ name }) =>
                  name
                    .toLowerCase()
                    .includes(searchValue.company.toLowerCase()),
                )
                .filter((e, i) => i < 10)
                .map(({ id, name }) => ({ key: id, name }))}
              title="Empresas"
              filterName="companyId"
              stateName="company"
              searchValue={searchValue.company}
              handleChange={handleChange}
              searchParams={searchParams}
              handleFilter={handleFilter}
              callback={loadCompanies}
              loadData={loadCompanies}
              isLoading={isDataLoading}
            />
            {(isPro || isMyTransfers || isBatecoms) && (
              <TextFilterSearch
                data={accounts
                  .filter((account) => account.name)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .filter(({ name }) =>
                    name
                      .toLowerCase()
                      .includes(searchValue.account.toLowerCase()),
                  )
                  .filter((e, i) => i < 10)
                  .map(({ id, name }) => ({ key: id, name }))}
                title="Cuentas"
                filterName="accountId"
                stateName="account"
                searchValue={searchValue.account}
                handleChange={handleChange}
                searchParams={searchParams}
                handleFilter={handleFilter}
                callback={loadAccounts}
                loadData={loadAccounts}
                isLoading={isDataLoading}
              />
            )}
            {!isBatecoms && (
              <TextFilterSearch
                data={hosts
                  .sort((a, b) => a.localeCompare(b))
                  .filter((host) =>
                    host.toLowerCase().includes(searchValue.host.toLowerCase()),
                  )
                  .filter((_, i) => i < 10)
                  .map((host) => ({ key: host, name: host }))}
                title="Canal"
                filterName="host"
                stateName="host"
                searchValue={searchValue.host}
                handleChange={handleChange}
                searchParams={searchParams}
                handleFilter={handleFilter}
                callback={loadHosts}
                loadData={loadHosts}
                isLoading={isDataLoading}
              />
            )}
          </div>
        </div>
        {!isBatecoms && (
          <div>
            <button
              type="button"
              onClick={toggleStatusChangeFilterModal}
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-gray-600 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cambio de estado
            </button>
          </div>
        )}
      </span>
    </>
  );
}

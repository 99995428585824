import { useEffect, useState } from 'react';
import { ClipboardCheckIcon } from '@heroicons/react/outline';

import BaseModal from './BaseModal';
import { useServices } from '../services';
import joinSurnames from '../helpers/joinSurnames';

const titleDictionary = {
  dniFront: 'DNI (Frontal)',
  dniBack: 'DNI (Reverso)',
  temporaryLeaveRequest: 'Solicitud de baja',
  proInvoice: 'Factura de profesional',
  saleContract: 'Contrato de compraventa',
  paidItpFile: 'Modelo 620',
  nifFile: 'TIF',
  circulationPermit: 'Permiso de circulación',
  technicalSheet: 'Ficha técnica',
  census: 'Padrón',
  mandate: 'Mandato',
};

const ocrDictionary = {
  dniFront: 'doi',
  dniBack: 'doi',
  nifFile: 'tif',
  circulationPermit: 'circulation_permit',
};

const filesToPerformOCR = [
  'nifFile',
  'circulationPermit',
  'dniFront',
  'dniBack',
];

const BUCKET_NAME = 'swipoo-transactions-files';

export default function UploadFileModal({
  entity,
  modalType,
  setModalType,
  entityKey,
  editEntity,
  forceUpdate,
}) {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { uploadFileToS3, getS3SignedUrl, skodaOcr } = useServices();

  const uploadFile = async () => {
    try {
      setIsLoading(true);
      const { fileKey } = await uploadFileToS3(file, BUCKET_NAME);

      let payload = {
        [modalType]: fileKey,
      };

      const { signedUrl } = await getS3SignedUrl(BUCKET_NAME, fileKey);

      if (filesToPerformOCR.includes(modalType)) {
        const ocrData = await skodaOcr(ocrDictionary[modalType], signedUrl);

        switch (modalType) {
          case 'nifFile': {
            const {
              nif,
              legalName,
              fiscalAddressZipCode,
              fiscalAddressState,
              fiscalAddressProvince,
              fiscalAddressCity,
              fiscalAddressAddress,
            } = ocrData;
            payload = {
              nif,
              legalName,
              fiscalAddressZipCode,
              fiscalAddressState,
              fiscalAddressProvince,
              fiscalAddressCity,
              fiscalAddressAddress,
              ...payload,
            };
            break;
          }
          case 'circulationPermit': {
            const {
              plate,
              enrollmentDate,
              brand,
              model,
              frameNumber,
              powerKw,
              cc,
              serviceType,
              fuel,
              kms,
            } = ocrData;
            payload = {
              plate,
              enrollmentDate,
              brand,
              model,
              frameNumber,
              powerKw,
              cc,
              serviceType,
              fuel,
              kms,
              ...payload,
            };
            break;
          }
          case 'dniBack': {
            const { city, address, ccaa, province, zipCode } = ocrData;
            payload = {
              city,
              address,
              ccaa,
              province,
              zipCode,
              photoDni: [entity?.photoDni?.[0], fileKey],
            };

            break;
          }
          case 'dniFront': {
            const {
              dni,
              name,
              firstSurname,
              secondSurname,
              birthDate,
              sex,
              expiryDate,
            } = ocrData;
            payload = {
              dni,
              name,
              surname: joinSurnames(firstSurname, secondSurname),
              birthDate,
              sex,
              dniExpiryDate: expiryDate,
              photoDni: [fileKey, entity?.photoDni?.[1]],
            };
            break;
          }
          default:
            break;
        }
      }

      await editEntity(entityKey, payload);
    } catch (e) {
      setErrorMessage('Error al subir el archivo');
    } finally {
      forceUpdate();
      setModalType('');
      setIsLoading(false);
    }
  };

  const onChangeFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (modalType) return;
    setFile(null);
    setErrorMessage('');
  }, [modalType]);

  return (
    <BaseModal
      isOpen={Boolean(modalType)}
      onClose={() => {
        setModalType('');
      }}
      overflowVisible={false}
    >
      <div className="p-6">
        <div className="mb-2">
          <p className="text-lg font-medium">{titleDictionary[modalType]}</p>
        </div>
        <div
          className={`px-6 pt-5 pb-6 border-2 border-gray-300 ${
            file ? 'border-solid' : 'border-dashed'
          } rounded-md`}
        >
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <div className="space-y-1 text-center justify-center">
            {file ? (
              <ClipboardCheckIcon
                className="h-10 w-10 text-gray-400 m-auto"
                aria-hidden="true"
              />
            ) : (
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            <div className="text-sm text-center text-gray-600">
              {file ? (
                <span>Archivo cargado</span>
              ) : (
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500"
                >
                  <span>Subir archivo</span>
                  <input
                    id="file-upload"
                    type="file"
                    className="sr-only"
                    name={modalType}
                    onChange={onChangeFile}
                    accept="image/*, application/pdf"
                  />
                </label>
              )}
            </div>
            {!file && (
              <p className="text-xs text-gray-500">
                PNG, JPG, PDF, TIF, HEIC, WEBP hasta 15 MB
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className={
            file && !isLoading
              ? 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm'
              : 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-300 text-base font-medium text-white hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm'
          }
          onClick={uploadFile}
          disabled={!file || isLoading}
        >
          {isLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
          {isLoading ? 'Guardando...' : 'Guardar'}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => {
            setModalType('');
          }}
        >
          Cancelar
        </button>
      </div>
    </BaseModal>
  );
}

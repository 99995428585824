import { AiFillCheckCircle } from 'react-icons/ai';
import { useEffect, useMemo, useState } from 'react';
import BaseModal from '../../../../../components/BaseModal';
import {
  ASYNC_STATE,
  STRIPE_CARD_DECLINE_CODES,
} from '../../../../../helpers/enums';
import { useServices } from '../../../../../services';

export default function NewManualPaymentModal({
  isOpen,
  setIsOpen,
  accountId,
  paymentMethodId,
}) {
  const [formState, setFormState] = useState({
    paymentAmount: '0',
    paymentDescription: '',
  });
  const [stripeError, setStripeError] = useState(null);
  const [createPaymentStatus, setCreatePaymentStatus] = useState(
    ASYNC_STATE.INITIAL,
  );

  const { generateManualPayment } = useServices();

  const handleCreatePayment = async () => {
    try {
      setCreatePaymentStatus(ASYNC_STATE.LOADING);
      setStripeError(null);
      await generateManualPayment(
        accountId,
        Number(formState.paymentAmount),
        formState.paymentDescription,
        paymentMethodId,
      );
      setCreatePaymentStatus(ASYNC_STATE.SUCCESS);
    } catch (e) {
      const possibleStripeError =
        STRIPE_CARD_DECLINE_CODES[e.response?.data?.error];
      if (possibleStripeError) {
        setStripeError(possibleStripeError);
      }
      setCreatePaymentStatus(ASYNC_STATE.ERROR);
    }
  };

  const handleChange = (e) =>
    setFormState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  const isFormComplete =
    Number(formState.paymentAmount) > 0 &&
    formState.paymentDescription.length > 0 &&
    formState.paymentDescription.length < 22;

  const resetForm = () => {
    setFormState({
      paymentAmount: '0',
      paymentDescription: '',
    });
    setCreatePaymentStatus(ASYNC_STATE.INITIAL);
  };

  useEffect(() => {
    if (isOpen) return;
    resetForm();
  }, [isOpen]);

  const descriptionIsTooLong = useMemo(
    () => formState.paymentDescription.length > 22,
    [formState.paymentDescription],
  );

  return (
    <BaseModal isOpen={isOpen} onClose={setIsOpen} overflowVisible>
      <div className="p-8 overflow-y-visible">
        <h1 className="text-2xl font-semibold text-gray-900">
          Generar nuevo pago manual
        </h1>
        {createPaymentStatus === ASYNC_STATE.ERROR &&
          (stripeError ? (
            <div className="flex flex-col text-red-500 my-3">
              <p className="font-bold">{stripeError.title}</p>
              <p>{stripeError.description}</p>
              <p>{stripeError.nextSteps}</p>
            </div>
          ) : (
            <p className="text-sm text-red-500 my-3">
              Ocurrió un error generando el pago manual
            </p>
          ))}
        {descriptionIsTooLong && (
          <p className="text-sm text-red-500 my-3">
            La descripción del extracto bancario solo puede tener hasta 22
            carácteres.
          </p>
        )}
        <div className="mt-6 flex flex-col gap-2 mb-2">
          <div className="flex flex-col">
            <label
              htmlFor="paymentAmount"
              className="text-sm font-medium leading-6 text-gray-900"
            >
              Cantidad
            </label>
            <input
              name="paymentAmount"
              id="paymentAmount"
              type="number"
              className="pl-2 border-gray-300 rounded-md text-grey-800 focus:ring-grey-600 p-0 py-1"
              onChange={handleChange}
              value={formState.paymentAmount}
              step="0.01"
              min="0"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="paymentDescription"
              className="text-sm font-medium leading-6 text-gray-900"
            >
              Descripción
            </label>
            <input
              name="paymentDescription"
              id="paymentDescription"
              type="text"
              className="pl-2 border-gray-300 rounded-md text-grey-800 focus:ring-grey-600 p-0 py-1"
              onChange={handleChange}
              value={formState.paymentDescription}
            />
          </div>
        </div>
        <div className="flex justify-between items-center mt-4 self-end">
          <button
            type="button"
            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 disabled:opacity-50 ${
              isFormComplete && createPaymentStatus !== ASYNC_STATE.LOADING
                ? 'bg-blue-600 hover:bg-blue-500'
                : 'bg-gray-600 hover:bg-gray-500'
            }`}
            onClick={handleCreatePayment}
            disabled={
              !isFormComplete || createPaymentStatus === ASYNC_STATE.LOADING
            }
          >
            Generar pago
          </button>

          {createPaymentStatus === ASYNC_STATE.SUCCESS && (
            <div className="rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AiFillCheckCircle
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-green-800">
                    Pago manual generado
                  </h3>
                  <div className="mt-2 text-sm text-green-700">
                    <p>Se generó el pago correctamente</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  );
}

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useServices } from '../../../../services';
import { bateStatus } from '../../../../helpers/enums';

export default function RelatedTramit({ transactionId }) {
  const [relatedTramit, setRelatedTramit] = useState(null);
  const [incidents, setIncidents] = useState([]);

  const { getAccessTokenSilently } = useAuth0();

  const { fetchAllIncidentsByEntityId } = useServices();

  useEffect(() => {
    if (!transactionId) return;
    const getBateByTransactionId = async () => {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/v1/transaction/${transactionId}/bate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setRelatedTramit(data);
      const incidentList = await fetchAllIncidentsByEntityId(data.bateId);
      setIncidents(incidentList.filter((i) => !i.resolvedAt));
    };

    getBateByTransactionId();
  }, [transactionId]);

  if (!relatedTramit) return null;

  const transStatus = bateStatus.find(
    (status) => status.id === relatedTramit.status,
  );

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div className="flex justify-between gap-x-4">
        <Link to={`/bates/${relatedTramit.bateCode}`}>
          <dt className="text-sm inline-flex font-medium text-gray-500 hover:text-blue-600 cursor-pointer">
            Trámite relacionado:{' '}
            <p className="ml-1 inline-flex text-sm text-gray-900 gap-x-1">
              {relatedTramit.bateCode} - (BATE)
            </p>
          </dt>
        </Link>
        <dd className="ml-1 flex text-sm text-gray-900 gap-x-1">
          <p
            className={`px-2 inline-flex text-xs leading-6 font-semibold rounded-full ${transStatus.bgColor} ${transStatus.textColor}`}
          >
            {transStatus.label}
          </p>
          {incidents.map((i) => (
            <p
              key={i.id}
              className="px-2 inline-flex text-xs leading-6 font-semibold rounded-full bg-red-100 text-red-800 ml-1"
            >
              {i.name}
            </p>
          ))}
        </dd>
      </div>
    </div>
  );
}

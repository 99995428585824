import { useState } from 'react';
import BaseModal from '../../../../components/BaseModal';
import classNames from '../../../../helpers/classNames';
import FileUploadInput from './FileUploadInput';
import { useServices } from '../../../../services';
import Spinner from '../../../../components/Spinner';
import Combobox from '../../../../components/Combobox';
import useDgtFees from '../../../../hooks/useDgtFees';
import useAccounts from '../../../../hooks/useAccounts';
import ComboboxDynamicValues from '../../../../components/ComboboxDynamicValues';

export default function UploadTrafficReportModal({
  isOpen,
  setIsOpen,
  isPro,
  forceUpdate,
}) {
  const [vehicleIdentifierType, setVehicleIdentifierType] = useState('plate');
  const [file, setFile] = useState(null);
  const [identifier, setIdentifier] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { uploadTrafficReport } = useServices();

  const {
    dgtFeeTypes,
    dgtFees,
    selectedFeeType,
    setSelectedFeeType,
    selectedDgtFee,
    setSelectedDgtFee,
  } = useDgtFees();

  const { selectedAccount, setSelectedAccount, accounts } = useAccounts(isPro);

  const isDisabled = !file || !identifier?.length || !selectedDgtFee;

  const close = () => {
    setIsOpen(false);
    setFile(null);
    setErrorMessage('');
  };

  const handleUpload = async (e) => {
    try {
      e.preventDefault();

      setIsLoading(true);

      const dgtFeeId = dgtFees.data.find(
        (dgtFee) => dgtFee.feeNumber === selectedDgtFee,
      )?.id;

      if (!dgtFeeId) {
        throw new Error('Fee not found');
      }

      await uploadTrafficReport(
        identifier,
        vehicleIdentifierType,
        dgtFeeId,
        file,
        isPro && selectedAccount.id,
      );

      forceUpdate();
      close();
    } catch (error) {
      if (error.message === 'Fee not found') {
        setErrorMessage('La tasa no existe');
      } else {
        setErrorMessage('Ha ocurrido un error al subir el informe');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const vehicleIdentifierTypes = [
    { key: 'plate', value: 'plate', label: 'Matrícula' },
    { key: 'frameNumber', value: 'frameNumber', label: 'VIN' },
  ];

  const handleChangeIdentifierType = (e) =>
    setVehicleIdentifierType(e.target.value);
  const handleChangeIdentifier = (e) => setIdentifier(e.target.value);
  const handleChangeFeeType = (e) => setSelectedFeeType(e.target.value);

  return (
    <BaseModal isOpen={isOpen} onClose={close}>
      <form onSubmit={handleUpload}>
        <div className="p-6 flex flex-col gap-y-2">
          <div className="mb-2">
            <p className="text-lg font-medium">Subir Informe de Tráfico</p>
            {errorMessage && (
              <p className="text-red-500 text-sm">{errorMessage}</p>
            )}
          </div>
          {isPro && (
            <div className="flex flex-col gap-2 mt-5">
              <label htmlFor="identifier">Cuenta</label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <ComboboxDynamicValues
                  data={accounts.data}
                  labelProperty="name"
                  valueProperty="id"
                  selectedItem={selectedAccount}
                  setSelectedItem={setSelectedAccount}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col gap-2">
            <label htmlFor="identifier">Identificador de vehículo</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <select
                id="vehicle-identifier-type"
                name="vehicle-identifier-type"
                className="w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
                onChange={handleChangeIdentifierType}
                value={vehicleIdentifierType}
                defaultValue="plate"
              >
                {vehicleIdentifierTypes.map((option) => (
                  <option key={option.key || option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="identifier">
              {
                vehicleIdentifierTypes.find(
                  (type) => type.value === vehicleIdentifierType,
                )?.label
              }
            </label>
            <input
              type="text"
              id="identifier"
              value={identifier}
              onChange={handleChangeIdentifier}
              className="border border-gray-300 rounded-md px-2 py-1"
            />
          </div>
          <div>
            <label htmlFor="fee">Tipo de Tasa</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <select
                id="dgt-fee-type"
                name="dgt-fee-type"
                className="w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
                onChange={handleChangeFeeType}
                value={selectedFeeType}
              >
                {dgtFeeTypes?.data
                  ?.map((feeType) => ({
                    value: feeType.type,
                    label: feeType.type,
                  }))
                  ?.map((option) => (
                    <option
                      key={option.key || option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {selectedFeeType && (
            <div className="flex flex-col">
              <label htmlFor="fee">Tasa utilizada</label>
              {dgtFees?.isLoading && (
                <Spinner size={6} color="text-indigo-700" />
              )}
              {!dgtFees?.isLoading && !dgtFees?.data?.length && (
                <span className="text-gray-600">
                  No existen tasas disponibles de tipo {selectedFeeType}
                </span>
              )}
              {!dgtFees?.isLoading && Boolean(dgtFees?.data?.length) && (
                <Combobox
                  data={dgtFees?.data?.map((dgtFee) => dgtFee?.feeNumber)}
                  selectedItem={selectedDgtFee}
                  setSelectedItem={setSelectedDgtFee}
                />
              )}
            </div>
          )}
          <FileUploadInput file={file} setFile={setFile} />
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="submit"
            className={classNames(
              'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm focus:ring-red-500',
              isDisabled
                ? 'bg-indigo-300 hover:bg-indigo-400'
                : 'bg-indigo-600 hover:bg-indigo-700',
            )}
            disabled={isDisabled}
          >
            {isLoading && (
              <Spinner color="text-indigo-600" size="4" marginTop="0" />
            )}
            {isLoading ? 'Subiendo...' : 'Subir'}
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={close}
          >
            Cancelar
          </button>
        </div>
      </form>
    </BaseModal>
  );
}

/* eslint-disable no-shadow */
export enum SWIPOO_STATUS {
  PENDING_DOCUMENTATION = 0,
  REVIEW_PENDING_DOCUMENTATION = 1,
  DIGITAL_CONTRACT_SENT = 5,
  DIGITAL_CONTRACT_SIGNED = 6,
  PRESENTED_TO_AGENCY = 4,
  PRESENTED_TO_DGT = 10,
  FINISHED_BY_DGT = 11,
  CIRCULATION_PERMIT_AT_OFFICE = 8,
  CIRCULATION_PERMIT_SENT = 3,
  CIRCULATION_PERMIT_DELIVERED = 9,
  CANCELED = 7,
}

export const statusDictionary: { [key: number]: string } = {
  0: 'PENDING_DOCUMENTATION',
  1: 'REVIEW_PENDING_DOCUMENTATION',
  5: 'DIGITAL_CONTRACT_SENT',
  6: 'DIGITAL_CONTRACT_SIGNED',
  4: 'PRESENTED_TO_AGENCY',
  10: 'PRESENTED_TO_DGT',
  11: 'FINISHED_BY_DGT',
  8: 'CIRCULATION_PERMIT_AT_OFFICE',
  3: 'CIRCULATION_PERMIT_SENT',
  9: 'CIRCULATION_PERMIT_DELIVERED',
};

export enum STEP_GROUP_KEY {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  SUBMITTED_TO_AGENCY = 'SUBMITTED_TO_AGENCY',
  SUBMITTED_TO_DGT = 'SUBMITTED_TO_DGT',
  FINISHED_BY_DGT = 'FINISHED_BY_DGT',
}

export const TRANSFER_STATUS: { [key: string]: number[] } = {
  NOT_SUBMITTED: [SWIPOO_STATUS.PENDING_DOCUMENTATION],
  SUBMITTED_TO_AGENCY: [
    SWIPOO_STATUS.REVIEW_PENDING_DOCUMENTATION,
    SWIPOO_STATUS.DIGITAL_CONTRACT_SENT,
    SWIPOO_STATUS.DIGITAL_CONTRACT_SIGNED,
    SWIPOO_STATUS.PRESENTED_TO_AGENCY,
  ],
  SUBMITTED_TO_DGT: [SWIPOO_STATUS.PRESENTED_TO_DGT],
  FINISHED_BY_DGT: [
    SWIPOO_STATUS.FINISHED_BY_DGT,
    SWIPOO_STATUS.CIRCULATION_PERMIT_AT_OFFICE,
    SWIPOO_STATUS.CIRCULATION_PERMIT_SENT,
    SWIPOO_STATUS.CIRCULATION_PERMIT_DELIVERED,
  ],
};

export const SHIPMENT_STATUS = {
  SENT: [SWIPOO_STATUS.CIRCULATION_PERMIT_SENT],
  DELIVERED: [SWIPOO_STATUS.CIRCULATION_PERMIT_DELIVERED],
};

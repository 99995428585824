import { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import BaseModal from '../../../../../components/BaseModal';

export default function GenerateInvoiceModal({
  transaction,
  ccaa,
  plate,
  setShowModal,
  showModal,
  forceUpdate,
}) {
  const [error, setError] = useState('');
  const [loading, setLoader] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const resetModal = () => {
    setError('');
    setLoader(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);

      if (!ccaa) {
        setError('Es necesaria la Comunidad Autónoma del comprador');
        return;
      }

      const token = await getAccessTokenSilently();

      const { paidItpFile, paidItpProof } = transaction;

      const itpPaymentFiles = [
        ...(paidItpFile ? [{ name: 'paidItpFile', url: paidItpFile }] : []),
        ...(paidItpProof ? [{ name: 'paidItpProof', url: paidItpProof }] : []),
      ];

      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/create-holded-purchase/itp-liquidation/${transaction.transactionCode}`,
        {
          plate,
          itpLiquidated: transaction.itpLiquidated,
          ccaa,
          itpPaymentFiles,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      forceUpdate();

      setShowModal(false);
    } catch (err) {
      setError(
        'Ha ocurrido un error generando la factura del liquidación de ITP',
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <BaseModal isOpen={showModal} onClose={resetModal} overflowVisible={false}>
      <div className="space-y-6">
        <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:mt-0 md:col-span-3">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                Nueva Factura de Liquidación del ITP
              </h3>
              <form onSubmit={handleSubmit}>
                <div>
                  <p>
                    Al confirmar, se generará una factura de gasto en holded por
                    el monto liquidado del ITP. Revisar que toda la info y
                    archivos son correctos
                  </p>
                  {error && <p className="text-red-500">{error}</p>}
                </div>
                <div className="flex justify-end mt-6">
                  <button type="button" onClick={() => setShowModal(false)}>
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white
                              bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                            `}
                  >
                    {loading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {loading ? 'Generando Factura...' : 'Generar Factura'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

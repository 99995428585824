import { useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';
import { useAuth0 } from '@auth0/auth0-react';
import { useServices } from '../../services';
import BaseModal from '../BaseModal';
import { classNames } from '../../helpers';

const initialStateNewIncidentType = {
  id: '',
  code: '',
  name: '',
  description: '',
  solution: '',
};

export default function OpenIncidentModal({
  setShowModal,
  showModal,
  entityId,
  entityType,
  forceUpdate,
  emailToNotify,
}) {
  const [incidents, setIncidents] = useState([]);
  const [newIncidentType, setNewIncidentType] = useState(
    initialStateNewIncidentType,
  );
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoader] = useState(false);

  const { openIncidentOnEntity, fetchAllIncidentsByEntityType } = useServices();

  const { user } = useAuth0();

  const handleSubmit = async (ev) => {
    try {
      ev.preventDefault();
      setErrorMessage('');
      setLoader(true);
      await openIncidentOnEntity({
        incidentId: newIncidentType.id,
        entityId,
        comment,
        createdBy: user.email,
      });
      setLoader(false);
      setShowModal(false);
      return forceUpdate();
    } catch (e) {
      if (e.message.includes('409')) {
        return setErrorMessage(
          'No puedes abrir dos incidencias del mismo tipo en una transferencia',
        );
      }
      return setErrorMessage('Ha ocurrido un error');
    } finally {
      setLoader(false);
    }
  };

  const close = () => {
    setShowModal(false);
    setNewIncidentType(initialStateNewIncidentType);
    setComment('');
  };

  const isButtonDisabled =
    newIncidentType.name === '' || loading || !emailToNotify;

  useEffect(() => {
    if (!showModal) return;
    if (!emailToNotify) {
      setErrorMessage(
        'No puedes abrir incidencias en trámites sin correo a notificar',
      );
      return;
    }
    (async () => {
      try {
        setNewIncidentType(initialStateNewIncidentType);
        setLoader(false);
        setErrorMessage('');
        const response = await fetchAllIncidentsByEntityType(entityType);
        setIncidents(response);
      } catch (e) {
        setErrorMessage(
          'Ha ocurrido un error cargando los tipos de incidencia',
        );
      }
    })();
  }, [showModal, emailToNotify]);

  useEffect(() => {
    if (showModal) return;
    setNewIncidentType(initialStateNewIncidentType);
    setComment('');
  }, [showModal]);

  return (
    <BaseModal isOpen={showModal} onClose={close} overflowVisible={false}>
      <div className="space-y-6 relative">
        <BsX
          className="cursor-pointer w-8 h-8 absolute top-0 right-4 text-gray-400"
          onClick={close}
        />
        <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                Nueva Incidencia
              </h3>
              <form onSubmit={handleSubmit}>
                {errorMessage && (
                  <p className="text-red-500 mb-2">{errorMessage}</p>
                )}
                <div className="max-h-[400px] overflow-y-auto">
                  <div className="mb-4">
                    <label
                      htmlFor="dni"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Tipo de incidencia:
                    </label>
                    <select
                      onChange={(e) =>
                        setNewIncidentType(
                          incidents.find(
                            (incident) => incident.id === e.target.value,
                          ),
                        )
                      }
                      name="incidentType"
                      id="incidentType"
                      className="border border-gray-300 rounded-md shadow-sm block w-full sm:text-sm focus:ring-0"
                      selected=""
                      disabled={!emailToNotify}
                      value={newIncidentType.id}
                    >
                      <option value="" disabled>
                        Selecciona un tipo
                      </option>
                      {incidents.map((incident) => (
                        <option key={incident.id} value={incident.id}>
                          {incident.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {newIncidentType.id && (
                    <div className="flex flex-col gap-y-4">
                      <div>
                        <p className="block text-sm font-medium text-gray-700">
                          Descripción:
                        </p>
                        <p>{newIncidentType.description}</p>
                      </div>
                      <div>
                        <p className="block text-sm font-medium text-gray-700">
                          Solución:
                        </p>
                        <p>{newIncidentType.solution}</p>
                      </div>
                      <div>
                        <label
                          htmlFor="incident-comment"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Comentario:
                        </label>
                        <textarea
                          id="incident-comment"
                          className="border border-gray-300 rounded-md shadow-sm block w-full sm:text-sm focus:ring-0"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    disabled={isButtonDisabled}
                    className={classNames(
                      'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white',
                      isButtonDisabled
                        ? 'bg-indigo-400'
                        : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-0',
                    )}
                  >
                    {loading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {loading ? 'Añadiendo...' : 'Añadir incidencia'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

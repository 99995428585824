/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import DomainReservationsTable from './components/DomainReservationsTable/DomainReservationsTable';
import DomainReserveTableSkeletonLoader from './components/DomainReservationsTableSkeletonLoader/DomainReservationsTableSkeletonLoader';
import { TYPEFORM_LOADING_STATE } from '../types/types';
import useDateParams from '../hooks/useDateParams';
import Pagination from '../components/Pagination';
import Filters from '../components/TypeformComponents/Filters';
import TypeformButton from '../components/TypeformComponents/TypeformButton';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import usePagination from '../hooks/usePagination';
import useTypeform from '../hooks/useTypeform';
import { Column } from '../components/TypeformComponents/Table';
import { domainReservationFields } from '../enums/typeformFields';

export interface DomainReservationForTable {
  id: string;
  plate: string;
  dniFront: string;
  dniBack: string;
  circulationPermit: string;
  technicalSheet: string;
  payCard: string;
  createdAt: string;
}

type TypeformDomainReservation = {
  answers: { field: { id: string }; text?: string; file_url?: string }[];
  response_id: string;
  submitted_at: string;
};

type TypeformDomainReservationResponse = {
  items: TypeformDomainReservation[];
  page_count: number;
  total_items: number;
};

export const LIMIT = 10;

export default function DomainReservationList() {
  const [loadState, setLoadState] = useState<TYPEFORM_LOADING_STATE>(
    TYPEFORM_LOADING_STATE.NOT_STARTED,
  );

  const [domainReservations, setDomainReservations] = useState<
    DomainReservationForTable[]
  >([]);

  const { getDateParams, dateSortParam, minDateParam, maxDateParam } =
    useDateParams();

  const { page, setPage } = usePagination();

  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { companyInfo } = useContext(userInfoContext);

  const columns: Column[] = [
    {
      label: 'Matrícula',
      key: 'plate',
    },
    {
      label: 'DNI Reverso',
      key: 'dniFront',
      file: true,
    },
    {
      label: 'DNI Frontal',
      key: 'dniBack',
      file: true,
    },
    {
      label: 'Permiso de Circulación',
      key: 'circulationPermit',
      file: true,
    },
    {
      label: 'Ficha Técnica',
      key: 'technicalSheet',
      file: true,
    },
    {
      label: 'Carta de Pago Financiera',
      key: 'payCard',
      file: true,
    },
    {
      label: 'Fecha de Creación',
      key: 'createdAt',
    },
  ];

  useTypeform();

  useEffect(() => {
    (async () => {
      if (!companyInfo) return;
      setLoadState(TYPEFORM_LOADING_STATE.LOADING);

      const { data: fetchedDomainReservations } =
        await axios.post<TypeformDomainReservationResponse>(
          `${process.env.REACT_APP_PIAGGIO_BASE_URL}/typeform`,
          {
            url: `${process.env.REACT_APP_TYPEFORM_BASE_URL}/forms/${
              process.env.REACT_APP_TYPEFORM_DOMAIN_RESERVATION_FORM_ID
            }/responses?page_size=1000&query=${
              companyInfo.nif
            }${getDateParams()}&sort=submitted_at,${
              dateSortParam === 'ASC' ? 'asc' : 'desc'
            }`,
          },
        );

      const mappedDomainReservations: DomainReservationForTable[] =
        fetchedDomainReservations.items.map(
          (domainReservation: TypeformDomainReservation) => ({
            id: domainReservation.response_id,
            plate: domainReservation.answers.find(
              (a) => a.field.id === domainReservationFields.plate,
            )?.text,
            dniFront:
              domainReservation.answers.find(
                (a) => a.field.id === domainReservationFields.ownerDniFront,
              )?.file_url || '',
            dniBack:
              domainReservation.answers.find(
                (a) => a.field.id === domainReservationFields.ownerDniBack,
              )?.file_url || '',
            circulationPermit:
              domainReservation.answers.find(
                (a) => a.field.id === domainReservationFields.circulationPermit,
              )?.file_url || '',
            technicalSheet:
              domainReservation.answers.find(
                (a) => a.field.id === domainReservationFields.technicalSheet,
              )?.file_url || '',
            payCard:
              domainReservation.answers.find(
                (a) => a.field.id === domainReservationFields.financeCard,
              )?.file_url || '',
            createdAt: domainReservation.submitted_at,
          }),
        );
      setDomainReservations(mappedDomainReservations);

      setTotalCount(fetchedDomainReservations.total_items);
      setTotalPages(Math.ceil(fetchedDomainReservations.total_items / LIMIT));
      setLoadState(TYPEFORM_LOADING_STATE.LOADED);
    })();
  }, [minDateParam, maxDateParam, companyInfo, dateSortParam, getDateParams]);

  return (
    <div className="flex flex-col gap-5 p-8 w-full h-full min-h-fit bg-gray-100">
      <header className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold">Reservas de Dominio</h1>
        <TypeformButton
          formId={process.env.REACT_APP_TYPEFORM_DOMAIN_RESERVATION_FORM_ID}
          formTitle={`title=${
            process.env.REACT_APP_ENV === 'PROD'
              ? 'Reserva de dominio'
              : '[SANDBOX] Reserva de dominio'
          }`}
          buttonLabel="Nueva Reserva de Dominio"
          nif={companyInfo?.nif}
        />
      </header>
      <Filters />
      {loadState === TYPEFORM_LOADING_STATE.LOADING && (
        <DomainReserveTableSkeletonLoader />
      )}
      {loadState === TYPEFORM_LOADING_STATE.LOADED && (
        <DomainReservationsTable
          domainReservations={domainReservations}
          columns={columns}
        />
      )}
      <Pagination
        limit={LIMIT}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </div>
  );
}

import Spinner from './Spinner';
import useAgentAssign from '../hooks/useAgentAssign';

export default function AgentAssignBox({
  agent,
  tramitCode,
  editTramit,
  forceUpdate,
}) {
  const {
    agents,
    selectedAgent,
    setSelectedAgent,
    tramitAssignLoading,
    tramitAssignError,
    inputField,
    setInputField,
    handleAssignTramitToAgent,
    handleRemoveAgent,
  } = useAgentAssign({
    agent,
    tramitCode,
    editTramit,
    forceUpdate,
  });

  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mb-5">
      <h2 className="text-lg font-medium text-gray-900 mb-1">Gestor</h2>
      {tramitAssignLoading && (
        <div className="flex justify-center">
          <Spinner size={6} color="indigo-700" />
        </div>
      )}
      {!tramitAssignLoading && agent?.name && (
        <p className="mb-4">{`${agent?.name} ${agent?.surname}`}</p>
      )}
      {!tramitAssignLoading && !agent?.name && (
        <div className="relative flex flex-col gap-3">
          <label htmlFor="agent-name" className="sr-only">
            Nombre:
          </label>
          <input
            className="border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
            id="agent-name"
            placeholder="Nombre"
            type="text"
            value={inputField}
            onChange={(e) => {
              setInputField(e.target.value);
              setSelectedAgent(null);
            }}
            autoComplete="off"
          />
          {inputField !== '' && (
            <ul className="absolute top-10 mt-2 flex flex-col gap-3 bg-white shadow-md z-10 w-full">
              {agents
                .filter(
                  (e) =>
                    e.name.toLowerCase().includes(inputField.toLowerCase()) &&
                    e.name.toLowerCase() !== inputField.toLowerCase(),
                )
                .map((e) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                  <li
                    className="cursor-pointer hover:bg-gray-100 p-3"
                    onClick={() => {
                      setInputField(`${e.name} ${e.surname}`);
                      setSelectedAgent(e);
                    }}
                    key={e.name}
                  >
                    {`${e.name} ${e.surname}`}
                  </li>
                ))}
            </ul>
          )}
          <button
            onClick={handleAssignTramitToAgent}
            type="button"
            className="bg-indigo-500 text-white hover:bg-indigo-700 rounded-md px-2 py-1 disabled:opacity-50"
            disabled={!selectedAgent}
          >
            Asignar gestor
          </button>
        </div>
      )}
      {!tramitAssignLoading && agent?.name && (
        <button
          onClick={handleRemoveAgent}
          type="button"
          className="bg-red-500 text-white hover:bg-red-700 rounded-md px-2 py-1"
        >
          Desasignar gestor
        </button>
      )}
      {tramitAssignError && (
        <p className="text-red-500 text-sm">{tramitAssignError}</p>
      )}
    </div>
  );
}

import { Fragment, useState } from 'react';
import {
  ERROR_SHIPMENT_STATUS,
  ccaaDictionary,
} from '../../../../../helpers/enums';
import PaginationFooter from '../../../../../components/PaginationFooter';
import { shipmentStatusDictionary } from '../../../../../components/Shipments/components/ShipmentsTable';
import ShipmentItemsInTable from '../../../../../components/Shipments/components/ShipmentItemsInTable';
import classNames from '../../../../../helpers/classNames';

export function ShipmentTableHeaders() {
  return (
    <div className="flex w-full pb-2 border-b bg-[#F9FBFB] pt-2">
      <div className="w-[20%] text-center text-sm font-semibold text-gray-900">
        Status
      </div>
      <div className="w-[30%] text-center text-sm font-semibold text-gray-900">
        OTN
      </div>
      <div className="w-[35%] text-center text-sm font-semibold text-gray-900">
        Items
      </div>
      <div className="w-[15%] text-center text-sm font-semibold text-gray-900">
        Fecha
      </div>
    </div>
  );
}

export default function ShipmentTable({
  shipments,
  count,
  title,
  page,
  goNext,
  goPrev,
}) {
  const [expandedShipmentId, setExpandedShipmentId] = useState('');

  const handleExpandShipment = (id) => {
    if ([0, 1].includes(shipments.length)) return;

    if (expandedShipmentId === id) {
      setExpandedShipmentId('');
      return;
    }

    setExpandedShipmentId(id);
  };

  return (
    <div className="lg:col-start-2 lg:col-span-2 space-y-6">
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-md text-black font-medium border-none">
            {title}
          </h3>
          <div className="w-full mt-4 border rounded-lg shadow-sm">
            <ShipmentTableHeaders />
            <div className="max-h-[300px] overflow-y-auto">
              {shipments.map((s) => (
                <Fragment key={s.id}>
                  <div
                    className={`flex items-center p-2 text-gray-500 text-xs hover:bg-gray-100 transition-colors ${
                      s.id === expandedShipmentId && shipments.length > 1
                        ? 'border-t bg-gray-50'
                        : 'border-b border-b-gray-200'
                    }`}
                    onClick={() => handleExpandShipment(s.id)}
                    onKeyDown={() => handleExpandShipment(s.id)}
                    tabIndex={0}
                    role="button"
                  >
                    <div
                      className={classNames(
                        'flex items-center justify-center w-[20%] max-w-[20%] text-center break-words',
                        ERROR_SHIPMENT_STATUS.includes(s.status) &&
                          'text-red-400',
                      )}
                    >
                      {shipmentStatusDictionary.get(s.status)}
                    </div>
                    <div className="flex items-center justify-center w-[30%] text-center">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-500 flex items-center justify-center"
                        href={
                          s.otn
                            ? `https://swipoo.outvio.com/es/id/${s.otn}`
                            : '#'
                        }
                      >
                        {s.otn}
                      </a>
                    </div>
                    <div className="w-[35%] flex items-center flex-col gap-y-0 pl-8">
                      <div className="block">
                        <ShipmentItemsInTable items={s.items} />
                      </div>
                    </div>
                    <div className="w-[15%] flex items-center flex-col gap-y-0 pl-8">
                      {new Date(s.shippedAt).toLocaleDateString()}
                    </div>
                  </div>

                  {s.id === expandedShipmentId && (
                    <div
                      className={`flex w-full justify-between px-16 pt-4 text-xs pb-4 ${
                        shipments.length > 1 && 'bg-gray-50 border-b'
                      }`}
                    >
                      <div className="flex flex-col">
                        <div className="flex flex-col gap-y-2">
                          <div>
                            <p className="text-gray-500">Comunidad Autónoma</p>
                            <p>{ccaaDictionary[s.delivery.state] || '-'}</p>
                          </div>
                          <div>
                            <p className="text-gray-500">Ciudad</p>
                            <p>{s.delivery.city || '-'}</p>
                          </div>
                          <div>
                            <p className="text-gray-500">Código Postal</p>
                            <p>{s.delivery.zipCode || '-'}</p>
                          </div>
                          <div>
                            <p className="text-gray-500">Dirección</p>
                            <p>{s.delivery.street || '-'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex flex-col gap-y-2">
                          <div>
                            <p className="text-gray-500">Nombre</p>
                            <p>{s.receiver.name || '-'}</p>
                          </div>
                          <div>
                            <p className="text-gray-500">DNI</p>
                            <p>{s.receiver.doi || '-'}</p>
                          </div>
                          <div>
                            <p className="text-gray-500">Email</p>
                            <p>{s.receiver.email || '-'}</p>
                          </div>
                          <div>
                            <p className="text-gray-500">Teléfono</p>
                            <p>{s.receiver.phone || '-'}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
          <PaginationFooter
            data={shipments}
            page={page}
            goNext={goNext}
            goPrev={goPrev}
            total={count}
          />
        </div>
      </div>
    </div>
  );
}

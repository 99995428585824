import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from 'react';
import { DigitallySignSteps } from '../../modals/DigitallySignModal/steps/DigitallySignStepsManager';

export type DigitallySignContextType = {
  currentStep: number;
  setCurrentDigitallySignStep: Dispatch<SetStateAction<DigitallySignSteps>>;
};

export const digitallySignContext = createContext<DigitallySignContextType>(
  {} as DigitallySignContextType,
);

export function DigitallySignContextProvider({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const [currentDigitallySignStep, setCurrentDigitallySignStep] =
    useState<DigitallySignSteps>(DigitallySignSteps.CHOOSE_DOCUMENT_SOURCE);

  const value = useMemo(
    () => ({
      currentStep: currentDigitallySignStep,
      setCurrentDigitallySignStep,
    }),
    [currentDigitallySignStep],
  );

  return (
    <digitallySignContext.Provider value={value}>
      {children}
    </digitallySignContext.Provider>
  );
}

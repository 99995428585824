import React, { useEffect } from 'react';

export default function useClickOutside(
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [ref]);
}

import axios from 'axios';

export const getFileName = (typeformUrl: string) =>
  typeformUrl.split('/files/')[1].split('-')[1];

export const downloadDocument = async (url: string, fileName: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_PIAGGIO_BASE_URL}/typeform-get-file`,
    {
      url,
    },
  );

  const imageUrl = `data:${response.headers['content-type']};base64,${response.data}`;

  const link = document.createElement('a');
  link.href = imageUrl;
  link.target = '_blank';
  link.download = fileName;
  link.click();
};

/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import { useServices } from '../../../services';
import Spinner from '../../../components/Spinner';
import EmptyBatecomState from './components/EmptyBatecomState';
import BatecomsTableList from './components/BatecomsTableList';
import useFilters from './hooks/useFilters';
import FilterOptions from '../transfers/components/FilterOptions';
import DateFilter from '../transfers/components/DateFilter';
import ActiveFilters from '../transfers/components/ActiveFilters';

export default function Batecoms() {
  const [loader, setLoader] = useState(true);
  const [transfers, setTransfers] = useState([]);

  const {
    searchParams,
    page,
    dateSort,
    minDate,
    maxDate,
    goNext,
    goPrev,
    setIncidentTypes,
    handleLabelsFilter,
    incidentTypeItems,
    statusItems,
    setMinDate,
    setMaxDate,
    handleDateSort,
    handleFilter,
    companies,
    setCompanies,
    labelOptions,
    setLabelOptions,
    accounts,
    setAccounts,
  } = useFilters();

  const [total, setTotal] = useState(0);

  const {
    getAllBatecoms,
    fetchAllLabelsByEntityType,
    fetchAllIncidentsByEntityType,
  } = useServices();

  const loadLabels = async () => {
    if (labelOptions.length === 0) {
      const transactionLabels = await fetchAllLabelsByEntityType('TRANSACTION');
      const bateLabels = await fetchAllLabelsByEntityType('BATE');
      setLabelOptions([...transactionLabels, ...bateLabels]);
    }
  };

  const batecomFilterItems = [
    {
      key: 'tramitType',
      name: 'BATE',
      value: 'batecom_purchase',
      color: 'bg-gray-400',
    },
    {
      key: 'tramitType',
      name: 'COM',
      value: 'batecom_sale',
      color: 'bg-gray-400',
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const transactionIncidents = await fetchAllIncidentsByEntityType(
          'TRANSACTION',
        );
        const bateIncidents = await fetchAllIncidentsByEntityType('BATE');
        setIncidentTypes([...transactionIncidents, ...bateIncidents]);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    const hasIncident = searchParams.get('hasIncident')
      ? Boolean(searchParams.get('hasIncident'))
      : false;
    const incidentType = searchParams.get('incidentType')
      ? searchParams.get('incidentType')
      : null;
    const status = searchParams.get('status')
      ? Number(searchParams.get('status'))
      : null;
    const companyId = searchParams.get('companyId')
      ? searchParams.get('companyId')
      : null;
    const accountId = searchParams.get('accountId')
      ? searchParams.get('accountId')
      : null;
    const tramitType = searchParams.get('tramitType')
      ? searchParams.get('tramitType')
      : null;

    const labels = searchParams.getAll('labels');

    const controller = new AbortController();
    const { signal } = controller;
    const fetchBatecoms = async () => {
      const { transfers: response, count } = await getAllBatecoms({
        page,
        signal,
        dateSort,
        incidentType,
        minDate,
        maxDate,
        hasIncident,
        companyId,
        accountId,
        labels,
        status,
        tramitType,
      });
      setTransfers(response);
      setTotal(count);
      setLoader(false);
    };

    fetchBatecoms();

    return () => {
      controller.abort();
    };
  }, [page, getAllBatecoms, searchParams]);

  return (
    <>
      {loader && (
        <div className="w-full h-full min-h-[500px] flex justify-center items-center">
          <Spinner color="text-blue-700" size={10} marginTop={28} />
        </div>
      )}
      {!loader && (
        <>
          <div className="flex items-center flex-wrap justify-between mt-3">
            <FilterOptions
              isBatecoms
              statusItems={statusItems}
              incidentTypeItems={incidentTypeItems}
              handleFilter={handleFilter}
              searchParams={searchParams}
              setCompanies={setCompanies}
              companies={companies}
              handleLabelsFilter={handleLabelsFilter}
              loadLabels={loadLabels}
              labelOptions={labelOptions}
              batecomFilterItems={batecomFilterItems}
              accounts={accounts}
              setAccounts={setAccounts}
            />
            <span className="flex flex-wrap gap-y-4 justify-end mb-4 rounded-md">
              <DateFilter
                minDate={minDate}
                setMinDate={setMinDate}
                maxDate={maxDate}
                setMaxDate={setMaxDate}
              />
            </span>
          </div>
          <ActiveFilters
            statusItems={statusItems}
            incidentTypeItems={incidentTypeItems}
            searchParams={searchParams}
            handleFilter={handleFilter}
            companies={companies}
            accounts={accounts}
            labelOptions={labelOptions}
            handleLabelsFilter={handleLabelsFilter}
            batecomFilterItems={batecomFilterItems}
          />

          {transfers.length > 0 ? (
            <BatecomsTableList
              data={transfers}
              page={page}
              goNext={goNext}
              goPrev={goPrev}
              handleDateSort={handleDateSort}
              dateSort={dateSort}
              total={total}
            />
          ) : (
            <EmptyBatecomState />
          )}
        </>
      )}
    </>
  );
}

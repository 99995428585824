/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import DuplicatesTable from './components/DuplicatesTable/DuplicatesTable';
import DuplicatesTableSkeletonLoader from './components/DuplicatesTableSkeletonLoader/DuplicatesTableSkeletonLoader';
import { TYPEFORM_LOADING_STATE } from '../types/types';
import useDateParams from '../hooks/useDateParams';
import usePagination from '../hooks/usePagination';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import useTypeform from '../hooks/useTypeform';
import { Column } from '../components/TypeformComponents/Table';
import Filters from '../components/TypeformComponents/Filters';
import TypeformButton from '../components/TypeformComponents/TypeformButton';
import Pagination from '../components/Pagination';
import { duplicateFields } from '../enums/typeformFields';

export interface DuplicateForTable {
  id: string;
  plate: string;
  ownerDoi: string;
  technicalSheet: string;
  createdAt: string;
}

type TypeformDuplicate = {
  answers: { field: { id: string }; text?: string; file_url?: string }[];
  response_id: string;
  submitted_at: string;
};

type TypeformDuplicatesResponse = {
  items: TypeformDuplicate[];
  page_count: number;
  total_items: number;
};

export const LIMIT = 10;

export default function DuplicatesList() {
  const [loadState, setLoadState] = useState<TYPEFORM_LOADING_STATE>(
    TYPEFORM_LOADING_STATE.NOT_STARTED,
  );

  const [duplicates, setDuplicates] = useState<DuplicateForTable[]>([]);

  const { getDateParams, dateSortParam, minDateParam, maxDateParam } =
    useDateParams();

  const { page, setPage } = usePagination();

  const columns: Column[] = [
    {
      label: 'Matrícula',
      key: 'plate',
    },
    {
      label: 'DOI del propietario',
      key: 'ownerDoi',
      file: true,
    },
    {
      label: 'Ficha Técnica',
      key: 'technicalSheet',
      file: true,
    },
    {
      label: 'Fecha de Creación',
      key: 'createdAt',
    },
  ];

  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { companyInfo } = useContext(userInfoContext);

  useTypeform();

  useEffect(() => {
    (async () => {
      if (!companyInfo) return;
      setLoadState(TYPEFORM_LOADING_STATE.LOADING);

      const { data: fetchedDuplicates } =
        await axios.post<TypeformDuplicatesResponse>(
          `${process.env.REACT_APP_PIAGGIO_BASE_URL}/typeform`,
          {
            url: `${process.env.REACT_APP_TYPEFORM_BASE_URL}/forms/${
              process.env.REACT_APP_TYPEFORM_DUPLICATES_FORM_ID
            }/responses?page_size=1000&query=${
              companyInfo.nif
            }${getDateParams()}&sort=submitted_at,${
              dateSortParam === 'ASC' ? 'asc' : 'desc'
            }`,
          },
        );

      const mappedDuplicates: DuplicateForTable[] = fetchedDuplicates.items.map(
        (duplicate: TypeformDuplicate) => ({
          id: duplicate.response_id,
          plate: duplicate.answers.find(
            (a) => a.field.id === duplicateFields.plate,
          )?.text,
          ownerDoi:
            duplicate.answers.find(
              (a) => a.field.id === duplicateFields.ownerDoi,
            )?.file_url || '',
          technicalSheet:
            duplicate.answers.find(
              (a) => a.field.id === duplicateFields.technicalSheet,
            )?.file_url || '',
          createdAt: duplicate.submitted_at,
        }),
      );
      setDuplicates(mappedDuplicates);

      setTotalCount(fetchedDuplicates.total_items);
      setTotalPages(Math.ceil(fetchedDuplicates.total_items / LIMIT));
      setLoadState(TYPEFORM_LOADING_STATE.LOADED);
    })();
  }, [minDateParam, maxDateParam, companyInfo, dateSortParam, getDateParams]);

  return (
    <div className="flex flex-col gap-5 p-8 w-full h-full min-h-fit bg-gray-100">
      <header className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold">Duplicados</h1>
        <TypeformButton
          formId={process.env.REACT_APP_TYPEFORM_DUPLICATES_FORM_ID}
          formTitle={`title=${
            process.env.REACT_APP_ENV === 'PROD'
              ? 'Duplicado permiso de circulación'
              : '[SANDBOX] Duplicado permiso de circulación'
          }`}
          buttonLabel="Nuevo Duplicado"
          nif={companyInfo?.nif}
        />
      </header>
      <Filters />
      {loadState === TYPEFORM_LOADING_STATE.LOADING && (
        <DuplicatesTableSkeletonLoader />
      )}
      {loadState === TYPEFORM_LOADING_STATE.LOADED && (
        <div className="max-w-screen overflow-x-scroll">
          <DuplicatesTable duplicates={duplicates} columns={columns} />
        </div>
      )}
      <Pagination
        limit={LIMIT}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </div>
  );
}

export const ccaaDictionary: {
  [key: string]: string;
} = {
  CT: 'Cataluña',
  MD: 'Madrid',
  EX: 'Extremadura',
  PV: 'País Vasco',
  NC: 'Navarra',
  RI: 'La Rioja',
  CL: 'Castilla y León',
  CM: 'Castilla-La Mancha',
  CB: 'Cantabria',
  GA: 'Galicia',
  AS: 'Asturias',
  CN: 'Canarias',
  AN: 'Andalucía',
  AR: 'Aragón',
  IB: 'Islas baleares',
  MC: 'Murcia',
  VC: 'Comunidad Valenciana',
};

import React, { useEffect, useState, useCallback, useReducer } from 'react';
import { Link } from 'react-router-dom';
import PaginationFooter from '../../../components/PaginationFooter';
import Spinner from '../../../components/Spinner';
import { parseDateHourYear } from '../../../helpers';
import usePagination from '../../../hooks/usePagination';
import { useServices } from '../../../services';
import NewBadgeModal from './NewBadgeModal';

export default function Badges() {
  const [badges, setBadges] = useState([]);
  const [page, goNext, goPrev] = usePagination();
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const { getAllBadges: fetchAllBadges } = useServices();

  const getAllBadges = useCallback(async () => {
    const response = await fetchAllBadges(page);
    setBadges(response);
    setLoader(false);
  }, [fetchAllBadges, page]);

  useEffect(() => {
    getAllBadges();
  }, [getAllBadges, updater]);

  return (
    <>
      <NewBadgeModal
        showModal={showModal}
        setShowModal={setShowModal}
        save={forceUpdate}
      />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle min-w-full sm:px-6 lg:px-8 flex flex-col">
            <span className="flex justify-end z-0 mb-4 rounded-md">
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="self-end inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Nuevo distintivo
              </button>
            </span>

            {loader && (
              <div className="w-full h-full min-h-[500px] flex justify-center items-center">
                <Spinner color="text-blue-700" size={10} marginTop={28} />
              </div>
            )}
            {!loader && (
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {!!badges.length && (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Tipo
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Matrícula
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Nombre
                        </th>
                        <th
                          scope="col"
                          colSpan={2}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Fecha
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {badges.map((badge, i) => (
                        <tr
                          key={badge.id}
                          className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {badge.badgeType}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {badge.plate}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {badge.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {parseDateHourYear(badge.createdAt)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer hover:text-blue-600">
                            <Link
                              to={`/environmental-badges/${badge.id}`}
                              key={badge.id}
                            >
                              Ver
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {badges.length === 0 && !loader && (
                  <p className="mb-4 px-2">
                    No se encontraron etiquetas medioambientales
                  </p>
                )}
                <PaginationFooter
                  data={badges}
                  page={page}
                  goNext={goNext}
                  goPrev={goPrev}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default function EmailInput({
  label,
  stateName,
  value,
  isDirty,
  required,
  handleChange,
}) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 flex items-center mb-4">
      <div>
        <label
          htmlFor={stateName}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        {!required && <span className="text-xs">(Opcional)</span>}
      </div>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <input
          onChange={handleChange}
          value={value}
          type="email"
          name={stateName}
          id={stateName}
          required={required}
          className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
        />
        {required && isDirty && !value && (
          <p className="text-red-600">Debe rellenar este campo</p>
        )}
      </div>
    </div>
  );
}

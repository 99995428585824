import React, { useEffect, useState } from 'react';

import { useServices } from '../../../services';
import usePagination from '../../../hooks/usePagination';
import Spinner from '../../../components/Spinner';
import PricePlansTableList from './components/PricePlansTableList';
import PricePlanEditModal from './components/PricePlanEditModal';
import PricePlanCreateModal from './components/PricePlanCreateModal';

export default function PricePlans() {
  const [pricePlans, setPricePlans] = useState([]);
  const [page, goNext, goPrev] = usePagination();
  const [loader, setLoader] = useState(true);
  const [pricePlanEdited, setPricePlanEdited] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { getAllPricePlans } = useServices();

  const fetchPricePlans = async () => {
    const response = await getAllPricePlans(page);
    setPricePlans(response);
    setLoader(false);
  };

  useEffect(() => {
    fetchPricePlans();
  }, [page, getAllPricePlans]);

  const closeEditModal = () => setPricePlanEdited(null);

  return (
    <>
      <PricePlanCreateModal
        isCreateModalOpen={isCreateModalOpen}
        setIsCreateModalOpen={setIsCreateModalOpen}
        fetchPricePlans={fetchPricePlans}
      />
      <PricePlanEditModal
        data={pricePlanEdited}
        closeModal={closeEditModal}
        fetchPricePlans={fetchPricePlans}
      />
      {loader && (
        <div className="w-full h-full min-h-[500px] flex justify-center items-center">
          <Spinner color="text-blue-700" size={10} marginTop={28} />
        </div>
      )}
      {!loader && (
        <div className="flex flex-col gap-y-2">
          <button
            onClick={() => setIsCreateModalOpen(true)}
            type="button"
            className="inline-flex items-center self-end px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Nuevo plan
          </button>
          <PricePlansTableList
            data={pricePlans}
            page={page}
            goNext={goNext}
            goPrev={goPrev}
            setPricePlanEdited={setPricePlanEdited}
          />
        </div>
      )}
    </>
  );
}

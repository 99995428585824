import { Dispatch, SetStateAction } from 'react';
import { DeliveryAddress } from '../../../../../types/types';
import { getAllDeliveryAddress } from '../../../../../services/shipment.services';

export const getDeliveryAddress = async ({
  accountId,
  setDeliveryAddress,
  setError,
  token,
}: {
  accountId: string | undefined;
  setDeliveryAddress: Dispatch<SetStateAction<DeliveryAddress[]>>;
  setError: Dispatch<SetStateAction<string>>;
  token: string;
}) => {
  if (!accountId) return;
  try {
    setError('');
    const response = await getAllDeliveryAddress(accountId, token);
    setDeliveryAddress(response.data);
  } catch (err) {
    setError('Ha ocurrido un error al cargar las direcciones de envío.');
  }
};

function PaginationFooter({ data, page, goNext, goPrev, total }) {
  const nextCondition = () => {
    if (total) {
      return data?.length === 10 && (page + 1) * 10 !== total;
    }
    return !(data?.length < 10);
  };

  if (data?.length < 10 && page === 0) return null;
  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        {total && (
          <p className="text-sm text-gray-700">
            Página{' '}
            <span className="font-medium">
              {page + 1} de {Math.ceil(total / 10)}
            </span>
          </p>
        )}
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {page > 0 && (
          <button
            onClick={goPrev}
            type="button"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Anterior
          </button>
        )}
        {nextCondition() && (
          <button
            onClick={goNext}
            type="button"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Siguiente
          </button>
        )}
      </div>
    </nav>
  );
}

export default PaginationFooter;

export const DGT_FEE_STATUS = {
  READY_TO_USE: 'READY_TO_USE',
  IN_QUARANTINE: 'IN_QUARANTINE',
  IN_DISPUTE: 'IN_DISPUTE',
  USED: 'USED',
};

export const dgtFeeStatusDictionary = {
  [DGT_FEE_STATUS.READY_TO_USE]: 'LIBRE',
  [DGT_FEE_STATUS.IN_QUARANTINE]: 'EN CUARENTENA',
  [DGT_FEE_STATUS.IN_DISPUTE]: 'EN DISPUTA',
  [DGT_FEE_STATUS.USED]: 'USADA',
};

export const tramitPaths = {
  TRANSACTION: 'transfers',
  BATE: 'bates',
  REGISTRATION: 'registration',
};

import React, { useContext } from 'react';
import { transferDetailsContext } from '../../../../../../context/transfer-details.context';
import { docDictionary, docFamilyDictionary } from './dictionaries';

function BasicDocs({ selectedDocs, handleInputCheck }) {
  const { transfer } = useContext(transferDetailsContext);

  const basicDocs = JSON.parse(
    JSON.stringify({
      buyer: {
        dniFront: transfer?.buyer?.photoDni?.[0] || undefined,
        dniBack: transfer?.buyer?.photoDni?.[1] || undefined,
        census: transfer?.buyer?.census || undefined,
      },
      seller: {
        dniFront: transfer?.seller?.photoDni?.[0] || undefined,
        dniBack: transfer?.seller?.photoDni?.[1] || undefined,
        census: transfer?.seller?.census || undefined,
      },
      ...(![transfer?.buyer?.id, transfer?.seller?.id].includes(
        transfer?.intermediary?.id,
      ) && {
        intermediary: {
          dniFront: transfer?.intermediary?.photoDni?.[0] || undefined,
          dniBack: transfer?.intermediary?.photoDni?.[1] || undefined,
          census: transfer?.intermediary?.census || undefined,
        },
      }),
      car: {
        circulationPermit: transfer?.car?.circulationPermit || undefined,
        technicalSheet: transfer?.car?.technicalSheet || undefined,
      },
      transfer: {
        saleContract: transfer?.transaction?.saleContract || undefined,
        temporaryCirculationDoc:
          transfer?.transaction?.temporaryCirculationDoc || undefined,
        presentationProof:
          transfer?.transaction?.presentationProof || undefined,
        proInvoice: transfer?.transaction?.proInvoice || undefined,
        paidItpFile: transfer?.transaction?.paidItpFile || undefined,
        paidItpProof: transfer?.transaction?.paidItpProof || undefined,
      },
      proBuyer: {
        nifFile: transfer?.buyer?.nifFile || undefined,
        dniFrontRepresentative:
          transfer?.buyer?.representative?.photoDni?.[0] || undefined,
        dniBackRepresentative:
          transfer?.buyer?.representative?.photoDni?.[1] || undefined,
        censusRepresentative:
          transfer?.buyer?.representative?.census || undefined,
      },
      proSeller: {
        nifFile: transfer?.seller?.nifFile || undefined,
        dniFrontRepresentative:
          transfer?.seller?.representative?.photoDni?.[0] || undefined,
        dniBackRepresentative:
          transfer?.seller?.representative?.photoDni?.[1] || undefined,
        censusRepresentative:
          transfer?.seller?.representative?.census || undefined,
      },
      ...(![transfer?.buyer?.id, transfer?.seller?.id].includes(
        transfer?.intermediary?.id,
      ) && {
        proIntermediary: {
          nifFile: transfer?.intermediary?.nifFile || undefined,
          dniFrontRepresentative:
            transfer?.intermediary?.representative?.photoDni?.[0] || undefined,
          dniBackRepresentative:
            transfer?.intermediary?.representative?.photoDni?.[1] || undefined,
          censusRepresentative:
            transfer?.intermediary?.representative?.census || undefined,
        },
      }),
    }),
  );
  return (
    <>
      {Object.keys(basicDocs).map((docFamily) =>
        Object.keys(basicDocs[docFamily]).length > 0 ? (
          <div key={docFamily}>
            <h3 className="text-lg font-medium">
              {docFamilyDictionary[docFamily]}
            </h3>
            <div className="flex flex-col gap-2 mb-4 mt-2">
              {Object.keys(basicDocs[docFamily]).map((doc) => (
                <div key={doc} className="flex items-center gap-2">
                  <input
                    id={`${docFamily}-${doc}`}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={selectedDocs.some(
                      (e) => e.family === docFamily && e.docName === doc,
                    )}
                    onChange={(e) =>
                      handleInputCheck(
                        e.target.checked,
                        docFamily,
                        doc,
                        basicDocs[docFamily][doc],
                      )
                    }
                  />
                  <label htmlFor={`${docFamily}-${doc}`}>
                    {docDictionary[doc]}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ) : null,
      )}
    </>
  );
}

export default BasicDocs;

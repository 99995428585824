import {
  OfficeBuildingIcon,
  PencilIcon,
  SwitchHorizontalIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';
import { MdFindReplace } from 'react-icons/md';
import { FaAddressCard } from 'react-icons/fa';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { classNames } from '../../../../helpers';
import { bateDetailsContext } from '../../../../context/bate-details.context';
import ReplaceEntityeModal from '../../../../components/ReplaceEntityModals/ReplaceEntityModal';
import ChangeEntityTypeModal from '../../../../components/ChangeEntityTypeModal/ChangeEntityTypeModal';

export default function BateSummary({ setModalType }) {
  const [showReplaceButton, setShowReplaceButton] = useState({
    seller: false,
    car: false,
  });
  const [entityToReplace, setEntityToReplace] = useState({
    id: null,
    type: null,
  });
  const [changeEntityModalData, setChangeEntityModalData] = useState({
    showModal: false,
    role: null,
    sourceEntityType: null,
    sourceEntityId: null,
  });

  const { aggregatedBate, forceUpdate } = useContext(bateDetailsContext);
  const { bate, buyer, seller, car, account } = aggregatedBate;

  const closeModal = () => setEntityToReplace({ id: null, type: null });

  const vehicleDisplayLabel = (
    <>
      {car?.brand} {car?.model}{' '}
      {(car?.brand || car?.model) && car?.plate && '-'} {car?.plate}
      {car?.id &&
        !car?.brand &&
        !car?.model &&
        !car?.plate &&
        'Vehículo sin datos'}
    </>
  );

  return (
    <>
      <ReplaceEntityeModal
        open={Boolean(entityToReplace?.id)}
        closeModal={closeModal}
        forceUpdate={forceUpdate}
        sourceEntityId={entityToReplace?.id}
        entityType={entityToReplace?.type}
      />
      <ChangeEntityTypeModal
        changeEntityModalData={changeEntityModalData}
        setChangeEntityModalData={setChangeEntityModalData}
        forceUpdate={forceUpdate}
        tramitType="bate"
        tramitCode={bate?.bateCode}
      />
      {buyer && (
        <div
          className="w-full"
          onMouseEnter={() =>
            setShowReplaceButton((prevState) => ({
              ...prevState,
              buyer: true,
            }))
          }
          onMouseLeave={() =>
            setShowReplaceButton((prevState) => ({
              ...prevState,
              buyer: false,
            }))
          }
        >
          <div className="flex items-center gap-x-4 w-[fit-content]">
            <Link
              to={`/${buyer.representative ? 'companies' : 'customers'}/${
                bate.buyerId
              }`}
            >
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 hover:text-blue-600">
                  Comprador
                </dt>
                <dd className="mt-1 text-sm text-gray-900 flex gap-x-1">
                  {buyer?.representative ? (
                    <OfficeBuildingIcon className="h-5 w-5 text-gray-600" />
                  ) : (
                    <UserCircleIcon className="h-5 w-5 text-gray-600" />
                  )}
                  {buyer?.name} {buyer?.surname}
                  {!(buyer.name || buyer.surname) &&
                    (buyer.email || buyer.dni || buyer.nif)}
                </dd>
              </div>
            </Link>
            {showReplaceButton.buyer && (
              <div div className="flex gap-x-2">
                <MdFindReplace
                  className="w-6 h-6 text-red-500 hover:text-red-700 cursor-pointer"
                  onClick={() => {
                    setEntityToReplace({
                      id: bate.buyerId,
                      type: buyer?.dni === undefined ? 'company' : 'user',
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {seller && (
        <div
          className="w-full"
          onMouseEnter={() =>
            setShowReplaceButton((prevState) => ({
              ...prevState,
              seller: true,
            }))
          }
          onMouseLeave={() =>
            setShowReplaceButton((prevState) => ({
              ...prevState,
              seller: false,
            }))
          }
        >
          <div className="flex items-center gap-x-4 w-[fit-content]">
            <Link
              to={`/${seller.representative ? 'companies' : 'customers'}/${
                bate.sellerId
              }`}
            >
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 hover:text-blue-600">
                  Vendedor
                </dt>
                <dd className="mt-1 text-sm text-gray-900 flex gap-x-1">
                  {seller?.representative ? (
                    <OfficeBuildingIcon className="h-5 w-5 text-gray-600" />
                  ) : (
                    <UserCircleIcon className="h-5 w-5 text-gray-600" />
                  )}
                  {seller?.name} {seller?.surname}
                  {!(seller.name || seller.surname) &&
                    (seller.email || seller.dni || seller.nif)}
                </dd>
              </div>
            </Link>
            {showReplaceButton.seller && (
              <div div className="flex gap-x-2">
                <MdFindReplace
                  className="w-6 h-6 text-red-500 hover:text-red-700 cursor-pointer"
                  onClick={() => {
                    setEntityToReplace({
                      id: bate.sellerId,
                      type: seller?.dni === undefined ? 'company' : 'user',
                    });
                  }}
                />
                <SwitchHorizontalIcon
                  className="w-6 h-6 text-red-500 hover:text-red-700 cursor-pointer"
                  onClick={() => {
                    setChangeEntityModalData({
                      showModal: true,
                      role: 'seller',
                      sourceEntityType:
                        seller?.dni === undefined ? 'company' : 'user',
                      sourceEntityId: bate?.sellerId,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {account && (
        <div className="flex items-center gap-x-4  w-[fit-content]">
          <Link to={`/account/${account.id}`}>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500 hover:text-blue-600">
                Cuenta
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex gap-x-1">
                <FaAddressCard
                  className={classNames(
                    'h-5 w-5',
                    account?.platform === 'SWIPOOPRO' && 'text-blue-600',
                    account?.platform === 'ANCOVE' && 'text-ancove-500',
                    !account?.platform && 'text-gray-600',
                  )}
                />
                {account?.name}
              </dd>
            </div>
          </Link>
        </div>
      )}
      {car && (
        <div
          className="w-full"
          onMouseEnter={() =>
            setShowReplaceButton((prevState) => ({
              ...prevState,
              car: true,
            }))
          }
          onMouseLeave={() =>
            setShowReplaceButton((prevState) => ({
              ...prevState,
              car: false,
            }))
          }
        >
          <div className="flex items-center gap-x-4  w-[fit-content]">
            <Link to={`/vehicles/${bate.carId}`}>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 hover:text-blue-600">
                  Vehículo
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {vehicleDisplayLabel}
                </dd>
              </div>
            </Link>
            {showReplaceButton.car && (
              <MdFindReplace
                className="w-6 h-6 text-red-500 hover:text-red-700 cursor-pointer"
                onClick={() => {
                  setEntityToReplace({
                    id: bate.carId,
                    type: 'car',
                  });
                }}
              />
            )}
          </div>
        </div>
      )}
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Número de tasa</dt>
        <dd className="mt-1 text-sm text-gray-900 flex items-center">
          {bate.dgtFeeNumber}
          <PencilIcon
            className="h-4 text-indigo-500 hover:text-indigo-600 cursor-pointer ml-2"
            onClick={() => {
              setModalType('dgtFeeNumber');
            }}
          />
        </dd>
      </div>
    </>
  );
}

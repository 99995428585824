import { useState } from 'react';

import BaseModal from './BaseModal';
import { classNames } from '../helpers';

export default function DeleteExtraFileModal({
  isOpen,
  close,
  file,
  handleDeleteExtraFile,
}) {
  const [accepted, setAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const resetState = () => {
    setAccepted(false);
    setIsLoading(false);
    setErrorMessage('');
  };

  const handleToggleAccept = () => setAccepted((prev) => !prev);
  const handleClick = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      await handleDeleteExtraFile(file);
      close();
      resetState();
    } catch (e) {
      setErrorMessage('No se pudo eliminar el archivo extra');
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !accepted || isLoading;

  return (
    <BaseModal isOpen={isOpen} onClose={close}>
      <div className="p-6">
        <div className="mb-2">
          <p className="text-lg font-medium">Eliminar archivo extra</p>
          {errorMessage && (
            <p className="text-red-500 text-sm">{errorMessage}</p>
          )}
        </div>
        <p>
          ¿Estás seguro de que deseas eliminar{' '}
          <span className="font-bold">{file?.split('-hash-')[0]}</span>? Esta
          acción no se puede deshacer.
        </p>
        <div className="flex gap-x-2 items-center mt-2">
          <input
            type="checkbox"
            id="accept"
            checked={accepted}
            onChange={handleToggleAccept}
            className="rounded"
          />
          <label htmlFor="accept">Sí, lo entiendo</label>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className={classNames(
            'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm focus:ring-red-500',
            isDisabled
              ? 'bg-red-300 hover:bg-red-400'
              : 'bg-red-600 hover:bg-red-700',
          )}
          onClick={handleClick}
          disabled={isDisabled}
        >
          {isLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
          {isLoading ? 'Eliminando...' : 'Eliminar'}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={close}
        >
          Cancelar
        </button>
      </div>
    </BaseModal>
  );
}

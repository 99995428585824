import { useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { useServices } from '../../../../services';
import Spinner from '../../../SwipooPro/components/Spinner';
import DgtFeeTypeInformationModal from './DgtFeeTypeInformationModal';
import DgtFeeTypeSelector from './DgtFeeTypeSelector';

const DGT_STATUS_DICTIONARY = {
  READY_TO_USE: 'Disponible',
  IN_QUARANTINE: 'En cuarentena',
  IN_DISPUTE: 'En disputa',
  USED: 'Utilizada',
};

export default function DgtFeeSummary({
  selectedFeeType,
  setSelectedFeeType,
  updater,
}) {
  const [summary, setSummary] = useState({
    READY_TO_USE: 0,
    IN_QUARANTINE: 0,
    IN_DISPUTE: 0,
    USED: 0,
  });
  const [dgtFeeTypes, setDgtFeeTypes] = useState([]);
  const [summaryLoader, setSummaryLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isInformationModalOpen, setIsInformationModalOpen] = useState(false);

  const { getDgtFeeSummary, getDgtFeeTypes } = useServices();

  useEffect(() => {
    const getAllDgtFeeTypes = async () => {
      try {
        const types = await getDgtFeeTypes();
        setDgtFeeTypes(types);
      } catch (e) {
        setErrorMessage('No se pudo obtener los tipos de tasas');
      }
    };

    getAllDgtFeeTypes();
  }, []);

  useEffect(() => {
    const getFeeSummary = async () => {
      try {
        setSummaryLoader(true);
        const summaryResponse = await getDgtFeeSummary(selectedFeeType);
        setSummary(summaryResponse);
      } catch (e) {
        setErrorMessage('No se pudo obtener el resumen de tasas');
      } finally {
        setSummaryLoader(false);
      }
    };
    getFeeSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updater, selectedFeeType]);

  return (
    <>
      <DgtFeeTypeInformationModal
        showModal={isInformationModalOpen}
        setShowModal={setIsInformationModalOpen}
        dgtFeeTypes={dgtFeeTypes}
      />
      <div>
        {errorMessage && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="align-middle min-w-full sm:px-6 lg:px-8 flex flex-col">
              <div className="mb-4 rounded-md">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    {summaryLoader && (
                      <span className="ml-2">
                        <Spinner />
                      </span>
                    )}
                  </div>
                </div>

                <div className="py-4 flex gap-x-2 items-center">
                  <label
                    htmlFor="fee-type"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Tipo
                  </label>
                  <div className="flex items-center gap-x-2">
                    <DgtFeeTypeSelector
                      setSelectedFeeType={setSelectedFeeType}
                      selectedFeeType={selectedFeeType}
                      summaryLoader={summaryLoader}
                      dgtFeeTypes={dgtFeeTypes}
                    />
                    <InformationCircleIcon
                      className="h-5 w-5 text-gray-400 cursor-pointer"
                      onClick={() => setIsInformationModalOpen(true)}
                    />
                  </div>
                </div>

                <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-4">
                  {Object.entries(summary).map(([status, count]) => (
                    <div
                      key={DGT_STATUS_DICTIONARY[status]}
                      className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                    >
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {DGT_STATUS_DICTIONARY[status]}
                      </dt>
                      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                        {count}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

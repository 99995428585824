import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FolderIcon } from '@heroicons/react/outline';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Hit as HitType,
  PrimitiveBate,
  PrimitiveRegistration,
  PrimitiveTrafficReport,
  PrimitiveTransaction,
} from './Searchbox';
import { parseDayMonth3LetterYear } from '../../../helpers/parseDate';
import {
  getTransferStatusLabel,
  getBateStatusLabel,
  getRegistrationStatusLabel,
} from '../../../helpers/statusLabelParser';
import classNames from '../../../helpers/classNames';
import { handleViewTrafficReport } from '../../../helpers/handleViewTrafficReport';

type HitProps = {
  hit: HitType;
  resetSearch: () => void;
};

export default function Hit({ hit, resetSearch }: HitProps) {
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const getEntityProperties = (
    hitParam: HitType,
  ): {
    label: string;
    link: string;
    action: () => Promise<void>;
    statusLabel: {
      label: string;
      colors: string;
    };
  } => {
    if (hitParam?.entityType === 'transfer') {
      const entityData = hitParam?.entityData as PrimitiveTransaction;
      return {
        label: 'Compraventa',
        statusLabel: getTransferStatusLabel(entityData?.status),
        link: `/transfer/${entityData?.transactionCode}`,
        action: null,
      };
    }
    if (hitParam?.entityType === 'bate') {
      const entityData = hitParam?.entityData as PrimitiveBate;
      return {
        label: 'Compraventa',
        statusLabel: getBateStatusLabel(entityData?.status),
        link: `/bate/${entityData?.bateCode}`,
        action: null,
      };
    }
    if (hitParam?.entityType === 'registration') {
      const entityData = hitParam?.entityData as PrimitiveRegistration;
      return {
        label: 'Matriculación',
        statusLabel: getRegistrationStatusLabel(entityData?.status),
        link: `/registration/${entityData?.registrationCode}`,
        action: null,
      };
    }
    if (hitParam?.entityType === 'trafficReport') {
      const entityData = hitParam?.entityData as PrimitiveTrafficReport;
      return {
        label: 'Informe',
        statusLabel: null,
        link: null,
        action: async () => {
          const token = await getAccessTokenSilently();
          await handleViewTrafficReport(entityData.id, token);
        },
      };
    }
    return null;
  };

  const { label, link, action, statusLabel } = getEntityProperties(hit);

  const handleClick = async () => {
    if (link) {
      navigate(link);
    }
    if (action) {
      await action();
    }

    resetSearch();
  };

  return (
    <div
      key={hit.entityId}
      className="py-2 px-4 hover:bg-gray-100 hover:text-blue-700 cursor-pointer border-b border-b-gray-100"
    >
      <button
        type="button"
        className="flex justify-between items-center w-full"
        onClick={handleClick}
      >
        <div className="flex items-center gap-x-2 w-[40%]">
          <FolderIcon
            className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <div className="flex items-center gap-x-2">
            <span className="text-xs">{label}</span>
            <span>
              {(
                hit.entityData as
                  | PrimitiveTransaction
                  | PrimitiveBate
                  | PrimitiveRegistration
              )?.car?.plate ||
                (hit.entityData as PrimitiveTrafficReport)?.plate ||
                (hit.entityData as PrimitiveTrafficReport)?.frameNumber ||
                '-'}
            </span>
          </div>
        </div>
        <div className="whitespace-nowrap px-3 text-sm text-gray-500 w-[40%]">
          <span
            className={classNames(
              'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium',
              statusLabel?.colors,
            )}
          >
            {statusLabel?.label}
          </span>
        </div>
        <div className="text-sm w-[20%]">
          {parseDayMonth3LetterYear(new Date(hit.createdAt))}
        </div>
      </button>
    </div>
  );
}

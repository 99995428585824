import { useRef, useState } from 'react';

import { useServices } from '../../../../../services';
import Spinner from '../../../../../components/Spinner';
import useClickOutside from '../../../../../hooks/useClickOutside';

const swipooMembersList = [
  { id: 'google-oauth2|115377320078851886987', name: 'Fredi Baqués Romagosa' },
  { id: 'google-oauth2|104167120711463071443', name: 'Alex Roa Diaz' },
  { id: 'google-oauth2|110739297447271674981', name: 'Juan López' },
  { id: 'google-oauth2|118127156570370259136', name: 'Juliana Pappa Franco' },
  {
    id: 'google-oauth2|107603546380568549764',
    name: 'Joan Albert Segura Rueda',
  },
  { id: 'google-oauth2|105923168068812817117', name: 'Daniel Rodrigo' },
  { id: 'google-oauth2|112602654350885750462', name: 'Joan  Tubella' },
];

export default function SwipooMembersModal({
  accountId,
  setOpenSwipooMembers,
  refresh,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [hasError, setHasError] = useState(false);

  const { addNewAccountMember } = useServices();

  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setOpenSwipooMembers(false));

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setHasError(false);
      setIsLoading(true);

      await addNewAccountMember(accountId, userId);

      setOpenSwipooMembers(false);
      refresh();
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full fixed left-0 top-0 bg-black/75 z-30 flex items-center justify-center">
      <div
        ref={modalRef}
        className="p-10 flex flex-col items-start bg-white rounded-md shadow-md"
      >
        <h2 className="text-xl font-medium mb-5">Añadir miembro de Swipoo</h2>
        <form onSubmit={handleSubmit}>
          <div className="w-80">
            <div className="flex">
              <select
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                name="userId"
                id="userId"
                defaultValue=""
                onChange={(e) => setUserId(e.target.value)}
              >
                <option value="" disabled>
                  -- Seleccionar Nuevo Usuario --
                </option>
                {swipooMembersList.map((m) => (
                  <option key={m.id} value={m.id}>
                    {m.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-5 items-center mt-5">
            <button
              type="submit"
              className="px-4 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Añadir
            </button>
            {isLoading && <Spinner color="blue" size={6} marginTop={0} />}
          </div>
          {hasError && (
            <p className="text-sm text-red-500 mt-2">
              Ha ocurrido un error al añadir el usuario.
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

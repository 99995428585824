import { FILE_TYPE } from '../types/types';

export const fileTypeDictionary = (type: FILE_TYPE): string => {
  switch (type) {
    case FILE_TYPE.DOI_FRONT:
      return 'DNI Frontal';
    case FILE_TYPE.DOI_BACK:
      return 'DNI Trasero';
    case FILE_TYPE.CIRCULATION_PERMIT:
      return 'Permiso de circulación';
    case FILE_TYPE.TECHNICAL_SHEET:
      return 'Ficha técnica';
    case FILE_TYPE.TIF:
      return 'Tarjeta de Identificación fiscal';
    default:
      return '';
  }
};

import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

export default function useCopyEntityData() {
  const { getAccessTokenSilently } = useAuth0();

  const copyEntityData = async (
    dataRecieverEntityId: string,
    dataProviderEntityId: string,
    entityType: 'car' | 'user' | 'company',
  ) => {
    const token = await getAccessTokenSilently();

    const url = `${process.env.REACT_APP_BASE_API_URL}/copy-entity-data`;
    return axios.post(
      url,
      {
        dataRecieverEntityId,
        dataProviderEntityId,
        entityType,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  };

  return { copyEntityData };
}
